/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */
// import store from '../store'
export default [{
  path: '*',
  meta: {
    name: '',
    requiresAuth: true
  },
  redirect: {
    path: '/'
    // path: JSON.parse(localStorage.getItem('user')).home || '/dashboard'
  }
},
// This  allows you to have pages apart of the app but no rendered inside the dash
{
  path: '/',
  meta: {
    name: '',
    requiresAuth: false
  },
  component: () =>
    import(/* webpackChunkName: "routes" */ '@/views/LoginHome.vue'),
  // redirect if already signed in
  beforeEnter: (to, from, next) => {
    // if (store.getters.authorized) {
    if (localStorage.getItem('token')) {
      // next('/thermal/monthlyThermalFailRateSummary')
      next(JSON.parse(localStorage.getItem('user')).home)
    } else {
      next()
    }
  },
  children: [{
    path: '',
    component: () => import('@/components/LoginForm.vue')
  }]
},
// add any extra routes that you want rendered in the dashboard as a child below. Change toolbar names here
// {
//   path: '/noEnter/:msg?',
//   meta: {
//     name: 'NoEnter',
//     requiresAuth: true
//   },
//   component: () => import('@/components/NoEnter.vue')
// },
{
  path: '/noEnter/:msg?',
  meta: {
    name: '',
    requiresAuth: true
  },
  component: () => import('@/views/DashboardView.vue'),
  children: [{
    path: '',
    component: () => import('@/components/NoEnter.vue')
  }]
},
{
  path: '/viewThermalLog/:ttrsPk?/:memo?',
  meta: {
    name: '線上檢視 LOG',
    requiresAuth: true
  },
  component: () => import('@/components/ViewThermalLog.vue')
},
{
  path: '/home',
  meta: {
    name: 'Home',
    requiresAuth: true
  },
  component: () => import('@/views/DashboardView.vue'),
  children: [{
    path: '',
    component: () => import('@/components/core/Home.vue')
  }]
},
{
  path: '/system',
  meta: {
    name: 'System',
    requiresAuth: true
  },
  component: () => import('@/views/DashboardView.vue'),
  children: [{
    path: 'serviceUsageAnalysisView/:hour?/:interval?',
    meta: {
      name: '功能用量統計',
      requiresAuth: true
    },
    component: () => import('@/components/System/ServiceUsageAnalysis.vue')
  },
  {
    path: 'download/:apiUrl?',
    meta: {
      name: '下載',
      requiresAuth: true
    },
    component: () => import('@/components/System/Download.vue')
  },
  {
    path: 'notificationListMaintainView',
    meta: {
      name: '轉檔結果通知',
      requiresAuth: true
    },
    component: () => import('@/components/System/NotificationListMaintain.vue')
  }
  ]
},
{
  path: '/thermal',
  meta: {
    name: 'Thermal(雙燒)',
    requiresAuth: true
  },
  component: () => import('@/views/DashboardView.vue'),
  children: [{
    path: 'monthlyThermalFailRateSummary/:searchDate?',
    meta: {
      name: '每月燒機不良率摘要',
      requiresAuth: true
    },
    component: () => import('@/components/Thermal/MonthlyThermalFailRateSummary.vue')
  },
  {
    path: 'weeklyThermalFailRateSummary/:searchDate?',
    meta: {
      name: '毎週燒機不良率摘要',
      requiresAuth: true
    },
    component: () => import('@/components/Thermal/WeeklyThermalFailRateSummary.vue')
  },
  {
    path: 'dailyThermalFailRateSummary/:searchDate?',
    meta: {
      name: '日燒機不良率摘要',
      requiresAuth: true
    },
    component: () => import('@/components/Thermal/DailyThermalFailRateSummary.vue')
  },
  {
    path: 'dailyBurnInDataStatistic',
    meta: {
      name: '燒機數據統計',
      requiresAuth: true
    },
    component: () => import('@/components/Thermal/DailyBurnInDataStatistic.vue')
  },
  {
    path: 'singleDeviceBurnInDataStatistic/:ttrsPk?/:groups?',
    meta: {
      name: '單機燒機數據分析',
      requiresAuth: true
    },
    component: () => import('@/components/Thermal/SingleDeviceBurnInDataStatistic.vue')
  },
  {
    path: 'singleDeviceBurnInDataSearch/:searchSN?',
    meta: {
      name: '燒機數據搜尋(單機查詢)',
      requiresAuth: true
    },
    component: () => import('@/components/Thermal/SingleDeviceBurnInDataSearch.vue')
  }
  ]
},
{
  path: '/thermal/thermalCpuBurnIn',
  meta: {
    name: 'Thermal(單燒)',
    requiresAuth: true
  },
  component: () => import('@/views/DashboardView.vue'),
  children: [{
    path: 'monthlyThermalCpuBurnInFailRateSummary/:searchDate?',
    meta: {
      name: '每月燒機不良率摘要',
      requiresAuth: true
    },
    component: () => import('@/components/ThermalCpuBurnIn/MonthlyThermalCpuBurnInFailRateSummary.vue')
  },
  {
    path: 'weeklyThermalCpuBurnInFailRateSummary/:searchDate?',
    meta: {
      name: '毎週燒機不良率摘要',
      requiresAuth: true
    },
    component: () => import('@/components/ThermalCpuBurnIn/WeeklyThermalCpuBurnInFailRateSummary.vue')
  },
  {
    path: 'dailyThermalCpuBurnInFailRateSummary/:searchDate?',
    meta: {
      name: '日燒機不良率摘要',
      requiresAuth: true
    },
    component: () => import('@/components/ThermalCpuBurnIn/DailyThermalCpuBurnInFailRateSummary.vue')
  },
  {
    path: 'singleModuleDailyCpuBurnInDataStatistic',
    meta: {
      name: '日燒機指定模組數據統計(單燒)',
      requiresAuth: true
    },
    component: () => import('@/components/ThermalCpuBurnIn/SingleModuleDailyCpuBurnInDataStatistic.vue')
  },
  {
    path: 'singleDeviceCpuBurnInDataStatistic/:ttrsPk?/:groups?',
    meta: {
      name: '單機燒機數據分析',
      requiresAuth: true
    },
    component: () => import('@/components/ThermalCpuBurnIn/SingleDeviceCpuBurnInDataStatistic.vue')
  },
  {
    path: 'singleDeviceCpuBurnInDataSearch/:searchSN?',
    meta: {
      name: '燒機數據搜尋(單機查詢)',
      requiresAuth: true
    },
    component: () => import('@/components/ThermalCpuBurnIn/SingleDeviceCpuBurnInDataSearch.vue')
  }
  ]
},
{
  path: '/thermalModule/pl1',
  meta: {
    name: 'ThermalModule(PL1)',
    requiresAuth: true
  },
  component: () => import('@/views/DashboardView.vue'),
  children: [{
    path: 'monthlyThermalModuleProdutionSummary/:searchDate?',
    meta: {
      name: '月模組生產概況',
      requiresAuth: true
    },
    component: () => import('@/components/ThermalModule/pl1/MonthlyThermalModuleProdutionSummary.vue')
  },
  {
    path: 'weeklyThermalModuleProdutionSummary/:searchDate?',
    meta: {
      name: '週模組生產概況',
      requiresAuth: true
    },
    component: () => import('@/components/ThermalModule/pl1/WeeklyThermalModuleProdutionSummary.vue')
  },
  {
    path: 'dailyThermalModuleProdutionSummary/:searchDate?',
    meta: {
      name: '日模組生產概況',
      requiresAuth: true
    },
    component: () => import('@/components/ThermalModule/pl1/DailyThermalModuleProdutionSummary.vue')
  },
  {
    path: 'thermalModuleTestDataStatistc',
    meta: {
      name: '模組熱測統計',
      requiresAuth: true
    },
    component: () => import('@/components/ThermalModule/pl1/ThermalModuleTestDataStatistc.vue')
  },
  {
    path: 'thermalModuleTestDataAnalysis',
    meta: {
      name: '模組熱測數據分析',
      requiresAuth: true
    },
    component: () => import('@/components/ThermalModule/pl1/ThermalModuleTestDataAnalysis.vue')
  },
  {
    path: 'singleThermalModuleTestDatalnfo/:pk?',
    meta: {
      name: '單模組數據檢示',
      requiresAuth: true
    },
    component: () => import('@/components/ThermalModule/pl1/SingleThermalModuleTestDatalnfo.vue')
  },
  {
    path: 'thermalModuleTestDataInfoSearch/:searchBarcode?',
    meta: {
      name: '模組熱測數據搜尋',
      requiresAuth: true
    },
    component: () => import('@/components/ThermalModule/pl1/ThermalModuleTestDataInfoSearch.vue')
  }
  ]
},
{
  path: '/thermalModule/pl2',
  meta: {
    name: 'ThermalModule(PL2)',
    requiresAuth: true
  },
  component: () => import('@/views/DashboardView.vue'),
  children: [{
    path: 'monthlyThermalModuleProdutionSummary/:searchDate?',
    meta: {
      name: '月模組生產概況',
      requiresAuth: true
    },
    component: () => import('@/components/ThermalModule/pl2/MonthlyThermalModuleProdutionSummary.vue')
  },
  {
    path: 'weeklyThermalModuleProdutionSummary/:searchDate?',
    meta: {
      name: '週模組生產概況',
      requiresAuth: true
    },
    component: () => import('@/components/ThermalModule/pl2/WeeklyThermalModuleProdutionSummary.vue')
  },
  {
    path: 'dailyThermalModuleProdutionSummary/:searchDate?',
    meta: {
      name: '日模組生產概況',
      requiresAuth: true
    },
    component: () => import('@/components/ThermalModule/pl2/DailyThermalModuleProdutionSummary.vue')
  },
  {
    path: 'thermalModuleTestDataStatistc',
    meta: {
      name: '模組熱測統計',
      requiresAuth: true
    },
    component: () => import('@/components/ThermalModule/pl2/ThermalModuleTestDataStatistc.vue')
  },
  {
    path: 'thermalModuleTestDataAnalysis',
    meta: {
      name: '模組熱測數據分析',
      requiresAuth: true
    },
    component: () => import('@/components/ThermalModule/pl2/ThermalModuleTestDataAnalysis.vue')
  },
  {
    path: 'singleThermalModuleTestDatalnfo/:pk?',
    meta: {
      name: '單模組數據檢示',
      requiresAuth: true
    },
    component: () => import('@/components/ThermalModule/pl2/SingleThermalModuleTestDatalnfo.vue')
  },
  {
    path: 'thermalModuleTestDataInfoSearch/:searchBarcode?',
    meta: {
      name: '模組熱測數據搜尋',
      requiresAuth: true
    },
    component: () => import('@/components/ThermalModule/pl2/ThermalModuleTestDataInfoSearch.vue')
  }
  ]
},
{
  path: '/thermalModule',
  meta: {
    name: 'ThermalModule',
    requiresAuth: true
  },
  component: () => import('@/views/DashboardView.vue'),
  children: [{
    path: 'monthlyThermalModuleProdutionSummary/:searchDate?',
    redirect: {
      path: '/thermalModule/pl1/monthlyThermalModuleProdutionSummary/:searchDate?'
    }
  },
  {
    path: 'weeklyThermalModuleProdutionSummary/:searchDate?',
    redirect: {
      path: '/thermalModule/pl1/weeklyThermalModuleProdutionSummary/:searchDate?'
    }
  },
  {
    path: 'dailyThermalModuleProdutionSummary/:searchDate?',
    redirect: {
      path: '/thermalModule/pl1/dailyThermalModuleProdutionSummary/:searchDate?'
    }
  },
  {
    path: 'thermalModuleTestDataStatistc',
    redirect: {
      path: '/thermalModule/pl1/thermalModuleTestDataStatistc'
    }
  },
  {
    path: 'thermalModuleTestDataAnalysis',
    redirect: {
      path: '/thermalModule/pl1/thermalModuleTestDataAnalysis'
    }
  },
  {
    path: 'singleThermalModuleTestDatalnfo/:pk?',
    redirect: {
      path: '/thermalModule/pl1/singleThermalModuleTestDatalnfo/:pk?'
    }
  },
  {
    path: 'thermalModuleTestDataInfoSearch/:searchBarcode?',
    redirect: {
      path: '/thermalModule/pl1/thermalModuleTestDataInfoSearch/:searchBarcode?'
    }
  }
  ]
},
{
  path: '/lcd',
  meta: {
    name: 'LCD',
    requiresAuth: true
  },
  component: () => import('@/views/DashboardView.vue'),
  children: [{
    path: 'lcdCalibrationAnalyzer',
    meta: {
      name: 'LCD calibration Analyzer',
      requiresAuth: true
    },
    component: () => import('@/components/LCD/LcdCalibrationAnalyzer.vue')
  },
  {
    path: 'lcdCalibrationAnalyzer/showLcdCalibrationXYZInfoView/:pk?',
    meta: {
      name: 'X/Y/Z Info',
      requiresAuth: true
    },
    component: () => import('@/components/LCD/ShowLcdCalibrationXYZInfo.vue')
  },
  {
    path: 'lcdCalibrationAnalyzer/showLcdCalibration2DXYScatterPlotView',
    meta: {
      name: '2D(x,y)散佈圖',
      requiresAuth: true
    },
    component: () => import('@/components/LCD/ShowLcdCalibration2DXYScatterPlot.vue')
  },
  {
    path: 'lcdCalibrationAnalyzer/showLcdCalibrationLuminanceYBarChartView',
    meta: {
      name: 'Luminance(Y)直條統計圖',
      requiresAuth: true
    },
    component: () => import('@/components/LCD/ShowLcdCalibrationLuminanceYBarChart.vue')
  },
  {
    path: 'lcdCalibrationAnalyzer/showLcdCalibrationDeltaEDistributionBarChartView',
    meta: {
      name: 'Delta E Distribution直條統計圖',
      requiresAuth: true
    },
    component: () => import('@/components/LCD/ShowLcdCalibrationDeltaEDistributionBarChart.vue')
  },
  {
    path: 'lcdCalibrationAnalyzer/showLcdCalibrationGammaDiffBarChartView',
    meta: {
      name: 'Gamma Diff直條統計圖',
      requiresAuth: true
    },
    component: () => import('@/components/LCD/ShowLcdCalibrationGammaDiffBarChart.vue')
  },
  {
    path: 'lcdCalibrationAnalyzer/showLcdCalibrationGammaBarChartView',
    meta: {
      name: 'Gamma統計資訊',
      requiresAuth: true
    },
    component: () => import('@/components/LCD/ShowLcdCalibrationGammaBarChart.vue')
  }
  ]
},
{
  path: '/pqm/cscDataParsingMgmt',
  meta: {
    name: 'CSC轉檔管理',
    requiresAuth: true
  },
  component: () => import('@/views/DashboardView.vue'),
  children: [{
    path: 'uploadParsingDataListView',
    meta: {
      name: 'CSC上傳資料管理',
      requiresAuth: true
    },
    component: () => import('@/components/CSC/CscUploadParsingDataMgmt.vue')
  },
  {
    path: 'modelNameMappingRuleMaintainView',
    meta: {
      name: '產品名稱轉檔規則資料維護',
      requiresAuth: true
    },
    component: () => import('@/components/CSC/ModelNameMappingRuleMaintain.vue')
  },
  {
    path: 'modelManufactureYearMaintainView',
    meta: {
      name: '產品出廠年度資料維護',
      requiresAuth: true
    },
    component: () => import('@/components/CSC/ModelManufactureYearMaintain.vue')
  },
  {
    path: 'reasonCodeMappingLevelMaintainView',
    meta: {
      name: '維修原因歸類規則維護',
      requiresAuth: true
    },
    component: () => import('@/components/CSC/ReasonCodeMappingLevelMaintain.vue')
  },
  {
    path: 'repairItemClassifyRuleMaintainView',
    meta: {
      name: '維修項目等級歸類規則維護',
      requiresAuth: true
    },
    component: () => import('@/components/CSC/RepairItemClassifyRuleMaintain.vue')
  },
  {
    path: 'cfrBaselineSettingView',
    meta: {
      name: 'CFR不良率標準設定',
      requiresAuth: true
    },
    component: () => import('@/components/CSC/CfrBaselineSetting.vue')
  },
  {
    path: 'downloadResourceDefinitionMaintainView',
    meta: {
      name: '下載資源管理維護',
      requiresAuth: true
    },
    component: () => import('@/components/CSC/DownloadResourceDefinitionMaintain.vue')
  },
  {
    path: 'cscRawDataExportView',
    meta: {
      name: 'CSC Raw Data匯出管理',
      requiresAuth: true
    },
    component: () => import('@/components/CSC/CscRawDataExport.vue')
  },
  {
    path: 'notificationListMaintainView',
    meta: {
      name: 'PQM發送通知人管理',
      requiresAuth: true
    },
    component: () => import('@/components/CSC/NotificationListMaintain.vue')
  },
  {
    path: 'systemSettingView',
    meta: {
      name: 'PQM功能設定',
      requiresAuth: true
    },
    component: () => import('@/components/CSC/SystemSetting.vue')
  },
  {
    path: 'mucModuleDataMgmt',
    meta: {
      name: '料件資料維護',
      requiresAuth: true
    },
    component: () => import('@/components/CSC/mucModuleDataMgmt.vue')
  }, {
    path: 'moduleStatisticDataExportView',
    meta: {
      name: '料件統計資料下載',
      requiresAuth: true
    },
    component: () => import('@/components/CSC/ModuleStatisticDataExport.vue')
  }
  ]
},
{
  path: '/pqm/cfrAnalysisMgmt',
  meta: {
    name: 'CFR不良率分析管理',
    requiresAuth: true
  },
  component: () => import('@/views/DashboardView.vue'),
  children: [{
    path: 'cfrAnalyzerView/:code?',
    meta: {
      name: '電競產品不良率分析工具',
      requiresAuth: true
    },
    component: () => import('@/components/CFR/CfrAnalyzer.vue')
  },
  {
    path: 'weeklyRepairItemAnalysisView',
    meta: {
      name: '每週不良項目分析',
      requiresAuth: true
    },
    component: () => import('@/components/CFR/WeeklyRepairItemAnalysis.vue')
  },
  {
    path: 'deviceCfrTrendAnalysisView/:depth1?/:depth2?/:depth3?/:depth4?/:depth5?',
    meta: {
      name: '機種不良率趨勢分析',
      requiresAuth: true
    },
    component: () => import('@/components/CFR/DeviceCfrTrendAnalysis.vue')
  },
  {
    path: 'threeYearCfrAnalysisView',
    meta: {
      name: '年度不良率趨勢',
      requiresAuth: true
    },
    component: () => import('@/components/CFR/ThreeYearCfrAnalysis.vue')
  },
  {
    path: 'problemStatisticView',
    meta: {
      name: '軟硬體不良率維修統計',
      requiresAuth: true
    },
    component: () => import('@/components/CFR/ProblemStatistic.vue')
  },
  {
    path: 'repairPartsStatisticView',
    meta: {
      name: '料件不良率統計',
      requiresAuth: true
    },
    component: () => import('@/components/CFR/RepairPartsStatistic.vue')
  },
  {
    path: 'repairPartsCFRStatisticView',
    meta: {
      name: '料件不良率(CFR)',
      requiresAuth: true
    },
    component: () => import('@/components/CFR/RepairPartsCFRStatistic.vue')
  },
  {
    path: 'machineCfrStatisicView',
    meta: {
      name: '機台維修不良率統計',
      requiresAuth: true
    },
    component: () => import('@/components/CFR/MachineCfrStatisic.vue')
  },
  {
    path: 'machineRepairBySnStatisicView',
    meta: {
      name: '機台返修查詢',
      requiresAuth: true
    },
    component: () => import('@/components/CFR/MachineRepairBySnStatisic.vue')
  },
  {
    path: 'issueTrackingMgmtView',
    meta: {
      name: '問題追蹤管理',
      requiresAuth: true
    },
    component: () => import('@/components/CFR/IssueTrackingMgmt.vue')
  },
  {
    path: 'cscBackendRawDataExportView',
    meta: {
      name: 'L3/L4原始維修資料下載',
      requiresAuth: true
    },
    component: () => import('@/components/CFR/CscBackendRawDataExport.vue')
  }
  ]
},
{
  path: '/keypart/battery',
  meta: {
    name: 'keypart',
    requiresAuth: true
  },
  component: () => import('@/views/DashboardView.vue'),
  children: [{
    path: 'batteryCPKAnalyzer',
    meta: {
      name: '電池製程品質分析',
      requiresAuth: true
    },
    component: () => import('@/components/Keypart/Battery/BatteryCPKAnalyzer.vue')
  },
  {
    path: 'batteryTestDataBySn',
    meta: {
      name: '電池生產資訊查詢',
      requiresAuth: true
    },
    component: () => import('@/components/Keypart/Battery/BatteryTestDataBySn.vue')
  },
  {
    path: 'batteryManufacturingReport',
    meta: {
      name: '電池生產品質週報',
      requiresAuth: true
    },
    component: () => import('@/components/Keypart/Battery/BatteryManufacturingReport.vue')
  }
  ]
},
{
  path: '/keypart/keyboard',
  meta: {
    name: '鍵盤料件品質管理',
    requiresAuth: true
  },
  component: () => import('@/views/DashboardView.vue'),
  children: [{
    path: 'keyboardSummaryAnalyzer/:searchWeeksStart?/:searchWeeksEnd?/:searchVendorName?/:searchModelName?/:searchPartNumber?',
    meta: {
      name: '鍵盤整體製程品質總覽',
      requiresAuth: true
    },
    component: () => import('@/components/Keypart/Keyboard/KeyboardSummaryAnalyzer.vue')
  },
  {
    path: 'keyboardRgbAnalyzer/:searchWeeksStart?/:searchWeeksEnd?/:searchVendorName?/:searchModelName?/:searchPartNumber?',
    meta: {
      name: '鍵盤RGB製程品質分析',
      requiresAuth: true
    },
    component: () => import('@/components/Keypart/Keyboard/KeyboardRgbAnalyzer.vue')
  }, {
    path: 'keyboardFeelingAnalyzer/:searchWeeksStart?/:searchWeeksEnd?/:searchVendorName?/:searchModelName?/:searchPartNumber?/:searchButtonCode?',
    meta: {
      name: '鍵盤操作體驗製程品質分析',
      requiresAuth: true
    },
    component: () => import('@/components/Keypart/Keyboard/KeyboardFeelingAnalyzer.vue')
  },
  {
    path: 'keyboardKeyHeightAnalyzer/:searchWeeksStart?/:searchWeeksEnd?/:searchVendorName?/:searchModelName?/:searchPartNumber?/:searchButtonCode?',
    meta: {
      name: '鍵盤高度數據分析',
      requiresAuth: true
    },
    component: () => import('@/components/Keypart/Keyboard/KeyboardKeyHeightAnalyzer.vue')
  },
  {
    path: 'keyboardLuminanceAnalyzer/:searchWeeksStart?/:searchWeeksEnd?/:searchVendorName?/:searchModelName?/:searchPartNumber?/:searchButtonCode?',
    meta: {
      name: '鍵盤亮度數據分析',
      requiresAuth: true
    },
    component: () => import('@/components/Keypart/Keyboard/KeyboardLuminanceAnalyzer.vue')
  },
  {
    path: 'keyboardAoiAnalyzer/:searchWeeksStart?/:searchWeeksEnd?/:searchVendorName?/:searchModelName?/:searchPartNumber?/:searchButtonCode?',
    meta: {
      name: '鍵盤密合度製程品質分析',
      requiresAuth: true
    },
    component: () => import('@/components/Keypart/Keyboard/KeyboardAoiAnalyzer.vue')
  },
  {
    path: 'keyboardRawDataFinder',
    meta: {
      name: '鍵盤原始生產數據查詢',
      requiresAuth: true
    },
    component: () => import('@/components/Keypart/Keyboard/KeyboardRawDataFinder.vue')
  },
  {
    path: 'keyboardManufacturingReport/:searchWeeksStart?/:searchWeeksEnd?/:searchVendorName?/:searchModelName?/:searchPartNumber?/:systemTrigger?',
    meta: {
      name: '鍵盤生產週報',
      requiresAuth: true
    },
    component: () => import('@/components/Keypart/Keyboard/KeyboardManufacturingReport.vue')
  }
  ]
},
{
  path: '/keypart/lcdModule',
  meta: {
    name: '面板料件品質管理',
    requiresAuth: true
  },
  component: () => import('@/views/DashboardView.vue'),
  children: [{
    path: 'lcdModuleSummaryAnalyzer/:searchWeeksStart?/:searchWeeksEnd?/:searchVendorName?/:searchPartNumber?',
    meta: {
      name: '面板料件整體製程品質總覽',
      requiresAuth: true
    },
    component: () => import('@/components/Keypart/LcdModule/LcdModuleSummaryAnalyzer.vue')
  },
  {
    path: 'lcdModuleSingleProcessAnalyzer/:searchWeeksStart?/:searchWeeksEnd?/:searchVendorName?/:searchPartNumber?/:searchProcessStation?',
    meta: {
      name: '面板料件單製程品質分析',
      requiresAuth: true
    },
    component: () => import('@/components/Keypart/LcdModule/LcdModuleSingleProcessAnalyzer.vue')
  },
  {
    path: 'lcdModuleRawDataFinder',
    meta: {
      name: '面板料件原始生產數據查詢',
      requiresAuth: true
    },
    component: () => import('@/components/Keypart/LcdModule/LcdModuleRawDataFinder.vue')
  }, {
    path: 'lcdModuleManufacturingReport/:searchWeeksStart?/:searchWeeksEnd?/:searchVendorName?/:searchPartNumber?/:systemTrigger?',
    meta: {
      name: '面板料件生產週報',
      requiresAuth: true
    },
    component: () => import('@/components/Keypart/LcdModule/LcdModuleManufacturingReport.vue')
  }, {
    path: 'panelReworkRateAnalyzer/:searchWeeksStart?/:searchWeeksEnd?/:searchVendorName?/:searchPartNumber?/:systemTrigger?',
    meta: {
      name: '面板重工率管制',
      requiresAuth: true
    },
    component: () => import('@/components/Keypart/LcdModule/PanelReworkRateAnalyzer.vue')
  }, {
    path: 'panelParticleMeasureAnalyzer/:searchWeeksStart?/:searchWeeksEnd?/:searchVendorName?/:searchPartNumber?/:systemTrigger?',
    meta: {
      name: '面板環境潔淨度管制',
      requiresAuth: true
    },
    component: () => import('@/components/Keypart/LcdModule/PanelParticleMeasureAnalyzer.vue')
  }, {
    path: 'panelYieldRateAnalyzer/:searchWeeksStart?/:searchWeeksEnd?/:searchVendorName?/:searchPartNumber?/:systemTrigger?',
    meta: {
      name: '面板料件生產良率管制',
      requiresAuth: true
    },
    component: () => import('@/components/Keypart/LcdModule/PanelYieldRateAnalyzer.vue')
  }
  ]
},
{
  path: '/keypart/speaker',
  meta: {
    name: '揚聲器料件品質管理',
    requiresAuth: true
  },
  component: () => import('@/views/DashboardView.vue'),
  children: [{
    path: 'speakerSummaryAnalyzer/:searchDateType?/:searchDateStart?/:searchVendorName?/:searchPartNumber?',
    meta: {
      name: '揚聲器料件整體製程品質總覽',
      requiresAuth: true
    },
    component: () => import('@/components/Keypart/Speaker/SpeakerSummaryAnalyzer.vue')
  },
  {
    path: 'speakerSingleProcessAnalyzer/:searchDateType?/:searchDateStart?/:searchVendorName?/:searchPartNumber?',
    meta: {
      name: '揚聲器料件單製程品質分析',
      requiresAuth: true
    },
    component: () => import('@/components/Keypart/Speaker/SpeakerSingleProcessAnalyzer.vue')
  }, {
    path: 'speakerRawDataFinder',
    meta: {
      name: '揚聲器料件原始生產數據查詢',
      requiresAuth: true
    },
    component: () => import('@/components/Keypart/Speaker/SpeakerRawDataFinder.vue')
  }, {
    path: 'speakerSystemParameterSettingView',
    meta: {
      name: '揚聲器分析統計參數設定',
      requiresAuth: true
    },
    component: () => import('@/components/Keypart/Speaker/SpeakerSystemParameterSetting.vue')
  }
  //  {
  //   path: 'lcdModuleManufacturingReport/:searchWeeksStart?/:searchWeeksEnd?/:searchVendorName?/:searchPartNumber?/:systemTrigger?',
  //   meta: {
  //     name: '面板料件生產週報',
  //     requiresAuth: true
  //   },
  //   component: () => import('@/components/Keypart/LcdModule/LcdModuleManufacturingReport.vue')
  // }, {
  //   path: 'panelReworkRateAnalyzer/:searchWeeksStart?/:searchWeeksEnd?/:searchVendorName?/:searchPartNumber?/:systemTrigger?',
  //   meta: {
  //     name: '面板重工率管制',
  //     requiresAuth: true
  //   },
  //   component: () => import('@/components/Keypart/LcdModule/PanelReworkRateAnalyzer.vue')
  // }, {
  //   path: 'panelParticleMeasureAnalyzer/:searchWeeksStart?/:searchWeeksEnd?/:searchVendorName?/:searchPartNumber?/:systemTrigger?',
  //   meta: {
  //     name: '面板環境潔淨度管制',
  //     requiresAuth: true
  //   },
  //   component: () => import('@/components/Keypart/LcdModule/PanelParticleMeasureAnalyzer.vue')
  // }, {
  //   path: 'panelYieldRateAnalyzer/:searchWeeksStart?/:searchWeeksEnd?/:searchVendorName?/:searchPartNumber?/:systemTrigger?',
  //   meta: {
  //     name: '面板料件生產良率管制',
  //     requiresAuth: true
  //   },
  //   component: () => import('@/components/Keypart/LcdModule/PanelYieldRateAnalyzer.vue')
  // }
  ]
},
{
  path: '/product/fundamental',
  meta: {
    name: '工廠生產設備資訊管理',
    requiresAuth: true
  },
  component: () => import('@/views/DashboardView.vue'),
  children: [{
    path: 'manufacturer/factoryMgmtView',
    meta: {
      name: '工廠資訊管理',
      requiresAuth: true
    },
    component: () => import('@/components/Product/Fundamental/FactoryMgmtView.vue')
  },
  {
    path: 'product/projectDefinitionMgmtView',
    meta: {
      name: '生產機種管理',
      requiresAuth: true
    },
    component: () => import('@/components/Product/Fundamental/ProjectDefinitionMgmtView.vue')
  },
  {
    path: 'manufacturer/stationDefinitionMgmtView',
    meta: {
      name: '產線工站管理',
      requiresAuth: true
    },
    component: () => import('@/components/Product/Fundamental/StationDefinitionMgmtView.vue')
  }
  ]
},
{
  path: '/product/analyze',
  meta: {
    name: '代工廠產品製程分析管理',
    requiresAuth: true
  },
  component: () => import('@/views/DashboardView.vue'),
  children: [{
    path: 'deviceManufacturedHistoryView/:searchSN?/',
    meta: {
      name: '產品生產歷程概覽',
      requiresAuth: true
    },
    component: () => import('@/components/Product/Analyze/DeviceManufacturedHistoryView.vue')
  },
  {
    path: 'productLineQualityReviewView',
    meta: {
      name: '產品產線生產良率統計',
      requiresAuth: true
    },
    component: () => import('@/components/Product/Analyze/ProductLineQualityReviewView.vue')
  },
  {
    path: 'dataAnalyzer/:searchDateStart?/:searchDateEnd?/:searchModelPk?/:searchStationPk?',
    meta: {
      name: '工站單製程品質分析',
      requiresAuth: true
    },
    component: () => import('@/components/Product/Analyze/DataAnalyzer.vue')
  },
  {
    path: 'mediaReviewAnalyzer',
    meta: {
      name: '產品出廠測試效能分析',
      requiresAuth: true
    },
    component: () => import('@/components/Product/Analyze/MediaReviewAnalyzer.vue')
  },
  {
    path: 'deviceStationTestItemStatistic/:searchModelPk?/:searchStationPk?/:searchSn?',
    meta: {
      name: '工站機台生產測試項目統計',
      requiresAuth: true
    },
    component: () => import('@/components/Product/Analyze/DeviceStationTestItemStatistic.vue')
  }
  ]
},
{
  path: '/privilege',
  meta: {
    name: 'Privilege',
    requiresAuth: true
  },
  component: () => import('@/views/DashboardView.vue'),
  children: [{
    path: 'userManagementSetting/:action?',
    meta: {
      name: '使用者管理',
      requiresAuth: true
    },
    component: () => import('@/components/Privilege/UserManagementSetting.vue')
  },
  {
    path: 'groupManagementSetting/:action?',
    meta: {
      name: '群組管理',
      requiresAuth: true
    },
    component: () => import('@/components/Privilege/GroupManagementSetting.vue')
  }
  ]
},
{ // Demo
  path: '/dashboard',
  meta: {
    name: 'Dashboard View',
    requiresAuth: true
  },
  component: () => import('@/views/DashboardView.vue'),
  children: [{
    path: '',
    name: 'Dashboard',
    component: () => import('@/components/DashViews/Dashboard.vue')
  },
  {
    path: 'search',
    meta: {
      name: 'Search',
      requiresAuth: true
    },
    component: () => import('@/components/DashViews/Search.vue')
  },
  {
    path: 'user-profile',
    meta: {
      name: 'User Profile',
      requiresAuth: true
    },
    component: () => import('@/components/DashViews/UserProfile.vue')
  },
  {
    path: 'table-list',
    meta: {
      name: 'Table List',
      requiresAuth: true
    },
    component: () => import('@/components/DashViews/SimpleTables.vue')
  },
  {
    path: 'user-tables',
    meta: {
      name: 'User Table',
      requiresAuth: true
    },
    component: () => import('@/components/DashViews/UsersTable.vue')
  },
  {
    path: 'tablestest',
    meta: {
      name: 'Complex Tables test',
      requiresAuth: true
    },
    component: () => import('@/components/DashViews/TableList.vue')
  },
  {
    path: 'typography',
    meta: {
      name: 'Typography',
      requiresAuth: true
    },
    component: () => import('@/components/DashViews/Typography.vue')
  },
  {
    path: 'icons',
    meta: {
      name: 'Icons',
      requiresAuth: true
    },
    component: () => import('@/components/DashViews/Icons.vue')
  },
  {
    path: 'maps',
    meta: {
      name: 'Maps',
      requiresAuth: true
    },
    component: () => import('@/components/DashViews/Maps.vue')
  },
  {
    path: 'notifications',
    meta: {
      name: 'Notifications',
      requiresAuth: true
    },
    component: () => import('@/components/DashViews/Notifications.vue')
  }
  ]
}
]
