<template>
  <v-container
    cfr
    issue_tracking
    fluid
    grid-list-xl
    pt-0
  >
    <v-layout wrap>
      <v-flex
        mt-3
        xs12
        md12
      >
        <material-card
          :title="'電池生產資訊查詢'"
          color="deep-orange"
          class="card-mix card-padding pt-0 pb-3">
          <v-form>
            <v-container
              py-0
              class="mw-100">
              <v-layout wrap>
                <v-flex
                  flex-small
                  xs8
                  lg8>
                  <v-autocomplete
                    v-model="SerialNumberListSelect"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="SerialNumberList"
                    label="序號清單"
                    class="v-text-field__slot issue_tracking_menu_chips"
                    color="white"
                    persistent-hint
                    outlined
                    multiple
                    multitude
                    chips
                  />
                </v-flex>
                <v-flex
                  flex-small
                  xs4
                  lg4
                >
                  <v-btn
                    color="general"
                    @click="selectAllSerialNumberListItem()"
                  >
                    選擇全部
                  </v-btn>
                  <v-btn
                    color="red"
                    @click="cancelSerialNumberListItem()"
                  >
                    取消選擇
                  </v-btn>
                  <v-btn
                    color="black"
                    @click="resetSerialNumberListItem()">
                    重置清單
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex
                  flex-small
                  xs12
                  lg12>
                  <v-text-field
                    v-model="SerialNumberInput"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    label="手輸序號清單 (多組請以逗號區隔)"
                    class="v-text-field__slot row-btn-450"
                    persistent-hint
                    outlined
                  />
                  <v-btn
                    class="mt-3 ml-3 mr-0 font-weight-light float-right"
                    color="black"
                    @click="doSampleDownload()">
                    <v-icon class="float-left">mdi-file</v-icon>下載範本檔案
                  </v-btn>
                  <v-btn
                    class="mt-3 ml-3 mr-0 font-weight-light float-right"
                    color="black"
                    @click="uploadFile(0)">
                    <v-icon class="float-left">mdi-expand-all</v-icon>匯入序號清單
                  </v-btn>
                  <v-btn
                    class="mt-3 ml-3 mr-0 font-weight-light float-right"
                    color="black"
                    @click="addDeviceSnListItem()">
                    <v-icon class="float-left">mdi-plus</v-icon>新增序號
                  </v-btn>
                  <input
                    v-show="false"
                    id="upload-file"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    type="file"
                    label="檔名"
                    @change="setFile($event)">
                </v-flex>
                <v-flex
                  flex-small
                  xs12
                  lg12>
                  <v-card-actions class="m-0 border-top border-secondary mt-3">
                    <v-spacer/>
                    <v-btn
                      color="general"
                      class="ml-3"
                      small
                      @click="doSubmit(true, 1)"
                    >
                      查詢
                    </v-btn>
                  </v-card-actions>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
      </v-flex>
      <v-flex
        v-show="queryShow"
        mt-3
        xs12
        md12
      >
        <material-card
          :title="'查詢結果'"
          color="deep-orange"
          class="card-mix card-padding pt-0 pb-3">
          <v-select
            v-model="perPageRows"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="rowsAmount"
            class="mix-list v-text-field__slot d-inline-block float-right ml-5"
            style="width: 90px;"
            persistent-hint
            outlined
            @change="doSubmit(true, 1)"
          />
          <v-btn
            class="mix-btn black text-light"
            style="right: 15rem;"
            @click="doGroupDownload(0)">
            <v-icon class="floata-left">mdi-download</v-icon>全部下載
          </v-btn>
          <v-btn
            class="mix-btn black text-light"
            style="right: 8rem;"
            @click="doGroupDownload(1)">
            <v-icon class="floata-left">mdi-download</v-icon>打包下載
          </v-btn>
          <v-autocomplete
            id="checkTableInput"
            v-model="checkTableShowList"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="summaryTableShowList"
            label="選擇欄位"
            class="v-text-field__slot row-btn-175 pt-0"
            color="white"
            multitude
            chips
            persistent-hint
            multiple
            outlined
            @change="showHeaders"
          />
          <v-btn
            class="red text-light float-right mr-0"
            @click="checkTableShowListClear()"
          >
            清除
          </v-btn>
          <v-btn
            class="blue text-light float-right"
            @click="checkTableShowListAll()"
          >
            全選
          </v-btn>
        </material-card>
        <material-card
          :style="'max-width: calc(100vw - '+(Number(shrink)+32)+'px);'"
          class="d-table-t0 no-data-left">
          <v-text-field
            v-model="keyWord"
            placeholder="請輸入關鍵字"
            class="mix-list v-text-field__slot d-inline-block float-left ml-5 text-white"
            style="width: 300px;right: 140px;"
            type="text"
            single-line
            hide-details
            label=""/>
          <v-data-table
            v-model="selected"
            :headers="showResponseHeaders"
            :items="desserts"
            :pagination.sync="pagination"
            :search="keyWord"
            :custom-filter="customFilter"
            select-all
            item-key="name"
            hide-actions
          >
            <template v-slot:headers="props">
              <tr>
                <th>
                  <v-checkbox
                    :input-value="allslectflg"
                    :indeterminate="props.indeterminate"
                    primary
                    hide-details
                    @click.stop="toggleAll"
                  />
                </th>
                <th
                  v-for="header in props.headers"
                  :key="header.text"
                  :class="['column sortable', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '']"
                  @click="changeSort(header.value)"
                >
                  <v-icon small>arrow_upward</v-icon>
                  {{ header.text }}
                </th>
              </tr>
            </template>
            <template v-slot:items="props" >
              <tr>
                <td>
                  <v-checkbox
                    :input-value="props.item.selected"
                    primary
                    hide-details
                    @click.stop="selectchang(props)"
                  />
                </td>
                <td>{{ props.item.manufactureWeek }}</td>
                <td class="text-xs-right">{{ props.item.manufactureDate }}</td>
                <td class="text-xs-right">{{ props.item.partNumber }}</td>
                <td class="text-xs-right">{{ props.item.cellType }}</td>
                <td class="text-xs-right">{{ props.item.modelName }}</td>
                <td class="text-xs-right">{{ props.item.serialNumber }}</td>
                <td class="text-xs-right">{{ props.item.voltage }}</td>
                <td class="text-xs-right">{{ props.item.fcc }}</td>
                <td class="text-xs-right">{{ props.item.rsoc }}</td>
                <td class="text-xs-right">{{ props.item.staticDeltaOfCellsVoltage }}</td>
                <td class="text-xs-right">{{ props.item.oqcRecord }}</td>
                <td
                  v-show="summaryTableRowDataShow['MANUFACTURE_MONTH']"
                  class="text-xs-right">
                  {{ props.item.manufactureMonth }}
                </td>
                <td
                  v-show="summaryTableRowDataShow['INVOICE_NUMBER']"
                  class="text-xs-right">
                  {{ props.item.invoiceNumber }}
                </td>
                <td
                  v-show="summaryTableRowDataShow['WO_NUMBER']"
                  class="text-xs-right">
                  {{ props.item.woNumber }}
                </td>
                <td
                  v-show="summaryTableRowDataShow['SN_PRODUCT_SN_0X70']"
                  class="text-xs-right">
                  {{ props.item.snProductSn0x70 }}
                </td>
                <td
                  v-show="summaryTableRowDataShow['Cell1_Voltage']"
                  class="text-xs-right">
                  {{ props.item.cell1Voltage }}
                </td>
                <td
                  v-show="summaryTableRowDataShow['Cell2_Voltage']"
                  class="text-xs-right">
                  {{ props.item.cell2Voltage }}
                </td>
                <td
                  v-show="summaryTableRowDataShow['Cell3_Voltage']"
                  class="text-xs-right">
                  {{ props.item.cell3Voltage }}
                </td>
                <td
                  v-show="summaryTableRowDataShow['Cell4_Voltage']"
                  class="text-xs-right">
                  {{ props.item.cell4Voltage }}
                </td>
                <td
                  v-show="summaryTableRowDataShow['REWORK_RECORD']"
                  class="text-xs-right">
                  {{ props.item.reworkRecord }}
                </td>
              </tr>
            </template>
          </v-data-table>
          <div
            v-if="pages > 1"
            class="text-xs-center pt-2 d-inline-block"
            style="margin-left: 0px;width: calc(100% - 140px);">
            <v-pagination
              v-model="pagination.page"
              :length="pages"
              @input="doSubmit(false,pagination.page)" />
          </div>
          <!-- <v-autocomplete
            v-if="pagination.rowsPerPage != -1"
            v-model="pagination.page"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="pageslist"
            class="v-text-field__slot d-inline-block float-right mr-0"
            style="width: 90px;"
            persistent-hint
            outlined
          /> -->
        </material-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="processingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        PROCESSING 處理中
      </p>
    </v-dialog>
    <v-dialog
      v-model="loadingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :color="snackbarColor"
      :top="true">
      {{ snackbarMsg }}
      <v-btn
        flat
        @click="snackbar = false">
        關閉
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import $ from 'jquery'
import { mapState } from 'vuex'
import Loading from '@/assets/img/loading.gif'

export default {
  name: 'BatteryTestDataBySn',
  data () {
    return {
      keyWord: '',
      snackbar: false,
      snackbarColor: 'red',
      snackbarMsg: '',
      Loading,
      loadingView: false,
      processingView: false,
      queryShow: false,
      allslectflg: false,
      responseData: [],
      responseDataOnly: [],
      responseHeadersOnly: [],
      responseHeadersList: [],
      responseColumnClassList: {},
      responseAlignList: {},
      responseTableShowList: {},
      responseTableShowListOnly: {},
      responseExcelToListData: {},
      checkTableShowList: [],
      summaryTableShowList: [],
      summaryTableRowDataShow: [],
      responseHeaders: [],
      showResponseHeaders: [],
      responseHeadersMap: {
        manufacture_week: { text: 'MANUFACTURE_WEEK', value: 'manufactureWeek', sortable: true, align: '', class: '', disabled: false },
        manufacture_date: { text: 'MANUFACTURE_DATE', value: 'manufactureDate', sortable: true, align: '', class: '', disabled: false },
        part_number: { text: 'PART_NUMBER', value: 'partNumber', sortable: true, align: '', class: '', disabled: false },
        cell_type: { text: 'Cell_TYPE', value: 'cellType', sortable: true, align: '', class: '', disabled: false },
        model_name: { text: 'MODEL_NAME', value: 'model_name', sortable: true, align: '', class: '', disabled: false },
        serial_number: { text: 'SERIAL_NUMBER', value: 'serialNumber', sortable: true, align: '', class: '', disabled: false },
        voltage: { text: 'Voltage', value: 'voltage', sortable: true, align: '', class: '', disabled: false },
        fcc: { text: 'FCC', value: 'fcc', sortable: true, align: '', class: '', disabled: false },
        rsoc: { text: 'RSOC', value: 'rsoc', sortable: true, align: '', class: '', disabled: false },
        static_delta_of_cells_voltage: { text: 'Static_Delta_of_cells_voltage', value: 'staticDeltaOfCellsVoltage', sortable: true, align: '', class: '', disabled: false },
        oqc_record: { text: 'OQC_RECORD', value: 'oqc_record', sortable: true, align: '', class: '', disabled: false },
        manufacture_month: { text: 'MANUFACTURE_MONTH', value: 'manufactureMonth', sortable: true, align: '', class: '', disabled: true },
        invoice_number: { text: 'INVOICE_NUMBER', value: 'invoiceNumber', sortable: true, align: '', class: '', disabled: true },
        wo_number: { text: 'WO_NUMBER', value: 'woNumber', sortable: true, align: '', class: '', disabled: true },
        sn_product_sn_0x70: { text: 'SN_PRODUCT_SN_0X70', value: 'snProductSn0x70', sortable: true, align: '', class: '', disabled: true },
        cell1_voltage: { text: 'Cell1_Voltage', value: 'cell1Voltage', sortable: true, align: '', class: '', disabled: true },
        cell2_voltage: { text: 'Cell2_Voltage', value: 'cell2Voltage', sortable: true, align: '', class: '', disabled: true },
        cell3_voltage: { text: 'Cell3_Voltage', value: 'cell3Voltage', sortable: true, align: '', class: '', disabled: true },
        cell4_voltage: { text: 'Cell4_Voltage', value: 'cell4Voltage', sortable: true, align: '', class: '', disabled: true },
        rework_record: { text: 'REWORK_RECORD', value: 'reworkRecord', sortable: true, align: '', class: '', disabled: true }
      },
      selected: [],
      desserts: [],
      Alldesserts: [],
      SerialNumberList: [],
      SerialNumberListSelect: [],
      tempSerialNumberList: [],
      checkList: [],
      rowsAmount: [25, 50, 100, { text: 'All', value: -1 }],
      perPageRows: 25,
      pagination: {
        page: 1,
        rowsPerPage: 25,
        sortBy: '',
        descending: false
      },
      pages: 1,
      SerialNumberInput: '',
      file: [
        {
          file: '',
          name: '',
          url: ''
        }
      ]
    }
  },
  computed: {
    ...mapState('app', ['theme', 'shrink']),

    pageslist () {
      let i = 0
      const list = []
      if (this.pagination.rowsPerPage) {
        for (i = 0; i < Math.ceil(this.responseData.length / this.pagination.rowsPerPage); i++) {
          list.push(i + 1)
        }
      }
      return list
    }
  },
  watch: {
    pagination: {
      handler (newName, oldName) {
        if (this.submit && !this.IsSubmitPage) {
          this.doSubmit(false, 1)
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted () {
    this.createshowHeaders()
    this.createTableShowlist()
    this.$store.dispatch('getAuthorize', '/keypart/battery/batteryTestDataBySn')
      .then(response => {
        if (response.status === 'success' && response.data.accessible) {
          //
        } else if (response.errCode === 'E10000002') {
          const routeData = this.$router.resolve({ path: '/noEnter/E10000002' })
          localStorage.setItem('errormsg', response.errMsg)
          window.location.assign(routeData.href)
        } else {
          const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
          window.location.assign(routeData.href)
        }
      })
      .catch(err => {
        console.log(err)
        const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
        window.location.assign(routeData.href)
      })
  },
  methods: {
    customFilter (items, search, filter) {
      let tempfilteritem = this.Alldesserts
      if (search !== '') {
        this.responseHeaders.some((element, index) => {
          tempfilteritem = this.Alldesserts.filter(row => filter(row[element.value], search.toLowerCase()))
          if (tempfilteritem.length > 0) {
            return true
          }
        })
        console.log('tempfilteritem', tempfilteritem)
        this.pages = Math.ceil(tempfilteritem.length / this.perPageRows)
      }

      return tempfilteritem
    },
    selectchang (props) {
      console.log('props', props)
      this.desserts[props.index].selected = !this.desserts[props.index].selected
      this.Alldesserts.filter(e => {
        if (e.serialNumber === props.item.serialNumber) {
          e.selected = !e.selected
        }
      })
      this.doSubmit(false, this.pagination.page)
      // setTimeout(() => {
      //   this.pagination.page = this.pagination.page
      // }, 500)
      return false
    },
    changeSort (column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending
      } else {
        this.pagination.sortBy = column
        this.pagination.descending = false
      }
    },
    toggleAll () {
      const _startPage = this.getStartPage(this.pagination.page)
      const _endPage = this.getEndPage(this.pagination.page)

      console.log('xx', _startPage, _endPage)
      this.allslectflg = !this.allslectflg

      if (this.allslectflg) {
        this.desserts.forEach((e, i) => {
          if (i >= _startPage && i <= _endPage) {
            e.selected = true
          }
        })
      } else {
        this.desserts.forEach((e, i) => {
          if (i >= _startPage && i <= _endPage) {
            e.selected = false
          }
        })
      }
      this.desserts.forEach((e, i) => {
        if (i >= _startPage && i <= _endPage) {
          this.Alldesserts.filter(e1 => {
            if (e.serialNumber === e1.serialNumber) {
              e1.selected = e.selected
            }
          })
        }
      })
      return false
    },
    showHeaders (Event) {
      Object.keys(this.summaryTableRowDataShow).forEach(e => {
        const filteritem = e.toLowerCase()
        if (Event.includes(e)) {
          this.summaryTableRowDataShow[e] = true
          this.responseHeadersMap[filteritem].disabled = false
        } else {
          this.summaryTableRowDataShow[e] = false
          this.responseHeadersMap[filteritem].disabled = true
        }
      })
      this.createshowHeaders()
      // return this.headers.filter(s => this.selectedHeaders.includes(s));
    },
    createshowHeaders () {
      this.responseHeaders = Object.values(this.responseHeadersMap)
      this.showResponseHeaders = this.responseHeaders.filter((element, index, arr) => {
        return element.disabled === false
      })
    },
    createTableShowlist () {
      // filter Hearders
      this.responseHeaders.filter((element, index, arr) => {
        return element.disabled === true
      }).forEach((item) => {
        this.summaryTableShowList.push(item.text)
        this.summaryTableRowDataShow[item.text] = false
      })
    },
    cancelSerialNumberListItem () {
      this.SerialNumberListSelect = []
    },
    selectAllSerialNumberListItem () {
      this.SerialNumberListSelect = this.SerialNumberList
    },
    resetSerialNumberListItem () {
      this.SerialNumberList = []
      this.SerialNumberListSelect = []
    },
    checkTableShowListClear () {
      this.checkTableShowList = []
      this.showHeaders([])
    },
    checkTableShowListAll () {
      this.checkTableShowList = []
      this.summaryTableShowList.forEach((s) => {
        this.checkTableShowList.push(s)
      })
      this.showHeaders(this.summaryTableShowList)
    },
    SerialNumberListOver100 (tempSerialNumbers) {
      const yes = confirm('輸入查詢已超過 100 筆上限, 為避免影響瀏覽器效能, 是否直接查詢？')

      if (yes) {
        this.SerialNumberListSelect = []
        this.tempSerialNumberList = tempSerialNumbers
        this.doSubmit(true, 1)
      } else {
        this.SerialNumberList = tempSerialNumbers
        this.SerialNumberListSelect = this.SerialNumberInput.split(',').concat(this.SerialNumberListSelect)
        this.SerialNumberListSelect = this.SerialNumberListSelect.filter((element, index, arr) => {
          return arr.indexOf(element) === index
        })
      }
    },
    addDeviceSnListItem () {
      let tempSerialNumbers = []
      if (this.SerialNumberInput.length > 0) {
        tempSerialNumbers = this.SerialNumberInput.split(',').concat(this.SerialNumberList)
        tempSerialNumbers = tempSerialNumbers.filter((element, index, arr) => {
          return arr.indexOf(element) === index
        })
        if (tempSerialNumbers.length > 100) {
          this.SerialNumberListOver100(tempSerialNumbers)
        } else {
          this.SerialNumberList = tempSerialNumbers
          this.SerialNumberListSelect = this.SerialNumberInput.split(',').concat(this.SerialNumberListSelect)
          this.SerialNumberListSelect = this.SerialNumberListSelect.filter((element, index, arr) => {
            return arr.indexOf(element) === index
          })
        }
        this.SerialNumberInput = ''
      } else {
        alert('請輸入序號')
      }
    },
    ImportSerialNumberListItem () {
      if (this.responseExcelToListData.serial_no.length > 100) {
        this.SerialNumberListOver100(this.responseExcelToListData.serial_no)
      } else {
        this.SerialNumberList = this.responseExcelToListData.serial_no.concat(this.SerialNumberList)
        this.SerialNumberList = this.SerialNumberList.filter((element, index, arr) => {
          return arr.indexOf(element) === index
        })
        this.SerialNumberListSelect = this.responseExcelToListData.serial_no.concat(this.SerialNumberListSelect)
        this.SerialNumberListSelect = this.SerialNumberListSelect.filter((element, index, arr) => {
          return arr.indexOf(element) === index
        })
      }
    },
    conversionExcelFieldsToList () {
      if (this.file[0].file) {
        this.processingView = true
        const formData = new FormData()
        formData.append('file', this.file[0].file)
        formData.append('sourceFields', ['serial_no'])
        this.$http.post('/api/pqm/cfrAnalysisMgmt/parseExcelFieldsToList', formData).then(res => res.data)
          .then(res => {
            this.processingView = false
            this.cancelFile()
            if (res.status === 'success') {
              this.responseExcelToListData = res.data
              setTimeout(() => {
                this.ImportSerialNumberListItem()
              }, 0)
            } else {
              this.snackbar = true
              this.snackbarColor = 'red'
              this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
            }
          })
      } else {
        this.processingView = false
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '檔名或檔案不得為空'
      }
    },
    uploadFile (type) {
      $('#upload-file').click()
    },
    cancelFile () {
      this.file[0].file = this.file[0].name = this.file[0].url = ''
      $('#upload-file').val('')
    },
    setFile (e) {
      const files = e.target.files || e.dataTransfer.files
      this.file[0].url = window.URL.createObjectURL(e.srcElement.files.item(0))
      this.file[0].file = files[0]
      this.file[0].name = e.srcElement.files.item(0).name
      setTimeout(() => {
        this.conversionExcelFieldsToList()
      }, 0)
    },
    doSampleDownload () {
      this.processingView = true
      this.$http.get('/api/keypart/battery/batterySampleSnListFile', { responseType: 'blob' })
        .then(res => {
          this.processingView = false
          if (res.data.type === 'application/json') {
            return new Promise((resolve, reject) => {
              const reader = new FileReader()
              reader.onload = () => {
                res.data = JSON.parse(reader.result)
                if (res.data.status === 'success') {
                  alert(res.data.data)
                } else {
                  this.snackbar = true
                  this.snackbarColor = 'red'
                  this.snackbarMsg = res.data.errMsg + ' (' + res.data.errCode + ')'
                }
              }
              reader.onerror = () => {
                reject(res.data.errMsg || res.data.data)
              }
              reader.readAsText(res.data)
            })
          } else if (res.data.status !== 'failure' && res.headers['content-disposition']) {
            const blob = new Blob([res.data], { type: 'application/octet-stream' })
            const url = window.URL.createObjectURL(blob)
            const fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]
            const downloadLink = document.createElement('a')
            downloadLink.href = url
            downloadLink.download = fileName

            document.body.appendChild(downloadLink)
            downloadLink.click()
            document.body.removeChild(downloadLink)
          } else {
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = '無檔案可供下載'
          }
        })
        .catch(err => {
          this.processingView = false
          this.snackbar = true
          this.snackbarColor = 'orange'
          this.snackbarMsg = err
        })
    },
    doSubmit (firstquery, selectPag) {
      const _snList = (this.SerialNumberListSelect.length === 0) ? this.tempSerialNumberList : this.SerialNumberListSelect

      if (_snList.length === 0) {
        return
      }

      if (firstquery) {
        this.pagination.page = 1
      } else {
        console.log('this.pagination.page', this.pagination.page)
      }

      const body = {
        pageNum: 1,
        pageRows: this.perPageRows === 'ALL' ? 9999999 : _snList.length,
        sortColumn: null,
        sortType: null,
        snList: _snList
      }
      const _startPage = this.getStartPage(selectPag)
      const _endPage = this.getEndPage(selectPag)
      if (selectPag === 1 && firstquery) {
        this.loadingView = true
        this.$http.post('/api/keypart/battery/batteryManufacturingRawDataList', body).then(res => res.data)
          .then(res => {
            if (res.status === 'success') {
              this.loadingView = false
              if (res.data.totalRows > 0) {
                this.queryShow = true
                res.data.pagingList.forEach(e => {
                  e.selected = false
                })
                this.Alldesserts = JSON.parse(JSON.stringify(res.data.pagingList))
                this.desserts = res.data.pagingList.slice(_startPage, _endPage)

                if (firstquery) {
                  this.pages = Math.ceil(res.data.totalRows / this.perPageRows)
                }
              } else {
                this.snackbar = true
                this.snackbarColor = 'red'
                this.snackbarMsg = '目前暫無資料。'
              }
            } else {
              this.loadingView = false
              this.snackbar = true
              this.snackbarColor = 'red'
              this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
            }
          })
      } else {
        this.allslectflg = false
        // let _startPage = this.getStartPage(selectPag)
        // let _endPage = this.getEndPage(selectPag)
        this.desserts = JSON.parse(JSON.stringify(this.Alldesserts))
        // this.desserts = this.desserts.slice(_startPage, _endPage)
        setTimeout(() => {
          this.pagination.page = selectPag
        }, 500)
      }
    },
    getStartPage (Page) {
      const _snList = (this.SerialNumberListSelect.length === 0) ? this.tempSerialNumberList : this.SerialNumberListSelect
      let _end = 0
      if (Page > 1) {
        _end = ((Page - 1) * this.perPageRows)
      }
      if (_end > _snList.length) {
        _end = _snList
      }

      return _end
    },
    getEndPage (Page) {
      const _snList = (this.SerialNumberListSelect.length === 0) ? this.tempSerialNumberList : this.SerialNumberListSelect
      const _max = _snList.length
      let _end = Page * this.perPageRows
      if (Page > 1) {
        _end = _end - 1
      }
      let resultPage = _end
      if (this.perPageRows === 'ALL' || _end > _max) {
        resultPage = _max
      }
      return resultPage
    },
    doGroupDownload (downloadtype) {
      // type = 0 =>ALL type = 1 =>Group
      const _checkList = []
      if (downloadtype === 0) {
        console.log('this.Alldesserts', this.Alldesserts)
        this.Alldesserts.forEach(e => {
          _checkList.push(e.serialNumber)
        })
      } else {
        this.Alldesserts.forEach(e => {
          if (e.selected) {
            _checkList.push(e.serialNumber)
          }
        })
      }

      if (_checkList.length > 0) {
        this.processingView = true
        const body = {
          snList: _checkList
        }

        this.$http.post('/api/keypart/battery/batteryManufacturingRawDataFile', body, { responseType: 'blob' })
          .then(res => {
            this.processingView = false
            if (res.data.type === 'application/json') {
              return new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.onload = () => {
                  res.data = JSON.parse(reader.result)
                  if (res.data.status === 'success') {
                    alert(res.data.data)
                    // resolve(Promise.reject(res.data.data))
                  } else {
                    this.snackbar = true
                    this.snackbarColor = 'red'
                    this.snackbarMsg = res.data.errMsg + ' (' + res.data.errCode + ')'
                  }
                }
                reader.onerror = () => {
                  reject(res.data.data)
                }
                reader.readAsText(res.data)
              })
            } else if (res.data.status !== 'failure' && res.headers['content-disposition']) {
              console.log(res)
              const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' })
              const url = window.URL.createObjectURL(blob)
              const fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]
              const downloadLink = document.createElement('a')
              downloadLink.href = url
              downloadLink.download = fileName
              console.log('url', url)
              document.body.appendChild(downloadLink)
              downloadLink.click()
              document.body.removeChild(downloadLink)
            } else {
              this.snackbar = true
              this.snackbarColor = 'red'
              this.snackbarMsg = '下載失敗，請通知系統管理員'
            }
          })
          .catch(err => {
            this.processingView = false
            this.snackbar = true
            this.snackbarColor = 'orange'
            this.snackbarMsg = err
          })
      } else {
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '無選擇資料'
      }
    }
  }
}
</script>
<style>
</style>
