import { set, toggle } from '@/utils/vuex'

export default {
  setDemo: set('demo'),
  setDrawer: set('drawer'),
  setImage: set('image'),
  setColor: set('color'),
  setTheme: set('theme'),
  setShrink: set('shrink'),
  toggleDrawer: toggle('drawer')
}
