<template>
  <v-container
    fundamental
    fluid
    grid-list-xl
    pt-0
  >
    <v-layout wrap>
      <!-- <v-flex
        xs12
        lg12
      >
        <material-card
          title="工廠篩選"
          class="card-padding v-card__first"
          color="general"
        >
          <v-form>
            <v-container
              py-0
              class="mw-100">
              <v-layout wrap>
                <v-flex
                  xs12
                  lg3
                  text-xs-right
                >
                  <v-btn
                    class="mx-0 font-weight-light"
                    color="general"
                    @click="doSubmit()"
                  >
                    <v-icon class="mr-2">mdi-backup-restore</v-icon>查詢
                  </v-btn>
                  <v-btn
                    class="mr-0 ml-3 font-weight-light"
                    color="pink"
                    @click="doAddWindows()"
                  >
                    <v-icon class="mr-2">mdi-plus</v-icon>新增
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
      </v-flex>-->
      <v-flex
        v-if="!processingView"
        flex_table_width_auto
        xs12
        lg12>
        <material-card
          id="checkTable"
          :style="'max-width: calc(100vw - '+(Number(shrink)+32)+'px);'"
          title="各模組聯絡人資訊"
          color="deep-orange"
          class="card-mix d-table-t0 no-data-left"
          style="margin-top: 30px!important;">
          <v-text-field
            v-model="keywordFilter"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            class="mix-input"
            style="right: 15rem;"
            append-icon="search"
            label="關鍵字過濾"
            single-line
            hide-details/>
          <!-- <v-select
            v-model="summary工廠類別"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="summary工廠類別Menu"
            class="mix-list v-text-field__slot d-inline-block float-right ml-5"
            style="width: 90px;"
            persistent-hint
            outlined
          /> -->
          <v-btn
            class="mix-btn mx-0 font-weight-light small"
            @click="doAddWindows()"
          >
            <v-icon class="mr-2">mdi-plus</v-icon>新增共用聯絡人
          </v-btn>
          <v-data-table
            :headers="responseHeaders"
            :items="responseData"
            class="height-row-3 table-align-left"
            multi-sort
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="subheading font-weight-light text-general text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <tr>
                <td class="table-width-200">{{ item.module }}</td>
                <td class="table-width-300">{{ item.description }}</td>
                <td style="padding: 10px!important;">
                  <span v-show="keywordFilter!==''">符合過濾的結果：</span>
                  <v-chip
                    v-for="(user,u) in item.userList"
                    v-show="keywordFilter===''||(user.userName.toUpperCase().search(keywordFilter.toUpperCase())!==-1||user.userMail.toUpperCase().search(keywordFilter.toUpperCase())!==-1)"
                    :key="'u'+u"
                    class="cursor"
                    style="margin:4px!important;"
                    small
                    @click="doEditWindows(user.pk, user.userName, user.userMail, user.userType, item.module)">{{ user.userName }} ｜ {{ user.userMail }}</v-chip>
                </td>
                <td>
                  <div>
                    <v-btn
                      class="ml-0 mr-2 font-weight-bold small"
                      color="deep-purple"
                      @click="doAddWindows(item.module)"
                    >
                      <v-icon class="mr-2">mdi-push</v-icon>新增
                    </v-btn>
                    <!-- <v-btn
                      :disabled="item.processing"
                      class="mx-0 font-weight-bold small"
                      color="orange"
                      @click="doUploadProductDataJob(item.code)">
                      <v-icon class="mr-2">mdi-file-replace-outline</v-icon>轉檔
                    </v-btn> -->
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </material-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="windowsForAddOrEdit"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <v-card
        max-width="25%"
        style="margin:0 auto;">
        <v-card-text>
          <v-alert
            v-model="windowsForAddOrEdit"
            class="mt-3 mb-3 border-0"
            color="deep-orange">
            <strong class="h4">{{ windows.module?windows.module:"全" }}模組通知維護</strong>
          </v-alert>
          <v-flex
            v-if="action==='add'&&windows.module"
            d-flex
            xs12
            lg12>
            <v-radio-group
              v-model="windows.userType"
              class="align-items-center"
              row>
              <v-radio
                label="新增內部聯絡人"
                value="internal"/>
              <v-radio
                label="新增外部聯絡人"
                value="external"/>
            </v-radio-group>
          </v-flex>
          <v-flex
            v-if="windows.userType==='external'"
            flex-small
            d-flex
            align-items-center
            xs12
            lg12>
            <p class="m-0 w-25">姓名</p>
            <v-text-field
              v-model="windows.name"
              :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
              placeholder="輸入姓名..."
              label=""
              class="w-75"
            />
          </v-flex>
          <v-flex
            v-if="windows.userType==='external'"
            flex-small
            d-flex
            align-items-center
            xs12
            lg12>
            <p class="m-0 w-25">信箱</p>
            <v-text-field
              v-model="windows.mail"
              :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
              placeholder="輸入信箱..."
              label=""
              class="w-75"
            />
          </v-flex>
          <v-flex
            v-if="action==='add'&&windows.userType==='internal'"
            flex-small
            d-flex
            align-items-center
            xs12
            lg12>
            <p class="m-0 w-25">姓名</p>
            <v-autocomplete
              v-model="windows.index"
              :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
              :items="notificationUserNameMenu"
              placeholder="選擇姓名..."
              label=""
              class="v-text-field__slot w-75"
              persistent-hint
              outlined
            />
          </v-flex>
          <v-flex
            v-if="action==='add'&&windows.userType==='internal'"
            flex-small
            d-flex
            align-items-center
            xs12
            lg12>
            <p class="m-0 w-25">信箱</p>
            <v-autocomplete
              v-model="windows.index"
              :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
              :items="notificationUserMailMenu"
              placeholder="選擇信箱..."
              label=""
              class="v-text-field__slot w-75"
              persistent-hint
              outlined
            />
          </v-flex>
          <v-flex
            v-if="action==='edit'&&windows.userType==='internal'"
            flex-small
            d-flex
            align-items-center
            xs12
            lg12>
            <p class="m-0 w-25">姓名</p>
            <v-text-field
              v-model="windows.name"
              :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
              placeholder="輸入姓名..."
              label=""
              class="w-75"
              disabled
            />
          </v-flex>
          <v-flex
            v-if="action==='edit'&&windows.userType==='internal'"
            flex-small
            d-flex
            align-items-center
            xs12
            lg12>
            <p class="m-0 w-25">信箱</p>
            <v-text-field
              v-model="windows.mail"
              :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
              placeholder="輸入信箱..."
              label=""
              class="w-75"
              disabled
            />
          </v-flex>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="grey"
            small
            @click="windowsForAddOrEdit=false">
            <v-icon class="mr-2">mdi-close</v-icon>返回
          </v-btn>
          <v-btn
            v-if="action==='add'"
            :disabled="!((windows.userType==='external'&&windows.name!=='' && windows.mail!=='') || (windows.userType==='internal'&&windows.index!==''))"
            color="blue"
            small
            @click="doAdd()">
            <v-icon class="mr-2">mdi-plus</v-icon>新增
          </v-btn>
          <v-btn
            v-if="action==='edit'&&windows.userType==='external'"
            :disabled="windows.name==='' || windows.mail===''"
            color="blue"
            small
            @click="doEdit()">
            <v-icon class="mr-2">mdi-content-save</v-icon>儲存
          </v-btn>
          <v-btn
            v-if="action==='edit'"
            color="red"
            small
            @click="doDelete()">
            <v-icon class="mr-2">mdi-delete</v-icon>刪除
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="processingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        PROCESSING 處理中
      </p>
    </v-dialog>
    <v-dialog
      v-model="loadingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :top="true"
      :color="snackbarColor">
      {{ snackbarMsg }}
      <v-btn
        dark
        flat
        @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import Loading from '@/assets/img/loading.gif'

export default {
  name: 'NotificationListMaintain',
  data () {
    return {
      action: 'add',
      windowsForAddOrEdit: false,
      snackbar: false,
      snackbarColor: 'red',
      snackbarMsg: '',
      Loading,
      loadingView: false,
      processingView: false,
      notificationModuleDescription: [],
      notificationUserNameMenu: [],
      notificationUserMailMenu: [],
      windows: {
        pk: 0,
        index: null,
        name: '',
        mail: '',
        userType: 'internal',
        module: ''
      },
      keywordFilter: '',
      responseData: [],
      responseHeaders: [
        {
          align: '',
          class: '',
          sortable: true,
          text: '模組',
          value: 'module'
        }, {
          align: '',
          class: '',
          sortable: true,
          text: 'Description',
          value: 'description'
        }, {
          align: '',
          class: '',
          sortable: false,
          text: '聯絡人',
          value: 'userList'
        }, {
          align: '',
          class: '',
          sortable: false,
          text: '操作',
          value: 'operation'
        }
      ]
    }
  },
  computed: {
    ...mapState('app', ['theme', 'shrink'])
  },
  watch: {},
  mounted () {
    this.$store.dispatch('getAuthorize', '/system/notificationListMaintainView')
      .then(response => {
        if (response.status === 'success' && response.data.accessible) {
          this.loadData()
        } else if (response.errCode === 'E10000002') {
          const routeData = this.$router.resolve({ path: '/noEnter/E10000002' })
          localStorage.setItem('errormsg', response.errMsg)
          window.location.assign(routeData.href)
        } else {
          const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
          window.location.assign(routeData.href)
        }
      })
      .catch(err => {
        console.log(err)
        const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
        window.location.assign(routeData.href)
      })
  },
  methods: {
    async loadData () {
      await this.getNotificationModuleDescription()
      await this.getUserMail()
      await this.doSubmit()
    },
    getNotificationModuleDescription () {
      this.$http.get('/api/system/moduleNotificationList/moduleNotificationDefinitionList').then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.notificationModuleDescription = res.data
          }
        })
    },
    getUserMail () {
      this.$http.get('/api/system/moduleNotificationList/userMail').then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            res.data.forEach((n, nn) => {
              this.notificationUserNameMenu.push({ text: n.name, value: nn })
              this.notificationUserMailMenu.push({ text: n.email, value: nn })
            })
          }
        })
    },
    doAddWindows (module) {
      this.action = 'add'
      this.windows = {
        pk: 0,
        index: null,
        name: '',
        mail: '',
        userType: 'internal',
        module: module
      }
      this.windowsForAddOrEdit = true
    },
    doEditWindows (pk, name, mail, type, module) {
      this.action = 'edit'
      this.windows = {
        pk: pk,
        index: null,
        name: name,
        mail: mail,
        userType: type,
        module: module
      }
      this.windowsForAddOrEdit = true
    },
    doSubmit () {
      this.loadingView = true
      this.$http.get('/api/system/moduleNotificationList/notificationList').then(res => res.data)
        .then(res => {
          this.loadingView = false
          if (res.status === 'success') {
            this.responseData = []
            let description = ''
            res.data.forEach((n) => {
              if (this.responseData.map((e) => { return e.module }).indexOf(n.module) === -1) {
                if (this.notificationModuleDescription.map((e) => { return e.module }).indexOf(n.module) !== -1) {
                  description = this.notificationModuleDescription[this.notificationModuleDescription.map((e) => { return e.module }).indexOf(n.module)].description
                } else {
                  description = ''
                }
                this.responseData.push({ module: n.module, description: description, userList: [] })
              }
              this.responseData[this.responseData.map((e) => { return e.module }).indexOf(n.module)].userList.push({ pk: n.pk, userName: n.userName, userMail: n.userMail, userType: n.userType })
            })
          }
        })
    },
    doAdd () {
      if (this.windows.userType === 'external') {
        const regexEmail = /^([a-z0-9_.-]+)@([\da-z.-]+)\.([a-z]{2,6})$/
        if (!regexEmail.test(this.windows.mail.replace(/(^[\s]*)|([\s]*$)/g, ''))) {
          this.snackbar = true
          this.snackbarColor = 'red'
          this.snackbarMsg = '信箱格式輸入錯誤'
          return
        } else {
          this.windows.mail = this.windows.mail.replace(/(^[\s]*)|([\s]*$)/g, '')
        }
      }
      this.windowsForAddOrEdit = false
      this.processingView = true
      let body = {}
      if (this.windows.userType === 'internal') {
        body = {
          pk: this.windows.pk,
          name: this.notificationUserNameMenu[this.windows.index].text,
          mail: this.notificationUserMailMenu[this.windows.index].text,
          userType: this.windows.userType,
          module: this.windows.module
        }
      } else {
        body = {
          pk: this.windows.pk,
          name: this.windows.name,
          mail: this.windows.mail,
          userType: this.windows.userType,
          module: this.windows.module
        }
      }
      if (this.windows.module) {
        this.$http.post('/api/system/moduleNotificationList/notificationUser', body).then(res => res.data)
          .then(res => {
            this.processingView = false
            if (res.status === 'success') {
              this.snackbar = true
              this.snackbarColor = 'green'
              this.snackbarMsg = '新增成功'
              this.doSubmit()
            } else { // failure
              this.windowsForAddOrEdit = true
              this.snackbar = true
              this.snackbarColor = 'red'
              this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
            }
          })
      } else {
        this.$http.post('/api/system/moduleNotificationList/notificationSystemUser', body).then(res => res.data)
          .then(res => {
            this.processingView = false
            if (res.status === 'success') {
              this.snackbar = true
              this.snackbarColor = 'green'
              this.snackbarMsg = '新增成功'
              this.doSubmit()
            } else { // failure
              this.windowsForAddOrEdit = true
              this.snackbar = true
              this.snackbarColor = 'red'
              this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
            }
          })
      }
    },
    doEdit () {
      if (this.windows.userType === 'external') {
        const regexEmail = /^([a-z0-9_.-]+)@([\da-z.-]+)\.([a-z]{2,6})$/
        if (!regexEmail.test(this.windows.mail.replace(/(^[\s]*)|([\s]*$)/g, ''))) {
          this.snackbar = true
          this.snackbarColor = 'red'
          this.snackbarMsg = '信箱格式輸入錯誤'
          return
        } else {
          this.windows.mail = this.windows.mail.replace(/(^[\s]*)|([\s]*$)/g, '')
        }
      }
      this.windowsForAddOrEdit = false
      this.processingView = true
      const body = {
        pk: this.windows.pk,
        name: this.windows.name,
        mail: this.windows.mail,
        userType: this.windows.userType,
        module: this.windows.module
      }
      this.$http.put('/api/system/moduleNotificationList/notificationUser', body).then(res => res.data)
        .then(res => {
          this.processingView = false
          if (res.status === 'success') {
            this.snackbar = true
            this.snackbarColor = 'green'
            this.snackbarMsg = '修改成功'
            this.doSubmit()
          } else { // failure
            this.windowsForAddOrEdit = true
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
          }
        })
    },
    doDelete () {
      this.windowsForAddOrEdit = false
      this.processingView = true
      this.$http.delete(`/api/system/moduleNotificationList/notificationUser/${this.windows.pk}`).then(res => res.data)
        .then(res => {
          this.processingView = false
          if (res.status === 'success') {
            this.snackbar = true
            this.snackbarColor = 'green'
            this.snackbarMsg = '刪除成功'
            this.doSubmit()
          } else { // failure
            this.windowsForAddOrEdit = true
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
          }
        })
    }
  }
}
</script>
<style>
</style>
