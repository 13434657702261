<template>
  <v-container
    fluid
    grid-list-xl
    pt-0
  >
    <v-layout wrap>
      <v-flex
        xs12
      >
        <material-card
          class="card-shrink card-padding v-card__first"
          color="general"
          title="使用者帳號搜尋"
        >
          <v-form>
            <v-container
              py-0
              class="mw-100">
              <v-layout wrap>
                <v-flex
                  xs12
                  sm6
                  lg3>
                  <v-autocomplete
                    v-model="summaryDept"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryDeptMenu"
                    label="所屬部門"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex
                  xs12
                  sm6
                  lg2>
                  <v-text-field
                    v-model="summaryUserName"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    label="使用者姓名"/>
                </v-flex>
                <v-flex
                  xs12
                  sm6
                  lg3>
                  <v-text-field
                    v-model="summaryUserEmail"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    label="使用者信箱"/>
                </v-flex>
                <v-flex
                  xs12
                  sm6
                  lg2>
                  <v-autocomplete
                    v-model="summaryGroup"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryGroupMenu"
                    label="所屬群組"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex
                  xs12
                  lg2
                  text-xs-right
                >
                  <v-btn
                    class="mx-0 font-weight-light"
                    color="blue"
                    @click="doSubmit()"
                  >
                    <v-icon class="mr-2">mdi-backup-restore</v-icon>查詢
                  </v-btn>
                  <!-- <v-btn
                    class="mr-0 ml-3 font-weight-light"
                    color="pink"
                    @click="doAdd()"
                  >
                    <v-icon class="mr-2">mdi-plus</v-icon>新增使用者
                  </v-btn> -->
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
      </v-flex>
      <v-flex
        flex_width_auto
        xs12
      >
        <material-card
          :title="'使用者帳號列表'"
          color="deep-orange"
          class="card-mix card-padding pt-0">
          <v-select
            v-model="perPageRows"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="rowsAmount"
            class="mix-list v-text-field__slot d-inline-block float-right ml-5"
            style="width: 90px;"
            persistent-hint
            outlined
          />
          <!-- <material-card class="d-inline-block"> -->
          <v-data-table
            :headers="headers"
            :items="responseDataTable"
            :pagination.sync="pagination"
            :rows-per-page-items ="rowsAmount"
            multi-sort
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="subheading font-weight-light text-general text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <!-- <td class="table-width-125">{{ item.code }}</td> -->
              <td class="table-width-125">{{ item.name }}</td>
              <td class="table-width-225">{{ item.email }}</td>
              <td class="table-width-250">{{ item.dept }}</td>
              <td class="table-width-150">{{ item.groupList.join() }}</td>
              <td class="table-width-105">
                <v-btn
                  class="mx-0 mr-3 font-weight-light small"
                  color="teal"
                  @click="doUserGroupEdit(item.pk, item.name, item.dept)">
                  <v-icon class="mr-2">mdi-account-group</v-icon>群組調整
                </v-btn>
                <!-- <v-btn
                  class="mx-0 mr-3 font-weight-light small"
                  color="deep-purple"
                  @click="doUserEdit(item.pk)">
                  <v-icon class="mr-2">mdi-pencil</v-icon>編輯帳號
                </v-btn> -->
                <v-btn
                  v-if="item.status === 2"
                  class="mx-0 font-weight-light small text-warning"
                  color="black"
                  @click="doAuthority(item.pk, '停用',3)">
                  <v-icon class="mr-2">mdi-block-helper</v-icon>停用帳號
                </v-btn>
                <v-btn
                  v-if="item.status === 3"
                  class="mx-0 font-weight-light small"
                  color="pink"
                  @click="doAuthority(item.pk, '啟用', 2)">
                  <v-icon class="mr-2">mdi-check</v-icon>啟用帳號
                </v-btn>
              </td>
            </template>
          </v-data-table>
          <div
            v-if="pagination.rowsPerPage != -1"
            class="text-xs-center pt-2 d-inline-block"
            style="margin-left: 0px;width: calc(100% - 140px);">
            <v-pagination
              v-model="pagination.page"
              :length="pages"/>
          </div>
          <v-autocomplete
            v-if="pagination.rowsPerPage != -1"
            v-model="pagination.page"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="pageslist"
            class="v-text-field__slot d-inline-block float-right mr-0"
            style="width: 90px;"
            persistent-hint
            outlined
          />
        </material-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-if="action==='group'"
      v-model="editUserGroupView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <v-card
        max-width="85%"
        min-heigh="400px"
        style="margin:0 auto;">
        <v-card-text>
          <v-alert
            v-model="editUserGroupView"
            class="mt-3 mb-3 border-0"
            color="deep-orange">
            <strong class="h4">{{ editUserGroupViewName }}({{ editUserGroupViewDept }})群組調整</strong>
          </v-alert>
          <v-container class="p-0 mw-100">
            <v-layout wrap>
              <v-flex xs5>
                <v-alert
                  v-model="editUserGroupView"
                  class="m-0 px-2 py-1 border-0"
                  color="blue-grey">
                  <strong class="h5">所屬群組</strong>
                  <input
                    v-model="filterUserGroupName"
                    :style="theme==='dark'?'background:#000;color:#fff;':theme==='light'?'background:#fff;color:#000;':''"
                    placeholder="篩選群組"
                    class="v-label row-btn-350 rounded p-1 float-right"
                    type="text">
                  <v-btn
                    color="indigo"
                    class="my-0 p-1 float-right"
                    @click="doViewGroupPrivilege(userGroupPrivilege, editUserGroupViewName+'('+editUserGroupViewDept+')')">
                    <v-icon class="mr-1">mdi-eye</v-icon>檢視現有權限
                  </v-btn>
                </v-alert>
                <div
                  :style="theme==='dark'?'background:#333;':theme==='light'?'background:#bbb;':''"
                  style="height: calc(100vh - 260px - 300px);min-height: 160px;overflow-y: auto;margin-top: 0.5rem;margin-bottom: 0.25rem;">
                  <div
                    v-for="(u_list, index) in responseDataUserGroup"
                    v-show="filterUserGroupName==='' ? true : (u_list.name.toUpperCase().indexOf(filterUserGroupName.toUpperCase()) != -1)"
                    :key="'u_list'+index"
                    :class="{'list-item-click' : responseDataUserGroupClick.findIndex(function checkGroup (g) { return g === u_list.value }) > -1}"
                    class="list-item-area">
                    <span
                      @click="doClickUserGroup(u_list.value)"
                      @dblclick="doGroupUserToAvailable('alone', u_list.value)">
                      {{ u_list.name }}
                    </span>
                    <v-btn
                      color="indigo"
                      class="list-item-btn float-right mt-1"
                      @click="doViewGroupPrivilege([u_list.value], u_list.name)">
                      檢視權限
                    </v-btn>
                  </div>
                </div>
                <v-btn
                  color="blue ml-0"
                  small
                  class=""
                  @click="doClickUserGroup('all')">
                  選擇全部
                </v-btn>
                <v-btn
                  color="red mr-0"
                  small
                  class=""
                  @click="doClickUserGroup('reset')">
                  取消選擇
                </v-btn>
              </v-flex>
              <v-flex
                xs2
                style="text-align: center;padding: 90px 0 0 0 !important;">
                <v-btn
                  color="black"
                  style="margin: calc((100vh - 400px) / 16) 10px;"
                  @click="doGroupAvailableToUser('array')">
                  ＜＜＜新增
                </v-btn>
                <v-btn
                  color="black"
                  style="margin: calc((100vh - 400px) / 16) 10px;"
                  @click="doGroupUserToAvailable('array')">
                  移除＞＞＞
                </v-btn>
              </v-flex>
              <v-flex xs5>
                <v-alert
                  v-model="editUserGroupView"
                  class="m-0 px-2 py-1 border-0"
                  color="blue-grey">
                  <strong class="h5">可選群組</strong>
                  <input
                    v-model="filterAvailableGroupName"
                    :style="theme==='dark'?'background:#000;color:#fff;':theme==='light'?'background:#fff;color:#000;':''"
                    placeholder="篩選群組"
                    class="v-label row-btn-350 rounded p-1 float-right"
                    type="text">
                  <router-link
                    :to="'groupManagementSetting/add'"
                    target="_blank">
                    <v-btn
                      color="pink"
                      class="my-0 p-1 float-right"
                      @click="doUserGroupEdit()">
                      <v-icon class="mr-0">mdi-plus</v-icon>新增群組
                    </v-btn>
                  </router-link>
                </v-alert>
                <div
                  :style="theme==='dark'?'background:#333;':theme==='light'?'background:#bbb;':''"
                  style="height: calc(100vh - 260px - 300px);min-height: 160px;overflow-y: auto;margin-top: 0.5rem;margin-bottom: 0.25rem;">
                  <div
                    v-for="(a_list, index) in responseDataAvailableGroup"
                    v-show="filterAvailableGroupName==='' ? true : (a_list.name.toUpperCase().indexOf(filterAvailableGroupName.toUpperCase()) != -1)"
                    :key="'a_list'+index"
                    :class="{'list-item-click' : responseDataAvailableGroupClick.findIndex(function checkGroup (g) { return g === a_list.value }) > -1}"
                    class="list-item-area">
                    <span
                      @click="doClickAvailableGroup(a_list.value)"
                      @dblclick="doGroupAvailableToUser('alone', a_list.value)">
                      {{ a_list.name }}
                    </span>
                    <v-btn
                      color="indigo"
                      class="list-item-btn float-right mt-1"
                      @click="doViewGroupPrivilege([a_list.value], a_list.name)">
                      檢視權限
                    </v-btn>
                  </div>
                </div>
                <v-btn
                  color="blue ml-0"
                  small
                  class=""
                  @click="doClickAvailableGroup('all')">
                  選擇全部
                </v-btn>
                <v-btn
                  color="red mr-0"
                  small
                  class=""
                  @click="doClickAvailableGroup('reset')">
                  取消選擇
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions class="border-top border-secondary">
          <v-spacer/>
          <v-btn
            color="red"
            small
            @click="editUserGroupView=false;action=''">
            <v-icon class="mr-2">mdi-close</v-icon>返回
          </v-btn>
          <v-btn
            color="lime"
            small
            class="text-dark"
            @click="doUserGroupEdit(editUserGroupViewPk, editUserGroupViewName, editUserGroupViewDept)">
            <v-icon class="mr-2">mdi-replay</v-icon>重置
          </v-btn>
          <v-btn
            color="blue"
            small
            @click="doSaveGroupPrivilege(editUserGroupViewPk)">
            <v-icon class="mr-2">mdi-content-save</v-icon>儲存
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="viewGroupPrivilege"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <v-card
        max-width="25%"
        style="margin:0 auto;">
        <v-card-text>
          <v-alert
            v-model="viewGroupPrivilege"
            class="mt-3 mb-3 border-0"
            color="deep-orange">
            <strong class="h4">{{ viewGroupPrivilegeName }} 群組權限一覽</strong>
          </v-alert>
          <ul>
            <li
              v-for="(n, index) in responseDataGroupPrivilege"
              :key="'n'+index">
              {{ n.text }}
              <ul v-if="n.children.length > 0">
                <li
                  v-for="nn in n.children"
                  :key="'nn'+nn.text">
                  {{ nn.text }}
                </li>
              </ul>
            </li>
          </ul>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="red"
            small
            @click="viewGroupPrivilege=false">
            <v-icon class="mr-2">mdi-close</v-icon>關閉
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="processingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        PROCESSING 處理中
      </p>
    </v-dialog>
    <v-dialog
      v-model="loadingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :color="snackbarColor"
      :top="true">
      {{ snackbarMsg }}
      <v-btn
        flat
        @click="snackbar = false">
        關閉
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import Loading from '@/assets/img/loading.gif'

export default {
  name: 'UserManagementSetting',
  components: {
  },
  data () {
    return {
      snackbar: false,
      snackbarColor: 'green',
      snackbarMsg: '',
      Loading,
      loadingView: false,
      processingView: false,
      action: '',
      summaryDeptMenu: [],
      summaryGroupMenu: [],
      summaryDept: 'All',
      summaryUserName: '',
      summaryUserEmail: '',
      summaryGroup: 'All',
      filterUserGroupName: '',
      filterAvailableGroupName: '',
      responseDataTable: [],
      responseDataAvailableGroup: [],
      responseDataAvailableGroupClick: [],
      responseDataUserGroup: [],
      responseDataUserGroupClick: [],
      responseDataGroupPrivilege: [],
      userGroupPrivilege: [],
      editUserGroupView: false,
      editUserGroupViewPk: '',
      editUserGroupViewName: '',
      editUserGroupViewDept: '',
      editUserGroupViewNumber: '',
      viewGroupPrivilege: false,
      viewGroupPrivilegeName: '',
      editUserView: false,
      checkList: [],
      // pagination: {
      //   rowsPerPage: 1,
      //   sortBy: 'pk', // The field that you're sorting by
      //   descending: true
      // },
      rowsAmount: [25, 50, 100, { text: 'All', value: -1 }],
      perPageRows: 25,
      pagination: {
        page: 1,
        rowsPerPage: 25,
        sortBy: '',
        descending: false
      },
      pages: 0,
      headers: [
        // {
        //   sortable: true,
        //   text: '編號',
        //   value: 'code'
        // },
        {
          sortable: true,
          text: '姓名',
          value: 'name'
        },
        {
          sortable: true,
          text: '信箱',
          value: 'email'
        },
        {
          sortable: true,
          text: '部門',
          value: 'dept'
        },
        {
          sortable: true,
          text: '群組',
          value: 'group'
        },
        {
          sortable: false,
          text: '操作',
          value: 'operation'
        }
      ]
    }
  },
  computed: {
    ...mapState('app', ['theme', 'shrink']),
    pageslist () {
      let i = 0
      const list = []
      if (this.pagination.rowsPerPage) {
        for (i = 0; i < Math.ceil(this.responseDataTable.length / this.pagination.rowsPerPage); i++) {
          list.push(i + 1)
        }
      }
      return list
    }
  },
  watch: {
    responseDataTable (val) {
      this.pages = Math.ceil(this.responseDataTable.length / this.pagination.rowsPerPage)
      if (this.pages > 1) {
        setTimeout(() => {
          this.pagination.page = this.pages
        }, 0)
        setTimeout(() => {
          this.pagination.page = 1
        }, 100)
      } else {
        setTimeout(() => {
          this.pagination.rowsPerPage = 1
        }, 0)
        setTimeout(() => {
          this.pagination.rowsPerPage = this.perPageRows
        }, 100)
      }
    },
    perPageRows (val) {
      if (val === -1) {
        if (confirm('完整顯示將可能導致效能降低，是否仍完整顯示?')) {
          this.pagination.rowsPerPage = val
          this.pages = Math.ceil(this.responseDataTable.length / this.pagination.rowsPerPage)
          this.pagination.page = 1
        } else {
          setTimeout(() => {
            this.perPageRows = this.pagination.rowsPerPage
          }, 100)
        }
      } else {
        this.pagination.rowsPerPage = val
        this.pages = Math.ceil(this.responseDataTable.length / this.pagination.rowsPerPage)
        this.pagination.page = 1
      }
    },
    'pagination.page' (val) {
      this.checkList = []
    },
    'pagination.rowsPerPage' (val) {
      this.checkList = []
    },
    'pagination.sortBy' (val) {
      this.checkList = []
    },
    'pagination.descending' (val) {
      this.checkList = []
    },
    filterUserGroupName (keyword) {
      this.responseDataUserGroupClick = []
    },
    filterAvailableGroupName (keyword) {
      this.responseDataAvailableGroupClick = []
    }
  },
  mounted () {
    this.$store.dispatch('getAuthorize', '/privilege/userManagementSetting')
      .then(response => {
        if (response.status === 'success' && response.data.accessible) {
          if (this.$route.params.action) {
            this.action = this.$route.params.action
          }
          this.$http.get('/api/getSelectOptionData/dept').then(res => res.data)
            .then(res => {
              if (res.status === 'success') {
                this.summaryDeptMenu = [{ text: 'All', value: 'All' }].concat(res.data)
              }
            })
          this.$http.get('/api/getSelectOptionData/group').then(res => res.data)
            .then(res => {
              if (res.status === 'success') {
                this.summaryGroupMenu = [{ text: 'All', value: 'All' }].concat(res.data)
              }
            })
          this.doSubmit()
        } else if (response.errCode === 'E10000002') {
          const routeData = this.$router.resolve({ path: '/noEnter/E10000002' })
          localStorage.setItem('errormsg', response.errMsg)
          window.location.assign(routeData.href)
        } else {
          const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
          window.location.assign(routeData.href)
        }
      })
      .catch(err => {
        console.log(err)
        const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
        window.location.assign(routeData.href)
      })
  },
  methods: {
    doAuthority (pk, status, code) {
      if (confirm('確定' + status + '此帳號?')) {
        this.processingView = true
        const body = {
          pk: pk,
          status: code
        }
        this.$http.post('/api/system/changeUserStatus', body).then(res => res.data)
          .then(res => {
            this.processingView = false
            if (res.status === 'success') {
              this.snackbar = true
              this.snackbarColor = 'green'
              this.snackbarMsg = '狀態變更成功'
              this.doSubmit()
            } else { // failure
              this.snackbar = true
              this.snackbarColor = 'red'
              this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
            }
          }).catch(err => {
            this.processingView = false
            this.snackbar = true
            this.snackbarColor = 'black'
            this.snackbarMsg = err
          })
      }
    },
    doClickAvailableGroup (pk) {
      if (pk === 'all') {
        this.responseDataAvailableGroupClick = []
        this.responseDataAvailableGroup.forEach((g) => {
          if (this.filterAvailableGroupName === '') {
            this.responseDataAvailableGroupClick.push(g.value)
          } else if (g.name.toUpperCase().indexOf(this.filterAvailableGroupName.toUpperCase()) !== -1) {
            this.responseDataAvailableGroupClick.push(g.value)
          }
        })
      } else if (pk === 'reset') {
        this.responseDataAvailableGroupClick = []
      } else {
        if (this.responseDataAvailableGroupClick.findIndex(function checkGroup (g) { return g === pk }) === -1) {
          this.responseDataAvailableGroupClick.push(pk)
        } else {
          this.responseDataAvailableGroupClick.splice(this.responseDataAvailableGroupClick.findIndex(function checkGroup (g) { return g === pk }), 1)
        }
      }
    },
    doClickUserGroup (pk) {
      if (pk === 'all') {
        this.responseDataUserGroupClick = []
        this.responseDataUserGroup.forEach((g) => {
          if (this.filterUserGroupName === '') {
            this.responseDataUserGroupClick.push(g.value)
          } else if (g.name.toUpperCase().indexOf(this.filterUserGroupName.toUpperCase()) !== -1) {
            this.responseDataUserGroupClick.push(g.value)
          }
        })
      } else if (pk === 'reset') {
        this.responseDataUserGroupClick = []
      } else {
        if (this.responseDataUserGroupClick.findIndex(function checkGroup (g) { return g === pk }) === -1) {
          this.responseDataUserGroupClick.push(pk)
        } else {
          this.responseDataUserGroupClick.splice(this.responseDataUserGroupClick.findIndex(function checkGroup (g) { return g === pk }), 1)
        }
      }
    },
    doGroupAvailableToUser (type, pk) {
      if (type === 'alone') {
        if (this.responseDataUserGroup.findIndex(function checkGroup (g) { return g.value === pk }) === -1) {
          this.responseDataAvailableGroup.forEach((g) => {
            if (g.value === pk) {
              this.responseDataUserGroup.push(g)
              this.responseDataAvailableGroup.splice(this.responseDataAvailableGroup.findIndex(function checkGroup (d) { return d.value === g.value }), 1)
            }
          })
        }
      } else if (type === 'array') {
        this.responseDataAvailableGroupClick.forEach((a) => {
          if (this.responseDataUserGroup.findIndex(function checkGroup (g) { return g.value === a }) === -1) {
            this.responseDataAvailableGroup.forEach((g) => {
              if (g.value === a) {
                this.responseDataUserGroup.push(g)
                this.responseDataAvailableGroup.splice(this.responseDataAvailableGroup.findIndex(function checkGroup (d) { return d.value === g.value }), 1)
              }
            })
          }
        })
      }
      this.responseDataAvailableGroupClick = []
      this.responseDataUserGroupClick = []
    },
    doGroupUserToAvailable (type, pk) {
      if (type === 'alone') {
        if (this.responseDataAvailableGroup.findIndex(function checkGroup (g) { return g.value === pk }) === -1) {
          this.responseDataUserGroup.forEach((g) => {
            if (g.value === pk) {
              this.responseDataAvailableGroup.push(g)
              this.responseDataUserGroup.splice(this.responseDataUserGroup.findIndex(function checkGroup (d) { return d.value === g.value }), 1)
            }
          })
        }
      } else if (type === 'array') {
        this.responseDataUserGroupClick.forEach((a) => {
          if (this.responseDataAvailableGroup.findIndex(function checkGroup (g) { return g.value === a }) === -1) {
            this.responseDataUserGroup.forEach((g) => {
              if (g.value === a) {
                this.responseDataAvailableGroup.push(g)
                this.responseDataUserGroup.splice(this.responseDataUserGroup.findIndex(function checkGroup (d) { return d.value === g.value }), 1)
              }
            })
          }
        })
      }
      this.responseDataAvailableGroupClick = []
      this.responseDataUserGroupClick = []
    },
    doViewGroupPrivilege (pk, name) {
      if (pk.length > 0) {
        // let param = ''
        // pk.forEach((g, index) => {
        //   if (index === 0) {
        //     param = param + '?groupPkList=' + g
        //   } else {
        //     param = param + '&groupPkList=' + g
        //   }
        // })
        const body = {
          groupPkList: pk
        }
        // this.$http.post('/api/system/groupPrivilege' + param).then(res => res.data)
        this.$http.post('/api/system/groupPrivilege', body).then(res => res.data)
          .then(res => {
            if (res.status === 'success') {
              this.viewGroupPrivilegeName = name
              if (res.data.length > 0) {
                this.responseDataGroupPrivilege = res.data
              } else {
                this.responseDataGroupPrivilege = [{ pk: 0, groupPk: 0, text: '目前尚無任何權限', children: [] }]
              }
            }
          })
        this.viewGroupPrivilege = true
      } else {
        alert('目前尚無任何權限')
      }
    },
    doUserGroupEdit (pk, name, dept) {
      this.$http.post('/api/system/group/' + pk).then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.filterUserGroupName = ''
            this.filterAvailableGroupName = ''
            this.responseDataAvailableGroupClick = []
            this.responseDataUserGroupClick = []
            this.responseDataAvailableGroup = res.data.availableGroup
            this.responseDataUserGroup = res.data.userGroup

            this.userGroupPrivilege = []
            res.data.userGroup.forEach((g) => {
              this.userGroupPrivilege.push(g.value)
            })
          }
        })
      this.editUserGroupViewPk = pk
      this.editUserGroupViewName = name
      // this.editUserGroupViewNumber = number
      this.editUserGroupViewDept = dept
      this.editUserGroupView = true
      this.action = 'group'
    },
    doSaveGroupPrivilege (pk) {
      this.processingView = true
      const userGroupPkList = []
      this.responseDataUserGroup.forEach((g) => {
        userGroupPkList.push(g.value)
      })
      const body = {
        userPk: pk,
        groupPkList: userGroupPkList
      }
      this.$http.post('/api/system/userGroup', body).then(res => res.data)
        .then(res => {
          this.processingView = false
          if (res.status === 'success') {
            this.snackbar = true
            this.snackbarColor = 'green'
            this.snackbarMsg = '群組調整成功'
            this.doUserGroupEdit(this.editUserGroupViewPk, this.editUserGroupViewName, this.editUserGroupViewDept)
          } else { // failure
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
          }
        }).catch(err => {
          this.processingView = false
          this.snackbar = true
          this.snackbarColor = 'black'
          this.snackbarMsg = err
        })
    },
    doUserEdit (pk) {
      alert('編輯功能尚未啟用')
    },
    doAdd () {
      alert('新增功能尚未啟用')
    },
    doSubmit () {
      this.loadingView = true
      const body = {
        deptPk: this.summaryDept === 'All' ? '' : this.summaryDept,
        userName: this.summaryUserName,
        email: this.summaryUserEmail,
        groupPk: this.summaryGroup === 'All' ? '' : this.summaryGroup
      }
      this.$http.post('/api/system/queryUser', body).then(res => res.data)
        .then(res => {
          this.loadingView = false
          if (res.status === 'success') {
            this.pagination.rowsPerPage = 25
            this.responseDataTable = res.data
          }
        })
    }
  }
}
</script>
<style>
</style>
