<template>
  <v-content :style="'min-height: 100vh;background-image: url('+Logobg+');background-position: center;background-size: 100% 100%;'">
    <v-container
      fill-height
      fluid>
      <v-layout
        align-start
        justify-center>
        <v-flex
          xs12
          md8
          lg6>
          <h1 class="text-center mb-3">品質管理平台</h1>
          <v-card
            class="elevation-12 text-center"
            style="background: RGBA(10,10,10,0.5);border-radius: 50px;">
            <!-- <v-toolbar
              color="">
              <v-toolbar-title style="color: #fff !important;font-weight: bold;">品質管理平台</v-toolbar-title>
              <v-spacer/>
            </v-toolbar> -->
            <v-card-text class="p-0">
              <v-form
                ref="form"
                v-model="valid">
                <v-layout>
                  <v-flex
                    v-if="status==='general'"
                    xs12
                    md4>
                    <v-text-field
                      ref="username"
                      v-model="username"
                      :rules="[(v) => !!v || 'This field is required']"
                      class="danger-input ml-3 mr-5"
                      prepend-icon="mdi-account"
                      label="Login"
                      color="red"
                      required
                    />
                  </v-flex>
                  <v-flex
                    v-if="status==='general'"
                    xs12
                    md4>
                    <v-text-field
                      ref="password"
                      v-model="password"
                      :rules="[(v) => !!v || 'This field is required']"
                      :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                      :type="showPassword ? 'text' : 'password'"
                      class="danger-input mr-0"
                      prepend-icon="mdi-lock"
                      label="Password"
                      color="red"
                      counter
                      required
                      @keydown.enter="login"
                      @click:append="showPassword = !showPassword"
                    />
                  </v-flex>
                  <v-flex
                    v-if="status==='general'"
                    xs12
                    md4>
                    <v-card-actions>
                      <v-spacer/>
                      <v-btn
                        :disabled="!valid"
                        align-center
                        justify-center
                        class="mt-2 pink"
                        style="min-width:auto;"
                        color=""
                        @click="login">登入
                      </v-btn>
                      <v-btn
                        align-center
                        justify-center
                        class="mt-2 black"
                        style="min-width:auto;"
                        color=""
                        @click="status='default'">返回
                      </v-btn>
                    </v-card-actions>
                  </v-flex>
                  <v-flex
                    v-if="status==='default'"
                    xs12>
                    <div class="text-center p-2">
                      <v-btn
                        :disabled="!valid"
                        align-center
                        justify-center
                        class="mt-2 pink"
                        style="min-width:auto;"
                        color=""
                        @click="status='general'">一般人員登入
                      </v-btn>
                      <a
                        :href="$CUSTOME_ENV.saml_url"
                        style="margin-top: -2px;">
                        <v-btn
                          :disabled="!valid"
                          align-center
                          justify-center
                          class="mt-2 ml-2 teal"
                          style="min-width:auto;"
                          color="">華碩職員登入
                        </v-btn>
                      </a>
                    </div>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-card-text>
            <v-snackbar
              v-model="snackbar"
              :color="color"
              :top="true">
              {{ errorMessages }}
              <v-btn
                dark
                flat
                @click="snackbar = false">
                Close
              </v-btn>
            </v-snackbar>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-content>
</template>

<script>
import Logobg from '@/assets/img/logobg.jpg'
import { mapState } from 'vuex'
export default {
  data: function () {
    return {
      Logobg,
      status: 'default',
      valid: false,
      username: '',
      password: '',
      errorMessages: '',
      snackbar: false,
      color: 'red',
      showPassword: false
    }
  },
  computed: {
    ...mapState('app', ['theme', 'shrink'])
  },
  // Sends action to Vuex that will log you in and redirect to the dash otherwise, error
  mounted () {
    const { sp } = this.$route.query
    if (sp) {
      this.$store.dispatch('staffLogin', { sp })
        .then(response => {
          if (response.status === 'success') {
            if (JSON.parse(localStorage.getItem('normalMenu')).length > 0) {
              const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
              window.location.assign(routeData.href)
            } else {
              localStorage.setItem('msg', '帳號尚未設定權限，請洽系統管理員')
              this.$store.dispatch('logout').then(() => {
                const routeData = this.$router.resolve({ path: '/' })
                window.location.assign(routeData.href)
              })
            }
          } else {
            this.errorMessages = response.errMsg
            this.snackbar = true
          }
        })
        .catch(err => {
          this.errorMessages = err
          this.snackbar = true
        })
    }
    if (localStorage.getItem('msg')) {
      alert(localStorage.getItem('msg'))
      localStorage.removeItem('msg')
    }
  },
  methods: {
    login: function () {
      const username = this.username
      const password = this.password
      if (username && password) {
        this.$store.dispatch('login', { username, password })
          .then(response => {
            if (response.status === 'success') {
              if (JSON.parse(localStorage.getItem('normalMenu')).length > 0) {
                const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
                window.location.assign(routeData.href)
              } else {
                localStorage.setItem('msg', '帳號尚未設定權限，請洽系統管理員')
                this.$store.dispatch('logout').then(() => {
                  const routeData = this.$router.resolve({ path: '/' })
                  window.location.assign(routeData.href)
                })
              }
            } else {
              this.errorMessages = response.errMsg || response
              this.snackbar = true
            }
          })
          .catch(err => {
            this.errorMessages = err
            this.snackbar = true
          })
          // .catch(err => {
          //   console.log('e' + err)
          //   this.snackbar = true
          //   // this.$router.push('/thermal/monthlyThermalFailRateSummary')
          // })
        // this.$router.push('/dashboard')
      }
    }
  },
  metaInfo () {
    return {
      title: 'QAMP | Login'
    }
  }
}
</script>
