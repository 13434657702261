<template>
  <v-footer
    id="core-footer"
    :dark="theme==='dark'"
    :light="theme==='light'"
    :class="{'footer_bg_dark' : theme==='dark' , 'footer_bg_light' : theme==='light'}"
    app
    absolute
    height="40"
  >
    <!-- <div class="footer-items">
      <span
        v-for="link in links"
        :key="link.name"
      >
        <a
          :href="link.Link"
          class="tertiary--text footer-links">{{ link.name }}</a>
      </span>
    </div> -->
    <v-spacer/>
    <span>
      &copy;
      {{ (new Date()).getFullYear() }} 本平台為
      <a
        href="https://www.asus.com/tw/"
        target="_blank">華碩電腦股份有限公司</a> 版權所有
    </span>
  </v-footer>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data: () => ({
    // links: [
    //   { name: 'Home', Link: '/dashboard' },
    //   { name: 'Demo', Link: 'https://clintoxx.github.io/vuetify-admin-dashboard/' },
    //   { name: 'GitHub', Link: 'https://github.com/ClintOxx' }
    // ]
  }),

  computed: {
    ...mapState('app', ['theme', 'shrink'])
  }

}
</script>

<style>
  #core-footer {
    z-index: 0;
  }

  .footer_bg_dark {
    -webkit-background-image: repeating-linear-gradient(120deg,RGBA(0,0,0,1) 0%,  RGBA(40,0,0,0.75) 100%)!important;
    -moz-background-image: repeating-linear-gradient(120deg,RGBA(0,0,0,1) 0%,  RGBA(40,0,0,0.75) 100%)!important;
    -o-background-image: repeating-linear-gradient(120deg,RGBA(0,0,0,1) 0%,  RGBA(40,0,0,0.75) 100%)!important;
    -ms-background-image: repeating-linear-gradient(120deg,RGBA(0,0,0,1) 0%,  RGBA(40,0,0,0.75) 100%)!important;
    background-image: repeating-linear-gradient(120deg,RGBA(0,0,0,1) 0%,  RGBA(40,0,0,0.75) 100%)!important;
    -webkit-border-image: repeating-linear-gradient(150deg, #000 0%, #800 20%, #300 35%, #500 50%, #A00 85%, #A00 100%)!important;
    -moz-border-image: repeating-linear-gradient(150deg, #000 0%, #800 20%, #300 35%, #500 50%, #A00 85%, #A00 100%)!important;
    -o-border-image: repeating-linear-gradient(150deg, #000 0%, #800 20%, #300 35%, #500 50%, #A00 85%, #A00 100%)!important;
    -ms-border-image: repeating-linear-gradient(150deg, #000 0%, #800 20%, #300 35%, #500 50%, #A00 85%, #A00 100%)!important;
    border-image: repeating-linear-gradient(150deg, #000 0%, #800 20%, #300 35%, #500 50%, #A00 85%, #A00 100%)!important;
    border-image-slice: 1!important;
    border-top: 2px solid!important;

  }

  .footer_bg_light {
    -webkit-background-image: repeating-linear-gradient(120deg,RGBA(255,255,255,1) 0%,  RGBA(30,200,250,0.5) 100%)!important;
    -moz-background-image: repeating-linear-gradient(120deg,RGBA(255,255,255,1) 0%,  RGBA(30,200,250,0.5) 100%)!important;
    -o-background-image: repeating-linear-gradient(120deg,RGBA(255,255,255,1) 0%,  RGBA(30,200,250,0.5) 100%)!important;
    -ms-background-image: repeating-linear-gradient(120deg,RGBA(255,255,255,1) 0%,  RGBA(30,200,250,0.5) 100%)!important;
    background-image: repeating-linear-gradient(120deg,RGBA(255,255,255,1) 0%,  RGBA(30,200,250,0.5) 100%)!important;
    -webkit-border-image: repeating-linear-gradient(150deg, #FFF 0%, #8EF 20%, #6BD 35%, #8EF 50%, #9EF 85%, #9EF 100%)!important;
    -moz-border-image: repeating-linear-gradient(150deg, #FFF 0%, #8EF 20%, #6BD 35%, #8EF 50%, #9EF 85%, #9EF 100%)!important;
    -o-border-image: repeating-linear-gradient(150deg, #FFF 0%, #8EF 20%, #6BD 35%, #8EF 50%, #9EF 85%, #9EF 100%)!important;
    -ms-border-image: repeating-linear-gradient(150deg, #FFF 0%, #8EF 20%, #6BD 35%, #8EF 50%, #9EF 85%, #9EF 100%)!important;
    border-image: repeating-linear-gradient(150deg, #FFF 0%, #8EF 20%, #6BD 35%, #8EF 50%, #9EF 85%, #9EF 100%)!important;
    border-image-slice: 1!important;
    border-top: 2px solid!important;
  }

  footer {
    color: #666 !important;
    padding: 15px 24px;
  }

  footer.theme--dark a {
    color: #f44336 !important;
  }

  footer.theme--light a {
    color: #1e88e5 !important;
  }
</style>
