<template>
  <v-container
    krypart
    fluid
    grid-list-xl
    pt-0
  >
    <v-layout wrap>
      <v-flex
        xs12
      >
        <material-card
          title="揚聲器料件單製程品質分析"
          class="card-shrink card-padding v-card__first"
          color="general"
        >
          <v-form>
            <v-container
              py-0
              class="mw-100">
              <v-layout
                wrap
                align-items-center>
                <v-flex
                  xs12
                  sm6
                  lg2>
                  <v-autocomplete
                    v-model="summaryVendorName"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryVendorNameMenu"
                    label="廠商"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex
                  xs12
                  sm6
                  lg2>
                  <v-autocomplete
                    v-model="summaryPartNumber"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryPartNumberMenu"
                    label="料號"
                    class="v-text-field__slot"
                    color="white"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex
                  xs12
                  lg1/>
                <v-flex
                  flex-small
                  d-flex
                  xs12
                  lg4>
                  <v-radio-group
                    v-model="summaryDateType"
                    class="justify-content-end align-items-center"
                    row>
                    <v-radio
                      label="依週次統計"
                      value="week"/>
                    <v-radio
                      label="依月份統計"
                      value="month"/>
                    <v-radio
                      label="依季別統計"
                      value="season"/>
                  </v-radio-group>
                </v-flex>
                <v-flex
                  v-show="summaryDateType==='week'"
                  flex-small
                  align-items-center
                  xs12
                  lg2>
                  <!--<v-autocomplete
                    v-model="summaryWeeksStart"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryAllYearWeeksList"
                    label="起始週次"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  >
                    <template v-slot:item="data">
                      <div
                        :class="{'weeks-list-data-tip':contentDateListWeeks.indexOf(data.item.value)!==-1}"
                        class="v-list__tile__content">
                        <div class="v-list__tile__title">
                          {{ data.item.text }}
                        </div>
                      </div>
                    </template>
                  </v-autocomplete>-->
                  <WeekPicker
                    :list-start-date="startdate"
                    :content-date-list="contentDateList"
                    :select-week="summaryWeeksStart"
                    label-text="起始週次"
                    value-type="sDay"
                    @selected-week="selectedWeek"/>
                </v-flex>
                <v-flex
                  v-show="summaryDateType==='month'"
                  xs12
                  lg2>
                  <v-menu
                    v-model="summaryMonthMenu"
                    :close-on-content-click="false"
                    :nudge-left="40"
                    lazy
                    transition="scale-transition"
                    max-width="290px"
                    offset-y
                    full-width
                  >
                    <v-text-field
                      slot="activator"
                      :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                      v-model="summaryMonthStart"
                      label="起始月份"
                      prepend-icon="event"
                    />
                    <v-date-picker
                      v-model="summaryMonthStart"
                      :min="startdate"
                      :max="new Date() | date-format"
                      :color="theme==='dark'?'danger':'general'"
                      :events="contentDateList.map((d) => this.$moment(d).format('YYYY-MM'))"
                      event-color="green lighten-1"
                      type="month"
                      no-title
                      scrollable
                      actions/>
                  </v-menu>
                </v-flex>
                <v-flex
                  v-show="summaryDateType==='season'"
                  flex-small
                  align-items-center
                  xs12
                  lg2>
                  <v-autocomplete
                    v-model="summarySeasonStart"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryAllYearSeasonList"
                    label="起始季別"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  >
                    <template v-slot:item="data">
                      <div
                        :class="{'weeks-list-data-tip':contentDateListSeason.indexOf(data.item.value)!==-1}"
                        class="v-list__tile__content">
                        <div class="v-list__tile__title">
                          {{ data.item.text }}
                        </div>
                      </div>
                    </template>
                  </v-autocomplete>
                </v-flex>
                <v-flex
                  xs12
                  lg1
                  text-xs-right
                >
                  <v-btn
                    :disabled="(summaryWeeksStart===''||summaryMonthStart===''||summarySeasonStart==='')||summaryVendorName===''||summaryPartNumber===''"
                    class="mx-0 font-weight-light"
                    color="general"
                    @click="doSubmit()"
                  >
                    <v-icon class="mr-2">mdi-backup-restore</v-icon>分析
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
      </v-flex>
      <v-flex
        v-if="processingStatus && summaryNoData"
        class="data-area no-data-page"
        xs12>
        此日期區間查無資料
      </v-flex>
      <v-flex
        v-if="processingStatus && !summaryNoData"
        flex_table_width_auto
        xs12
      >
        <material-card
          id="checkTable"
          :title="'SPL統計表'"
          :style="'max-width: calc(100vw - '+(Number(shrink)+32)+'px);'"
          color="deep-orange"
          class="card-mix d-table-t0 no-data-left"
          style="margin-top: 30px!important;">
          <v-btn
            v-if="responseLimitSigma >= 3"
            class="mix-btn black text-light mx-0"
            style="left:140px;right:unset;text-transform: none;"
            @click="IsShowSigma3=!IsShowSigma3">{{ IsShowSigma3?'隱藏':'顯示' }}±３L</v-btn>
          <v-btn
            v-if="responseLimitSigma >= 4"
            class="mix-btn black text-light mx-0"
            style="left:220px;right:unset;text-transform: none;"
            @click="IsShowSigma4=!IsShowSigma4">{{ IsShowSigma4?'隱藏':'顯示' }}±４L</v-btn>
          <v-btn
            v-if="responseLimitSigma >= 5"
            class="mix-btn black text-light mx-0"
            style="left:300px;right:unset;text-transform: none;"
            @click="IsShowSigma5=!IsShowSigma5">{{ IsShowSigma5?'隱藏':'顯示' }}±５L</v-btn>
          <v-btn
            v-if="responseLimitSigma >= 6"
            class="mix-btn black text-light mx-0"
            style="left:380px;right:unset;text-transform: none;"
            @click="IsShowSigma6=!IsShowSigma6">{{ IsShowSigma6?'隱藏':'顯示' }}±６L</v-btn>
          <!-- <v-btn
            class="mix-btn black text-light mx-0"
            style="right:285px;"
            @click="doDownload(null, null, null)">匯出Raw Data</v-btn>
          <v-btn
            class="mix-btn black text-light mx-0"
            style="right:110px;text-transform: none;"
            @click="doDownload(null, responseLimitSigma, null)">匯出Over ±{{ responseLimitSigma }}L Rawdata</v-btn> -->
          <p
            :style="'right:'+((responseLimitSigma-1)*115+95)+'px;font-weight:700 !important;'"
            class="mix-btn text-light mx-0">匯出 Raw Data</p>
          <v-btn
            :style="'right:'+((responseLimitSigma-2)*115+140)+'px;'"
            title="匯出 All Raw Data"
            class="mix-btn black text-light mx-0"
            @click="doDownload(null, null, null, null)"><v-icon class="mr-2">mdi-download</v-icon>All</v-btn>
          <v-btn
            v-if="responseLimitSigma >= 3"
            :style="'right:'+((responseLimitSigma-3)*115+140)+'px;text-transform: none;'"
            title="匯出 Over ±3L Raw Data"
            class="mix-btn black text-light mx-0"
            @click="doDownload(null, 3, null, null)"><v-icon class="mr-2">mdi-download</v-icon>Over ±３L</v-btn>
          <v-btn
            v-if="responseLimitSigma >= 4"
            :style="'right:'+((responseLimitSigma-4)*115+140)+'px;text-transform: none;'"
            title="匯出 Over ±4L Raw Data"
            class="mix-btn black text-light mx-0"
            @click="doDownload(null, 4, null, null)"><v-icon class="mr-2">mdi-download</v-icon>Over ±４L </v-btn>
          <v-btn
            v-if="responseLimitSigma >= 5"
            :style="'right:'+((responseLimitSigma-5)*115+140)+'px;text-transform: none;'"
            title="匯出 Over ±5L Raw Data"
            class="mix-btn black text-light mx-0"
            @click="doDownload(null, 5, null, null)"><v-icon class="mr-2">mdi-download</v-icon>Over ±５L </v-btn>
          <v-btn
            v-if="responseLimitSigma >= 6"
            :style="'right:'+((responseLimitSigma-6)*115+140)+'px;text-transform: none;'"
            title="匯出 Over ±6L Raw Data"
            class="mix-btn black text-light mx-0"
            @click="doDownload(null, 6, null, null)"><v-icon class="mr-2">mdi-download</v-icon>Over ±６L </v-btn>
          <p
            class="mix-btn text-light mx-0"
            style="right:112px;">|</p>
          <v-btn
            class="mix-btn black text-light mx-0"
            style="right:30px;"
            @click="dofilterALLClear()">清除條件</v-btn>
          <!-- <v-select
            v-model="pagination.rowsPerPage"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="rowsAmount"
            class="mix-list v-text-field__slot d-inline-block float-right ml-5"
            style="width: 90px;"
            persistent-hint
            outlined
          /> -->
          <table
            :key="'table'+tableOperatenum"
            class="statistic-table keypart-table speaker-table">
            <thead>
              <tr>
                <th
                  colspan="100%"
                  class="text-white">機種：{{ responseModelList.join('、') }}</th>
              </tr>
              <tr>
                <template v-for="(header, h) in responseHeaders">
                  <th
                    v-if="header.show"
                    :key="'h'+h"
                    :class="{'desc':pagination.sortBy===header.value&&pagination.descending, 'asc':pagination.sortBy===header.value&&!pagination.descending}"
                    style="position: relative;">
                    <div
                      v-if="header.type"
                      class="table-filter-item">
                      <v-icon @click="dofilterViewOpen(header.type, header.index, header.group, header.text)">mdi-filter</v-icon>
                      {{ filterSettingList[header.index] }}
                      <v-icon
                        v-if="filterSettingList[header.index]"
                        class="close"
                        style="margin-top: -20px;"
                        @click="dofilterClear(header.index)">
                        mdi-close
                      </v-icon>
                    </div>
                    <span v-if="!header.sortable">{{ header.text }}</span>
                    <span
                      v-if="header.sortable"
                      @click="doTableSort(header.value)">
                      {{ header.text }}
                      <v-icon class="sort-btn">mdi-arrow-up</v-icon>
                    </span>
                  </th>
                </template>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item,i) in responseDataCompile"
                v-show="pagination.rowsPerPage===-1||((i+1)>(pagination.page-1)*pagination.rowsPerPage&&(i+1)<=pagination.page*pagination.rowsPerPage)"
                :key="'i'+i">
                <td v-if="doHeadersShow('group')">{{ item.group }}</td>
                <td v-if="false">{{ 'item.vendor' }}</td>
                <!-- <td v-if="false">{{ 'item.itemNo' }}</td> -->
                <td>{{ item.speakerType }}</td>
                <td>{{ item.testItemName }}<v-icon v-if="doWarnGroupCpk(i)">mdi-alert-decagram</v-icon></td>
                <td v-if="doHeadersShow('chipNo')">{{ item.chipNo }}</td>
                <td v-if="item.lowerSpecValue!==null && item.upperSpecValue!==null">({{ item.lowerSpecValue.toFixed(2) }} ~ {{ item.upperSpecValue.toFixed(2) }})</td>
                <td v-else>{{ item.lowerSpecValue?('≥'+item.lowerSpecValue):item.upperSpecValue?('≤'+item.upperSpecValue):'' }}</td>
                <td
                  class="cursor"
                  @click="doSubmitSingle(item.speakerType, item.testItemName)">{{ item.cpk.toFixed(2) }}</td>
                <td v-if="responseLimitSigma >= 3 && IsShowSigma3">{{ item.distributionCoverageList.filter((sigma) => sigma.define.sigma === 3).length>0?item.distributionCoverageList.filter((sigma) => sigma.define.sigma === 3)[0].define.value.toFixed(2):'' }}</td>
                <td v-if="responseLimitSigma >= 4 && IsShowSigma4">{{ item.distributionCoverageList.filter((sigma) => sigma.define.sigma === 4).length>0?item.distributionCoverageList.filter((sigma) => sigma.define.sigma === 4)[0].define.value.toFixed(2):'' }}</td>
                <td v-if="responseLimitSigma >= 5 && IsShowSigma5">{{ item.distributionCoverageList.filter((sigma) => sigma.define.sigma === 5).length>0?item.distributionCoverageList.filter((sigma) => sigma.define.sigma === 5)[0].define.value.toFixed(2):'' }}</td>
                <td v-if="responseLimitSigma >= 6 && IsShowSigma6">{{ item.distributionCoverageList.filter((sigma) => sigma.define.sigma === 6).length>0?item.distributionCoverageList.filter((sigma) => sigma.define.sigma === 6)[0].define.value.toFixed(2):'' }}</td>
                <td v-if="responseLimitSigma >= 3 && IsShowSigma3">{{ item.distributionCoverageList.filter((sigma) => sigma.define.sigma === -3).length>0?item.distributionCoverageList.filter((sigma) => sigma.define.sigma === -3)[0].define.value.toFixed(2):'' }}</td>
                <td v-if="responseLimitSigma >= 4 && IsShowSigma4">{{ item.distributionCoverageList.filter((sigma) => sigma.define.sigma === -4).length>0?item.distributionCoverageList.filter((sigma) => sigma.define.sigma === -4)[0].define.value.toFixed(2):'' }}</td>
                <td v-if="responseLimitSigma >= 5 && IsShowSigma5">{{ item.distributionCoverageList.filter((sigma) => sigma.define.sigma === -5).length>0?item.distributionCoverageList.filter((sigma) => sigma.define.sigma === -5)[0].define.value.toFixed(2):'' }}</td>
                <td v-if="responseLimitSigma >= 6 && IsShowSigma6">{{ item.distributionCoverageList.filter((sigma) => sigma.define.sigma === -6).length>0?item.distributionCoverageList.filter((sigma) => sigma.define.sigma === -6)[0].define.value.toFixed(2):'' }}</td>
                <td
                  v-if="responseLimitSigma >= 3 && IsShowSigma3"
                  :class="{'cursor text-white': item.distributionCoverageList.filter((sigma) => sigma.define.sigma === 3).length>0?(item.distributionCoverageList.filter((sigma) => sigma.define.sigma === 3)[0].amount>0?true:false):false}"
                  class="text-gray"
                  @click="item.distributionCoverageList.filter((sigma) => sigma.define.sigma === 3).length>0?(item.distributionCoverageList.filter((sigma) => sigma.define.sigma === 3)[0].amount>0?doDownload(item.testItemName, null, 3, item.speakerType):false):false"
                >{{ item.distributionCoverageList.filter((sigma) => sigma.define.sigma === 3).length>0?item.distributionCoverageList.filter((sigma) => sigma.define.sigma === 3)[0].amount:'' }}</td>
                <td
                  v-if="responseLimitSigma >= 4 && IsShowSigma4"
                  :class="{'cursor text-white': item.distributionCoverageList.filter((sigma) => sigma.define.sigma === 4).length>0?(item.distributionCoverageList.filter((sigma) => sigma.define.sigma === 4)[0].amount>0?true:false):false}"
                  class="text-gray"
                  @click="item.distributionCoverageList.filter((sigma) => sigma.define.sigma === 4).length>0?(item.distributionCoverageList.filter((sigma) => sigma.define.sigma === 4)[0].amount>0?doDownload(item.testItemName, null, 4, item.speakerType):false):false"
                >{{ item.distributionCoverageList.filter((sigma) => sigma.define.sigma === 4).length>0?item.distributionCoverageList.filter((sigma) => sigma.define.sigma === 4)[0].amount:'' }}</td>
                <td
                  v-if="responseLimitSigma >= 5 && IsShowSigma5"
                  :class="{'cursor text-white': item.distributionCoverageList.filter((sigma) => sigma.define.sigma === 5).length>0?(item.distributionCoverageList.filter((sigma) => sigma.define.sigma === 5)[0].amount>0?true:false):false}"
                  class="text-gray"
                  @click="item.distributionCoverageList.filter((sigma) => sigma.define.sigma === 5).length>0?(item.distributionCoverageList.filter((sigma) => sigma.define.sigma === 5)[0].amount>0?doDownload(item.testItemName, null, 5, item.speakerType):false):false"
                >{{ item.distributionCoverageList.filter((sigma) => sigma.define.sigma === 5).length>0?item.distributionCoverageList.filter((sigma) => sigma.define.sigma === 5)[0].amount:'' }}</td>
                <td
                  v-if="responseLimitSigma >= 6 && IsShowSigma6"
                  :class="{'cursor text-white': item.distributionCoverageList.filter((sigma) => sigma.define.sigma === 6).length>0?(item.distributionCoverageList.filter((sigma) => sigma.define.sigma === 6)[0].amount>0?true:false):false}"
                  class="text-gray"
                  @click="item.distributionCoverageList.filter((sigma) => sigma.define.sigma === 6).length>0?(item.distributionCoverageList.filter((sigma) => sigma.define.sigma === 6)[0].amount>0?doDownload(item.testItemName, null, 6, item.speakerType):false):false"
                >{{ item.distributionCoverageList.filter((sigma) => sigma.define.sigma === 6).length>0?item.distributionCoverageList.filter((sigma) => sigma.define.sigma === 6)[0].amount:'' }}</td>
                <td
                  v-if="responseLimitSigma >= 3 && IsShowSigma3"
                  :class="{'cursor text-white': item.distributionCoverageList.filter((sigma) => sigma.define.sigma === -3).length>0?(item.distributionCoverageList.filter((sigma) => sigma.define.sigma === -3)[0].amount>0?true:false):false}"
                  class="text-gray"
                  @click="item.distributionCoverageList.filter((sigma) => sigma.define.sigma === -3).length>0?(item.distributionCoverageList.filter((sigma) => sigma.define.sigma === -3)[0].amount>0?doDownload(item.testItemName, null, -3, item.speakerType):false):false"
                >{{ item.distributionCoverageList.filter((sigma) => sigma.define.sigma === -3).length>0?item.distributionCoverageList.filter((sigma) => sigma.define.sigma === -3)[0].amount:'' }}</td>
                <td
                  v-if="responseLimitSigma >= 4 && IsShowSigma4"
                  :class="{'cursor text-white': item.distributionCoverageList.filter((sigma) => sigma.define.sigma === -4).length>0?(item.distributionCoverageList.filter((sigma) => sigma.define.sigma === -4)[0].amount>0?true:false):false}"
                  class="text-gray"
                  @click="item.distributionCoverageList.filter((sigma) => sigma.define.sigma === -4).length>0?(item.distributionCoverageList.filter((sigma) => sigma.define.sigma === -4)[0].amount>0?doDownload(item.testItemName, null, -4, item.speakerType):false):false"
                >{{ item.distributionCoverageList.filter((sigma) => sigma.define.sigma === -4).length>0?item.distributionCoverageList.filter((sigma) => sigma.define.sigma === -4)[0].amount:'' }}</td>
                <td
                  v-if="responseLimitSigma >= 5 && IsShowSigma5"
                  :class="{'cursor text-white': item.distributionCoverageList.filter((sigma) => sigma.define.sigma === -5).length>0?(item.distributionCoverageList.filter((sigma) => sigma.define.sigma === -5)[0].amount>0?true:false):false}"
                  class="text-gray"
                  @click="item.distributionCoverageList.filter((sigma) => sigma.define.sigma === -5).length>0?(item.distributionCoverageList.filter((sigma) => sigma.define.sigma === -5)[0].amount>0?doDownload(item.testItemName, null, -5, item.speakerType):false):false"
                >{{ item.distributionCoverageList.filter((sigma) => sigma.define.sigma === -5).length>0?item.distributionCoverageList.filter((sigma) => sigma.define.sigma === -5)[0].amount:'' }}</td>
                <td
                  v-if="responseLimitSigma >= 6 && IsShowSigma6"
                  :class="{'cursor text-white': item.distributionCoverageList.filter((sigma) => sigma.define.sigma === -6).length>0?(item.distributionCoverageList.filter((sigma) => sigma.define.sigma === -6)[0].amount>0?true:false):false}"
                  class="text-gray"
                  @click="item.distributionCoverageList.filter((sigma) => sigma.define.sigma === -6).length>0?(item.distributionCoverageList.filter((sigma) => sigma.define.sigma === -6)[0].amount>0?doDownload(item.testItemName, null, -6, item.speakerType):false):false"
                >{{ item.distributionCoverageList.filter((sigma) => sigma.define.sigma === -6).length>0?item.distributionCoverageList.filter((sigma) => sigma.define.sigma === -6)[0].amount:'' }}</td>
                <td
                  :style="!doCpkGrade(item.cpk)?'color:red;':''"
                  class="cursor"
                  @click="doSubmitSingle(item.speakerType, item.testItemName)">
                  {{ item.lvl }}</td>
                <td>{{ item.max.toFixed(2) }}</td>
                <td>{{ item.min.toFixed(2) }}</td>
                <td>{{ item.avg.toFixed(2) }}</td>
              </tr>
            </tbody>
          </table>
          <p
            class="text-xs-center pt-2 d-inline-block"
            style="margin-left: 0px;width: calc(100% - 0px);text-align: right!important;">
            共 {{ responseTotalInput }} 筆資料
          </p>
          <!-- <div
            v-if="pagination.rowsPerPage != -1"
            class="text-xs-center pt-2 d-inline-block"
            style="margin-left: 0px;width: calc(100% - 140px);">
            <v-pagination
              v-model="pagination.page"
              :length="pages"/>
          </div>
          <v-autocomplete
            v-if="pagination.rowsPerPage != -1"
            v-model="pagination.page"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="pageslist"
            class="v-text-field__slot d-inline-block float-right mr-0"
            style="width: 90px;"
            persistent-hint
            outlined
          /> -->
        </material-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="chartLineView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <v-card
        max-width="80%"
        style="margin:0 auto;">
        <v-card-text>
          <v-alert
            v-model="chartLineView"
            class="mt-3 mb-3 border-0"
            color="brown">
            <strong class="h4"> ( {{ chartViewSpeakerType }} ) {{ responseStandardData?responseStandardData.testItemName:'' }} ： {{ responseDateStart }} - {{ responseDateEnd }} ({{ responseDateType }}) ｜ {{ responseVendorName }} ｜ {{ responsePartNumber }}</strong>
          </v-alert>
          <div class="d-flex">
            <div class="w-100 m-3">
              <p class="text-center my-1">{{ chartViewTestItemName }}{{ chartViewButtonCode?' [ '+chartViewButtonCode+' ] ':'' }}</p>
              <div class="keypart-chart-info">
                <div :class="{ 'ordinary':doCpkGrade(responseStandardData.cpk),'bad':!doCpkGrade(responseStandardData.cpk) }">
                  <span>CPK</span>
                  <p>{{ responseStandardData.cpk.toFixed(2) }}</p>
                  <div>
                    <span>{{ doCpkGradeName(responseStandardData.cpk) }}</span>
                  </div>
                </div>
                <div>
                  <span>SPEC</span>
                  <p>{{ responseStandardData.lowerSpecValue }}{{ (responseStandardData.lowerSpecValue!==null&&responseStandardData.upperSpecValue!==null)?' ~ ':'' }}{{ responseStandardData.upperSpecValue }}</p>
                  <div>
                    <span v-if="responseStandardData.lowerSpecValue!==null">LOWER</span>
                    <span v-if="responseStandardData.upperSpecValue!==null">UPPER</span>
                  </div>
                </div>
              </div>
              <table class="keypart-chart-table statistic-table">
                <thead>
                  <tr>
                    <th>sigma</th>
                    <th>value</th>
                    <th>histogram</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(row, r) in responseStandardData.distributionCoverageList">
                    <tr
                      v-if="row.define.type === 'Sigma'"
                      :key="'r'+r">
                      <td>{{ row.define.name }}</td>
                      <td>{{ row.define.value.toFixed(4) }}</td>
                      <td>{{ row.amount }}</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <vue-c3
              :handler="handler_line"
              class="keypart-chart-area"/>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="red"
            small
            @click="chartLineView=false">
            <v-icon class="mr-2">mdi-close</v-icon>關閉
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="filterView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <v-card
        max-width="35%"
        style="margin:0 auto;">
        <v-card-text>
          <v-alert
            v-model="filterView"
            class="mt-3 mb-3 border-0"
            color="brown">
            <strong class="h4">正在設定[{{ filterTitle }}]條件：{{ filterDirections }}</strong>
          </v-alert>
          <v-flex
            d-flex
            table-filter-setting-bar
            xs12>
            <v-text-field
              v-if="filterInfo.type==='keyword'"
              v-model="filterKeyword"
              :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
              label="關鍵字"
              placeholder="請輸入關鍵字"
              persistent-hint/>
            <v-btn-toggle
              v-if="filterInfo.type==='formula'"
              v-model="filterBtnSelect"
              class="d-flex align-items-center"
              multiple>
              <v-text-field
                v-model="filterMinValue"
                :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                :disabled="filterBtnSelect.indexOf('ge')===-1"
                label=""
                persistent-hint
                @keyup="doMinValueReplaceText()"/>
              <v-btn
                value="ge"
                class="general"
                color="light"
                flat>
                <v-icon>mdi-less-than-or-equal</v-icon>
              </v-btn>
              <v-text-field
                :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                label=""
                value="X"
                disabled
                persistent-hint/>
              <v-btn
                value="le"
                class="general"
                color="light"
                flat>
                <v-icon>mdi-less-than-or-equal</v-icon>
              </v-btn>
              <v-text-field
                v-model="filterMaxValue"
                :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                :disabled="filterBtnSelect.indexOf('le')===-1"
                label=""
                persistent-hint
                @keyup="doMaxValueReplaceText()"/>
            </v-btn-toggle>
          </v-flex>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="pink"
            small
            @click="dofilterSetting()">
            <v-icon class="mr-2">mdi-plus</v-icon>新增條件
          </v-btn>
          <v-btn
            color="general"
            small
            @click="filterView=false">
            <v-icon class="mr-2">mdi-close</v-icon>返回
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="processingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        PROCESSING 處理中
      </p>
    </v-dialog>
    <v-dialog
      v-model="loadingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :top="true"
      :color="snackbarColor">
      {{ snackbarMsg }}
      <v-btn
        dark
        flat
        @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import qs from 'qs'
import Vue from 'vue'
import VueC3 from 'vue-c3'
import 'c3/c3.min.css'
import { mapState } from 'vuex'
import NormalDistribution from 'normal-distribution'
import Loading from '@/assets/img/loading.gif'
import WeekPicker from '@/components/module/datepicker/WeekPicker.vue'

export default {
  name: 'SpeakerSingleProcessAnalyzer',
  components: {
    VueC3,
    WeekPicker
  },
  data () {
    return {
      startdate: '2018-04-01',
      snackbar: false,
      snackbarColor: 'red',
      snackbarMsg: '',
      Loading,
      loadingView: false,
      processingView: false,
      processingStatus: false,
      summaryNoData: true,
      tableOperatenum: 0,
      filterView: false,
      filterInfo: {
        type: '',
        index: -1,
        group: '',
        item: ''
      },
      filterTitle: '',
      filterKeyword: '',
      filterMinValue: '',
      filterMaxValue: '',
      filterBtnSelect: [],
      filterSettingList: new Array(26),
      filterSettingDetailList: new Array(26),
      IsShowSigma3: true,
      IsShowSigma4: true,
      IsShowSigma5: true,
      IsShowSigma6: true,
      summaryDateType: 'week',
      summaryMonthMenu: false,
      summaryMonth: this.$moment(new Date()).format('YYYY-MM'),
      summaryMonthStart: this.$moment(new Date()).format('YYYY-MM'),
      summarySeasonStart: Math.ceil((Number(this.$moment(new Date()).format('MM')) - 1) / 3) === 0 ? (Number(this.$moment(new Date()).format('YYYY')) - 1) + '-11-01' : (this.$moment(new Date()).format('YYYY')) + '-0' + (((Math.ceil((Number(this.$moment(new Date()).format('MM')) - 1) / 3) - 1) * 3) + 2) + '-01',
      // summaryWeeksStart: Number(this.$moment(new Date()).format('YYYY')) * 100 + Math.ceil((((new Date(this.$moment(new Date()).format('YYYY-MM-DD')) - new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1))) / 86400000) + new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1)).getDay()) / 7) - parseInt((new Date(this.$moment(new Date()).format('YYYY') + '-01-01').getDay() + 5) / 6),
      summaryWeeksStart: this.$moment(new Date()).format('YYYY-MM-DD'),
      summaryVendorNameMenu: [],
      summaryPartNumberMenu: [],
      summaryVendorName: '',
      summaryPartNumber: '',
      // summaryYearWeeksList: [],
      // summaryAllYearWeeksList: [],
      summaryYearSeasonList: [],
      summaryAllYearSeasonList: [],
      tempDateType: 'week',
      tempVendorName: '',
      tempPartNumber: '',
      CpkGradeList: [],
      contentDateList: [],
      responseDateType: 'week',
      responseVendorName: '',
      responseModelList: [],
      responsePartNumber: '',
      responseDateStart: '',
      responseDateEnd: '',
      // responseWeeksStart: '',
      responseStandardData: {
        cpk: 0
      },
      responseDataCompile: [],
      responseLimitSigma: 3,
      responseTotalInput: 0,
      chartLineView: false,
      chartLineViewNum: 0,
      chartLineViewName: '',
      chartViewButtonCode: '',
      chartViewTestItemName: '',
      chartViewSpeakerType: '',
      barXaxisName: [],
      barXaxisStandard: [],
      barXaxisValue: [],
      rowsAmount: [25, 50, 100, { text: 'All', value: -1 }],
      pagination: {
        page: 1,
        rowsPerPage: -1,
        sortBy: null,
        descending: null
      },
      // pages: 0,
      responseData: [],
      handler_line: new Vue()
    }
  },
  computed: {
    ...mapState('app', ['theme', 'shrink']),
    responseStandardUpperLower () {
      const array = []
      let ULindex = this.responseStandardData.upperSpecValue !== null ? -0.5 : null
      let LLindex = this.responseStandardData.lowerSpecValue !== null ? -0.5 : null
      this.responseStandardData.distributionCoverageList.map(function (e) {
        if (e.define.type === 'Sigma') {
          return e.define
        } else {
          return null
        }
      }).filter(Boolean).forEach((r, rr) => {
        if (this.responseStandardData.lowerSpecValue && this.responseStandardData.lowerSpecValue > r.value) {
          LLindex = rr + 0.5
        }
        if (this.responseStandardData.upperSpecValue && this.responseStandardData.upperSpecValue >= r.value) {
          ULindex = rr + 0.5
        }
      })
      if (ULindex) {
        array.push({ value: ULindex, text: 'Upper Spec Value' })
      }
      if (LLindex) {
        array.push({ value: LLindex, text: 'Lower Spec Value' })
      }
      return array
    },
    responseHeaders () {
      const array = [{
        sortable: true,
        type: 'keyword',
        text: '分類',
        index: 0,
        show: this.doHeadersShow('group'),
        value: 'group',
        align: 'left'
      }, {
        sortable: true,
        type: 'keyword',
        text: 'Vendor',
        index: 1,
        show: false,
        value: 'vendor',
        align: 'left'
      }, {
      //   sortable: true,
      //   type: 'keyword',
      //   text: 'PartNumber',
      //   index: 2,
      //   show: false,
      //   value: 'itemNo',
      //   align: 'left'
      // }, {
        sortable: true,
        type: 'keyword',
        text: 'Seaker Type',
        index: 2,
        show: true,
        value: 'speakerType',
        align: 'left'
      }, {
        sortable: true,
        type: 'keyword',
        text: 'Test item',
        index: 3,
        show: true,
        value: 'testItemName',
        align: 'left'
      }, {
        sortable: true,
        type: 'keyword',
        text: 'CHIP NO',
        index: 4,
        show: this.doHeadersShow('chipNo'),
        value: 'chipNo',
        align: 'left'
      }, {
        sortable: false,
        type: null,
        text: 'Spec',
        index: 5,
        show: true,
        value: 'spec',
        align: 'left'
      }, {
        sortable: true,
        type: 'formula',
        text: 'CPK',
        index: 6,
        show: true,
        value: 'cpk',
        align: 'left'
      }, {
        sortable: true,
        type: 'formula',
        text: '+3L',
        index: 7,
        show: this.IsShowSigma3 && this.responseLimitSigma >= 3,
        value: 'targetSigma3',
        align: 'left'
      }, {
        sortable: true,
        type: 'formula',
        text: '+4L',
        index: 8,
        show: this.IsShowSigma4 && this.responseLimitSigma >= 4,
        value: 'targetSigma4',
        align: 'left'
      }, {
        sortable: true,
        type: 'formula',
        text: '+5L',
        index: 9,
        show: this.IsShowSigma5 && this.responseLimitSigma >= 5,
        value: 'targetSigma5',
        align: 'left'
      }, {
        sortable: true,
        type: 'formula',
        text: '+6L',
        index: 10,
        show: this.IsShowSigma6 && this.responseLimitSigma >= 6,
        value: 'targetSigma6',
        align: 'left'
      }, {
        sortable: true,
        type: 'formula',
        text: '-3L',
        index: 11,
        show: this.IsShowSigma3 && this.responseLimitSigma >= 3,
        value: 'targetSigma-3',
        align: 'left'
      }, {
        sortable: true,
        type: 'formula',
        text: '-4L',
        index: 12,
        show: this.IsShowSigma4 && this.responseLimitSigma >= 4,
        value: 'targetSigma-4',
        align: 'left'
      }, {
        sortable: true,
        type: 'formula',
        text: '-5L',
        index: 13,
        show: this.IsShowSigma5 && this.responseLimitSigma >= 5,
        value: 'targetSigma-5',
        align: 'left'
      }, {
        sortable: true,
        type: 'formula',
        text: '-6L',
        index: 14,
        show: this.IsShowSigma6 && this.responseLimitSigma >= 6,
        value: 'targetSigma-6',
        align: 'left'
      }, {
        sortable: true,
        type: 'formula',
        text: '>+3L',
        index: 15,
        show: this.IsShowSigma3 && this.responseLimitSigma >= 3,
        value: 'targetAmount3',
        align: 'left'
      }, {
        sortable: true,
        type: 'formula',
        text: '>+4L',
        index: 16,
        show: this.IsShowSigma4 && this.responseLimitSigma >= 4,
        value: 'targetAmount4',
        align: 'left'
      }, {
        sortable: true,
        type: 'formula',
        text: '>+5L',
        index: 17,
        show: this.IsShowSigma5 && this.responseLimitSigma >= 5,
        value: 'targetAmount5',
        align: 'left'
      }, {
        sortable: true,
        type: 'formula',
        text: '>+6L',
        index: 18,
        show: this.IsShowSigma6 && this.responseLimitSigma >= 6,
        value: 'targetAmount6',
        align: 'left'
      }, {
        sortable: true,
        type: 'formula',
        text: '<-3L',
        index: 19,
        show: this.IsShowSigma3 && this.responseLimitSigma >= 3,
        value: 'targetAmount-3',
        align: 'left'
      }, {
        sortable: true,
        type: 'formula',
        text: '<-4L',
        index: 20,
        show: this.IsShowSigma4 && this.responseLimitSigma >= 4,
        value: 'targetAmount-4',
        align: 'left'
      }, {
        sortable: true,
        type: 'formula',
        text: '<-5L',
        index: 21,
        show: this.IsShowSigma5 && this.responseLimitSigma >= 5,
        value: 'targetAmount-5',
        align: 'left'
      }, {
        sortable: true,
        type: 'formula',
        text: '<-6L',
        index: 22,
        show: this.IsShowSigma6 && this.responseLimitSigma >= 6,
        value: 'targetAmount-6',
        align: 'left'
      }, {
        sortable: true,
        // type: 'options',
        type: 'keyword',
        text: 'Level',
        index: 23,
        show: true,
        value: 'lvl',
        align: 'left'
      }, {
        sortable: true,
        type: 'formula',
        text: 'Max',
        index: 24,
        show: true,
        value: 'max',
        align: 'left'
      }, {
        sortable: true,
        type: 'formula',
        text: 'Min',
        index: 25,
        show: true,
        value: 'min',
        align: 'left'
      }, {
        sortable: true,
        type: 'formula',
        text: 'Avg',
        index: 26,
        show: true,
        value: 'avg',
        align: 'left'
      }]
      return array
    },
    responseDataEarlyCompile () {
      const array = []
      this.responseData.forEach((r, rr) => {
        array.push(r)
        array[rr].index = rr
        array[rr].lvl = this.doCpkGradeName(r.cpk)
        array[rr].group = null
        array[rr].chipNo = null
        array[rr].testItemName = String(r.testItemName)
        if (r.note) {
          // eslint-disable-next-line no-unused-vars
          for (const [key, value] of Object.entries(r.note)) {
            array[rr][key] = String(value)
          }
        }
      })
      return array
    },
    filterDirections () {
      let result = ''
      if (this.filterInfo.type === 'formula') {
        if (this.filterBtnSelect.indexOf('le') > -1 && this.filterBtnSelect.indexOf('ge') > -1) {
          result = '介於' + this.filterMinValue + '~' + this.filterMaxValue + '之間'
        } else if (this.filterBtnSelect.indexOf('le') > -1) {
          result = '小於等於' + this.filterMaxValue
        } else if (this.filterBtnSelect.indexOf('ge') > -1) {
          result = '大於等於' + this.filterMinValue
        }
      } else if (this.filterInfo.type === 'keyword') {
        result = '包含「' + (this.filterKeyword || '') + '」的字詞'
      }
      return result
    },
    pages () {
      return Math.ceil(this.responseDataCompile.length / this.pagination.rowsPerPage)
    },
    pageslist () {
      let i = 0
      const list = []
      if (this.pagination.rowsPerPage) {
        for (i = 0; i < Math.ceil(this.responseDataCompile.length / this.pagination.rowsPerPage); i++) {
          list.push(i + 1)
        }
      }
      return list
    },
    options_line () {
      return {
        data: {
          x: 'x',
          columns: [
            this.barXaxisName,
            this.barXaxisStandard,
            this.barXaxisValue
          ],
          axes: {
            常態分佈: 'y2'
          },
          type: 'bar',
          types: {
            常態分佈: 'spline'
          }
        },
        axis: {
          x: {
            type: 'category',
            padding: {
              top: 0,
              right: 0.1,
              bottom: 0,
              left: 0.2
            },
            tick: {
              rotate: -45,
              multiline: false
            }
          },
          y: {
            tick: {
              format: function (value) { return value }
            },
            label: {
              text: '統計次數',
              position: 'outer-middle'
            }
          },
          y2: {
            tick: {
              format: function (value) { return value }
            },
            label: {
              text: '常態分佈',
              position: 'outer-middle'
            },
            show: true
          }
        },
        tooltip: {
          order: function (t1, t2) {
            return t1.id < t2.id
          },
          contents: (d, value, ratio, id) => {
            return '<table class="c3-tooltip"><tbody><tr><th colspan="2">' + this.barXaxisName[d[1].index + 1] + '</th></tr><tr class="c3-tooltip-name--統計次數"><td class="name"><span style="background-color:#1f77b4"></span>統計次數</td><td class="value">' + d[1].value + '</td></tr></tbody></table>'
          }
          // format: {
          //   title: (d, value) => {
          //     return `${this.barXaxisName[d + 1]}`
          //   },
          //   value: (value, ratio, id) => {
          //     // if (id === '常態分佈') {
          //     //   return `${value.toFixed(3)}`
          //     // } else {
          //     //   return `${value}`
          //     // }
          //   }
          // }
          // order: 'asc'
        },
        grid: {
          x: {
            show: true,
            lines: this.responseStandardUpperLower
          },
          y: {
            show: true
          }
        },
        legend: {
          position: 'bottom',
          padding: 16,
          show: false
        },
        size: {
          width: ((window.innerWidth - this.shrink) * 0.5) - 64,
          height: 500
        },
        spline: {
          interpolation: {
            type: 'monotone'
          }
        }
      }
    },
    // contentDateListWeeks () {
    //   const array = []
    //   this.summaryYearWeeksList.forEach((n, nn) => {
    //     n.forEach((w, ww) => {
    //       if (ww === n.length - 1 && Number(this.$moment(w.start).format('DD')) > 25) {
    //         for (let d = new Date((Number(this.$moment(this.startdate).format('YYYY')) + nn) + '-' + this.$moment(w.start).format('MM-DD')).getTime(); d <= new Date((Number(this.$moment(this.startdate).format('YYYY')) + nn + 1) + '-' + this.$moment(w.end).format('MM-DD')).getTime(); d = d + 86400000) {
    //           // console.log(this.$moment(d).format('YYYY-MM-DD'))
    //           if (this.contentDateList.indexOf(this.$moment(d).format('YYYY-MM-DD')) !== -1) {
    //             array.push(Number((Number(this.$moment(this.startdate).format('YYYY')) + nn) + (ww < 10 ? '0' + String(ww) : String(ww))))
    //           }
    //         }
    //       }
    //       for (let d = new Date((Number(this.$moment(this.startdate).format('YYYY')) + nn) + '-' + this.$moment(w.start).format('MM-DD')).getTime(); d <= new Date((Number(this.$moment(this.startdate).format('YYYY')) + nn) + '-' + this.$moment(w.end).format('MM-DD')).getTime(); d = d + 86400000) {
    //         // console.log(this.$moment(d).format('YYYY-MM-DD'))
    //         if (this.contentDateList.indexOf(this.$moment(d).format('YYYY-MM-DD')) !== -1) {
    //           array.push(Number((Number(this.$moment(this.startdate).format('YYYY')) + nn) + (ww < 10 ? '0' + String(ww) : String(ww))))
    //         }
    //       }
    //     })
    //   })
    //   return array
    // },
    // contentDateListWeeks () {
    //   const array = []
    //   this.summaryYearWeeksList.forEach((n, nn) => {
    //     n.forEach((w, ww) => {
    //       if (w) {
    //         for (let d = new Date(w.start).getTime(); d <= new Date(w.end).getTime(); d = d + 86400000) {
    //           if (this.contentDateList.indexOf(this.$moment(d).format('YYYY-MM-DD')) !== -1) {
    //             array.push(Number(this.$moment(w.start).format('YYYY') + (ww < 10 ? '0' + String(ww) : String(ww))))
    //           }
    //         }
    //       }
    //     })
    //   })
    //   return array
    // },
    contentDateListSeason () {
      const array = []
      this.summaryYearSeasonList.forEach((n, nn) => {
        n.forEach((s, ss) => {
          if (s) {
            for (let d = new Date(s.start).getTime(); d <= new Date(s.end).getTime(); d = d + 86400000) {
              if (this.contentDateList.indexOf(this.$moment(d).format('YYYY-MM-DD')) !== -1) {
                array.push(s.start)
              }
            }
          }
        })
      })
      return array
    }
  },
  watch: {
    summaryMonth (val) {
      this.summaryMonthMenu = false
    },
    summaryVendorName (val) {
      this.getPartNumber(val)
    },
    summaryPartNumber (val) {
      this.contentDateList = []
      if (val) this.getContentDateList(this.summaryVendorName, val, this.summaryDateType)
    },
    summaryDateType (val) {
      this.contentDateList = []
      if (this.summaryPartNumber) this.getContentDateList(this.summaryVendorName, this.summaryPartNumber, val)
    },
    'pagination.rowsPerPage' (val) {
      if (this.pagination.page > this.pages) {
        this.pagination.page = this.pages
      }
      if (this.pagination.page < 1) {
        this.pagination.page = 1
      }
    },
    pagination: {
      handler () {
        this.doTableSortDetail()
      },
      deep: true
    },
    responseDataEarlyCompile (val) {
      this.doResponseDataCompile()
    },
    filterSettingList (val) {
      this.doResponseDataCompile()
    },
    chartLineView (val) {
      if (val) {
        this.barXaxisName = ['x']
        this.barXaxisStandard = ['常態分佈']
        this.barXaxisValue = ['統計次數']
        if (this.responseStandardData.distributionCoverageList.length > 0) {
          this.responseStandardData.distributionCoverageList.forEach((r) => {
            if (r.define.type === 'Sigma') {
              this.barXaxisName.push(r.define.name)
              if (this.responseStandardData.standardDeviation === 0) {
                this.barXaxisStandard.push(r.amount.toFixed(3))
              } else {
                this.barXaxisStandard.push(new NormalDistribution(this.responseStandardData.average, this.responseStandardData.standardDeviation).pdf(r.define.value).toFixed(3))
              }
              this.barXaxisValue.push(r.amount)
            }
          })
        }
        if (this.chartLineViewNum === 0) {
          this.handler_line.$emit('init', this.options_line)
          this.chartLineViewNum++
        } else {
          this.handler_line.$emit('dispatch', chart => {
            const options = {
              columns: [
                this.barXaxisName,
                this.barXaxisStandard,
                this.barXaxisValue
              ]
            }
            chart.load(options)
            chart.xgrids(this.responseStandardUpperLower)
          })
        }
      } else {
        // this.handler_line.$emit('destroy')
      }
    }
  },
  mounted () {
    this.$store.dispatch('getAuthorize', '/keypart/speaker/speakerSingleProcessAnalyzer')
      .then(response => {
        if (response.status === 'success' && response.data.accessible) {
          JSON.parse(localStorage.getItem('moduleDataInitDate')).forEach((n) => {
            if (n.name === 'lcdModule') {
              this.startdate = n.value
            }
          })
          if (this.$route.params.searchDateType && this.$route.params.searchDateStart && this.$route.params.searchVendorName && this.$route.params.searchPartNumber) {
            this.summaryDateType = this.$route.params.searchDateType
            switch (this.$route.params.searchDateType) {
              case 'week':
                this.summaryWeeksStart = this.$route.params.searchDateStart
                break
              case 'month':
                this.summaryMonthStart = this.$moment(this.$route.params.searchDateStart).format('YYYY-MM')
                break
              case 'season':
                this.summarySeasonStart = this.$route.params.searchDateStart
                break
            }
            this.tempVendorName = this.$route.params.searchVendorName
            this.tempPartNumber = this.$route.params.searchPartNumber
          }
          this.$http.get('/api/keypart/speaker/vendorName').then(res => res.data)
            .then(res => {
              if (res.status === 'success') {
                this.summaryVendorNameMenu = []
                res.data.forEach((n) => {
                  this.summaryVendorNameMenu.push({ text: n, value: n })
                })
                if (this.tempVendorName !== '') {
                  this.summaryVendorName = this.tempVendorName
                }
              }
            })
          this.loadData()
        } else if (response.errCode === 'E10000002') {
          const routeData = this.$router.resolve({ path: '/noEnter/E10000002' })
          localStorage.setItem('errormsg', response.errMsg)
          window.location.assign(routeData.href)
        } else {
          const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
          window.location.assign(routeData.href)
        }
      })
      .catch(err => {
        console.log(err)
        const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
        window.location.assign(routeData.href)
      })
  },
  methods: {
    selectedWeek (val) {
      this.summaryWeeksStart = val
    },
    async loadData () {
      this.loadingView = true
      await this.YearSeasonList()
      await this.AllYearSeasonList()
      // await this.YearWeeksList()
      // await this.AllYearWeeksList()
    },
    // YearWeeksList () {
    //   const Arr = []
    //   const YearStart = this.$moment(this.startdate).format('YYYY')
    //   const YearEnd = this.$moment(new Date()).format('YYYY')
    //   let NewYear = false
    //   let YearEndWeek = 0

    //   for (let y = 0; y <= (YearEnd - YearStart + 1); y++) {
    //     Arr[y] = []
    //     let w = 0
    //     let n = 0
    //     let day = new Date(Number(YearStart) + y + '-01-01').getTime()
    //     if (y === 0 && this.$moment(this.startdate).format('MM-DD') !== '01-01') {
    //       day = new Date(Number(YearStart) + y + '-' + this.$moment(this.startdate).format('MM-DD')).getTime()
    //       w = Math.ceil((((new Date(this.$moment(this.startdate).format('YYYY-MM-DD')) - new Date(new Date(this.$moment(this.startdate).format('YYYY'), 0, 1))) / 86400000) + new Date(new Date(this.$moment(this.startdate).format('YYYY'), 0, 1)).getDay()) / 7)
    //       if (new Date(this.$moment(this.startdate).format('YYYY') + '-01-01').getDay() > 0) {
    //         w = w - 1
    //       }
    //       Arr[y][w] = { start: this.$moment(this.startdate).format('YYYY-MM-DD'), end: '' }
    //       if (new Date(this.$moment(this.startdate).format('YYYY-MM-DD')).getDay() === 6) {
    //         Arr[y][w].end = this.$moment(this.startdate).format('YYYY-MM-DD')
    //       } else if (new Date(this.$moment(this.startdate).format('YYYY-MM-DD')).getDay() === 0) {
    //         w = w - 1
    //       }
    //     } else if (new Date(Number(YearStart) + y + '-01-01').getDay() !== 0) {
    //       n = 6 - new Date(Number(YearStart) + y + '-01-01').getDay()
    //       Arr[y][1] = { start: '', end: '' }
    //     }
    //     for (let d = (day + (86400000 * n)); d <= (new Date(Number(YearStart) + y + '-12-31').getTime()); d = d + 86400000) {
    //       if (y > 0 && new Date(d).getDay() === 6) {
    //         if (Arr[y][1].start === '') {
    //           Arr[y - 1][YearEndWeek].end = this.$moment(d).format('YYYY-MM-DD')
    //           Arr[y][0] = { start: Arr[y - 1][YearEndWeek].start, end: this.$moment(d).format('YYYY-MM-DD') }
    //           w = 0
    //           YearEndWeek = w
    //           NewYear = true
    //         }
    //       }
    //       if (new Date(d).getDay() === 0) {
    //         w++
    //         YearEndWeek = w
    //         NewYear = false
    //         Arr[y][w] = { start: this.$moment(d).format('YYYY-MM-DD'), end: '' }
    //       } else if (!NewYear && new Date(d).getDay() === 6) {
    //         Arr[y][w].end = this.$moment(d).format('YYYY-MM-DD')
    //       }
    //     }
    //   }
    //   this.summaryYearWeeksList = Arr
    // },
    // AllYearWeeksList () {
    //   const Arr = []
    //   const YearStart = this.$moment(this.startdate).format('YYYY')
    //   let YearEnd = this.$moment(this.summaryMonth).format('YYYY')
    //   let LastYear = 0
    //   if (new Date(this.$moment(this.summaryMonth).format('YYYY') + '-01-01').getDay() > 0) {
    //     LastYear = 1
    //   }
    //   const WeeksEnd = Math.ceil((((new Date(this.$moment(new Date()).format('YYYY-MM-DD')) - new Date(new Date(this.$moment(this.summaryMonth).format('YYYY'), 0, 1))) / 86400000) + new Date(new Date(this.$moment(this.summaryMonth).format('YYYY'), 0, 1)).getDay()) / 7) - LastYear
    //   if (WeeksEnd === 0) {
    //     YearEnd--
    //   }
    //   for (let y = 0; y <= (YearEnd - YearStart); y++) {
    //     for (let w = 1; w < this.summaryYearWeeksList[y].length; w++) {
    //       if (y === (YearEnd - YearStart) && w === WeeksEnd) {
    //         Arr.push({ text: (Number(YearStart) + y) + 'w' + w + '( ' + this.$moment(this.summaryYearWeeksList[y][w].start).format('MM-DD') + ' ～ ' + this.$moment(this.summaryYearWeeksList[y][w].end).format('MM-DD') + ' )', value: (Number(YearStart) + y) * 100 + w })
    //         break
    //       }
    //       if (this.summaryYearWeeksList[y][w]) {
    //         Arr.push({ text: (Number(YearStart) + y) + 'w' + w + '( ' + this.$moment(this.summaryYearWeeksList[y][w].start).format('MM-DD') + ' ～ ' + this.$moment(this.summaryYearWeeksList[y][w].end).format('MM-DD') + ' )', value: (Number(YearStart) + y) * 100 + w })
    //       }
    //     }
    //   }
    //   Arr.sort(function (a, b) { return b.value - a.value })
    //   this.summaryAllYearWeeksList = Arr
    //   this.loadingView = false
    // },
    YearSeasonList () {
      const Arr = []
      const startYear = this.$moment(this.startdate).format('YYYY')
      const endYear = this.$moment(new Date()).format('YYYY')

      for (let year = startYear; year <= endYear; year++) {
        Arr[Number(year) - Number(startYear)] = []
        for (let month = 1; month < 12; month += 3) {
          const seasonStartMonth = month
          const seasonEndMonth = month + 3 > 12 ? month + 3 - 12 : month + 3

          // const season = `${month + 3 > 12 ? year + 1 : year} Q${Math.ceil(seasonStartMonth / 3)} `
          // const seasonRange = { start: this.$moment(year + '-' + (seasonStartMonth + 1) + '-01').format('YYYY-MM-DD'), end: this.$moment(new Date((month + 3 > 12 ? Number(year) + 1 : year) + '-' + (seasonEndMonth + 1) + '-01').getTime() - 86400000).format('YYYY-MM-DD') }
          const seasonRange = { start: this.$moment(year + '-' + seasonStartMonth + '-01').format('YYYY-MM-DD'), end: this.$moment(new Date(year + '-' + seasonEndMonth + '-01').getTime() - 86400000).format('YYYY-MM-DD') }
          Arr[Number(year) - Number(startYear)][Math.floor(seasonStartMonth / 3)] = seasonRange
          // Arr.push({ text: season + seasonRange, value: seasonRange })
        }
      }

      this.summaryYearSeasonList = Arr
    },
    AllYearSeasonList () {
      const Arr = []
      const startDate = this.$moment(this.startdate).format('YYYY-MM-DD')
      const endDate = this.$moment(new Date()).format('YYYY-MM-DD')

      this.summaryYearSeasonList.forEach((year, y) => {
        year.forEach((season, q) => {
          if (new Date(season.start).getTime() < new Date(endDate).getTime() && new Date(season.end).getTime() > new Date(startDate).getTime()) {
            Arr.push({ text: (Number(this.$moment(this.startdate).format('YYYY')) + y) + '　Q' + (q + 1) + '　( ' + this.$moment(season.start).format('MM-DD') + ' ～ ' + this.$moment(season.end).format('MM-DD') + ' )', value: season.start })
          }
        })
      })

      this.summaryAllYearSeasonList = Arr.reverse()
      this.loadingView = false
    },
    getCpkGradeList () {
      this.$http.get('/api/keypart/support/cpkEvaluationList').then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.CpkGradeList = res.data
          }
        })
    },
    getFilterResult (array) {
      let result = true
      this.filterSettingDetailList.forEach((n, nn) => {
        if (n !== undefined) {
          if (this.responseHeaders[nn].type === 'formula') {
            if (n[0].indexOf('le') !== -1 && n[0].indexOf('ge') !== -1) {
              if (!(array[this.responseHeaders[nn].value] >= n[1] && array[this.responseHeaders[nn].value] <= n[2])) {
                result = false
              }
            } else if (n[0].indexOf('le') !== -1) {
              if (!(array[this.responseHeaders[nn].value] <= n[2])) {
                result = false
              }
            } else if (n[0].indexOf('ge') !== -1) {
              if (!(array[this.responseHeaders[nn].value] >= n[1])) {
                result = false
              }
            }
          } else if (this.responseHeaders[nn].type === 'keyword') {
            if (array[this.responseHeaders[nn].value].toUpperCase().indexOf(n.toUpperCase()) === -1) {
              result = false
            }
          }
        }
      })
      return result
    },
    doHeadersShow (val) {
      const array = []
      this.responseDataCompile.forEach(r => {
        if (r[val] !== null) {
          array.push(r[val])
        }
      })
      return array.length > 0
    },
    doResponseDataCompile () {
      this.responseDataCompile = []
      this.responseDataEarlyCompile.forEach((t, tt) => {
        if (this.getFilterResult(t)) {
          this.responseDataCompile.push(t)
          this.doTableSortDetail()
        }
      })
    },
    doTableSort (val) {
      if (this.pagination.sortBy === val) {
        if (this.pagination.descending === false) {
          this.pagination.descending = true
        } else if (this.pagination.descending === true) {
          this.pagination.descending = null
          this.pagination.sortBy = null
        } else if (this.pagination.descending === null) {
          this.pagination.descending = false
        }
      } else {
        this.pagination.sortBy = val
        this.pagination.descending = false
      }
    },
    doTableSortDetail () {
      if (this.pagination.descending === null) {
        this.responseDataCompile.sort((a, b) => {
          return parseFloat(a.index) - parseFloat(b.index)
        })
      } else if (this.pagination.descending) {
        if (this.responseHeaders[this.responseHeaders.map(function (e) { return e.value }).indexOf(this.pagination.sortBy)].type === 'keyword') {
          this.responseDataCompile.sort((a, b) => b[this.pagination.sortBy].localeCompare(a[this.pagination.sortBy]))
        } else {
          this.responseDataCompile.sort((a, b) => {
            return parseFloat(b[this.pagination.sortBy]) - parseFloat(a[this.pagination.sortBy])
          })
        }
      } else {
        if (this.responseHeaders[this.responseHeaders.map(function (e) { return e.value }).indexOf(this.pagination.sortBy)].type === 'keyword') {
          this.responseDataCompile.sort((a, b) => a[this.pagination.sortBy].localeCompare(b[this.pagination.sortBy]))
        } else {
          this.responseDataCompile.sort((a, b) => {
            return parseFloat(a[this.pagination.sortBy]) - parseFloat(b[this.pagination.sortBy])
          })
        }
      }
    },
    doMinValueReplaceText () {
      this.filterMinValue = this.filterMinValue.replace(/^[^0-9-]*(-?)(\d*(?:\.\d{0,2})?).*$/g, '$1$2')
    },
    doMaxValueReplaceText () {
      this.filterMaxValue = this.filterMaxValue.replace(/^[^0-9-]*(-?)(\d*(?:\.\d{0,2})?).*$/g, '$1$2')
    },
    doWarnGroupCpk (index) {
      let result = false
      if (!this.doCpkGrade(this.responseDataCompile[index].cpk)) {
        result = true
      }
      return result
    },
    doCpkGrade (cpk) {
      let result = false
      this.CpkGradeList.forEach(e => {
        if ((e.upperSpecLimit ? e.upperSpecLimit : 999999999999999) > cpk && cpk >= (e.lowerSpecLimit ? e.lowerSpecLimit : -999999999999999)) {
          result = (e.status === 'qualified')
        }
      })
      return result
    },
    doCpkGradeName (cpk) {
      let result = false
      this.CpkGradeList.forEach(e => {
        if ((e.upperSpecLimit ? e.upperSpecLimit : 999999999999999) > cpk && cpk >= (e.lowerSpecLimit ? e.lowerSpecLimit : -999999999999999)) {
          result = e.evaluation
        }
      })
      return result
    },
    dofilterViewOpen (type, index, group, item) {
      this.filterTitle = group ? group + '-' + item : item
      this.filterInfo = {
        type: type,
        index: index,
        group: group,
        item: item
      }
      if (type === 'formula') {
        this.filterBtnSelect = this.filterSettingDetailList[index] ? this.filterSettingDetailList[index][0] : []
        this.filterMinValue = this.filterSettingDetailList[index] ? this.filterSettingDetailList[index][1] : 0
        this.filterMaxValue = this.filterSettingDetailList[index] ? this.filterSettingDetailList[index][2] : 0
      } else if (type === 'keyword') {
        this.filterKeyword = this.filterSettingList[index]
      }
      this.filterView = true
    },
    dofilterSetting () {
      if (this.filterInfo.type === 'formula') {
        if (this.filterBtnSelect.indexOf('le') > -1 && this.filterBtnSelect.indexOf('ge') > -1) {
          if (Number(this.filterMaxValue) >= Number(this.filterMinValue)) {
            this.filterSettingDetailList[this.filterInfo.index] = [this.filterBtnSelect, this.filterMinValue, this.filterMaxValue]
            this.filterSettingList.splice(this.filterInfo.index, 1, this.filterMinValue + '≤ X ≤' + this.filterMaxValue)
            this.filterView = false
          } else {
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = '左側數字不得大於右側數字'
          }
        } else if (this.filterBtnSelect.indexOf('le') > -1) {
          this.filterSettingDetailList[this.filterInfo.index] = [this.filterBtnSelect, 0, this.filterMaxValue]
          this.filterSettingList.splice(this.filterInfo.index, 1, '≤' + this.filterMaxValue)
          this.filterView = false
        } else if (this.filterBtnSelect.indexOf('ge') > -1) {
          this.filterSettingDetailList[this.filterInfo.index] = [this.filterBtnSelect, this.filterMinValue, 0]
          this.filterSettingList.splice(this.filterInfo.index, 1, '≥' + this.filterMinValue)
          this.filterView = false
        } else {
          this.snackbar = true
          this.snackbarColor = 'red'
          this.snackbarMsg = '請輸入條件'
        }
      } else if (this.filterInfo.type === 'keyword') {
        this.filterSettingDetailList[this.filterInfo.index] = this.filterKeyword
        this.filterSettingList.splice(this.filterInfo.index, 1, this.filterKeyword)
        this.filterView = false
      }
    },
    dofilterClear (index) {
      this.filterSettingDetailList[index] = undefined
      this.filterSettingList.splice(index, 1, undefined)
    },
    dofilterALLClear () {
      this.filterSettingDetailList = new Array(9)
      this.filterSettingList = new Array(9)
    },
    getPartNumber (vendor) {
      const body = {
        vendorName: vendor
      }
      this.$http.get('/api/keypart/speaker/partNumber?' + qs.stringify(body)).then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.summaryPartNumberMenu = []
            this.summaryPartNumber = ''
            res.data.forEach((n) => {
              this.summaryPartNumberMenu.push({ text: n, value: n })
            })
            if (this.tempPartNumber !== '') {
              this.summaryPartNumber = this.tempPartNumber
              this.tempVendorName = ''
              this.tempPartNumber = ''
              this.doSubmit()
            }
          }
        })
    },
    getContentDateList (vendor, partNumber, statistic) {
      const body = {
        vendorName: vendor,
        partNumber: partNumber,
        statisticType: statistic
      }
      this.$http.get('/api/keypart/speaker/manufacturingDate?' + qs.stringify(body)).then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.contentDateList = res.data
          }
        })
    },
    async doSubmit () {
      this.loadingView = true
      this.processingStatus = false
      this.summaryNoData = true
      // let wwS = 0

      // if (Number.isInteger(this.summaryWeeksStart)) {
      //   wwS = String(this.summaryWeeksStart).substr(4)
      // } else {
      //   wwS = Math.ceil((((new Date(this.$moment(new Date()).format('YYYY-MM-DD')) - new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1))) / 86400000) + new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1)).getDay()) / 7) - parseInt((new Date(this.$moment(new Date()).format('YYYY') + '-01-01').getDay() + 5) / 6)
      // }

      const dateType = this.summaryDateType

      let manufactureStart = this.$moment(new Date()).format('YYYY-MM-DD')
      // let manufactureEnd = this.$moment(new Date()).format('YYYY-MM-DD')
      switch (dateType) {
        case 'week':
          // manufactureStart = this.$moment(this.summaryYearWeeksList[String(this.summaryWeeksStart).substr(0, 4) - this.$moment(this.startdate).format('YYYY')][Number(wwS)].start).format('YYYY-MM-DD')
          // manufactureEnd = this.$moment(this.summaryYearWeeksList[String(this.summaryWeeksStart).substr(0, 4) - this.$moment(this.startdate).format('YYYY')][Number(wwS)].end).format('YYYY-MM-DD')
          manufactureStart = this.summaryWeeksStart
          // manufactureStart = this.$moment().year(String(this.summaryWeeksStart).substr(0, 4)).isoWeek(String(this.summaryWeeksStart).substr(4, 2)).startOf('isoWeek').format('YYYY-MM-DD')
          break
        case 'month':
          manufactureStart = this.summaryMonthStart + '-01'
          // manufactureEnd = this.$moment(new Date(new Date(manufactureStart).getFullYear(), new Date(manufactureStart).getMonth() + 1, 0)).format('YYYY-MM-DD')
          break
        case 'season':
          manufactureStart = this.summarySeasonStart
          // manufactureEnd = this.summaryYearSeasonList.flat(Infinity).filter((x) => x.start === manufactureStart)[0].end
          break
      }

      // const manufactureWeeksStart = this.summaryWeeksStart
      const vendorName = this.summaryVendorName
      const partNumber = this.summaryPartNumber
      this.responseData = []
      await this.getCpkGradeList()
      await this.doSubmitTypeDetail(dateType, vendorName, partNumber, manufactureStart)
      // await this.doSubmitAfter(dateType, vendorName, partNumber, manufactureStart, manufactureEnd, (this.summaryDateType === 'week' ? manufactureWeeksStart : manufactureStart))
      await this.doSubmitAfter(dateType, vendorName, partNumber, manufactureStart)
    },
    doSubmitTypeDetail (dateType, vendorName, partNumber, manufactureStart) {
      const body = {
        dateType: dateType,
        date: manufactureStart,
        vendorName: vendorName,
        partNumber: partNumber
      }
      this.$http.get('/api/keypart/speaker/detailSpeakerAnalysisDataList?' + qs.stringify(body)).then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            if (res.data.testRecordList.length > 0) {
              this.summaryNoData = false
              // this.responseData = res.data.testRecordList
              this.responseData = [...res.data.testRecordList.map(e => {
                let obj = {}
                e.distributionCoverageList.map((d) => {
                  obj[`targetAmount${String(d.define.sigma)}`] = d.amount
                  obj[`targetSigma${String(d.define.sigma)}`] = d.define.value
                })
                return { ...e, ...obj }
              })]
              this.responseModelList = res.data.modelList
              this.responseLimitSigma = res.data.limitSigma
              this.responseTotalInput = res.data.totalInput
            }
            this.loadingView = false
            this.processingStatus = true
          } else { // failure
            this.loadingView = false
            alert('系統錯誤，請洽系統管理人員\n錯誤訊息：' + res.errMsg)
          }
        })
        .catch(err => {
          this.loadingView = false
          alert('系統錯誤，請洽系統管理人員\n錯誤訊息：' + err)
        })
      // const resdata = {
      //   vendor: 'VECOFS',
      //   partNumber: '04072-05320100',
      //   modelList: ['AX501', 'AX502'],
      //   limitSigma: 6,
      //   totalInput: 7504,
      //   testRecords: [{
      //     testItemName: 'L-THD_250Hz',
      //     speakerType: 'Pair-LR2',
      //     testItemType: 'THD',
      //     upperSpecValue: 0.35,
      //     lowerSpecValue: -9999.0,
      //     cpk: 0.7544,
      //     distributionCoverageList: [
      //       {
      //         define: {
      //           name: 'Avg+1σ',
      //           type: 'Sigma',
      //           value: 0.2549439889928354,
      //           sigma: 1
      //         },
      //         amount: 1607
      //       },
      //       {
      //         define: {
      //           name: 'Avg+2σ',
      //           type: 'Sigma',
      //           value: 0.33019901210080993,
      //           sigma: 2
      //         },
      //         amount: 0
      //       },
      //       {
      //         define: {
      //           name: 'Avg+3σ',
      //           type: 'Sigma',
      //           value: 0.40545403520878454,
      //           sigma: 3
      //         },
      //         amount: 0
      //       },
      //       {
      //         define: {
      //           name: 'Avg+4σ',
      //           type: 'Sigma',
      //           value: 0.48070905831675914,
      //           sigma: 4
      //         },
      //         amount: 0
      //       },
      //       {
      //         define: {
      //           name: 'Avg+5σ',
      //           type: 'Sigma',
      //           value: 0.5559640814247337,
      //           sigma: 5
      //         },
      //         amount: 0
      //       },
      //       {
      //         define: {
      //           name: 'Avg+6σ',
      //           type: 'Sigma',
      //           value: 0.6312191045327084,
      //           sigma: 6
      //         },
      //         amount: 0
      //       },
      //       {
      //         define: {
      //           name: 'Avg-1σ',
      //           type: 'Sigma',
      //           value: 0.10443394277688618,
      //           sigma: -1
      //         },
      //         amount: 1602
      //       },
      //       {
      //         define: {
      //           name: 'Avg-2σ',
      //           type: 'Sigma',
      //           value: 0.02917891966891159,
      //           sigma: -2
      //         },
      //         amount: 0
      //       },
      //       {
      //         define: {
      //           name: 'Avg-3σ',
      //           type: 'Sigma',
      //           value: -0.046076103439063,
      //           sigma: -3
      //         },
      //         amount: 0
      //       },
      //       {
      //         define: {
      //           name: 'Avg-4σ',
      //           type: 'Sigma',
      //           value: -0.1213311265470376,
      //           sigma: -4
      //         },
      //         amount: 0
      //       },
      //       {
      //         define: {
      //           name: 'Avg-5σ',
      //           type: 'Sigma',
      //           value: -0.19658614965501217,
      //           sigma: -5
      //         },
      //         amount: 0
      //       },
      //       {
      //         define: {
      //           name: 'Avg-6σ',
      //           type: 'Sigma',
      //           value: -0.27184117276298675,
      //           sigma: -6
      //         },
      //         amount: 0
      //       },
      //       {
      //         define: {
      //           name: 'LL',
      //           type: 'Spec_limit',
      //           value: -9999.0,
      //           sigma: 0
      //         },
      //         amount: 0
      //       },
      //       {
      //         define: {
      //           name: 'UL',
      //           type: 'Spec_limit',
      //           value: 0.35,
      //           sigma: 0
      //         },
      //         amount: 0
      //       }
      //     ],
      //     max: 0.31,
      //     min: 0.051,
      //     avg: 0.17968896588486077
      //   }, {
      //     testItemName: 'L-RUB_250Hz',
      //     speakerType: 'Pair-LR2',
      //     testItemType: 'RUB',
      //     upperSpecValue: 0.1,
      //     lowerSpecValue: -9999.0,
      //     cpk: 0.7423,
      //     distributionCoverageList: [
      //       {
      //         define: {
      //           name: 'Avg+1σ',
      //           type: 'Sigma',
      //           value: 0.07544536357190156,
      //           sigma: 1
      //         },
      //         amount: 1548
      //       },
      //       {
      //         define: {
      //           name: 'Avg+2σ',
      //           type: 'Sigma',
      //           value: 0.09545842437194829,
      //           sigma: 2
      //         },
      //         amount: 0
      //       },
      //       {
      //         define: {
      //           name: 'Avg+3σ',
      //           type: 'Sigma',
      //           value: 0.11547148517199501,
      //           sigma: 3
      //         },
      //         amount: 0
      //       },
      //       {
      //         define: {
      //           name: 'Avg+4σ',
      //           type: 'Sigma',
      //           value: 0.13548454597204174,
      //           sigma: 4
      //         },
      //         amount: 0
      //       },
      //       {
      //         define: {
      //           name: 'Avg+5σ',
      //           type: 'Sigma',
      //           value: 0.1554976067720885,
      //           sigma: 5
      //         },
      //         amount: 0
      //       },
      //       {
      //         define: {
      //           name: 'Avg+6σ',
      //           type: 'Sigma',
      //           value: 0.1755106675721352,
      //           sigma: 6
      //         },
      //         amount: 0
      //       },
      //       {
      //         define: {
      //           name: 'Avg-1σ',
      //           type: 'Sigma',
      //           value: 0.0354192419718081,
      //           sigma: -1
      //         },
      //         amount: 1594
      //       },
      //       {
      //         define: {
      //           name: 'Avg-2σ',
      //           type: 'Sigma',
      //           value: 0.01540618117176137,
      //           sigma: -2
      //         },
      //         amount: 0
      //       },
      //       {
      //         define: {
      //           name: 'Avg-3σ',
      //           type: 'Sigma',
      //           value: -0.00460687962828536,
      //           sigma: -3
      //         },
      //         amount: 0
      //       },
      //       {
      //         define: {
      //           name: 'Avg-4σ',
      //           type: 'Sigma',
      //           value: -0.02461994042833209,
      //           sigma: -4
      //         },
      //         amount: 0
      //       },
      //       {
      //         define: {
      //           name: 'Avg-5σ',
      //           type: 'Sigma',
      //           value: -0.04463300122837882,
      //           sigma: -5
      //         },
      //         amount: 0
      //       },
      //       {
      //         define: {
      //           name: 'Avg-6σ',
      //           type: 'Sigma',
      //           value: -0.06464606202842554,
      //           sigma: -6
      //         },
      //         amount: 0
      //       },
      //       {
      //         define: {
      //           name: 'LL',
      //           type: 'Spec_limit',
      //           value: -9999.0,
      //           sigma: 0
      //         },
      //         amount: 0
      //       },
      //       {
      //         define: {
      //           name: 'UL',
      //           type: 'Spec_limit',
      //           value: 0.1,
      //           sigma: 0
      //         },
      //         amount: 0
      //       }
      //     ],
      //     max: 0.09,
      //     min: 0.021,
      //     avg: 0.05543230277185483
      //   }]
      // }

      // this.summaryNoData = false
      // this.responseData = resdata.testRecords
      // this.responseModelList = resdata.modelList
      // this.responseLimitSigma = resdata.limitSigma
      // this.responseTotalInput = resdata.totalInput
      // this.loadingView = false
      // this.processingStatus = true
    },
    // doSubmitAfter (dateType, vendorName, partNumber, manufactureStart, manufactureEnd, manufactureWeeksStart) {
    doSubmitAfter (dateType, vendorName, partNumber, manufactureStart) {
      this.responseVendorName = vendorName
      this.responsePartNumber = partNumber
      this.responseDateType = dateType
      // this.responseWeeksStart = manufactureWeeksStart
      this.responseDateStart = manufactureStart
      // this.responseDateEnd = manufactureEnd
      // const Url = '/keypart/speaker/speakerSingleProcessAnalyzer/' + this.responseDateType + '/' + (this.summaryDateType === 'week' ? this.responseWeeksStart : this.responseDateStart) + '/' + this.responseVendorName + '/' + this.responsePartNumber
      const Url = '/keypart/speaker/speakerSingleProcessAnalyzer/' + this.responseDateType + '/' + this.responseDateStart + '/' + this.responseVendorName + '/' + this.responsePartNumber
      if (this.$router.history.current.path !== Url) {
        this.$router.replace(Url)
      }
    },
    doSubmitSingle (speakerType, testItemName) {
      this.loadingView = true

      const body = {
        dateType: this.responseDateType,
        date: this.responseDateStart,
        vendorName: this.responseVendorName,
        partNumber: this.responsePartNumber,
        testItemName: testItemName,
        speakerType: speakerType
      }

      this.$http.get('/api/keypart/speaker/singleSpeakerCpkItem?' + qs.stringify(body)).then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.responseStandardData = res.data
            this.responseStandardData.distributionCoverageList.sort(function (a, b) {
              return parseFloat(a.define.value) - parseFloat(b.define.value)
            })
            this.chartViewTestItemName = testItemName
            this.chartViewSpeakerType = speakerType
            this.chartLineView = true
            this.loadingView = false
          } else { // failure
            this.loadingView = false
            alert('系統錯誤，請洽系統管理人員\n錯誤訊息：' + res.errMsg)
          }
        })
        .catch(err => {
          this.loadingView = false
          alert('系統錯誤，請洽系統管理人員\n錯誤訊息：' + err)
        })
    },
    doDownload (testItemName, limitSigma, targetSigma, speakerType) {
      this.processingView = true
      const body = {
        date: this.responseDateStart,
        vendorName: this.responseVendorName,
        partNumber: this.responsePartNumber,
        dateType: this.responseDateType,
        limitSigma: limitSigma,
        testItemName: testItemName,
        targetSigma: targetSigma,
        speakerType: speakerType
      }
      this.$http.get('/api/keypart/speaker/speakerManufacturingRawDataFile?' + qs.stringify(body, { arrayFormat: 'repeat', encode: true }), { observe: 'response', responseType: 'blob' })
        .then(res => {
          this.processingView = false
          if (res.data.type === 'application/json') {
            return new Promise((resolve, reject) => {
              const reader = new FileReader()
              reader.onload = () => {
                res.data = JSON.parse(reader.result)
                if (res.data.status === 'success') {
                  alert(res.data.data)
                } else {
                  this.snackbar = true
                  this.snackbarColor = 'red'
                  this.snackbarMsg = res.data.errMsg + ' (' + res.data.errCode + ')'
                }
              }
              reader.onerror = () => {
                reject(res.data.errMsg || res.data.data)
              }
              reader.readAsText(res.data)
            })
          } else if (res.data.status !== 'failure' && res.headers['content-disposition']) {
            const blob = new Blob([res.data], { type: 'application/octet-stream' })
            const url = window.URL.createObjectURL(blob)
            const fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]
            const downloadLink = document.createElement('a')
            downloadLink.href = url
            downloadLink.download = fileName

            document.body.appendChild(downloadLink)
            downloadLink.click()
            document.body.removeChild(downloadLink)
          } else {
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = '下載失敗，請通知系統管理員'
          }
        })
        .catch(err => {
          this.processingView = false
          this.snackbar = true
          this.snackbarColor = 'orange'
          this.snackbarMsg = err
        })
    }
  }
}
</script>
<style>
</style>
