<template>
  <v-container
    cfr
    fluid
    grid-list-xl
  >
    <v-layout wrap>
      <v-flex
        v-if="resBookmarkList.length>0"
        xs12
        style="padding: 0px 12px!important;margin-bottom: 10px;">
        <material-card class="bookmark-area">
          <v-container class="p-0 pb-3 mw-100">
            <v-layout wrap>
              <v-flex
                flex-small
                xs12
                lg12
                style="display: flex !important;flex-flow: wrap;padding: 2px 12px!important;">
                <div style="display: flex !important;align-items: center !important;">
                  <h5 class="my-0 mr-2 d-inline-flex">
                    <v-icon class="mr-0 text-deep-orange">mdi-bookmark-check</v-icon>常用分析
                    <v-checkbox
                      v-model="bookmarkNoQuery"
                      :value="true"
                      class="m-0 py-0"
                      style="right: 15px;position: absolute;"
                      label="僅載入圖表線組資料(不查詢)"/>
                  </h5>
                </div>
              </v-flex>
            </v-layout>
          </v-container>
          <v-card-actions class="m-0 pt-3 px-0 border-top border-secondary">
            <v-container class="p-0 mw-100">
              <v-layout wrap>
                <v-flex
                  flex-small
                  xs12
                  lg12
                  style="display: flex !important;flex-flow: wrap;padding: 2px 12px!important;"
                  class="bookmark-list">
                  <div
                    v-for="(bookmark,b) in resBookmarkList"
                    :key="'b'+b"
                    class="bookmark"
                  >
                    <div>
                      <v-icon>mdi-bookmark</v-icon>
                      <v-icon
                        class="mr-0"
                        @click="copyBookmarkView=true;doCopyBookmarkInfo(bookmark.bookmarkName,bookmark.bookmarkToken)">mdi-content-copy</v-icon>
                      <span @click="getBookmarkContent(bookmark.bookmarkToken);(code!==bookmark.bookmarkToken)?$router.push({ params: { code: bookmark.bookmarkToken } }):''">{{ bookmark.bookmarkName }}</span>
                      <v-icon
                        class="mr-0"
                        @click="doDelBookmark(bookmark.pk)">mdi-close</v-icon>
                    </div>
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-actions>
        </material-card>
      </v-flex>
      <v-flex
        xs12
        style="padding: 0px 12px!important;margin-bottom: 10px;">
        <material-card>
          <v-container class="p-0 pb-3 mw-100">
            <v-layout wrap>
              <v-flex
                flex-small
                xs12
                lg12
                style="display: flex !important;justify-content: space-between;padding: 2px 12px!important;">
                <div style="display: flex !important;align-items: center !important;">
                  <h5 class="my-0 mr-2 d-inline-flex"><v-icon class="mr-0 text-warning">mdi-numeric-1-box</v-icon>圖表設定</h5>
                  <v-btn
                    :class="{'select': lineType===0}"
                    class="line-type line"
                    small
                    @click="chartType=0;lineType=0">
                    <v-icon class="mr-0">mdi-chart-line</v-icon>折線圖
                  </v-btn>
                  <v-btn
                    :class="{'select': lineType===1}"
                    class="line-type bar"
                    small
                    @click="chartType=0;lineType=1">
                    <v-icon class="mr-0">mdi-chart-bar</v-icon>直條圖
                  </v-btn>
                  <v-btn
                    :class="{'select': lineType===2}"
                    class="line-type pie"
                    small
                    @click="chartType=1;lineType=2">
                    <v-icon class="mr-0">mdi-chart-pie</v-icon>圓餅圖
                  </v-btn>
                </div>
              </v-flex>
            </v-layout>
          </v-container>
          <v-card-actions class="m-0 p-0 border-top border-secondary">
            <v-container class="p-0 mw-100">
              <v-layout wrap>
                <v-flex
                  d-flex
                  align-items-center
                  xs12
                  lg12>
                  <p style="max-width: 150px;margin: 0!important;">圖表名稱</p>
                  <v-text-field
                    v-model="chartName"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    label=""/>
                </v-flex>
                <v-flex
                  d-flex
                  align-items-center
                  xs12
                  lg5
                  style="padding-top: 0px!important;margin-top: -15px;">
                  <p style="max-width: 150px;margin: 0!important;">計算不良率分母</p>
                  <v-radio-group
                    v-model="statisticType"
                    class="m-0 py-0"
                    row>
                    <v-radio
                      label="激活數"
                      value="Active"/>
                    <v-radio
                      label="銷售數"
                      value="Sales"/>
                    <v-radio
                      label="出貨數"
                      value="EMS"/>
                    <v-radio
                      label="無分母"
                      value="None"/>
                  </v-radio-group>
                </v-flex>
                <v-flex
                  d-flex
                  align-items-center
                  xs12
                  lg3
                  style="padding-top: 0px!important;margin-top: -15px;">
                  <v-checkbox
                    v-model="excludeBestBuy"
                    :value="true"
                    class="m-0 py-0"
                    label="排除 Best BUY"/>
                </v-flex>
                <v-flex
                  v-if="chartType===0"
                  d-flex
                  align-items-center
                  xs12
                  lg4
                  style="padding-top: 0px!important;margin-top: -15px;">
                  <p style="max-width: 150px;margin: 0!important;">選擇時間格式</p>
                  <v-radio-group
                    v-model="statisticTimeFormat"
                    class="m-0 py-0"
                    row>
                    <v-radio
                      label="週期月(Life time)"
                      value="lifetime"/>
                    <v-radio
                      label="返修年/月"
                      value="yearMonth"/>
                  </v-radio-group>
                </v-flex>
                <!-- <v-flex
                  d-flex
                  align-items-center
                  xs12
                  lg4
                  style="padding-top: 0px!important;margin-top: -15px;">
                  <v-checkbox
                    v-model="baseline"
                    :value="true"
                    class="m-0 py-0"
                    label="顯示比較線"/>
                </v-flex> -->
              </v-layout>
            </v-container>
          </v-card-actions>
        </material-card>
      </v-flex>
      <v-flex
        xs12
        md3
        style="padding: 0px 12px!important;margin-bottom: 10px;">
        <material-card>
          <v-container class="p-0 pb-3 mw-100">
            <v-layout wrap>
              <v-flex
                flex-small
                xs12
                lg12
                style="display: flex !important;justify-content: space-between;padding: 2px 12px!important;">
                <div style="display: flex !important;align-items: center !important;">
                  <h5 class="my-0 mr-2 d-inline-flex"><v-icon class="mr-0 text-warning">mdi-numeric-2-box</v-icon>資料項目</h5>
                </div>
              </v-flex>
            </v-layout>
          </v-container>
          <v-card-actions class="m-0 border-top border-secondary flex-wrap">
            <div
              v-for="(item, i) in typeList"
              v-show="!(typeAloneColumnNameList.indexOf(item.value)!==-1&&(chartType===1||statisticType==='None'))"
              :key="'i'+i"
              :class="{'select': typeSelectList.indexOf(item.value)>-1}"
              class="list-item cursor"
              @click="doSelectType(item.value,i)">
              {{ item.text }}
              <v-icon
                v-if="typeAloneColumnNameList.indexOf(item.value)!==-1"
                title="清單均為獨立線組項目"
                class="rotate90 small text-muted">mdi-minus-circle-outline</v-icon>
            </div>
          </v-card-actions>
        </material-card>
      </v-flex>
      <v-flex
        v-if="typeSelectList.length>0"
        xs12
        md9
        style="padding: 0px 12px!important;margin-bottom: 10px;">
        <v-container
          class="px-0 py-1 mw-100">
          <v-layout wrap>
            <v-flex
              v-for="(type, t) in typeItemList"
              :key="'t'+t">
              <material-card>
                <v-container class="p-0 pb-3 mw-100">
                  <v-layout wrap>
                    <v-flex
                      flex-small
                      style="display: flex;justify-content: space-between;align-items: center;">
                      <strong class="h5 m-0">{{ type.columnLabel }}</strong>
                      <div style="display: flex;align-items: center;">
                        <input
                          v-if="!type.custom"
                          v-model="typeKeywordList[t]"
                          :style="theme==='dark'?'background:#000;color:#fff;':theme==='light'?'background:#fff;color:#000;':''"
                          placeholder="關鍵字篩選"
                          class="v-label rounded"
                          type="text"
                          @focus="doTypeKeywordListIndex(t)">
                        <input
                          v-if="type.custom"
                          v-model="typeCustomItemList[t]"
                          :style="theme==='dark'?'background:#000;color:#fff;':theme==='light'?'background:#fff;color:#000;':''"
                          placeholder="輸入自訂項目"
                          class="v-label rounded"
                          type="text">
                        <v-btn
                          v-if="type.custom"
                          color="pink"
                          class="my-0 px-1"
                          @click="doAddListItem(t,typeCustomItemList[t])">
                          <v-icon>mdi-plus</v-icon>新增
                        </v-btn>
                      </div>
                    </v-flex>
                  </v-layout>
                </v-container>
                <v-card-actions class="m-0 border-top border-secondary">
                  <div
                    :style="theme==='dark'?'background-color:#333;':theme==='light'?'background-color:#eee;':''"
                    style="width: 100%;height: calc((100vh - 420px) / 2);min-height: 70px;overflow-y: auto;margin-top: 0.5rem;margin-bottom: 0.25rem;">
                    <div
                      v-for="(item, i) in type.itemList"
                      v-show="doGroupOpen(item,t)"
                      :key="'i'+i"
                      :class="{'list-item-area':typeKeywordList[t]==='', 'vertical-line':item.itemList?(item.itemList.length>0):false}"
                      :style="(theme==='dark'?'background-color:#333;':theme==='light'?'background-color:#eee;':'')+(type.itemList[2]?(type.itemList[2].itemList?'background-image: linear-gradient(to right, transparent 5%, '+depthColor[item.depth-1]+'66 100%);':''):'')">
                      <span
                        v-if="(!(item.itemList?(item.itemList.length>0):false)&&!(type.composition === 'alone'&&item.type==='group'))||((item.itemList?(item.itemList.length>0):false)&&(type.composition === 'alone'&&item.type==='item'))"
                        v-show="((typeKeywordList[t]===''&&doGroupOpen(item,t))||(typeKeywordList[t]!==''&&item.type!=='title'&&(item.text.toUpperCase().indexOf(typeKeywordList[t].toUpperCase()) > -1)))"
                        :title="item.type==='title'?'[單擊]展開/收合群組項目':item.type==='group'?'[單擊]新增群組項目至單一新線組；[雙擊]新增全部項目至各別新線組':(item.type==='item'&&type.composition==='mix')?'[單擊]新增項目至已選取線組；[雙擊]新增項目至新線組':''"
                        :class="{'group-title':item.type==='title','group-all':(item.type==='group'&&item.group!==''),'group-item-title':typeKeywordList[t]===''&&item.type==='item'&&item.group!==''&&item.itemList.length>0,'group-item':typeKeywordList[t]===''&&item.type==='item'&&item.group!==''&&item.itemList.length===0}"
                        class="w-100"
                        @click="doClickListItem(item.type,t,i,item.value,item.group,item.text,item.rootGroupColumn,item.rootGroupValue)"
                        @dblclick="doDblclickListItem(item.type,t,i,item.value,item.group,(item.type==='group'?item.textList:item.text),item.rootGroupColumn,item.rootGroupValue,(item.type==='group'?item.groupList:[]))">
                        <v-icon
                          v-if="item.type==='title'&&item.group"
                          :style="item.type==='title'?('color:'+depthColor[item.depth-1]+';'):''">mdi-chevron-down-circle</v-icon>
                        <v-icon
                          v-if="item.type==='title'&&!item.group"
                          :style="item.type==='title'?('color:'+depthColor[item.depth-1]+';'):''">mdi-chevron-up-circle</v-icon>
                        <span :style="item.type==='title'?('color:'+depthColor[item.depth-1]+';'):''">
                          {{ item.type==='group'?'群組 [ '+item.text+' ] 全選':item.text }}
                          <v-icon
                            v-if="type.composition === 'alone'&&item.type!=='title'"
                            title="獨立線組項目"
                            class="rotate90 small text-muted">mdi-minus-circle-outline</v-icon>
                        </span>
                        <hr v-if="item.type==='title'">
                      </span>
                      <list-item-menu-selector
                        v-for="(subitem,ii) in item.itemList"
                        :key="t+'-'+(item.depth+1)+'-'+ii+'-'+item.group+'-'+item.text+'-'+item.type"
                        :item="subitem"
                        :is-sub-dblclick="isDblclick"
                        :type="t"
                        :position="[i,ii]"
                        :depth-color="depthColor"
                        :type-keyword-list="typeKeywordList[t]"
                        :type-sub-item-list="typeItemList[t]"
                        :column-name="typeItemList[t].columnName"
                        :group-mapping-column="item.groupMappingColumn"
                        @add-sub-item-many-to-one="addItemManyToOne"
                        @add-sub-item-one-to-one="addItemOneToOne"
                        @do-change-is-sub-dblclick="doChangeIsSubDblclick"/>
                    </div>
                  </div>
                </v-card-actions>
              </material-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
      <v-flex
        xs12
        style="padding: 0px 12px!important;">
        <material-card>
          <v-container class="p-0 pb-3 mw-100">
            <v-layout wrap>
              <v-flex
                flex-small
                xs12
                lg12
                style="display: flex !important;justify-content: space-between;padding: 2px 12px!important;">
                <div style="display: flex !important;align-items: center !important;">
                  <h5 class="my-0 mr-2 d-inline-flex"><v-icon class="mr-0 text-warning">mdi-numeric-3-box</v-icon>圖表線組</h5>
                  <v-btn
                    :class="{'select': lineType===0}"
                    class="line-type line"
                    small
                    @click="chartType=0;lineType=0">
                    <v-icon class="mr-0">mdi-chart-line</v-icon>折線圖
                  </v-btn>
                  <v-btn
                    :class="{'select': lineType===1}"
                    class="line-type bar"
                    small
                    @click="chartType=0;lineType=1">
                    <v-icon class="mr-0">mdi-chart-bar</v-icon>直條圖
                  </v-btn>
                  <v-btn
                    :class="{'select': lineType===2}"
                    class="line-type pie"
                    small
                    @click="chartType=1;lineType=2">
                    <v-icon class="mr-0">mdi-chart-pie</v-icon>圓餅圖
                  </v-btn>
                </div>
                <div style="display: flex !important;align-items: center !important;">
                  <v-btn
                    v-if="lineArray.length>0"
                    color="black"
                    class="my-0"
                    small
                    @click="doAllDeleteLine()">
                    <v-icon class="mr-2">mdi-close</v-icon>全部刪除
                  </v-btn>
                  <v-btn
                    v-if="lineGroupList.length>1"
                    color="black"
                    class="my-0 text-warning"
                    small
                    @click="doMultipleDeleteLine()">
                    <v-icon class="mr-2">mdi-close</v-icon>刪除已選
                  </v-btn>
                  <v-btn
                    v-if="lineArray.length>0"
                    color="indigo"
                    class="my-0"
                    small
                    @click="copyMultipleLine()">
                    <v-icon class="mr-2">mdi-content-copy</v-icon>複製已選
                  </v-btn>
                  <v-btn
                    color="pink"
                    class="my-0"
                    small
                    @click="addLine()">
                    <v-icon class="mr-2">mdi-plus</v-icon>新增線組
                  </v-btn>
                </div>
              </v-flex>
            </v-layout>
          </v-container>
          <v-card-actions class="m-0 border-top border-secondary">
            <v-container class="p-0 pb-3 mw-100">
              <v-layout wrap>
                <v-flex
                  v-for="(line, i) in lineArray"
                  :key="'i'+i"
                  :class="{'active': (lineGroup===i || lineGroupList.indexOf(i)>-1)}"
                  class="line-item">
                  <h6 :class="{'line':line.lineType===0,'bar':line.lineType===1,'pie':line.lineType===2,'base':line.lineType===3}">
                    <v-icon v-if="line.customGroupName">mdi-pencil</v-icon>
                    <input
                      v-model="line.groupName"
                      :style="'width:'+(line.groupName.length*7.25+45)+'px;'"
                      title="[單擊]自訂標題"
                      @click="line.customGroupName=true">
                  </h6>
                  <div
                    :class="{'read-only':line.lineType===3}"
                    :style="theme==='dark'?'background:#333;':theme==='light'?'background:#eee;':''"
                    :title="(line.lineType===3?'獨立線組(單一條件項目)：無法複選及複製':'混合線組(多重條件項目)：[單擊+ctrl/command/window鍵]複選線組；[雙擊]複製線組')"
                    @click.ctrl="multiple(i)"
                    @click.meta="multiple(i)"
                    @click.exact="singleSelect(line.lineType===3?null:i)"
                    @dblclick="copyLine(i)">
                    <v-icon
                      v-if="line.lineType===0"
                      class="line">mdi-chart-line</v-icon>
                    <v-icon
                      v-if="line.lineType===1"
                      class="bar">mdi-chart-bar</v-icon>
                    <v-icon
                      v-if="line.lineType===2"
                      class="pie">mdi-chart-pie</v-icon>
                    <v-icon
                      v-if="line.lineType===3"
                      class="base">mdi-chart-areaspline</v-icon>
                    <div
                      v-for="(allfields, a) in line.selectAllFields"
                      :key="'a'+a">
                      <span
                        title="單擊刪除項目"
                        @click="doDeleteGroup(i, allfields.groupValue, a, line.lineType===3?true:false)">
                        [{{ allfields.groupName }}]
                      </span>
                    </div>
                    <div
                      v-for="(fields, f) in line.fields"
                      :style="(line.selectAllList.indexOf(fields.fieldName+':'+fields.group)>-1)?'display: none !important;':''"
                      :key="'f'+f">
                      <span
                        title="單擊刪除項目"
                        @click="doDeleteItem(i, f, line.lineType===3?true:false)">
                        {{ fields.text }}
                      </span>
                    </div>
                    <v-icon @click="doDeleteLine(i)">mdi-close</v-icon>
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-actions>
          <v-card-actions class="mx-2">
            <v-spacer/>
            <v-btn
              class="mx-0 mr-3 font-weight-light"
              color="deep-orange"
              @click="doAddBookmark()"
            >
              <v-icon class="mr-2">mdi-bookmark-plus</v-icon>產生書籤
            </v-btn>
            <v-btn
              class="mx-0 mr-3 font-weight-light"
              color="general"
              @click="doSubmit()"
            >
              <v-icon class="mr-2">mdi-backup-restore</v-icon>查詢
            </v-btn>
            <v-btn
              class="mx-0 font-weight-light"
              color="indigo"
              @click="doDownload()"
            >
              <v-icon class="mr-2">mdi-export</v-icon>匯出Raw Data
            </v-btn>
          </v-card-actions>
        </material-card>
      </v-flex>
      <v-flex
        v-show="analyzerView"
        :class="{'md9':analyzerType===1,'md12':analyzerType===0}"
        xs12
        style="padding: 0px 12px!important;">
        <material-card
          :title="analyzerName||'電競產品不良率分析結果'"
          color="brown"
          class="card-mix d-table-t0 no-data-left"
          style="margin-top: 30px!important;">
          <div
            v-if="analyzerType===0"
            class="d-flex align-items-center mix-list float-right pt-0"
            style="width: 630px;">
            <p style="max-width: 130px;margin: 0!important;">圖表數值控制項目：</p>
            <v-radio-group
              v-model="labelOperation"
              class="m-0 py-0"
              style="max-width: 160px;"
              row>
              <v-radio
                label="顯示"
                value="display"/>
              <v-radio
                label="位移"
                value="position"/>
            </v-radio-group>
            <p style="max-width: 130px;margin: 0!important;margin-left: 30px!important;">圖表數值位移量</p>
            <p style="max-width: 20px;margin: 0!important;margin-left: 30px!important;">X：</p>
            <v-text-field
              v-model="xOffset"
              :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
              type="number"
              class="mix-list v-text-field__slot d-inline-block float-right"
              style="max-width: 50px;"
            />
            <p style="max-width: 20px;margin: 0!important;margin-left: 30px!important;">Y：</p>
            <v-text-field
              v-model="yOffset"
              :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
              type="number"
              class="mix-list v-text-field__slot d-inline-block float-right text-right"
              style="max-width: 50px;"
            />
          </div>
          <vue-c3 :handler="chartArea"/>
          <svg style="height: 0;">
            <defs>
              <linearGradient
                id="targetGradient"
                x1="0%"
                y1="0%"
                x2="0%"
                y2="100%"
                spreadMethod="pad">
                <stop
                  offset="25%"
                  stop-color="#99cc55"
                  stop-opacity="1"
                />
                <stop
                  offset="100%"
                  stop-color="#99cc55"
                  stop-opacity="0"
                />
              </linearGradient>
            </defs>
          </svg>
        </material-card>
      </v-flex>
      <v-flex
        v-show="analyzerView"
        :class="{'md3':analyzerType===1,'md12':analyzerType===0}"
        flex_table_width_auto
        xs12
        style="padding: 0px 12px!important;">
        <material-card
          title="電競產品不良率分析結果列表"
          color="deep-orange"
          class="card-mix table-overflow d-table-t0 no-data-left"
          style="margin-top: 30px!important;">
          <table class="statistic-table analyzer-table">
            <thead>
              <tr>
                <th
                  :rowspan="(analyzerType===0)?2:1"
                  style="min-width: 65px;"/>
                <th
                  v-show="analyzerType===0"
                  :colspan="responseTableData.columnName.length"
                  class="text-center">{{ (statisticTimeFormat==='lifetime')?'週期月':'年/月' }}</th>
                <th
                  v-for="(title,t) in responseTableData.columnName"
                  v-show="analyzerType===1"
                  :key="'t'+t"
                  class="text-center">{{ title }}</th>
              </tr>
              <tr v-if="analyzerType===0">
                <th
                  v-for="(title,t) in responseTableData.columnName"
                  :key="'t'+t"
                  class="text-center">{{ title }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(content,c) in responseTableData.content"
                :key="'c'+c">
                <td style="min-width: 65px;">{{ content.name }}</td>
                <td
                  v-for="(data,d) in content.data"
                  :key="'d'+d"
                  class="text-center">
                  <v-chip
                    v-if="data.length>1 && data[1] > 0"
                    class="text-white">{{ (data[2] * 100).toFixed(2) }}
                    <span class="percentage">%</span>
                  </v-chip>
                  <div>
                    <span
                      v-if="data.length>1 && data[1] > 0"
                      :class="{'text-lightgray':(data[2]>=content.target)}">{{ data[0] }}</span>
                    <span
                      v-if="data.length>1 && data[1] > 0"
                      :class="{'text-lightgray':(data[2]>=content.target)}"
                      class="mx-1">/</span>
                    <span
                      v-if="data.length>1 && data[1] > 0"
                      :class="{'text-lightgray':(data[2]>=content.target)}">{{ data[1] }}</span>
                    <span v-if="data.length===1">{{ (analyzerType===1 && d===1)?((data[0] * 100).toFixed(2)+'%'):data[0] }}</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </material-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="copyBookmarkView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>

      <v-card
        max-width="50%"
        style="margin:0 auto;">
        <v-card-text>
          <v-alert
            v-model="copyBookmarkView"
            class="mt-3 mb-3 border-0"
            color="deep-orange">
            <strong class="h4"><v-icon>mdi-bookmark</v-icon>書籤：{{ copyBookmarkInfo.name }}</strong>
          </v-alert>
          {{ copyBookmarkInfo.url }}
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="red"
            small
            @click="copyBookmarkView=false;doCopyBookmarkInfo('','')">
            <v-icon class="mr-2">mdi-close</v-icon>關閉
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="processingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        PROCESSING 處理中
      </p>
    </v-dialog>
    <v-dialog
      v-model="loadingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 5px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :color="snackbarColor"
      :top="true">
      {{ snackbarMsg }}
      <v-btn
        flat
        @click="snackbar = false">
        關閉
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import Vue from 'vue'
import VueC3 from 'vue-c3'
import 'c3/c3.min.css'
import ListItemMenuSelector from './ListItemMenuSelector.vue'
import { mapState } from 'vuex'
import Loading from '@/assets/img/loading.gif'

export default {
  name: 'CfrAnalyzer',
  components: {
    VueC3,
    ListItemMenuSelector
  },
  data () {
    return {
      depthColor: [
        '#f44336', // red
        '#ffff00', // yellow
        '#c4bbff', // purple
        '#4db0ff', // blue
        '#7edd81', // green
        '#ff99ff' // pink
      ],
      // test: [
      //   {
      //     columnName: 'test',
      //     columnLabel: '{{測試用項目}}',
      //     groupMappingColumn: 'year', // 對應group 2018 2019
      //     itemList: [
      //       {
      //         text: 'A',
      //         value: 'A',
      //         group: '2018',
      //         groupValue: '2018',
      //         selectAll: true,
      //         groupMappingColumn: 'N1', // 對應group A
      //         itemList: [
      //           {
      //             text: 'A1',
      //             value: 'A1',
      //             group: 'A',
      //             groupValue: 'A',
      //             selectAll: true,
      //             groupMappingColumn: 'N2', // 對應group A1
      //             itemList: [
      //               {
      //                 text: 'A1A',
      //                 value: 'A1A',
      //                 group: 'A1',
      //                 groupValue: 'A1',
      //                 selectAll: true,
      //                 groupMappingColumn: 'N3', // 對應group A1A
      //                 itemList: [
      //                   {
      //                     text: 'A1A1',
      //                     value: 'A1A1',
      //                     group: 'A1A',
      //                     groupValue: 'A1A',
      //                     selectAll: true,
      //                     groupMappingColumn: 'N4', // 對應group A1A1
      //                     itemList: [
      //                       {
      //                         text: 'A1A1A',
      //                         value: 'A1A1A',
      //                         group: 'A1A1',
      //                         groupValue: 'A1A1',
      //                         selectAll: true,
      //                         groupMappingColumn: '',
      //                         itemList: []
      //                       }
      //                     ]
      //                   }
      //                 ]
      //               },
      //               {
      //                 text: 'A1B',
      //                 value: 'A1B',
      //                 group: 'A1',
      //                 groupValue: 'A1',
      //                 selectAll: true,
      //                 groupMappingColumn: '',
      //                 itemList: []
      //               }
      //             ]
      //           },
      //           {
      //             text: 'A2',
      //             value: 'A2',
      //             group: 'A',
      //             groupValue: 'A',
      //             selectAll: true,
      //             groupMappingColumn: '',
      //             itemList: []
      //           },
      //           {
      //             text: 'A3',
      //             value: 'A3',
      //             group: 'A',
      //             groupValue: 'A',
      //             selectAll: true,
      //             groupMappingColumn: '',
      //             itemList: []
      //           }
      //         ]
      //       },
      //       {
      //         text: 'B',
      //         value: 'B',
      //         group: '2018',
      //         groupValue: '2018',
      //         selectAll: true,
      //         groupMappingColumn: 'N1', // 對應group B
      //         itemList: [
      //           {
      //             text: 'B1',
      //             value: 'B1',
      //             group: 'B',
      //             groupValue: 'B',
      //             selectAll: true,
      //             groupMappingColumn: '',
      //             itemList: []
      //           }
      //         ]
      //       },
      //       {
      //         text: 'C',
      //         value: 'C',
      //         group: '2018',
      //         groupValue: '2018',
      //         selectAll: true,
      //         groupMappingColumn: '',
      //         itemList: []
      //       },
      //       {
      //         text: 'D',
      //         value: 'D',
      //         group: '2019',
      //         groupValue: '2019',
      //         selectAll: true,
      //         groupMappingColumn: 'N1', // 對應group D
      //         itemList: [
      //           {
      //             text: 'D1',
      //             value: 'D1',
      //             group: 'D',
      //             groupValue: 'D',
      //             selectAll: true,
      //             groupMappingColumn: '',
      //             itemList: []
      //           }
      //         ]
      //       }
      //     ]
      //   }
      // ],
      snackbar: false,
      snackbarColor: 'red',
      snackbarMsg: '',
      Loading,
      loadingView: false,
      processingView: false,
      // editGroupPrivilegeArea: true,
      // editGroupMemberArea: true,
      // summaryAuthorityMenu: [],
      // summaryGroupName: '',
      // summaryAuthority: 'All',
      // item: [],
      code: '',
      copyBookmarkView: false,
      copyBookmarkInfo: {
        name: '',
        code: ''
      },
      chartType: 0,
      chartName: '',
      statisticTimeFormat: 'lifetime',
      statisticType: 'Active',
      excludeBestBuy: false,
      baseline: false,
      isDblclick: false,
      isDeleteItem: false,
      analyzerView: false,
      analyzerType: 0,
      analyzerName: '',
      bookmarkNoQuery: false,
      resBookmarkList: [
        // {
        //   pk: 1, // required, 不良率分析工具儲存畫面條件 PK
        //   bookmarkName: '三年不良率統計', // required, 不良率分析工具儲存畫面條件名稱
        //   bookmarkToken: '3kjoxlk3jlkjlkxl' // required, 不良率分析工具儲存畫面條件令牌
        // }, {
        //   pk: 2, // required, 不良率分析工具儲存畫面條件 PK
        //   bookmarkName: '三年不良率統計', // required, 不良率分析工具儲存畫面條件名稱
        //   bookmarkToken: 'djfmhhillg' // required, 不良率分析工具儲存畫面條件令牌
        // }, {
        //   pk: 2, // required, 不良率分析工具儲存畫面條件 PK
        //   bookmarkName: '三年不良率統計', // required, 不良率分析工具儲存畫面條件名稱
        //   bookmarkToken: 'djfmhhillg' // required, 不良率分析工具儲存畫面條件令牌
        // }, {
        //   pk: 2, // required, 不良率分析工具儲存畫面條件 PK
        //   bookmarkName: '三年不良率統計三年不良率統計三年不良率統計', // required, 不良率分析工具儲存畫面條件名稱
        //   bookmarkToken: 'djfmhhillg' // required, 不良率分析工具儲存畫面條件令牌
        // }, {
        //   pk: 2, // required, 不良率分析工具儲存畫面條件 PK
        //   bookmarkName: '三年不良率統計', // required, 不良率分析工具儲存畫面條件名稱
        //   bookmarkToken: 'djfmhhillg' // required, 不良率分析工具儲存畫面條件令牌
        // }, {
        //   pk: 2, // required, 不良率分析工具儲存畫面條件 PK
        //   bookmarkName: '三年不良率統計三年不良率統計', // required, 不良率分析工具儲存畫面條件名稱
        //   bookmarkToken: 'djfmhhillg' // required, 不良率分析工具儲存畫面條件令牌
        // }, {
        //   pk: 2, // required, 不良率分析工具儲存畫面條件 PK
        //   bookmarkName: '三年不良率統計', // required, 不良率分析工具儲存畫面條件名稱
        //   bookmarkToken: 'djfmhhillg' // required, 不良率分析工具儲存畫面條件令牌
        // }, {
        //   pk: 2, // required, 不良率分析工具儲存畫面條件 PK
        //   bookmarkName: '三年不良率統計', // required, 不良率分析工具儲存畫面條件名稱
        //   bookmarkToken: 'djfmhhillg' // required, 不良率分析工具儲存畫面條件令牌
        // }, {
        //   pk: 2, // required, 不良率分析工具儲存畫面條件 PK
        //   bookmarkName: '三年不良率統計', // required, 不良率分析工具儲存畫面條件名稱
        //   bookmarkToken: 'djfmhhillg' // required, 不良率分析工具儲存畫面條件令牌
        // }, {
        //   pk: 2, // required, 不良率分析工具儲存畫面條件 PK
        //   bookmarkName: '三年不良率統計', // required, 不良率分析工具儲存畫面條件名稱
        //   bookmarkToken: 'djfmhhillg' // required, 不良率分析工具儲存畫面條件令牌
        // }, {
        //   pk: 2, // required, 不良率分析工具儲存畫面條件 PK
        //   bookmarkName: '三年不良率統計', // required, 不良率分析工具儲存畫面條件名稱
        //   bookmarkToken: 'djfmhhillg' // required, 不良率分析工具儲存畫面條件令牌
        // }
      ],
      resTypeListData: [],
      typeSelectList: [],
      typeItemList: [],
      typeKeywordList: [],
      typeKeywordTempList: [],
      typeKeywordListIndex: -1,
      typeCustomItemList: [],
      typeAloneColumnNameList: ['base_line'],
      lineType: 0,
      lineGroup: 0,
      lineGroupList: [],
      lineArray: [
        { lineType: 0, customGroupName: false, groupName: '', fields: [], selectAllFields: [], selectAllList: [] }
      ],
      labelOperation: 'display',
      xOffset: 10,
      yOffset: 10,
      responseChartData: [],
      chartXaxisName: [],
      chartXaxisValue: [],
      chartLineTypes: {},
      // chartLineRegions: {},
      chartLineHint: {},
      // chartLineTooltip: {
      //   contents: undefined,
      //   format: {
      //     title: (d, value) => {
      //       if (d) {
      //         return `${this.chartXaxisName[0][d + 1]}`
      //       } else {
      //         return `${this.chartXaxisValue[value][0]}`
      //       }
      //     },
      //     value: (value, ratio, id) => {
      //       return `${(value * 100).toFixed(2)}%`
      //     }
      //   }
      // },
      // chartPieTooltip: {
      //   contents: (d, defaultTitleFormat, defaultValueFormat, color) => {
      //     return '<p>' + defaultTitleFormat + '</p>'
      //   }
      // },
      chartPieTooltip: [],
      chartBaseLineId: [],
      chartBaseLineColor: {},
      chartArea: new Vue(),
      responseTableData: {
        columnName: [],
        content: []
      }
      // responseTableData: {
      //   columnName: [1, 2, 3, 4, 5, 6],
      //   content: [
      //     {
      //       // 直條或趨勢圖
      //       name: '2018',
      //       data: [[1, 14691, 0.000289], [81, 23691, 0.050289], [3, 28691, 0.010289], [5, 21691, 0.0089], [12, 55231, 0.0032], [8, 23691, 0.0102]]
      //     }, {
      //       // 直條或趨勢圖
      //       name: '2019',
      //       data: [[5, 21691, 0.0089], [12, 55231, 0.0032], [8, 23691, 0.0102], [1, 14691, 0.000289], [81, 23691, 0.050289], [3, 28691, 0.010289]]
      //     }, {
      //       // 圓餅圖
      //       name: 'GX501',
      //       data: [[200], [0.52], [0.52], [200], [0.52], [0.52]]
      //     }
      //   ]
      // }
      // pagination: {
      //   rowsPerPage: 1,
      //   sortBy: 'name', // The field that you're sorting by
      //   descending: true
      // },
      // headers: [
      //   {
      //     sortable: true,
      //     text: '群組名稱',
      //     value: 'name',
      //     class: 'table-width-135'
      //   },
      //   {
      //     sortable: false,
      //     text: '操作',
      //     value: 'operation',
      //     class: 'table-width-125'
      //   }
      // ]
    }
  },
  computed: {
    ...mapState('app', ['theme', 'shrink']),
    typeList () {
      const list = []
      this.resTypeListData.forEach((r) => {
        list.push({ text: r.columnLabel, value: r.columnName })
      })
      return list
    },
    options_line () {
      return {
        data: {
          x: 'x',
          columns: this.chartXaxisName.concat(this.chartXaxisValue),
          // columns: [
          //   this.chartXaxisName,
          //   this.chartXaxisValue
          // ],
          labels: {
            format: (v, id, i, j) => {
              if (v && id && i >= 0) {
                if (this.chartLineHint[id].indexOf(i) > -1) {
                  return (this.statisticType === 'None') ? v : (v * 100).toFixed(2) + '%'
                } else {
                  return ''
                }
              }
            },
            color: '#999999'
          },
          type: (this.analyzerType === 0) ? 'line' : 'pie',
          types: this.chartLineTypes,
          // regions: this.chartLineRegions,
          // colors: {
          //   // ModelName: '#999999'
          // },
          colors: this.chartBaseLineColor,
          // color: function (color, d) {
          //   // d will be 'id' when called for legends
          //   return d.value >= 5.0 ? '#ff0000' : d.value >= 3.0 ? '#ff9800' : color
          // }
          // selection: {
          //   enabled: true
          // }
          onclick: (d, i) => {
            switch (this.labelOperation) {
              case 'display':
                // console.log('onclick', d, i)
                // console.log(d.id)
                // console.log(d.index)
                // this.chartLineHint[d.id].push(d.index)
                // if (d.id !== this.chartBaseLineId) {
                //   this.doChangeChartLineHint(d)
                // }
                if (this.chartBaseLineId.indexOf(d.id) === -1) {
                  this.doChangeChartLineHint(d)
                }
                break
                // this.chartXaxisValue.forEach((x, index) => {
                //   if (x[0] === d.id) {
                //     console.log(this.chartXaxisValue[index][d.index + 1])
                //   }
                // })
              case 'position':
                let targetId = d.id.replace(/([!"#$%&'()*+,./:;<=>?@[\\\]^`{|}~])/g, '\\$1').replace(/ /g, '-')
                let labelResult = this.$el.querySelectorAll(`g.c3-chart-text.c3-target.c3-target-${targetId} > g > text.c3-text.c3-text-${d.x}`)
                let label = labelResult[0]
                let x = label.getAttribute('x')
                x = parseInt(x) + parseInt(this.xOffset)
                label.setAttribute('x', x)
                let y = label.getAttribute('y')
                y = parseInt(y) + parseInt(this.yOffset)
                label.setAttribute('y', y)
                break
            }
          }
          // onmouseover: (d, i) => { console.log('onmouseover', d, i) },
          // onmouseout: (d, i) => { console.log('onmouseout', d, i) }
        },
        // title: {
        //   text: ''
        // },
        // point: {
        //   r: (v) => {
        //     if (v.id && v.index >= 0) {
        //       if (this.chartLineHint[v.id].indexOf(v.index) > -1) {
        //         return 5
        //       // } else if (v.id === this.chartBaseLineId) {
        //       } else if (this.chartBaseLineId.indexOf(v.id) !== -1) {
        //         return 0
        //       } else {
        //         return 3
        //       }
        //     } else {
        //       return 0
        //     }
        //   }
        // },
        axis: {
          x: {
            type: 'category', // this needed to load string x value
            tick: {
              rotate: -45,
              multiline: false
            },
            label: {
              text: (this.statisticTimeFormat === 'lifetime') ? '週期月' : '年/月',
              position: 'outer-right'
            },
            padding: {
              top: 0,
              right: 0.25,
              bottom: 0,
              left: 0.25
            }
          },
          y: {
            tick: {
              format: (value) => { return (this.statisticType === 'None') ? value : (value * 100).toFixed(2) + '%' }
            },
            label: {
              text: (this.statisticType === 'None') ? '不良數' : '不良率',
              position: 'outer-bottom'
            }
          }
        },
        pie: {
          label: {
            format: (value, ratio, id) => {
              return (ratio * 100).toFixed(2) + '%'
            }
          }
        },
        // pie: {
        //   label: {
        //     format: (value, ratio, id) => {
        //       return value + 'Pcs'
        //     },
        //     threshold: -1
        //   }
        // },
        // tooltip: this.analyzerType === 0 ? this.chartLineTooltip : this.chartPieTooltip,
        tooltip: {
          contents: this.analyzerType === 0 ? undefined : (d, defaultTitleFormat, defaultValueFormat, color) => {
            return this.chartPieTooltip[this.chartXaxisName[0].indexOf(d[0].name) - 1]
            // return this.chartPieTooltip[d[0].index]
          },
          format: {
            title: (d, value) => {
              if (d) {
                return `${this.chartXaxisName[0][d + 1]}`
              } else {
                return `${this.chartXaxisValue[value][0]}`
              }
              // return `第 ${d} 期`
            },
            value: (value, ratio, id) => {
              return `${(value * 100).toFixed(2)} %`
              // return `${value} 億`
            }
          }
        },
        grid: {
          x: {
            show: true
          },
          y: {
            show: true
          }
        },
        legend: {
          position: 'right',
          padding: 0,
          show: true
        },
        size: {
          width: ((window.innerWidth - this.shrink) * 1) - 64 - (this.chartType === 1 ? 410 : 0),
          height: 600
        }
      }
    }
  },
  watch: {
    typeKeywordList: {
      handler (newValue, oldValue) {
        // let result = newValue.filter((e) => {
        //   return this.typeKeywordTempList.indexOf(e) === -1
        // })
        setTimeout(() => {
          if (this.typeKeywordListIndex > -1) {
            this.typeItemList[this.typeKeywordListIndex].itemList.forEach((item, i) => {
              if (item.type === 'title') {
                this.typeItemList[this.typeKeywordListIndex].itemList[i].group = (this.typeKeywordList[this.typeKeywordListIndex].length > 0)
              } else if (item.type === 'item') {
                setTimeout(() => {
                  this.doAutoGroupOpen(this.typeKeywordListIndex, [i], (this.typeKeywordList[this.typeKeywordListIndex].length > 0))
                }, 0)
              }
            })
          }
        }, 0)
        setTimeout(() => {
          this.typeKeywordTempList = JSON.parse(JSON.stringify(newValue))
        }, 0)
      },
      immediate: false,
      deep: true
    },
    // chartType (val) {
    //   if (val === 0) {
    //     this.lineArray = [{ lineType: this.lineType, customGroupName: false, groupName: '', fields: [], selectAllFields: [], selectAllList: [] }]
    //     this.lineGroup = 0
    //     this.lineGroupList = []
    //   } else if (val === 1) {
    //     this.lineArray = [{ lineType: 2, customGroupName: false, groupName: '', fields: [], selectAllFields: [], selectAllList: [] }]
    //     this.lineGroup = 0
    //     this.lineGroupList = []
    //   }
    // },
    lineType (val) {
      this.processingView = true
      let del = 0
      this.lineArray.forEach((line, i) => {
        if (this.lineArray[i - del].fields.length === 0 && this.lineArray[i - del].selectAllFields.length === 0) {
          setTimeout(() => {
            this.doDeleteLine(i - del)
            del++
          }, 0)
        }
      })

      setTimeout(() => {
        switch (val) {
          case 0:
            if ((this.lineArray[0] ? this.lineArray[0].lineType === 2 : false) || (this.lineArray[0].fields.length === 0 && this.lineArray[0].selectAllFields.length === 0)) {
              this.doAllDeleteLine()
              // this.lineArray = [{ lineType: 0, customGroupName: false, groupName: '', fields: [], selectAllFields: [], selectAllList: [] }]
              // this.lineGroup = 0
              // this.lineGroupList = []
            } else if (this.lineArray[0].lineType !== 3) {
              this.addLine()
            }
            this.processingView = false
            break
          case 1:
            if ((this.lineArray[0] ? this.lineArray[0].lineType === 2 : false) || (this.lineArray[0].fields.length === 0 && this.lineArray[0].selectAllFields.length === 0)) {
              this.doAllDeleteLine()
              // this.lineArray = [{ lineType: 1, customGroupName: false, groupName: '', fields: [], selectAllFields: [], selectAllList: [] }]
              // this.lineGroup = 0
              // this.lineGroupList = []
            } else if (this.lineArray[0].lineType !== 3) {
              this.addLine()
            }
            this.processingView = false
            break
          case 2:
            if (this.lineArray[0] ? this.lineArray[0].lineType !== 2 : false) {
              this.doAllDeleteLine()
              // this.lineArray = [{ lineType: 2, customGroupName: false, groupName: '', fields: [], selectAllFields: [], selectAllList: [] }]
              // this.lineGroup = 0
              // this.lineGroupList = []
            }

            let del1 = 0
            this.typeSelectList.forEach((type, t) => {
              if (this.typeAloneColumnNameList.indexOf(type) !== -1) {
                setTimeout(() => {
                  this.typeSelectList.splice((t - del1), 1)
                  del1++
                }, 0)
              }
            })

            let del2 = 0
            this.typeItemList.forEach((list, s) => {
              if (this.typeAloneColumnNameList.indexOf(list.columnName) !== -1) {
                setTimeout(() => {
                  this.typeItemList.splice((s - del2), 1)
                  del2++
                }, 0)
              }
            })

            this.processingView = false
            break
        }
      }, 0)
    },
    statisticType (val) {
      if (val === 'None') {
        this.processingView = true
        let del1 = 0
        this.typeSelectList.forEach((type, t) => {
          if (this.typeAloneColumnNameList.indexOf(type) !== -1) {
            setTimeout(() => {
              this.typeSelectList.splice((t - del1), 1)
              del1++
            }, 0)
          }
        })

        let del2 = 0
        this.typeItemList.forEach((list, s) => {
          if (this.typeAloneColumnNameList.indexOf(list.columnName) !== -1) {
            setTimeout(() => {
              this.typeItemList.splice((s - del2), 1)
              del2++
            }, 0)
          }
        })

        let del3 = 0
        this.lineArray.forEach((line, i) => {
          if (line.lineType === 3) {
            setTimeout(() => {
              this.doDeleteLine(i - del3)
              del3++
            }, 0)
          }
        })
        this.processingView = false
      }
    }
  },
  mounted () {
    this.$store.dispatch('getAuthorize', '/pqm/cfrAnalysisMgmt/cfrAnalyzerView')
      .then(response => {
        if (response.status === 'success' && response.data.accessible) {
          if (this.$route.params.code) {
            this.code = this.$route.params.code
            this.getBookmarkContent(this.code)
          }
          this.getBookmarkList()
          this.$http.get('/api/pqm/cfr/option/multiGroupOptions').then(res => res.data)
            .then(res => {
              if (res.status === 'success') {
                this.resTypeListData = res.data
                // this.resTypeListData = this.test.concat(res.data)
              }
            })
          // this.doSubmit()
          // this.chartArea.$emit('init', this.options_line)
        } else if (response.errCode === 'E10000002') {
          const routeData = this.$router.resolve({ path: '/noEnter/E10000002' })
          localStorage.setItem('errormsg', response.errMsg)
          window.location.assign(routeData.href)
        } else {
          const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
          window.location.assign(routeData.href)
        }
      })
      .catch(err => {
        console.log(err)
        const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
        window.location.assign(routeData.href)
      })
  },
  methods: {
    doChangeChartLineHint (d) {
      if (this.chartLineHint[d.id].indexOf(d.index) === -1) {
        this.chartLineHint[d.id].push(d.index)
      } else {
        this.chartLineHint[d.id].splice(this.chartLineHint[d.id].indexOf(d.index), 1)
      }
      this.chartArea.$emit('dispatch', chart => {
        const options = {
          columns: this.chartXaxisName.concat(this.chartXaxisValue)
        //   type: (this.analyzerType === 0) ? 'line' : 'pie',
        //   types: this.chartLineTypes,
        //   regions: this.chartLineRegions,
        //   tooltip: this.chartLineTooltip
        }
        chart.load(options)
      })
    },
    getBookmarkList () {
      this.$http.get('/api/pqm/cfr/analyzer/bookmark').then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.resBookmarkList = res.data
          }
        })
    },
    getBookmarkContent (code) {
      this.loadingView = true
      this.$http.get(`/api/pqm/cfr/analyzer/criteria?bookmarkToken=${code}`).then(res => res.data)
        .then(res => {
          this.loadingView = false
          if (res.status === 'success') {
            this.chartName = res.data.chartName
            this.statisticType = res.data.statisticType
            this.statisticTimeFormat = res.data.statisticTimeFormat
            // this.baseline = res.data.baseline
            this.chartType = res.data.chartType
            setTimeout(() => {
              this.lineArray = res.data.criterial
              if (!this.bookmarkNoQuery) {
                this.doSubmit()
              }
            }, 0)
          }
        })
    },
    doCopyBookmarkInfo (name, code) {
      this.copyBookmarkInfo.name = name
      this.copyBookmarkInfo.code = code
      this.copyBookmarkInfo.url = location.href.split('/')[2] + '/pqm/cfrAnalysisMgmt/cfrAnalyzerView/' + code
    },
    doSelectType (val, i) {
      this.typeKeywordListIndex = -1
      if (this.typeSelectList.indexOf(val) === -1) {
        this.typeSelectList.push(val)
        this.typeKeywordList.push('')
        this.typeCustomItemList.push('')
        this.doAddTypeItemList(i)
      } else {
        this.typeKeywordList.splice(this.typeSelectList.indexOf(val), 1)
        this.typeCustomItemList.splice(this.typeSelectList.indexOf(val), 1)
        this.typeItemList.splice(this.typeSelectList.indexOf(val), 1)
        this.typeSelectList.splice(this.typeSelectList.indexOf(val), 1)
      }
      if (this.typeSelectList.length > 2) {
        this.typeSelectList.splice(0, 1)
        this.typeItemList.splice(0, 1)
        this.typeKeywordList.splice(0, 1)
        this.typeCustomItemList.splice(0, 1)
      }
    },
    doAddTypeItemList (val) {
      let itemList = []
      const groupNameList = []
      const groupItemList = []
      const noGroupItemList = []
      let custom = false
      let composition = 'mix'
      this.resTypeListData[val].itemList.forEach((i, n) => {
        if (i.group !== '' && i.groupValue !== '' && groupNameList.indexOf(i.group + ':' + i.groupValue) === -1) {
          groupNameList.push(i.group + ':' + i.groupValue)
          groupItemList.push({ text: i.group, code: i.groupValue, value: [], list: [], nameList: [] })
        }
        if (i.group !== '' && i.groupValue !== '') {
          groupItemList[groupNameList.indexOf(i.group + ':' + i.groupValue)].list.push(i.value)
          groupItemList[groupNameList.indexOf(i.group + ':' + i.groupValue)].nameList.push(i.text)

          // temp
          if (!i.itemList) {
            this.resTypeListData[val].itemList[n]['itemList'] = []
          }

          if (i.itemList.length > 0) {
            groupItemList[groupNameList.indexOf(i.group + ':' + i.groupValue)].value.push({ type: 'item', value: i.value, text: i.text, group: i.groupValue, depth: 1, itemList: this.doAddLevelItemList(i.itemList, 2, this.resTypeListData[val].columnName, this.resTypeListData[val].groupMappingColumn, i.groupValue), columnName: this.resTypeListData[val].columnName, groupMappingColumn: i.groupMappingColumn, rootGroupColumn: this.resTypeListData[val].groupMappingColumn, rootGroupValue: i.groupValue })
          } else {
            groupItemList[groupNameList.indexOf(i.group + ':' + i.groupValue)].value.push({ type: 'item', value: i.value, text: i.text, group: i.groupValue, depth: 1, itemList: [], rootGroupColumn: this.resTypeListData[val].groupMappingColumn, rootGroupValue: i.groupValue })
          }
        } else {
          noGroupItemList.push({ type: 'item', value: i.value, text: i.text, group: '', rootGroupColumn: '', rootGroupValue: '' })
        }
      })

      groupNameList.sort().forEach((n) => {
        groupItemList.forEach((g) => {
          if (n.split(':')[0] === g.text) {
            itemList.push({ type: 'title', value: g.code, text: g.text, group: false, depth: 1, textList: '', rootGroupColumn: this.resTypeListData[val].groupMappingColumn, rootGroupValue: g.code })
            itemList.push({ type: 'group', value: g.list, text: g.text, group: g.code, depth: 1, textList: g.nameList, groupList: g.value.map(x => x.itemList.length > 0 ? { value: x.value, column: x.groupMappingColumn } : { value: null, column: null }), rootGroupColumn: this.resTypeListData[val].groupMappingColumn, rootGroupValue: g.code })
            itemList = itemList.concat(g.value)
          }
        })
      })
      itemList = itemList.concat(noGroupItemList)
      if (itemList.length === 0) {
        custom = true
      }
      if (this.typeAloneColumnNameList.indexOf(this.resTypeListData[val].columnName) !== -1) {
        composition = 'alone'
      }
      this.typeItemList.push({ columnName: this.resTypeListData[val].columnName, columnLabel: this.resTypeListData[val].columnLabel, groupMappingColumn: this.resTypeListData[val].groupMappingColumn, itemList: itemList, custom: custom, composition: composition })
    },
    doAddLevelItemList (val, depth, columnName, rootGroupColumn, rootGroupValue) {
      let itemList = []
      const groupNameList = []
      const groupItemList = []
      // const noGroupItemList = []
      val.forEach((i) => {
        if (i.group !== '' && i.groupValue !== '' && groupNameList.indexOf(i.group + ':' + i.groupValue) === -1) {
          groupNameList.push(i.group + ':' + i.groupValue)
          groupItemList.push({ text: i.group, code: i.groupValue, value: [], list: [], nameList: [] })
        }

        if (i.group !== '' && i.groupValue !== '') {
          groupItemList[groupNameList.indexOf(i.group + ':' + i.groupValue)].list.push(i.value)
          groupItemList[groupNameList.indexOf(i.group + ':' + i.groupValue)].nameList.push(i.text)

          if (i.itemList.length > 0) {
            groupItemList[groupNameList.indexOf(i.group + ':' + i.groupValue)].value.push({ type: 'item', value: i.value, text: i.text, group: i.groupValue, depth: depth, itemList: this.doAddLevelItemList(i.itemList, depth + 1, columnName, rootGroupColumn, rootGroupValue), columnName: columnName, groupMappingColumn: i.groupMappingColumn, rootGroupColumn: rootGroupColumn, rootGroupValue: rootGroupValue })
          } else {
            groupItemList[groupNameList.indexOf(i.group + ':' + i.groupValue)].value.push({ type: 'item', value: i.value, text: i.text, group: i.groupValue, depth: depth, itemList: [], rootGroupColumn: rootGroupColumn, rootGroupValue: rootGroupValue })
          }
        // } else {
        //   noGroupItemList.push({ type: 'item', value: i.value, text: i.text, group: '' })
        }
      })

      groupNameList.sort().forEach((n) => {
        groupItemList.forEach((g) => {
          if (n.split(':')[0] === g.text) {
            itemList.push({ type: 'title', value: g.code, text: g.text, group: false, depth: depth, textList: '', rootGroupColumn: rootGroupColumn, rootGroupValue: rootGroupValue })
            itemList.push({ type: 'group', value: g.list, text: g.text, group: g.code, depth: depth, textList: g.nameList, groupList: g.value.map(x => x.itemList.length > 0 ? { value: x.value, column: x.groupMappingColumn } : { value: null, column: null }), rootGroupColumn: rootGroupColumn, rootGroupValue: rootGroupValue })
            itemList = itemList.concat(g.value)
          }
        })
      })
      // itemList = itemList.concat(noGroupItemList)
      return itemList
    },
    doAddListItem (index, val) {
      this.typeItemList[index].itemList.push({ type: 'item', value: '^' + val, text: '^' + val, group: '', textList: '', rootGroupColumn: '', rootGroupValue: '' })
      // this.doClickListItem('item', index, (this.typeItemList[index].itemList.length - 1), '*' + val, '', '')
      this.typeCustomItemList[index] = ''
    },
    doTypeKeywordListIndex (t) {
      this.typeKeywordListIndex = t
    },
    doGroupOpen (val, index) {
      let group = false
      if (val.type !== 'title' && val.group !== '') {
        this.typeItemList[index].itemList.forEach((i) => {
          if (val.group === i.value && i.type === 'title') {
            group = i.group
          }
        })
      } else {
        group = true
      }
      return group
    },
    doAutoGroupOpen (itemListIndex, indexPath, open) {
      let target = this.typeItemList[itemListIndex]
      indexPath.forEach((p) => {
        target = target.itemList[p]
      })
      target.itemList.forEach((item, i) => {
        if (item.type === 'title') {
          target.itemList[i].group = open
        } else if (item.type === 'item' && item.itemList.length > 0) {
          setTimeout(() => {
            this.doAutoGroupOpen(itemListIndex, indexPath.concat([i]), open)
          }, 0)
        }
      })
    },
    doClickListItem (type, t, i, val, group, name, rootGroupColumn, rootGroupValue) {
      if (type === 'title') {
        this.typeItemList[t].itemList[i].group = !this.typeItemList[t].itemList[i].group
      } else {
        this.isDblclick = false
        this.addItemManyToOne(this.typeItemList[t].columnName, this.typeItemList[t].groupMappingColumn, val, group, name, rootGroupColumn, rootGroupValue, false, this.typeItemList[t].composition)
      }
    },
    doDblclickListItem (type, t, i, val, group, name, rootGroupColumn, rootGroupValue, groupList) {
      if (type !== 'title' && this.typeItemList[t].composition === 'mix') {
        this.addItemOneToOne(this.typeItemList[t].columnName, this.typeItemList[t].groupMappingColumn, val, group, name, rootGroupColumn, rootGroupValue, groupList, this.typeItemList[t].composition)
      } else {
        this.isDblclick = true
      }
    },
    changeGroupOpenValue (type, position) {
      // 取得目標值
      let target = position.reduce((acc, curr) => acc.itemList[curr], this.typeItemList[type])

      // 更新目標值
      target.group = !target.group
    },
    addLine (composition) {
      if (this.chartType === 0 && composition === 'alone') {
        this.lineArray.unshift({ lineType: 3, customGroupName: false, groupName: '', fields: [], selectAllFields: [], selectAllList: [] })
      } else if (this.chartType === 0) {
        this.lineArray.push({ lineType: this.lineType, customGroupName: false, groupName: '', fields: [], selectAllFields: [], selectAllList: [] })
      } else if (this.chartType === 1) {
        this.lineArray.push({ lineType: 2, customGroupName: false, groupName: '', fields: [], selectAllFields: [], selectAllList: [] })
      }
      this.lineGroup = (this.lineArray.length - 1)
      this.lineGroupList = []
    },
    doChangeIsSubDblclick (val) {
      this.isDblclick = val
    },
    addItemManyToOne (type, column, val, group, name, rootGroupColumn, rootGroupValue, multiple, composition) {
      if (composition === 'alone') {
        if (this.chartType === 0) {
          setTimeout(() => {
            if (!this.isDblclick) {
              if (this.lineArray.map(e => e.fields.map(x => x.value)[0]).indexOf(val) === -1 || this.lineArray.map(e => e.fields.map(x => x.rootGroupValue)[0]).indexOf(rootGroupValue) === -1) {
                this.addLine('alone')
                this.lineArray[0].fields.push({ fieldName: type, value: val, group: group, text: name, rootGroupColumn: rootGroupColumn, rootGroupValue: rootGroupValue })
                this.doAutoName(0, group)
                this.lineGroup = this.lineArray.length - 1
                this.snackbar = true
                this.snackbarColor = 'green'
                this.snackbarMsg = '獨立線組已新增'
              } else {
                this.snackbar = true
                this.snackbarColor = 'red'
                this.snackbarMsg = '相同條件項目的獨立線組已存在'
              }
            }
          }, 300)
        } else {
          this.snackbar = true
          this.snackbarColor = 'red'
          this.snackbarMsg = '圖表類型不支援獨立線組'
        }
      } else if (this.lineGroup >= 0 || multiple) {
        setTimeout(() => {
          if (!this.isDblclick) {
            const list = []
            const allList = []
            this.lineArray[this.lineGroup].fields.forEach((i) => {
              list.push(i.fieldName + '-' + i.value)
            })
            this.lineArray[this.lineGroup].selectAllFields.forEach((i) => {
              allList.push(i.columnName + '-' + i.groupValue + '-' + i.groupColumn)
            })
            if (Array.isArray(val)) {
              // Array
              if (group === '') {
                const list = []
                this.lineArray[this.lineGroup].selectAllFields.forEach((a, index) => {
                  if (a.columnName === type) {
                    list.push(index)
                  }
                })
                list.sort().reverse().forEach((l) => {
                  this.lineArray[this.lineGroup].selectAllFields.splice(l, 1)
                })
              }
              if (allList.indexOf(type + '-' + group + '-' + column) === -1) {
                this.lineArray[this.lineGroup].selectAllFields.push({ columnName: type, groupValue: group, groupName: name, groupColumn: column, rootGroupColumn: rootGroupColumn, rootGroupValue: rootGroupValue })
                this.lineArray[this.lineGroup].selectAllList.push(type + ':' + group)
              } else {
                this.snackbar = true
                this.snackbarColor = 'orange'
                this.snackbarMsg = '相同條件群組已存在的於目標混合線組'
              }
              val.forEach((i) => {
                if (list.indexOf(type + '-' + i) === -1) {
                  this.lineArray[this.lineGroup].fields.push({ fieldName: type, value: i, group: group, text: name, rootGroupColumn: rootGroupColumn, rootGroupValue: rootGroupValue })
                  this.doAutoName(this.lineGroup, group)
                }
              })
              this.doAutoName(this.lineGroup, group)
            } else {
              if (list.indexOf(type + '-' + val) === -1 && allList.indexOf(type + '-' + group + '-' + column) === -1) {
                this.lineArray[this.lineGroup].fields.push({ fieldName: type, value: val, group: group, text: name, rootGroupColumn: rootGroupColumn, rootGroupValue: rootGroupValue })
                this.doAutoName(this.lineGroup, group)
              } else {
                this.snackbar = true
                this.snackbarColor = 'orange'
                this.snackbarMsg = '相同條件項目已存在的於目標混合線組'
              }
            }
          }
        }, 300)
      } else if (this.lineGroupList.length > 0) {
        this.lineGroupList.forEach((b) => {
          setTimeout(() => {
            if (!this.isDblclick) {
              const list = []
              const allList = []
              this.lineArray[b].fields.forEach((i) => {
                list.push(i.fieldName + '-' + i.value)
              })
              this.lineArray[b].selectAllFields.forEach((i) => {
                allList.push(i.columnName + '-' + i.groupValue + '-' + i.groupColumn)
              })
              if (Array.isArray(val)) {
                // Array
                if (group === '') {
                  const list = []
                  this.lineArray[b].selectAllFields.forEach((a, index) => {
                    if (a.columnName === type) {
                      list.push(index)
                    }
                  })
                  list.sort().reverse().forEach((l) => {
                    this.lineArray[b].selectAllFields.splice(l, 1)
                  })
                }
                if (allList.indexOf(type + '-' + group + '-' + column) === -1) {
                  this.lineArray[b].selectAllFields.push({ columnName: type, groupValue: group, groupName: name, groupColumn: column, rootGroupColumn: rootGroupColumn, rootGroupValue: rootGroupValue })
                  this.lineArray[b].selectAllList.push(type + ':' + group)
                } else {
                  this.snackbar = true
                  this.snackbarColor = 'orange'
                  this.snackbarMsg = '相同條件群組已存在的於目標混合線組'
                }
                val.forEach((i) => {
                  if (list.indexOf(type + '-' + i) === -1) {
                    this.lineArray[b].fields.push({ fieldName: type, value: i, group: group, text: name, rootGroupColumn: rootGroupColumn, rootGroupValue: rootGroupValue })
                    this.doAutoName(b, group)
                  }
                })
                this.doAutoName(b, group)
              } else {
                if (list.indexOf(type + '-' + val) === -1 && allList.indexOf(type + '-' + group + '-' + column) === -1) {
                  this.lineArray[b].fields.push({ fieldName: type, value: val, group: group, text: name, rootGroupColumn: rootGroupColumn, rootGroupValue: rootGroupValue })
                  this.doAutoName(b, group)
                } else {
                  this.snackbar = true
                  this.snackbarColor = 'orange'
                  this.snackbarMsg = '相同條件項目已存在的於目標混合線組'
                }
              }
            }
          }, 300)
        })
        this.lineGroup = -1
      } else {
        this.addLine()
        this.addItemManyToOne(type, column, val, group, name, false)
      }
    },
    addItemOneToOne (type, column, val, group, name, rootGroupColumn, rootGroupValue, groupList, composition) {
      this.isDblclick = true
      if (this.lineArray[(this.lineArray.length - 1)].fields.length === 0 && this.lineArray[(this.lineArray.length - 1)].selectAllFields.length === 0) {
        this.lineArray.splice(val, 1)
      }
      if (Array.isArray(val)) {
        // Array
        // if (group === '') {
        //   let list = []
        //   this.lineArray[(this.lineArray.length - 1)].selectAllFields.forEach((a, index) => {
        //     if (a.columnName === type) {
        //       list.push(index)
        //     }
        //   })
        //   list.sort().reverse().forEach((l) => {
        //     this.lineArray[(this.lineArray.length - 1)].selectAllFields.splice(l, 1)
        //   })
        // }
        // this.lineArray[(this.lineArray.length - 1)].selectAllFields.push({ columnName: type, groupValue: group, groupName: name, groupColumn: column, rootGroupColumn: rootGroupColumn, rootGroupValue: rootGroupValue })
        val.forEach((i, index) => {
          this.addLine()
          if (groupList.map(x => x.value).indexOf(i) !== -1) {
            this.lineArray[(this.lineArray.length - 1)].selectAllFields.push({ columnName: type, groupValue: groupList[groupList.map(x => x.value).indexOf(i)].value, groupName: name[index], groupColumn: groupList[groupList.map(x => x.value).indexOf(i)].column, rootGroupColumn: rootGroupColumn, rootGroupValue: rootGroupValue })
            this.lineArray[(this.lineArray.length - 1)].selectAllList.push(type + ':' + group)
          } else {
            this.lineArray[(this.lineArray.length - 1)].fields.push({ fieldName: type, value: i, group: group, text: name[index], rootGroupColumn: rootGroupColumn, rootGroupValue: rootGroupValue })
          }
          this.doAutoName((this.lineArray.length - 1), group)
        })
      } else {
        this.addLine()
        this.lineArray[(this.lineArray.length - 1)].fields.push({ fieldName: type, value: val, group: group, text: name, rootGroupColumn: rootGroupColumn, rootGroupValue: rootGroupValue })
        this.doAutoName((this.lineArray.length - 1), group)
      }
    },
    doDeleteItem (LineIndex, itemIndex, deleteLine) {
      if (deleteLine) {
        this.doDeleteLine(LineIndex)
      } else {
        // let deleteAllIndex = ''
        this.isDeleteItem = true
        this.lineGroup = LineIndex
        this.resTypeListData.forEach((t) => {
          if (t.columnName === this.lineArray[LineIndex].fields[itemIndex].fieldName) {
            t.itemList.forEach((i, index) => {
              if (i.value === this.lineArray[LineIndex].fields[itemIndex].value) {
                if (i.groupValue !== '') {
                  this.lineArray[LineIndex].selectAllFields.forEach((a, index) => {
                    if (a.groupValue === i.groupValue && a.columnName === t.columnName) {
                      // deleteAllIndex = index
                      this.lineArray[LineIndex].selectAllFields.splice(index, 1)
                    }
                  })
                  // this.lineArray[LineIndex].selectAllFields.splice(deleteAllIndex, 1)
                }
              }
            })
          }
        })
        this.lineArray[LineIndex].selectAllFields.forEach((a, index) => {
          if (a.groupValue === '' && a.columnName === this.lineArray[LineIndex].fields[itemIndex].fieldName) {
            this.lineArray[LineIndex].selectAllFields.splice(index, 1)
          }
        })
        this.lineArray[LineIndex].fields.splice(itemIndex, 1)
        this.doAutoName(LineIndex)
      }
    },
    doDeleteGroup (LineIndex, group, index, deleteLine) {
      if (deleteLine) {
        this.doDeleteLine(LineIndex)
      } else {
        this.isDeleteItem = true
        const list = []
        this.lineArray[LineIndex].fields.forEach((f) => {
          if (f.group !== group) {
            list.push(f)
          }
        })
        this.lineArray[LineIndex].fields = list
        this.lineArray[LineIndex].selectAllFields.splice(index, 1)
        this.lineArray[LineIndex].selectAllList.splice(index, 1)
        this.doAutoName(LineIndex)
      }
    },
    doDeleteLine (LineIndex) {
      this.lineArray.splice(LineIndex, 1)
      setTimeout(() => {
        this.lineGroup = (this.lineArray.length - 1)
      }, 0)
      if (this.lineArray.length < 1 || this.lineArray[this.lineArray.length - 1].lineType === 3) {
        this.addLine()
      }
    },
    doMultipleDeleteLine () {
      const tempList = []
      this.lineArray.forEach((i, index) => {
        if (this.lineGroupList.indexOf(index) === -1) {
          tempList.push(i)
        }
      })
      this.lineArray = tempList
      // this.lineGroupList.forEach((i) => {
      //   this.lineArray.splice(i, 1)
      //   console.log(this.lineArray)
      // })
      // this.lineGroup = (this.lineArray.length - 1)
      this.lineGroupList = []
      if (this.lineArray.length < 1) {
        this.addLine()
      }
    },
    doAllDeleteLine () {
      if (this.chartType === 0) {
        this.lineArray = [{ lineType: this.lineType, customGroupName: false, groupName: '', fields: [], selectAllFields: [], selectAllList: [] }]
      } else if (this.chartType === 1) {
        this.lineArray = [{ lineType: 2, customGroupName: false, groupName: '', fields: [], selectAllFields: [], selectAllList: [] }]
      }
      this.lineGroup = 0
      this.lineGroupList = []
    },
    copyLine (val) {
      if (this.lineArray[val].lineType !== 3) {
        this.lineArray.push(JSON.parse(JSON.stringify(this.lineArray[val])))
        this.lineGroup = (this.lineArray.length - 1)
      }
    },
    copyMultipleLine () {
      if (this.lineGroupList.length === 0) {
        this.lineGroupList.push(this.lineGroup)
        this.lineGroup = -1
      }
      this.lineGroupList.forEach((i) => {
        this.lineArray.push(JSON.parse(JSON.stringify(this.lineArray[i])))
      })
      this.lineGroup = (this.lineArray.length - 1)
      this.lineGroupList = []
    },
    singleSelect (val) {
      if (!this.isDeleteItem) {
        if (this.lineGroup === val) {
          this.lineGroup = -1
        } else if (val !== null) {
          this.lineGroup = val
        } else {
        }
        this.lineGroupList = []
      }
      this.isDeleteItem = false
    },
    multiple (val) {
      if (this.lineArray[val].lineType !== 3) {
        if (this.lineGroupList.length === 0 && this.lineGroupList.indexOf(this.lineGroup) === -1 && this.lineGroup !== -1) {
          this.lineGroupList.push(this.lineGroup)
        }
        if (this.lineGroupList.indexOf(val) === -1) {
          this.lineGroupList.push(val)
          this.lineGroup = -1
        } else {
          this.lineGroupList.splice(this.lineGroupList.indexOf(val), 1)
        }
      }
    },
    doAutoName (val, group) {
      const list = []
      const item = []
      let name = ''
      this.lineArray[val].fields.forEach((i) => {
        list.push(i.fieldName + ':' + i.value + ':' + i.group + ':' + i.text + ':' + i.rootGroupColumn + ':' + i.rootGroupValue)
      })
      list.sort()
      this.lineArray[val].selectAllFields.forEach((s) => {
        name = name + '[' + s.groupName + '],'
      })
      this.typeList.forEach((t) => {
        list.forEach((i) => {
          const array = i.split(':')
          if (t.value === array[0] && this.lineArray[val].selectAllList.indexOf(array[0] + ':' + array[2]) === -1) {
            name = name + array[3] + ','
            item.push({ fieldName: array[0], value: array[1], group: array[2], text: array[3], rootGroupColumn: array[4], rootGroupValue: array[5] })
          }
        })
        if (name.lastIndexOf(',') === (name.length - 1)) {
          name = name.substr(0, name.lastIndexOf(',')) + '+'
        }
      })
      if (name.substr(0, 1) === '+') {
        name = name.substr(1, (name.length - 1))
      }
      if (name.substr((name.length - 1), 1) === '+') {
        name = name.substr(0, (name.length - 1))
      }
      if (!this.lineArray[val].customGroupName) {
        this.lineArray[val].groupName = name
      }
      this.lineArray[val].fields = item
    },
    doClearBlankLine () {
      const clearList = []
      this.lineArray.forEach((line, index) => {
        if (index > 0 && line.fields.length === 0 && line.selectAllFields.length === 0) {
          clearList.push(index)
        }
      })
      clearList.sort().reverse().forEach((c) => {
        this.lineArray.splice(c, 1)
      })
    },
    doSubmitVerification () {
      if (this.chartName === '') {
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '圖表名稱不得為空'
        return false
      } else {
        this.doClearBlankLine()
        if (this.lineArray.length > 1 || this.lineArray[0].fields.length > 0 || this.lineArray[0].selectAllFields.length > 0) {
          if (this.lineArray[0].fields.length === 0 && this.lineArray[0].selectAllFields.length === 0) {
            this.lineArray.splice(0, 1)
          }
          return true
        } else {
          this.snackbar = true
          this.snackbarColor = 'red'
          this.snackbarMsg = '圖表線組不得為空'
          return false
        }
      }
    },
    doSubmit () {
      if (this.doSubmitVerification()) {
        this.loadingView = true
        const body = {
          chartName: this.chartName,
          statisticType: this.statisticType,
          statisticTimeFormat: this.statisticTimeFormat,
          excludeBestBuy: this.excludeBestBuy || false,
          // baseline: this.baseline,
          chartType: this.chartType,
          criterial: this.lineArray
        }
        this.$http.post('/api/pqm/cfr/analyzer/chartData', body).then(res => res.data)
          .then(res => {
            this.loadingView = false
            if (res.status === 'success') {
              this.responseTableData = res.data.table
              this.chartXaxisName = [res.data.xColumn]
              this.chartXaxisValue = []
              this.chartLineTypes = {}
              this.chartPieTooltip = []
              // this.chartLineRegions = {}
              this.chartLineHint = {}
              if (res.data.lineData.length > 0) {
                res.data.lineData.forEach((c) => {
                  this.chartXaxisValue.push(c.data)
                  this.chartLineTypes[c.data[0]] = 'line'
                  this.chartLineHint[c.data[0]] = c.hint
                })
              }
              if (res.data.barData.length > 0) {
                res.data.barData.forEach((c) => {
                  this.chartXaxisValue.push(c.data)
                  this.chartLineTypes[c.data[0]] = 'bar'
                  this.chartLineHint[c.data[0]] = c.hint
                })
              }
              if (res.data.pieData.length > 0) {
                res.data.pieData.forEach((c) => {
                  this.chartXaxisValue.push(c.data)
                  this.chartLineTypes[c.data[0]] = 'pie'
                  this.chartLineHint[c.data[0]] = c.hint
                  this.chartPieTooltip.push('<div style="color:#ffffff!important;background-color:#000000;padding:10px;font-weight:900!important;">' + c.tip + '</div>')
                })
              }
              // if (this.baseline && this.chartType === 0) {
              //   this.chartXaxisValue.push(res.data.baseline.data)
              //   this.chartLineTypes[res.data.baseline.data[0]] = 'line'
              //   // this.chartLineRegions[baseline.data[0]] = [{ style: 'dashed' }]
              //   this.chartLineHint[res.data.baseline.data[0]] = res.data.baseline.hint
              //   this.chartBaseLineId = res.data.baseline.data[0]
              // }
              if (res.data.type === 0 && res.data.baseline) {
                this.chartBaseLineId = []
                this.chartBaseLineColor = {}
                res.data.baseline.forEach((c) => {
                  this.chartXaxisValue.push(c.data)
                  this.chartLineTypes[c.data[0]] = 'area'
                  // this.chartLineRegions[c.data[0]] = [{ style: 'dashed' }]
                  this.chartLineHint[c.data[0]] = c.hint
                  this.chartBaseLineId.push(c.data[0])
                  this.chartBaseLineColor[c.data[0]] = '#99cc5555'
                })
              }
              this.analyzerName = this.chartName
              this.analyzerType = this.chartType
              this.chartArea.$emit('init', this.options_line)
              this.analyzerView = true
            } else {
              this.analyzerView = false
              this.snackbar = true
              this.snackbarColor = 'red'
              this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
            }
          })
      }
      // let xColumn = ['x', '2010-01-01', '2011-01-01', '2012-01-01', '2013-01-01', '2014-01-01', '2015-01-01', '2016-01-01', '2017-01-01', '2018-01-01', '2019-01-01', '2020-01-01', '2021-01-01']
      // let lineData = []
      // let barData = []
      // let pieData = []
      // if (this.chartType === 0) {
      //   lineData = [
      //     {
      //       data: ['sample', 30, 200, 100, 400, 150, 250],
      //       hint: [1, 4],
      //       tip: ''
      //     }, {
      //       data: ['test', 100, 20, 10, 40, 10, 50],
      //       hint: [0, 2],
      //       tip: ''
      //     }
      //   ]
      //   barData = [
      //     {
      //       data: ['sample2sample2sample2', 30, 200, 100, 400, 150, 250, 30, 200, 100, 400, 150, 250],
      //       hint: [0, 7],
      //       tip: ''
      //     }, {
      //       data: ['test2', 10, 20, 10, 40, 10, 50, 10, 20, 10, 40, 10, 50],
      //       hint: [3, 4],
      //       tip: ''
      //     }
      //   ]
      // }
      // if (this.chartType === 1) {
      //   pieData = [
      //     {
      //       data: ['sample6', 30, 200, 100, 400, 150, 250, 30, 200, 100, 400, 150, 250],
      //       hint: [1, 4],
      //       tip: ''
      //     }, {
      //       data: ['test6', 100, 20, 10, 40, 10, 50, 100, 20, 10, 40, 10, 50],
      //       hint: [0, 2],
      //       tip: ''
      //     }
      //   ]
      // }
      // let baseline = {
      //   data: ['基準線', 135, 105, 205, 205, 355, 155, 135, 105, 205, 205, 355, 155],
      //   hint: [],
      //   tip: ''
      // }
      // this.chartXaxisName = [xColumn]
      // this.chartXaxisValue = []
      // this.chartLineTypes = {}
      // // this.chartLineRegions = {}
      // this.chartLineHint = {}
      // lineData.forEach((c) => {
      //   this.chartXaxisValue.push(c.data)
      //   this.chartLineTypes[c.data[0]] = 'line'
      //   this.chartLineHint[c.data[0]] = c.hint
      // })
      // barData.forEach((c) => {
      //   this.chartXaxisValue.push(c.data)
      //   this.chartLineTypes[c.data[0]] = 'bar'
      //   this.chartLineHint[c.data[0]] = c.hint
      // })
      // pieData.forEach((c) => {
      //   this.chartXaxisValue.push(c.data)
      //   this.chartLineTypes[c.data[0]] = 'pie'
      //   this.chartLineHint[c.data[0]] = c.hint
      // })
      // if (this.baseline && this.chartType === 0) {
      //   this.chartXaxisValue.push(baseline.data)
      //   this.chartLineTypes[baseline.data[0]] = 'line'
      //   // this.chartLineRegions[baseline.data[0]] = [{ style: 'dashed' }]
      //   this.chartLineHint[baseline.data[0]] = baseline.hint
      // }
      // // if (this.chartType === 0) {
      // //   this.chartLineTooltip = {
      // //     format: {
      // //       title: (d, value) => {
      // //         if (d) {
      // //           return `${this.chartXaxisName[0][d + 1]}`
      // //         } else {
      // //           return `${this.chartXaxisValue[value][0]}`
      // //         }
      // //       },
      // //       value: (value, ratio, id) => {
      // //         return `${(v * 100).toFixed(2)} %%`
      // //       }
      // //     }
      // //   }
      // // } else if (this.chartType === 1) {
      // //   this.chartLineTooltip = {
      // //     contents: (d, defaultTitleFormat, defaultValueFormat, color) => {
      // //       return '<p>xxx</p>'
      // //     }
      // //   }
      // // }
      // // if (this.chartType === 1) {
      // //   this.chartLineTooltip['contents'] = (d, defaultTitleFormat, defaultValueFormat, color) => {
      // //     return '<p>xxx</p>'
      // //   }
      // // }
      // this.chartArea.$emit('init', this.options_line)
      // // this.chartArea.$emit('dispatch', chart => {
      // //   const options = {
      // //     columns: this.chartXaxisName.concat(this.chartXaxisValue),
      // //     type: (this.chartType === 0) ? 'line' : 'pie',
      // //     types: this.chartLineTypes,
      // //     regions: this.chartLineRegions,
      // //     tooltip: this.chartLineTooltip
      // //   }
      // //   chart.load(options)
      // // })
      // // const body = {
      // //   name: this.summaryGroupName,
      // //   menuPk: this.summaryAuthority === 'All' ? '' : this.summaryAuthority
      // // }
      // // this.$http.post('/api/system/queryGroup', body).then(res => res.data)
      // //   .then(res => {
      // //     if (res.status === 'success') {
      // //       this.responseDataTable = res.data
      // //     }
      // //   })
      // this.analyzerType = this.chartType
      // this.analyzerView = true
    },
    doAddBookmark () {
      if (this.doSubmitVerification()) {
        this.processingView = true
        const body = {
          chartName: this.chartName,
          statisticType: this.statisticType,
          statisticTimeFormat: this.statisticTimeFormat,
          excludeBestBuy: this.excludeBestBuy || false,
          // baseline: this.baseline,
          chartType: this.chartType,
          criterial: this.lineArray
        }
        this.$http.post('/api/pqm/cfr/analyzer/bookmark', body).then(res => res.data)
          .then(res => {
            this.processingView = false
            if (res.status === 'success') {
              this.snackbar = true
              this.snackbarColor = 'green'
              this.snackbarMsg = '書籤已建立'
              this.getBookmarkList()
            } else {
              this.snackbar = true
              this.snackbarColor = 'red'
              this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
            }
          })
      }
    },
    doDelBookmark (pk) {
      this.processingView = true
      this.$http.delete(`/api/pqm/cfr/analyzer/bookmark/${pk}`).then(res => res.data)
        .then(res => {
          this.processingView = false
          if (res.status === 'success') {
            this.snackbar = true
            this.snackbarColor = 'green'
            this.snackbarMsg = '書籤已刪除'
            this.getBookmarkList()
          } else {
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
          }
        })
    },
    doDownload () {
      if (this.doSubmitVerification()) {
        this.processingView = true
        const body = {
          chartName: this.chartName,
          statisticType: this.statisticType,
          statisticTimeFormat: this.statisticTimeFormat,
          excludeBestBuy: this.excludeBestBuy || false,
          // baseline: this.baseline,
          chartType: this.chartType,
          criterial: this.lineArray
        }
        this.$http.post('/api/pqm/cfr/analyzer/rawDataFile', body, { responseType: 'blob' })
          .then(res => {
            this.processingView = false
            if (res.data.type === 'application/json') {
              return new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.onload = () => {
                  res.data = JSON.parse(reader.result)
                  if (res.data.status === 'success') {
                    alert(res.data.data)
                    // resolve(Promise.reject(res.data.data))
                  } else {
                    this.snackbar = true
                    this.snackbarColor = 'red'
                    this.snackbarMsg = res.data.errMsg + ' (' + res.data.errCode + ')'
                  }
                }
                reader.onerror = () => {
                  reject(res.data.data)
                }
                reader.readAsText(res.data)
              })
            } else if (res.data.status !== 'failure' && res.headers['content-disposition']) {
              const blob = new Blob([res.data], { type: 'application/octet-stream' })
              const url = window.URL.createObjectURL(blob)
              const fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]
              const downloadLink = document.createElement('a')
              downloadLink.href = url
              downloadLink.download = fileName

              document.body.appendChild(downloadLink)
              downloadLink.click()
              document.body.removeChild(downloadLink)
            } else {
              this.snackbar = true
              this.snackbarColor = 'red'
              this.snackbarMsg = '下載失敗，請通知系統管理員'
            }
          })
          .catch(err => {
            this.processingView = false
            this.snackbar = true
            this.snackbarColor = 'orange'
            this.snackbarMsg = err
          })
      }
    }
  }
}
</script>
<style>
  .text-lightgray{
    color:lightgray !important;
  }
  /* .v-chip .percentage {
    color: #fff !important;
    font-size: 18px !important;
  } */
  .c3-area {
    fill: url(#targetGradient) !important;
    opacity: 0.25 !important;
  }
</style>
