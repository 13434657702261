<template>
  <v-container
    cfr
    fluid
    grid-list-xl
    pt-0
  >
    <v-layout wrap>
      <v-flex
        xs12
        lg12
      >
        <material-card
          class="card-padding v-card__first"
          color="general"
        >
          <v-form>
            <v-container
              py-0
              class="mw-100">
              <v-layout wrap>
                <v-flex
                  v-for="(item,i) in systemSettingList"
                  :key="'i'+i"
                  flex-small
                  text-left
                  lg12>
                  <h3 class="my-1 mr-5 float-left">{{ item.name }}</h3>
                  <v-switch
                    :color="theme==='dark'?'error':'info'"
                    :input-value="systemSettingList[i].status==='enable'"
                    label=""
                    class="m-0 mt-2 float-left"
                    style="transform: scale(1.5);"
                    hide-details
                    @change="doChangeStatus(i, item.id)"/>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="processingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        PROCESSING 處理中
      </p>
    </v-dialog>
    <v-dialog
      v-model="loadingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :top="true"
      :color="snackbarColor">
      {{ snackbarMsg }}
      <v-btn
        dark
        flat
        @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
// import qs from 'qs'
import { mapState } from 'vuex'
import Loading from '@/assets/img/loading.gif'
export default {
  name: 'SystemSetting',
  data () {
    return {
      startdate: '2018-04-01',
      submit: false,
      snackbar: false,
      snackbarColor: 'red',
      snackbarMsg: '',
      Loading,
      loadingView: false,
      processingView: false,
      systemSettingList: []
    }
  },
  computed: {
    ...mapState('app', ['theme', 'shrink'])
  },
  watch: {},
  mounted () {
    this.$store.dispatch('getAuthorize', '/pqm/cscDataParsingMgmt/systemSettingView')
      .then(response => {
        if (response.status === 'success' && response.data.accessible) {
          this.getSystemSetting()
          // this.doSubmit()
        } else if (response.errCode === 'E10000002') {
          const routeData = this.$router.resolve({ path: '/noEnter/E10000002' })
          localStorage.setItem('errormsg', response.errMsg)
          window.location.assign(routeData.href)
        } else {
          const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
          window.location.assign(routeData.href)
        }
      })
      .catch(err => {
        console.log(err)
        const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
        window.location.assign(routeData.href)
      })
  },
  methods: {
    getSystemSetting () {
      this.$http.get('/api/pqm/cscDataParsingMgmt/systemSetting/').then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.systemSettingList = res.data
          } else { // failure
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
          }
        }).catch(err => {
          this.processingView = false
          this.snackbar = true
          this.snackbarColor = 'red'
          this.snackbarMsg = err
        })
    },
    doChangeStatus (i, id) {
      this.submit = true
      this.loadingView = true
      const body = {
        status: this.systemSettingList[i].status === 'enable' ? 'disable' : 'enable'
      }
      this.$http.post(`/api/pqm/cscDataParsingMgmt/systemSetting/${id}`, body).then(res => res.data)
        .then(res => {
          this.loadingView = false
          if (res.status === 'success') {
            this.getSystemSetting()
            this.snackbar = true
            this.snackbarColor = 'green'
            this.snackbarMsg = '修改成功'
          }
        })
    }
  }
}
</script>
<style>
</style>
