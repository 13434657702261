<template>
  <v-container
    krypart
    fluid
    grid-list-xl
    pt-0
  >
    <v-layout wrap>
      <v-flex
        xs12
      >
        <material-card
          title="鍵盤高度數據分析"
          class="card-shrink card-padding v-card__first"
          color="general"
        >
          <v-form>
            <v-container
              py-0
              class="mw-100">
              <v-layout
                wrap
                align-items-center>
                <v-flex
                  xs12
                  sm6
                  lg2>
                  <v-autocomplete
                    v-model="summaryVendorName"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryVendorNameMenu"
                    label="廠商"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex
                  xs12
                  sm6
                  lg2>
                  <v-autocomplete
                    v-model="summaryModelName"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryModelNameMenu"
                    label="機種"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex
                  xs12
                  sm6
                  lg2>
                  <v-autocomplete
                    v-model="summaryPartNumber"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryPartNumberMenu"
                    label="料號"
                    class="v-text-field__slot"
                    color="white"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex
                  flex-small
                  d-flex
                  align-items-center
                  xs12
                  lg5>
                  <v-autocomplete
                    v-model="summaryWeeksStart"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryAllYearWeeksList"
                    label="起始時間"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  >
                    <template v-slot:item="data">
                      <div
                        :class="{'weeks-list-data-tip':contentDateListWeeks.indexOf(data.item.value)!==-1}"
                        class="v-list__tile__content">
                        <div class="v-list__tile__title">
                          {{ data.item.text }}
                        </div>
                      </div>
                    </template>
                  </v-autocomplete>
                  <p class="m-0 text-center px-3">到</p>
                  <v-autocomplete
                    v-model="summaryWeeksEnd"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryAllYearWeeksList"
                    label="結尾時間"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  >
                    <template v-slot:item="data">
                      <div
                        :class="{'weeks-list-data-tip':contentDateListWeeks.indexOf(data.item.value)!==-1}"
                        class="v-list__tile__content">
                        <div class="v-list__tile__title">
                          {{ data.item.text }}
                        </div>
                      </div>
                    </template>
                  </v-autocomplete>
                </v-flex>
                <v-flex
                  xs12
                  lg1
                  text-xs-right
                >
                  <v-btn
                    :disabled="summaryWeeksStart===''||summaryWeeksEnd===''||summaryVendorName===''||summaryModelName===''||summaryPartNumber===''"
                    class="mx-0 font-weight-light"
                    color="general"
                    @click="doSubmit()"
                  >
                    <v-icon class="mr-2">mdi-backup-restore</v-icon>分析
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-layout
                wrap
                align-items-center>
                <v-flex
                  style="flex-basis:auto"
                  d-flex
                  xs12
                  lg12>
                  <v-radio-group
                    v-model="summaryButtonCodeType"
                    class="align-items-center"
                    row>
                    <v-radio
                      label="全部按鍵"
                      value="all"/>
                    <v-radio
                      label="指定按鍵"
                      value="specific"/>
                  </v-radio-group>
                  <v-autocomplete
                    v-model="summaryButtonCode"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryButtonCodeMenu"
                    :disabled="summaryButtonCodeType==='all'"
                    label="指定按鍵"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                    multiple
                  />
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
      </v-flex>
      <v-flex
        v-if="processingView"
        xs12
        sm6
        lg2>
        <material-stats-card
          :value="responseModelName"
          style="margin:10px 0 !important;"
          class="v-offset-none"
          color="purple"
          icon="mdi-folder-open"
          title="案名"/>
      </v-flex>
      <v-flex
        v-if="processingView"
        xs12
        sm6
        lg3>
        <material-stats-card
          :value="responseVendorName"
          style="margin:10px 0 !important;"
          class="v-offset-none"
          icon="mdi-factory"
          title="廠商"/>
      </v-flex>
      <v-flex
        v-if="processingView"
        xs12
        sm6
        lg3>
        <material-stats-card
          :value="responsePartNumber"
          style="margin:10px 0 !important;"
          class="v-offset-none"
          icon="mdi-factory"
          title="料號"/>
      </v-flex>
      <v-flex
        v-if="processingView"
        xs12
        sm6
        lg4>
        <material-stats-card
          :value="responseDateStart +' 至 '+ responseDateEnd"
          style="margin:10px 0 !important;"
          color="indigo"
          icon="mdi-calendar"
          title="資料日期"/>
      </v-flex>
      <v-flex
        v-if="processingView && summaryNoData"
        class="data-area no-data-page"
        xs12>
        此日期區間查無資料
      </v-flex>
      <v-flex
        v-if="processingView && !summaryNoData && responseDataCompileFailList.length>0"
        lg4
      >
        <material-card
          title="Problem key"
          class="card-shrink card-padding v-card__first"
          color="orange-6"
        >
          <div class="fail-data-area">
            <div
              v-for="(fail,f) in responseDataCompileFailList"
              :key="'f'+f">
              <p>{{ fail.buttonCode }}</p>
              <div>
                <span
                  v-for="(list,l) in fail.list"
                  :key="'l'+l"
                  @click="doSubmitSingle('Key_Height', fail.buttonCode, list)">{{ list }}</span>
              </div>
            </div>
          </div>
        </material-card>
      </v-flex>
      <v-flex
        v-if="processingView && !summaryNoData"
        lg4
      >
        <material-card
          title="Key Height"
          class="card-shrink card-padding v-card__first"
          color="orange-3"
        >
          <div
            v-if="responseCpkData.Key_Height.length>0"
            class="data-area feeling key-height">
            <div
              v-for="(group,g) in responseCpkData.Key_Height"
              :key="'g'+g"
              class="data-group">
              <div class="data-group-name">
                CPK
                <p
                  :style="!doCpkGrade(group.item[1].value)?'color:red;font-weight:500!important;':''"
                  class="cpk"
                  style="cursor: pointer;"
                  @click="doSubmitSingle('Key_Height', null, 'HEIGHT')">{{ group.item[1].value.toFixed(2) }}</p>
                <p
                  :style="doCpkGrade(group.item[1].value)?'color:#ffffff;background-color:green;font-weight:900!important;':'color:#ffffff;background-color:red;font-weight:900!important;'"
                  class="lv">{{ doCpkGradeName(group.item[1].value) }}</p>
              </div>
              <div class="data-set">
                <div
                  v-for="(item,i) in group.item"
                  v-show="item.statType!=='cpk'"
                  :key="'i'+i"
                  class="data-box">
                  <div class="data-item">
                    <span class="data-item-name">{{ item.statType.toUpperCase() }}</span>
                    <span class="data-item-value">{{ item.value.toFixed(2) }}</span>
                    <span class="data-item-detail">
                      <span
                        v-for="(position,p) in item.detail"
                        :key="'p'+p"
                        @click="doSubmitSingle('Key_Height', position, 'HEIGHT')"
                      >{{ position }}</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-else
            class="data-area no-data">
            此日期區間查無資料
          </div>
        </material-card>
      </v-flex>
      <v-flex
        v-if="processingView && !summaryNoData"
        flex_table_width_auto
        xs12
      >
        <material-card
          id="checkTable"
          :style="'max-width: calc(100vw - '+(Number(shrink)+32)+'px);'"
          title="鍵盤高度數據統計表"
          color="deep-orange"
          class="card-mix d-table-t0 no-data-left"
          style="margin-top: 30px!important;">
          <v-btn
            class="mix-btn black text-light mx-0"
            style="right:140px;"
            @click="dofilterALLClear()">清除條件</v-btn>
          <v-select
            v-model="pagination.rowsPerPage"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="rowsAmount"
            class="mix-list v-text-field__slot d-inline-block float-right ml-5"
            style="width: 90px;"
            persistent-hint
            outlined
          />
          <table
            class="statistic-table keypart-table">
            <thead>
              <tr v-if="responseDataGroup.length>0">
                <th
                  :class="{'desc':pagination.sortBy==='buttonCode'&&pagination.descending, 'asc':pagination.sortBy==='buttonCode'&&!pagination.descending}"
                  rowspan="2">
                  <div class="table-filter-item">
                    <v-icon @click="dofilterViewOpen('keyword', 0, '', 'buttonCode')">mdi-filter</v-icon>
                    {{ filterSettingList[0] }}
                    <v-icon
                      v-if="filterSettingList[0]"
                      class="close"
                      @click="dofilterClear(0)">
                      mdi-close
                    </v-icon>
                  </div>
                  <span @click="doTableSort('buttonCode')">
                    {{ responseHeaders[0].text }}
                    <v-icon class="sort-btn">mdi-arrow-up</v-icon>
                  </span>
                </th>
                <th
                  v-for="(groupTitle, g) in responseDataGroup"
                  :key="'g'+g"
                  colspan="5"
                  class="data-group-name">
                  {{ responseHeaders[g*5+1].groupTitle }}
                </th>
              </tr>
              <tr>
                <th
                  v-if="responseDataGroup.length===0"
                  :class="{'desc':pagination.sortBy==='buttonCode'&&pagination.descending, 'asc':pagination.sortBy==='buttonCode'&&!pagination.descending}">
                  <div class="table-filter-item">
                    <v-icon @click="dofilterViewOpen('keyword', 0, '', 'buttonCode')">mdi-filter</v-icon>
                    {{ filterSettingList[0] }}
                    <v-icon
                      v-if="filterSettingList[0]"
                      class="close"
                      @click="dofilterClear(0)">
                      mdi-close
                    </v-icon>
                  </div>
                  <span @click="doTableSort('buttonCode')">
                    {{ responseHeaders[0].text }}
                    <v-icon class="sort-btn">mdi-arrow-up</v-icon>
                  </span>
                </th>
                <th
                  v-for="(header, h) in responseHeaders"
                  v-show="h>0"
                  :key="'h'+h"
                  :class="{'desc':pagination.sortBy===header.value&&pagination.descending, 'asc':pagination.sortBy===header.value&&!pagination.descending}">
                  <div class="table-filter-item">
                    <v-icon @click="dofilterViewOpen(header.type, header.index, header.group, header.text)">mdi-filter</v-icon>
                    {{ filterSettingList[header.index] }}
                    <v-icon
                      v-if="filterSettingList[header.index]"
                      class="close"
                      @click="dofilterClear(header.index)">
                      mdi-close
                    </v-icon>
                  </div>
                  <span @click="doTableSort(header.value)">
                    {{ header.text }}
                    <v-icon class="sort-btn">mdi-arrow-up</v-icon>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item,i) in responseDataCompile"
                v-show="pagination.rowsPerPage===-1||((i+1)>(pagination.page-1)*pagination.rowsPerPage&&(i+1)<=pagination.page*pagination.rowsPerPage)"
                :key="'i'+i">
                <td>{{ item.buttonCode }}<v-icon v-if="doWarnGroupCpk(i)">mdi-alert-decagram</v-icon></td>
                <template v-for="(cnt, idx) in responseDataGroup.length">
                  <td
                    :key="'cpk'+idx"
                    class="cursor"
                    @click="doSubmitSingle('Key_Height', item.buttonCode, responseDataGroup[idx])">{{ item[responseDataGroup[idx]+'_cpk'].toFixed(2) }}</td>
                  <td
                    :key="'lvl'+idx"
                    :style="!doCpkGrade(item[responseDataGroup[idx]+'_cpk'])?'color:red;':''"
                    class="cursor"
                    @click="doSubmitSingle('Key_Height', item.buttonCode, responseDataGroup[idx])">
                    {{ item[responseDataGroup[idx]+'_lvl'] }}</td>
                  <td :key="'max'+idx">{{ item[responseDataGroup[idx]+'_max'].toFixed(2) }}</td>
                  <td :key="'min'+idx">{{ item[responseDataGroup[idx]+'_min'].toFixed(2) }}</td>
                  <td :key="'avg'+idx">{{ item[responseDataGroup[idx]+'_avg'].toFixed(2) }}</td>
                </template>
              </tr>
            </tbody>
          </table>
          <div
            v-if="pagination.rowsPerPage != -1"
            class="text-xs-center pt-2 d-inline-block"
            style="margin-left: 0px;width: calc(100% - 140px);">
            <v-pagination
              v-model="pagination.page"
              :length="pages"/>
          </div>
          <v-autocomplete
            v-if="pagination.rowsPerPage != -1"
            v-model="pagination.page"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="pageslist"
            class="v-text-field__slot d-inline-block float-right mr-0"
            style="width: 90px;"
            persistent-hint
            outlined
          />
        </material-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="chartLineView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <v-card
        max-width="80%"
        style="margin:0 auto;">
        <v-card-text>
          <v-alert
            v-model="chartLineView"
            class="mt-3 mb-3 border-0"
            color="brown">
            <strong class="h4">{{ responseStandardData?responseStandardData.testItemName:'' }} ： {{ responseDateStart }} - {{ responseDateEnd }} ｜ {{ responseVendorName }} ｜ {{ responseModelName }} ｜ {{ responsePartNumber }}</strong>
          </v-alert>
          <div class="d-flex">
            <div class="w-100 m-3">
              <p class="text-center my-1">{{ chartViewTestItemName }}{{ chartViewButtonCode?' [ '+chartViewButtonCode+' ] ':'' }}</p>
              <div class="keypart-chart-info">
                <div :class="{ 'ordinary':doCpkGrade(responseStandardData.cpk),'bad':!doCpkGrade(responseStandardData.cpk) }">
                  <span>CPK</span>
                  <p>{{ responseStandardData.cpk.toFixed(2) }}</p>
                  <div>
                    <span>{{ doCpkGradeName(responseStandardData.cpk) }}</span>
                  </div>
                </div>
                <div>
                  <span>SPEC</span>
                  <p>{{ responseStandardData.lowerSpecValue }}{{ (responseStandardData.lowerSpecValue!==null&&responseStandardData.upperSpecValue!==null)?' ~ ':'' }}{{ responseStandardData.upperSpecValue }}</p>
                  <div>
                    <span v-if="responseStandardData.lowerSpecValue!==null">LOWER</span>
                    <span v-if="responseStandardData.upperSpecValue!==null">UPPER</span>
                  </div>
                </div>
              </div>
              <table class="keypart-chart-table statistic-table">
                <thead>
                  <tr>
                    <th>sigma</th>
                    <th>value</th>
                    <th>histogram</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(row, r) in responseStandardData.distributionCoverageList">
                    <tr
                      v-if="row.define.type === 'Sigma'"
                      :key="'r'+r">
                      <td>{{ row.define.name }}</td>
                      <td>{{ row.define.value.toFixed(4) }}</td>
                      <td>{{ row.amount }}</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <vue-c3
              :handler="handler_line"
              class="keypart-chart-area"/>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="red"
            small
            @click="chartLineView=false">
            <v-icon class="mr-2">mdi-close</v-icon>關閉
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="filterView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <v-card
        max-width="35%"
        style="margin:0 auto;">
        <v-card-text>
          <v-alert
            v-model="filterView"
            class="mt-3 mb-3 border-0"
            color="brown">
            <strong class="h4">正在設定[{{ filterTitle }}]條件：{{ filterDirections }}</strong>
          </v-alert>
          <v-flex
            d-flex
            table-filter-setting-bar
            xs12>
            <v-text-field
              v-if="filterInfo.type==='keyword'"
              v-model="filterKeyword"
              :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
              label="關鍵字"
              placeholder="請輸入關鍵字"
              persistent-hint/>
            <v-btn-toggle
              v-if="filterInfo.type==='formula'"
              v-model="filterBtnSelect"
              class="d-flex align-items-center"
              multiple>
              <v-text-field
                v-model="filterMinValue"
                :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                :disabled="filterBtnSelect.indexOf('ge')===-1"
                label=""
                persistent-hint
                @keyup="doMinValueReplaceText()"/>
              <v-btn
                value="ge"
                class="general"
                color="light"
                flat>
                <v-icon>mdi-less-than-or-equal</v-icon>
              </v-btn>
              <v-text-field
                :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                label=""
                value="X"
                disabled
                persistent-hint/>
              <v-btn
                value="le"
                class="general"
                color="light"
                flat>
                <v-icon>mdi-less-than-or-equal</v-icon>
              </v-btn>
              <v-text-field
                v-model="filterMaxValue"
                :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                :disabled="filterBtnSelect.indexOf('le')===-1"
                label=""
                persistent-hint
                @keyup="doMaxValueReplaceText()"/>
            </v-btn-toggle>
          </v-flex>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="pink"
            small
            @click="dofilterSetting()">
            <v-icon class="mr-2">mdi-plus</v-icon>新增條件
          </v-btn>
          <v-btn
            color="general"
            small
            @click="filterView=false">
            <v-icon class="mr-2">mdi-close</v-icon>返回
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="processloadingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog>
    <v-dialog
      v-model="loadingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :top="true"
      :color="snackbarColor">
      {{ snackbarMsg }}
      <v-btn
        dark
        flat
        @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import qs from 'qs'
import Vue from 'vue'
import VueC3 from 'vue-c3'
import 'c3/c3.min.css'
import { mapState } from 'vuex'
import NormalDistribution from 'normal-distribution'
import Loading from '@/assets/img/loading.gif'

export default {
  name: 'KeyboardKeyHeightAnalyzer',
  components: {
    VueC3
  },
  data () {
    return {
      startdate: '2018-04-01',
      snackbar: false,
      snackbarColor: 'red',
      snackbarMsg: '',
      Loading,
      loadingView: false,
      overload: false,
      overloadMsg: '',
      processingView: false,
      summaryNoData: true,
      filterView: false,
      filterInfo: {
        type: '',
        index: -1,
        group: '',
        item: ''
      },
      filterTitle: '',
      filterKeyword: '',
      filterMinValue: '',
      filterMaxValue: '',
      filterBtnSelect: [],
      filterSettingList: [],
      filterSettingDetailList: [],
      summaryLoading: {
        Key_Height: false,
        Detail: false
      },
      summaryMonth: this.$moment(new Date()).format('YYYY-MM'),
      summaryWeeksStart: Number(this.$moment(new Date()).format('YYYY')) * 100 + Math.ceil((((new Date(this.$moment(new Date()).format('YYYY-MM-DD')) - new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1))) / 86400000) + new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1)).getDay()) / 7) - parseInt((new Date(this.$moment(new Date()).format('YYYY') + '-01-01').getDay() + 5) / 6),
      summaryWeeksEnd: Number(this.$moment(new Date()).format('YYYY')) * 100 + Math.ceil((((new Date(this.$moment(new Date()).format('YYYY-MM-DD')) - new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1))) / 86400000) + new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1)).getDay()) / 7) - parseInt((new Date(this.$moment(new Date()).format('YYYY') + '-01-01').getDay() + 5) / 6),
      summaryVendorNameMenu: [],
      summaryModelNameMenu: [],
      summaryPartNumberMenu: [],
      summaryButtonCodeMenu: [],
      summaryVendorName: '',
      summaryModelName: '',
      summaryPartNumber: '',
      summaryButtonCodeType: 'all',
      summaryButtonCode: [],
      summaryYearWeeksList: [],
      summaryAllYearWeeksList: [],
      tempVendorName: '',
      tempModelName: '',
      tempPartNumber: '',
      tempButtonCode: [],
      CpkGradeList: [],
      contentDateList: [],
      responseVendorName: '',
      responseModelName: '',
      responsePartNumber: '',
      responseDateStart: '',
      responseDateEnd: '',
      responseWeeksStart: '',
      responseWeeksEnd: '',
      responseCpkData: [],
      responseStandardData: {
        cpk: 0
      },
      responseDataCompile: [],
      chartLineView: false,
      chartLineViewNum: 0,
      chartLineViewName: '',
      chartViewButtonCode: '',
      chartViewTestItemName: '',
      barXaxisName: [],
      barXaxisStandard: [],
      barXaxisValue: [],
      WeekList: ['日', '一', '二', '三', '四', '五', '六'],
      rowsAmount: [25, 50, 100, { text: 'All', value: -1 }],
      pagination: {
        page: 1,
        rowsPerPage: 25,
        sortBy: null,
        descending: null
      },
      // pages: 0,
      responseData: [],
      handler_line: new Vue()
    }
  },
  computed: {
    ...mapState('app', ['theme', 'shrink']),
    processloadingView () {
      if (!this.summaryLoading.Key_Height && !this.summaryLoading.Detail) {
        return false
      } else {
        return true
      }
    },
    responseStandardUpperLower () {
      const array = []
      let ULindex = this.responseStandardData.upperSpecValue !== null ? -0.5 : null
      let LLindex = this.responseStandardData.lowerSpecValue !== null ? -0.5 : null
      this.responseStandardData.distributionCoverageList.map(function (e) {
        if (e.define.type === 'Sigma') {
          return e.define
        } else {
          return null
        }
      }).filter(Boolean).forEach((r, rr) => {
        if (this.responseStandardData.lowerSpecValue > r.value) {
          LLindex = rr + 0.5
        }
        if (this.responseStandardData.upperSpecValue >= r.value) {
          ULindex = rr + 0.5
        }
      })
      if (ULindex) {
        array.push({ value: ULindex, text: 'Upper Spec Value' })
      }
      if (LLindex) {
        array.push({ value: LLindex, text: 'Lower Spec Value' })
      }
      return array
    },
    responseHeaders () {
      const array = [
        {
          sortable: true,
          type: 'keyword',
          text: 'Name',
          index: 0,
          value: 'buttonCode',
          group: '',
          align: 'left'
        }
      ]
      this.responseDataGroup.forEach((g, gg) => {
        let rangeValue = ''
        this.responseData.forEach((r, rr) => {
          if (r.testItemName === g && r.lowerSpecValue && r.upperSpecValue) {
            rangeValue = ' (' + r.lowerSpecValue + ' ~ ' + r.upperSpecValue + ')'
          } else if (r.testItemName === g) {
            rangeValue = ' (' + (r.lowerSpecValue || r.upperSpecValue) + ')'
          }
        })
        array.push({
          sortable: true,
          type: 'formula',
          text: 'CPK',
          index: gg * 5 + 1,
          value: g + '_cpk',
          group: g,
          groupTitle: g + rangeValue,
          align: 'left'
        }, {
          sortable: true,
          // type: 'options',
          type: 'keyword',
          text: 'Level',
          index: gg * 5 + 2,
          value: g + '_lvl',
          group: g,
          groupTitle: '',
          align: 'left'
        }, {
          sortable: true,
          type: 'formula',
          text: 'Max',
          index: gg * 5 + 3,
          value: g + '_max',
          group: g,
          groupTitle: '',
          align: 'left'
        }, {
          sortable: true,
          type: 'formula',
          text: 'Min',
          index: gg * 5 + 4,
          value: g + '_min',
          group: g,
          groupTitle: '',
          align: 'left'
        }, {
          sortable: true,
          type: 'formula',
          text: 'Avg',
          index: gg * 5 + 5,
          value: g + '_avg',
          group: g,
          groupTitle: '',
          align: 'left'
        })
      })
      return array
    },
    responseDataGroup () {
      const array = []
      this.responseData.forEach((r, rr) => {
        if (array.indexOf(r.testItemName) === -1) {
          array.push(r.testItemName)
        }
      })
      array.sort(function (a, b) {
        return parseFloat(a) - parseFloat(b)
      })
      return array
    },
    responseDataCompileFailList () {
      const array = []
      this.responseData.forEach((r, rr) => {
        if (!this.doCpkGrade(r.cpk)) {
          if (array.map(function (e) { return e.buttonCode }).indexOf(String(r.buttonCode)) === -1) {
            array.push({ buttonCode: String(r.buttonCode), list: [r.testItemName] })
          } else {
            array[array.map(function (e) { return e.buttonCode }).indexOf(String(r.buttonCode))].list.push(r.testItemName)
          }
        }
      })
      return array
    },
    responseDataEarlyCompile () {
      const array = []
      const arrayTemp = []
      this.responseData.forEach((r, rr) => {
        if (arrayTemp.map(function (e) { return e.buttonCode }).indexOf(String(r.buttonCode)) === -1) {
          arrayTemp.push({ buttonCode: String(r.buttonCode), project: [r] })
        } else {
          arrayTemp[arrayTemp.map(function (e) { return e.buttonCode }).indexOf(String(r.buttonCode))].project.push(r)
        }
      })
      arrayTemp.forEach((t, tt) => {
        array.push({ buttonCode: String(t.buttonCode), index: tt })
        t.project.sort(function (a, b) {
          return a.testItemName.localeCompare(b.testItemName)
        })
        t.project.forEach((p, pp) => {
          array[tt][p.testItemName + '_cpk'] = p.cpk
          array[tt][p.testItemName + '_lvl'] = this.doCpkGradeName(p.cpk)
          array[tt][p.testItemName + '_min'] = p.min
          array[tt][p.testItemName + '_max'] = p.max
          array[tt][p.testItemName + '_avg'] = p.avg
        })
      })
      return array
    },
    filterDirections () {
      let result = ''
      if (this.filterInfo.type === 'formula') {
        if (this.filterBtnSelect.indexOf('le') > -1 && this.filterBtnSelect.indexOf('ge') > -1) {
          result = '介於' + this.filterMinValue + '~' + this.filterMaxValue + '之間'
        } else if (this.filterBtnSelect.indexOf('le') > -1) {
          result = '小於等於' + this.filterMaxValue
        } else if (this.filterBtnSelect.indexOf('ge') > -1) {
          result = '大於等於' + this.filterMinValue
        }
      } else if (this.filterInfo.type === 'keyword') {
        result = '包含「' + (this.filterKeyword || '') + '」的字詞'
      }
      return result
    },
    pages () {
      return Math.ceil(this.responseDataCompile.length / this.pagination.rowsPerPage)
    },
    pageslist () {
      let i = 0
      const list = []
      if (this.pagination.rowsPerPage) {
        for (i = 0; i < Math.ceil(this.responseDataCompile.length / this.pagination.rowsPerPage); i++) {
          list.push(i + 1)
        }
      }
      return list
    },
    options_line () {
      return {
        data: {
          x: 'x',
          columns: [
            this.barXaxisName,
            this.barXaxisStandard,
            this.barXaxisValue
          ],
          axes: {
            常態分佈: 'y2'
          },
          type: 'bar',
          types: {
            常態分佈: 'spline'
          }
        },
        axis: {
          x: {
            type: 'category',
            padding: {
              top: 0,
              right: 0.1,
              bottom: 0,
              left: 0.2
            }
          },
          y: {
            tick: {
              format: function (value) { return value }
            },
            label: {
              text: '統計次數',
              position: 'outer-middle'
            }
          },
          y2: {
            tick: {
              format: function (value) { return value }
            },
            label: {
              text: '常態分佈',
              position: 'outer-middle'
            },
            show: true
          }
        },
        tooltip: {
          order: function (t1, t2) {
            return t1.id < t2.id
          },
          contents: (d, value, ratio, id) => {
            return '<table class="c3-tooltip"><tbody><tr><th colspan="2">' + this.barXaxisName[d[1].index + 1] + '</th></tr><tr class="c3-tooltip-name--統計次數"><td class="name"><span style="background-color:#1f77b4"></span>統計次數</td><td class="value">' + d[1].value + '</td></tr></tbody></table>'
          }
          // format: {
          //   title: (d, value) => {
          //     return `${this.barXaxisName[d + 1]}`
          //   },
          //   value: (value, ratio, id) => {
          //     // if (id === '常態分佈') {
          //     //   return `${value.toFixed(3)}`
          //     // } else {
          //     //   return `${value}`
          //     // }
          //   }
          // }
          // order: 'asc'
        },
        grid: {
          x: {
            show: true,
            lines: this.responseStandardUpperLower
          },
          y: {
            show: true
          }
        },
        legend: {
          position: 'bottom',
          padding: 16,
          show: false
        },
        size: {
          width: ((window.innerWidth - this.shrink) * 0.5) - 64,
          height: 500
        },
        spline: {
          interpolation: {
            type: 'monotone'
          }
        }
      }
    },
    contentDateListWeeks () {
      const array = []
      this.summaryYearWeeksList.forEach((n, nn) => {
        n.forEach((w, ww) => {
          if (ww === n.length - 1 && Number(this.$moment(w.start).format('DD')) > 25) {
            for (let d = new Date((Number(this.$moment(this.startdate).format('YYYY')) + nn) + '-' + this.$moment(w.start).format('MM-DD')).getTime(); d <= new Date((Number(this.$moment(this.startdate).format('YYYY')) + nn + 1) + '-' + this.$moment(w.end).format('MM-DD')).getTime(); d = d + 86400000) {
              // console.log(this.$moment(d).format('YYYY-MM-DD'))
              if (this.contentDateList.indexOf(this.$moment(d).format('YYYY-MM-DD')) !== -1) {
                array.push(Number((Number(this.$moment(this.startdate).format('YYYY')) + nn) + (ww < 10 ? '0' + String(ww) : String(ww))))
              }
            }
          }
          for (let d = new Date((Number(this.$moment(this.startdate).format('YYYY')) + nn) + '-' + this.$moment(w.start).format('MM-DD')).getTime(); d <= new Date((Number(this.$moment(this.startdate).format('YYYY')) + nn) + '-' + this.$moment(w.end).format('MM-DD')).getTime(); d = d + 86400000) {
            // console.log(this.$moment(d).format('YYYY-MM-DD'))
            if (this.contentDateList.indexOf(this.$moment(d).format('YYYY-MM-DD')) !== -1) {
              array.push(Number((Number(this.$moment(this.startdate).format('YYYY')) + nn) + (ww < 10 ? '0' + String(ww) : String(ww))))
            }
          }
        })
      })
      return array
    }
  },
  watch: {
    processloadingView (val) {
      if (!val && !this.overload) {
        this.processingView = true
      } else if (!val && this.overload) {
        alert(this.overloadMsg)
      }
    },
    summaryVendorName (val) {
      this.getModelName(val)
    },
    summaryModelName (val) {
      this.getPartNumber(this.summaryVendorName, val)
    },
    summaryPartNumber (val) {
      this.contentDateList = []
      if (val) this.getContentDateList(this.summaryVendorName, this.summaryModelName, val)
      this.summaryButtonCodeMenu = []
      this.summaryButtonCode = []
      if (val) this.getButtonCode(this.summaryVendorName, this.summaryModelName, val)
    },
    'pagination.rowsPerPage' (val) {
      if (this.pagination.page > this.pages) {
        this.pagination.page = this.pages
      }
      if (this.pagination.page < 1) {
        this.pagination.page = 1
      }
    },
    pagination: {
      handler () {
        this.doTableSortDetail()
      },
      deep: true
    },
    responseDataGroup (val) {
      this.filterSettingDetailList = new Array(val.length * 5 + 1)
      this.filterSettingList = new Array(val.length * 5 + 1)
    },
    filterSettingList (val) {
      this.responseDataCompile = []
      this.responseDataEarlyCompile.forEach((t, tt) => {
        if (this.getFilterResult(t)) {
          this.responseDataCompile.push(t)
          this.doTableSortDetail()
        }
      })
    },
    chartLineView (val) {
      if (val) {
        this.barXaxisName = ['x']
        this.barXaxisStandard = ['常態分佈']
        this.barXaxisValue = ['統計次數']
        if (this.responseStandardData.distributionCoverageList.length > 0) {
          this.responseStandardData.distributionCoverageList.forEach((r) => {
            if (r.define.type === 'Sigma') {
              this.barXaxisName.push(r.define.name)
              if (this.responseStandardData.standardDeviation === 0) {
                this.barXaxisStandard.push(r.amount.toFixed(3))
              } else {
                this.barXaxisStandard.push(new NormalDistribution(this.responseStandardData.average, this.responseStandardData.standardDeviation).pdf(r.define.value).toFixed(3))
              }
              this.barXaxisValue.push(r.amount)
            }
          })
        }
        if (this.chartLineViewNum === 0) {
          this.handler_line.$emit('init', this.options_line)
          this.chartLineViewNum++
        } else {
          this.handler_line.$emit('dispatch', chart => {
            const options = {
              columns: [
                this.barXaxisName,
                this.barXaxisStandard,
                this.barXaxisValue
              ]
            }
            chart.load(options)
            chart.xgrids(this.responseStandardUpperLower)
          })
        }
      } else {
        // this.handler_line.$emit('destroy')
      }
    }
  },
  mounted () {
    this.$store.dispatch('getAuthorize', '/keypart/keyboard/keyboardKeyHeightAnalyzer')
      .then(response => {
        if (response.status === 'success' && response.data.accessible) {
          JSON.parse(localStorage.getItem('moduleDataInitDate')).forEach((n) => {
            if (n.name === 'keyboard') {
              this.startdate = n.value
            }
          })
          if (this.$route.params.searchWeeksStart && this.$route.params.searchWeeksEnd && this.$route.params.searchVendorName && this.$route.params.searchModelName && this.$route.params.searchPartNumber) {
            this.summaryWeeksStart = Number(this.$route.params.searchWeeksStart)
            this.summaryWeeksEnd = Number(this.$route.params.searchWeeksEnd)
            this.tempVendorName = this.$route.params.searchVendorName
            this.tempModelName = this.$route.params.searchModelName
            this.tempPartNumber = this.$route.params.searchPartNumber
            this.tempButtonCode = this.$route.params.searchButtonCode ? this.$route.params.searchButtonCode.split(',') : []
          }
          this.$http.get('/api/keypart/keyboard/vendorName').then(res => res.data)
            .then(res => {
              if (res.status === 'success') {
                this.summaryVendorNameMenu = []
                res.data.forEach((n) => {
                  this.summaryVendorNameMenu.push({ text: n, value: n })
                })
                if (this.tempVendorName !== '') {
                  this.summaryVendorName = this.tempVendorName
                }
              }
            })
          this.loadData()
        } else if (response.errCode === 'E10000002') {
          const routeData = this.$router.resolve({ path: '/noEnter/E10000002' })
          localStorage.setItem('errormsg', response.errMsg)
          window.location.assign(routeData.href)
        } else {
          const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
          window.location.assign(routeData.href)
        }
      })
      .catch(err => {
        console.log(err)
        const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
        window.location.assign(routeData.href)
      })
  },
  methods: {
    async loadData () {
      this.loadingView = true
      await this.YearWeeksList()
      await this.AllYearWeeksList()
    },
    YearWeeksList () {
      const Arr = []
      const YearStart = this.$moment(this.startdate).format('YYYY')
      const YearEnd = this.$moment(new Date()).format('YYYY')
      let NewYear = false
      let YearEndWeek = 0

      for (let y = 0; y <= (YearEnd - YearStart + 1); y++) {
        Arr[y] = []
        let w = 0
        let n = 0
        let day = new Date(Number(YearStart) + y + '-01-01').getTime()
        if (y === 0 && this.$moment(this.startdate).format('MM-DD') !== '01-01') {
          day = new Date(Number(YearStart) + y + '-' + this.$moment(this.startdate).format('MM-DD')).getTime()
          w = Math.ceil((((new Date(this.$moment(this.startdate).format('YYYY-MM-DD')) - new Date(new Date(this.$moment(this.startdate).format('YYYY'), 0, 1))) / 86400000) + new Date(new Date(this.$moment(this.startdate).format('YYYY'), 0, 1)).getDay()) / 7)
          if (new Date(this.$moment(this.startdate).format('YYYY') + '-01-01').getDay() > 0) {
            w = w - 1
          }
          Arr[y][w] = { start: this.$moment(this.startdate).format('YYYY-MM-DD'), end: '' }
          if (new Date(this.$moment(this.startdate).format('YYYY-MM-DD')).getDay() === 6) {
            Arr[y][w].end = this.$moment(this.startdate).format('YYYY-MM-DD')
          } else if (new Date(this.$moment(this.startdate).format('YYYY-MM-DD')).getDay() === 0) {
            w = w - 1
          }
        } else if (new Date(Number(YearStart) + y + '-01-01').getDay() !== 0) {
          n = 6 - new Date(Number(YearStart) + y + '-01-01').getDay()
          Arr[y][1] = { start: '', end: '' }
        }
        for (let d = (day + (86400000 * n)); d <= (new Date(Number(YearStart) + y + '-12-31').getTime()); d = d + 86400000) {
          if (y > 0 && new Date(d).getDay() === 6) {
            if (Arr[y][1].start === '') {
              Arr[y - 1][YearEndWeek].end = this.$moment(d).format('YYYY-MM-DD')
              Arr[y][0] = { start: Arr[y - 1][YearEndWeek].start, end: this.$moment(d).format('YYYY-MM-DD') }
              w = 0
              YearEndWeek = w
              NewYear = true
            }
          }
          if (new Date(d).getDay() === 0) {
            w++
            YearEndWeek = w
            NewYear = false
            Arr[y][w] = { start: this.$moment(d).format('YYYY-MM-DD'), end: '' }
          } else if (!NewYear && new Date(d).getDay() === 6) {
            Arr[y][w].end = this.$moment(d).format('YYYY-MM-DD')
          }
        }
      }
      this.summaryYearWeeksList = Arr
    },
    AllYearWeeksList () {
      const Arr = []
      const YearStart = this.$moment(this.startdate).format('YYYY')
      let YearEnd = this.$moment(this.summaryMonth).format('YYYY')
      let LastYear = 0
      if (new Date(this.$moment(this.summaryMonth).format('YYYY') + '-01-01').getDay() > 0) {
        LastYear = 1
      }
      const WeeksEnd = Math.ceil((((new Date(this.$moment(new Date()).format('YYYY-MM-DD')) - new Date(new Date(this.$moment(this.summaryMonth).format('YYYY'), 0, 1))) / 86400000) + new Date(new Date(this.$moment(this.summaryMonth).format('YYYY'), 0, 1)).getDay()) / 7) - LastYear
      if (WeeksEnd === 0) {
        YearEnd--
      }
      for (let y = 0; y <= (YearEnd - YearStart); y++) {
        for (let w = 1; w < this.summaryYearWeeksList[y].length; w++) {
          if (y === (YearEnd - YearStart) && w === WeeksEnd) {
            Arr.push({ text: (Number(YearStart) + y) + '　w' + w + '　( ' + this.$moment(this.summaryYearWeeksList[y][w].start).format('MM-DD') + ' ～ ' + this.$moment(this.summaryYearWeeksList[y][w].end).format('MM-DD') + ' )', value: (Number(YearStart) + y) * 100 + w })
            break
          }
          if (this.summaryYearWeeksList[y][w]) {
            Arr.push({ text: (Number(YearStart) + y) + '　w' + w + '　( ' + this.$moment(this.summaryYearWeeksList[y][w].start).format('MM-DD') + ' ～ ' + this.$moment(this.summaryYearWeeksList[y][w].end).format('MM-DD') + ' )', value: (Number(YearStart) + y) * 100 + w })
          }
        }
      }
      Arr.sort(function (a, b) { return b.value - a.value })
      this.summaryAllYearWeeksList = Arr
      this.loadingView = false
    },
    responseCpkDataCompileGroup (groupType, data) {
      const array = []
      data.forEach((i, ii) => {
        if (array.map(function (e) { return e.buttonCode }).indexOf(i[groupType]) === -1) {
          array.push({ buttonCode: i[groupType], item: [i] })
        } else {
          array[array.map(function (e) { return e.buttonCode }).indexOf(i[groupType])].item.push(i)
        }
      })
      // array.item.sort((a, b) => a.testItemName.localeCompare(b.testItemName))
      return array
    },
    getCpkGradeList () {
      this.$http.get('/api/keypart/support/cpkEvaluationList').then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.CpkGradeList = res.data
          }
        })
    },
    getFilterResult (array) {
      let result = true
      this.filterSettingDetailList.forEach((n, nn) => {
        if (n !== undefined) {
          if (this.responseHeaders[nn].type === 'formula') {
            if (n[0].indexOf('le') !== -1 && n[0].indexOf('ge') !== -1) {
              if (!(array[this.responseHeaders[nn].value] >= n[1] && array[this.responseHeaders[nn].value] <= n[2])) {
                result = false
              }
            } else if (n[0].indexOf('le') !== -1) {
              if (!(array[this.responseHeaders[nn].value] <= n[2])) {
                result = false
              }
            } else if (n[0].indexOf('ge') !== -1) {
              if (!(array[this.responseHeaders[nn].value] >= n[1])) {
                result = false
              }
            }
          } else if (this.responseHeaders[nn].type === 'keyword') {
            if (array[this.responseHeaders[nn].value].toUpperCase().indexOf(n.toUpperCase()) === -1) {
              result = false
            }
          }
        }
      })
      return result
    },
    doTableSort (val) {
      if (this.pagination.sortBy === val) {
        if (this.pagination.descending === false) {
          this.pagination.descending = true
        } else if (this.pagination.descending === true) {
          this.pagination.descending = null
          this.pagination.sortBy = null
        } else if (this.pagination.descending === null) {
          this.pagination.descending = false
        }
      } else {
        this.pagination.sortBy = val
        this.pagination.descending = false
      }
    },
    doTableSortDetail () {
      if (this.pagination.descending === null) {
        this.responseDataCompile.sort((a, b) => {
          return parseFloat(a.index) - parseFloat(b.index)
        })
      } else if (this.pagination.descending) {
        if (this.responseHeaders[this.responseHeaders.map(function (e) { return e.value }).indexOf(this.pagination.sortBy)].type === 'keyword') {
          this.responseDataCompile.sort((a, b) => b[this.pagination.sortBy].localeCompare(a[this.pagination.sortBy]))
        } else {
          this.responseDataCompile.sort((a, b) => {
            return parseFloat(b[this.pagination.sortBy]) - parseFloat(a[this.pagination.sortBy])
          })
        }
      } else {
        if (this.responseHeaders[this.responseHeaders.map(function (e) { return e.value }).indexOf(this.pagination.sortBy)].type === 'keyword') {
          this.responseDataCompile.sort((a, b) => a[this.pagination.sortBy].localeCompare(b[this.pagination.sortBy]))
        } else {
          this.responseDataCompile.sort((a, b) => {
            return parseFloat(a[this.pagination.sortBy]) - parseFloat(b[this.pagination.sortBy])
          })
        }
      }
    },
    doMinValueReplaceText () {
      this.filterMinValue = this.filterMinValue.replace(/^[^0-9-]*(-?)(\d*(?:\.\d{0,2})?).*$/g, '$1$2')
    },
    doMaxValueReplaceText () {
      this.filterMaxValue = this.filterMaxValue.replace(/^[^0-9-]*(-?)(\d*(?:\.\d{0,2})?).*$/g, '$1$2')
    },
    doWarnGroupCpk (index) {
      let result = false
      this.responseDataGroup.forEach(g => {
        if (!this.doCpkGrade(this.responseDataCompile[index][g + '_cpk'])) {
          result = true
        }
      })
      return result
    },
    doCpkGrade (cpk) {
      let result = false
      this.CpkGradeList.forEach(e => {
        if ((e.upperSpecLimit ? e.upperSpecLimit : 999999999999999) > cpk && cpk >= (e.lowerSpecLimit ? e.lowerSpecLimit : -999999999999999)) {
          result = (e.status === 'qualified')
        }
      })
      return result
    },
    doCpkGradeName (cpk) {
      let result = false
      this.CpkGradeList.forEach(e => {
        if ((e.upperSpecLimit ? e.upperSpecLimit : 999999999999999) > cpk && cpk >= (e.lowerSpecLimit ? e.lowerSpecLimit : -999999999999999)) {
          result = e.evaluation
        }
      })
      return result
    },
    dofilterViewOpen (type, index, group, item) {
      this.filterTitle = group ? group + '-' + item : item
      this.filterInfo = {
        type: type,
        index: index,
        group: group,
        item: item
      }
      if (type === 'formula') {
        this.filterBtnSelect = this.filterSettingDetailList[index] ? this.filterSettingDetailList[index][0] : []
        this.filterMinValue = this.filterSettingDetailList[index] ? this.filterSettingDetailList[index][1] : 0
        this.filterMaxValue = this.filterSettingDetailList[index] ? this.filterSettingDetailList[index][2] : 0
      } else if (type === 'keyword') {
        this.filterKeyword = this.filterSettingList[index]
      }
      this.filterView = true
    },
    dofilterSetting () {
      if (this.filterInfo.type === 'formula') {
        if (this.filterBtnSelect.indexOf('le') > -1 && this.filterBtnSelect.indexOf('ge') > -1) {
          if (Number(this.filterMaxValue) >= Number(this.filterMinValue)) {
            this.filterSettingDetailList[this.filterInfo.index] = [this.filterBtnSelect, this.filterMinValue, this.filterMaxValue]
            this.filterSettingList.splice(this.filterInfo.index, 1, this.filterMinValue + '≤ X ≤' + this.filterMaxValue)
            this.filterView = false
          } else {
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = '左側數字不得大於右側數字'
          }
        } else if (this.filterBtnSelect.indexOf('le') > -1) {
          this.filterSettingDetailList[this.filterInfo.index] = [this.filterBtnSelect, 0, this.filterMaxValue]
          this.filterSettingList.splice(this.filterInfo.index, 1, '≤' + this.filterMaxValue)
          this.filterView = false
        } else if (this.filterBtnSelect.indexOf('ge') > -1) {
          this.filterSettingDetailList[this.filterInfo.index] = [this.filterBtnSelect, this.filterMinValue, 0]
          this.filterSettingList.splice(this.filterInfo.index, 1, '≥' + this.filterMinValue)
          this.filterView = false
        } else {
          this.snackbar = true
          this.snackbarColor = 'red'
          this.snackbarMsg = '請輸入條件'
        }
      } else if (this.filterInfo.type === 'keyword') {
        this.filterSettingDetailList[this.filterInfo.index] = this.filterKeyword
        this.filterSettingList.splice(this.filterInfo.index, 1, this.filterKeyword)
        this.filterView = false
      }
    },
    dofilterClear (index) {
      this.filterSettingDetailList[index] = undefined
      this.filterSettingList.splice(index, 1, undefined)
    },
    dofilterALLClear () {
      this.filterSettingDetailList = new Array(this.responseDataGroup.length * 5 + 1)
      this.filterSettingList = new Array(this.responseDataGroup.length * 5 + 1)
    },
    getModelName (vendor) {
      this.$http.get(`/api/keypart/keyboard/modelName/${vendor}`).then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.summaryModelNameMenu = []
            this.summaryModelName = ''
            this.summaryPartNumberMenu = []
            this.summaryPartNumber = ''
            this.summaryButtonCodeMenu = []
            this.summaryButtonCode = []
            res.data.forEach((n) => {
              this.summaryModelNameMenu.push({ text: n, value: n })
            })
            if (this.tempModelName !== '') {
              this.summaryModelName = this.tempModelName
            }
          }
        })
    },
    getPartNumber (vendor, model) {
      const body = {
        vendorName: vendor,
        modelName: model
      }
      this.$http.get('/api/keypart/keyboard/partNumber?' + qs.stringify(body)).then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.summaryPartNumberMenu = []
            this.summaryPartNumber = ''
            this.summaryButtonCodeMenu = []
            this.summaryButtonCode = []
            res.data.forEach((n) => {
              this.summaryPartNumberMenu.push({ text: n, value: n })
            })
            if (this.tempPartNumber !== '') {
              this.summaryPartNumber = this.tempPartNumber
            }
          }
        })
    },
    getButtonCode (vendor, model, partNumber) {
      let wwS = 0
      let wwE = 0
      if (Number.isInteger(this.summaryWeeksStart)) {
        wwS = String(this.summaryWeeksStart).substr(4)
      } else {
        wwS = Math.ceil((((new Date(this.$moment(new Date()).format('YYYY-MM-DD')) - new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1))) / 86400000) + new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1)).getDay()) / 7) - parseInt((new Date(this.$moment(new Date()).format('YYYY') + '-01-01').getDay() + 5) / 6)
      }
      if (Number.isInteger(this.summaryWeeksEnd)) {
        wwE = String(this.summaryWeeksEnd).substr(4)
      } else {
        wwE = Math.ceil((((new Date(this.$moment(new Date()).format('YYYY-MM-DD')) - new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1))) / 86400000) + new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1)).getDay()) / 7) - parseInt((new Date(this.$moment(new Date()).format('YYYY') + '-01-01').getDay() + 5) / 6)
      }
      const body = {
        manufactureStart: this.$moment(this.summaryYearWeeksList[String(this.summaryWeeksStart).substr(0, 4) - this.$moment(this.startdate).format('YYYY')][Number(wwS)].start).format('YYYY-MM-DD'),
        manufactureEnd: this.$moment(this.summaryYearWeeksList[String(this.summaryWeeksEnd).substr(0, 4) - this.$moment(this.startdate).format('YYYY')][Number(wwE)].end).format('YYYY-MM-DD'),
        vendorName: vendor,
        modelName: model,
        partNumber: partNumber,
        type: 'Key_Height'
      }
      this.$http.get('/api/keypart/keyboard/buttonCode?' + qs.stringify(body)).then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            res.data.forEach((n) => {
              this.summaryButtonCodeMenu.push({ text: n, value: n })
            })
            if (this.tempButtonCode.length > 0) {
              this.summaryButtonCodeType = 'specific'
              this.summaryButtonCode = this.tempButtonCode
              this.tempVendorName = ''
              this.tempModelName = ''
              this.tempPartNumber = ''
              this.tempButtonCode = []
              this.doSubmit()
            } else if (this.tempPartNumber !== '') {
              this.tempVendorName = ''
              this.tempModelName = ''
              this.tempPartNumber = ''
              this.doSubmit()
            }
          }
        })
    },
    getContentDateList (vendor, model, partNumber) {
      const body = {
        vendorName: vendor,
        modelName: model,
        partNumber: partNumber
      }
      this.$http.get('/api/keypart/keyboard/manufacturingDate?' + qs.stringify(body)).then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.contentDateList = res.data
          }
        })
    },
    async doSubmit () {
      if (this.summaryButtonCodeType === 'specific' && this.summaryButtonCode.length === 0) {
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '請選擇指定按鍵'
      } else if (this.summaryWeeksEnd < this.summaryWeeksStart) {
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '結尾時間不得早於起始時間'
      } else {
        this.processingView = false
        this.summaryNoData = true
        this.summaryLoading = {
          Key_Height: true,
          Detail: true
        }
        this.overload = false
        this.overloadMsg = ''
        let wwS = 0
        let wwE = 0
        if (Number.isInteger(this.summaryWeeksStart)) {
          wwS = String(this.summaryWeeksStart).substr(4)
        } else {
          wwS = Math.ceil((((new Date(this.$moment(new Date()).format('YYYY-MM-DD')) - new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1))) / 86400000) + new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1)).getDay()) / 7) - parseInt((new Date(this.$moment(new Date()).format('YYYY') + '-01-01').getDay() + 5) / 6)
        }
        if (Number.isInteger(this.summaryWeeksEnd)) {
          wwE = String(this.summaryWeeksEnd).substr(4)
        } else {
          wwE = Math.ceil((((new Date(this.$moment(new Date()).format('YYYY-MM-DD')) - new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1))) / 86400000) + new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1)).getDay()) / 7) - parseInt((new Date(this.$moment(new Date()).format('YYYY') + '-01-01').getDay() + 5) / 6)
        }
        const manufactureStart = this.$moment(this.summaryYearWeeksList[String(this.summaryWeeksStart).substr(0, 4) - this.$moment(this.startdate).format('YYYY')][Number(wwS)].start).format('YYYY-MM-DD')
        const manufactureEnd = this.$moment(this.summaryYearWeeksList[String(this.summaryWeeksEnd).substr(0, 4) - this.$moment(this.startdate).format('YYYY')][Number(wwE)].end).format('YYYY-MM-DD')
        const manufactureWeeksStart = this.summaryWeeksStart
        const manufactureWeeksEnd = this.summaryWeeksEnd
        const vendorName = this.summaryVendorName
        const modelName = this.summaryModelName
        const partNumber = this.summaryPartNumber
        const buttonCode = this.summaryButtonCodeType === 'all' ? [] : this.summaryButtonCode
        this.responseCpkData = {
          Key_Height: []
        }
        this.responseData = []
        await this.getCpkGradeList()
        await this.doSubmitType('Key_Height', 'group', vendorName, modelName, partNumber, manufactureStart, manufactureEnd)
        await this.doSubmitTypeDetail('Key_Height', vendorName, modelName, partNumber, manufactureStart, manufactureEnd, buttonCode)
        await this.doSubmitAfter(vendorName, modelName, partNumber, manufactureStart, manufactureEnd, manufactureWeeksStart, manufactureWeeksEnd, buttonCode)
      }
    },
    doSubmitType (type, groupType, vendorName, modelName, partNumber, manufactureStart, manufactureEnd) {
      const body = {
        manufactureStart: manufactureStart,
        manufactureEnd: manufactureEnd,
        vendorName: vendorName,
        modelName: modelName,
        partNumber: partNumber,
        type: type
      }
      this.$http.get('/api/keypart/keyboard/summaryKeyboardAnalysisDataList?' + qs.stringify(body)).then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            if (res.data.length > 0) {
              this.summaryNoData = false
              this.responseCpkData[type] = this.responseCpkDataCompileGroup(groupType, res.data)
            }
            this.summaryLoading[type] = false
          } else { // failure
            this.overload = true
            this.overloadMsg = '系統錯誤，請洽系統管理人員\n錯誤訊息：' + res.errMsg
            this.summaryLoading[type] = false
          }
        })
        .catch(err => {
          this.overload = true
          this.overloadMsg = '系統錯誤，請洽系統管理人員\n錯誤訊息：' + err
          this.summaryLoading[type] = false
        })
    },
    doSubmitTypeDetail (type, vendorName, modelName, partNumber, manufactureStart, manufactureEnd, buttonCode) {
      const body = {
        manufactureStart: manufactureStart,
        manufactureEnd: manufactureEnd,
        vendorName: vendorName,
        modelName: modelName,
        partNumber: partNumber,
        type: type,
        buttonCode: buttonCode
      }
      this.$http.get('/api/keypart/keyboard/detailKeyboardAnalysisDataList?' + qs.stringify(body)).then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            if (res.data.length > 0) {
              this.summaryNoData = false
              this.responseData = res.data
            }
            this.summaryLoading.Detail = false
          } else { // failure
            this.overload = true
            this.overloadMsg = '系統錯誤，請洽系統管理人員\n錯誤訊息：' + res.errMsg
            this.summaryLoading.Detail = false
          }
        })
        .catch(err => {
          this.overload = true
          this.overloadMsg = '系統錯誤，請洽系統管理人員\n錯誤訊息：' + err
          this.summaryLoading.Detail = false
        })
    },
    doSubmitAfter (vendorName, modelName, partNumber, manufactureStart, manufactureEnd, manufactureWeeksStart, manufactureWeeksEnd, buttonCode) {
      this.responseVendorName = vendorName
      this.responseModelName = modelName
      this.responsePartNumber = partNumber
      this.responseDateStart = manufactureStart
      this.responseDateEnd = manufactureEnd
      this.responseWeeksStart = manufactureWeeksStart
      this.responseWeeksEnd = manufactureWeeksEnd
      const Url = '/keypart/keyboard/keyboardKeyHeightAnalyzer/' + this.responseWeeksStart + '/' + this.responseWeeksEnd + '/' + this.responseVendorName + '/' + this.responseModelName + '/' + this.responsePartNumber + '/' + buttonCode.join(',')
      if (this.$router.history.current.path !== Url) {
        this.$router.replace(Url)
      }
    },
    doSubmitSingle (type, buttonCode, testItemName) {
      this.loadingView = true

      const body = {
        manufactureStart: this.responseDateStart,
        manufactureEnd: this.responseDateEnd,
        vendorName: this.responseVendorName,
        modelName: this.responseModelName,
        partNumber: this.responsePartNumber,
        type: type,
        buttonCode: buttonCode,
        testItemName: testItemName
      }

      this.$http.get('/api/keypart/keyboard/singleKeyboardCpkItem?' + qs.stringify(body)).then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.responseStandardData = res.data
            this.responseStandardData.distributionCoverageList.sort(function (a, b) {
              return parseFloat(a.define.value) - parseFloat(b.define.value)
            })
            this.chartViewButtonCode = buttonCode
            this.chartViewTestItemName = testItemName
            this.chartLineView = true
            this.loadingView = false
          } else { // failure
            this.loadingView = false
            alert('系統錯誤，請洽系統管理人員\n錯誤訊息：' + res.errMsg)
          }
        })
        .catch(err => {
          this.loadingView = false
          alert('系統錯誤，請洽系統管理人員\n錯誤訊息：' + err)
        })
    }
  }
}
</script>
<style>
</style>
