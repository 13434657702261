<template>
  <v-container
    fluid
    grid-list-xl
    pt-0
  >
    <v-layout wrap>
      <v-flex
        mt-3
        xs12
      >
        <material-card
          title="面板料件原始生產數據查詢"
          color="general"
          class="card-padding pt-0 pb-3">
          <v-form>
            <v-container
              py-0
              class="mw-100">
              <v-layout
                wrap
                align-items-center>
                <v-flex
                  style="flex-basis:auto"
                  d-flex
                  xs12
                  lg12>
                  <v-radio-group
                    v-model="summaryWay"
                    class="align-items-center"
                    row>
                    <v-radio
                      label="依指定序號查詢"
                      value="sn"/>
                    <v-radio
                      label="依指定日期及料號查詢"
                      value="condition"/>
                  </v-radio-group>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
        <material-card
          v-if="summaryWay==='condition'"
          style="margin-top: 20px!important;">
          <v-form>
            <v-container
              py-0
              class="mw-100">
              <v-layout
                wrap
                align-items-center>
                <v-flex
                  xs12
                  sm6
                  lg2>
                  <v-autocomplete
                    v-model="summaryVendorName"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryVendorNameMenu"
                    label="廠商"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex
                  xs12
                  sm6
                  lg2>
                  <v-autocomplete
                    v-model="summaryPartNumber"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryPartNumberMenu"
                    label="料號"
                    class="v-text-field__slot"
                    color="white"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex
                  xs12
                  lg1/>
                <v-flex
                  flex-small
                  d-flex
                  align-items-center
                  xs12
                  lg5>
                  <!-- <v-autocomplete
                    v-model="summaryWeeksStart"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryAllYearWeeksList"
                    label="起始時間"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  >
                    <template v-slot:item="data">
                      <div
                        :class="{'weeks-list-data-tip':contentDateListWeeks.indexOf(data.item.value)!==-1}"
                        class="v-list__tile__content">
                        <div class="v-list__tile__title">
                          {{ data.item.text }}
                        </div>
                      </div>
                    </template>
                  </v-autocomplete> -->
                  <WeekPicker
                    :list-start-date="startdate"
                    :content-date-list="contentDateList"
                    :select-week="summaryWeeksStart"
                    label-text="起始時間"
                    value-type="sDay"
                    @selected-week="selectedWeekStart"/>
                  <p class="m-0 text-center px-3">到</p>
                  <!-- <v-autocomplete
                    v-model="summaryWeeksEnd"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryAllYearWeeksList"
                    label="結尾時間"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  >
                    <template v-slot:item="data">
                      <div
                        :class="{'weeks-list-data-tip':contentDateListWeeks.indexOf(data.item.value)!==-1}"
                        class="v-list__tile__content">
                        <div class="v-list__tile__title">
                          {{ data.item.text }}
                        </div>
                      </div>
                    </template>
                  </v-autocomplete> -->
                  <WeekPicker
                    :list-start-date="startdate"
                    :content-date-list="contentDateList"
                    :select-week="summaryWeeksEnd"
                    label-text="結尾時間"
                    value-type="eDay"
                    @selected-week="selectedWeekEnd"/>
                </v-flex>
                <v-flex
                  flex-small
                  xs12
                  lg12>
                  <v-card-actions class="m-0 border-top border-secondary mt-3">
                    <v-spacer/>
                    <v-btn
                      :disabled="summaryWeeksStart===''||summaryWeeksEnd===''||summaryVendorName===''||summaryPartNumber===''"
                      color="indigo"
                      class="ml-3"
                      small
                      @click="doDownload()">
                      <v-icon class="mr-2">mdi-export</v-icon>匯出Raw Data
                    </v-btn>
                  </v-card-actions>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
        <material-card
          v-if="summaryWay==='sn'"
          style="margin-top: 20px!important;">
          <v-form>
            <v-container
              py-0
              class="mw-100">
              <v-layout wrap>
                <v-flex
                  flex-small
                  xs12
                  lg12>
                  <v-autocomplete
                    v-model="deviceSnListSelect"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="deviceSnList"
                    label="追蹤序號清單"
                    class="v-text-field__slot issue_tracking_menu_chips row-btn-325"
                    color="white"
                    persistent-hint
                    outlined
                    multiple
                    multitude
                    chips
                  />
                  <v-btn
                    class="mt-3 ml-3 mr-0 font-weight-light float-right"
                    color="black"
                    @click="resetDeviceSnListItem()"
                  >重置清單</v-btn>
                  <v-btn
                    class="mt-3 ml-3 mr-0 font-weight-light float-right"
                    color="red"
                    @click="cancelSelectDeviceSnListItem()"
                  >取消選擇</v-btn>
                  <v-btn
                    class="mt-3 ml-3 mr-0 font-weight-light float-right"
                    color="blue"
                    @click="selectAllDeviceSnListItem()"
                  >選擇全部</v-btn>
                </v-flex>
                <v-flex
                  flex-small
                  xs12
                  lg12>
                  <v-text-field
                    v-model="deviceSnInput"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    label="手輸序號清單 (多組請以逗號區隔)"
                    class="v-text-field__slot row-btn-450"
                    persistent-hint
                    outlined
                  />
                  <!-- <v-text-field
                    v-model="file[0].name"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    label="SN檔名"
                    disabled
                    @click="uploadFile()"/> -->
                  <v-btn
                    class="mt-3 ml-3 mr-0 font-weight-light float-right"
                    color="black"
                    @click="doSampleDownload()">
                    <v-icon class="float-left">mdi-file</v-icon>下載範本檔案
                  </v-btn>
                  <v-btn
                    class="mt-3 ml-3 mr-0 font-weight-light float-right"
                    color="black"
                    @click="uploadFile(0)">
                    <v-icon class="float-left">mdi-expand-all</v-icon>匯入序號清單
                  </v-btn>
                  <!-- <v-btn
                    class="mt-3 ml-3 mr-0 font-weight-light float-right"
                    color="black"
                    @click="cancelFile(0)">
                    <v-icon class="float-left">mdi-close</v-icon>清除檔案
                  </v-btn> -->
                  <v-btn
                    class="mt-3 ml-3 mr-0 font-weight-light float-right"
                    color="black"
                    @click="addDeviceSnListItem()">
                    <v-icon class="float-left">mdi-plus</v-icon>新增序號
                  </v-btn>
                  <input
                    v-show="false"
                    id="upload-file"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    type="file"
                    label="檔名"
                    @change="setFile($event)">
                </v-flex>
                <v-flex
                  flex-small
                  xs12
                  lg12>
                  <v-card-actions class="m-0 border-top border-secondary mt-3">
                    <v-spacer/>
                    <v-btn
                      :disabled="deviceSnListSelect.length===0"
                      color="indigo"
                      class="ml-3"
                      small
                      @click="doDownload()">
                      <v-icon class="mr-2">mdi-export</v-icon>匯出Raw Data
                    </v-btn>
                  </v-card-actions>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="processingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        PROCESSING 處理中
      </p>
    </v-dialog>
    <v-dialog
      v-model="loadingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :color="snackbarColor"
      :top="true">
      {{ snackbarMsg }}
      <v-btn
        flat
        @click="snackbar = false">
        關閉
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import qs from 'qs'
import $ from 'jquery'
import { mapState } from 'vuex'
import Loading from '@/assets/img/loading.gif'
import WeekPicker from '@/components/module/datepicker/WeekPicker.vue'

export default {
  name: 'LcdModuleRawDataFinder',
  components: {
    WeekPicker
  },
  data () {
    return {
      startdate: '2018-04-01',
      snackbar: false,
      snackbarColor: 'green',
      snackbarMsg: '',
      Loading,
      loadingView: false,
      processingView: false,
      expressDownload: false,
      summaryMonth: this.$moment(new Date()).format('YYYY-MM'),
      // summaryWeeksStart: Number(this.$moment(new Date()).format('YYYY')) * 100 + Math.ceil((((new Date(this.$moment(new Date()).format('YYYY-MM-DD')) - new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1))) / 86400000) + new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1)).getDay()) / 7) - parseInt((new Date(this.$moment(new Date()).format('YYYY') + '-01-01').getDay() + 5) / 6),
      // summaryWeeksEnd: Number(this.$moment(new Date()).format('YYYY')) * 100 + Math.ceil((((new Date(this.$moment(new Date()).format('YYYY-MM-DD')) - new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1))) / 86400000) + new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1)).getDay()) / 7) - parseInt((new Date(this.$moment(new Date()).format('YYYY') + '-01-01').getDay() + 5) / 6),
      summaryWeeksStart: this.$moment(new Date()).format('YYYY-MM-DD'),
      summaryWeeksEnd: this.$moment(new Date()).format('YYYY-MM-DD'),
      summaryVendorNameMenu: [],
      summaryPartNumberMenu: [],
      summaryVendorName: '',
      summaryPartNumber: '',
      summaryWay: 'sn',
      contentDateList: [],
      responseExcelToObjectData: [],
      responseExcelToListData: {},
      deviceSnList: [],
      deviceSnListSelect: [],
      deviceSnInput: '',
      file: [
        {
          file: '',
          name: '',
          url: ''
        }
      ],
      pagination: {
        rowsPerPage: -1,
        sortBy: '', // The field that you're sorting by
        descending: true
      },
      headers: [
        {
          sortable: true,
          text: '追蹤問題',
          value: 'name',
          class: ''
        },
        {
          sortable: false,
          text: '操作',
          value: 'operation',
          class: ''
        }
      ]
    }
  },
  computed: {
    ...mapState('app', ['theme', 'shrink']),
    userPk () {
      return JSON.parse(localStorage.getItem('user')).userPk
    }
    // contentDateListWeeks () {
    //   const array = []
    //   this.summaryYearWeeksList.forEach((n, nn) => {
    //     n.forEach((w, ww) => {
    //       if (ww === n.length - 1 && Number(this.$moment(w.start).format('DD')) > 25) {
    //         for (let d = new Date((Number(this.$moment(this.startdate).format('YYYY')) + nn) + '-' + this.$moment(w.start).format('MM-DD')).getTime(); d <= new Date((Number(this.$moment(this.startdate).format('YYYY')) + nn + 1) + '-' + this.$moment(w.end).format('MM-DD')).getTime(); d = d + 86400000) {
    //           // console.log(this.$moment(d).format('YYYY-MM-DD'))
    //           if (this.contentDateList.indexOf(this.$moment(d).format('YYYY-MM-DD')) !== -1) {
    //             array.push(Number((Number(this.$moment(this.startdate).format('YYYY')) + nn) + (ww < 10 ? '0' + String(ww) : String(ww))))
    //           }
    //         }
    //       }
    //       for (let d = new Date((Number(this.$moment(this.startdate).format('YYYY')) + nn) + '-' + this.$moment(w.start).format('MM-DD')).getTime(); d <= new Date((Number(this.$moment(this.startdate).format('YYYY')) + nn) + '-' + this.$moment(w.end).format('MM-DD')).getTime(); d = d + 86400000) {
    //         // console.log(this.$moment(d).format('YYYY-MM-DD'))
    //         if (this.contentDateList.indexOf(this.$moment(d).format('YYYY-MM-DD')) !== -1) {
    //           array.push(Number((Number(this.$moment(this.startdate).format('YYYY')) + nn) + (ww < 10 ? '0' + String(ww) : String(ww))))
    //         }
    //       }
    //     })
    //   })
    //   return array
    // }
  },
  watch: {
    summaryVendorName (val) {
      this.getPartNumber(val)
    },
    summaryPartNumber (val) {
      this.contentDateList = []
      if (val) this.getContentDateList(this.summaryVendorName, val)
    },
    startDate (val) {
      this.startDateMenu = false
    },
    endDate (val) {
      this.endDateMenu = false
    }
  },
  mounted () {
    this.$store.dispatch('getAuthorize', '/keypart/lcdModule/lcdModuleRawDataFinder')
      .then(response => {
        if (response.status === 'success' && response.data.accessible) {
          JSON.parse(localStorage.getItem('moduleDataInitDate')).forEach((n) => {
            if (n.name === 'lcdModule') {
              this.startdate = n.value
            }
          })
          this.$http.get('/api/keypart/lcdModule/vendorName').then(res => res.data)
            .then(res => {
              if (res.status === 'success') {
                this.summaryVendorNameMenu = []
                res.data.forEach((n) => {
                  this.summaryVendorNameMenu.push({ text: n, value: n })
                })
              }
            })
          // this.loadData()
        } else if (response.errCode === 'E10000002') {
          const routeData = this.$router.resolve({ path: '/noEnter/E10000002' })
          localStorage.setItem('errormsg', response.errMsg)
          window.location.assign(routeData.href)
        } else {
          const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
          window.location.assign(routeData.href)
        }
      })
      .catch(err => {
        console.log(err)
        const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
        window.location.assign(routeData.href)
      })
  },
  methods: {
    selectedWeekStart (val) {
      this.summaryWeeksStart = val
    },
    selectedWeekEnd (val) {
      this.summaryWeeksEnd = val
    },
    // async loadData () {
    //   this.loadingView = true
    //   await this.YearWeeksList()
    //   await this.AllYearWeeksList()
    // },
    // YearWeeksList () {
    //   const Arr = []
    //   const YearStart = this.$moment(this.startdate).format('YYYY')
    //   const YearEnd = this.$moment(new Date()).format('YYYY')
    //   let NewYear = false
    //   let YearEndWeek = 0

    //   for (let y = 0; y <= (YearEnd - YearStart + 1); y++) {
    //     Arr[y] = []
    //     let w = 0
    //     let n = 0
    //     let day = new Date(Number(YearStart) + y + '-01-01').getTime()
    //     if (y === 0 && this.$moment(this.startdate).format('MM-DD') !== '01-01') {
    //       day = new Date(Number(YearStart) + y + '-' + this.$moment(this.startdate).format('MM-DD')).getTime()
    //       w = Math.ceil((((new Date(this.$moment(this.startdate).format('YYYY-MM-DD')) - new Date(new Date(this.$moment(this.startdate).format('YYYY'), 0, 1))) / 86400000) + new Date(new Date(this.$moment(this.startdate).format('YYYY'), 0, 1)).getDay()) / 7)
    //       if (new Date(this.$moment(this.startdate).format('YYYY') + '-01-01').getDay() > 0) {
    //         w = w - 1
    //       }
    //       Arr[y][w] = { start: this.$moment(this.startdate).format('YYYY-MM-DD'), end: '' }
    //       if (new Date(this.$moment(this.startdate).format('YYYY-MM-DD')).getDay() === 6) {
    //         Arr[y][w].end = this.$moment(this.startdate).format('YYYY-MM-DD')
    //       } else if (new Date(this.$moment(this.startdate).format('YYYY-MM-DD')).getDay() === 0) {
    //         w = w - 1
    //       }
    //     } else if (new Date(Number(YearStart) + y + '-01-01').getDay() !== 0) {
    //       n = 6 - new Date(Number(YearStart) + y + '-01-01').getDay()
    //       Arr[y][1] = { start: '', end: '' }
    //     }
    //     for (let d = (day + (86400000 * n)); d <= (new Date(Number(YearStart) + y + '-12-31').getTime()); d = d + 86400000) {
    //       if (y > 0 && new Date(d).getDay() === 6) {
    //         if (Arr[y][1].start === '') {
    //           Arr[y - 1][YearEndWeek].end = this.$moment(d).format('YYYY-MM-DD')
    //           Arr[y][0] = { start: Arr[y - 1][YearEndWeek].start, end: this.$moment(d).format('YYYY-MM-DD') }
    //           w = 0
    //           YearEndWeek = w
    //           NewYear = true
    //         }
    //       }
    //       if (new Date(d).getDay() === 0) {
    //         w++
    //         YearEndWeek = w
    //         NewYear = false
    //         Arr[y][w] = { start: this.$moment(d).format('YYYY-MM-DD'), end: '' }
    //       } else if (!NewYear && new Date(d).getDay() === 6) {
    //         Arr[y][w].end = this.$moment(d).format('YYYY-MM-DD')
    //       }
    //     }
    //   }
    //   this.summaryYearWeeksList = Arr
    // },
    // AllYearWeeksList () {
    //   const Arr = []
    //   const YearStart = this.$moment(this.startdate).format('YYYY')
    //   let YearEnd = this.$moment(this.summaryMonth).format('YYYY')
    //   let LastYear = 0
    //   if (new Date(this.$moment(this.summaryMonth).format('YYYY') + '-01-01').getDay() > 0) {
    //     LastYear = 1
    //   }
    //   const WeeksEnd = Math.ceil((((new Date(this.$moment(new Date()).format('YYYY-MM-DD')) - new Date(new Date(this.$moment(this.summaryMonth).format('YYYY'), 0, 1))) / 86400000) + new Date(new Date(this.$moment(this.summaryMonth).format('YYYY'), 0, 1)).getDay()) / 7) - LastYear
    //   if (WeeksEnd === 0) {
    //     YearEnd--
    //   }
    //   for (let y = 0; y <= (YearEnd - YearStart); y++) {
    //     for (let w = 1; w < this.summaryYearWeeksList[y].length; w++) {
    //       if (y === (YearEnd - YearStart) && w === WeeksEnd) {
    //         Arr.push({ text: (Number(YearStart) + y) + 'w' + w + '( ' + this.$moment(this.summaryYearWeeksList[y][w].start).format('MM-DD') + ' ～ ' + this.$moment(this.summaryYearWeeksList[y][w].end).format('MM-DD') + ' )', value: (Number(YearStart) + y) * 100 + w })
    //         break
    //       }
    //       if (this.summaryYearWeeksList[y][w]) {
    //         Arr.push({ text: (Number(YearStart) + y) + 'w' + w + '( ' + this.$moment(this.summaryYearWeeksList[y][w].start).format('MM-DD') + ' ～ ' + this.$moment(this.summaryYearWeeksList[y][w].end).format('MM-DD') + ' )', value: (Number(YearStart) + y) * 100 + w })
    //       }
    //     }
    //   }
    //   Arr.sort(function (a, b) { return b.value - a.value })
    //   this.summaryAllYearWeeksList = Arr
    //   this.loadingView = false
    // },
    getPartNumber (vendor) {
      const body = {
        vendorName: vendor
      }
      this.$http.get('/api/keypart/lcdModule/partNumber?' + qs.stringify(body)).then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.summaryPartNumberMenu = []
            this.summaryPartNumber = ''
            res.data.forEach((n) => {
              this.summaryPartNumberMenu.push({ text: n, value: n })
            })
          }
        })
    },
    getContentDateList (vendor, partNumber) {
      const body = {
        vendorName: vendor,
        partNumber: partNumber,
        requestType: 'rawdata'
      }
      this.$http.get('/api/keypart/lcdModule/manufacturingDate?' + qs.stringify(body)).then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.contentDateList = res.data
          }
        })
    },
    resetDeviceSnListItem () {
      this.deviceSnList = []
      this.deviceSnListSelect = []
    },
    addDeviceSnListItem () {
      if (this.deviceSnInput.length > 0) {
        if (this.deviceSnInput.split(',').length > 100) {
          if (confirm('因匯入筆數較多可能影響畫面顯示效能，是否直接進行匯出檔案？')) {
            this.expressDownload = true
          }
        }
        this.deviceSnList = this.deviceSnInput.split(',').concat(this.deviceSnList)
        this.deviceSnList = this.deviceSnList.filter((element, index, arr) => {
          return arr.indexOf(element) === index
        })
        this.deviceSnInput = ''
        this.selectAllDeviceSnListItem()
      } else {
        alert('請輸入序號')
      }
    },
    ImportDeviceSnListItem () {
      this.deviceSnList = this.responseExcelToListData.serial_no.concat(this.deviceSnList)
      this.deviceSnList = this.deviceSnList.filter((element, index, arr) => {
        return arr.indexOf(element) === index
      })
      this.selectAllDeviceSnListItem()
    },
    cancelSelectDeviceSnListItem () {
      this.deviceSnListSelect = []
    },
    selectAllDeviceSnListItem () {
      this.deviceSnListSelect = this.deviceSnList
      if (this.expressDownload) {
        this.doDownload()
      }
    },
    conversionExcelFieldsToObject () {
      if (this.file[0].file) {
        this.processingView = true
        const formData = new FormData()
        formData.append('file', this.file[0].file)
        formData.append('sourceFields', ['serial_no'])
        this.$http.post('/api/pqm/cfrAnalysisMgmt/parseExcelFieldsToObject', formData).then(res => res.data)
          .then(res => {
            this.processingView = false
            this.cancelFile()
            if (res.status === 'success') {
              this.responseExcelToObjectData = res.data
              setTimeout(() => {
                this.ImportDeviceSnListItem()
              }, 0)
            } else {
              this.snackbar = true
              this.snackbarColor = 'red'
              this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
            }
          })
      } else {
        this.processingView = false
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '檔名或檔案不得為空'
      }
    },
    conversionExcelFieldsToList () {
      if (this.file[0].file) {
        this.processingView = true
        const formData = new FormData()
        formData.append('file', this.file[0].file)
        formData.append('sourceFields', ['serial_no'])
        this.$http.post('/api/pqm/cfrAnalysisMgmt/parseExcelFieldsToList', formData).then(res => res.data)
          .then(res => {
            this.processingView = false
            this.cancelFile()
            if (res.status === 'success') {
              this.responseExcelToListData = res.data
              if (this.responseExcelToListData.serial_no.length > 100) {
                if (confirm('因匯入筆數較多可能影響畫面顯示效能，是否直接進行匯出檔案？')) {
                  this.expressDownload = true
                }
              }
              setTimeout(() => {
                this.ImportDeviceSnListItem()
              }, 0)
            } else {
              this.snackbar = true
              this.snackbarColor = 'red'
              this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
            }
          })
      } else {
        this.processingView = false
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '檔名或檔案不得為空'
      }
    },
    uploadFile (type) {
      $('#upload-file').click()
    },
    cancelFile () {
      this.file[0].file = this.file[0].name = this.file[0].url = ''
      $('#upload-file').val('')
    },
    setFile (e) {
      const files = e.target.files || e.dataTransfer.files
      this.file[0].url = window.URL.createObjectURL(e.srcElement.files.item(0))
      this.file[0].file = files[0]
      this.file[0].name = e.srcElement.files.item(0).name
      setTimeout(() => {
        this.conversionExcelFieldsToList()
      }, 0)
    },
    doDownload () {
      if (this.summaryWay === 'condition' && (this.summaryWeeksEnd < this.summaryWeeksStart)) {
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '結尾時間不得早於起始時間'
      } else if (this.summaryWay === 'condition' && (this.summaryWeeksEnd - this.summaryWeeksStart > 3)) {
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '日期區間不得超過4週(28天)'
      } else {
        // let wwS = 0
        // let wwE = 0
        // if (Number.isInteger(this.summaryWeeksStart)) {
        //   wwS = String(this.summaryWeeksStart).substr(4)
        // } else {
        //   wwS = Math.ceil((((new Date(this.$moment(new Date()).format('YYYY-MM-DD')) - new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1))) / 86400000) + new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1)).getDay()) / 7) - parseInt((new Date(this.$moment(new Date()).format('YYYY') + '-01-01').getDay() + 5) / 6)
        // }
        // if (Number.isInteger(this.summaryWeeksEnd)) {
        //   wwE = String(this.summaryWeeksEnd).substr(4)
        // } else {
        //   wwE = Math.ceil((((new Date(this.$moment(new Date()).format('YYYY-MM-DD')) - new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1))) / 86400000) + new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1)).getDay()) / 7) - parseInt((new Date(this.$moment(new Date()).format('YYYY') + '-01-01').getDay() + 5) / 6)
        // }
        // const manufactureStart = this.$moment(this.summaryYearWeeksList[String(this.summaryWeeksStart).substr(0, 4) - this.$moment(this.startdate).format('YYYY')][Number(wwS)].start).format('YYYY-MM-DD')
        // const manufactureEnd = this.$moment(this.summaryYearWeeksList[String(this.summaryWeeksEnd).substr(0, 4) - this.$moment(this.startdate).format('YYYY')][Number(wwE)].end).format('YYYY-MM-DD')

        this.processingView = true
        let body = {}
        if (this.summaryWay === 'sn') {
          body = {
            snList: this.deviceSnListSelect
          }
        } else if (this.summaryWay === 'condition') {
          body = {
            vendorName: this.summaryVendorName,
            partNumber: this.summaryPartNumber,
            // manufactureStart: manufactureStart,
            // manufactureEnd: manufactureEnd
            manufactureStart: this.summaryWeeksStart,
            manufactureEnd: this.summaryWeeksEnd
          }
        }
        this.$http.post('/api/keypart/lcdModule/lcdModuleManufacturingRawDataFile', body, { responseType: 'blob' })
          .then(res => {
            if (this.expressDownload) {
              this.resetDeviceSnListItem()
              this.expressDownload = false
            }
            this.processingView = false
            if (res.data.type === 'application/json') {
              return new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.onload = () => {
                  res.data = JSON.parse(reader.result)
                  if (res.data.status === 'success') {
                    alert(res.data.data)
                    // resolve(Promise.reject(res.data.data))
                  } else {
                    this.snackbar = true
                    this.snackbarColor = 'red'
                    this.snackbarMsg = res.data.errMsg + ' (' + res.data.errCode + ')'
                  }
                }
                reader.onerror = () => {
                  reject(res.data.data)
                }
                reader.readAsText(res.data)
              })
            } else if (res.data.status !== 'failure' && res.headers['content-disposition']) {
              const blob = new Blob([res.data], { type: 'application/octet-stream' })
              const url = window.URL.createObjectURL(blob)
              const fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]
              const downloadLink = document.createElement('a')
              downloadLink.href = url
              downloadLink.download = fileName

              document.body.appendChild(downloadLink)
              downloadLink.click()
              document.body.removeChild(downloadLink)
            } else {
              this.snackbar = true
              this.snackbarColor = 'red'
              this.snackbarMsg = '下載失敗，請通知系統管理員'
            }
          })
          .catch(err => {
            this.processingView = false
            this.snackbar = true
            this.snackbarColor = 'orange'
            this.snackbarMsg = err
          })
      }
    },
    doSampleDownload () {
      this.processingView = true
      this.$http.get('/api/keypart/lcdModule/lcdModuleSampleSnListFile', { responseType: 'blob' })
        .then(res => {
          this.processingView = false
          if (res.data.type === 'application/json') {
            return new Promise((resolve, reject) => {
              const reader = new FileReader()
              reader.onload = () => {
                res.data = JSON.parse(reader.result)
                if (res.data.status === 'success') {
                  alert(res.data.data)
                  // resolve(Promise.reject(res.data.data))
                } else {
                  this.snackbar = true
                  this.snackbarColor = 'red'
                  this.snackbarMsg = res.data.errMsg + ' (' + res.data.errCode + ')'
                }
              }
              reader.onerror = () => {
                reject(res.data.errMsg || res.data.data)
              }
              reader.readAsText(res.data)
            })
          } else if (res.data.status !== 'failure' && res.headers['content-disposition']) {
            const blob = new Blob([res.data], { type: 'application/octet-stream' })
            const url = window.URL.createObjectURL(blob)
            const fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]
            const downloadLink = document.createElement('a')
            downloadLink.href = url
            downloadLink.download = fileName

            document.body.appendChild(downloadLink)
            downloadLink.click()
            document.body.removeChild(downloadLink)
          } else {
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = '無檔案可供下載'
          }
        })
        .catch(err => {
          this.processingView = false
          this.snackbar = true
          this.snackbarColor = 'orange'
          this.snackbarMsg = err
        })
    }
  }
}
</script>
<style>
</style>
