<template>
  <v-container
    cfr
    fluid
    grid-list-xl
    pt-0
  >
    <v-layout wrap>
      <v-flex
        xs12
        lg12
      >
        <material-card
          title="料件不良率統計(CFR)"
          class="card-padding v-card__first"
          color="general"
        >
          <v-form>
            <v-container
              py-0
              class="mw-100">
              <v-layout wrap>
                <v-flex
                  flex-small
                  xs12
                  lg2>
                  <v-autocomplete
                    v-model="pnPrefixCandidate"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="pnPrefixCandidateMenu"
                    item-value="text"
                    item-text="text"
                    label="料件類別"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex
                  flex-small
                  xs12
                  lg2>
                  <v-text-field
                    v-show="showPnInput"
                    v-model="pn"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    label="料件P/N"/>
                </v-flex>
                <v-flex lg1/>
                <v-flex
                  flex-small
                  xs12
                  lg1>
                  <v-autocomplete
                    v-model="modelYear"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="modelYearMenu"
                    label="機種開賣年度"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex
                  flex-small
                  xs12
                  lg3
                  style="justify-content: space-between;display: flex;">
                  <v-autocomplete
                    v-model="modelGroup"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="modelGroupMenu"
                    label="modelGroup"
                    class="v-text-field__slot list-select-field"
                    persistent-hint
                    outlined
                    multiple
                  />
                </v-flex>
                <v-flex
                  flex-small
                  xs12
                  lg3
                  style="justify-content: space-between;display: flex;">
                  <v-autocomplete
                    v-model="model"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="modelMenu"
                    label="CFR_MODEL"
                    class="v-text-field__slot list-select-field"
                    persistent-hint
                    outlined
                    multiple
                  />
                </v-flex>
                <v-flex
                  xs8
                  lg8
                />
                <v-flex
                  xs4
                  lg4
                  flex-small
                  d-flex
                  align-items-center
                  text-xs-right
                >
                  <v-checkbox
                    v-model="excludeBestBuy"
                    :value="true"
                    input-value="1"
                    class="m-0 py-0"
                    label="Exclude Best Buy"/>
                  <v-btn
                    class="mx-0 mr-3 font-weight-light"
                    color="general"
                    @click="doSubmit()"
                  >
                    <v-icon class="mr-2">mdi-backup-restore</v-icon>統計
                  </v-btn>
                  <v-btn
                    class="mx-0 mr-3 font-weight-light"
                    color="indigo"
                    @click="doDownloadRawData()"
                  >
                    <v-icon class="mr-2">mdi-export</v-icon>匯出Raw Data
                  </v-btn>
                  <v-btn
                    class="mx-0 font-weight-light"
                    color="deep-purple"
                    @click="doDownloadWeeklyReport()"
                  >
                    <v-icon class="mr-2">mdi-download</v-icon>下載當週報表
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
      </v-flex>
      <v-flex
        v-if="submit"
        flex_table_width_auto
        xs12
        style="padding: 0px 12px!important;">
        <material-card
          title="不良率(CFR)統計結果列表"
          color="deep-orange"
          class="card-mix table-overflow d-table-t0 no-data-left"
          style="margin-top: 30px!important;">
          <table class="statistic-table analyzer-table">
            <thead>
              <tr>
                <th class="text-center table-width-75">item</th>
                <th class="text-center table-width-110">{{ modelTableStyle }}</th>
                <th
                  v-if="modelTableStyle==='P/N'"
                  class="text-center table-width-75">Vendor</th>
                <th
                  v-if="modelTableStyle==='P/N'"
                  class="text-center table-width-450">Spec</th>
                <th
                  v-for="(title,t) in responseTableData.columnName"
                  :key="'t'+t"
                  class="text-center table-width-125">{{ title }}</th>
                <th class="text-center">合計</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(content,c) in responseTableData.content"
                :key="'c'+c">
                <td class="text-center table-width-75">{{ content.item }}</td>
                <td
                  :class="{'text-body yellow':isHighlightCell(content.statistics)}"
                  class="text-center table-width-110">{{ content.pn }}</td>
                <td
                  v-if="modelTableStyle==='P/N'"
                  :class="{'text-body yellow':isHighlightCell(content.statistics)}"
                  class="text-center table-width-75">{{ content.vendor }}</td>
                <td
                  v-if="modelTableStyle==='P/N'"
                  :class="{'text-body yellow':isHighlightCell(content.statistics)}"
                  class="table-width-450">{{ content.spec }}</td>
                <td
                  v-for="(data,d) in content.statistics"
                  :key="'d'+d"
                  class="text-center table-width-125">
                  <v-chip
                    v-if="data.length>1"
                    :title="data[3]+' / '+data[4]+' (累積)'"
                    :class="{'text-white-50':data[2]===0}"
                    class="text-white">{{ (data[2] * 100).toFixed(2) }}
                    <span class="percentage">%</span>
                  </v-chip>
                  <div>
                    <v-icon
                      v-if="data[2]>=cfrBaseValue.cfr"
                      style="color:yellow;margin-left:-26px;margin-top:-6px;margin-right:2px;">mdi-alert-decagram</v-icon>
                    <span
                      v-if="data.length>1"
                      :class="{'text-white-50':data[0]===0}">{{ data[0] }}</span>
                    <span
                      v-if="data.length>1"
                      class="mx-1">/</span>
                    <span
                      v-if="data.length>1"
                      :class="{'text-white-50':data[1]===0}">{{ data[1] }}</span>
                      <!-- <span
                      v-if="data.length===1"
                      class="text-danger">{{ d===1?((data[0] * 100).toFixed(2)+'%'):data[0] }}</span> -->
                  </div>
                </td>
                <td class="text-center">
                  <v-chip
                    :title="getTableDataTotal(content.pn)[0]+' / '+getTableDataTotal(content.pn)[1]+' (累積)'"
                    :class="{'text-white-50':getTableDataTotal(content.pn)[2]===0}"
                    class="text-white">{{ (getTableDataTotal(content.pn)[2] * 100).toFixed(2) }}
                    <span class="percentage">%</span>
                  </v-chip>
                  <div>
                    <span :class="{'text-white-50':getTableDataTotal(content.pn)[0]===0}">{{ getTableDataTotal(content.pn)[0] }}</span>
                    <span class="mx-1">/</span>
                    <span :class="{'text-white-50':getTableDataTotal(content.pn)[1]===0}">{{ getTableDataTotal(content.pn)[1] }}</span>
                  </div>
                </td>
              </tr>
              <tr class="problem-total">
                <td
                  :colspan="modelTableStyle!=='P/N'?2:4"
                  class="text-center">平均</td>
                <td
                  v-for="(col,c) in responseTableData.columnName"
                  :key="'c'+c"
                  class="text-center">
                  <v-chip
                    :title="getTableDataAvg(c)[3]+' / '+getTableDataAvg(c)[4]+' (累積)'"
                    :class="{'text-white-50':getTableDataAvg(c)[2]===0}"
                    class="text-white">{{ (getTableDataAvg(c)[2] * 100).toFixed(2) }}
                    <span class="percentage">%</span>
                  </v-chip>
                  <div>
                    <span :class="{'text-white-50':getTableDataAvg(c)[0]===0}">{{ getTableDataAvg(c)[0] }}</span>
                    <span class="mx-1">/</span>
                    <span :class="{'text-white-50':getTableDataAvg(c)[1]===0}">{{ getTableDataAvg(c)[1] }}</span>
                  </div>
                </td>
                <td/>
              </tr>
              <tr>
                <td
                  :colspan="modelTableStyle!=='P/N'?2:4"
                  class="text-center">Target</td>
                <td
                  v-for="(col,c) in responseTableData.columnName"
                  :key="'c'+c"
                  class="text-center">
                  <v-chip class="text-white">{{ (cfrBaseValue.cfr * 100).toFixed(2) }}
                    <span class="percentage">%</span>
                  </v-chip>
                </td>
                <td/>
              </tr>
            </tbody>
          </table>
          <ul class="m-0 ml-3">
            <li
              v-if="!showLcdCfrTargetLineWording"
              class="text-danger font-weight-bold">CFR超過目標線會顯示黃框, 連續"1"個月CFR黃框顯示該料件品名spec會轉換黃框</li>
            <li
              v-if="showLcdCfrTargetLineWording"
              class="text-danger font-weight-bold">CFR超過目標線會顯示黃框, 連續"3"個月CFR黃框顯示該料件品名spec會轉換黃框</li>
            <li class="text-danger font-weight-bold">目標監控標線為 "{{ cfrBaseValue.cfr * 1000000 }}" DPPM</li>
            <li class="text-danger font-weight-bold">
              <div class="d-inline float-left">欄位數值說明：</div>
              <div class="border-danger text-center d-inline float-left px-3">
                <span class="pl-2 font-weight-bold">CFR(<span class="text-white">累積</span>不良率)</span>
                <hr class="border-danger m-0">
                <span class="font-weight-bold"><span class="text-white">當月</span>不良數 / <span class="text-white">當月</span>激活數</span>
              </div>
            </li>
          </ul>
        </material-card>
      </v-flex>
      <v-flex
        v-show="submit"
        md12
        xs12
        style="padding: 0px 12px!important;">
        <material-card
          :title="pnPrefixType"
          color="brown"
          class="card-mix d-table-t0 no-data-left"
          style="margin-top: 30px!important;">
          <vue-c3 :handler="chartArea"/>
        </material-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="processingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        PROCESSING 處理中
      </p>
    </v-dialog>
    <v-dialog
      v-model="loadingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :top="true"
      :color="snackbarColor">
      {{ snackbarMsg }}
      <v-btn
        dark
        flat
        @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
// import $ from 'jquery'
import Vue from 'vue'
import VueC3 from 'vue-c3'
import 'c3/c3.min.css'
import { mapState } from 'vuex'
import Loading from '@/assets/img/loading.gif'
export default {
  name: 'RepairPartsCFRStatistic',
  components: {
    VueC3
  },
  data () {
    return {
      submit: false,
      snackbar: false,
      snackbarColor: 'red',
      snackbarMsg: '',
      Loading,
      processingView: false,
      cfrBaseValueLoading: false,
      activeQtyStatisticLoading: false,
      qtyStatisticLoading: false,
      loadingOfModelGroup: false,
      loadingOfModel: false,
      pn: '',
      pnPrefixCandidate: '',
      pnPrefixCandidateMenu: [],
      pnPrefixType: '',
      statisticType: 0,
      modelYear: '',
      modelYearMenu: [],
      modelGroup: [],
      modelGroupMenu: [],
      model: [],
      modelMenu: [],
      excludeBestBuy: false,
      chartArea: new Vue(),
      cfrBaseLineList: [],
      cfrBaseValue: { cfr: 0.001 },
      responseMachineActiveQtyStatisticList: [],
      responseQtyStatisticList: [],
      modelTableStyle: 'P/N',
      yearWordLength: 4,
      error: false
    }
  },
  computed: {
    ...mapState('app', ['theme', 'shrink']),
    yearMonthStart () {
      let timestamp = Number(this.concatResponseTableData[0].statistics[0].yearMonth.split('/')[0] * 12) + Number(this.concatResponseTableData[0].statistics[0].yearMonth.split('/')[1]) - 1
      this.concatResponseTableData.forEach(n => {
        n.statistics.forEach(m => {
          if (Number(m.yearMonth.split('/')[0] * 12) + Number(m.yearMonth.split('/')[1]) <= (timestamp + 1)) {
            timestamp = Number(m.yearMonth.split('/')[0] * 12) + Number(m.yearMonth.split('/')[1]) - 1
          }
        })
      })
      return timestamp
    },
    yearMonthEnd () {
      let timestamp = Number(this.concatResponseTableData[0].statistics[0].yearMonth.split('/')[0] * 12) + Number(this.concatResponseTableData[0].statistics[0].yearMonth.split('/')[1]) - 1
      this.concatResponseTableData.forEach(n => {
        n.statistics.forEach(m => {
          if (Number(m.yearMonth.split('/')[0] * 12) + Number(m.yearMonth.split('/')[1]) >= (timestamp + 1)) {
            timestamp = Number(m.yearMonth.split('/')[0] * 12) + Number(m.yearMonth.split('/')[1]) - 1
          }
        })
      })
      return timestamp
    },
    concatResponseTableData () {
      const data = []
      this.responseMachineActiveQtyStatisticList.forEach((n, nn) => {
        data.push({
          item: n.item,
          pn: n.pn || n.modelGroup || n.model,
          vendor: n.vendor || '',
          spec: n.spec || '',
          statistics: []
        })
        n.statistics.forEach(m => {
          if (m.yearMonth.split('/')[0].length === this.yearWordLength) {
            data[nn].statistics.push({
              yearMonth: m.yearMonth,
              yearMonthCode: Number(m.yearMonth.split('/')[0] * 12) + Number(m.yearMonth.split('/')[1]),
              activeQty: m.qty,
              defectiveQty: 0
            })
          } else {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.submit = false
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.snackbar = true
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.snackbarColor = 'red'
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.snackbarMsg = '資料欄位異常錯誤，請洽系統管理人員'
          }
        })
      })
      this.responseQtyStatisticList.forEach(n => {
        n.statistics.forEach((m, mm) => {
          if (data.map(e => { return e.pn }).indexOf(n.pn || n.modelGroup || n.model) === -1 && data.length > 0) {
            data.push({
              item: n.item,
              pn: n.pn || n.modelGroup || n.model,
              vendor: n.vendor || '',
              spec: n.spec || '',
              statistics: []
            })
            // this.submit = false
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.snackbar = true
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.snackbarColor = 'orange'
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.snackbarMsg = '資料筆數異常，請檢查來源資料是否正確'
            n.statistics.forEach(m => {
              data[data.length - 1].statistics.push({
                yearMonth: m.yearMonth,
                yearMonthCode: Number(m.yearMonth.split('/')[0] * 12) + Number(m.yearMonth.split('/')[1]),
                activeQty: 0,
                defectiveQty: m.qty
              })
            })
          }
          if (data[data.map(e => { return e.pn }).indexOf(n.pn || n.modelGroup || n.model)].statistics.map(e => { return e.yearMonth }).indexOf(m.yearMonth) === -1 && m.yearMonth.split('/')[0].length !== this.yearWordLength) {
            // if (data[data.map(e => { return e.pn }).indexOf(n.pn)].statistics.map(e => { return e.yearMonth }).indexOf('error') === -1) {
            //   data[data.map(e => { return e.pn }).indexOf(n.pn)].statistics.push({
            //     yearMonth: 'error',
            //     yearMonthCode: 99999,
            //     activeQty: 0,
            //     defectiveQty: 0
            //   })
            // }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.submit = false
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.snackbar = true
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.snackbarColor = 'red'
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.snackbarMsg = '資料欄位異常錯誤，請洽系統管理人員'
          } else if (data[data.map(e => { return e.pn }).indexOf(n.pn || n.modelGroup || n.model)].statistics.map(e => { return e.yearMonth }).indexOf(m.yearMonth) === -1 && m.yearMonth.split('/')[0].length === this.yearWordLength) {
            data[data.map(e => { return e.pn }).indexOf(n.pn || n.modelGroup || n.model)].statistics.push({
              yearMonth: m.yearMonth,
              yearMonthCode: Number(m.yearMonth.split('/')[0] * 12) + Number(m.yearMonth.split('/')[1]),
              activeQty: 0,
              defectiveQty: m.qty
            })
          } else {
            data[data.map(e => { return e.pn }).indexOf(n.pn || n.modelGroup || n.model)].statistics[data[data.map(e => { return e.pn }).indexOf(n.pn || n.modelGroup || n.model)].statistics.map(e => { return e.yearMonth }).indexOf(m.yearMonth)].defectiveQty = m.qty
          }
        })
      })
      return data
    },
    responseTableData () {
      const data = {
        columnName: [],
        content: []
      }
      if (!this.loadingView) {
        // const monthColNum = Math.max(...this.responseMachineActiveQtyStatisticList.map(e => { return e.statistics.length }))
        for (let i = this.yearMonthStart; i <= this.yearMonthEnd; i++) {
          // if (i % 1200 === 0) {
          //   data.columnName.push((parseInt(i / 1200) - 1) + '/' + 12)
          // } else {
          //   data.columnName.push(parseInt(i / 1200) + '/' + (i % 1200))
          // }
          data.columnName.push(parseInt(i / 12) + '/' + ((i % 12) + 1))
        }
        // this.responseMachineActiveQtyStatisticList.forEach(a => {
        this.concatResponseTableData.forEach(c => {
          const num = []
          let TotalDefective = 0
          let TotalActive = 0
          const s = c.statistics.sort((a, b) => parseFloat(a.yearMonthCode) - parseFloat(b.yearMonthCode))
          // for (let i = 0; i < monthColNum - a.statistics.length; i++) {
          for (let i = 0; i < (s[0].yearMonthCode - this.yearMonthStart - 1); i++) {
            num.push([])
          }
          // a.statistics.sort((a, b) => parseFloat(a.yearMonth) - parseFloat(b.yearMonth)).forEach(n => {
          const sortData = c.statistics.sort((a, b) => parseFloat(a.yearMonthCode) - parseFloat(b.yearMonthCode))
          sortData.forEach((n, nn) => {
            // if (data.columnName.length < monthColNum && a.statistics.length === monthColNum) {
            //   data.columnName.push(n.yearMonth)
            // }
            if (nn > 0 && (sortData[nn].yearMonthCode - sortData[nn - 1].yearMonthCode) > 1) {
              for (let i = 1; i < (sortData[nn].yearMonthCode - sortData[nn - 1].yearMonthCode); i++) {
                num.push([0, 0, (TotalDefective / TotalActive), TotalDefective, TotalActive, sortData[nn - 1].yearMonthCode + i])
              }
            }
            TotalDefective += n.defectiveQty
            TotalActive += n.activeQty
            num.push([n.defectiveQty, n.activeQty, (TotalDefective / TotalActive), TotalDefective, TotalActive, n.yearMonthCode])
          })
          const e = c.statistics.sort((a, b) => parseFloat(b.yearMonthCode) - parseFloat(a.yearMonthCode))
          for (let i = 0; i < (this.yearMonthEnd - e[0].yearMonthCode + 1); i++) {
            num.push([])
          }
          data.content.push({
            item: c.item,
            pn: c.pn || c.model,
            vendor: c.vendor || '',
            spec: c.spec || '',
            statistics: num
          })
        })
        // const i = data.content.map(e => { return e.pn })
        // this.responseQtyStatisticList.forEach((q) => {
        //   const qq = i.indexOf(q.pn)
        //   let TotalQty = 0
        //   let TotalActive = 0
        //   if (qq !== -1 && q.pn === data.content[qq].pn) {
        //     // const space = monthColNum - q.statistics.length
        //     const space = (((this.$moment(new Date(q.statistics.sort((a, b) => parseFloat(a.yearMonth) - parseFloat(b.yearMonth))[0].yearMonth)).format('YYYY') * 12) + this.$moment(new Date(q.statistics.sort((a, b) => parseFloat(a.yearMonth) - parseFloat(b.yearMonth))[0].yearMonth)).format('mm')) - this.yearMonthStart)
        //     q.statistics.sort((a, b) => a.yearMonth.localeCompare(b.yearMonth)).forEach((n, nn) => {
        //       data.content[qq].statistics[space + nn][0] = n.qty
        //       TotalQty += n.qty
        //       TotalActive += data.content[qq].statistics[space + nn][1]
        //       data.content[qq].statistics[space + nn][2] = (TotalQty / TotalActive)
        //       data.content[qq].statistics[space + nn][3] = TotalQty
        //       data.content[qq].statistics[space + nn][4] = TotalActive
        //       // data.content[qq].statistics[space + nn].push(data.content[qq].statistics[space + nn][0] / n.qty)
        //     })
        //   }
        // })
      }
      return data
    },
    chartXaxisName () {
      const data = ['x'].concat(this.responseTableData.columnName)
      return [data]
    },
    chartXaxisValue () {
      const data = []
      this.responseTableData.content.forEach((c) => {
        const val = [c.pn]
        c.statistics.forEach((s) => {
          val.push(s.length > 0 ? s[2] : null)
        })
        data.push(val)
      })
      return data
    },
    chartXaxisNormalValue () {
      const data = []
      // 平均線
      const avg = ['平均']
      for (let i = 0; i < this.responseTableData.columnName.length; i++) {
        avg.push(this.getTableDataAvg(i)[2])
      }
      data.push(avg)
      // 目標線
      const target = ['Target']
      for (let i = 0; i < this.responseTableData.columnName.length; i++) {
        target.push(this.cfrBaseValue.cfr)
        console.log('XXX ->' + this.cfrBaseValue.cfr)
      }
      data.push(target)
      return data
    },
    options_line () {
      return {
        data: {
          x: 'x',
          columns: this.chartXaxisName.concat(this.chartXaxisValue).concat(this.chartXaxisNormalValue),
          labels: {
            format: (v, id, i, j) => {
              if (id === '平均' || (i === (this.chartXaxisName[0].length - 2) && v >= this.cfrBaseValue.cfr)) {
                return (v * 100).toFixed(2) + '%'
              } else {
                return ''
              }
            },
            color: '#999999'
          },
          type: 'line',
          colors: {
            平均: '#98df8a',
            Target: '#d62728'
          }
        },
        color: {
          pattern: ['#1f77b4', '#aec7e8', '#ff7f0e', '#ffbb78', '#2ca02c', '#ff9896', '#9467bd', '#c5b0d5', '#8c564b', '#c49c94', '#e377c2', '#f7b6d2', '#7f7f7f', '#c7c7c7', '#bcbd22', '#dbdb8d', '#17becf', '#9edae5']
        },
        axis: {
          x: {
            type: 'category', // this needed to load string x value
            tick: {
              rotate: -45,
              multiline: false
            },
            label: {
              text: '年/月',
              position: 'outer-right'
            },
            padding: {
              top: 0,
              right: 0.25,
              bottom: 0,
              left: 0.25
            }
          },
          y: {
            tick: {
              format: (value) => { return (value * 100).toFixed(2) + '%' }
            },
            label: {
              text: '不良率',
              position: 'outer-bottom'
            }
          }
        },
        grid: {
          x: {
            show: true
          },
          y: {
            show: true
          }
        },
        legend: {
          position: 'right',
          padding: 0,
          show: true
        },
        size: {
          width: ((window.innerWidth - this.shrink) * 1) - 64 - (this.chartType === 1 ? 410 : 0),
          height: 600
        }
      }
    },
    loadingView () {
      if (!this.cfrBaseValueLoading && !this.activeQtyStatisticLoading && !this.qtyStatisticLoading) {
        return false
      } else {
        return true
      }
    },
    showPnInput () {
      // 根據選中的選項決定是否顯示 Pn 輸入框
      const pnPrefixDto = this.pnPrefixCandidateMenu.find(option => option.text === this.pnPrefixCandidate)
      if (pnPrefixDto) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.statisticType = pnPrefixDto.statisticType
      }
      return pnPrefixDto && pnPrefixDto.statisticType === 0
    },
    showLcdCfrTargetLineWording () {
      // 根據選中的選項決定是否顯示 Target 線說明
      const baseLineDto = this.cfrBaseLineList.find(baseLine => baseLine.name === this.pnPrefixCandidate)
      this.setBaseLineDto()
      return baseLineDto && this.pnPrefixCandidate === 'LCD PANEL'
    }
  },
  watch: {
    pnPrefixCandidate (val) {
      this.pn = this.pnPrefixCandidateMenu[this.pnPrefixCandidateMenu.map(function (e) { return e.text }).indexOf(val)].value
    },
    // pn (val) {
    //   if (val.length >= 2) {
    //     this.pnPrefixCandidate = val.substr(0, 2)
    //   }
    // },
    modelYear (val) {
      this.modelGroup = []
      this.getModelGroup(val)
      this.getModel(val, this.modelGroup)
    },
    modelGroup (val) {
      this.model = []
      this.getModel(this.modelYear, val)
    },
    loadingView (val) {
      if (!val && !this.error) {
        this.submit = true
        this.chartArea.$emit('init', this.options_line)
      } else {
        this.submit = false
      }
    }
  },
  mounted () {
    this.checkPermission()
    this.getPartsNoPrefix()
    this.getCfrBaseLineList()
  },
  methods: {
    checkPermission () {
      this.$store.dispatch('getAuthorize', '/pqm/cfrAnalysisMgmt/repairPartsCFRStatisticView')
        .then(response => {
          if (response.status === 'success' && response.data.accessible) {
            this.$http.get('/api/pqm/cfrAnalysisMgmt/modelYear').then(res => res.data)
              .then(res => {
                if (res.status === 'success') {
                  this.modelYearMenu = res.data.sort((a, b) => b.value.localeCompare(a.value))
                }
              })
            this.modelYear = this.$moment(new Date()).format('YYYY')
            // this.doSubmit()
          } else if (response.errCode === 'E10000002') {
            const routeData = this.$router.resolve({ path: '/noEnter/E10000002' })
            localStorage.setItem('errormsg', response.errMsg)
            window.location.assign(routeData.href)
          } else {
            const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
            window.location.assign(routeData.href)
          }
        })
        .catch(err => {
          console.log(err)
          const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
          window.location.assign(routeData.href)
        })
    },
    getModelGroup (year) {
      if (year !== '' && !this.loadingOfModelGroup) {
        this.loadingOfModelGroup = true
        this.$http.get(`/api/pqm/cfr/modelGroup?year=${year}`).then(res => res.data)
          .then(res => {
            this.loadingOfModelGroup = false
            if (res.status === 'success') {
              this.modelGroupMenu = res.data
              this.modelGroup = []
            }
          })
      }
    },
    getModel (year, modelGroup) {
      if (year !== '' && !this.loadingOfModel) {
        this.loadingOfModel = true
        this.$http.get(`/api/pqm/cfr/model?year=${year}&modelGroupList=${modelGroup}`).then(res => res.data)
          .then(res => {
            this.loadingOfModel = false
            if (res.status === 'success') {
              this.modelMenu = res.data
              this.model = []
            }
          })
      }
    },
    getPartsNoPrefix () {
      this.$http.get('/api/pqm/cfr/cfrMaterialPartNoPrefixList').then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.pnPrefixCandidateMenu = res.data
          }
        })
    },
    getCfrBaseLineList () {
      const body = {
        type: 2
      }
      this.$http.post('/api/pqm/cscDataParsingMgmt/cfrBaselineSettingList', body).then(res => res.data)
        .then(res => {
          this.MaterialNRLoading = false
          if (res.status === 'success') {
            this.cfrBaseLineList = res.data
          }
        })
    },
    inputPnPrefix (value, index) {
      this.pn = value
    },
    isHighlightCell (value) {
      let isHighlight = false
      if (this.pnPrefixType === 'LCD') {
        value.forEach((v) => {
          if (v[2] >= this.cfrBaseValue.cfr) {
            isHighlight = true
          }
        })
      } else {
        value.forEach((v, vv) => {
          if ((value[vv - 1] ? value[vv - 1][2] : -1) >= this.cfrBaseValue.cfr && v[2] >= this.cfrBaseValue.cfr && (value[vv + 1] ? value[vv + 1][2] : -1) >= this.cfrBaseValue.cfr) {
            isHighlight = true
          }
        })
      }
      return isHighlight
    },
    getTableDataTotal (pn) {
      let qty = 0
      let active = 0
      this.responseTableData.content[this.responseTableData.content.map(e => { return e.pn }).indexOf(pn)].statistics.forEach((n) => {
        if (n.length > 0) {
          qty += n[0]
          active += n[1]
        }
      })
      return [qty, active, qty / active]
    },
    getTableDataAvg (col) {
      let qty = 0
      let active = 0
      let totalQty = 0
      let totalActive = 0
      // for (let i = 0; i <= col; i++) {
      for (let i = (this.yearMonthStart + 1); i <= Number(this.responseTableData.columnName[col].split('/')[0] * 12) + Number(this.responseTableData.columnName[col].split('/')[1]); i++) {
        this.responseTableData.content.map(e => { return e.statistics[i - this.yearMonthStart - 1] }).forEach(n => {
          if (n && n.length > 0) {
            if ((i - this.yearMonthStart - 1) === col) {
              qty += n[0]
              active += n[1]
            }
            totalQty += n[0]
            totalActive += n[1]
          }
        })
      }
      return [qty, active, totalQty / totalActive, totalQty, totalActive]
    },
    doSubmit () {
      if (this.pnPrefixCandidateMenu.map(e => { return e.text }).indexOf(this.pnPrefixCandidate) === -1) {
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '請選擇料件類別'
      } else if (this.modelYear === '') {
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '請選擇開賣年度'
      // } else if (this.model.length === 0) {
      //   this.snackbar = true
      //   this.snackbarColor = 'red'
      //   this.snackbarMsg = '請選擇CFR_MODEL'
      //   return false
      } else if (this.pn.length < 2) {
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '料件P/N不得為空或少於2字元'
      } else {
        this.chartArea.$emit('destroy')
        this.doSubmitForRepairPartsMachineActiveQtyStatisticList()
        this.doSubmitForRepairPartsQtyStatisticList()
        this.setBaseLineDto()
      }
    },
    doSubmitForRepairPartsMachineActiveQtyStatisticList () {
      this.activeQtyStatisticLoading = true
      this.$http.get(`/api/pqm/cfr/repairPartsMachineActiveQtyStatisticList?pn=${this.pn}&partName=${this.pnPrefixCandidate}&model=${this.model}&modelGroup=${this.modelGroup}&modelYear=${this.modelYear}&statisticType=${this.statisticType}&excludeBestBuy=${this.excludeBestBuy || false}`).then(res => res.data)
        .then(res => {
          this.activeQtyStatisticLoading = false
          if (res.status === 'success') {
            if (res.data[0].modelGroup) {
              this.responseMachineActiveQtyStatisticList = res.data.sort((a, b) => a.modelGroup.localeCompare(b.modelGroup))
              this.modelTableStyle = 'Model Group'
            } else if (res.data[0].model) {
              this.responseMachineActiveQtyStatisticList = res.data.sort((a, b) => a.model.localeCompare(b.model))
              this.modelTableStyle = 'Model'
            } else {
              this.responseMachineActiveQtyStatisticList = res.data.sort((a, b) => a.pn.localeCompare(b.pn))
              this.modelTableStyle = 'P/N'
            }
          } else {
            this.error = true
            this.responseMachineActiveQtyStatisticList = []
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
          }
        })
        .catch(err => {
          // this.error = true
          this.activeQtyStatisticLoading = false
          this.responseMachineActiveQtyStatisticList = []
          this.snackbar = true
          this.snackbarColor = 'red'
          this.snackbarMsg = '系統錯誤，請洽系統管理人員\n錯誤訊息：' + err
        })
    },
    doSubmitForRepairPartsQtyStatisticList () {
      this.qtyStatisticLoading = true
      this.$http.get(`/api/pqm/cfr/repairPartsQtyStatisticList?pn=${this.pn}&partName=${this.pnPrefixCandidate}&model=${this.model}&modelGroup=${this.modelGroup}&modelYear=${this.modelYear}&statisticType=${this.statisticType}&excludeBestBuy=${this.excludeBestBuy || false}`).then(res => res.data)
        .then(res => {
          this.qtyStatisticLoading = false
          if (res.status === 'success') {
            if (res.data.length !== 0 && res.data[0].modelGroup) {
              this.responseQtyStatisticList = res.data.sort((a, b) => a.modelGroup.localeCompare(b.modelGroup))
            } else if (res.data.length !== 0 && res.data[0].model) {
              this.responseQtyStatisticList = res.data.sort((a, b) => a.model.localeCompare(b.model))
            } else {
              this.responseQtyStatisticList = res.data.sort((a, b) => a.pn.localeCompare(b.pn))
            }
          } else {
            this.error = true
            this.responseQtyStatisticList = []
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
          }
        })
        .catch(err => {
          // this.error = true
          this.qtyStatisticLoading = false
          this.responseQtyStatisticList = []
          this.snackbar = true
          this.snackbarColor = 'red'
          this.snackbarMsg = '系統錯誤，請洽系統管理人員\n錯誤訊息：' + err
        })
    },
    doDownloadRawData () {
      if (this.pnPrefixCandidateMenu.map(e => { return e.text }).indexOf(this.pnPrefixCandidate) === -1) {
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '請選擇料件類別'
      } else if (this.modelYear === '') {
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '請選擇開賣年度'
      // } else if (this.model.length === 0) {
      //   this.snackbar = true
      //   this.snackbarColor = 'red'
      //   this.snackbarMsg = '請選擇CFR_MODEL'
      //   return false
      } else if (this.pn.length < 2) {
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '料件P/N不得為空或少於2字元'
      } else {
        this.processingView = true
        const body = {
          pn: this.pn,
          partName: this.pnPrefixCandidate,
          modelYear: this.modelYear,
          modelGroup: this.modelGroup,
          modelList: this.model,
          excludeBestBuy: this.excludeBestBuy || false
        }
        this.$http.post('/api/pqm/cfr/repairPartsCFRRawDataStatisticListFile', body, { responseType: 'blob' })
          .then(res => {
            this.processingView = false
            if (res.data.type === 'application/json') {
              return new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.onload = () => {
                  res.data = JSON.parse(reader.result)
                  if (res.data.status === 'success') {
                    alert(res.data.data)
                    // resolve(Promise.reject(res.data.data))
                  } else {
                    this.snackbar = true
                    this.snackbarColor = 'orange'
                    this.snackbarMsg = res.data.errMsg + ' (' + res.data.errCode + ')'
                  }
                }
                reader.onerror = () => {
                  reject(res.data.data)
                }
                reader.readAsText(res.data)
              })
            } else if (res.data.status !== 'failure' && res.headers['content-disposition']) {
              const blob = new Blob([res.data], { type: 'application/octet-stream' })
              const url = window.URL.createObjectURL(blob)
              const fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]
              const downloadLink = document.createElement('a')
              downloadLink.href = url
              downloadLink.download = fileName

              document.body.appendChild(downloadLink)
              downloadLink.click()
              document.body.removeChild(downloadLink)
            } else {
              this.snackbar = true
              this.snackbarColor = 'red'
              this.snackbarMsg = '下載失敗，請通知系統管理員'
            }
          })
          .catch(err => {
            this.processingView = false
            this.snackbar = true
            this.snackbarColor = 'orange'
            this.snackbarMsg = err
          })
      }
    },
    setBaseLineDto () {
      const baseLineDto = this.cfrBaseLineList.find(baseLine => baseLine.name === this.pnPrefixCandidate)
      if (baseLineDto) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.cfrBaseValue = baseLineDto
      }
    },
    doDownloadWeeklyReport () {
      this.processingView = true
      // const body = {
      //   reportDate: this.$moment(new Date()).format('YYYYMMDD')
      // }
      // this.$http.post('/api/pqm/cfr/repairPartsCFRWeeklyReportFile', body, { responseType: 'blob' })
      const body = {}
      this.$http.post('/api/pqm/cfr/repairPartsCFRWeeklyReportFile', body, { responseType: 'blob' })
        .then(res => {
          this.processingView = false
          if (res.data.type === 'application/json') {
            return new Promise((resolve, reject) => {
              const reader = new FileReader()
              reader.onload = () => {
                res.data = JSON.parse(reader.result)
                if (res.data.status === 'success') {
                  alert(res.data.data)
                  // resolve(Promise.reject(res.data.data))
                } else {
                  this.snackbar = true
                  this.snackbarColor = 'orange'
                  this.snackbarMsg = res.data.errMsg + ' (' + res.data.errCode + ')'
                }
              }
              reader.onerror = () => {
                reject(res.data.data)
              }
              reader.readAsText(res.data)
            })
          } else if (res.data.status !== 'failure' && res.headers['content-disposition']) {
            const blob = new Blob([res.data], { type: 'application/octet-stream' })
            const url = window.URL.createObjectURL(blob)
            const fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]
            const downloadLink = document.createElement('a')
            downloadLink.href = url
            downloadLink.download = fileName

            document.body.appendChild(downloadLink)
            downloadLink.click()
            document.body.removeChild(downloadLink)
          } else {
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = '下載失敗，請通知系統管理員'
          }
        })
        .catch(err => {
          // this.error = true
          this.processingView = false
          this.snackbar = true
          this.snackbarColor = 'orange'
          this.snackbarMsg = err
        })
    }
  }
}
</script>
<style>
.hint_item {
  margin-left: 8px;
}
.cfr .c3-line.c3-line-平均 {
  stroke-width: 10px!important
}
</style>
