// https://vuex.vuejs.org/en/actions.html
import sha256 from 'sha256'
import axios from 'axios'

// The login action passes vuex commit helper that we will use to trigger mutations.
export default {
  login ({ commit }, userData) {
    return new Promise((resolve, reject) => {
      localStorage.removeItem('token')
      const pw = `${userData.username}/${userData.password}`
      commit('auth_request')
      axios.post(`/api/system/login?id=${userData.username}&pwd=${sha256(pw).toUpperCase()}`).then(response => response.data)
        .then(response => {
          if (response.status === 'success') {
            const token = response.data.authToken
            axios.defaults.headers.common.Authorization = 'Bearer ' + token
            axios.post('/api/system/getUserInfo').then(res => res.data)
              .then(res => {
                if (res.status === 'success') {
                  const user = res.data.userInfo
                  const normalMenu = res.data.normalMenu
                  const devMenu = res.data.devMenu
                  const moduleDataInitDate = res.data.moduleDataInitDate
                  localStorage.setItem('token', token)
                  localStorage.setItem('user', JSON.stringify(user))
                  localStorage.setItem('theme', res.data.userInfo.theme)
                  localStorage.setItem('normalMenu', JSON.stringify(normalMenu))
                  localStorage.setItem('devMenu', JSON.stringify(devMenu))
                  localStorage.setItem('moduleDataInitDate', JSON.stringify(moduleDataInitDate))
                  // console.log(localStorage.getItem('token'))
                  // console.log(JSON.parse(localStorage.getItem('user')))
                  // console.log(localStorage.getItem('theme'))
                  // console.log(JSON.parse(localStorage.getItem('normalMenu')))
                  // console.log(JSON.parse(localStorage.getItem('devMenu')))
                  // console.log(JSON.parse(localStorage.getItem('moduleDataInitDate')))
                  commit('auth_success', { token, user, normalMenu, devMenu, moduleDataInitDate })
                  resolve(response)
                }
              })
            // const token = response.data.authToken
            // const user = userData.username
            // console.log(response)
            // storing jwt in localStorage. https cookie is safer place to store
            // localStorage.setItem('token', token, user)
            // localStorage.setItem('user', user)
            // axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
            // mutation to change state properties to the values passed along
            // commit('auth_success', { token, user })
          } else {
            commit('auth_failure')
            resolve(response)
          }
          // commit('auth_success', { token, user })
          // resolve(response)
        })
        .catch(err => {
          commit('auth_error')
          localStorage.removeItem('token')
          resolve(err)
        })
        // .catch(err => {
        //   // console.log('login error')
        //   commit('auth_error')
        //   localStorage.removeItem('token')

      //   const token = 'access_token'
      //   // const user = 'username'
      //   localStorage.setItem('token', token)
      //   // localStorage.setItem('user', user)
      //   axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
      //   commit('auth_success', { token })
      //   // commit('auth_success', { token, user })

      //   reject(err)
      // })
    })
  },
  staffLogin ({ commit }, userData) {
    return new Promise((resolve, reject) => {
      const token = userData.sp
      axios.defaults.headers.common.Authorization = 'Bearer ' + token
      axios.post('/api/system/getUserInfo').then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            const user = res.data.userInfo
            const normalMenu = res.data.normalMenu
            const devMenu = res.data.devMenu
            const moduleDataInitDate = res.data.moduleDataInitDate
            localStorage.setItem('token', token)
            localStorage.setItem('user', JSON.stringify(user))
            localStorage.setItem('theme', res.data.userInfo.theme)
            localStorage.setItem('normalMenu', JSON.stringify(normalMenu))
            localStorage.setItem('devMenu', JSON.stringify(devMenu))
            localStorage.setItem('moduleDataInitDate', JSON.stringify(moduleDataInitDate))
            commit('auth_success', { token, user, normalMenu, devMenu, moduleDataInitDate })
            resolve(res)
          }
        })
    })
  },
  logout ({ commit }) {
    return new Promise((resolve, reject) => {
      commit('logout')
      axios.post('/api/system/logout')
      // .then(response => {
      // }
      localStorage.removeItem('token')
      delete axios.defaults.headers.common.Authorization
      resolve()
    })
  },
  refreshtoken ({ commit }) {
    axios.get('/refresh')
      .then(response => {
        const token = response.data.access_token
        localStorage.setItem('token', token)
        axios.defaults.headers.common.Authorization = 'Bearer ' + token
        commit('auth_success', { token })
      })
      .catch(error => {
        // console.log('refresh token error')
        commit('logout')
        localStorage.removeItem('token')
        // console.log(error)
        commit(error)
      })
  },
  getAuthorize ({ commit }, url) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/system/doAuthorize?url=${url}`).then(response => response.data)
        .then(response => {
          resolve(response)
        })
    })
  },
  getTableList ({ commit }, tableName) {
    this.$http.get(`/${tableName}`)
      .then(response => {
        // console.log(response)
        const tableList = response.data.Keys
        commit('setTableList', { tableList })
      })
      // .catch(error => console.log(error))
  },
  updateTableItem ({ commit }, tableData) {
    return new Promise((resolve, reject) => {
      const httpmethod = tableData.method
      axios({ url: `/${tableData.endpoint}`, method: httpmethod, data: tableData.tableItem })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          // console.log(error)
          reject(error)
        })
    })
  },
  open ({ commit }, widths) {
    return new Promise((resolve, reject) => {
      // commit('width')
      // document.getElementsByClassName('v-overlay')[0].style = 'width:calc(100% - ' + widths + 'px)!important;'
      document.getElementsByClassName('v-dialog__content')[0].style = 'width:calc(100% - ' + widths + 'px)!important;'
      // resolve(widths + '000')
    })
  }
  // autoRefreshToken ({ commit }) {
  //   return new Promise((resolve, reject) => {
  //     setInterval(function () {
  //       // code goes here that will be run every 20 minutes.
  //       axios.get('/refresh')
  //         .then(response => {
  //           const token = response.data.access_token
  //           localStorage.setItem('token', token)
  //           axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
  //           commit('auth_success', { token })
  //           resolve(response)
  //         })
  //         .catch(error => {
  //           console.log('refresh token error')
  //           console.log(error)
  //           reject(error)
  //         })
  //     }, 1200000)
  //   }
  //   )
  // },

}
