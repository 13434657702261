<template>
  <v-container
    fluid
    grid-list-xl
    pt-0
  >
    <v-layout wrap>
      <v-flex
        mt-3
        xs12
      >
        <material-card
          title="產品效能測試分析"
          color="general"
          class="card-padding pt-0 pb-3">
          <v-form>
            <v-container
              py-0
              class="mw-100">
              <v-layout
                wrap
                align-items-center>
                <v-flex
                  flex-small
                  d-flex
                  align-items-center
                  xs12
                  lg4>
                  <p class="m-0">時間</p>
                  <v-menu
                    v-model="summaryStartDateMenu"
                    :close-on-content-click="false"
                    :nudge-left="40"
                    lazy
                    transition="scale-transition"
                    style="max-width: 170px;margin-left: 10px;"
                    max-width="290px"
                    offset-y
                    full-width
                  >
                    <v-text-field
                      slot="activator"
                      :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                      v-model="summaryStartDate"
                      label=""
                      prepend-icon="event"
                    />
                    <v-date-picker
                      v-model="summaryStartDate"
                      :min="startdate"
                      :max="new Date() | date-format"
                      :color="theme==='dark'?'danger':'general'"
                      type="date"
                      no-title
                      scrollable
                      actions/>
                  </v-menu>
                  <p class="m-0 text-center px-3">到</p>
                  <v-menu
                    v-model="summaryEndDateMenu"
                    :close-on-content-click="false"
                    :nudge-left="40"
                    lazy
                    transition="scale-transition"
                    style="max-width: 170px;margin-left: 10px;"
                    max-width="290px"
                    offset-y
                    full-width
                  >
                    <v-text-field
                      slot="activator"
                      :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                      v-model="summaryEndDate"
                      label=""
                      prepend-icon="event"
                    />
                    <v-date-picker
                      v-model="summaryEndDate"
                      :min="summaryStartDate"
                      :max="new Date() | date-format"
                      :color="theme==='dark'?'danger':'general'"
                      type="date"
                      no-title
                      scrollable
                      actions/>
                  </v-menu>
                </v-flex>
                <v-flex
                  xs12
                  lg6>
                  <v-autocomplete
                    v-model="summaryModelAndFactory"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryModelAndFactoryMenu"
                    label="Model name"
                    class="v-text-field__slot"
                    multitude
                    chips
                    persistent-hint
                    multiple
                    outlined
                  />
                </v-flex>
                <v-flex
                  xs12
                  lg2
                  text-right
                >
                  <v-btn
                    :disabled="summaryStartDate===''||summaryEndDate===''"
                    class="mx-0 font-weight-light"
                    color="general"
                    @click="doSubmitAndGetModelFeature()"
                  >
                    <v-icon class="mr-2">mdi-backup-restore</v-icon>生產機種一覽
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
      </v-flex>
      <v-flex
        v-if="isGet"
        xs12
        style="padding: 0px 12px!important;margin-bottom: 10px;">
        <material-card>
          <v-container class="p-0 pb-3 mw-100">
            <v-layout wrap>
              <v-flex
                flex-small
                xs12
                lg12
                style="display: flex !important;justify-content: space-between;padding: 2px 12px!important;">
                <div style="display: flex !important;align-items: center !important;">
                  <h5 class="my-0 mr-2 d-inline-flex">MODEL</h5>
                </div>
              </v-flex>
            </v-layout>
          </v-container>
          <v-card-actions class="m-0 border-top border-secondary flex-wrap">
            <div
              v-for="(model, m) in modelFeatureModelList"
              :key="'m'+m"
              :class="{'select': modelFeatureModelSelectList.indexOf(model.name)>-1,'translucent': modelFeatureIntersectionModelList[modelFeatureIntersectionModelList.map((e) => { return e.name }).indexOf(model.name)].qty===0}"
              class="list-item cursor highlight"
              @click="doSelectModel(model.name)">{{ model.name }} ｜ {{ modelFeatureIntersectionTotal>0?(modelFeatureIntersectionModelList[modelFeatureIntersectionModelList.map((e) => { return e.name }).indexOf(model.name)].qty/modelFeatureIntersectionTotal*100).toFixed(1):'0.0' }}%</div>
          </v-card-actions>
        </material-card>
      </v-flex>
      <v-flex
        v-if="isGet"
        xs12
        style="padding: 0px 12px!important;margin-bottom: 10px;">
        <material-card>
          <v-container class="p-0 pb-3 mw-100">
            <v-layout wrap>
              <v-flex
                flex-small
                xs12
                lg12
                style="display: flex !important;justify-content: space-between;padding: 2px 12px!important;">
                <div style="display: flex !important;align-items: center !important;">
                  <h5 class="my-0 mr-2 d-inline-flex">CPU</h5>
                </div>
              </v-flex>
            </v-layout>
          </v-container>
          <v-card-actions class="m-0 border-top border-secondary flex-wrap">
            <div
              v-for="(cpu, c) in modelFeatureCpuList"
              :key="'c'+c"
              :class="{'select': modelFeatureCpuSelectList.indexOf(cpu.name)>-1,'translucent': modelFeatureIntersectionCpuList[modelFeatureIntersectionCpuList.map((e) => { return e.name }).indexOf(cpu.name)].qty===0}"
              class="list-item cursor highlight"
              @click="doSelectCpu(cpu.name)">{{ cpu.name }} ｜ {{ modelFeatureIntersectionTotal>0?(modelFeatureIntersectionCpuList[modelFeatureIntersectionCpuList.map((e) => { return e.name }).indexOf(cpu.name)].qty/modelFeatureIntersectionTotal*100).toFixed(1):'0.0' }}%</div>
          </v-card-actions>
        </material-card>
      </v-flex>
      <v-flex
        v-if="isGet"
        xs12
        style="padding: 0px 12px!important;margin-bottom: 10px;">
        <material-card>
          <v-container class="p-0 pb-3 mw-100">
            <v-layout wrap>
              <v-flex
                flex-small
                xs12
                lg12
                style="display: flex !important;justify-content: space-between;padding: 2px 12px!important;">
                <div style="display: flex !important;align-items: center !important;">
                  <h5 class="my-0 mr-2 d-inline-flex">VGA</h5>
                </div>
              </v-flex>
            </v-layout>
          </v-container>
          <v-card-actions class="m-0 border-top border-secondary flex-wrap">
            <div
              v-for="(vga, v) in modelFeatureVgaList"
              :key="'v'+v"
              :class="{'select': modelFeatureVgaSelectList.indexOf(vga.name)>-1,'translucent': modelFeatureIntersectionVgaList[modelFeatureIntersectionVgaList.map((e) => { return e.name }).indexOf(vga.name)].qty===0}"
              class="list-item cursor highlight"
              @click="doSelectVga(vga.name)">{{ vga.name }} ｜ {{ modelFeatureIntersectionTotal>0?(modelFeatureIntersectionVgaList[modelFeatureIntersectionVgaList.map((e) => { return e.name }).indexOf(vga.name)].qty/modelFeatureIntersectionTotal*100).toFixed(1):'0.0' }}%</div>
          </v-card-actions>
        </material-card>
      </v-flex>
      <v-flex
        v-if="isGet"
        xs12
        style="padding: 0px 12px!important;margin-bottom: 10px;">
        <material-card>
          <v-form>
            <v-container
              py-0
              class="mw-100">
              <v-layout
                wrap
                align-items-center>
                <v-flex
                  xs12
                  text-right
                >
                  <v-btn
                    :disabled="!(modelFeatureModelSelectList.length>0||modelFeatureCpuSelectList.length>0||modelFeatureVgaSelectList.length>0)"
                    class="mx-0 mr-3 font-weight-light"
                    color="general"
                    @click="doSubmit()"
                  >
                    <v-icon class="mr-2">mdi-backup-restore</v-icon>統計
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
      </v-flex>
      <v-flex
        v-if="isSubmit"
        flex_table_width_auto
        flex_width_auto
        xs12
      >
        <material-card
          id="checkTable"
          :style="'max-width: calc(100vw - '+(Number(shrink)+32)+'px);'"
          title="產品效能測試明細"
          color="deep-orange"
          class="card-mix d-table-t0 no-data-left"
          style="margin-top: 30px!important;">
          <v-btn
            class="mix-btn black text-light mx-0"
            style="right:140px;"
            @click="dofilterALLClear()">清除條件</v-btn>
          <v-btn
            class="mix-btn black text-light"
            style="right: 13.5rem;"
            @click="doDownload()">
            <v-icon class="floata-left">mdi-download</v-icon>全部下載
          </v-btn>
          <v-btn
            class="mix-btn black text-light"
            style="right: 20rem;"
            @click="doGroupDownload()">
            <v-icon class="floata-left">mdi-download</v-icon>打包下載
          </v-btn>
          <v-select
            v-model="perPageRows"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="rowsAmount"
            class="mix-list v-text-field__slot d-inline-block float-right ml-5"
            style="width: 90px;"
            persistent-hint
            outlined
          />
          <v-autocomplete
            id="checkTableInput"
            v-model="checkTableShowList"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="responseDataShowList"
            label="選擇欄位"
            class="v-text-field__slot row-btn-175 pt-0"
            color="white"
            multitude
            chips
            persistent-hint
            multiple
            outlined
          />
          <v-btn
            class="red text-light float-right mr-0"
            @click="checkTableShowListClear()"
          >
            清除
          </v-btn>
          <v-btn
            class="blue text-light float-right"
            @click="checkTableShowListAll()"
          >
            全選
          </v-btn>
        </material-card>
        <material-card
          :style="'max-width: calc(100vw - '+(Number(shrink)+32)+'px);'"
          class="d-table-t0 no-data-left">
          <table
            :class="{ 'showList': checkTableShowList.length>0}"
            class="freeze-title statistic-table keypart-table icon-white">
            <thead>
              <tr>
                <th
                  :class="'text-xs-'+responseHeadersCompile[0].align"
                  class="td-check table-width-55"
                  style="position: relative;overflow: hidden;">
                  <!-- <v-checkbox
                    v-model="checkALL"
                    :value="true"
                    class="mr-2 mt-0"
                    color="white"
                    label=""/> -->
                  標記
                </th>
                <th
                  v-for="(header, h) in responseHeadersCompile"
                  v-show="h>0 && (header.fixed || checkTableShowList.indexOf(header.value)!==-1)"
                  :key="'h'+h"
                  :class="{'desc':pagination.current.sortBy===header.value&&pagination.current.descending, 'asc':pagination.current.sortBy===header.value&&!pagination.current.descending, 'text-xs-left': header.align==='left', 'text-xs-center': header.align==='center', 'text-xs-right': header.align==='right'}"
                  class="freeze-width-200">
                  <div
                    :style="'justify-content:'+header.align+';'"
                    class="table-filter-item">
                    <v-icon @click="dofilterViewOpen(header.type, header.index, header.group, header.text)">mdi-filter</v-icon>
                    {{ filterSettingList[header.index] }}
                    <v-icon
                      v-if="filterSettingList[header.index]"
                      class="close"
                      @click="dofilterClear(header.index)">
                      mdi-close
                    </v-icon>
                  </div>
                  <span @click="doTableSort(header.value)">
                    {{ header.text }}
                    <v-icon class="sort-btn">mdi-arrow-up</v-icon>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item,i) in responseData"
                :key="'i'+i">
                <td
                  :class="'text-xs-'+responseHeadersCompile[0].align"
                  class="td-check table-width-55"
                  style="position: relative;overflow: hidden;">
                  <v-checkbox
                    v-model="checkList"
                    :value="item.pk"
                    class="mr-2 mt-0"
                    color="white"
                    label=""/>
                </td>
                <td class="text-center freeze-width-200">{{ item.isn }}</td>
                <td class="text-center freeze-width-200">{{ item.Project_DBINFO }}</td>
                <td class="text-center freeze-width-200">{{ item['90_DBINFO'] }}</td>
                <td class="text-center freeze-width-200">{{ item.CPU_DBINFO }}</td>
                <td class="text-center freeze-width-200">{{ item.VGA_DBINFO }}</td>
                <td class="text-center freeze-width-200">{{ item.GPU_DBINFO }}</td>
                <td class="text-center freeze-width-200">{{ item.RAM_Size_DBINFO }}</td>
                <template v-for="(col, index) in responseDataShowList">
                  <td
                    v-if="checkTableShowList.indexOf(col.value)!==-1 && responseHeadersCompile[index+8].format==='number'"
                    :key="'col'+index"
                    :class="'freeze-width-200 text-xs-'+responseHeadersCompile[index+8].align"
                  >{{ Number(item[col.value]).toFixed(0) }}</td>
                  <td
                    v-else-if="checkTableShowList.indexOf(col.value)!==-1 && responseHeadersCompile[index+8].format==='decimal'"
                    :key="'col'+index"
                    :class="'freeze-width-200 text-xs-'+responseHeadersCompile[index+8].align"
                  >{{ Number(item[col.value]).toFixed(2) }}</td>
                  <td
                    v-else-if="checkTableShowList.indexOf(col.value)!==-1 && responseHeadersCompile[index+8].format==='date'"
                    :key="'col'+index"
                    :class="'freeze-width-200 text-xs-'+responseHeadersCompile[index+8].align"
                  >{{ $moment(item[col.value]).format('YYYY-MM-DD') }}</td>
                  <td
                    v-else-if="checkTableShowList.indexOf(col.value)!==-1 && responseHeadersCompile[index+8].format==='datetime'"
                    :key="'col'+index"
                    :class="'freeze-width-200 text-xs-'+responseHeadersCompile[index+8].align"
                  >{{ $moment(item[col.value]).format('YYYY-MM-DD HH:mm:ss') }}</td>
                  <td
                    v-else-if="checkTableShowList.indexOf(col.value)!==-1 && responseHeadersCompile[index+8].format==='download'"
                    :key="'col'+index"
                    :class="'freeze-width-200 text-xs-'+responseHeadersCompile[index+8].align"
                  >
                    <router-link
                      :to="responseHeadersCompile[index+8].memo.url.replace('{pk}',item.pk)"
                      :style="'color:' + (theme === 'dark' ? '#ffffff' : '#000000') + '!important;'"
                      target="_blank">
                      {{ item[col.value] }}
                    </router-link>
                  </td>
                  <td
                    v-else-if="checkTableShowList.indexOf(col.value)!==-1 && responseHeadersCompile[index+8].format==='link'"
                    :key="'col'+index"
                    :class="'freeze-width-200 text-xs-'+responseHeadersCompile[index+8].align"
                  >
                    <router-link
                      :to="responseHeadersCompile[index+8].memo.url+item.pk"
                      :style="'color:' + (theme === 'dark' ? '#ffffff' : '#000000') + '!important;'"
                      target="_blank">
                      {{ item[col.value] }}
                    </router-link>
                  </td>
                  <td
                    v-else-if="checkTableShowList.indexOf(col.value)!==-1"
                    :key="'col'+index"
                    :class="'freeze-width-200 text-xs-'+responseHeadersCompile[index+8].align"
                  >{{ item[col.value] }}</td>
                </template>
              </tr>
            </tbody>
          </table>
          <div
            v-if="pagination.current.rowsPerPage != -1"
            class="text-xs-center pt-2 d-inline-block"
            style="margin-left: 0px;width: calc(100% - 140px);">
            <v-pagination
              v-model="pagination.current.page"
              :length="pages"/>
          </div>
          <v-autocomplete
            v-if="pagination.current.rowsPerPage != -1"
            v-model="pagination.current.page"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="pageslist"
            class="v-text-field__slot d-inline-block float-right mr-0"
            style="width: 90px;"
            persistent-hint
            outlined
          />
        </material-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="filterView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <v-card
        max-width="35%"
        style="margin:0 auto;">
        <v-card-text>
          <v-alert
            v-model="filterView"
            class="mt-3 mb-3 border-0"
            color="brown">
            <strong class="h4">正在設定[{{ filterTitle }}]條件：{{ filterDirections }}</strong>
          </v-alert>
          <v-flex
            d-flex
            table-filter-setting-bar
            xs12>
            <v-text-field
              v-if="filterInfo.type==='keyword'"
              v-model="filterKeyword"
              :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
              label="關鍵字"
              placeholder="請輸入關鍵字"
              persistent-hint/>
            <v-btn-toggle
              v-if="filterInfo.type==='formula'"
              v-model="filterBtnSelect"
              class="d-flex align-items-center"
              multiple>
              <v-text-field
                v-model="filterMinValue"
                :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                :disabled="filterBtnSelect.indexOf('ge')===-1"
                label=""
                persistent-hint
                @keyup="doMinValueReplaceText()"/>
              <v-btn
                value="ge"
                class="general"
                color="light"
                flat>
                <v-icon>mdi-less-than-or-equal</v-icon>
              </v-btn>
              <v-text-field
                :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                label=""
                value="X"
                disabled
                persistent-hint/>
              <v-btn
                value="le"
                class="general"
                color="light"
                flat>
                <v-icon>mdi-less-than-or-equal</v-icon>
              </v-btn>
              <v-text-field
                v-model="filterMaxValue"
                :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                :disabled="filterBtnSelect.indexOf('le')===-1"
                label=""
                persistent-hint
                @keyup="doMaxValueReplaceText()"/>
            </v-btn-toggle>
          </v-flex>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="pink"
            small
            @click="dofilterSetting()">
            <v-icon class="mr-2">mdi-plus</v-icon>新增條件
          </v-btn>
          <v-btn
            color="general"
            small
            @click="filterView=false">
            <v-icon class="mr-2">mdi-close</v-icon>返回
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="processingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        PROCESSING 處理中
      </p>
    </v-dialog>
    <v-dialog
      v-model="loadingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :color="snackbarColor"
      :top="true">
      {{ snackbarMsg }}
      <v-btn
        flat
        @click="snackbar = false">
        關閉
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import qs from 'qs'
// import $ from 'jquery'
import { mapState } from 'vuex'
import Loading from '@/assets/img/loading.gif'

export default {
  name: 'MediaReviewAnalyzer',
  components: {
  },
  data () {
    return {
      startdate: '2018-04-01',
      snackbar: false,
      snackbarColor: 'green',
      snackbarMsg: '',
      Loading,
      loadingView: false,
      processingView: false,
      expressDownload: false,
      isSubmit: false,
      isGet: false,
      filterView: false,
      filterInfo: {
        type: '',
        index: -1,
        group: '',
        item: ''
      },
      filterTitle: '',
      filterKeyword: '',
      filterMinValue: '',
      filterMaxValue: '',
      filterBtnSelect: [],
      filterSettingList: [],
      filterSettingDetailList: [],
      summaryModelAndFactoryMenu: [],
      summaryModelAndFactory: [],
      summaryStartDateMenu: false,
      summaryStartDate: this.$moment(new Date()).format('YYYY-MM-DD'),
      summaryEndDateMenu: false,
      summaryEndDate: this.$moment(new Date()).format('YYYY-MM-DD'),
      checkALL: null,
      checkList: [],
      checkTableShowList: [],
      responseDateStart: '',
      responseDateEnd: '',
      responseModelFeature: [],
      modelFeatureModelSelectList: [],
      modelFeatureCpuSelectList: [],
      modelFeatureVgaSelectList: [],
      // rowsAmount: [25, 50, 100, { text: 'All', value: -1 }],
      rowsAmount: [10, 25, 50, 100],
      perPageRows: 25,
      pagination: {
        page: 1,
        current: {
          descending: true,
          page: 1,
          rowsPerPage: 25,
          sortBy: '',
          totalItems: 0
        }
      },
      pages: 0,
      responseData: [],
      responseHeaders: [],
      params: {
        pageNum: 1,
        pageRows: 25,
        sortColumn: '',
        sortType: true,
        filterList: []
      }
    }
  },
  computed: {
    ...mapState('app', ['theme', 'shrink']),
    modelFeatureModelList () {
      const array = []
      this.responseModelFeature.forEach((item) => {
        if (array.map((e) => { return e.name }).indexOf(item.model) === -1) {
          array.push({ name: item.model, qty: item.qty })
        } else {
          array[array.map((e) => { return e.name }).indexOf(item.model)].qty += item.qty
        }
      })
      return array
    },
    modelFeatureCpuList () {
      const array = []
      this.responseModelFeature.forEach((item) => {
        if (array.map((e) => { return e.name }).indexOf(item.cpu) === -1) {
          array.push({ name: item.cpu, qty: item.qty })
        } else {
          array[array.map((e) => { return e.name }).indexOf(item.cpu)].qty += item.qty
        }
      })
      return array
    },
    modelFeatureVgaList () {
      const array = []
      this.responseModelFeature.forEach((item) => {
        if (array.map((e) => { return e.name }).indexOf(item.vga) === -1) {
          array.push({ name: item.vga, qty: item.qty })
        } else {
          array[array.map((e) => { return e.name }).indexOf(item.vga)].qty += item.qty
        }
      })
      return array
    },
    modelFeatureIntersectionCompile () {
      const model = this.modelFeatureModelSelectList.length > 0 ? this.modelFeatureModelSelectList : this.modelFeatureModelList.map((e) => { return e.name })
      const cpu = this.modelFeatureCpuSelectList.length > 0 ? this.modelFeatureCpuSelectList : this.modelFeatureCpuList.map((e) => { return e.name })
      const vga = this.modelFeatureVgaSelectList.length > 0 ? this.modelFeatureVgaSelectList : this.modelFeatureVgaList.map((e) => { return e.name })
      const array = this.responseModelFeature.filter((v) => {
        return (model.indexOf(v.model) !== -1) && (cpu.indexOf(v.cpu) !== -1) && (vga.indexOf(v.vga) !== -1)
      })
      return array
    },
    modelFeatureIntersectionModelList () {
      const array = []
      this.modelFeatureIntersectionCompile.forEach((item) => {
        if (array.map((e) => { return e.name }).indexOf(item.model) === -1) {
          array.push({ name: item.model, qty: item.qty })
        } else {
          array[array.map((e) => { return e.name }).indexOf(item.model)].qty += item.qty
        }
      })
      this.responseModelFeature.forEach((item) => {
        if (array.map((e) => { return e.name }).indexOf(item.model) === -1) {
          array.push({ name: item.model, qty: 0 })
        }
      })
      return array
    },
    modelFeatureIntersectionCpuList () {
      const array = []
      this.modelFeatureIntersectionCompile.forEach((item) => {
        if (array.map((e) => { return e.name }).indexOf(item.cpu) === -1) {
          array.push({ name: item.cpu, qty: item.qty })
        } else {
          array[array.map((e) => { return e.name }).indexOf(item.cpu)].qty += item.qty
        }
      })
      this.responseModelFeature.forEach((item) => {
        if (array.map((e) => { return e.name }).indexOf(item.cpu) === -1) {
          array.push({ name: item.cpu, qty: 0 })
        }
      })
      return array
    },
    modelFeatureIntersectionVgaList () {
      const array = []
      this.modelFeatureIntersectionCompile.forEach((item) => {
        if (array.map((e) => { return e.name }).indexOf(item.vga) === -1) {
          array.push({ name: item.vga, qty: item.qty })
        } else {
          array[array.map((e) => { return e.name }).indexOf(item.vga)].qty += item.qty
        }
      })
      this.responseModelFeature.forEach((item) => {
        if (array.map((e) => { return e.name }).indexOf(item.vga) === -1) {
          array.push({ name: item.vga, qty: 0 })
        }
      })
      return array
    },
    modelFeatureIntersectionTotal () {
      let total = 0
      this.modelFeatureIntersectionCompile.forEach((item) => {
        total += item.qty
      })
      return total
    },
    responseHeadersCompile () {
      const array = [
        {
          sortable: false,
          fixed: true,
          type: null,
          text: '標記',
          index: 0,
          value: '',
          group: '',
          align: 'center'
        }
      ]
      this.responseHeaders.forEach((g, gg) => {
        array.push({
          sortable: g.sortable,
          fixed: g.fixed,
          type: g.dataType === 'text' ? 'keyword' : g.dataType === 'number' ? 'formula' : null,
          text: g.text,
          format: g.displayFormat,
          index: gg + 1,
          value: g.value,
          align: g.align,
          class: g.tag,
          memo: g.memo
        })
      })
      return array
    },
    responseDataShowList () {
      const array = []
      this.responseHeadersCompile.forEach((r, rr) => {
        if (!r.fixed) {
          array.push({ text: r.text, value: r.value })
        }
      })
      return array
    },
    filterDirections () {
      let result = ''
      if (this.filterInfo.type === 'formula') {
        if (this.filterBtnSelect.indexOf('le') > -1 && this.filterBtnSelect.indexOf('ge') > -1) {
          result = '介於' + this.filterMinValue + '~' + this.filterMaxValue + '之間'
        } else if (this.filterBtnSelect.indexOf('le') > -1) {
          result = '小於等於' + this.filterMaxValue
        } else if (this.filterBtnSelect.indexOf('ge') > -1) {
          result = '大於等於' + this.filterMinValue
        }
      } else if (this.filterInfo.type === 'keyword') {
        result = '包含「' + (this.filterKeyword || '') + '」的字詞'
      }
      return result
    },
    // pages () {
    //   return Math.ceil(this.pagination.current.totalItems / this.pagination.current.rowsPerPage)
    // },
    pageslist () {
      let i = 0
      const list = []
      if (this.pagination.current.rowsPerPage) {
        for (i = 0; i < Math.ceil(this.pagination.current.totalItems / this.pagination.current.rowsPerPage); i++) {
          list.push(i + 1)
        }
      }
      return list
    }
  },
  watch: {
    startDate (val) {
      this.startDateMenu = false
    },
    endDate (val) {
      this.endDateMenu = false
    },
    checkALL (val) {
      if (!this.processingView) {
        if (val) {
          this.responseData.forEach((r, rr) => {
            if (this.checkList.indexOf(r.pk) === -1) {
              this.checkList.push(r.pk)
            }
          })
        } else {
          this.responseData.forEach((r, rr) => {
            this.checkList.splice(this.checkList.indexOf(r.pk), 1)
          })
        }
      }
    },
    'pagination.current.page' (val) {
      this.pagination.page = val
    },
    'pagination.current.rowsPerPage' (val) {
      if (this.pagination.current.page > this.pages) {
        this.pagination.current.page = this.pages
      }
      if (this.pagination.current.page < 1) {
        this.pagination.current.page = 1
      }
    },
    'pagination.current.sortBy' (val) {
      this.pagination.current.page = 1
    },
    'pagination.current.descending' (val) {
      this.pagination.current.page = 1
    },
    // 'pagination.current.page' (val) {
    //   this.checkList = []
    // },
    // 'pagination.rowsPerPage' (val) {
    //   this.checkList = []
    // },
    // 'pagination.sortBy' (val) {
    //   this.checkList = []
    // },
    // 'pagination.descending' (val) {
    //   this.checkList = []
    // },
    pagination: {
      handler () {
        if (!this.processingView) {
          this.doSubmitData(this.params, true)
        }
      },
      immediate: false,
      deep: true
    },
    summaryStartDate (val) {
      this.getManufacturingModel()
    },
    summaryEndDate (val) {
      this.getManufacturingModel()
    },
    filterSettingList (val) {
      if (!this.processingView) {
        this.pagination.current.page = 1
        this.doSubmitData(this.params, true)
      }
    },
    checkTableShowList (newName, oldName) {
      if (newName.length <= oldName.length) {
        // const array = []
        this.responseDataShowList.forEach((s, ss) => {
          if (newName.indexOf(s.value) === -1) {
            this.dofilterClear(this.responseHeadersCompile.map((e) => { return e.value }).indexOf(s.value))
            if (this.pagination.current.sortBy === s.value) {
              this.pagination.current.sortBy = null
              this.pagination.current.descending = null
            }
          }
        })
        // val.forEach((t, tt) => {
        //   let group = this.responseHeadersCompile[this.responseHeadersCompile.map((e) => { return e.value }).indexOf(t)].group
        //   if (array[group] > 0) {
        //     array[group]++
        //   } else {
        //     array[group] = 1
        //   }
        // })
        // this.checkTableGroupLengthList = array
      }
    },
    responseData (val) {
      this.pages = Math.ceil(this.pagination.current.totalItems / this.pagination.current.rowsPerPage)
    //   // if (this.pages > 1) {
    //   //   setTimeout(() => {
    //   //     this.pagination.current.page = this.pages
    //   //   }, 0)
    //   //   setTimeout(() => {
    //   //     this.pagination.current.page = 1
    //   //   }, 100)
    //   // } else {
    //   //   setTimeout(() => {
    //   //     this.pagination.current.rowsPerPage = 1
    //   //   }, 0)
    //   //   setTimeout(() => {
    //   //     this.pagination.current.rowsPerPage = this.perPageRows
    //   //   }, 100)
    //   // }
    },
    perPageRows (val) {
      this.pagination.current.rowsPerPage = val
      this.pages = Math.ceil(this.responseData.length / this.pagination.current.rowsPerPage)
      this.pagination.current.page = 1
      if (!this.processingView) {
        this.doSubmitData(this.params, true)
      }
    }
    // perPageRows (val) {
    //   if (val === -1) {
    //     if (confirm('完整顯示將可能導致效能降低，是否仍完整顯示?')) {
    //       this.pagination.rowsPerPage = val
    //       this.pages = Math.ceil(this.responseData.length / this.pagination.rowsPerPage)
    //       this.pagination.current.page = 1
    //     } else {
    //       setTimeout(() => {
    //         this.perPageRows = this.pagination.rowsPerPage
    //       }, 100)
    //     }
    //   } else {
    //     this.pagination.rowsPerPage = val
    //     this.pages = Math.ceil(this.responseData.length / this.pagination.rowsPerPage)
    //     this.pagination.current.page = 1
    //   }
    // }
  },
  mounted () {
    this.$store.dispatch('getAuthorize', '/product/analyze/mediaReviewAnalyzer')
      .then(response => {
        if (response.status === 'success' && response.data.accessible) {
          JSON.parse(localStorage.getItem('moduleDataInitDate')).forEach((n) => {
            if (n.name === 'dataLog') {
              this.startdate = n.value
            }
          })
          if (this.$route.params.searchModelPk && this.$route.params.searchStationPk && this.$route.params.searchSn) {
            this.tempModelAndFactory = Number(this.$route.params.searchModelPk)
            this.addDeviceSnListItem()
          }
          this.getManufacturingModel()
        } else if (response.errCode === 'E10000002') {
          const routeData = this.$router.resolve({ path: '/noEnter/E10000002' })
          localStorage.setItem('errormsg', response.errMsg)
          window.location.assign(routeData.href)
        } else {
          const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
          window.location.assign(routeData.href)
        }
      })
      .catch(err => {
        console.log(err)
        const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
        window.location.assign(routeData.href)
      })
  },
  methods: {
    getManufacturingModel () {
      const body = {
        manufacturerDateStart: this.summaryStartDate,
        manufacturerDateEnd: this.summaryEndDate,
        stationName: 'S-BENCHMARK'
      }
      this.$http.get('/api/product/fundamental/manufacturingModel?' + qs.stringify(body)).then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.summaryModelAndFactoryMenu = []
            res.data.forEach((n) => {
              this.summaryModelAndFactoryMenu.push({ text: (n.modelName || 'N/A') + '　　(' + (n.factoryName || 'N/A') + ')', value: n.pk })
            })
            if (this.tempModelAndFactory !== '') {
              this.summaryModelAndFactory = this.tempModelAndFactory
            }
          }
        })
    },
    getFilterResult (array) {
      let result = true
      this.filterSettingDetailList.forEach((n, nn) => {
        if (n !== undefined) {
          if (this.responseHeadersCompile[nn].type === 'formula') {
            if (n[0].indexOf('le') !== -1 && n[0].indexOf('ge') !== -1) {
              if (!(array[this.responseHeadersCompile[nn].value] >= n[1] && array[this.responseHeadersCompile[nn].value] <= n[2])) {
                result = false
              }
            } else if (n[0].indexOf('le') !== -1) {
              if (!(array[this.responseHeadersCompile[nn].value] <= n[2])) {
                result = false
              }
            } else if (n[0].indexOf('ge') !== -1) {
              if (!(array[this.responseHeadersCompile[nn].value] >= n[1])) {
                result = false
              }
            }
          } else if (this.responseHeadersCompile[nn].type === 'keyword') {
            if (array[this.responseHeadersCompile[nn].value].toUpperCase().indexOf(n.toUpperCase()) === -1) {
              result = false
            }
          }
        }
      })
      return result
    },
    checkTableShowListAll () {
      this.checkTableShowList = []
      this.responseDataShowList.forEach((s) => {
        this.checkTableShowList.push(s.value)
      })
    },
    checkTableShowListClear () {
      this.checkTableShowList = []
    },
    doTableSort (val) {
      if (this.pagination.current.sortBy === val) {
        if (this.pagination.current.descending === false) {
          this.pagination.current.descending = true
        } else if (this.pagination.current.descending === true) {
          this.pagination.current.descending = null
          this.pagination.current.sortBy = null
        } else if (this.pagination.current.descending === null) {
          this.pagination.current.descending = false
        }
      } else {
        this.pagination.current.sortBy = val
        this.pagination.current.descending = false
      }
    },
    doMinValueReplaceText () {
      this.filterMinValue = this.filterMinValue.replace(/^[^0-9-]*(-?)(\d*(?:\.\d{0,2})?).*$/g, '$1$2')
    },
    doMaxValueReplaceText () {
      this.filterMaxValue = this.filterMaxValue.replace(/^[^0-9-]*(-?)(\d*(?:\.\d{0,2})?).*$/g, '$1$2')
    },
    dofilterViewOpen (type, index, group, item) {
      this.filterTitle = group ? group + '-' + item : item
      this.filterInfo = {
        type: type,
        index: index,
        group: group,
        item: item
      }
      if (type === 'formula') {
        this.filterBtnSelect = this.filterSettingDetailList[index] ? this.filterSettingDetailList[index][0] : []
        this.filterMinValue = this.filterSettingDetailList[index] ? this.filterSettingDetailList[index][1] : 0
        this.filterMaxValue = this.filterSettingDetailList[index] ? this.filterSettingDetailList[index][2] : 0
      } else if (type === 'keyword') {
        this.filterKeyword = this.filterSettingList[index]
      }
      this.filterView = true
    },
    dofilterSetting () {
      if (this.filterInfo.type === 'formula') {
        if (this.filterBtnSelect.indexOf('le') > -1 && this.filterBtnSelect.indexOf('ge') > -1) {
          if (Number(this.filterMaxValue) >= Number(this.filterMinValue)) {
            this.filterSettingDetailList[this.filterInfo.index] = [this.filterBtnSelect, this.filterMinValue, this.filterMaxValue]
            this.filterSettingList.splice(this.filterInfo.index, 1, this.filterMinValue + '≤ X ≤' + this.filterMaxValue)
            this.filterView = false
          } else {
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = '左側數字不得大於右側數字'
          }
        } else if (this.filterBtnSelect.indexOf('le') > -1) {
          this.filterSettingDetailList[this.filterInfo.index] = [this.filterBtnSelect, 0, this.filterMaxValue]
          this.filterSettingList.splice(this.filterInfo.index, 1, '≤' + this.filterMaxValue)
          this.filterView = false
        } else if (this.filterBtnSelect.indexOf('ge') > -1) {
          this.filterSettingDetailList[this.filterInfo.index] = [this.filterBtnSelect, this.filterMinValue, 0]
          this.filterSettingList.splice(this.filterInfo.index, 1, '≥' + this.filterMinValue)
          this.filterView = false
        } else {
          this.snackbar = true
          this.snackbarColor = 'red'
          this.snackbarMsg = '請輸入條件'
        }
      } else if (this.filterInfo.type === 'keyword') {
        this.filterSettingDetailList[this.filterInfo.index] = this.filterKeyword
        this.filterSettingList.splice(this.filterInfo.index, 1, this.filterKeyword)
        this.filterView = false
      }
    },
    dofilterClear (index) {
      this.filterSettingDetailList[index] = undefined
      this.filterSettingList.splice(index, 1, undefined)
    },
    dofilterALLClear () {
      this.filterSettingDetailList = new Array(this.responseHeadersCompile.length + 1)
      this.filterSettingList = new Array(this.responseHeadersCompile.length + 1)
    },
    dofilterListCompile () {
      const array = []
      this.filterSettingDetailList.forEach((f, ff) => {
        if (Array.isArray(f)) {
          if (f[0].indexOf('le') !== -1) {
            array.push({ column: this.responseHeaders[ff - 1].value, dataType: this.responseHeaders[ff - 1].dataType, value: Number(f[2]), compareType: 'le' })
          }
          if (f[0].indexOf('ge') !== -1) {
            array.push({ column: this.responseHeaders[ff - 1].value, dataType: this.responseHeaders[ff - 1].dataType, value: Number(f[1]), compareType: 'ge' })
          }
        } else if (typeof f === 'string') {
          array.push({ column: this.responseHeaders[ff - 1].value, dataType: this.responseHeaders[ff - 1].dataType, value: f, compareType: 'like' })
        }
      })
      return array
    },
    doSelectModel (val) {
      if (this.modelFeatureModelSelectList.indexOf(val) === -1) {
        this.modelFeatureModelSelectList.push(val)
      } else {
        this.modelFeatureModelSelectList.splice(this.modelFeatureModelSelectList.indexOf(val), 1)
      }
    },
    doSelectCpu (val) {
      if (this.modelFeatureCpuSelectList.indexOf(val) === -1) {
        this.modelFeatureCpuSelectList.push(val)
      } else {
        this.modelFeatureCpuSelectList.splice(this.modelFeatureCpuSelectList.indexOf(val), 1)
      }
    },
    doSelectVga (val) {
      if (this.modelFeatureVgaSelectList.indexOf(val) === -1) {
        this.modelFeatureVgaSelectList.push(val)
      } else {
        this.modelFeatureVgaSelectList.splice(this.modelFeatureVgaSelectList.indexOf(val), 1)
      }
    },
    doSubmitAndGetModelFeature () {
      if ((new Date(this.summaryEndDate).getTime() < new Date(this.summaryStartDate).getTime())) {
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '結尾時間不得早於起始時間'
      } else if ((new Date(this.summaryEndDate).getTime() - new Date(this.summaryStartDate).getTime()) > 86400000 * 90) {
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '日期區間不得超過90天'
      } else {
        this.loadingView = true
        this.isSubmit = false
        const body = {
          manufactureStart: this.summaryStartDate,
          manufactureEnd: this.summaryEndDate,
          modelPkList: this.summaryModelAndFactory
        }
        this.$http.get('/api/product/mediaReview/modelFeature?' + qs.stringify(body)).then(res => res.data)
          .then(res => {
            this.loadingView = false
            if (res.status === 'success') {
              this.isGet = true
              this.modelFeatureModelSelectList = []
              this.modelFeatureCpuSelectList = []
              this.modelFeatureVgaSelectList = []
              this.responseDateStart = this.summaryStartDate
              this.responseDateEnd = this.summaryEndDate
              this.responseModelFeature = res.data
              this.IsSubmitPage = true
            }
            return res
          })
      }
    },
    doSubmit () {
      if ((new Date(this.summaryEndDate).getTime() < new Date(this.summaryStartDate).getTime())) {
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '結尾時間不得早於起始時間'
      } else if ((new Date(this.summaryEndDate).getTime() - new Date(this.summaryStartDate).getTime()) > 86400000 * 90) {
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '日期區間不得超過90天'
      } else {
        this.processingView = true
        const body = {
          pageNum: 1,
          pageRows: this.pagination.current.rowsPerPage,
          sortColumn: '',
          sortType: this.pagination.current.descending ? 'DESC' : ((this.pagination.current.descending !== null) ? 'ASC' : ''),
          modelNameList: this.modelFeatureModelSelectList,
          cpuList: this.modelFeatureCpuSelectList,
          vgaList: this.modelFeatureVgaSelectList,
          manufactureStart: this.responseDateStart,
          manufactureEnd: this.responseDateEnd,
          filterList: []
        }
        this.doSubmitHeaders(body)
      }
    },
    doSubmitHeaders (body) {
      this.$http.get('/api/product/mediaReview/modelTestDataColumnDefinition?' + qs.stringify(body)).then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.pagination.current.sortBy = res.data.sortBy
            this.pagination.current.descending = res.data.descending
            this.filterSettingDetailList = new Array(res.data.columnDefine.length + 1)
            this.filterSettingList = new Array(res.data.columnDefine.length + 1)
            this.responseHeaders = res.data.columnDefine
            this.pagination.current.page = 1
            this.checkTableShowList = []
            setTimeout(() => {
              this.doSubmitData(body, false)
            }, 0)
          } else { // failure
            this.processingView = false
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
          }
        })
        .catch(err => {
          this.processingView = false
          this.snackbar = true
          this.snackbarColor = 'red'
          this.snackbarMsg = err
        })
    },
    doSubmitData (params, replace) {
      const body = params
      if (replace) {
        this.processingView = true
        body.pageNum = this.pagination.current.page
        body.pageRows = this.pagination.current.rowsPerPage
        body.sortColumn = (this.pagination.current.descending !== null) ? this.pagination.current.sortBy : ''
        body.sortType = this.pagination.current.descending ? 'DESC' : ((this.pagination.current.descending !== null) ? 'ASC' : '')
        body.filterList = this.dofilterListCompile()
      }
      this.$http.get('/api/product/mediaReview/modelTestData?' + qs.stringify(body, { allowDots: true, encode: true })).then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.isSubmit = true
            this.params = body
            this.responseData = res.data.pagingList
            this.pagination.current.totalItems = res.data.totalRows
            this.checkALL = false
            this.checkList = []
            setTimeout(() => {
              this.processingView = false
            }, 0)
          } else { // failure
            this.processingView = false
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
          }
        })
        .catch(err => {
          this.processingView = false
          this.snackbar = true
          this.snackbarColor = 'red'
          this.snackbarMsg = err
        })
    },
    doDownload () {
      this.processingView = true
      const body = {
        manufactureStart: this.responseDateStart,
        manufactureEnd: this.responseDateEnd,
        modelNameList: this.modelFeatureModelSelectList,
        cpuList: this.modelFeatureCpuSelectList,
        vgaList: this.modelFeatureVgaSelectList
      }
      this.$http.get('/api/product/mediaReview/modelTestDataFile?' + qs.stringify(body, { arrayFormat: 'repeat', encode: true }), { observe: 'response', responseType: 'blob' })
        .then(res => {
          this.processingView = false
          if (res.data.type === 'application/json') {
            return new Promise((resolve, reject) => {
              const reader = new FileReader()
              reader.onload = () => {
                res.data = JSON.parse(reader.result)
                if (res.data.status === 'success') {
                  alert(res.data.data)
                  // resolve(Promise.reject(res.data.data))
                } else {
                  this.snackbar = true
                  this.snackbarColor = 'red'
                  this.snackbarMsg = res.data.errMsg + ' (' + res.data.errCode + ')'
                }
              }
              reader.onerror = () => {
                reject(res.data.data)
              }
              reader.readAsText(res.data)
            })
          } else if (res.data.status !== 'failure' && res.headers['content-disposition']) {
            // let blob = new Blob([res.data])
            const url = window.URL.createObjectURL(res.data)
            const fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]
            const downloadLink = document.createElement('a')

            // downloadLink.setAttribute('download', fileName)
            downloadLink.href = url
            downloadLink.download = fileName

            document.body.appendChild(downloadLink)
            downloadLink.click()
            document.body.removeChild(downloadLink)
          } else {
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = '下載失敗，請通知系統管理員'
          }
        })
        .catch(err => {
          // this.error = true
          this.processingView = false
          this.snackbar = true
          this.snackbarColor = 'orange'
          this.snackbarMsg = err
        })
    },
    doGroupDownload () {
      if (this.checkList.length > 0) {
        this.processingView = true
        const body = {
          manufactureStart: this.responseDateStart,
          manufactureEnd: this.responseDateEnd,
          modelNameList: this.modelFeatureModelSelectList,
          cpuList: this.modelFeatureCpuSelectList,
          vgaList: this.modelFeatureVgaSelectList,
          pk: this.checkList
        }
        body.pk.forEach((tt, index) => {
          body.pk[index] = Number(tt)
        })
        this.$http.get('/api/product/mediaReview/modelTestDataFile?' + qs.stringify(body, { arrayFormat: 'repeat', encode: true }), { observe: 'response', responseType: 'blob' })
          .then(res => {
            this.processingView = false
            if (res.data.type === 'application/json') {
              return new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.onload = () => {
                  res.data = JSON.parse(reader.result)
                  if (res.data.status === 'success') {
                    alert(res.data.data)
                    // resolve(Promise.reject(res.data.data))
                  } else {
                    this.snackbar = true
                    this.snackbarColor = 'red'
                    this.snackbarMsg = res.data.errMsg + ' (' + res.data.errCode + ')'
                  }
                }
                reader.onerror = () => {
                  reject(res.data.data)
                }
                reader.readAsText(res.data)
              })
            } else if (res.data.status !== 'failure' && res.headers['content-disposition']) {
              // let blob = new Blob([res.data])
              const url = window.URL.createObjectURL(res.data)
              const fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]
              const downloadLink = document.createElement('a')

              // downloadLink.setAttribute('download', fileName)
              downloadLink.href = url
              downloadLink.download = fileName

              document.body.appendChild(downloadLink)
              downloadLink.click()
              document.body.removeChild(downloadLink)
            } else {
              this.snackbar = true
              this.snackbarColor = 'red'
              this.snackbarMsg = '下載失敗，請通知系統管理員'
            }
          })
          .catch(err => {
            this.processingView = false
            this.snackbar = true
            this.snackbarColor = 'orange'
            this.snackbarMsg = err
          })
      } else {
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '未勾選Device ISN，請勾選後，再重新下載'
      }
    }
  }
}
</script>
<style>
</style>
