<template>
  <div
    v-show="doGroupOpen(item)"
    :class="{'list-item-area':typeKeywordList==='', 'vertical-line':item.itemList?(item.itemList.length>0):false,'list-item-alone-menu-selector':(typeSubItemList.composition === 'alone'&&item.type==='title')}"
    :style="(theme==='dark'?'background-color:#333;':theme==='light'?'background-color:#eee;':'')+('background-image: linear-gradient(to right, transparent 5%, '+depthColor[item.depth-1]+'66 100%);')"
    class="list-item-menu-selector">
    <span
      v-if="(!(item.itemList?(item.itemList.length>0):false)&&!(typeSubItemList.composition === 'alone'&&item.type==='group'))||((item.itemList?(item.itemList.length>0):false)&&(typeSubItemList.composition === 'alone'&&item.type==='item'))"
      v-show="((typeKeywordList===''&&doGroupOpen(item))||(typeKeywordList!==''&&item.type!=='title'&&(item.text.toUpperCase().indexOf(typeKeywordList.toUpperCase()) > -1)))"
      :title="item.type==='title'?'[單擊]展開/收合群組項目':item.type==='group'?'[單擊]新增群組項目至單一新線組；[雙擊]新增全部項目至各別新線組':(item.type==='item'&&typeSubItemList.composition==='mix')?'[單擊]新增項目至已選取線組；[雙擊]新增項目至新線組':''"
      :class="{'group-title':item.type==='title','group-all':(item.type==='group'&&item.group!==''),'group-item-title':typeKeywordList===''&&item.type==='item'&&item.group!==''&&item.itemList.length>0,'group-item':typeKeywordList===''&&item.type==='item'&&item.group!==''&&item.itemList.length===0}"
      class="w-100"
      @click="doClickListItem(item)"
      @dblclick="doDblclickListItem(item,(item.type==='group'?item.textList:item.text),(item.type==='group'?item.groupList:[]))">
      <v-icon
        v-if="item.type==='title'&&item.group"
        :style="item.type==='title'?('color:'+depthColor[item.depth-1]+';'):''">mdi-chevron-down-circle</v-icon>
      <v-icon
        v-if="item.type==='title'&&!item.group"
        :style="item.type==='title'?('color:'+depthColor[item.depth-1]+';'):''">mdi-chevron-up-circle</v-icon>
      <span
        v-if="!(typeSubItemList.composition === 'alone'&&item.type==='title')"
        :style="item.type==='title'?('color:'+depthColor[item.depth-1]+';'):''">
        {{ item.type==='group'?'群組 [ '+item.text+' ] 全選':item.text }}
        <v-icon
          v-if="typeSubItemList.composition === 'alone'&&item.type!=='title'"
          title="獨立線組項目"
          class="rotate90 small text-muted">mdi-minus-circle-outline</v-icon>
      </span>
      <hr v-if="item.type==='title'">
    </span>
    <list-item-menu-selector
      v-for="(subitem,i) in item.itemList"
      :key="type+'-'+(item.depth+1)+'-'+i+'-'+item.group+'-'+item.text+'-'+item.type"
      :item="subitem"
      :is-sub-dblclick="isSubDblclick"
      :type="type"
      :depth-color="depthColor"
      :position="position.concat([i])"
      :type-keyword-list="typeKeywordList"
      :type-sub-item-list="typeSubItemList"
      :column-name="item.columnName"
      :group-mapping-column="item.groupMappingColumn"
      @add-sub-item-many-to-one="addSubItemManyToOne"
      @add-sub-item-one-to-one="addSubItemOneToOne"
      @do-change-is-sub-dblclick="doChangeIsSubDblclick"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'ListItemMenuSelector',
  props: {
    isSubDblclick: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: function () {
        return {}
      }
    },
    depthColor: {
      type: Array,
      default: function () {
        return []
      }
    },
    typeKeywordList: {
      type: String,
      default: ''
    },
    typeSubItemList: {
      type: Object,
      default: function () {
        return {}
      }
    },
    columnName: {
      type: String,
      default: ''
    },
    groupMappingColumn: {
      type: String,
      default: ''
    },
    type: {
      type: Number,
      default: null
    },
    position: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data () {
    return {}
  },
  computed: {
    ...mapState('app', ['theme'])
    // indent () {
    //   return {
    //     transform: `translate(${(this.depth + (this.firstIndent ? 0 : -1)) * 28}px)`
    //   }
    // }
  },
  watch: {},
  methods: {
    doGroupOpen (val) {
      let group = false
      if (val.type !== 'title' && val.group !== '') {
        let p = this.position.slice(0, -1)
        p.reduce((acc, curr) => acc.itemList[curr], this.typeSubItemList).itemList.forEach((i) => {
          if (val.group === i.value && i.type === 'title') {
            group = i.group
          }
        })
      } else {
        group = true
      }
      return group
    },
    doClickListItem (val) {
      if (val.type === 'title') {
        let p = this.position
        let target = p.reduce((acc, curr) => acc.itemList[curr], this.typeSubItemList)
        target.group = !target.group
      } else {
        this.$emit('do-change-is-sub-dblclick', false)
        this.$emit('add-sub-item-many-to-one', this.columnName, this.groupMappingColumn, val.value, val.group, val.text, val.rootGroupColumn, val.rootGroupValue, false, this.typeSubItemList.composition)
      }
    },
    doDblclickListItem (val, name, groupList) {
      if (val.type !== 'title' && this.typeSubItemList.composition === 'mix') {
        this.$emit('add-sub-item-one-to-one', this.columnName, this.groupMappingColumn, val.value, val.group, name, val.rootGroupColumn, val.rootGroupValue, groupList, this.typeSubItemList.composition)
      } else {
        this.$emit('do-change-is-sub-dblclick', true)
      }
    },
    doChangeIsSubDblclick (val) {
      this.$emit('do-change-is-sub-dblclick', val)
    },
    addSubItemManyToOne (columnName, groupMappingColumn, value, group, text, rootGroupColumn, rootGroupValue, multiple, composition) {
      this.$emit('add-sub-item-many-to-one', columnName, groupMappingColumn, value, group, text, rootGroupColumn, rootGroupValue, multiple, composition)
    },
    addSubItemOneToOne (columnName, groupMappingColumn, value, group, text, rootGroupColumn, rootGroupValue, composition) {
      this.$emit('add-sub-item-one-to-one', columnName, groupMappingColumn, value, group, text, rootGroupColumn, rootGroupValue, composition)
    }
  }
}
</script>
<style></style>
