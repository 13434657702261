<template>
  <v-container
    lcd
    fluid
    grid-list-xl
    pt-0
  >
    <v-layout wrap>
      <v-flex
        xs12
      >
        <material-card
          :class="{'card-shrink-active': shrink_1, 'card-shrink-70': shrink_1}"
          title="Lcd Calibration Analyzer"
          class="card-shrink card-padding v-card__first"
          color="general"
        >
          <v-icon
            :class="{'card-shrink-active-icon' : shrink_1}"
            @click="shrink_1=!shrink_1"
          >mdi-apple-keyboard-control</v-icon>
          <v-form>
            <v-container
              py-0
              class="mw-100">
              <v-layout wrap>
                <v-flex
                  flex-small
                  mt-3
                  xs12
                  sm6
                  lg3>
                  <v-autocomplete
                    v-model="factory"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="factoryMenu"
                    label="Factory"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex
                  flex-small
                  mt-3
                  xs12
                  sm6
                  lg3>
                  <v-autocomplete
                    v-model="project"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="projectMenu"
                    label="Project"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex
                  flex-small
                  mt-3
                  d-flex
                  align-items-center
                  xs12
                  lg5>
                  <p class="m-0">Time Interval</p>
                  <v-menu
                    v-model="rangeStartDateMenu"
                    :close-on-content-click="false"
                    :nudge-left="40"
                    lazy
                    transition="scale-transition"
                    max-width="290px"
                    offset-y
                    full-width
                  >
                    <v-text-field
                      slot="activator"
                      :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                      v-model="rangeStartDate"
                      label=""
                      prepend-icon="event"
                    />
                    <v-date-picker
                      v-model="rangeStartDate"
                      :min="startdate"
                      :max="new Date() | date-format"
                      :color="theme==='dark'?'danger':'general'"
                      type="date"
                      no-title
                      scrollable
                      actions/>
                  </v-menu>
                  <!-- <v-menu
                    v-model="startTimeMenu"
                    :close-on-content-click="false"
                    :nudge-left="40"
                    lazy
                    transition="scale-transition"
                    style="max-width: 100px;margin-left: 10px;"
                    max-width="290px"
                    offset-y
                    full-width
                  >
                    <v-text-field
                      slot="activator"
                      :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                      v-model="startTime"
                      label=""
                      prepend-icon=""
                    />
                    <v-time-picker
                      v-model="startTime"
                      :color="theme==='dark'?'error':'general'"
                      :allowed-minutes="m => m % 30 === 0"
                      format="24hr"
                      type="time"/>
                  </v-menu> -->
                  <p class="m-0 text-center px-3">到</p>
                  <v-menu
                    v-model="rangeEndDateMenu"
                    :close-on-content-click="false"
                    :nudge-left="40"
                    lazy
                    transition="scale-transition"
                    max-width="290px"
                    offset-y
                    full-width
                  >
                    <v-text-field
                      slot="activator"
                      :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                      v-model="rangeEndDate"
                      label=""
                      prepend-icon="event"
                    />
                    <v-date-picker
                      v-model="rangeEndDate"
                      :min="rangeStartDate"
                      :max="new Date() | date-format"
                      :color="theme==='dark'?'danger':'general'"
                      type="date"
                      no-title
                      scrollable
                      actions/>
                  </v-menu>
                  <!-- <v-menu
                    v-model="endTimeMenu"
                    :close-on-content-click="false"
                    :nudge-left="40"
                    lazy
                    transition="scale-transition"
                    style="max-width: 100px;margin-left: 10px;"
                    max-width="290px"
                    offset-y
                    full-width
                  >
                    <v-text-field
                      slot="activator"
                      :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                      v-model="endTime"
                      label=""
                      prepend-icon=""
                    />
                    <v-time-picker
                      v-model="endTime"
                      :color="theme==='dark'?'error':'general'"
                      :allowed-minutes="m => m % 30 === 0"
                      format="24hr"
                      type="time"/>
                  </v-menu> -->
                </v-flex>
                <v-flex
                  flex-small
                  mt-3
                  xs12
                  lg1
                  text-xs-right
                  d-flex
                  align-items-center
                >
                  <v-btn
                    v-if="shrink_1"
                    class="mx-0 font-weight-light"
                    color="general"
                    @click="doSubmit()"
                  >
                    <v-icon class="mr-2">mdi-backup-restore</v-icon>查詢
                  </v-btn>
                </v-flex>
                <v-flex
                  flex-small
                  border-primary
                  xs12
                  lg12
                  style="border-top: 2px dashed;"/>
                <v-flex
                  flex-small
                  xs12
                  sm6
                  lg2>
                  <v-text-field
                    v-model="biosSn"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    label="BIOS SN"/>
                </v-flex>
                <v-flex
                  flex-small
                  xs12
                  sm6
                  lg3>
                  <v-text-field
                    v-model="i1Sn"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    label="i1 SN"/>
                </v-flex>
                <v-flex
                  flex-small
                  xs12
                  sm6
                  lg1>
                  <v-text-field
                    v-model="prodid"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    label="PROD ID"/>
                </v-flex>
                <v-flex
                  flex-small
                  xs12
                  sm6
                  lg1>
                  <v-text-field
                    v-model="mfrid"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    label="MFR ID"/>
                </v-flex>
                <v-flex
                  flex-small
                  xs12
                  sm6
                  lg1>
                  <v-text-field
                    v-model="mfrdate"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    label="MFR DATE"/>
                </v-flex>
                <v-flex
                  flex-small
                  xs12
                  sm6
                  lg4>
                  <v-text-field
                    v-model="errorCode"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    label="Error Code"/>
                </v-flex>
                <!-- <v-flex
                  flex-small
                  xs12
                  sm6
                  lg2>
                  <v-datetime-picker
                    v-model="datetime"
                    :text-field-props="textFieldProps"
                    :date-picker-props="dateProps"
                    :time-picker-props="timeProps"
                    time-format="HH:mm:ss"
                    class="datetime"
                  />
                </v-flex> -->
                <v-flex
                  flex-small
                  xs12
                  lg3>
                  <v-autocomplete
                    v-model="yRangeType"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="[
                      { text: 'Red', value: 'Red' },
                      { text: 'Green', value: 'Green' },
                      { text: 'Blue', value: 'Blue' },
                      { text: 'White', value: 'White' }
                    ]"
                    label="R/G/B/W of Y[Range]"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex
                  flex-small
                  d-flex
                  align-items-center
                  xs12
                  lg4>
                  <p
                    class="m-0"
                    style="width: 140px;">Y[Range]</p>
                  <v-text-field
                    v-model="yRangeStart"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    label=""/>
                  <p class="m-0 text-center px-3">到</p>
                  <v-text-field
                    v-model="yRangeEnd"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    label=""/>
                </v-flex>
                <v-flex
                  flex-small
                  xs12
                  lg5/>
                <v-flex
                  flex-small
                  xs12
                  lg3>
                  <v-autocomplete
                    v-model="xyCoordinateRangeType"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="[
                      { text: 'Red', value: 'Red' },
                      { text: 'Green', value: 'Green' },
                      { text: 'Blue', value: 'Blue' },
                      { text: 'White', value: 'White' }
                    ]"
                    label="R/G/B/W of(x,y)"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex
                  flex-small
                  d-flex
                  align-items-center
                  xs12
                  lg4>
                  <p
                    class="m-0"
                    style="width: 140px;">(x[Range])coordinate</p>
                  <v-text-field
                    v-model="xCoordinateRangeStart"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    label=""/>
                  <p class="m-0 text-center px-3">到</p>
                  <v-text-field
                    v-model="xCoordinateRangeEnd"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    label=""/>
                </v-flex>
                <v-flex
                  flex-small
                  d-flex
                  align-items-center
                  xs12
                  lg4>
                  <p
                    class="m-0"
                    style="width: 140px;">(y[Range])coordinate</p>
                  <v-text-field
                    v-model="yCoordinateRangeStart"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    label=""/>
                  <p class="m-0 text-center px-3">到</p>
                  <v-text-field
                    v-model="yCoordinateRangeEnd"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    label=""/>
                </v-flex>
                <v-flex
                  flex-small
                  xs12
                  lg1/>
                <v-flex
                  flex-small
                  xs12
                  lg3/>
                <v-flex
                  flex-small
                  d-flex
                  align-items-center
                  xs12
                  lg4>
                  <p
                    class="m-0"
                    style="width: 140px;">Gamma(Range)</p>
                  <v-text-field
                    v-model="gammaRangeStart"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    label=""/>
                  <p class="m-0 text-center px-3">到</p>
                  <v-text-field
                    v-model="gammaRangeEnd"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    label=""/>
                </v-flex>
                <v-flex
                  flex-small
                  d-flex
                  align-items-center
                  xs12
                  lg4>
                  <p
                    class="m-0"
                    style="width: 140px;">Gamma Diff</p>
                  <v-text-field
                    v-model="gammaDiffRangeStart"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    label=""/>
                  <p class="m-0 text-center px-3">到</p>
                  <v-text-field
                    v-model="gammaDiffRangeEnd"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    label=""/>
                </v-flex>
                <v-flex
                  flex-small
                  xs12
                  lg1
                  text-xs-right
                  d-flex
                  align-items-center
                >
                  <v-btn
                    class="mx-0 font-weight-light"
                    color="general"
                    @click="doSubmit()"
                  >
                    <v-icon class="mr-2">mdi-backup-restore</v-icon>查詢
                  </v-btn>
                </v-flex>
                <v-flex
                  xs12
                  lg12
                />
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
      </v-flex>
      <v-flex
        v-if="submit"
        xs12
        sm6
        lg3>
        <material-stats-card
          v-model="passAmount"
          class="v-offset-none text-success"
          style="margin:0 !important;"
          color="purple"
          icon="mdi-folder-open"
          title="Test Pass"/>
      </v-flex>
      <v-flex
        v-if="submit"
        xs12
        sm6
        lg3>
        <material-stats-card
          v-model="failAmount"
          class="v-offset-none text-danger"
          style="margin:0 !important;"
          color="indigo"
          icon="mdi-calendar"
          title="Test Fail"/>
      </v-flex>
      <v-flex
        v-if="submit"
        xs12
        sm6
        lg3>
        <material-stats-card
          v-model="totalTestInputs"
          class="v-offset-none text-info"
          style="margin:0 !important;"
          color="light-blue"
          icon="mdi-factory"
          title="Test Total"/>
      </v-flex>
      <v-flex
        v-if="submit"
        xs12
        sm6
        lg3>
        <material-stats-card
          :value="(totalTestInputs>0?((Number(passAmount)/Number(totalTestInputs) * 100).toFixed(1)):0) + '%'"
          :class="(Number(passAmount)/Number(totalTestInputs)<0.8)?'red':'green'"
          :color="(Number(passAmount)/Number(totalTestInputs)<0.8)?'pink':'light-green'"
          :icon="(Number(passAmount)/Number(totalTestInputs)<0.8)?'mdi-alert':'mdi-check'"
          class="title-color"
          style="margin:0 !important;"
          title="Test Pass Rate"/>
      </v-flex>
      <v-flex
        v-if="submit"
        flex_width_auto
        xs12>
        <material-card
          id="checkTable"
          :style="'max-width: calc(100vw - '+(Number(shrink)+32)+'px);'"
          title="查詢結果列表"
          color="deep-orange"
          class="card-mix card-padding">
          <v-select
            v-model="pagination.current.rowsPerPage"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="rowsAmount"
            class="mix-list v-text-field__slot d-inline-block float-right ml-5"
            style="width: 90px;"
            persistent-hint
            outlined
          />
          <router-link
            v-if="responseData.length>0"
            :to="'/lcd/lcdCalibrationAnalyzer/showLcdCalibration2DXYScatterPlotView'"
            class="mix-btn p-0"
            style="right: 54.5rem;"
            target="_blank">
            <v-btn class="black text-light primary mx-0 px-2">
              <v-icon class="float-left mr-1">mdi-chart-line</v-icon>2D(x,y)
            </v-btn>
          </router-link>
          <router-link
            v-if="responseData.length>0"
            :to="'/lcd/lcdCalibrationAnalyzer/showLcdCalibrationLuminanceYBarChartView'"
            class="mix-btn p-0"
            style="right: 44.5rem;"
            target="_blank">
            <v-btn class="black text-light primary mx-0 px-2">
              <v-icon class="float-left mr-1">mdi-chart-bar</v-icon>Luminance(Y)
            </v-btn>
          </router-link>
          <router-link
            v-if="responseData.length>0"
            :to="'/lcd/lcdCalibrationAnalyzer/showLcdCalibrationDeltaEDistributionBarChartView'"
            class="mix-btn p-0"
            style="right: 31.5rem;"
            target="_blank">
            <v-btn class="black text-light primary mx-0 px-2">
              <v-icon class="float-left mr-1">mdi-chart-bar</v-icon>Delta E Distribution
            </v-btn>
          </router-link>
          <router-link
            v-if="responseData.length>0"
            :to="'/lcd/lcdCalibrationAnalyzer/showLcdCalibrationGammaDiffBarChartView'"
            class="mix-btn p-0"
            style="right: 22.5rem;"
            target="_blank">
            <v-btn class="black text-light primary mx-0 px-2">
              <v-icon class="float-left mr-1">mdi-chart-bar</v-icon>Gamma Diff
            </v-btn>
          </router-link>
          <router-link
            v-if="responseData.length>0"
            :to="'/lcd/lcdCalibrationAnalyzer/showLcdCalibrationGammaBarChartView'"
            class="mix-btn p-0"
            style="right: 15.5rem;"
            target="_blank">
            <v-btn class="black text-light primary mx-0 px-2">
              <v-icon class="float-left mr-1">mdi-chart-bar</v-icon>Gamma
            </v-btn>
          </router-link>
          <v-btn
            v-if="responseData.length>0"
            class="mix-btn black text-light"
            style="right: 8rem;"
            @click="downloadCSV()">
            <v-icon class="float-left mr-1">mdi-download</v-icon>下載CSV
          </v-btn>
          <v-autocomplete
            id="checkTableInput"
            v-model="checkTableShowList"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="[
              { text: 'MeasureLoop', value: 'measureLoop' },
              { text: 'Validation0', value: 'validationType0' },
              { text: 'Validation1', value: 'validationType1' },
              { text: 'WriteBIOS', value: 'writeBios' },
              { text: 'PanelIndex', value: 'panelIndex' },
              { text: 'Luminance of White', value: 'luminanceOfWhite' },
              { text: 'Luminance of Black', value: 'luminanceOfBlack' },
              { text: 'Luminance of Red', value: 'luminanceOfRed' },
              { text: 'Luminance of Green', value: 'luminanceOfGreen' },
              { text: 'Luminance of Blue', value: 'luminanceOfBlue' },
              { text: 'CalibrationWY', value: 'calibratedWy' },
              { text: 'CalibratedGamma', value: 'calibratedGamma' },
              { text: 'Gamma', value: 'gamma' },
              { text: 'GammaL', value: 'gammaLow' },
              { text: 'GammaH', value: 'gammaHigh' },
              { text: 'Gamma_Diff', value: 'gammaDiff' },
              { text: 'Contrast', value: 'contrast' },
              { text: 'R/G/B/W', value: 'assignColor' },
              { text: 'x of coordinate', value: 'xcoordinate' },
              { text: 'y of coordinate', value: 'ycoordinate' },
              { text: 'ProjectTargetWx', value: 'projectTargetWx' },
              { text: 'ProjectTargetWy', value: 'projectTargetWy' },
              { text: 'ProjectYLimit', value: 'projectYLimit' },
              { text: 'ImprovedTargetWx', value: 'improvedTargetWx' },
              { text: 'ImprovedTargetWy', value: 'improvedTargetWy' },
              { text: 'PredImprovedTargetWx', value: 'predictImprovedTargetWx' },
              { text: 'PredImprovedTargetWy', value: 'predictImprovedTargetWy' },
              { text: 'MeasImprovedTargetWx', value: 'measureImprovedTargetWx' },
              { text: 'MeasImprovedTargetWy', value: 'measureImprovedTargetWy' },
              { text: 'MeasImprovedTargetWY', value: 'measureImprovedTargetWYY' },
              { text: 'FinalTargetWx', value: 'finalTargetWx' },
              { text: 'FinalTargetWy', value: 'finalTargetWy' },
              { text: 'FinalWx', value: 'finalWx' },
              { text: 'FinalWy', value: 'finalWy' },
              { text: 'FinalWY', value: 'finalWYY' },
              { text: 'CalibratedWhiteDistanceFail', value: 'calibratedWhiteDistanceFail' },
              { text: 'DeltaE_ScoreAvg', value: 'deltaEScoreAvg' },
              { text: 'DeltaE_Score', value: 'deltaEScore' },
              { text: 'CCT', value: 'cct' },
              { text: 'CCT_Gamma', value: 'cctGamma' },
              { text: 'BiosString', value: 'biosString' },
              { text: 'X/Y/Z Info', value: 'xyzInfo' }
            ]"
            label="選擇欄位"
            class="v-text-field__slot row-btn-175 pt-0 mt-3"
            color="white"
            multitude
            chips
            persistent-hint
            multiple
            outlined
          />
          <v-btn
            class="red text-light float-right mr-0 mt-4"
            @click="checkTableShowListClear()"
          >
            清除
          </v-btn>
          <v-btn
            class="blue text-light float-right mt-4"
            @click="checkTableShowListAll()"
          >
            全選
          </v-btn>
        </material-card>
        <material-card
          :style="'max-width: calc(100vw - '+(Number(shrink)+32)+'px);'"
          class="d-table-t0 no-data-left">
          <!-- <v-select
            v-model="pagination.rowsPerPage"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="rowsAmount"
            class="v-text-field__slot d-inline-block float-right ml-5"
            style="width: 90px;"
            persistent-hint
            outlined
          /> -->
          <v-data-table
            :headers="responseHeaders"
            :items="responseData"
            :pagination.sync="pagination.current"
            :rows-per-page-items="rowsAmount"
            :class="{ 'showList': checkTableShowList.length>0}"
            class="freeze-title height-row-3"
            multi-sort
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="subheading font-weight-light text-general text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <tr>
                <td class="table-width-150">{{ item.deviceIsn }}</td>
                <td class="table-width-185">{{ item.launchTime }}</td>
                <td class="table-width-185">{{ item.traceStartTime }}</td>
                <td class="table-width-185">{{ item.traceEndTime }}</td>
                <td class="table-width-100">{{ item.apVersion }}</td>
                <td class="table-width-125">{{ item.biosId }}</td>
                <td class="table-width-90">{{ item.mfrId }}</td>
                <td class="table-width-100">{{ item.prodId }}</td>
                <td class="table-width-110">{{ item.mfrDate }}</td>
                <td class="table-width-175">{{ item.i1SerialNo }}</td>
                <td class="table-width-90">{{ item.endResult }}</td>
                <td class="table-width-300">
                  <v-btn
                    class="mx-0 mr-3 font-weight-bold small"
                    color="orange"
                    @click="downloadHitTraceLog(item.testResultPk)">
                    <v-icon class="mr-2">mdi-download</v-icon>HITTrace LOG
                  </v-btn>
                  <v-btn
                    class="mx-0 font-weight-bold small"
                    color="orange"
                    @click="downloadNBreport(item.testResultPk)">
                    <v-icon class="mr-2">mdi-download</v-icon>NB Report
                  </v-btn>
                </td>
                <td
                  v-for="(col, index) in responseTableShowList"
                  v-show="responseTableShowList[index]"
                  :class="responseColumnClassList[index]+' text-xs-'+responseAlignList[index]"
                  :key="'col'+index"
                >
                  {{ item[index] }}
                  <router-link
                    v-if="index==='xyzInfo'&&item.testResultPk"
                    :to="'/lcd/lcdCalibrationAnalyzer/showLcdCalibrationXYZInfoView/' + item.testResultPk"
                    target="_blank">
                    <v-btn
                      class="mx-0 mr-3 font-weight-light small"
                      color="green">
                      <v-icon class="mr-2">mdi-file-find</v-icon>X/Y/Z Info
                    </v-btn>
                  </router-link>
                </td>
              </tr>
            </template>
          </v-data-table>
          <div
            v-if="pagination.current.rowsPerPage != -1"
            class="text-xs-center pt-2 d-inline-block"
            style="margin-left: 0px;width: calc(100% - 140px);">
            <v-pagination
              v-model="pagination.page"
              :length="pages"/>
          </div>
          <v-autocomplete
            v-if="pagination.current.rowsPerPage != -1"
            v-model="pagination.page"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="pageslist"
            class="v-text-field__slot d-inline-block float-right mr-0"
            style="width: 90px;"
            persistent-hint
            outlined
          />
        </material-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="processingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        PROCESSING 處理中
      </p>
    </v-dialog>
    <v-dialog
      v-model="loadingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :top="true"
      :color="snackbarColor">
      {{ snackbarMsg }}
      <v-btn
        dark
        flat
        @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
// import Vue from 'vue'
// import VueC3 from 'vue-c3'
// import 'c3/c3.min.css'
import { mapState } from 'vuex'
import Loading from '@/assets/img/loading.gif'

export default {
  name: 'LcdCalibrationAnalyzer',
  // components: {
  //   VueC3
  // },
  data () {
    return {
      startdate: '2018-04-01',
      snackbar: false,
      snackbarColor: 'red',
      snackbarMsg: '',
      Loading,
      loadingView: false,
      processingView: false,
      // summaryCaseName: '',
      // summaryDate: '',
      // summaryEMS: '',
      // summaryEMSpk: 0,
      // summaryRange: '5',
      // summaryItem: 'CPU_TEMP',
      summaryTableShowList: [],
      // nullDatetime: null,
      // datetime: new Date(),
      // datetimeString: '2019-01-01 12:00',
      // formattedDatetime: '09/01/2019 12:00',
      // textFieldProps: {
      //   appendIcon: 'event'
      // },
      // dateProps: {
      //   headerColor: 'red'
      // },
      // timeProps: {
      //   useSeconds: false,
      //   ampmInTitle: true,
      //   format: '24hr',
      //   allowedMinutes: m => m % 30 === 0
      // },
      shrink_1: true,
      rangeStartDateMenu: false,
      rangeStartDate: this.$moment(new Date()).format('YYYY-MM-DD'),
      rangeEndDateMenu: false,
      rangeEndDate: this.$moment(new Date()).format('YYYY-MM-DD'),
      factory: '',
      factoryMenu: [],
      project: '',
      projectMenu: [],
      biosSn: '',
      i1Sn: '',
      prodid: '',
      mfrid: '',
      mfrdate: '',
      errorCode: '',
      yRangeType: '',
      yRangeStart: '',
      yRangeEnd: '',
      xyCoordinateRangeType: '',
      xCoordinateRangeStart: '',
      xCoordinateRangeEnd: '',
      yCoordinateRangeStart: '',
      yCoordinateRangeEnd: '',
      gammaRangeStart: '',
      gammaRangeEnd: '',
      gammaDiffRangeStart: '',
      gammaDiffRangeEnd: '',
      // RangeAll: true,
      // RangeIndex: 999,
      // RangeMin: 0,
      // RangeMax: 9999,
      // RangeMaxList: {
      //   CPU_TEMP: 200,
      //   GPU_TEMP: 200,
      //   CPU_POWER: 200,
      //   GPU_POWER: 200
      // },
      // OverRangeCount: 0,
      // RangeCount: 1,
      passAmount: '0',
      failAmount: '0',
      totalTestInputs: '0',
      submit: false,
      IsSubmitPage: true,
      responseData: [],
      responseDataOnly: [],
      responseColumnDefine: [
        {
          align: '',
          class: 'table-width-150',
          fixed: true,
          sortable: true,
          text: 'BiosSN',
          value: 'deviceIsn'
        },
        {
          align: '',
          class: 'table-width-185',
          fixed: true,
          sortable: true,
          text: 'Launchtime',
          value: 'launchTime'
        },
        {
          align: '',
          class: 'table-width-185',
          fixed: true,
          sortable: true,
          text: 'Starttime',
          value: 'traceStartTime'
        },
        {
          align: '',
          class: 'table-width-185',
          fixed: true,
          sortable: true,
          text: 'Endtime',
          value: 'traceEndTime'
        },
        {
          align: '',
          class: 'table-width-100',
          fixed: true,
          sortable: true,
          text: 'Apver',
          value: 'apVersion'
        },
        {
          align: '',
          class: 'table-width-125',
          fixed: true,
          sortable: true,
          text: 'Biosid',
          value: 'biosId'
        },
        {
          align: '',
          class: 'table-width-90',
          fixed: true,
          sortable: true,
          text: 'MFRID',
          value: 'mfrId'
        },
        {
          align: '',
          class: 'table-width-100',
          fixed: true,
          sortable: true,
          text: 'PRODID',
          value: 'prodId'
        },
        {
          align: '',
          class: 'table-width-110',
          fixed: true,
          sortable: true,
          text: 'MFRDATE',
          value: 'mfrDate'
        },
        {
          align: '',
          class: 'table-width-175',
          fixed: true,
          sortable: true,
          text: 'i1_SN',
          value: 'i1SerialNo'
        },
        {
          align: '',
          class: 'table-width-110',
          fixed: true,
          sortable: true,
          text: 'ErrorCode',
          value: 'endResult'
        },
        {
          align: '',
          class: 'table-width-300',
          fixed: true,
          sortable: true,
          text: '操作',
          value: ''
        },
        {
          align: '',
          class: 'table-width-150',
          fixed: false,
          sortable: true,
          text: 'MeasureLoop',
          value: 'measureLoop'
        },
        {
          align: '',
          class: 'table-width-150',
          fixed: false,
          sortable: true,
          text: 'Validation0',
          value: 'validationType0'
        },
        {
          align: '',
          class: 'table-width-150',
          fixed: false,
          sortable: true,
          text: 'Validation1',
          value: 'validationType1'
        },
        {
          align: '',
          class: 'table-width-150',
          fixed: false,
          sortable: true,
          text: 'WriteBIOS',
          value: 'writeBios'
        },
        {
          align: '',
          class: 'table-width-150',
          fixed: false,
          sortable: true,
          text: 'PanelIndex',
          value: 'panelIndex'
        },
        {
          align: '',
          class: 'table-width-150',
          fixed: false,
          sortable: true,
          text: 'ErrorCode',
          value: 'endResult'
        },
        {
          align: '',
          class: 'table-width-200',
          fixed: false,
          sortable: true,
          text: 'Luminance of White',
          value: 'luminanceOfWhite'
        },
        {
          align: '',
          class: 'table-width-185',
          fixed: false,
          sortable: true,
          text: 'Luminance of Black',
          value: 'luminanceOfBlack'
        },
        {
          align: '',
          class: 'table-width-185',
          fixed: false,
          sortable: true,
          text: 'Luminance of Red',
          value: 'luminanceOfRed'
        },
        {
          align: '',
          class: 'table-width-200',
          fixed: false,
          sortable: true,
          text: 'Luminance of Green',
          value: 'luminanceOfGreen'
        },
        {
          align: '',
          class: 'table-width-185',
          fixed: false,
          sortable: true,
          text: 'Luminance of Blue',
          value: 'luminanceOfBlue'
        },
        {
          align: '',
          class: 'table-width-150',
          fixed: false,
          sortable: true,
          text: 'CalibrationWY',
          value: 'calibratedWy'
        },
        {
          align: '',
          class: 'table-width-185',
          fixed: false,
          sortable: true,
          text: 'CalibratedGamma',
          value: 'calibratedGamma'
        },
        {
          align: '',
          class: 'table-width-100',
          fixed: false,
          sortable: true,
          text: 'Gamma',
          value: 'gamma'
        },
        {
          align: '',
          class: 'table-width-100',
          fixed: false,
          sortable: true,
          text: 'GammaL',
          value: 'gammaLow'
        },
        {
          align: '',
          class: 'table-width-110',
          fixed: false,
          sortable: true,
          text: 'GammaH',
          value: 'gammaHigh'
        },
        {
          align: '',
          class: 'table-width-135',
          fixed: false,
          sortable: true,
          text: 'Gamma_Diff',
          value: 'gammaDiff'
        },
        {
          align: '',
          class: 'table-width-100',
          fixed: false,
          sortable: true,
          text: 'Contrast',
          value: 'contrast'
        },
        {
          align: '',
          class: 'table-width-110',
          fixed: false,
          sortable: true,
          text: 'R/G/B/W',
          value: 'assignColor'
        },
        {
          align: '',
          class: 'table-width-150',
          fixed: false,
          sortable: true,
          text: 'x of coordinate',
          value: 'xcoordinate'
        },
        {
          align: '',
          class: 'table-width-150',
          fixed: false,
          sortable: true,
          text: 'y of coordinate',
          value: 'ycoordinate'
        },
        {
          align: '',
          class: 'table-width-175',
          fixed: false,
          sortable: true,
          text: 'ProjectTargetWx',
          value: 'projectTargetWx'
        },
        {
          align: '',
          class: 'table-width-175',
          fixed: false,
          sortable: true,
          text: 'ProjectTargetWy',
          value: 'projectTargetWy'
        },
        {
          align: '',
          class: 'table-width-150',
          fixed: false,
          sortable: true,
          text: 'ProjectYLimit',
          value: 'projectYLimit'
        },
        {
          align: '',
          class: 'table-width-185',
          fixed: false,
          sortable: true,
          text: 'ImprovedTargetWx',
          value: 'improvedTargetWx'
        },
        {
          align: '',
          class: 'table-width-185',
          fixed: false,
          sortable: true,
          text: 'ImprovedTargetWy',
          value: 'improvedTargetWy'
        },
        {
          align: '',
          class: 'table-width-225',
          fixed: false,
          sortable: true,
          text: 'PredImprovedTargetWx',
          value: 'predictImprovedTargetWx'
        },
        {
          align: '',
          class: 'table-width-225',
          fixed: false,
          sortable: true,
          text: 'PredImprovedTargetWy',
          value: 'predictImprovedTargetWy'
        },
        {
          align: '',
          class: 'table-width-225',
          fixed: false,
          sortable: true,
          text: 'MeasImprovedTargetWx',
          value: 'measureImprovedTargetWx'
        },
        {
          align: '',
          class: 'table-width-225',
          fixed: false,
          sortable: true,
          text: 'MeasImprovedTargetWy',
          value: 'measureImprovedTargetWy'
        },
        {
          align: '',
          class: 'table-width-225',
          fixed: false,
          sortable: true,
          text: 'MeasImprovedTargetWY',
          value: 'measureImprovedTargetWYY'
        },
        {
          align: '',
          class: 'table-width-150',
          fixed: false,
          sortable: true,
          text: 'FinalTargetWx',
          value: 'finalTargetWx'
        },
        {
          align: '',
          class: 'table-width-150',
          fixed: false,
          sortable: true,
          text: 'FinalTargetWy',
          value: 'finalTargetWy'
        },
        {
          align: '',
          class: 'table-width-100',
          fixed: false,
          sortable: true,
          text: 'FinalWx',
          value: 'finalWx'
        },
        {
          align: '',
          class: 'table-width-100',
          fixed: false,
          sortable: true,
          text: 'FinalWy',
          value: 'finalWy'
        },
        {
          align: '',
          class: 'table-width-100',
          fixed: false,
          sortable: true,
          text: 'FinalWY',
          value: 'finalWYY'
        },
        {
          align: '',
          class: 'table-width-275',
          fixed: false,
          sortable: true,
          text: 'CalibratedWhiteDistanceFail',
          value: 'calibratedWhiteDistanceFail'
        },
        {
          align: '',
          class: 'table-width-175',
          fixed: false,
          sortable: true,
          text: 'DeltaE_ScoreAvg',
          value: 'deltaEScoreAvg'
        },
        {
          align: '',
          class: 'table-width-200',
          fixed: false,
          sortable: true,
          text: 'DeltaE_Score',
          value: 'deltaEScore'
        },
        {
          align: '',
          class: 'table-width-200',
          fixed: false,
          sortable: true,
          text: 'CCT',
          value: 'cct'
        },
        {
          align: '',
          class: 'table-width-200',
          fixed: false,
          sortable: true,
          text: 'CCT_Gamma',
          value: 'cctGamma'
        },
        {
          align: '',
          class: 'table-width-200',
          fixed: false,
          sortable: true,
          text: 'BiosString',
          value: 'biosString'
        },
        {
          align: '',
          class: 'table-width-150',
          fixed: false,
          sortable: true,
          text: 'X/Y/Z Info',
          value: 'xyzInfo'
        }
      ],
      responseHeaders: [],
      responseHeadersOnly: [],
      responseHeadersList: [],
      responseColumnClassList: {},
      responseAlignList: {},
      responseTableShowList: {},
      responseTableShowListOnly: {},
      responseAreaData: {
        commonFailQty: 0,
        commonFailRate: 0,
        failQty: 0,
        failRate: 0,
        fanErrorCodeList: [],
        fanFailQty: 0,
        fanFailRate: 0,
        noDefineFailQty: 0,
        passQty: 0,
        passRate: 0,
        productionQty: 0,
        thermalFailQty: 0,
        thermalFailRate: 0,
        warningColor: 'green'
      },
      checkList: [],
      checkTableShowList: [],
      // lineXaxisName: [],
      // lineXaxisValue: [],
      rowsAmount: [10, 25, 50, 100],
      pagination: {
        page: 1,
        current: {
          descending: null,
          page: 1,
          rowsPerPage: 25,
          sortBy: null,
          totalItems: 0
        }
      },
      pages: 1
      // handler_bar: new Vue()
    }
  },
  computed: {
    ...mapState('app', ['theme', 'shrink']),
    // pages () {
    //   if(this.responseData.length > 0) {
    //     return this.pagination.rowsPerPage ? Math.ceil(this.responseData.length / this.pagination.rowsPerPage) : 10
    //   }
    // },
    pageslist () {
      let i = 0
      const list = []
      if (this.pagination.current.rowsPerPage) {
        for (i = 0; i < Math.ceil(this.pagination.current.totalItems / this.pagination.current.rowsPerPage); i++) {
          list.push(i + 1)
        }
      }
      return list
    }
    // options_bar () {
    //   return {
    //     data: {
    //       x: 'x',
    //       columns: [
    //         this.barXaxisName,
    //         this.barXaxisValue
    //       ],
    //       labels: true,
    //       // labels: {
    //       //   // format: function (v, id, i, j) { return v + '%' }
    //       // },
    //       // onclick: function (d) { alert(d.index) },
    //       onclick: (d) => { this.summaryList(d.index, 'range') },
    //       type: 'bar',
    //       types: {
    //         'NormalDistribution': 'spline'
    //       },
    //       axes: {
    //         'ModelName': 'y'
    //       }
    //       // colors: {
    //       //   ModelName: '#999999'
    //       // }
    //       // onclick: (d, i) => { this.summaryList(d, i) } // {id: "data23", value: 120, ratio: 0.8, index: 1, name: "data23"}
    //       // color: function (color, d) {
    //       //   // d will be 'id' when called for legends
    //       //   return d.value >= 5.0 ? '#ff0000' : d.value >= 3.0 ? '#ff9800' : color
    //       // }
    //       // color: (color, d) => {
    //       //   // d will be 'id' when called for legends
    //       //   return (this.OverRangeCount > 0 && d.index === this.barXaxisName.length - 2) ? '#ffa500' : color
    //       // }
    //     },
    //     axis: {
    //       x: {
    //         type: 'category', // this needed to load string x value
    //         tick: {
    //           rotate: -45,
    //           multiline: false
    //         }
    //         // label: {
    //         //   text: '期',
    //         //   position: 'outer-center'
    //         // }
    //       },
    //       y: {
    //         // max: this.RangeCount,
    //         // min: 0,
    //         // tick: {
    //         //   // count: this.RangeCount + 1
    //         // },
    //         // padding: {
    //         //   top: 0,
    //         //   bottom: 0
    //         // }
    //         // tick: {
    //         //   format: function (value) { return value + '%' }
    //         // }
    //         // label: {
    //         //   text: '%',
    //         //   position: 'outer-middle'
    //         // }
    //       },
    //       y2: {
    //         // show: true
    //         // label: {
    //         //   text: '億',
    //         //   position: 'outer-middle'
    //         // }
    //       }
    //     },
    //     tooltip: {
    //       format: {
    //         title (d) {
    //           // return `第 ${d} 期`
    //         },
    //         value (value, ratio, id) {
    //           // return `${value} 億`
    //         }
    //       }
    //     },
    //     grid: {
    //       x: {
    //         show: true
    //       },
    //       y: {
    //         show: true
    //       }
    //     },
    //     legend: {
    //       position: 'bottom',
    //       padding: 16,
    //       show: false
    //     },
    //     size: {
    //       // width: 640,
    //       height: 500
    //     }
    //   }
    // }
  },
  watch: {
    factory (val) {
      this.$http.get(`/api/lcd/analyzeMgmt/getSelectOptionData/project/${val}`).then(res => res.data)
      // this.$http.get(`http://rap2api.taobao.org/app/mock/277656/api/lcd/getSelectOptionData/project/${val}`).then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.projectMenu = res.data
          }
        })
    },
    rangeStartDate (val) {
      this.rangeStartDateMenu = false
      // this.startTimeMenu = true
    },
    // startTime (val) {
    //   if (this.startTimeMenuOperating > 0) {
    //     this.startTimeMenuOperating = 0
    //     this.startTimeMenu = false
    //   } else {
    //     this.startTimeMenuOperating = this.startTimeMenuOperating + 1
    //   }
    // },
    // startTimeMenu (val) {
    //   if (!val) {
    //     this.startTimeMenuOperating = 0
    //   }
    // },
    rangeEndDate (val) {
      this.rangeEndDateMenu = false
      // this.endTimeMenu = true
    },
    // endTime (val) {
    //   if (this.endTimeMenuOperating > 0) {
    //     this.endTimeMenuOperating = 0
    //     this.endTimeMenu = false
    //   } else {
    //     this.endTimeMenuOperating = this.endTimeMenuOperating + 1
    //   }
    // },
    // endTimeMenu (val) {
    //   if (!val) {
    //     this.endTimeMenuOperating = 0
    //   }
    // },
    checkTableShowList (val) {
      this.responseHeaders = JSON.parse(JSON.stringify(this.responseHeadersOnly))
      this.responseTableShowList = JSON.parse(JSON.stringify(this.responseTableShowListOnly))
      this.summaryTableShowList.forEach((s, index) => {
        this.checkTableShowList.forEach((c) => {
          this.responseTableShowList[c] = true
          if (s.value === c) {
            this.responseHeaders.push(this.responseHeadersList[index])
          }
        })
      })
    },
    // responseData (val) {
    //   this.pages = Math.ceil(this.totalRows / this.pagination.rowsPerPage)
    //   if (this.pages > 1) {
    //     setTimeout(() => {
    //       this.pagination.page = this.pages
    //     }, 0)
    //     setTimeout(() => {
    //       this.pagination.page = 1
    //     }, 100)
    //   } else {
    //     setTimeout(() => {
    //       this.pagination.rowsPerPage = 1
    //     }, 0)
    //     setTimeout(() => {
    //       this.pagination.rowsPerPage = this.perPageRows
    //     }, 100)
    //   }
    // },
    // perPageRows (val) {
    //   if (val === -1) {
    //     if (confirm('完整顯示將可能導致效能降低，是否仍完整顯示?')) {
    //       this.pagination.rowsPerPage = val
    //       this.pages = Math.ceil(this.responseData.length / this.pagination.rowsPerPage)
    //       this.pagination.page = 1
    //     } else {
    //       setTimeout(() => {
    //         this.perPageRows = this.pagination.rowsPerPage
    //       }, 100)
    //     }
    //   } else {
    //     this.pagination.rowsPerPage = val
    //     this.pages = Math.ceil(this.responseData.length / this.pagination.rowsPerPage)
    //     this.pagination.page = 1
    //   }
    // },
    pagination: {
      handler (newName, oldName) {
        if (this.submit && !this.IsSubmitPage) {
          this.doLcdCalibrationDataList()
        }
      },
      immediate: true,
      deep: true
    }
    // 'pagination.page' (val) {
    //   this.checkList = []
    // },
    // 'pagination.rowsPerPage' (val) {
    //   this.checkList = []
    // },
    // 'pagination.sortBy' (val) {
    //   this.checkList = []
    // },
    // 'pagination.descending' (val) {
    //   this.checkList = []
    // }
    // summaryDate (val) {
    //   this.summaryDateMenu = false
    // },
    // summaryItem (val) {
    //   this.doSummary()
    // },
    // summaryRange (val) {
    //   this.doSummary()
    // }
  },
  mounted () {
    this.$store.dispatch('getAuthorize', '/lcd/lcdCalibrationAnalyzer')
      .then(response => {
        if (response.status === 'success' && response.data.accessible) {
          JSON.parse(localStorage.getItem('moduleDataInitDate')).forEach((n) => {
            if (n.name === 'lcd') {
              this.startdate = n.value
            }
          })
          this.$http.get('/api/lcd/analyzeMgmt/getSelectOptionData/factory').then(res => res.data)
            .then(res => {
              if (res.status === 'success') {
                this.factoryMenu = res.data
              }
            })
          // const { modelname, date, emspk, ems } = this.$route.query
          // this.summaryCaseName = modelname || this.summaryCaseName
          // this.summaryDate = date || this.summaryDate
          // this.summaryEMSpk = emspk || this.summaryEMSpk
          // this.summaryEMS = ems || this.summaryEMS
          // this.doSubmit()
          this.doResponseColumnDefine()
        } else if (response.errCode === 'E10000002') {
          const routeData = this.$router.resolve({ path: '/noEnter/E10000002' })
          localStorage.setItem('errormsg', response.errMsg)
          window.location.assign(routeData.href)
        } else {
          const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
          window.location.assign(routeData.href)
        }
      })
      .catch(err => {
        console.log(err)
        const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
        window.location.assign(routeData.href)
      })
  },
  methods: {
    doResponseColumnDefine () {
      // this.responseHeaders.push({
      //   align: '',
      //   class: 'table-width-55',
      //   fixed: true,
      //   sortable: false,
      //   text: '標記',
      //   value: ''
      // })
      // this.responseHeadersOnly.push({
      //   align: '',
      //   class: 'table-width-55',
      //   fixed: true,
      //   sortable: false,
      //   text: '標記',
      //   value: ''
      // })
      this.responseHeadersList = []
      this.responseColumnDefine.forEach((r) => {
        if (r.fixed) {
          this.responseHeaders.push(r)
          this.responseHeadersOnly.push(r)
        } else {
          this.responseHeadersList.push(r)
          this.responseTableShowList[r.value] = r.fixed
          this.responseTableShowListOnly[r.value] = r.fixed
          this.responseColumnClassList[r.value] = r.class
          this.responseAlignList[r.value] = r.align
          this.summaryTableShowList.push({ text: r.text, value: r.value })
        }
      })
    },
    // doBeforeLink (TtrsPk, deviceSn) {
    //   this.$http.post('/api/thermal/getSingleDeviceBurnInDataStatistic?ttrsPk=' + TtrsPk).then(res => res.data)
    //     .then(res => {
    //       if (res.status === 'success') {
    //         let Groups = res.data.fanTestData.length
    //         const routeData = this.$router.resolve({ path: '/thermal/singleDeviceBurnInDataStatistic/' + TtrsPk + '/' + Groups, query: { sn: deviceSn, modelname: this.summaryCaseName, date: this.summaryDate, ems: this.summaryEMS } })
    //         window.open(routeData.href, '_blank')
    //       }
    //     })
    // },
    // doSummary (type) {
    //   this.barXaxisName = ['x']
    //   this.barXaxisValue = ['ModelName']
    //   let AllArray = []
    //   this.responseDataOnly.forEach((r) => {
    //     if (r[this.summaryItem] >= 0) {
    //       AllArray.push(r[this.summaryItem])
    //     }
    //   })
    //   this.RangeMax = Math.ceil(Math.max(...AllArray) / this.summaryRange) * this.summaryRange
    //   this.RangeMin = Math.floor(Math.min(...AllArray) / this.summaryRange) * this.summaryRange
    //   if (this.RangeMax > this.RangeMaxList[this.summaryItem]) {
    //     this.RangeMax = Math.min(this.RangeMax, this.RangeMaxList[this.summaryItem])
    //   }
    //   this.OverRangeCount = 0
    //   const RangeArray = []
    //   for (let i = this.RangeMin; i < this.RangeMax; i = i + this.summaryRange) {
    //     RangeArray[(i - this.RangeMin) / this.summaryRange] = 0
    //   }
    //   AllArray.forEach((r) => {
    //     if (r > this.RangeMaxList[this.summaryItem]) {
    //       this.OverRangeCount = this.OverRangeCount + 1
    //     } else {
    //       if (RangeArray[Math.floor((r - this.RangeMin) / this.summaryRange)] > 0) {
    //         RangeArray[Math.floor((r - this.RangeMin) / this.summaryRange)] = RangeArray[Math.floor((r - this.RangeMin) / this.summaryRange)] + 1
    //       } else {
    //         RangeArray[Math.floor((r - this.RangeMin) / this.summaryRange)] = 1
    //       }
    //     }
    //   })

    //   this.RangeCount = Math.max(...RangeArray)
    //   for (let i = 0; i < RangeArray.length; i++) {
    //     this.barXaxisValue.push(RangeArray[i] || 0)
    //     switch (true) {
    //       case (this.summaryRange === '5' || this.summaryRange === '10'):
    //         this.barXaxisName.push((this.RangeMin + (i * this.summaryRange)).toFixed(0) + '-' + (this.RangeMin + ((i + 1) * this.summaryRange)).toFixed(0))
    //         break
    //       case (this.summaryRange === '2.5' || this.summaryRange === '7.5'):
    //         this.barXaxisName.push((this.RangeMin + (i * this.summaryRange)).toFixed(1) + '-' + (this.RangeMin + ((i + 1) * this.summaryRange)).toFixed(1))
    //         break
    //       // default:
    //       // return
    //     }
    //   }
    //   if (this.OverRangeCount > 0) {
    //     this.barXaxisValue.push(this.OverRangeCount)
    //     this.barXaxisName.push('>' + this.RangeMaxList[this.summaryItem])
    //   }
    //   // RangeArray.forEach((r, index) => {
    //   //   console.log(r)
    //   //   console.log(index)
    //   //   this.barXaxisValue.push(r)
    //   //   this.barXaxisName.push((this.RangeMin + (index * this.summaryRange)) + '-' + (this.RangeMin + ((index + 1) * this.summaryRange)))
    //   // })
    //   if (type === 'init') {
    //     this.handler_bar.$emit('init', this.options_bar)
    //   } else {
    //     this.handler_bar.$emit('dispatch', chart => {
    //       const options = {
    //         columns: [
    //           this.barXaxisName,
    //           this.barXaxisValue
    //         ]
    //       }
    //       chart.load(options)
    //     })
    //   }
    // },
    checkTableShowListAll () {
      this.checkTableShowList = []
      this.summaryTableShowList.forEach((s) => {
        this.checkTableShowList.push(s.value)
      })
    },
    checkTableShowListClear () {
      this.checkTableShowList = []
    },
    doSubmit () {
      if (this.doSubmitVerification()) {
        this.loadingView = true
        this.doLcdCalibrationDataList()
        this.doLcdCalibrationSummaryInfo()
        this.doLcdCalibrationSaveFilterInfo()
        // setTimeout(() => {
        //   this.loadingView = false
        // }, 0)
      }
    },
    doSubmitVerification () {
      if (this.factory === '') {
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '請選擇Factory'
        return false
      } else if (this.project === '') {
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '請選擇Project'
        return false
      } else {
        return true
      }
    },
    doLcdCalibrationSaveFilterInfo () {
      const body = {
        factory: this.factory,
        factoryName: this.factoryMenu[this.factoryMenu.map(e => { return e.value }).indexOf(this.factory)].text || null,
        project: this.project,
        projectName: this.projectMenu[this.projectMenu.map(e => { return e.value }).indexOf(this.project)].text || null,
        startTime: this.rangeStartDate,
        endTime: this.rangeEndDate,
        biosSn: this.biosSn,
        errorCode: this.errorCode,
        i1Sn: this.i1Sn,
        mfrid: this.mfrid,
        mfrdate: this.mfrdate,
        prodid: this.prodid,
        yRangeStart: this.yRangeStart,
        yRangeEnd: this.yRangeEnd,
        yRangeType: this.yRangeType,
        gammaRangeStart: this.gammaRangeStart,
        gammaRangeEnd: this.gammaRangeEnd,
        gammaDiffRangeStart: this.gammaDiffRangeStart,
        gammaDiffRangeEnd: this.gammaDiffRangeEnd,
        xCoordinateRangeStart: this.xCoordinateRangeStart,
        xCoordinateRangeEnd: this.xCoordinateRangeEnd,
        yCoordinateRangeStart: this.yCoordinateRangeStart,
        yCoordinateRangeEnd: this.yCoordinateRangeEnd,
        xyCoordinateRangeType: this.xyCoordinateRangeType
      }
      localStorage.setItem('lcdFilterInfo', JSON.stringify(body))
      // localStorage.setItem('lcdFilterUpdateTime', this.$moment(new Date()).format('YYYY-MM-DD HH:mm:ss'))
    },
    doLcdCalibrationSummaryInfo () {
      const body = {
        factory: this.factory,
        project: this.project,
        startTime: this.rangeStartDate,
        endTime: this.rangeEndDate,
        biosSn: this.biosSn,
        errorCode: this.errorCode,
        i1Sn: this.i1Sn,
        mfrid: this.mfrid,
        mfrdate: this.mfrdate,
        prodid: this.prodid,
        yRangeStart: this.yRangeStart,
        yRangeEnd: this.yRangeEnd,
        yRangeType: this.yRangeType,
        gammaRangeStart: this.gammaRangeStart,
        gammaRangeEnd: this.gammaRangeEnd,
        gammaDiffRangeStart: this.gammaDiffRangeStart,
        gammaDiffRangeEnd: this.gammaDiffRangeEnd,
        xCoordinateRangeStart: this.xCoordinateRangeStart,
        xCoordinateRangeEnd: this.xCoordinateRangeEnd,
        yCoordinateRangeStart: this.yCoordinateRangeStart,
        yCoordinateRangeEnd: this.yCoordinateRangeEnd,
        xyCoordinateRangeType: this.xyCoordinateRangeType
      }
      this.$http.post('/api/lcd/analyzeMgmt/lcdCalibrationSummaryInfo', body).then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.passAmount = String(res.data.passAmount)
            this.failAmount = String(res.data.failAmount)
            this.totalTestInputs = String(res.data.totalTestInputs)
            this.loadingView = false
          }
        })
    },
    doLcdCalibrationDataList () {
      this.submit = true
      const body = {
        pageNum: this.pagination.page,
        pageRows: this.pagination.current.rowsPerPage,
        sortColumn: (this.pagination.current.descending !== null) ? this.pagination.current.sortBy : '',
        sortType: this.pagination.current.descending ? 'DESC' : ((this.pagination.current.descending !== null) ? 'ASC' : ''),
        factory: this.factory,
        project: this.project,
        startTime: this.rangeStartDate,
        endTime: this.rangeEndDate,
        biosSn: this.biosSn,
        errorCode: this.errorCode,
        i1Sn: this.i1Sn,
        mfrid: this.mfrid,
        mfrdate: this.mfrdate,
        prodid: this.prodid,
        yRangeStart: this.yRangeStart,
        yRangeEnd: this.yRangeEnd,
        yRangeType: this.yRangeType,
        gammaRangeStart: this.gammaRangeStart,
        gammaRangeEnd: this.gammaRangeEnd,
        gammaDiffRangeStart: this.gammaDiffRangeStart,
        gammaDiffRangeEnd: this.gammaDiffRangeEnd,
        xCoordinateRangeStart: this.xCoordinateRangeStart,
        xCoordinateRangeEnd: this.xCoordinateRangeEnd,
        yCoordinateRangeStart: this.yCoordinateRangeStart,
        yCoordinateRangeEnd: this.yCoordinateRangeEnd,
        xyCoordinateRangeType: this.xyCoordinateRangeType
      }
      this.$http.post('/api/lcd/analyzeMgmt/lcdCalibrationDataList', body).then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            // this.pagination.rowsPerPage = res.data.length
            this.responseData = res.data.pagingList
            this.responseDataOnly = res.data.pagingList
            // res.data.deviceList.forEach((r, index) => {
            //   this.responseData.push({})
            //   r.forEach((rr) => {
            //     this.responseData[index][rr.name] = rr.value
            //   })
            //   this.responseDataOnly.push({})
            //   r.forEach((rr) => {
            //     this.responseDataOnly[index][rr.name] = rr.value
            //   })
            // })
            this.pagination.current.totalItems = Number(res.data.totalRows)
            // this.pages = Math.ceil(res.data.totalRows / this.pagination.current.rowsPerPage)
            this.IsSubmitPage = true
            // this.pagination.current.totalItems = res.data.totalRows
            // this.pagination.rowsPerPage = 25
            // this.pages = Math.ceil(this.responseData.length / this.pagination.rowsPerPage)
            // setTimeout(() => {
            //   this.pages = Math.ceil(res.data.totalRows / this.pagination.current.rowsPerPage)
            // }, 0)
          }
          return res
        })
        .then(res => {
          if (res.status === 'success') {
            this.pages = Number(Math.ceil(res.data.totalRows / this.pagination.current.rowsPerPage))
            this.IsSubmitPage = false
          }
        })
    },
    downloadCSV () {
      if (this.doSubmitVerification()) {
        this.processingView = true
        const body = {
          factory: this.factory,
          project: this.project,
          startTime: this.rangeStartDate,
          endTime: this.rangeEndDate,
          biosSn: this.biosSn,
          errorCode: this.errorCode,
          i1Sn: this.i1Sn,
          mfrid: this.mfrid,
          mfrdate: this.mfrdate,
          prodid: this.prodid,
          yRangeStart: this.yRangeStart,
          yRangeEnd: this.yRangeEnd,
          yRangeType: this.yRangeType,
          gammaRangeStart: this.gammaRangeStart,
          gammaRangeEnd: this.gammaRangeEnd,
          gammaDiffRangeStart: this.gammaDiffRangeStart,
          gammaDiffRangeEnd: this.gammaDiffRangeEnd,
          xCoordinateRangeStart: this.xCoordinateRangeStart,
          xCoordinateRangeEnd: this.xCoordinateRangeEnd,
          yCoordinateRangeStart: this.yCoordinateRangeStart,
          yCoordinateRangeEnd: this.yCoordinateRangeEnd,
          xyCoordinateRangeType: this.xyCoordinateRangeType
        }
        this.$http.post('/api/lcd/analyzeMgmt/downloadCsv', body, { responseType: 'blob' })
          .then(res => {
            this.processingView = false
            if (res.data.type === 'application/json') {
              return new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.onload = () => {
                  res.data = JSON.parse(reader.result)
                  if (res.data.status === 'success') {
                    alert(res.data.data)
                    // resolve(Promise.reject(res.data.data))
                  } else {
                    this.snackbar = true
                    this.snackbarColor = 'red'
                    this.snackbarMsg = res.data.errMsg + ' (' + res.data.errCode + ')'
                  }
                }
                reader.onerror = () => {
                  reject(res.data.data)
                }
                reader.readAsText(res.data)
              })
            } else if (res.data.status !== 'failure' && res.headers['content-disposition']) {
              const blob = new Blob([res.data], { type: 'application/octet-stream' })
              const url = window.URL.createObjectURL(blob)
              const fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]
              const downloadLink = document.createElement('a')
              downloadLink.href = url
              downloadLink.download = fileName

              document.body.appendChild(downloadLink)
              downloadLink.click()
              document.body.removeChild(downloadLink)
            } else {
              this.snackbar = true
              this.snackbarColor = 'red'
              this.snackbarMsg = '下載失敗，請通知系統管理員'
            }
          })
          .catch(err => {
            this.processingView = false
            this.snackbar = true
            this.snackbarColor = 'orange'
            this.snackbarMsg = err
          })
      }
    },
    downloadNBreport (pk) {
      this.processingView = true
      this.$http.get(`/api/lcd/analyzeMgmt/downloadNBReport/${pk}`, {}, { responseType: 'blob' })
        .then(res => {
          this.processingView = false
          if (res.data.type === 'application/json') {
            return new Promise((resolve, reject) => {
              const reader = new FileReader()
              reader.onload = () => {
                res.data = JSON.parse(reader.result)
                if (res.data.status === 'success') {
                  alert(res.data.data)
                } else {
                  this.snackbar = true
                  this.snackbarColor = 'red'
                  this.snackbarMsg = res.data.errMsg + ' (' + res.data.errCode + ')'
                }
              }
              reader.onerror = () => {
                reject(res.data.errMsg || res.data.data)
              }
              reader.readAsText(res.data)
            })
          } else if (res.data.status !== 'failure' && res.headers['content-disposition']) {
            const blob = new Blob([res.data], { type: 'application/octet-stream' })
            const url = window.URL.createObjectURL(blob)
            const fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]
            const downloadLink = document.createElement('a')
            downloadLink.href = url
            downloadLink.download = fileName

            document.body.appendChild(downloadLink)
            downloadLink.click()
            document.body.removeChild(downloadLink)
          } else {
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = '下載失敗，請通知系統管理員'
          }
        })
        .catch(err => {
          this.processingView = false
          this.snackbar = true
          this.snackbarColor = 'orange'
          this.snackbarMsg = err
        })
    },
    downloadHitTraceLog (pk) {
      this.processingView = true
      this.$http.get(`/api/lcd/analyzeMgmt/downloadHitTraceLog/${pk}`, {}, { responseType: 'blob' })
        .then(res => {
          this.processingView = false
          if (res.data.type === 'application/json') {
            return new Promise((resolve, reject) => {
              const reader = new FileReader()
              reader.onload = () => {
                res.data = JSON.parse(reader.result)
                if (res.data.status === 'success') {
                  alert(res.data.data)
                } else {
                  this.snackbar = true
                  this.snackbarColor = 'red'
                  this.snackbarMsg = res.data.errMsg + ' (' + res.data.errCode + ')'
                }
              }
              reader.onerror = () => {
                reject(res.data.errMsg || res.data.data)
              }
              reader.readAsText(res.data)
            })
          } else if (res.data.status !== 'failure' && res.headers['content-disposition']) {
            const blob = new Blob([res.data], { type: 'application/octet-stream' })
            const url = window.URL.createObjectURL(blob)
            const fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]
            const downloadLink = document.createElement('a')
            downloadLink.href = url
            downloadLink.download = fileName

            document.body.appendChild(downloadLink)
            downloadLink.click()
            document.body.removeChild(downloadLink)
          } else {
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = '下載失敗，請通知系統管理員'
          }
        })
        .catch(err => {
          this.processingView = false
          this.snackbar = true
          this.snackbarColor = 'orange'
          this.snackbarMsg = err
        })
    }
  }
}
</script>
<style>
/* .c3-axis-x .tick tspan:hover {
  cursor: pointer;
} */
</style>
