<template>
  <v-container
    lcd
    fluid
    grid-list-xl
    pt-0
  >
    <v-layout wrap>
      <!-- <v-flex
        xs12
        sm6
        lg3>
        <material-stats-card
          :value="summaryCaseName"
          style="margin-bottom:-20px !important;"
          class="v-offset-none"
          color="purple"
          icon="mdi-folder-open"
          title="案名"/>
      </v-flex>
      <v-flex
        xs12
        sm6
        lg3>
        <material-stats-card
          :value="summaryDate"
          style="margin-bottom:-20px !important;"
          color="indigo"
          icon="mdi-calendar"
          title="資料日期"/>
      </v-flex>
      <v-flex
        xs12
        sm6
        lg3>
        <material-stats-card
          :value="summaryEMS"
          style="margin-bottom:-20px !important;"
          color="light-blue"
          icon="mdi-factory"
          title="EMS"/>
      </v-flex>
      <v-flex
        xs12
        sm6
        lg3>
        <material-stats-card
          :value="(responseAreaData.failRate * 100).toFixed(1) + '%'"
          :class="responseAreaData.warningColor || 'green'"
          :color="responseAreaData.color"
          :icon="responseAreaData.icon || 'mdi-check'"
          class="title-color"
          style="margin-bottom:-20px !important;"
          title="Fail Rate"/>
      </v-flex> -->
      <v-flex
        class="flex_width_auto v-flex__first"
        style="margin-top: 55px !important;"
        xs12>
        <material-card
          id="checkTable"
          :style="'max-width: calc(100vw - '+(Number(shrink)+32)+'px);'"
          :title="'X/Y/Z Info - [ '+deviceIsn+' ]'"
          color="deep-orange"
          class="card-mix d-table-t0 no-data-left">
          <v-select
            v-model="pagination.rowsPerPage"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="rowsAmount"
            class="mix-list v-text-field__slot d-inline-block float-right ml-5"
            style="width: 90px;"
            persistent-hint
            outlined
          />
          <v-data-table
            :headers="headers"
            :items="responseDataTable"
            :pagination.sync="pagination"
            multi-sort
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="subheading font-weight-light text-general text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <!-- <td :class="item.color.toLowerCase().replace('gray','grey')">{{ item.color }}</td> -->
              <!-- <td><span :class="{'text-danger':item.color==='Red','text-success':item.color==='Green','text-info':item.color==='Blue','text-gray':item.color==='Gray'}">{{ item.color }}</span></td> -->
              <td><span :class="{'text-danger':item.color==='Red','text-success':item.color==='Green','text-info':item.color==='Blue','text-gray':item.color==='Gray'}">█ </span>{{ item.color }}</td>
              <td class="text-xs-right">{{ item.position }}</td>
              <td class="text-xs-right">{{ (item.bigX).toFixed(2) }}</td>
              <td class="text-xs-right">{{ (item.bigY).toFixed(2) }}</td>
              <td class="text-xs-right">{{ (item.bigZ).toFixed(2) }}</td>
              <td class="text-xs-right">{{ (item.x) ? (item.x).toFixed(2) : "" }}</td>
              <td class="text-xs-right">{{ (item.y) ? (item.y).toFixed(2) : "" }}</td>
            </template>
          </v-data-table>
          <div
            v-if="pagination.rowsPerPage != -1"
            class="text-xs-center pt-2 d-inline-block"
            style="margin-left: 0px;width: calc(100% - 140px);">
            <v-pagination
              v-model="pagination.page"
              :length="pages"/>
          </div>
          <v-autocomplete
            v-if="pagination.rowsPerPage != -1"
            v-model="pagination.page"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="pageslist"
            class="v-text-field__slot d-inline-block float-right mr-0"
            style="width: 90px;"
            persistent-hint
            outlined
          />
        </material-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="loadingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :top="true"
      :color="snackbarColor">
      {{ snackbarMsg }}
      <v-btn
        dark
        flat
        @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import Loading from '@/assets/img/loading.gif'

export default {
  name: 'ShowLcdCalibrationXYZInfo',
  components: {
  },
  data () {
    return {
      snackbar: false,
      snackbarColor: 'red',
      snackbarMsg: '',
      Loading,
      loadingView: false,
      lcdTestResultPk: '',
      deviceIsn: '',
      responseDataTable: [],
      rowsAmount: [25, 50, 100, { text: 'All', value: -1 }],
      pagination: {
        rowsPerPage: 25,
        sortBy: '', // The field that you're sorting by
        descending: true
      },
      headers: [
        {
          sortable: true,
          text: 'R/G/B/W',
          value: 'color',
          class: 'table-width-100'
        },
        {
          sortable: true,
          text: 'Value',
          value: 'position',
          align: '',
          class: 'table-width-100'
        },
        {
          sortable: true,
          text: 'X',
          value: 'bigX',
          align: '',
          class: 'table-width-100'
        },
        {
          sortable: true,
          text: 'Y',
          value: 'bigY',
          align: '',
          class: 'table-width-100'
        },
        {
          sortable: true,
          text: 'Z',
          value: 'bigZ',
          align: '',
          class: 'table-width-100'
        },
        {
          sortable: true,
          text: 'x',
          value: 'x',
          align: '',
          class: 'table-width-100'
        },
        {
          sortable: false,
          text: 'y',
          value: 'y',
          align: '',
          class: 'table-width-100'
        }
      ]
    }
  },
  computed: {
    ...mapState('app', ['theme', 'shrink']),
    pages () {
      let n = ''
      if (this.responseDataTable.length > 0) {
        n = this.pagination.rowsPerPage ? Math.ceil(this.responseDataTable.length / this.pagination.rowsPerPage) : 10
      }
      return n
    },
    pageslist () {
      let i = 0
      const list = []
      if (this.pagination.rowsPerPage) {
        for (i = 0; i < Math.ceil(this.responseDataTable.length / this.pagination.rowsPerPage); i++) {
          list.push(i + 1)
        }
      }
      return list
    }
  },
  watch: {
  },
  mounted () {
    this.$store.dispatch('getAuthorize', '/lcd/lcdCalibrationAnalyzer/showLcdCalibrationXYZInfoView')
      .then(response => {
        if (response.status === 'success' && response.data.accessible) {
          if (this.$route.params.pk) {
            this.lcdTestResultPk = this.$route.params.pk
            this.doSubmit()
          }
        } else if (response.errCode === 'E10000002') {
          const routeData = this.$router.resolve({ path: '/noEnter/E10000002' })
          localStorage.setItem('errormsg', response.errMsg)
          window.location.assign(routeData.href)
        } else {
          const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
          window.location.assign(routeData.href)
        }
      })
      .catch(err => {
        console.log(err)
        const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
        window.location.assign(routeData.href)
      })
  },
  methods: {
    doSubmit () {
      this.loadingView = true
      this.$http.get(`/api/lcd/analyzeMgmt/xyzInfoDataList/${this.lcdTestResultPk}`).then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.deviceIsn = res.data.deviceIsn
            // this.pagination.rowsPerPage = res.data.dataList.length
            this.responseDataTable = res.data.dataList
          }
          this.loadingView = false
        })
    }
  }
}
</script>
