<template>
  <v-container
    fluid
    grid-list-xl
    pt-0
  >
    <v-layout wrap>
      <v-flex
        mt-3
        xs10
      >
        <material-card
          title="產線品質統計"
          color="general"
          class="card-padding pt-0 pb-3">
          <v-form>
            <v-container
              py-0
              class="mw-100">
              <v-layout
                wrap
                align-items-center>
                <v-flex
                  xs12
                  sm6
                  lg4>
                  <v-autocomplete
                    v-model="summaryModelAndFactory"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryModelAndFactoryMenu"
                    label="Model name"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex
                  xs12
                  lg1/>
                <v-flex
                  flex-small
                  d-flex
                  align-items-center
                  xs12
                  lg5>
                  <p class="m-0">時間</p>
                  <v-menu
                    v-model="summaryStartDateMenu"
                    :close-on-content-click="false"
                    :nudge-left="40"
                    lazy
                    transition="scale-transition"
                    style="max-width: 170px;margin-left: 10px;"
                    max-width="290px"
                    offset-y
                    full-width
                  >
                    <v-text-field
                      slot="activator"
                      :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                      v-model="summaryStartDate"
                      label=""
                      prepend-icon="event"
                    />
                    <v-date-picker
                      v-model="summaryStartDate"
                      :min="startdate"
                      :max="new Date() | date-format"
                      :color="theme==='dark'?'danger':'general'"
                      :event-color="'green'"
                      :events="manufacturingDateList"
                      type="date"
                      no-title
                      scrollable
                      actions/>
                  </v-menu>
                  <p class="m-0 text-center px-3">到</p>
                  <v-menu
                    v-model="summaryEndDateMenu"
                    :close-on-content-click="false"
                    :nudge-left="40"
                    lazy
                    transition="scale-transition"
                    style="max-width: 170px;margin-left: 10px;"
                    max-width="290px"
                    offset-y
                    full-width
                  >
                    <v-text-field
                      slot="activator"
                      :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                      v-model="summaryEndDate"
                      label=""
                      prepend-icon="event"
                    />
                    <v-date-picker
                      v-model="summaryEndDate"
                      :min="summaryStartDate"
                      :max="new Date() | date-format"
                      :color="theme==='dark'?'danger':'general'"
                      :event-color="'green'"
                      :events="manufacturingDateList"
                      type="date"
                      no-title
                      scrollable
                      actions/>
                  </v-menu>
                </v-flex>
                <v-flex
                  xs12
                  lg2
                  text-xs-right
                >
                  <v-btn
                    :disabled="summaryStartDate===''||summaryEndDate===''||summaryModelAndFactory===''"
                    color="general"
                    class="ml-3"
                    small
                    @click="doSubmit()">
                    <v-icon class="mr-2">mdi-backup-restore</v-icon>分析
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
      </v-flex>
      <v-flex
        v-if="isSubmit"
        xs10
        lg2>
        <material-stats-card
          :value="(responseYieldRate * 100).toFixed(1) + '%'"
          :class="evaluationSpecBgColor"
          :color="evaluationSpecColor"
          :icon="evaluationSpecIcon"
          class="title-color"
          style="margin-top:40px !important;margin-bottom:0px !important;"
          title="Yield Rate"/>
      </v-flex>
      <v-flex
        v-if="isSubmit"
        flex_table_width_auto
        xs12
      >
        <material-card
          id="checkTable"
          :style="'max-width: calc(100vw - '+(Number(shrink)+32)+'px);'"
          title="查詢結果"
          color="deep-orange"
          class="card-mix d-table-t0 no-data-left"
          style="margin-top: 30px!important;">
          <v-btn
            class="mix-btn black text-light"
            style="right: 1rem;"
            @click="doGroupDownload()">
            <v-icon class="floata-left">mdi-download</v-icon>打包下載
          </v-btn>
          <v-data-table
            :headers="responseHeaders"
            :items="responseData"
            class="height-row-3"
            multi-sort
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="subheading font-weight-light text-general text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <tr>
                <td>
                  <router-link
                    :to="'/product/analyze/dataAnalyzer/'+responseDateStart+'/'+responseDateEnd+'/'+responseModelAndFactory+'/'+item.stationPk"
                    target="_blank"
                    class="text-white">
                    {{ item.stationName }}
                  </router-link>
                </td>
                <td class="text-right">{{ item.totalDeviceInputs }}</td>
                <td class="text-right">{{ item.failAmounts }}</td>
                <td class="text-right">{{ (item.yieldRate*100).toFixed(2) }}%</td>
                <td class="text-right">{{ (item.retryRate*100).toFixed(2) }}%</td>
              </tr>
            </template>
          </v-data-table>
        </material-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="processingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        PROCESSING 處理中
      </p>
    </v-dialog>
    <v-dialog
      v-model="loadingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :color="snackbarColor"
      :top="true">
      {{ snackbarMsg }}
      <v-btn
        flat
        @click="snackbar = false">
        關閉
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import qs from 'qs'
import { mapState } from 'vuex'
import Loading from '@/assets/img/loading.gif'

export default {
  name: 'ProductLineQualityReviewView',
  components: {
  },
  data () {
    return {
      startdate: '2018-04-01',
      snackbar: false,
      snackbarColor: 'green',
      snackbarMsg: '',
      Loading,
      loadingView: false,
      processingView: false,
      isSubmit: false,
      summaryModelAndFactoryMenu: [],
      summaryModelAndFactory: '',
      summaryStartDateMenu: false,
      summaryStartDate: this.$moment(new Date()).format('YYYY-MM-DD'),
      summaryEndDateMenu: false,
      summaryEndDate: this.$moment(new Date()).format('YYYY-MM-DD'),
      manufacturingDateList: [],
      evaluationSpecStandard: 0.8,
      evaluationSpecStatus: 'qualified',
      evaluationSpecBgColor: 'green',
      evaluationSpecColor: 'light-green',
      evaluationSpecIcon: 'mdi-check',
      responseDateStart: '',
      responseDateEnd: '',
      responseModelAndFactory: '',
      responseYieldRate: 0,
      responseData: [],
      responseHeaders: [
        {
          align: '',
          sortable: true,
          text: 'Station',
          value: 'stationName'
        }, {
          align: 'right',
          sortable: true,
          text: 'Total Device Inputs (sets)',
          value: 'totalDeviceInputs'
        }, {
          align: 'right',
          sortable: true,
          text: 'Fail sets',
          value: 'failAmounts'
        }, {
          align: 'right',
          sortable: true,
          text: 'Yield Rate (%)',
          value: 'yieldRate'
        }, {
          align: 'right',
          sortable: true,
          text: 'Retry Rate (%)',
          value: 'retryRate'
        }
      ]
    }
  },
  computed: {
    ...mapState('app', ['theme', 'shrink'])
  },
  watch: {
    summaryModelAndFactory (val) {
      this.getManufacturingDate(val)
    },
    startDate (val) {
      this.startDateMenu = false
    },
    endDate (val) {
      this.endDateMenu = false
    }
  },
  mounted () {
    this.$store.dispatch('getAuthorize', '/product/analyze/productLineQualityReviewView')
      .then(response => {
        if (response.status === 'success' && response.data.accessible) {
          JSON.parse(localStorage.getItem('moduleDataInitDate')).forEach((n) => {
            if (n.name === 'dataLog') {
              this.startdate = n.value
            }
          })
          this.$http.get('/api/product/fundamental/manufacturingModel').then(res => res.data)
            .then(res => {
              if (res.status === 'success') {
                this.summaryModelAndFactoryMenu = []
                res.data.forEach((n) => {
                  this.summaryModelAndFactoryMenu.push({ text: (n.modelName || '(N/A)') + '　　(' + (n.factoryName || 'N/A') + ')', value: n.pk })
                })
              }
            })
        } else if (response.errCode === 'E10000002') {
          const routeData = this.$router.resolve({ path: '/noEnter/E10000002' })
          localStorage.setItem('errormsg', response.errMsg)
          window.location.assign(routeData.href)
        } else {
          const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
          window.location.assign(routeData.href)
        }
      })
      .catch(err => {
        console.log(err)
        const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
        window.location.assign(routeData.href)
      })
  },
  methods: {
    getManufacturingDate (pk) {
      if (pk) {
        const body = {
          modelPk: this.summaryModelAndFactory
        }
        this.$http.get('/api/product/manufacturing/manufacturingDate?' + qs.stringify(body)).then(res => res.data)
          .then(res => {
            if (res.status === 'success') {
              this.manufacturingDateList = res.data
            }
          })
      }
    },
    getEvaluationSpec () {
      this.$http.get(`/api/product/support/evaluationSpec/${this.summaryModelAndFactory}`).then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.evaluationSpecStandard = res.data.standard
            this.evaluationSpecStatus = res.data.status
          }
        })
    },
    doSubmit () {
      if (new Date(this.summaryEndDate).getTime() < new Date(this.summaryStartDate).getTime()) {
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '結尾時間不得早於起始時間'
      } else if ((new Date(this.summaryEndDate).getTime() - new Date(this.summaryStartDate).getTime()) > 86400000 * 90) {
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '日期區間不得超過90天'
      } else {
        this.loadingView = true
        this.getEvaluationSpec()
        const body = {
          modelPk: this.summaryModelAndFactory,
          manufacturingDateStart: this.summaryStartDate,
          manufacturingDateEnd: this.summaryEndDate
        }
        this.$http.get('/api/product/manufacturing/productLineQualityData?' + qs.stringify(body)).then(res => res.data)
          .then(res => {
            this.loadingView = false
            if (res.status === 'success') {
              this.responseDateStart = this.summaryStartDate
              this.responseDateEnd = this.summaryEndDate
              this.responseModelAndFactory = this.summaryModelAndFactory
              this.responseYieldRate = res.data.summary.yieldRate
              this.evaluationSpecColor = (this.responseYieldRate >= this.evaluationSpecStandard) ? 'light-green' : 'pink'
              this.evaluationSpecBgColor = (this.responseYieldRate >= this.evaluationSpecStandard) ? 'green' : 'red'
              this.evaluationSpecIcon = (this.responseYieldRate >= this.evaluationSpecStandard) ? 'mdi-check' : 'mdi-alert'
              this.responseData = res.data.detail
              this.responseData.forEach((n, i) => {
                this.responseData[i].stationPk = n.station.pk
                this.responseData[i].stationName = n.station.name
              })
              this.isSubmit = true
            } else { // failure
              this.snackbar = true
              this.snackbarColor = 'red'
              this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
            }
          })
          .catch(err => {
            this.loadingView = false
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = err
          })
      }
    },
    // doDownload () {
    //   if (this.summaryWay === 'dateRange' && (new Date(this.summaryEndDate).getTime() < new Date(this.summaryStartDate).getTime())) {
    //     this.snackbar = true
    //     this.snackbarColor = 'red'
    //     this.snackbarMsg = '結尾時間不得早於起始時間'
    //   } else if ((new Date(this.summaryEndDate).getTime() - new Date(this.summaryStartDate).getTime()) <= 86400000 * 30) {
    //     this.snackbar = true
    //     this.snackbarColor = 'red'
    //     this.snackbarMsg = '日期區間不得超過30天'
    //   } else {
    //     this.processingView = true
    //     let body = {}
    //     if (this.summaryWay === 'sn') {
    //       body = {
    //         modelPk: this.summaryModel,
    //         factoryPk: this.summaryFactory,
    //         stationPk: this.summaryProductStation,
    //         snList: this.deviceSnListSelect
    //       }
    //     } else if (this.summaryWay === 'dateRange') {
    //       body = {
    //         modelPk: this.summaryModel,
    //         factoryPk: this.summaryFactory,
    //         stationPk: this.summaryProductStation,
    //         manufactureStart: this.summaryStartDate,
    //         manufactureEnd: this.summaryEndDate
    //       }
    //     }
    //     this.$http.post('/api/keypart/keyboard/keyboardManufacturingRawDataFile', body, { responseType: 'blob' })
    //       .then(res => {
    //         if (this.expressDownload) {
    //           this.resetDeviceSnListItem()
    //           this.expressDownload = false
    //         }
    //         this.processingView = false
    //         if (res.data.type === 'application/json') {
    //           return new Promise((resolve, reject) => {
    //             let reader = new FileReader()
    //             reader.onload = () => {
    //               res.data = JSON.parse(reader.result)
    //               alert(res.data.data)
    //               // resolve(Promise.reject(res.data.data))
    //             }
    //             reader.onerror = () => {
    //               reject(res.data.data)
    //             }
    //             reader.readAsText(res.data)
    //           })
    //         } else if (res.data.status !== 'failure' && res.headers['content-disposition']) {
    //           let blob = new Blob([res.data], { type: 'application/octet-stream' })
    //           let url = window.URL.createObjectURL(blob)
    //           let fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]
    //           const downloadLink = document.createElement('a')
    //           downloadLink.href = url
    //           downloadLink.download = fileName

    //           document.body.appendChild(downloadLink)
    //           downloadLink.click()
    //           document.body.removeChild(downloadLink)
    //         } else {
    //           this.snackbar = true
    //           this.snackbarColor = 'red'
    //           this.snackbarMsg = '下載失敗，請通知系統管理員'
    //         }
    //       })
    //   }
    // },
    doGroupDownload () {
      if (this.responseData.length > 0) {
        this.processingView = true
        const body = {
          modelPk: this.summaryModelAndFactory,
          manufacturingDateStart: this.summaryStartDate,
          manufacturingDateEnd: this.summaryEndDate
        }
        this.$http.get('/api/product/manufacturing/productLineQualityDataFile?' + qs.stringify(body, { arrayFormat: 'repeat', encode: true }), { observe: 'response', responseType: 'blob' })
          .then(res => {
            this.processingView = false
            if (res.data.type === 'application/json') {
              return new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.onload = () => {
                  res.data = JSON.parse(reader.result)
                  if (res.data.status === 'success') {
                    alert(res.data.data)
                    // resolve(Promise.reject(res.data.data))
                  } else {
                    this.snackbar = true
                    this.snackbarColor = 'red'
                    this.snackbarMsg = res.data.errMsg + ' (' + res.data.errCode + ')'
                  }
                }
                reader.onerror = () => {
                  reject(res.data.data)
                }
                reader.readAsText(res.data)
              })
            } else if (res.data.status !== 'failure' && res.headers['content-disposition']) {
              // let blob = new Blob([res.data])
              const url = window.URL.createObjectURL(res.data)
              const fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]
              const downloadLink = document.createElement('a')

              // downloadLink.setAttribute('download', fileName)
              downloadLink.href = url
              downloadLink.download = fileName

              document.body.appendChild(downloadLink)
              downloadLink.click()
              document.body.removeChild(downloadLink)
            } else {
              this.snackbar = true
              this.snackbarColor = 'red'
              this.snackbarMsg = '下載失敗，請通知系統管理員'
            }
          })
          .catch(err => {
            this.processingView = false
            this.snackbar = true
            this.snackbarColor = 'orange'
            this.snackbarMsg = err
          })
      } else {
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '無資料可供下載'
      }
    }
  }
}
</script>
<style>
</style>
