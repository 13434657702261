<template>
  <v-container
    fluid
    grid-list-xl
    pt-0
  >
    <v-layout wrap>
      <v-flex
        xs12
      >
        <material-card
          class="card-shrink card-padding v-card__first"
          color="general"
          title="燒機數據搜尋"
        >
          <v-form>
            <v-container
              py-0
              class="mw-100">
              <v-layout wrap>
                <v-flex
                  xs12
                  sm12
                  lg6>
                  <v-text-field
                    v-model="summarySN"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    label="S/N"/>
                </v-flex>
                <v-flex
                  xs12
                  lg6
                  text-xs-right
                >
                  <v-btn
                    class="mx-0 font-weight-light"
                    color="general"
                    @click="doSubmit()"
                  >
                    <v-icon class="mr-2">mdi-backup-restore</v-icon>查詢
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
        <material-card>
          <v-data-table
            :headers="headers"
            :items="responseDataTable"
            :pagination.sync="pagination"
            multi-sort
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="subheading font-weight-light text-general text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <td
                class="td-check"
                style="position: relative;">
                <v-checkbox
                  v-model="checkList"
                  :value="item.ttrsPk"
                  class="mx-2"
                  style="display: table-caption;max-height: 20px;"
                  color="orange"
                  label=""/>
              </td>
              <td>{{ item.deviceSn }}</td>
              <td class="text-xs-right">{{ item.modelName }}</td>
              <td class="text-xs-right">{{ item.ems }}</td>
              <td class="text-xs-right">{{ item.cpuAvgTemperature }}</td>
              <td>{{ item.errorCode }}</td>
              <td>
                <v-btn
                  class="mx-0 mr-3 font-weight-light small"
                  color="primary"
                  @click="doBeforeLink(item.ttrsPk,item.deviceSn,item.modelName,item.ems,item.date)">
                  <v-icon class="mr-2">mdi-chart-bubble</v-icon>線上分析
                </v-btn>
                <router-link
                  :to="'/viewThermalLog/' + item.ttrsPk"
                  target="_blank">
                  <v-btn
                    class="mx-0 mr-3 font-weight-light small"
                    color="green">
                    <v-icon class="mr-2">mdi-file-find</v-icon>線上檢視
                  </v-btn>
                </router-link>
                <v-btn
                  class="mx-0 font-weight-light small"
                  color="orange"
                  @click="doDownload(item.ttrsPk)">
                  <v-icon class="mr-2">mdi-download</v-icon>下載LOG
                </v-btn>
              </td>
              <td>{{ item.date }}
                <router-link
                  :to="'/thermal/thermalCpuBurnIn/dailyThermalCpuBurnInFailRateSummary/' + item.date"
                  target="_blank">
                  <v-btn
                    title="檢示日燒機不良率摘要"
                    class="black text-light pl-2 pr-2">
                    <v-icon>mdi-clipboard-text-outline</v-icon>
                  </v-btn>
                </router-link>
              </td>
            </template>
          </v-data-table>
          <v-btn
            class="black text-light mx-0 font-weight-light"
            @click="doGroupDownload()">
            <v-icon class="floata-left">mdi-download</v-icon>打包下載
          </v-btn>
        </material-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="processingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        PROCESSING 處理中
      </p>
    </v-dialog>
    <v-dialog
      v-model="loadingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :top="true"
      :color="snackbarColor">
      {{ snackbarMsg }}
      <v-btn
        dark
        flat
        @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import Loading from '@/assets/img/loading.gif'

export default {
  name: 'SingleDeviceCpuBurnInDataSearch',
  components: {
  },
  data () {
    return {
      startdate: '2018-04-01',
      snackbar: false,
      snackbarColor: 'red',
      snackbarMsg: '',
      Loading,
      loadingView: false,
      processingView: false,
      summarySN: '',
      responseDataTable: [],
      checkList: [],
      pagination: {
        rowsPerPage: 1,
        sortBy: 'TotalFailRate', // The field that you're sorting by
        descending: true
      },
      headers: [
        {
          sortable: false,
          text: '',
          value: ''
        },
        {
          sortable: true,
          text: 'S/N',
          value: 'deviceSn'
        },
        {
          sortable: true,
          text: '案名',
          value: 'modelName',
          align: 'right'
        },
        {
          sortable: true,
          text: 'EMS',
          value: 'ems',
          align: 'right'
        },
        {
          sortable: true,
          text: 'CPU溫度',
          value: 'cpuAvgTemperature',
          align: 'right'
        },
        {
          sortable: true,
          text: 'MESSAGE',
          value: 'errorCode'
        },
        {
          sortable: false,
          text: '操作',
          value: 'operation'
        },
        {
          sortable: false,
          text: '生產日期',
          value: 'date'
        }
      ]
    }
  },
  computed: {
    ...mapState('app', ['theme', 'shrink'])
  },
  watch: {
  },
  mounted () {
    this.$store.dispatch('getAuthorize', '/thermal/thermalCpuBurnIn/singleDeviceCpuBurnInDataSearch')
      .then(response => {
        if (response.status === 'success' && response.data.accessible) {
          JSON.parse(localStorage.getItem('moduleDataInitDate')).forEach((n) => {
            if (n.name === 'thermal') {
              this.startdate = n.value
            }
          })
          if (this.$route.params.searchSN) {
            this.summarySN = this.$route.params.searchSN
          }
          this.doSubmit()
        } else if (response.errCode === 'E10000002') {
          const routeData = this.$router.resolve({ path: '/noEnter/E10000002' })
          localStorage.setItem('errormsg', response.errMsg)
          window.location.assign(routeData.href)
        } else {
          const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
          window.location.assign(routeData.href)
        }
      })
      .catch(err => {
        console.log(err)
        const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
        window.location.assign(routeData.href)
      })
  },
  methods: {
    doBeforeLink (TtrsPk, deviceSn, modelname, ems, date) {
      const routeData = this.$router.resolve({ path: '/thermal/thermalCpuBurnIn/singleDeviceCpuBurnInDataStatistic/' + TtrsPk + '/0', query: { sn: deviceSn, modelname: modelname, date: date, ems: ems } })
      window.open(routeData.href, '_blank')
    },
    doSubmit () {
      this.loadingView = true
      this.$http.post('/api/thermal/singleDeviceCpuBurnInData?deviceSn=' + this.summarySN).then(res => res.data)
        .then(res => {
          this.loadingView = false
          if (res.status === 'success') {
            this.pagination.rowsPerPage = res.data.length
            this.responseDataTable = res.data
          }
        })
    },
    doDownload (key) {
      this.processingView = true
      const body = {
        type: 0,
        ttrsPkList: [key]
      }
      this.$http.post('/api/thermal/downloadDailyBurnInOriginalData', body)
        .then(res => {
          this.processingView = false
          if (res.data.type === 'application/json') {
            return new Promise((resolve, reject) => {
              const reader = new FileReader()
              reader.onload = () => {
                res.data = JSON.parse(reader.result)
                if (res.data.status === 'success') {
                  alert(res.data.data)
                } else {
                  this.snackbar = true
                  this.snackbarColor = 'red'
                  this.snackbarMsg = res.data.errMsg + ' (' + res.data.errCode + ')'
                }
              }
              reader.onerror = () => {
                reject(res.data.errMsg || res.data.data)
              }
              reader.readAsText(res.data)
            })
          } else if (res.data.status !== 'failure' && res.headers['content-disposition']) {
            const blob = new Blob([res.data], { type: 'application/octet-stream' })
            const url = window.URL.createObjectURL(blob)
            const fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]
            const downloadLink = document.createElement('a')
            downloadLink.href = url
            downloadLink.download = fileName

            document.body.appendChild(downloadLink)
            downloadLink.click()
            document.body.removeChild(downloadLink)
          } else {
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = '下載失敗，請通知系統管理員'
          }
        })
        .catch(err => {
          this.processingView = false
          this.snackbar = true
          this.snackbarColor = 'orange'
          this.snackbarMsg = err
        })
    },
    doGroupDownload () {
      if (this.checkList.length > 0) {
        this.processingView = true
        const body = {
          type: 1,
          ttrsPkList: this.checkList.join().split(',')
        }
        body.ttrsPkList.forEach((tt, index) => {
          body.ttrsPkList[index] = Number(tt)
        })
        this.$http.post('/api/thermal/downloadDailyBurnInOriginalData', body, { observe: 'response', responseType: 'blob' })
          .then(res => {
            this.processingView = false
            if (res.data.type === 'application/json') {
              return new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.onload = () => {
                  res.data = JSON.parse(reader.result)
                  if (res.data.status === 'success') {
                    alert(res.data.data)
                    // resolve(Promise.reject(res.data.data))
                  } else {
                    this.snackbar = true
                    this.snackbarColor = 'red'
                    this.snackbarMsg = res.data.errMsg + ' (' + res.data.errCode + ')'
                  }
                }
                reader.onerror = () => {
                  reject(res.data.data)
                }
                reader.readAsText(res.data)
              })
            } else if (res.data.status !== 'failure' && res.headers['content-disposition']) {
              // let blob = new Blob([res.data])
              const url = window.URL.createObjectURL(res.data)
              const fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]
              const downloadLink = document.createElement('a')

              // downloadLink.setAttribute('download', fileName)
              downloadLink.href = url
              downloadLink.download = fileName

              document.body.appendChild(downloadLink)
              downloadLink.click()
              document.body.removeChild(downloadLink)
            } else {
              this.snackbar = true
              this.snackbarColor = 'red'
              this.snackbarMsg = '下載失敗，請通知系統管理員'
            }
          })
          .catch(err => {
            this.processingView = false
            this.snackbar = true
            this.snackbarColor = 'orange'
            this.snackbarMsg = err
          })
      } else {
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '未勾選S/N，請勾選後，再重新下載'
      }
    }
  }
}
</script>
<style>
</style>
