<template>
  <div class="category-options">
    <div
      v-for="(category,c) in originalData"
      :key="'c'+c"
      class="category-options-area">
      <h6 class="category-options-title">{{ category.category }}</h6>
      <div class="category-options-contect">
        <span
          v-for="(item,i) in category.contect"
          :key="'i'+i"
          :style="'color:'+item.color+';border-color:'+item.color"
          class="category-options-item"
          @click="returnOptionSequence(item.sequence)">
          {{ item.name }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CategoryOptionsDisplay',
  props: {
    originalData: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data () {
    return {}
  },
  computed: {},
  methods: {
    returnOptionSequence (sequence) {
      this.$emit('return-option-sequence', sequence)
    }
  }
}
</script>
<style>
.category-options{
  width: 100%;
  height: 100%;
  background-color: #0000002c;
  padding: 10px;
}
.category-options-area{
  background-color: #0000002c;
  border-radius: 5px;
  margin: 5px 0;
  padding: 5px;
}
.category-options-title{
  color: lightgray;
  font-weight: 900 !important;
}
/* .category-options-title>hr{
  width: 100%;
  border-color: lightgray;
  margin-left: 10px;
} */
.category-options-contect{
  flex-wrap: wrap !important;
}
.category-options-item{
  padding: 2px 4px;
  border-radius: 10px;
  border: 1px solid;
  margin: 5px;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  text-align: center;
}
</style>
