<template>
  <v-container
    fluid
    grid-list-xl
    pt-0
  >
    <v-layout wrap>
      <v-flex
        xs12
      >
        <material-card
          :title="'燒機不良率摘要篩選 (' + summaryMonth + ')'"
          class="card-shrink card-padding v-card__first"
          color="general"
        >
          <div class="card-memo-1 text-white">
            <v-icon class="text-warning">mdi-checkbox-blank</v-icon>
            黃字代表不良率大於3%</div>
          <div class="card-memo-2 text-white">
            <v-icon class="text-danger">mdi-checkbox-blank</v-icon>
            紅字代表不良率大於5%</div>
          <v-form>
            <v-container
              py-0
              class="mw-100">
              <v-layout wrap>
                <v-flex
                  xs12
                  lg2>
                  <v-menu
                    v-model="summaryMonthMenu"
                    :close-on-content-click="false"
                    :nudge-left="40"
                    lazy
                    transition="scale-transition"
                    max-width="290px"
                    offset-y
                    full-width
                  >
                    <v-text-field
                      slot="activator"
                      :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                      v-model="summaryMonth"
                      label="時間"
                      prepend-icon="event"
                    />
                    <v-date-picker
                      v-model="summaryMonth"
                      :min="startdate"
                      :max="new Date() | date-format"
                      :color="theme==='dark'?'danger':'general'"
                      type="month"
                      no-title
                      scrollable
                      actions/>
                  </v-menu>
                </v-flex>
                <v-flex
                  xs12
                  sm6
                  lg2>
                  <v-autocomplete
                    v-model="summaryStage"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryStageMenu"
                    label="Stage"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex
                  xs12
                  sm6
                  lg2>
                  <v-autocomplete
                    v-model="summaryEMS"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryEMSMenu"
                    label="EMS"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex
                  xs12
                  sm6
                  lg2>
                  <v-autocomplete
                    v-model="summaryFailRate"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="[
                      { text: 'All', value: 'All' },
                      { text: '> 3%', value: '0.03' },
                      { text: '> 5%', value: '0.05' }
                    ]"
                    label="Fail Rate"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex
                  xs12
                  sm6
                  lg2>
                  <v-autocomplete
                    v-model="summaryModelName"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryModelNameMenu"
                    label="Model Name"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex
                  xs12
                  lg2
                  text-xs-right
                >
                  <v-btn
                    class="mx-0 font-weight-light"
                    color="general"
                    @click="doSubmit()"
                  >
                    <v-icon class="mr-2">mdi-backup-restore</v-icon>查詢
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
        <material-card>
          <div class="calendar">
            <div>
              <div style="margin-left: 70px;">
                <div
                  v-for="(week, w) in WeekList"
                  :key="'w'+w"
                  class="calendar-week">
                  <span>{{ week }}</span>
                </div>
              </div>
              <div
                v-for="(list, d) in summaryMonthWeeksList"
                :key="'d'+d"
                :class="{'calendar-row-unfold': summaryWeeksMatch(list.weeks)}"
                class="calendar-row mr-0 ml-0">
                <div class="calendar-weeks">
                  <span>W {{ list.weeks }}</span>
                  <router-link
                    :to="'/thermal/weeklyThermalFailRateSummary/' + String(summaryMonth).substr(0,4) + (list.weeks < 10 ? ('0' + String(list.weeks)) : String(list.weeks))"
                    target="_blank">
                    <v-btn
                      title="檢示每週生產概要"
                      class="black text-light pl-2 pr-2"><v-icon>mdi-clipboard-text-outline</v-icon></v-btn>
                  </router-link>
                </div>
                <div
                  v-for="(days, dd) in list.date"
                  :key="'dd'+dd"
                  :class="{'calendar-around': days.around}"
                  class="calendar-day calendar-day-fail">
                  <router-link
                    :to="'/thermal/dailyThermalFailRateSummary/' + days.date"
                    title="檢示日燒機不良率摘要"
                    target="_blank">
                    {{ days.day }}
                  </router-link>
                  <v-icon
                    v-if="days.over"
                    class="calendar-day-icon"
                    @click="summaryWeeksArray(list.weeks)">mdi-apple-keyboard-control</v-icon>
                  <div
                    v-for="(fail, f) in days.failList"
                    :key="'f'+f"
                    class="calendar-fail">
                    <router-link
                      :to="'/thermal/dailyBurnInDataStatistic?modelname='+fail.modelName+'&date='+days.date+'&emspk='+fail.emsPk+'&ems='+fail.ems"
                      :style="'color:' + (fail.warningColor !== '' ? fail.warningColor : theme === 'dark' ? 'rgba(245,245,245,1)' : 'rgba(30,30,30,1)') + '!important;'"
                      :hover="'color:' + (fail.warningColor !== '' ? fail.warningColor : theme === 'dark' ? 'rgba(245,245,245,1)' : 'rgba(30,30,30,1)') + '!important;'"
                      target="_blank"
                      small>
                      {{ (fail.failRate*100).toFixed(1) }}% {{ fail.modelName }}
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </material-card>
      </v-flex>
      <v-flex
        flex_width_auto
        xs12
      >
        <material-card
          :title="'符合 '+summaryMonth+' 篩選條件的總合數據'"
          color="deep-orange"
        >
          <v-data-table
            :headers="headers"
            :items="responseFailTable"
            :pagination.sync="pagination"
            multi-sort
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="subheading font-weight-light text-general text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <td>{{ item.modelName }}</td>
              <td class="text-xs-right">{{ item.productionQty }}</td>
              <td class="text-xs-right">{{ item.failQty }}</td>
              <td class="text-xs-right"><v-chip
                :class="item.warningColor"
                small>{{ (item.failRate*100).toFixed(1) }}%</v-chip></td>
              <td>
                <span
                  v-for="(stage, s) in item.stageList"
                  :key="'s'+s">
                  {{ stage }}
                </span>
              </td>
              <td>{{ item.ems }}</td>
              <td>
                <v-btn
                  class="mx-0 font-weight-light small mr-2"
                  color="green"
                  @click="chartLineViewName=item.modelName;chartLineView=true">
                  <v-icon class="mr-2">mdi-chart-line</v-icon>折線分佈
                </v-btn>
                <v-btn
                  class="mx-0 font-weight-bold small mr-2"
                  color="orange"
                  @click="doDownload(item)">
                  <v-icon class="mr-2">mdi-download</v-icon>當月數據
                </v-btn>
                <v-btn
                  class="mx-0 font-weight-bold small"
                  color="orange"
                  @click="doDownloadPowerData(item)">
                  <v-icon class="mr-2">mdi-download</v-icon>POWER DATA
                </v-btn>
              </td>
            </template>
          </v-data-table>
        </material-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="chartLineView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <v-card
        max-width="85%"
        style="margin:0 auto;">
        <v-card-text>
          <v-alert
            v-model="chartLineView"
            class="mt-3 mb-3 border-0"
            color="brown">
            <strong class="h4">{{ chartLineViewName }} 燒機不良率折線分佈 {{ summaryMonth }}</strong>
          </v-alert>
          <vue-c3 :handler="handler_line"/>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="red"
            small
            @click="chartLineView=false">
            <v-icon class="mr-2">mdi-close</v-icon>關閉
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="processingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        PROCESSING 處理中
      </p>
    </v-dialog>
    <v-dialog
      v-model="loadingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :top="true"
      :color="snackbarColor">
      {{ snackbarMsg }}
      <v-btn
        dark
        flat
        @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import Vue from 'vue'
import VueC3 from 'vue-c3'
import 'c3/c3.min.css'
import { mapState } from 'vuex'
import Loading from '@/assets/img/loading.gif'

export default {
  name: 'MonthlyThermalFailRateSummary',
  components: {
    VueC3
  },
  data () {
    return {
      startdate: '2018-04-01',
      snackbar: false,
      snackbarColor: 'red',
      snackbarMsg: '',
      Loading,
      loadingView: false,
      processingView: false,
      downloadStartDate: '',
      downloadEndDate: '',
      summaryMonthMenu: false,
      summaryMonth: this.$moment(new Date()).format('YYYY-MM'),
      summaryStageMenu: [],
      summaryEMSMenu: [],
      summaryModelNameMenu: [],
      summaryStage: 'All',
      summaryEMS: 'All',
      summaryFailRate: 'All',
      summaryModelName: 'All',
      summaryWeeksUnfoldList: [],
      summaryMonthWeeksList: [],
      summaryYearWeeksList: [],
      summaryAllYearWeeksList: [],
      summarySearchYearWeeksList: [],
      responseFailList: [],
      responseFailTable: [],
      chartLineView: false,
      chartLineViewNum: 0,
      chartLineViewName: '',
      barXaxisName: [],
      barXaxisValue: [],
      WeekList: ['日', '一', '二', '三', '四', '五', '六'],
      pagination: {
        rowsPerPage: 1,
        sortBy: 'failRate', // The field that you're sorting by
        descending: true
      },
      headers: [
        {
          sortable: true,
          text: 'ModelName',
          value: 'modelName'
        },
        {
          sortable: true,
          text: '生產數量',
          value: 'productionQty',
          align: 'right'
        },
        {
          sortable: true,
          text: '總不良數量',
          value: 'failQty',
          align: 'right'
        },
        {
          sortable: true,
          text: '總不良率',
          value: 'failRate',
          align: 'right'
        },
        {
          sortable: true,
          text: 'Stage',
          value: 'stageList'
        },
        {
          sortable: true,
          text: 'EMS',
          value: 'ems'
        },
        {
          sortable: false,
          text: '操作',
          value: 'operation'
        }
      ],
      handler_line: new Vue()
    }
  },
  computed: {
    ...mapState('app', ['theme', 'shrink']),
    options_line () {
      return {
        data: {
          x: 'x',
          columns: [
            this.barXaxisName,
            this.barXaxisValue
          ],
          labels: {
            // format: function (v, id, i, j) { return v + '%' }
          },
          type: 'line',
          colors: {
            // ModelName: '#999999'
          }
          // color: function (color, d) {
          //   // d will be 'id' when called for legends
          //   return d.value >= 5.0 ? '#ff0000' : d.value >= 3.0 ? '#ff9800' : color
          // }
        },
        axis: {
          x: {
            type: 'category', // this needed to load string x value
            tick: {
              rotate: -45,
              multiline: false
            },
            label: {
              text: '日期',
              position: 'outer-center'
            }
          },
          y: {
            tick: {
              format: function (value) { return value + '%' }
            },
            label: {
              text: '不良率',
              position: 'outer-middle'
            }
          }
        },
        tooltip: {
          format: {
            title: (d, value) => {
              return `${this.barXaxisName[d + 1]}`
              // return `第 ${d} 期`
            },
            value: (value, ratio, id) => {
              return `${value} %`
              // return `${value} 億`
            }
          }
        },
        grid: {
          x: {
            show: true
          },
          y: {
            show: true
          }
        },
        legend: {
          position: 'bottom',
          padding: 16,
          show: false
        },
        size: {
          width: ((window.innerWidth - this.shrink) * 0.85) - 64,
          height: 500
        }
      }
    }
  },
  watch: {
    summaryMonth (val) {
      this.summaryMonthMenu = false
    },
    chartLineView (val) {
      if (val) {
        this.barXaxisName = ['x']
        this.barXaxisValue = ['不良率']
        this.responseFailList.forEach((r) => {
          this.barXaxisName.push(this.$moment(r.productionDate).format('MM-DD'))
          this.barXaxisValue.push('')
          if (r.productionData.length > 0) {
            r.productionData.forEach((rr) => {
              if (rr.modelName === this.chartLineViewName) {
                this.barXaxisValue[this.barXaxisValue.length - 1] = (rr.failRate * 100).toFixed(1)
              }
            })
          }
        })
        if (this.chartLineViewNum === 0) {
          this.handler_line.$emit('init', this.options_line)
          this.chartLineViewNum++
        } else {
          this.handler_line.$emit('dispatch', chart => {
            const options = {
              columns: [
                this.barXaxisName,
                this.barXaxisValue
              ]
            }
            chart.load(options)
          })
        }
      } else {
        // this.handler_line.$emit('destroy')
      }
    }
  },
  mounted () {
    this.$store.dispatch('getAuthorize', '/thermal/monthlyThermalFailRateSummary')
      .then(response => {
        if (response.status === 'success' && response.data.accessible) {
          JSON.parse(localStorage.getItem('moduleDataInitDate')).forEach((n) => {
            if (n.name === 'thermal') {
              this.startdate = n.value
            }
          })
          if (this.$route.params.searchDate) {
            this.summaryMonth = this.$route.params.searchDate
          }
          this.$http.get('/api/getSelectOptionData/stage').then(res => res.data)
            .then(res => {
              if (res.status === 'success') {
                this.summaryStageMenu = [{ text: 'All', value: 'All' }].concat(res.data)
              }
            })
          this.$http.get('/api/getSelectOptionData/ems').then(res => res.data)
            .then(res => {
              if (res.status === 'success') {
                this.summaryEMSMenu = [{ text: 'All', value: 'All' }].concat(res.data)
              }
            })
          this.$http.get('/api/getSelectOptionData/modelName').then(res => res.data)
            .then(res => {
              if (res.status === 'success') {
                this.summaryModelNameMenu = [{ text: 'All', value: 'All' }].concat(res.data)
              }
            })
          this.loadData()
        } else if (response.errCode === 'E10000002') {
          const routeData = this.$router.resolve({ path: '/noEnter/E10000002' })
          localStorage.setItem('errormsg', response.errMsg)
          window.location.assign(routeData.href)
        } else {
          const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
          window.location.assign(routeData.href)
        }
      })
      .catch(err => {
        console.log(err)
        const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
        window.location.assign(routeData.href)
      })
  },
  methods: {
    async loadData () {
      this.loadingView = true
      await this.YearWeeksList()
      await this.AllYearWeeksList()
      await this.doSubmit()
    },
    YearWeeksList () {
      const Arr = []
      const YearStart = this.$moment(this.startdate).format('YYYY')
      const YearEnd = this.$moment(new Date()).format('YYYY')
      let NewYear = false
      let YearEndWeek = 0

      for (let y = 0; y <= (YearEnd - YearStart + 1); y++) {
        Arr[y] = []
        let w = 0
        let n = 0
        let day = new Date(Number(YearStart) + y + '-01-01').getTime()
        if (y === 0 && this.$moment(this.startdate).format('MM-DD') !== '01-01') {
          day = new Date(Number(YearStart) + y + '-' + this.$moment(this.startdate).format('MM-DD')).getTime()
          w = Math.ceil((((new Date(this.$moment(this.startdate).format('YYYY-MM-DD')) - new Date(new Date(this.$moment(this.startdate).format('YYYY'), 0, 1))) / 86400000) + new Date(new Date(this.$moment(this.startdate).format('YYYY'), 0, 1)).getDay()) / 7)
          if (new Date(this.$moment(this.startdate).format('YYYY') + '-01-01').getDay() > 0) {
            w = w - 1
          }
          Arr[y][w] = { start: this.$moment(this.startdate).format('YYYY-MM-DD'), end: '' }
          if (new Date(this.$moment(this.startdate).format('YYYY-MM-DD')).getDay() === 6) {
            Arr[y][w].end = this.$moment(this.startdate).format('YYYY-MM-DD')
          } else if (new Date(this.$moment(this.startdate).format('YYYY-MM-DD')).getDay() === 0) {
            w = w - 1
          }
        } else if (new Date(Number(YearStart) + y + '-01-01').getDay() !== 0) {
          n = 6 - new Date(Number(YearStart) + y + '-01-01').getDay()
          Arr[y][1] = { start: '', end: '' }
        }
        for (let d = (day + (86400000 * n)); d <= (new Date(Number(YearStart) + y + '-12-31').getTime()); d = d + 86400000) {
          if (y > 0 && new Date(d).getDay() === 6) {
            if (Arr[y][1].start === '') {
              Arr[y - 1][YearEndWeek].end = this.$moment(d).format('YYYY-MM-DD')
              Arr[y][0] = { start: Arr[y - 1][YearEndWeek].start, end: this.$moment(d).format('YYYY-MM-DD') }
              w = 0
              YearEndWeek = w
              NewYear = true
            }
          }
          if (new Date(d).getDay() === 0) {
            w++
            YearEndWeek = w
            NewYear = false
            Arr[y][w] = { start: this.$moment(d).format('YYYY-MM-DD'), end: '' }
          } else if (!NewYear && new Date(d).getDay() === 6) {
            Arr[y][w].end = this.$moment(d).format('YYYY-MM-DD')
          }
        }
      }
      this.summaryYearWeeksList = Arr
    },
    AllYearWeeksList () {
      const SearchArr = []
      const YearStart = this.$moment(this.startdate).format('YYYY')
      const YearEnd = this.$moment(this.summaryMonth).format('YYYY')

      for (let y = 0; y <= (YearEnd - YearStart); y++) {
        SearchArr[y] = []
        for (let w = 1; w < this.summaryYearWeeksList[y].length; w++) {
          if (this.summaryYearWeeksList[y][w]) {
            SearchArr[y].push({ start: this.summaryYearWeeksList[y][w].start, end: this.summaryYearWeeksList[y][w].end })
          }
        }
      }
      this.summarySearchYearWeeksList = SearchArr
    },
    doSubmit () {
      this.loadingView = true
      const WeeksStart = Math.ceil((((new Date(this.summaryMonth + '-01') - new Date(new Date(this.$moment(this.summaryMonth).format('YYYY'), 0, 1))) / 86400000) + new Date(new Date(this.$moment(this.summaryMonth).format('YYYY'), 0, 1)).getDay()) / 7)
      const WeeksEnd = Math.ceil((((new Date(this.summaryMonth + '-' + this.$moment(new Date(this.$moment(this.summaryMonth).format('YYYY'), this.$moment(this.summaryMonth).format('MM'), 0)).format('DD')) - new Date(new Date(this.$moment(this.summaryMonth).format('YYYY'), 0, 1))) / 86400000) + new Date(new Date(this.$moment(this.summaryMonth).format('YYYY'), 0, 1)).getDay()) / 7)
      let SearchLastYear = 0

      let queryStartWeeks = WeeksStart
      const queryEndWeeks = WeeksEnd
      if (queryStartWeeks === 1 && this.$moment(this.summaryMonth).format('YYYY') !== this.$moment(this.startdate).format('YYYY') && new Date(this.$moment(this.summaryMonth).format('YYYY') + '-01-01').getDay() > 0) {
        queryStartWeeks = this.summarySearchYearWeeksList[this.$moment(this.summaryMonth).format('YYYY') - this.$moment(this.startdate).format('YYYY') - 1].length + 1
        SearchLastYear = 1
      }
      const Arr = []
      let LastYear = 0
      if (new Date(this.$moment(this.summaryMonth).format('YYYY') + '-01-01').getDay() > 0) {
        LastYear = 1
      }
      this.downloadStartDate = this.summarySearchYearWeeksList[this.$moment(this.summaryMonth).format('YYYY') - this.$moment(this.startdate).format('YYYY') - SearchLastYear][Number(queryStartWeeks) - 1 - LastYear].start
      this.downloadEndDate = this.summarySearchYearWeeksList[this.$moment(this.summaryMonth).format('YYYY') - this.$moment(this.startdate).format('YYYY')][Number(queryEndWeeks) - 1 - LastYear].end

      const body = {
        queryStartDate: this.summarySearchYearWeeksList[this.$moment(this.summaryMonth).format('YYYY') - this.$moment(this.startdate).format('YYYY') - SearchLastYear][Number(queryStartWeeks) - 1 - LastYear].start,
        queryEndDate: this.summarySearchYearWeeksList[this.$moment(this.summaryMonth).format('YYYY') - this.$moment(this.startdate).format('YYYY')][Number(queryEndWeeks) - 1 - LastYear].end,
        stage: this.summaryStage === 'All' ? '' : this.summaryStage,
        ems: this.summaryEMS === 'All' ? '' : this.summaryEMS,
        failRate: this.summaryFailRate === 'All' ? '' : this.summaryFailRate,
        modelPk: this.summaryModelName === 'All' ? '' : this.summaryModelName
      }

      this.$http.post('/api/thermal/thermalBurnInFailRateSummaryList', body).then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.pagination.rowsPerPage = res.data.length
            this.responseFailTable = res.data
          }
        })
      this.$http.post('/api/thermal/dailyThermalBurnInFailRateInfo', body).then(res => res.data)
        .then(res => {
          this.loadingView = false
          if (res.status === 'success') {
            this.responseFailList = res.data
            for (let i = 0; i <= (WeeksEnd - WeeksStart); i++) {
              let start = this.$moment(this.summaryYearWeeksList[this.$moment(this.summaryMonth).format('YYYY') - this.$moment(this.startdate).format('YYYY')][Number(WeeksStart) + i - LastYear].start).format('DD')
              let end = this.$moment(this.summaryYearWeeksList[this.$moment(this.summaryMonth).format('YYYY') - this.$moment(this.startdate).format('YYYY')][Number(WeeksStart) + i - LastYear].end).format('DD')
              if (i === 0 && start > end) {
                start = '01'
              } else if (i === (WeeksEnd - WeeksStart) && end < start) {
                end = this.$moment(new Date(this.$moment(this.summaryMonth).format('YYYY'), this.$moment(this.summaryMonth).format('MM'), 0)).format('DD')
              }
              Arr[i] = { weeks: Number(WeeksStart) + i - LastYear, date: [] }
              if (Arr[i].weeks === 0) {
                Arr[i].weeks = this.summaryYearWeeksList[this.$moment(this.summaryMonth).format('YYYY') - this.$moment(this.startdate).format('YYYY') - 1].length - 1
              }

              const BeforeSpaceList = []
              const lastMonthEndDate = this.$moment(new Date(this.summaryMonth) - 86400000).format('DD')
              for (let i = 0; i < new Date(this.summaryMonth).getDay(); i++) {
                BeforeSpaceList.push({ date: this.$moment(this.$moment(new Date(this.summaryMonth + '-01').getTime() - 86400000).format('YYYY-MM') + '-' + (lastMonthEndDate - new Date(this.summaryMonth).getDay() + i + 1)).format('YYYY-MM-DD'), day: lastMonthEndDate - new Date(this.summaryMonth).getDay() + i + 1, around: true, fail: res.data[i].productionData.length > 0, over: res.data[i].productionData.length > 5, failList: res.data[i].productionData })
              }

              if (i === 0) {
                for (let n = 0; n < BeforeSpaceList.length; n++) {
                  Arr[i].date.push(BeforeSpaceList[n])
                }
              }

              const len = BeforeSpaceList.length - 1
              for (let d = 0; d <= (end - start); d++) {
                const dd = (Number(start) + d) < 10 ? '0' + (Number(start) + d) : (Number(start) + d)
                Arr[i].date.push({ date: this.$moment(this.summaryMonth + '-' + dd).format('YYYY-MM-DD'), day: Number(start) + d, fail: res.data[Number(start) + d + len].productionData.length > 0, over: res.data[Number(start) + d + len].productionData.length > 5, failList: res.data[Number(start) + d + len].productionData })
              }

              const AfterSpaceList = []
              const MonthEndDateWeek = new Date(this.summaryMonth + '-' + this.$moment(new Date(this.$moment(this.summaryMonth).format('YYYY'), this.$moment(this.summaryMonth).format('MM'), 0)).format('DD')).getDay()
              if ((6 - MonthEndDateWeek) > 0) {
                for (let i = 0; i < 6 - MonthEndDateWeek; i++) {
                  const dd = (i + 1) < 10 ? '0' + (i + 1) : (i + 1)
                  AfterSpaceList.push({ date: this.$moment(this.$moment(new Date(this.summaryMonth + '-' + this.$moment(new Date(this.$moment(this.summaryMonth).format('YYYY'), this.$moment(this.summaryMonth).format('MM'), 0)).format('DD')).getTime() + 86400000).format('YYYY-MM') + '-' + dd).format('YYYY-MM-DD'), day: i + 1, around: true, fail: res.data[res.data.length - (6 - MonthEndDateWeek) + i].productionData.length > 0, over: res.data[res.data.length - (6 - MonthEndDateWeek) + i].productionData.length > 5, failList: res.data[res.data.length - (6 - MonthEndDateWeek) + i].productionData })
                }
              }

              if (i === (WeeksEnd - WeeksStart)) {
                for (let n = 0; n < AfterSpaceList.length; n++) {
                  Arr[i].date.push(AfterSpaceList[n])
                }
              }
            }
            this.summaryMonthWeeksList = Arr
          }
        })
    },
    summaryWeeksArray (weeks) {
      if (this.summaryWeeksUnfoldList.findIndex(function checkUnfold (ww) { return ww === weeks }) === -1) {
        this.summaryWeeksUnfoldList.push(weeks)
      } else {
        this.summaryWeeksUnfoldList.splice(this.summaryWeeksUnfoldList.findIndex(function checkUnfold (ww) { return ww === weeks }), 1)
      }
    },
    summaryWeeksMatch (weeks) {
      return this.summaryWeeksUnfoldList.findIndex(function checkUnfold (ww) { return ww === weeks }) > -1
    },
    doDownload (item) {
      this.processingView = true
      const body = {
        modelName: item.modelName,
        factoryName: item.ems,
        queryStartDate: this.downloadStartDate,
        queryEndDate: this.downloadEndDate,
        productionQty: item.productionQty
      }
      this.$http.post('/api/thermal/thermalMonthlyTestLogFile', body, { responseType: 'blob' })
        .then(res => {
          this.processingView = false
          if (res.data.type === 'application/json') {
            return new Promise((resolve, reject) => {
              const reader = new FileReader()
              reader.onload = () => {
                res.data = JSON.parse(reader.result)
                if (res.data.status === 'success') {
                  alert(res.data.data)
                } else {
                  this.snackbar = true
                  this.snackbarColor = 'red'
                  this.snackbarMsg = res.data.errMsg + ' (' + res.data.errCode + ')'
                }
              }
              reader.onerror = () => {
                reject(res.data.errMsg || res.data.data)
              }
              reader.readAsText(res.data)
            })
          } else if (res.data.status !== 'failure' && res.headers['content-disposition']) {
            const blob = new Blob([res.data], { type: 'application/octet-stream' })
            const url = window.URL.createObjectURL(blob)
            const fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]
            const downloadLink = document.createElement('a')
            downloadLink.href = url
            downloadLink.download = fileName

            document.body.appendChild(downloadLink)
            downloadLink.click()
            document.body.removeChild(downloadLink)
          } else {
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = '下載失敗，請通知系統管理員'
          }
        })
        .catch(err => {
          this.processingView = false
          this.snackbar = true
          this.snackbarColor = 'orange'
          this.snackbarMsg = err
        })
    },
    doDownloadPowerData (item) {
      this.processingView = true
      const body = {
        modelName: item.modelName,
        factoryName: item.ems,
        queryStartDate: this.downloadStartDate,
        queryEndDate: this.downloadEndDate,
        productionQty: item.productionQty
      }
      this.$http.post('/api/thermal/powerMonthlyTestLogFile', body, { responseType: 'blob' })
        .then(res => {
          this.processingView = false
          if (res.data.type === 'application/json') {
            return new Promise((resolve, reject) => {
              const reader = new FileReader()
              reader.onload = () => {
                res.data = JSON.parse(reader.result)
                if (res.data.status === 'success') {
                  alert(res.data.data)
                } else {
                  this.snackbar = true
                  this.snackbarColor = 'red'
                  this.snackbarMsg = res.data.errMsg + ' (' + res.data.errCode + ')'
                }
              }
              reader.onerror = () => {
                reject(res.data.errMsg || res.data.data)
              }
              reader.readAsText(res.data)
            })
          } else if (res.data.status !== 'failure' && res.headers['content-disposition']) {
            const blob = new Blob([res.data], { type: 'application/octet-stream' })
            const url = window.URL.createObjectURL(blob)
            const fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]
            const downloadLink = document.createElement('a')
            downloadLink.href = url
            downloadLink.download = fileName

            document.body.appendChild(downloadLink)
            downloadLink.click()
            document.body.removeChild(downloadLink)
          } else {
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = '下載失敗，請通知系統管理員'
          }
        })
        .catch(err => {
          this.processingView = false
          this.snackbar = true
          this.snackbarColor = 'orange'
          this.snackbarMsg = err
        })
    }
  }
}
</script>
<style>
</style>
