<template>
  <v-container
    fundamental
    fluid
    grid-list-xl
    pt-0
  >
    <v-layout wrap>
      <v-flex
        v-if="!processingView&&tableView==='notification'"
        flex_table_width_auto
        xs12
        lg12>
        <material-card
          id="checkTable"
          :style="'max-width: calc(100vw - '+(Number(shrink)+32)+'px);'"
          title="各通知項目聯絡人資訊"
          color="deep-orange"
          class="card-mix d-table-t0 no-data-left"
          style="margin-top: 30px!important;">
          <v-text-field
            v-model="keywordFilter"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            class="mix-input"
            style="right: 15rem;"
            append-icon="search"
            label="過濾聯絡人"
            single-line
            hide-details/>
          <!-- <v-select
            v-model="summary工廠類別"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="summary工廠類別Menu"
            class="mix-list v-text-field__slot d-inline-block float-right ml-5"
            style="width: 90px;"
            persistent-hint
            outlined
          /> -->
          <v-btn
            class="mix-btn mx-0 font-weight-light small"
            @click="doAddWindows()"
          >
            <v-icon class="mr-2">mdi-plus</v-icon>新增共用聯絡人
          </v-btn>
          <v-data-table
            :headers="responseHeaders"
            :items="responseData"
            class="height-row-3 table-align-left"
            multi-sort
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="subheading font-weight-light text-general text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <tr>
                <td class="table-width-200">{{ item.notificationCase }}</td>
                <td class="table-width-300">{{ item.description }}</td>
                <td style="padding: 10px!important;">
                  <span v-show="keywordFilter!==''">符合過濾的結果：</span>
                  <v-chip
                    v-for="(user,u) in item.userList"
                    v-show="keywordFilter===''||(user.userName.toUpperCase().search(keywordFilter.toUpperCase())!==-1||user.userMail.toUpperCase().search(keywordFilter.toUpperCase())!==-1)"
                    :key="'u'+u"
                    class="cursor"
                    style="margin:4px!important;"
                    small
                    @click="doEditWindows(user.pk, user.userName, user.userMail, user.userType, item.notificationCase, null)">{{ user.userName }} ｜ {{ user.userMail }}</v-chip>
                </td>
                <td>
                  <div>
                    <v-btn
                      v-if="item.notificationCase!=='repair_parts_statistic_report'"
                      class="ml-0 mr-2 font-weight-bold small"
                      color="pink"
                      @click="doAddWindows(item.notificationCase, null)"
                    >
                      <v-icon class="mr-2">mdi-plus</v-icon>新增
                    </v-btn>
                    <v-btn
                      v-if="item.notificationCase==='repair_parts_statistic_report'"
                      class="ml-0 mr-2 font-weight-bold small"
                      color="deep-purple"
                      @click="doGroupdataLoad(item.notificationCase)"
                    >
                      <v-icon class="mr-2">mdi-pencil</v-icon>編輯
                    </v-btn>
                    <!-- <v-btn
                      :disabled="item.processing"
                      class="mx-0 font-weight-bold small"
                      color="orange"
                      @click="doUploadProductDataJob(item.code)">
                      <v-icon class="mr-2">mdi-file-replace-outline</v-icon>轉檔
                    </v-btn> -->
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </material-card>
      </v-flex>
      <v-flex
        v-if="tableView==='modelGroup'"
        xs12
        lg12
      >
        <material-card
          title="機種不良率及料件耗用分析通知聯絡人資訊搜尋"
          class="card-mix card-padding v-card__first"
          color="general">
          <v-btn
            class="mix-btn mx-0 grey font-weight-light small"
            style="right: 8rem;"
            @click="doSubmit()"
          >
            <v-icon class="mr-2">mdi-arrow-left</v-icon>返回總表
          </v-btn>
          <v-btn
            class="mix-btn mx-0 font-weight-light small"
            @click="modelYear='';modelGroup=[];model=[]"
          >清除重置</v-btn>
          <v-form>
            <v-container
              py-0
              class="mw-100">
              <v-layout wrap>
                <v-flex
                  flex-small
                  xs12
                  sm6
                  lg2
                  style="display: flex;">
                  <v-autocomplete
                    v-model="modelYear"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="modelYearMenu"
                    label="MODEL_YEAR"
                    class="v-text-field__slot issue_tracking_menu_chips"
                    color="white"
                    style="margin-top: 10px;"
                    persistent-hint
                    outlineds/>
                    <!-- <v-btn
                      class="mt-3 ml-3 mr-0 font-weight-light"
                      color="black"
                      @click="modelYear=[]"
                    >清除</v-btn> -->
                </v-flex>
                <v-flex
                  flex-small
                  xs12
                  sm6
                  lg3
                  style="display: flex;">
                  <v-autocomplete
                    v-model="modelGroup"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="modelGroupMenu"
                    label="MODEL_GROUP"
                    class="v-text-field__slot issue_tracking_menu_chips"
                    color="white"
                    persistent-hint
                    outlined
                    multiple
                    multitude
                    chips/>
                    <!-- <v-btn
                      class="mt-3 ml-3 mr-0 font-weight-light"
                      color="black"
                      @click="modelGroup=[]"
                    >清除</v-btn> -->
                </v-flex>
                <v-flex
                  flex-small
                  xs12
                  sm6
                  lg3
                  style="display: flex;">
                  <v-autocomplete
                    v-model="model"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="modelMenu"
                    label="MODEL"
                    class="v-text-field__slot issue_tracking_menu_chips"
                    color="white"
                    persistent-hint
                    outlined
                    multiple
                    multitude
                    chips/>
                    <!-- <v-btn
                      class="mt-3 ml-3 mr-0 font-weight-light"
                      color="black"
                      @click="model=[]"
                    >清除</v-btn> -->
                </v-flex>
                <!-- <v-flex
                  flex-small
                  xs12
                  sm6
                  lg4>
                  <v-text-field
                    v-model="notificationUser"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    label="聯絡人關鍵字"/>
                </v-flex> -->
                <!-- <v-flex
                  flex-small
                  xs12
                  sm6
                  lg3
                  style="display: flex;">
                  <v-autocomplete
                    v-model="notificationUser"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="notificationUserMenu"
                    label="聯絡人"
                    class="v-text-field__slot issue_tracking_menu_chips"
                    color="white"
                    persistent-hint
                    outlined
                    multiple
                    multitude
                    chips/>
                  <v-btn
                    class="mt-3 ml-3 mr-0 font-weight-light"
                    color="black"
                    @click="notificationUser=[]"
                  >清除</v-btn>
                </v-flex> -->
                <v-flex
                  xs12
                  lg3
                />
                <v-flex
                  xs12
                  lg1
                  text-xs-right
                >
                  <v-btn
                    class="mx-0 font-weight-light"
                    color="general"
                    @click="doGroupTable(true)"
                  >
                    <v-icon class="mr-2">mdi-backup-restore</v-icon>查詢
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
      </v-flex>
      <v-flex
        v-if="!processingView&&doGroupSubmit&&tableView==='modelGroup'"
        flex_table_width_auto
        xs12
        lg12>
        <material-card
          id="checkTable"
          :style="'max-width: calc(100vw - '+(Number(shrink)+32)+'px);'"
          title="機種不良率及料件耗用分析通知聯絡人資訊"
          color="deep-orange"
          class="card-mix d-table-t0 no-data-left"
          style="margin-top: 30px!important;">
          <v-select
            v-model="groupPagination.rowsPerPage"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="groupRowsAmount"
            class="mix-list v-text-field__slot d-inline-block float-right ml-5"
            style="width: 90px;"
            persistent-hint
            outlined
          />
          <v-text-field
            v-model="keywordFilterForGroup"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            class="mix-input"
            style="right: 19rem;"
            append-icon="search"
            label="過濾聯絡人"
            single-line
            hide-details/>
          <!-- <v-select
            v-model="summary工廠類別"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="summary工廠類別Menu"
            class="mix-list v-text-field__slot d-inline-block float-right ml-5"
            style="width: 90px;"
            persistent-hint
            outlined
          /> -->
          <v-btn
            class="mix-btn mx-0 font-weight-light small"
            style="right: 8.5rem;"
            @click="doAddWindows()"
          >
            <v-icon class="mr-2">mdi-plus</v-icon>新增共用聯絡人
          </v-btn>
          <v-data-table
            :headers="groupHeaders"
            :items="groupData"
            :pagination.sync="groupPagination"
            :rows-per-page-items ="groupRowsAmount"
            class="height-row-3 table-align-left"
            multi-sort
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="subheading font-weight-light text-general text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <tr>
                <td class="table-width-100">{{ item.year }}</td>
                <td class="table-width-150">{{ item.modelGroup }}</td>
                <td class="table-width-300">{{ item.modelList.join(' , ') }}</td>
                <td style="padding: 10px!important;">
                  <span v-show="keywordFilterForGroup!==''">符合過濾的結果：</span>
                  <v-chip
                    v-for="(user,u) in item.userList"
                    v-show="keywordFilterForGroup===''||(user.userName.toUpperCase().search(keywordFilterForGroup.toUpperCase())!==-1||user.userMail.toUpperCase().search(keywordFilterForGroup.toUpperCase())!==-1)"
                    :key="'u'+u"
                    class="cursor"
                    style="margin:4px!important;"
                    small
                    @click="doEditWindows(user.pk, user.userName, user.userMail, user.userType, null, item.modelGroup, item.year)">{{ user.userName }} ｜ {{ user.userMail }}</v-chip>
                </td>
                <td>
                  <div>
                    <v-btn
                      class="ml-0 mr-2 font-weight-bold small"
                      color="pink"
                      @click="doAddWindows(null, item.modelGroup, item.year)"
                    >
                      <v-icon class="mr-2">mdi-plus</v-icon>新增
                    </v-btn>
                    <!-- <v-btn
                      :disabled="item.processing"
                      class="mx-0 font-weight-bold small"
                      color="orange"
                      @click="doUploadProductDataJob(item.code)">
                      <v-icon class="mr-2">mdi-file-replace-outline</v-icon>轉檔
                    </v-btn> -->
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
          <div
            v-if="groupPagination.rowsPerPage != -1"
            class="text-xs-center pt-2 d-inline-block"
            style="margin-left: 0px;width: calc(100% - 140px);">
            <v-pagination
              v-model="groupPagination.page"
              :length="groupPages"/>
          </div>
          <v-autocomplete
            v-if="groupPagination.rowsPerPage != -1"
            v-model="groupPagination.page"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="groupPageslist"
            class="v-text-field__slot d-inline-block float-right mr-0"
            style="width: 90px;"
            persistent-hint
            outlined
          />
        </material-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="windowsForAddOrEdit"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <v-card
        max-width="25%"
        style="margin:0 auto;">
        <v-card-text>
          <v-alert
            v-model="windowsForAddOrEdit"
            class="mt-3 mb-3 border-0"
            color="deep-orange">
            <strong class="h4">{{ (windows.notificationCase||windows.modelGroup)?(windows.notificationCase||windows.modelGroup):"全" }}通知項目通知維護</strong>
          </v-alert>
          <v-flex
            v-if="action==='add'&&(windows.notificationCase||windows.modelGroup)"
            d-flex
            xs12
            lg12>
            <v-radio-group
              v-model="windows.userType"
              class="align-items-center"
              row>
              <v-radio
                label="新增內部聯絡人"
                value="internal"/>
              <v-radio
                label="新增外部聯絡人"
                value="external"/>
            </v-radio-group>
          </v-flex>
          <v-flex
            v-if="windows.userType==='external'"
            flex-small
            d-flex
            align-items-center
            xs12
            lg12>
            <p class="m-0 w-25">姓名</p>
            <v-text-field
              v-model="windows.name"
              :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
              placeholder="輸入姓名..."
              label=""
              class="w-75"
            />
          </v-flex>
          <v-flex
            v-if="windows.userType==='external'"
            flex-small
            d-flex
            align-items-center
            xs12
            lg12>
            <p class="m-0 w-25">信箱</p>
            <v-text-field
              v-model="windows.mail"
              :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
              placeholder="輸入信箱..."
              label=""
              class="w-75"
            />
          </v-flex>
          <v-flex
            v-if="action==='add'&&windows.userType==='internal'"
            flex-small
            d-flex
            align-items-center
            xs12
            lg12>
            <p class="m-0 w-25">姓名</p>
            <v-autocomplete
              v-model="windows.index"
              :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
              :items="notificationUserNameMenu"
              placeholder="選擇姓名..."
              label=""
              class="v-text-field__slot w-75"
              persistent-hint
              outlined
            />
          </v-flex>
          <v-flex
            v-if="action==='add'&&windows.userType==='internal'"
            flex-small
            d-flex
            align-items-center
            xs12
            lg12>
            <p class="m-0 w-25">信箱</p>
            <v-autocomplete
              v-model="windows.index"
              :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
              :items="notificationUserMailMenu"
              placeholder="選擇信箱..."
              label=""
              class="v-text-field__slot w-75"
              persistent-hint
              outlined
            />
          </v-flex>
          <v-flex
            v-if="action==='edit'&&windows.userType==='internal'"
            flex-small
            d-flex
            align-items-center
            xs12
            lg12>
            <p class="m-0 w-25">姓名</p>
            <v-text-field
              v-model="windows.name"
              :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
              placeholder="輸入姓名..."
              label=""
              class="w-75"
              disabled
            />
          </v-flex>
          <v-flex
            v-if="action==='edit'&&windows.userType==='internal'"
            flex-small
            d-flex
            align-items-center
            xs12
            lg12>
            <p class="m-0 w-25">信箱</p>
            <v-text-field
              v-model="windows.mail"
              :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
              placeholder="輸入信箱..."
              label=""
              class="w-75"
              disabled
            />
          </v-flex>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="grey"
            small
            @click="windowsForAddOrEdit=false">
            <v-icon class="mr-2">mdi-close</v-icon>返回
          </v-btn>
          <v-btn
            v-if="action==='add'"
            :disabled="!((windows.userType==='external'&&windows.name!=='' && windows.mail!=='') || (windows.userType==='internal'&&windows.index!==''))"
            color="blue"
            small
            @click="doAdd()">
            <v-icon class="mr-2">mdi-plus</v-icon>新增
          </v-btn>
          <v-btn
            v-if="action==='edit'&&windows.userType==='external'"
            :disabled="windows.name==='' || windows.mail===''"
            color="blue"
            small
            @click="doEdit()">
            <v-icon class="mr-2">mdi-content-save</v-icon>儲存
          </v-btn>
          <v-btn
            v-if="action==='edit'"
            color="red"
            small
            @click="doDelete()">
            <v-icon class="mr-2">mdi-delete</v-icon>刪除
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="processingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        PROCESSING 處理中
      </p>
    </v-dialog>
    <v-dialog
      v-model="loadingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :top="true"
      :color="snackbarColor">
      {{ snackbarMsg }}
      <v-btn
        dark
        flat
        @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import Loading from '@/assets/img/loading.gif'

export default {
  name: 'NotificationListMaintain',
  data () {
    return {
      action: 'add',
      doGroupSubmit: false,
      tableView: 'notification', // modelGroup
      windowsForAddOrEdit: false,
      snackbar: false,
      snackbarColor: 'red',
      snackbarMsg: '',
      Loading,
      loadingView: false,
      processingView: false,
      notificationCaseDescription: [],
      notificationUserNameMenu: [],
      notificationUserMailMenu: [],
      modelYear: '',
      modelYearTemp: '',
      modelYearMenu: [],
      model: [],
      modelTemp: [],
      modelMenu: [],
      modelGroup: [],
      modelGroupTemp: [],
      modelGroupMenu: [],
      // notificationUser: [],
      // notificationUserTemp: [],
      // notificationUserMenu: [],
      notificationCase: '',
      windows: {
        pk: 0,
        index: null,
        name: '',
        mail: '',
        userType: 'internal',
        notificationCase: '',
        modelGroup: '',
        year: ''
      },
      keywordFilter: '',
      keywordFilterForGroup: '',
      responseData: [],
      responseHeaders: [
        {
          align: '',
          class: '',
          sortable: true,
          text: '通知項目',
          value: 'notificationCase'
        }, {
          align: '',
          class: '',
          sortable: true,
          text: 'Description',
          value: 'description'
        }, {
          align: '',
          class: '',
          sortable: false,
          text: '聯絡人',
          value: 'userList'
        }, {
          align: '',
          class: '',
          sortable: false,
          text: '操作',
          value: 'operation'
        }
      ],
      groupData: [],
      groupHeaders: [
        {
          align: '',
          class: '',
          sortable: true,
          text: 'Year',
          value: 'year'
        }, {
          align: '',
          class: '',
          sortable: true,
          text: 'Model Group',
          value: 'modelGroup'
        }, {
          align: '',
          class: '',
          sortable: true,
          text: '機種',
          value: 'modelList'
        }, {
          align: '',
          class: '',
          sortable: false,
          text: '聯絡人',
          value: 'userList'
        }, {
          align: '',
          class: '',
          sortable: false,
          text: '操作',
          value: 'operation'
        }
      ],
      groupRowsAmount: [10, 25, 50, { text: 'All', value: -1 }],
      groupPagination: {
        page: 1,
        rowsPerPage: 10,
        sortBy: 'modelGroup', // The field that you're sorting by
        descending: true
      }
    }
  },
  computed: {
    ...mapState('app', ['theme', 'shrink']),
    groupPages () {
      return this.groupPagination.rowsPerPage ? Math.ceil(this.groupData.length / this.groupPagination.rowsPerPage) : 0
    },
    groupPageslist () {
      let i = 0
      const list = []
      if (this.groupPagination.rowsPerPage) {
        for (i = 0; i < Math.ceil(this.groupData.length / this.groupPagination.rowsPerPage); i++) {
          list.push(i + 1)
        }
      }
      return list
    }
  },
  watch: {
    'groupPagination.rowsPerPage' (val) {
      if (this.groupPagination.page > this.groupPages) {
        this.groupPagination.page = this.groupPages
      }
      if (this.groupPagination.page < 1) {
        this.groupPagination.page = 1
      }
    },
    modelYear () {
      this.modelGroup = []
      this.getModelGroup()
    },
    modelGroup () {
      this.model = []
      this.getModel()
    }
    // pagination: {
    //   handler () {
    //     this.doTableSortDetail()
    //   },
    //   deep: true
    // }
  },
  mounted () {
    this.$store.dispatch('getAuthorize', '/pqm/cscDataParsingMgmt/notificationListMaintainView')
      .then(response => {
        if (response.status === 'success' && response.data.accessible) {
          this.loadData()
        } else if (response.errCode === 'E10000002') {
          const routeData = this.$router.resolve({ path: '/noEnter/E10000002' })
          localStorage.setItem('errormsg', response.errMsg)
          window.location.assign(routeData.href)
        } else {
          const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
          window.location.assign(routeData.href)
        }
      })
      .catch(err => {
        console.log(err)
        const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
        window.location.assign(routeData.href)
      })
  },
  methods: {
    async loadData () {
      await this.getNotificationCaseDescription()
      await this.getUserMail()
      await setTimeout(() => {
        this.doSubmit()
      }, 1000)
    },
    getNotificationCaseDescription () {
      this.$http.get('/api/pqm/cscDataParsingMgmt/notificationCaseDefinitionList').then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.notificationCaseDescription = res.data
          }
        })
    },
    getUserMail () {
      this.$http.get('/api/system/moduleNotificationList/userMail').then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            res.data.forEach((n, nn) => {
              this.notificationUserNameMenu.push({ text: n.name, value: nn })
              this.notificationUserMailMenu.push({ text: n.email, value: nn })
            })
          }
        })
    },
    async getModelYear () {
      const data = await this.$httpGet('/api/pqm/cfrAnalysisMgmt/modelYear')
      if (data.status === 'success') {
        this.modelYearMenu = data.data
      }
    },
    getModel () {
      this.$http.get(`/api/pqm/cfr/model?modelGroupList=${this.modelGroup}`).then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.modelMenu = res.data
          }
        })
    },
    getModelGroup () {
      this.$http.get(`/api/pqm/cfr/modelGroup?year=${this.modelYear}&seriesList=`).then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.modelGroupMenu = res.data
          }
        })
    },
    async doGroupdataLoad (notificationCase) {
      this.notificationCase = notificationCase
      this.doGroupSubmit = false
      await this.doGroupTable(true)
      await this.getModelYear()
      this.tableView = 'modelGroup'
    },
    async doGroupTable (inquire) {
      if (inquire) {
        this.modelYearTemp = this.modelYear
        this.modelTemp = this.model
        this.modelGroupTemp = this.modelGroup
        // this.notificationUserTemp = this.notificationUser
      }
      await this.doSubmitForGroup()
    },
    doAddWindows (notificationCase, modelGroup, year) {
      this.action = 'add'
      this.windows = {
        pk: 0,
        index: null,
        name: '',
        mail: '',
        userType: 'internal',
        notificationCase: notificationCase,
        modelGroup: modelGroup,
        year: year
      }
      this.windowsForAddOrEdit = true
    },
    doEditWindows (pk, name, mail, type, notificationCase, modelGroup, year) {
      this.action = 'edit'
      this.windows = {
        pk: pk,
        index: null,
        name: name,
        mail: mail,
        userType: type,
        notificationCase: notificationCase,
        modelGroup: modelGroup,
        year: year
      }
      this.windowsForAddOrEdit = true
    },
    doSubmit () {
      this.loadingView = true
      this.groupData = []
      this.modelYear = ''
      this.$http.get('/api/pqm/cscDataParsingMgmt/notificationCaseList').then(res => res.data)
        .then(res => {
          this.loadingView = false
          if (res.status === 'success') {
            this.tableView = 'notification'
            this.responseData = []
            let description = ''
            res.data.forEach((n) => {
              if (this.responseData.map((e) => { return e.notificationCase }).indexOf(n.notificationCase) === -1) {
                if (this.notificationCaseDescription.map((e) => { return e.notificationCase }).indexOf(n.notificationCase) !== -1) {
                  description = this.notificationCaseDescription[this.notificationCaseDescription.map((e) => { return e.notificationCase }).indexOf(n.notificationCase)].description
                } else {
                  description = ''
                }
                this.responseData.push({ notificationCase: n.notificationCase, description: description, userList: [] })
              }
              this.responseData[this.responseData.map((e) => { return e.notificationCase }).indexOf(n.notificationCase)].userList.push({ pk: n.pk, userName: n.userName, userMail: n.userMail, userType: n.userType })
            })
            // 尚未有使用者的項目
            this.notificationCaseDescription.forEach((n) => {
              if (this.responseData.map((e) => { return e.notificationCase }).indexOf(n.notificationCase) === -1) {
                this.responseData.push({ notificationCase: n.notificationCase, description: n.description, userList: [] })
              }
            })
          }
        })
    },
    async doSubmitForGroup () {
      try {
        this.loadingView = true

        // 使用 Promise.all 发起两个请求
        const [notificationModelGroupResult, rptUserMappingResult] = await Promise.all([
          this.$httpGet(`/api/pqm/cscDataParsingMgmt/notificationCase/notificationModelGroup?modelYear=${this.modelYearTemp}&model=${this.modelTemp}&modelGroup=${this.modelGroupTemp}`),
          this.$httpGet(`/api/pqm/cscDataParsingMgmt/notificationCase/notificationModelCfrAndPartsStatisticReportCaseUser?modelYear=${this.modelYearTemp}&modelGroup=${this.modelGroupTemp}&model=${this.modelTemp}`)
        ])

        if (notificationModelGroupResult.status === 'success' && rptUserMappingResult.status === 'success') {
          // 当两个请求都完成后，您可以在这里处理响应数据
          this.processGroupData(notificationModelGroupResult.data, rptUserMappingResult.data)
        }

        this.loadingView = false
      } catch (error) {
        this.loadingView = false
        console.error(`Error in doSubmitForGroup(): ${error}`)
        console.error(error)
      }
    },
    processGroupData (notificationModelGroup, rptUserSettingList) {
      this.groupData = []
      rptUserSettingList.forEach((rptUserSetting) => {
        console.log(``)
        let groupDataItem = this.groupData.find(e => e.modelGroup === rptUserSetting.notificationCase.modelGroup && e.year === rptUserSetting.notificationCase.year)
        if (!groupDataItem) {
          const notificationCaseModelGroupItem = notificationModelGroup.find(e => e.modelGroup === rptUserSetting.notificationCase.modelGroup && e.year === rptUserSetting.notificationCase.year)
          const modelList = notificationCaseModelGroupItem ? notificationCaseModelGroupItem.modelList : []
          groupDataItem = {
            year: rptUserSetting.notificationCase.year,
            modelGroup: rptUserSetting.notificationCase.modelGroup,
            modelList: modelList,
            userList: []
          }
          this.groupData.push(groupDataItem)
        }
        groupDataItem.userList.push({
          pk: rptUserSetting.pk,
          userName: rptUserSetting.userName,
          userMail: rptUserSetting.userMail,
          userType: rptUserSetting.userType
        })
      })
      // 尚未有使用者的項目
      notificationModelGroup.forEach((n) => {
        if (!this.groupData.some(e => e.modelGroup === n.modelGroup && e.year === n.year)) {
          this.groupData.push({
            year: n.year,
            modelGroup: n.modelGroup,
            modelList: n.modelList,
            userList: []
          })
        }
      })
      this.doGroupSubmit = true
    },
    doAdd () {
      if (this.windows.userType === 'external') {
        const regexEmail = /^([a-z0-9_.-]+)@([\da-z.-]+)\.([a-z]{2,6})$/
        if (!regexEmail.test(this.windows.mail.replace(/(^[\s]*)|([\s]*$)/g, ''))) {
          this.snackbar = true
          this.snackbarColor = 'red'
          this.snackbarMsg = '信箱格式輸入錯誤'
          return
        } else {
          this.windows.mail = this.windows.mail.replace(/(^[\s]*)|([\s]*$)/g, '')
        }
      }
      this.windowsForAddOrEdit = false
      this.processingView = true
      let body = {}
      if (this.windows.userType === 'internal' && this.windows.modelGroup && this.windows.year) {
        body = {
          pk: this.windows.pk,
          name: this.notificationUserNameMenu[this.windows.index].text,
          mail: this.notificationUserMailMenu[this.windows.index].text,
          userType: this.windows.userType,
          notificationCase: this.windows.notificationCase,
          modelGroup: this.windows.modelGroup,
          year: this.windows.year
        }
      } else if (this.windows.userType === 'internal') {
        body = {
          pk: this.windows.pk,
          name: this.notificationUserNameMenu[this.windows.index].text,
          mail: this.notificationUserMailMenu[this.windows.index].text,
          userType: this.windows.userType,
          notificationCase: this.windows.notificationCase,
          modelGroup: this.modelGroupTemp
        }
      } else {
        body = {
          pk: this.windows.pk,
          name: this.windows.name,
          mail: this.windows.mail,
          userType: this.windows.userType,
          notificationCase: this.windows.notificationCase,
          modelGroup: this.windows.modelGroup,
          year: this.windows.year
        }
      }
      if (this.windows.notificationCase || this.windows.modelGroup) {
        if (this.tableView === 'notification') {
          this.$http.post('/api/pqm/cscDataParsingMgmt/notificationCaseUser', body).then(res => res.data)
            .then(res => {
              this.processingView = false
              if (res.status === 'success') {
                this.snackbar = true
                this.snackbarColor = 'green'
                this.snackbarMsg = '新增成功'
                this.doSubmit()
              } else { // failure
                this.windowsForAddOrEdit = true
                this.snackbar = true
                this.snackbarColor = 'red'
                this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
              }
            })
        } else if (this.tableView === 'modelGroup') {
          this.$http.post('/api/pqm/cscDataParsingMgmt/notificationCase/notificationModelCfrAndPartsStatisticReportCaseUser', body).then(res => res.data)
            .then(res => {
              this.processingView = false
              if (res.status === 'success') {
                this.snackbar = true
                this.snackbarColor = 'green'
                this.snackbarMsg = '新增成功'
                this.doGroupTable(false)
              } else { // failure
                this.windowsForAddOrEdit = true
                this.snackbar = true
                this.snackbarColor = 'red'
                this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
              }
            })
        }
      } else {
        if (this.tableView === 'notification') {
          this.$http.post('/api/pqm/cscDataParsingMgmt/notificationCaseSystemUser', body).then(res => res.data)
            .then(res => {
              this.processingView = false
              if (res.status === 'success') {
                this.snackbar = true
                this.snackbarColor = 'green'
                this.snackbarMsg = '新增成功'
                this.doSubmit()
              } else { // failure
                this.windowsForAddOrEdit = true
                this.snackbar = true
                this.snackbarColor = 'red'
                this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
              }
            })
        } else if (this.tableView === 'modelGroup') {
          this.$http.post('/api/pqm/cscDataParsingMgmt/notificationCase/notificationModelCfrAndPartsStatisticReportCaseSystemUser', body).then(res => res.data)
            .then(res => {
              this.processingView = false
              if (res.status === 'success') {
                this.snackbar = true
                this.snackbarColor = 'green'
                this.snackbarMsg = '新增成功'
                this.doGroupTable(false)
              } else { // failure
                this.windowsForAddOrEdit = true
                this.snackbar = true
                this.snackbarColor = 'red'
                this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
              }
            })
        }
      }
    },
    doEdit () {
      if (this.windows.userType === 'external') {
        const regexEmail = /^([a-z0-9_.-]+)@([\da-z.-]+)\.([a-z]{2,6})$/
        if (!regexEmail.test(this.windows.mail.replace(/(^[\s]*)|([\s]*$)/g, ''))) {
          this.snackbar = true
          this.snackbarColor = 'red'
          this.snackbarMsg = '信箱格式輸入錯誤'
          return
        } else {
          this.windows.mail = this.windows.mail.replace(/(^[\s]*)|([\s]*$)/g, '')
        }
      }
      this.windowsForAddOrEdit = false
      this.processingView = true
      const body = {
        pk: this.windows.pk,
        name: this.windows.name,
        mail: this.windows.mail,
        userType: this.windows.userType,
        notificationCase: this.windows.notificationCase,
        modelGroup: this.windows.modelGroup,
        year: this.windows.year
      }
      if (this.tableView === 'notification') {
        this.$http.put('/api/pqm/cscDataParsingMgmt/notificationCaseUser', body).then(res => res.data)
          .then(res => {
            this.processingView = false
            if (res.status === 'success') {
              this.snackbar = true
              this.snackbarColor = 'green'
              this.snackbarMsg = '修改成功'
              this.doSubmit()
            } else { // failure
              this.windowsForAddOrEdit = true
              this.snackbar = true
              this.snackbarColor = 'red'
              this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
            }
          })
      } else if (this.tableView === 'modelGroup') {
        this.$http.put('/api/pqm/cscDataParsingMgmt/notificationCase/notificationModelCfrAndPartsStatisticReportCaseUser', body).then(res => res.data)
          .then(res => {
            this.processingView = false
            if (res.status === 'success') {
              this.snackbar = true
              this.snackbarColor = 'green'
              this.snackbarMsg = '修改成功'
              this.doGroupTable(false)
            } else { // failure
              this.windowsForAddOrEdit = true
              this.snackbar = true
              this.snackbarColor = 'red'
              this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
            }
          })
      }
    },
    doDelete () {
      this.windowsForAddOrEdit = false
      this.processingView = true
      if (this.tableView === 'notification') {
        this.$http.delete(`/api/pqm/cscDataParsingMgmt/notificationCaseUser/${this.windows.pk}`).then(res => res.data)
          .then(res => {
            this.processingView = false
            if (res.status === 'success') {
              this.snackbar = true
              this.snackbarColor = 'green'
              this.snackbarMsg = '刪除成功'
              this.doSubmit()
            } else { // failure
              this.windowsForAddOrEdit = true
              this.snackbar = true
              this.snackbarColor = 'red'
              this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
            }
          })
      } else if (this.tableView === 'modelGroup') {
        this.$http.delete(`/api/pqm/cscDataParsingMgmt/notificationCase/notificationModelCfrAndPartsStatisticReportCaseUser/${this.windows.pk}`).then(res => res.data)
          .then(res => {
            this.processingView = false
            if (res.status === 'success') {
              this.snackbar = true
              this.snackbarColor = 'green'
              this.snackbarMsg = '刪除成功'
              this.doGroupTable(false)
            } else { // failure
              this.windowsForAddOrEdit = true
              this.snackbar = true
              this.snackbarColor = 'red'
              this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
            }
          })
      }
    }
  }
}
</script>
<style>
</style>
