<template>
  <v-toolbar
    id="core-toolbar"
    :dark="theme==='dark'"
    :light="theme==='light'"
    :class="{'toolbar_bg_dark' : theme==='dark' , 'toolbar_bg_light' : theme==='light'}"
    :style="'padding-left: '+shrink+'px;'"
    app
    flat
    prominent>
    <div class="v-toolbar-title">
      <v-toolbar-title class="font-weight-light ml-5">
        <!-- <v-icon
          class="toolbar-items"
          color
          @click="setShrink">mdi-logout</v-icon>
        <v-icon
          :style="(shrink== '260' ? 'left: 240px;transform: rotate(-90deg);': 'left: 60px;transform: rotate(90deg);') + (theme=='light' ? 'color:orangered;' : 'color:greenyellow;')"
          style="position: fixed;top: 1.1rem;font-size: 2rem;text-shadow: RGBA(0,0,0,0.5) 0px 0px 5px;"
          color
          @click="setShrink">mdi-triangle</v-icon> -->
        <v-btn
          :style="'left:' + shrink + 'px;transform: rotate(' + (parseInt(shrink / 250) * 180 + 180) + 'deg);'"
          class="default v-btn--simple"
          style="position: absolute;top: 0.5rem;"
          icon
          @click="setShrink">
          <v-icon>mdi-backburger</v-icon>
        </v-btn>
        <span
          :class="{ 'text-secondary': title }">{{ path }}
        </span>
        <span
          v-if="path && title"
          class="ml-2 mr-2 text-secondary">>
        </span>
        {{ title }}
      </v-toolbar-title>
    </div>

    <v-spacer/>

    <div :style="'text-decoration: underline; color: ' + (theme === 'dark' ? 'rgba(255,255,255,.87)':'rgba(0,0,0,.87)')">
      <!-- <span
        v-if="checkVisible()"
        :style="'cursor: pointer;'"
        class="mr-4 font-weight-bold pr-4"
        @click="doManualDownload()">
        CFR不良率分析使用者手冊下載
      </span> -->
      <span
        v-if="checkVisible()"
        v-show="responseDownloadlabData"
        :style="'cursor: pointer;'"
        class="mr-4 font-weight-bold pr-4"
        @click="doManualDownload()">
        {{ responseDownloadlabData.displayLabel }}
      </span>
    </div>

    <v-toolbar-items>
      <v-flex
        align-center
        layout
        py-2>
        <!-- <v-text-field
          v-if="responsiveInput"
          class="mr-4 mt-2 purple-input"
          label="Search..."
          hide-details
          color="purple"
        /> -->

        <!-- <router-link
          v-ripple
          class="toolbar-items"
          to="/">
          <v-icon color>mdi-home</v-icon>
        </router-link> -->

        <!-- <v-menu
          bottom
          left
          content-class
          offset-y
          transition="slide-y-transition">
          <router-link
            v-ripple
            slot="activator"
            class="toolbar-items"
            to="/dashboard/notifications"
          >
            <v-badge
              color="error"
              overlap>
              <template slot="badge">{{ notifications.length }}</template>
              <v-icon color>mdi-bell</v-icon>
            </v-badge>
          </router-link>
          <v-card>
            <v-list dense>
              <v-list-tile
                v-for="notification in notifications"
                :key="notification"
                @click="onClick">
                <v-list-tile-title v-text="notification"/>
              </v-list-tile>
            </v-list>
          </v-card>
        </v-menu> -->
        <!-- <router-link
          v-ripple
          class="toolbar-items"
          to="/dashboard/user-profile">
          <v-icon color>mdi-account</v-icon>
        </router-link> -->
        <div
          :style="'border-right: 1px solid ' + (theme === 'dark' ? 'rgba(255,255,255,.87)':'rgba(0,0,0,.87)')"
          class="mr-4 text-right">
          <span
            class="mr-4 font-weight-bold"
            style="font-size: 0.75rem;">{{ departmentName }}</span>
          <br>
          <span class="mr-4 font-weight-bold">{{ displayName }}</span>
        </div>
        <span
          v-if="enableDevMode"
          class="mr-2">開發模式</span>
        <v-switch
          v-if="enableDevMode"
          :color="theme==='dark'?'error':'info'"
          label=""
          value="develop"
          hide-details
          @change="setDemo"/>
        <v-icon
          class="toolbar-items"
          color
          @click="setTheme">mdi-theme-light-dark</v-icon>
        <v-icon
          class="toolbar-items"
          color
          @click="logout">mdi-logout</v-icon>
      </v-flex>
    </v-toolbar-items>
  </v-toolbar>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex'
// import DatePicker from 'vue2-datepicker'
// import 'vue2-datepicker/index.css'
// import { ModelSelect } from 'vue-search-select'
// import 'vue-search-select/dist/VueSearchSelect.css'
// import $ from 'jquery'
// window.$ = window.jQuery = $

export default {
  // components: { DatePicker, ModelSelect },
  data: () => ({
    // notifications: [
    //   'Mike, Thanos is coming',
    //   '5 new avengers joined the team',
    //   "You're now friends with Capt",
    //   'Another Notification',
    //   'Another One - Dj Khalid voice'
    // ],
    // path: '',
    // title: '',
    responsive: false,
    responsiveInput: false,
    responseDownloadlabData: '',
    apiKey: 'pqm_user_help_file'
  }),

  computed: {
    ...mapGetters(['authorized']),
    ...mapState('app', ['theme', 'shrink', 'demo']),
    path () {
      return this.$route.matched[0].meta.name
    },
    title () {
      return this.$route.meta.name
    },
    displayName () {
      return JSON.parse(localStorage.getItem('user')).displayName
    },
    departmentName () {
      return JSON.parse(localStorage.getItem('user')).departmentName
    },
    enableDevMode () {
      return JSON.parse(localStorage.getItem('user')).enableDevMode
    }
  },

  watch: {
    // $route (val) {
    // this.path = val.matched[0].meta.name
    // this.title = val.meta.name
    // console.log(val)
    // }
  },

  mounted () {
    if (!JSON.parse(localStorage.getItem('user')) || !localStorage.getItem('theme')) {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/')
      })
    }
    this.onResponsiveInverted()
    window.addEventListener('resize', this.onResponsiveInverted)
    this.timer = setInterval(() => {
      this.$store.state.app.theme = localStorage.getItem('theme')
    }, 1000)
    this.getDownloadLab()
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResponsiveInverted)
  },

  methods: {
    ...mapMutations('app', ['setShrink', 'setTheme', 'setDemo', 'setDrawer', 'toggleDrawer']),
    setShrink: function () {
      this.$store.state.app.shrink = this.$store.state.app.shrink === '260' ? '50' : '260'
    },
    onClickBtn () {
      // this.setDrawer(!this.$store.state.app.drawer)
    },
    onClick () {
      //
    },
    checkVisible: function () {
      if (this.$route.path.includes('/cfrAnalysisMgmt/')) {
        return true
      }
      return false
    },
    getDownloadLab: function () {
      const apiKey = this.apiKey
      this.$http.get(`/api/pqm/cscDataParsingMgmt/downloadResourceDefinition/${apiKey}`).then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.responseDownloadlabData = res.data
          }
          return res
        })
    },
    doManualDownload () {
      const apiKey = this.apiKey
      this.processingView = true
      this.$http.get('api/pqm/cscDataParsingMgmt/downloadResource/' + apiKey, { responseType: 'blob' })
        .then(res => {
          this.processingView = false
          if (res.data.type === 'application/json') {
            return new Promise((resolve, reject) => {
              const reader = new FileReader()
              reader.onload = () => {
                res.data = JSON.parse(reader.result)
                if (res.data.status === 'success') {
                  alert(res.data.data)
                  // resolve(Promise.reject(res.data.data))
                } else {
                  this.snackbar = true
                  this.snackbarColor = 'red'
                  this.snackbarMsg = res.data.errMsg + ' (' + res.data.errCode + ')'
                }
              }
              reader.onerror = () => {
                reject(res.data.data)
              }
              reader.readAsText(res.data)
            })
          } else if (res.data.status !== 'failure' && res.headers['content-disposition']) {
            const blob = new Blob([res.data], { type: 'application/octet-stream' })
            const url = window.URL.createObjectURL(blob)
            let fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]
            fileName = decodeURI(fileName)
            const downloadLink = document.createElement('a')
            downloadLink.href = url
            downloadLink.download = fileName

            document.body.appendChild(downloadLink)
            downloadLink.click()
            document.body.removeChild(downloadLink)
          } else {
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = '下載失敗，請通知系統管理員'
          }
        })
        .catch(err => {
          this.processingView = false
          this.snackbar = true
          this.snackbarColor = 'orange'
          this.snackbarMsg = err
        })
      // this.$http.post('/api/pqm/cscDataParsingMgmt/downloadResource/userHelp.ppt', {}, { responseType: 'blob' })
      //   .then(res => {
      //     this.processingView = false
      //     if (res.data.type === 'application/json') {
      //       return new Promise((resolve, reject) => {
      //         let reader = new FileReader()
      //         reader.onload = () => {
      //           res.data = JSON.parse(reader.result)
      //           alert(res.data.data)
      //           resolve(Promise.reject(res.data.data))
      //         }
      //         reader.onerror = () => {
      //           reject(res.data.data)
      //         }
      //         reader.readAsText(res.data)
      //       })
      //     } else if (res.data.status !== 'failure' && res.headers['content-disposition']) {
      //       let blob = new Blob([res.data], { type: 'application/octet-stream' })
      //       let url = window.URL.createObjectURL(blob)
      //       let fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]
      //       const downloadLink = document.createElement('a')
      //       downloadLink.href = url
      //       downloadLink.download = fileName

      //       document.body.appendChild(downloadLink)
      //       downloadLink.click()
      //       document.body.removeChild(downloadLink)
      //     } else {
      //       this.snackbar = true
      //       this.snackbarColor = 'red'
      //       this.snackbarMsg = '下載失敗，請通知系統管理員'
      //       console.log(res.data.error)
      //     }
      //   })
    },
    onResponsiveInverted () {
      if (window.innerWidth < 991) {
        this.responsive = true
        this.responsiveInput = false
      } else {
        this.responsive = false
        this.responsiveInput = true
      }
    },
    setDemo: function () {
      this.$store.state.app.demo = !this.$store.state.app.demo
    },
    setTheme: function () {
      // this.auth_theme(localStorage.getItem('theme') === 'dark' ? 'light' : 'dark')
      const style = this.$store.state.app.theme === 'dark' ? 'light' : 'dark'
      this.$store.state.app.theme = style
      localStorage.setItem('theme', style)
    },
    logout: function () {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/')
      })
    }
  }
}
</script>

<style>
  #core-toolbar a {
    text-decoration: none;
  }

  .toolbar_bg_dark {
    -webkit-background-image: repeating-linear-gradient(90deg,RGBA(80,0,0,0.75) 0%,  RGBA(0,0,0,0.75) 100%),linear-gradient(150deg, #000 80%,  #700 80%,  #000 90%);
    -moz-background-image: repeating-linear-gradient(90deg,RGBA(80,0,0,0.75) 0%,  RGBA(0,0,0,0.75) 100%),linear-gradient(150deg, #000 80%,  #700 80%,  #000 90%);
    -o-background-image: repeating-linear-gradient(90deg,RGBA(80,0,0,0.75) 0%,  RGBA(0,0,0,0.75) 100%),linear-gradient(150deg, #000 80%,  #700 80%,  #000 90%);
    -ms-background-image: repeating-linear-gradient(90deg,RGBA(80,0,0,0.75) 0%,  RGBA(0,0,0,0.75) 100%),linear-gradient(150deg, #000 80%,  #700 80%,  #000 90%);
    background-image: repeating-linear-gradient(90deg,RGBA(80,0,0,0.75) 0%,  RGBA(0,0,0,0.75) 100%),linear-gradient(150deg, #000 80%,  #700 80%,  #000 90%);
  }

  .toolbar_bg_light {
    -webkit-background-image: repeating-linear-gradient(90deg,RGBA(100,200,250,0.25) 0%,  RGBA(255,255,255,0.75) 100%),linear-gradient(150deg, #FFF 80%,  #BFF 80%,  #FFF 90%);
    -moz-background-image: repeating-linear-gradient(90deg,RGBA(100,200,250,0.25) 0%,  RGBA(255,255,255,0.75) 100%),linear-gradient(150deg, #FFF 80%,  #BFF 80%,  #FFF 90%);
    -o-background-image: repeating-linear-gradient(90deg,RGBA(100,200,250,0.25) 0%,  RGBA(255,255,255,0.75) 100%),linear-gradient(150deg, #FFF 80%,  #BFF 80%,  #FFF 90%);
    -ms-background-image: repeating-linear-gradient(90deg,RGBA(100,200,250,0.25) 0%,  RGBA(255,255,255,0.75) 100%),linear-gradient(150deg, #FFF 80%,  #BFF 80%,  #FFF 90%);
    background-image: repeating-linear-gradient(90deg,RGBA(100,200,250,0.25) 0%,  RGBA(255,255,255,0.75) 100%),linear-gradient(150deg, #FFF 80%,  #BFF 80%,  #FFF 90%);
  }

  .theme--dark .v-toolbar__title{
    color: #f44336 !important;
  }

  .theme--light .v-toolbar__title{
    color: #1e88e5 !important;
  }

  .toolbar-items {
    opacity: 0.5;
    transition: 0.25s;
  }

  .toolbar-items:hover {
    opacity: 1;
  }
</style>
