<template>
  <v-container
    lcd
    fluid
    grid-list-xl
    pt-0
  >
    <v-layout wrap>
      <!-- <v-flex
        xs12
        sm6
        lg3>
        <material-stats-card
          :value="summaryCaseName"
          style="margin-bottom:-20px !important;"
          class="v-offset-none"
          color="purple"
          icon="mdi-folder-open"
          title="案名"/>
      </v-flex>
      <v-flex
        xs12
        sm6
        lg3>
        <material-stats-card
          :value="summaryDate"
          style="margin-bottom:-20px !important;"
          color="indigo"
          icon="mdi-calendar"
          title="資料日期"/>
      </v-flex>
      <v-flex
        xs12
        sm6
        lg3>
        <material-stats-card
          :value="summaryEMS"
          style="margin-bottom:-20px !important;"
          color="light-blue"
          icon="mdi-factory"
          title="EMS"/>
      </v-flex>
      <v-flex
        xs12
        sm6
        lg3>
        <material-stats-card
          :value="(responseAreaData.failRate * 100).toFixed(1) + '%'"
          :class="responseAreaData.warningColor || 'green'"
          :color="responseAreaData.color"
          :icon="responseAreaData.icon || 'mdi-check'"
          class="title-color"
          style="margin-bottom:-20px !important;"
          title="Fail Rate"/>
      </v-flex> -->
      <v-flex
        class="v-flex__first"
        style="margin-top: 55px !important;"
        xs12>
        <material-card
          class="v-flex v-c3-chart"
        >
          <div
            class="chart-btn c3-chart c3-click"
            style="margin-top: -3.5rem!important;">
            <material-notification
              color="brown"
              class="mb-3 h4 font-weight-bold"
            >
              Luminance(Y)直條統計圖 [數值區間：{{ xRange }}]
              <p class="m-0">Factory：{{ propData.factoryName }} ｜ Project：{{ propData.projectName }} ｜ Time Interval：{{ propData.startTime }} ~ {{ propData.endTime }}</p>
              <!-- <h6 class="my-1 float-right">篩選條件最後更新時間：{{ lcdFilterUpdateTime }}</h6> -->
            </material-notification>
            <vue-c3 :handler="handler_bar"/>
          </div>
          <div>
            <v-flex
              xs12
              style="margin-left: 16px!important;padding: 0!important;">
              <v-autocomplete
                v-model="xRange"
                :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                :items="[
                  { text: 5, value: 5 },
                  { text: 10, value: 10 },
                  { text: 15, value: 15 },
                  { text: 20, value: 20 }
                ]"
                label="數值區間"
                class="v-text-field__slot v-text-field__details-none"
                persistent-hint
                outlined
              />
            </v-flex>
            <material-notification
              :class="{'text-white' : theme==='dark' , 'text-body' : theme==='light'}"
              :color="theme==='dark'?'black':'white'"
              class="mt-1 py-1 mb-1 h4 font-weight-bold"
            >
              <v-card-title
                primary-title
                class="mt-0 px-0 py-0 d-block text-center">
                <div class="headline m-auto">
                  <h6 class="my-0 text-left">校正前-Max</h6>
                  <h3 class="my-0 text-right">{{ Max.beforeCalibrationValue.toFixed(2) }}</h3>
                  <hr class="my-3 white">
                  <h6 class="my-0 text-left">校正前-Avg</h6>
                  <h3 class="my-0 text-right">{{ Avg.beforeCalibrationValue.toFixed(2) }}</h3>
                  <hr class="my-3 white">
                  <h6 class="my-0 text-left">校正前-Min</h6>
                  <h3 class="my-0 text-right">{{ Min.beforeCalibrationValue.toFixed(2) }}</h3>
                  <hr class="my-3 white">
                  <h6 class="my-0 text-left">校正後-Max</h6>
                  <h3 class="my-0 text-right">{{ Max.afterCalibrationValue.toFixed(2) }}</h3>
                  <hr class="my-3 white">
                  <h6 class="my-0 text-left">校正後-Avg</h6>
                  <h3 class="my-0 text-right">{{ Avg.afterCalibrationValue.toFixed(2) }}</h3>
                  <hr class="my-3 white">
                  <h6 class="my-0 text-left">校正後-Min</h6>
                  <h3 class="my-0 text-right">{{ Min.afterCalibrationValue.toFixed(2) }}</h3>
                </div>
              </v-card-title>
            </material-notification>
          </div>
        </material-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="loadingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :top="true"
      :color="snackbarColor">
      {{ snackbarMsg }}
      <v-btn
        dark
        flat
        @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import Vue from 'vue'
import VueC3 from 'vue-c3'
import 'c3/c3.min.css'
import { mapState } from 'vuex'
import Loading from '@/assets/img/loading.gif'

export default {
  name: 'ShowLcdCalibrationLuminanceYBarChart',
  components: {
    VueC3
  },
  data () {
    return {
      snackbar: false,
      snackbarColor: 'red',
      snackbarMsg: '',
      Loading,
      loadingView: false,
      propData: {},
      // lcdFilterUpdateTime: '',
      Min: {
        beforeCalibrationValue: 0,
        afterCalibrationValue: 0
      },
      Max: {
        beforeCalibrationValue: 0,
        afterCalibrationValue: 0
      },
      Avg: {
        beforeCalibrationValue: 0,
        afterCalibrationValue: 0
      },
      RangeMin: 0,
      RangeMax: 9999,
      RangeMinLimit: -99999,
      RangeMaxLimit: 99999,
      xRange: 10,
      LessRangeCount: {
        beforeCalibrationValue: 0,
        afterCalibrationValue: 0
      },
      OverRangeCount: {
        beforeCalibrationValue: 0,
        afterCalibrationValue: 0
      },
      // RangeCount: 1,
      responseAreaData: [],
      barXaxisItem: ['beforeCalibrationValue', 'afterCalibrationValue'],
      barXaxisItemName: ['校正前亮度', '校正後亮度'],
      barXaxisName: [],
      barXaxisValue: [],
      handler_bar: new Vue()
    }
  },
  computed: {
    ...mapState('app', ['theme', 'shrink']),
    options_bar () {
      return {
        data: {
          x: 'x',
          columns: [this.barXaxisName].concat(this.barXaxisValue),
          labels: true,
          // labels: {
          //   // format: function (v, id, i, j) { return v + '%' }
          // },
          // onclick: function (d) { alert(d.index) },
          // onclick: (d) => { this.summaryList(d.index, 'range') },
          type: 'bar'
          // types: {
          //   'NormalDistribution': 'spline'
          // },
          // axes: {
          //   'ModelName': 'y'
          // }
          // colors: {
          //   ModelName: '#999999'
          // }
          // onclick: (d, i) => { this.summaryList(d, i) } // {id: "data23", value: 120, ratio: 0.8, index: 1, name: "data23"}
          // color: function (color, d) {
          //   // d will be 'id' when called for legends
          //   return d.value >= 5.0 ? '#ff0000' : d.value >= 3.0 ? '#ff9800' : color
          // }
          // color: (color, d) => {
          //   // d will be 'id' when called for legends
          //   return (this.OverRangeCount > 0 && d.index === this.barXaxisName.length - 2) ? '#ffa500' : color
          // }
        },
        axis: {
          x: {
            type: 'category', // this needed to load string x value
            tick: {
              rotate: -45,
              multiline: false
            }
            // label: {
            //   text: '期',
            //   position: 'outer-center'
            // }
          },
          y: {
            // max: this.RangeCount,
            // min: 0,
            // tick: {
            //   // count: this.RangeCount + 1
            // },
            // padding: {
            //   top: 0,
            //   bottom: 0
            // }
            // tick: {
            //   format: function (value) { return value + '%' }
            // }
            // label: {
            //   text: '%',
            //   position: 'outer-middle'
            // }
          },
          y2: {
            // show: true
            // label: {
            //   text: '億',
            //   position: 'outer-middle'
            // }
          }
        },
        tooltip: {
          format: {
            title: (d) => {
              return `${this.barXaxisName[d + 1]}區間`
            },
            valuee: (value, ratio, id) => {
              return `${value}`
            }
          }
        },
        grid: {
          x: {
            show: true
          },
          y: {
            show: true
          }
        },
        legend: {
          position: 'bottom',
          padding: 16,
          show: true
        },
        size: {
          // width: 640,
          height: 600
        }
      }
    }
  },
  watch: {
    xRange (val) {
      this.doSummary()
    }
  },
  mounted () {
    this.$store.dispatch('getAuthorize', '/lcd/lcdCalibrationAnalyzer/showLcdCalibrationLuminanceYBarChartView')
      .then(response => {
        if (response.status === 'success' && response.data.accessible) {
          this.propData = JSON.parse(localStorage.getItem('lcdFilterInfo'))
          // this.lcdFilterUpdateTime = localStorage.getItem('lcdFilterUpdateTime')
          this.doSubmit()
        } else if (response.errCode === 'E10000002') {
          const routeData = this.$router.resolve({ path: '/noEnter/E10000002' })
          localStorage.setItem('errormsg', response.errMsg)
          window.location.assign(routeData.href)
        } else {
          const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
          window.location.assign(routeData.href)
        }
      })
      .catch(err => {
        console.log(err)
        const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
        window.location.assign(routeData.href)
      })
  },
  methods: {
    doSummary (type) {
      this.barXaxisName = ['x']
      this.barXaxisValue = []
      let AllArray = []
      const itemArray = {}
      const itemTotal = {}
      this.barXaxisItem.forEach((item, index) => {
        this.barXaxisValue.push([this.barXaxisItemName[index]])
        itemArray[item] = []
        itemTotal[item] = 0
        this.responseAreaData.forEach((r) => {
          if (r[item] >= 0) {
            itemArray[item].push(r[item])
            itemTotal[item] += r[item]
          }
        })
        this.Max[item] = Math.max(...itemArray[item])
        this.Min[item] = Math.min(...itemArray[item])
        this.Avg[item] = itemTotal[item] / itemArray[item].length
        AllArray = AllArray.concat(itemArray[item])
      })
      this.RangeMax = Math.ceil(Math.max(...AllArray) / this.xRange) * this.xRange
      this.RangeMin = Math.floor(Math.min(...AllArray) / this.xRange) * this.xRange
      if (this.RangeMin < this.RangeMinLimit) {
        this.RangeMin = Math.max(this.RangeMin, this.RangeMinLimit)
      }
      if (this.RangeMax > this.RangeMaxLimit) {
        this.RangeMax = Math.min(this.RangeMax, this.RangeMaxLimit)
      }
      const RangeArray = {}
      this.barXaxisItem.forEach((item, index) => {
        this.LessRangeCount[item] = 0
        this.OverRangeCount[item] = 0
        RangeArray[item] = []
        for (let i = this.RangeMin; i < this.RangeMax; i = i + this.xRange) {
          RangeArray[item][(i - this.RangeMin) / this.xRange] = 0
        }
        itemArray[item].forEach((r) => {
          if (r < this.RangeMinLimit) {
            this.LessRangeCount[item] += 1
          } else if (r > this.RangeMaxLimit) {
            this.OverRangeCount[item] += 1
          } else {
            if (RangeArray[item][Math.floor((r - this.RangeMin) / this.xRange)] > 0) {
              RangeArray[item][Math.floor((r - this.RangeMin) / this.xRange)] += 1
            } else {
              RangeArray[item][Math.floor((r - this.RangeMin) / this.xRange)] = 1
            }
          }
        })

        // this.RangeCount = Math.max(...RangeArray)
        for (let i = 0; i < RangeArray[item].length; i++) {
          this.barXaxisValue[index].push(RangeArray[item][i] || 0)
          switch (true) {
            case (this.xRange === 5 || this.xRange === 10 || this.xRange === 15 || this.xRange === 20):
              if (this.barXaxisName.indexOf((this.RangeMin + (i * this.xRange)).toFixed(0) + '-' + (this.RangeMin + ((i + 1) * this.xRange)).toFixed(0)) === -1) {
                this.barXaxisName.push((this.RangeMin + (i * this.xRange)).toFixed(0) + '-' + (this.RangeMin + ((i + 1) * this.xRange)).toFixed(0))
              }
              break
            // case (this.xRange === 2.5 || this.xRange === 7.5):
            //   if (this.barXaxisName.indexOf((this.RangeMin + (i * this.xRange)).toFixed(1) + '-' + (this.RangeMin + ((i + 1) * this.xRange)).toFixed(1)) === -1) {
            //    this.barXaxisName.push((this.RangeMin + (i * this.xRange)).toFixed(1) + '-' + (this.RangeMin + ((i + 1) * this.xRange)).toFixed(1))
            //   }
            //   break
          }
        }
        if (this.LessRangeCount[item] > 0) {
          this.barXaxisValue[index].splice(1, 0, this.LessRangeCount[item])
          if (this.barXaxisName.indexOf(('<' + this.RangeMinLimit)) === -1) {
            this.barXaxisName.splice(1, 0, ('<' + this.RangeMinLimit))
          }
        }
        if (this.OverRangeCount[item] > 0) {
          this.barXaxisValue[index].push(this.OverRangeCount[item])
          if (this.barXaxisName.indexOf(('>' + this.RangeMaxLimit)) === -1) {
            this.barXaxisName.push('>' + this.RangeMaxLimit)
          }
        }
      })
      if (type === 'init') {
        this.handler_bar.$emit('init', this.options_bar)
      } else {
        this.handler_bar.$emit('dispatch', chart => {
          const options = {
            columns: [this.barXaxisName].concat(this.barXaxisValue)
          }
          chart.load(options)
        })
      }
    },
    doSubmit () {
      this.loadingView = true
      this.$http.post('/api/lcd/analyzeMgmt/luminanceYDataList', this.propData).then(res => res.data)
        .then(res => {
          this.loadingView = false
          if (res.status === 'success') {
            this.responseAreaData = res.data.calibrationValueList
            this.doSummary('init')
          }
        })
    }
  }
}
</script>
<style>
/* .c3-axis-x .tick tspan:hover {
  cursor: pointer;
} */
</style>
