<template>
  <v-container
    fill-height
    fluid>
    <v-layout justify-center>
      <v-flex
        xs12>
        <h2 class="text-center">{{ errormsg }}<span style="margin-left: 10px; font-size: 24px; color:#ff6f00;">({{ errorcode }})</span></h2>
        <h1 class="text-center"><v-icon style="font-size: 450px;opacity: 0.25;color:#f00;">mdi-close-octagon</v-icon></h1>
        <!-- <pre style="word-wrap: break-word; white-space: pre-wrap;">{{ msg }}</pre> -->
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'NoEnter',
  data: function () {
    return {
      errormsg: '',
      errorcode: ''
    }
  },
  // Sends action to Vuex that will log you in and redirect to the dash otherwise, error
  mounted () {
    if (this.$route.params.msg || localStorage.getItem('errormsg')) {
      this.errormsg = localStorage.getItem('errormsg')
      this.errorcode = this.$route.params.msg
    }
  },
  methods: {
  }
}
</script>
