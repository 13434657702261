<template>
  <div class="tree-menu">
    <div
      v-if="name"
      :style="'transform:translate('+((depth + (firstIndent ? 0 : -1)) * 28 + 5 + (children ? 0 : 28))+'px)'"
      class="tree-menu-option">
      <v-icon
        v-if="children&&isShow"
        title="點擊收合項目"
        class="tree-menu-option-icon"
        @click="toggleExpand">mdi-chevron-down-circle</v-icon>
      <v-icon
        v-if="children&&!isShow"
        title="點擊展開項目"
        class="tree-menu-option-icon"
        @click="toggleExpand">mdi-chevron-up-circle</v-icon>
      <span
        v-if="name !== 'N/A'"
        :style="'width:calc(100% - '+((depth + (firstIndent ? 0 : -1)) * 28 + 10)+'px - '+(children ? 28 : 28)+'px);color:'+depthColor[depth]+';'+(isSelected?('background-image: linear-gradient(to right, transparent 25%, '+depthColor[depth]+'aa 100%);'):'')"
        class="tree-menu-option-name"
        @click="returnOptionValue(!isSelected,name,attribute,depthColor[depth],depth,type,sequence);optionSelect(!isSelected)">{{ name }}</span>
      <span
        v-if="name === 'N/A'"
        :style="'width:calc(100% - '+((depth + (firstIndent ? 0 : -1)) * 28 + 10)+'px - '+(children ? 28 : 28)+'px);color: lightgray;cursor: default!important;border-color: transparent!important;'"
        class="tree-menu-option-name">{{ name }}</span>
    </div>
    <tree-menu-selector
      v-for="(child,c) in children"
      v-show="isShow"
      :key="(depth+1)+child+c"
      :type="child.type"
      :name="child.name"
      :children="child.subTrees"
      :attribute="Object.assign({...attribute},{[child.type]:child.name})"
      :depth="depth+1"
      :sequence="sequence*100+c"
      :parameter="parameter"
      :deselect="deselect"
      :first-indent="firstIndent"
      @return-option-value="returnOptionValue"
      @return-deselect-state="returnDeselectState"
    />
  </div>
</template>
<script>
export default {
  name: 'TreeMenuSelector',
  props: {
    type: {
      type: String,
      default: ''
    },
    name: {
      type: [Number, String, Boolean],
      default: false
    },
    children: {
      type: Array,
      default: function () {
        return []
      }
    },
    attribute: {
      type: Object,
      default: function () {
        return {}
      }
    },
    depth: {
      type: Number,
      default: 0
    },
    sequence: {
      type: Number,
      default: 1
    },
    parameter: {
      type: Array,
      default: function () {
        return []
      }
    },
    deselect: {
      type: Number,
      default: null
    },
    firstIndent: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      depthColor: [
        '#f44336', // red
        '#ffff00', // yellow
        '#c4bbff', // purple
        '#4db0ff', // blue
        '#7edd81', // green
        '#ff99ff' // pink
      ],
      isSelected: false,
      isShow: !this.name
    }
  },
  computed: {
    // indent () {
    //   return {
    //     transform: `translate(${(this.depth + (this.firstIndent ? 0 : -1)) * 28}px)`
    //   }
    // }
  },
  watch: {
    parameter (val) {
      if (Object.values(this.attribute).join('-') === val.join('-')) {
        this.returnOptionValue(!this.isSelected, this.name, this.attribute, this.depthColor[this.depth], this.depth, this.type, this.sequence)
        this.optionSelect(!this.isSelected)
        this.returnDeselectState(null)
      }
    },
    deselect (val) {
      if (this.sequence === val) {
        this.returnOptionValue(!this.isSelected, this.name, this.attribute, this.depthColor[this.depth], this.depth, this.type, this.sequence)
        this.optionSelect(!this.isSelected)
        this.returnDeselectState(null)
      }
    }
  },
  methods: {
    toggleExpand () {
      this.isShow = !this.isShow
    },
    optionSelect (state) {
      this.isSelected = state
    },
    returnOptionValue (selected, name, attribute, color, depth, category, sequence) {
      this.$emit('return-option-value', selected, name, attribute, color, depth, category, sequence)
    },
    returnDeselectState (state) {
      this.$emit('return-deselect-state', state)
    }
  }
}
</script>
<style>
.tree-menu{
  overflow: hidden;
  background-color: #0000002c;
}
.tree-menu-option{
  margin: 0;
}
.tree-menu-option-icon{
  color: lightgray !important;
  margin-right: 4px;
}
.tree-menu-option-name{
  word-break: break-word;
  display: inline-block;
  border-radius: 5px;
  padding: 1px 5px;
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
  text-shadow: 2px 1px black;
  font-weight: 900 !important;
}
.tree-menu-option-name:hover{
  cursor: pointer;
  border-color:  #ffffff99;
}
</style>
