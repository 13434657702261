<template>
  <v-container
    csc
    fluid
    grid-list-xl
    pt-0
  >
    <v-layout wrap>
      <v-flex
        xs12
        lg6
      >
        <material-card
          title="維修項目歸類規則搜尋"
          class="card-padding v-card__first"
          color="general"
        >
          <v-form>
            <v-container
              py-0
              class="mw-100">
              <v-layout wrap>
                <v-flex
                  flex-small
                  xs12
                  sm6
                  lg4>
                  <v-text-field
                    v-model="reasonCode"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    label="Reason Code"/>
                </v-flex>
                <v-flex
                  flex-small
                  xs12
                  sm6
                  lg4>
                  <v-autocomplete
                    v-model="level"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="[{ text: 'All', value: 'All' }].concat(levelMenu)"
                    label="LEVEL"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex
                  xs12
                  lg4
                  text-xs-right
                >
                  <v-btn
                    class="mx-0 font-weight-light"
                    color="general"
                    @click="doSubmitBefore()"
                  >
                    <v-icon class="mr-2">mdi-backup-restore</v-icon>查詢
                  </v-btn>
                  <v-btn
                    class="mr-0 ml-3 font-weight-light"
                    color="pink"
                    @click="doAddWindows()"
                  >
                    <v-icon class="mr-2">mdi-plus</v-icon>新增
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
      </v-flex>
      <v-flex
        xs12
        lg6
      />
      <v-flex
        flex_table_width_auto
        xs12
        lg6>
        <material-card
          id="checkTable"
          :style="'max-width: calc(100vw - '+(Number(shrink)+32)+'px);'"
          title="查詢結果列表"
          color="deep-orange"
          class="card-mix d-table-t0 no-data-left"
          style="margin-top: 30px!important;">
          <v-select
            v-model="pagination.current.rowsPerPage"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="rowsAmount"
            class="mix-list v-text-field__slot d-inline-block float-right ml-5"
            style="width: 90px;"
            persistent-hint
            outlined
          />
          <v-data-table
            :headers="responseHeaders"
            :items="responseData"
            :pagination.sync="pagination.current"
            :rows-per-page-items ="rowsAmount"
            class="height-row-3 table-align-left"
            multi-sort
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="subheading font-weight-light text-general text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <tr>
                <td>{{ item.reasonCode }}</td>
                <td>{{ item.level }}</td>
                <td>
                  <div>
                    <v-btn
                      class="ml-0 mr-2 font-weight-bold small"
                      color="deep-purple"
                      @click="doEditWindows(item.pk, item.reasonCode, item.level)">
                      <v-icon class="mr-2">mdi-pencil</v-icon>修改
                    </v-btn>
                    <v-btn
                      class="mx-0 font-weight-bold small"
                      color="red"
                      @click="doDelete(item.pk)">
                      <v-icon class="mr-2">mdi-close</v-icon>刪除
                    </v-btn>
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
          <div
            v-if="pagination.current.rowsPerPage != -1"
            class="text-xs-center pt-2 d-inline-block"
            style="margin-left: 0px;width: calc(100% - 140px);">
            <v-pagination
              v-model="pagination.page"
              :length="pages"/>
          </div>
          <v-autocomplete
            v-if="pagination.current.rowsPerPage != -1"
            v-model="pagination.page"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="pageslist"
            class="v-text-field__slot d-inline-block float-right mr-0"
            style="width: 90px;"
            persistent-hint
            outlined
          />
        </material-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="windowsForAddOrEdit"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <v-card
        max-width="25%"
        style="margin:0 auto;">
        <v-card-text>
          <v-alert
            v-model="windowsForAddOrEdit"
            class="mt-3 mb-3 border-0"
            color="deep-orange">
            <strong class="h4">{{ (action==='add')?'新增':'修改' }}對照條件</strong>
          </v-alert>
          <v-flex
            flex-small
            d-flex
            align-items-center
            xs12
            lg12>
            <p class="m-0">Reason Code</p>
            <v-text-field
              v-model="windows.reasonCode"
              :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
              label=""/>
          </v-flex>
          <v-flex
            flex-small
            d-flex
            align-items-center
            xs12
            lg12>
            <p class="m-0">LEVEL</p>
            <v-autocomplete
              v-model="windows.level"
              :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
              :items="levelMenu"
              label=""
              class="v-text-field__slot"
              persistent-hint
              outlined
            />
          </v-flex>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="red"
            small
            @click="windowsForAddOrEdit=false">
            <v-icon class="mr-2">mdi-close</v-icon>取消
          </v-btn>
          <v-btn
            v-if="action==='add'"
            color="blue"
            small
            @click="doAdd()">
            <v-icon class="mr-2">mdi-plus</v-icon>新增
          </v-btn>
          <v-btn
            v-if="action==='edit'"
            color="blue"
            small
            @click="doEdit()">
            <v-icon class="mr-2">mdi-content-save</v-icon>儲存
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="processingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        PROCESSING 處理中
      </p>
    </v-dialog>
    <v-dialog
      v-model="loadingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :top="true"
      :color="snackbarColor">
      {{ snackbarMsg }}
      <v-btn
        dark
        flat
        @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import Loading from '@/assets/img/loading.gif'

export default {
  name: 'ReasonCodeMappingLevelMaintain',
  data () {
    return {
      action: 'add',
      windowsForAddOrEdit: false,
      snackbar: false,
      snackbarColor: 'red',
      snackbarMsg: '',
      Loading,
      loadingView: false,
      processingView: false,
      reasonCode: '',
      reasonCode_check: '',
      level: '',
      level_check: '',
      levelMenu: [],
      windows: {
        pk: 0,
        reasonCode: '',
        level: ''
      },
      submit: false,
      IsSubmitPage: false,
      responseData: [],
      responseHeaders: [
        {
          align: '',
          class: '',
          sortable: true,
          text: 'Reason Code',
          value: 'reasonCode'
        }, {
          align: '',
          class: '',
          sortable: true,
          text: 'LEVEL',
          value: 'level'
        }, {
          align: '',
          class: '',
          sortable: false,
          text: '操作',
          value: 'operation'
        }
      ],
      // rowsAmount: [25, 50, 100, { text: 'All', value: -1 }],
      rowsAmount: [10, 25, 50, 100],
      pagination: {
        page: 1,
        current: {
          descending: null,
          page: 1,
          rowsPerPage: 25,
          sortBy: null,
          totalItems: 0
        }
      },
      pages: 1,
      totalRows: 0
    }
  },
  computed: {
    ...mapState('app', ['theme', 'shrink']),
    // pages () {
    //   if(this.responseData.length > 0) {
    //     return this.pagination.rowsPerPage ? Math.ceil(this.responseData.length / this.pagination.rowsPerPage) : 10
    //   }
    // },
    pageslist () {
      let i = 0
      const list = []
      if (this.pagination.current.rowsPerPage) {
        for (i = 0; i < Math.ceil(this.pagination.current.totalItems / this.pagination.current.rowsPerPage); i++) {
          list.push(i + 1)
        }
      }
      return list
    }
  },
  watch: {
    // perPageRows (val) {
    //   if (val === -1) {
    //     if (confirm('完整顯示將可能導致效能降低，是否仍完整顯示?')) {
    //       this.pagination.current.rowsPerPage = val
    //       this.pages = Math.ceil(this.pagination.current.totalItems / this.pagination.current.rowsPerPage)
    //       this.pagination.page = 1
    //     } else {
    //       setTimeout(() => {
    //         this.perPageRows = this.pagination.current.rowsPerPage
    //       }, 100)
    //     }
    //   } else {
    //     this.pagination.current.rowsPerPage = val
    //     this.pages = Math.ceil(this.pagination.current.totalItems / this.pagination.current.rowsPerPage)
    //     this.pagination.page = 1
    //   }
    // },
    pagination: {
      handler (newName, oldName) {
        if (this.submit && !this.IsSubmitPage) {
          this.doSubmit()
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted () {
    this.$store.dispatch('getAuthorize', '/pqm/cscDataParsingMgmt/reasonCodeMappingLevelMaintainView')
      .then(response => {
        if (response.status === 'success' && response.data.accessible) {
          this.$http.get('/api/pqm/getSelectOptionData/mappingLevel').then(res => res.data)
            .then(res => {
              if (res.status === 'success') {
                this.levelMenu = res.data
              }
            })
          this.doSubmit()
        } else if (response.errCode === 'E10000002') {
          const routeData = this.$router.resolve({ path: '/noEnter/E10000002' })
          localStorage.setItem('errormsg', response.errMsg)
          window.location.assign(routeData.href)
        } else {
          const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
          window.location.assign(routeData.href)
        }
      })
      .catch(err => {
        console.log(err)
        const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
        window.location.assign(routeData.href)
      })
  },
  methods: {
    paginate () {
      alert('x')
    },
    doAddWindows () {
      this.action = 'add'
      this.windows = {
        pk: 0,
        reasonCode: '',
        level: ''
      }
      this.windowsForAddOrEdit = true
    },
    doEditWindows (pk, reasonCode, level) {
      this.action = 'edit'
      this.windows = {
        pk: pk,
        reasonCode: reasonCode,
        level: level
      }
      this.windowsForAddOrEdit = true
    },
    doSubmitBefore () {
      this.reasonCode_check = this.reasonCode
      this.level_check = this.level
      this.pagination.page = 1
      this.doSubmit()
    },
    doSubmit () {
      this.submit = true
      this.loadingView = true
      const body = {
        pageNum: this.pagination.page,
        pageRows: this.pagination.current.rowsPerPage,
        sortColumn: (this.pagination.current.descending !== null) ? this.pagination.current.sortBy : '',
        sortType: this.pagination.current.descending ? 'DESC' : ((this.pagination.current.descending !== null) ? 'ASC' : ''),
        reasonCode: this.reasonCode_check,
        level: this.level_check
      }
      this.$http.post('/api/pqm/cscDataParsingMgmt/reasonCodeMappingLevelMaintainList', body).then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.responseData = res.data.data
            this.IsSubmitPage = true
            this.pagination.current.totalItems = res.data.totalRows
          }
          return res
        })
        .then(res => {
          if (res.status === 'success') {
            this.loadingView = false
            this.pages = Math.ceil(res.data.totalRows / this.pagination.current.rowsPerPage)
            this.IsSubmitPage = false
          }
        })
    },
    doAdd () {
      this.submit = true
      this.windowsForAddOrEdit = false
      this.processingView = true
      const body = {
        reasonCode: this.windows.reasonCode,
        level: this.windows.level
      }
      this.$http.post('/api/pqm/cscDataParsingMgmt/reasonCodeMappingLevel', body).then(res => res.data)
        .then(res => {
          this.processingView = false
          if (res.status === 'success') {
            this.snackbar = true
            this.snackbarColor = 'green'
            this.snackbarMsg = '新增成功'
            this.doSubmit()
          } else { // failure
            this.windowsForAddOrEdit = true
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
          }
        })
    },
    doEdit () {
      this.submit = true
      this.windowsForAddOrEdit = false
      this.processingView = true
      const body = {
        reasonCode: this.windows.reasonCode,
        level: this.windows.level
      }
      this.$http.put(`/api/pqm/cscDataParsingMgmt/reasonCodeMappingLevel/${this.windows.pk}`, body).then(res => res.data)
        .then(res => {
          this.processingView = false
          if (res.status === 'success') {
            this.snackbar = true
            this.snackbarColor = 'green'
            this.snackbarMsg = '修改成功'
            this.doSubmit()
          } else { // failure
            this.windowsForAddOrEdit = true
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
          }
        })
    },
    doDelete (pk) {
      if (confirm('是否確認刪除?')) {
        this.processingView = true
        this.$http.delete(`/api/pqm/cscDataParsingMgmt/reasonCodeMappingLevel/${pk}`).then(res => res.data)
          .then(res => {
            this.processingView = false
            if (res.status === 'success') {
              this.snackbar = true
              this.snackbarColor = 'green'
              this.snackbarMsg = '刪除成功'
              this.doSubmit()
            } else { // failure
              this.snackbar = true
              this.snackbarColor = 'red'
              this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
            }
          })
      }
    }
  }
}
</script>
<style>
</style>
