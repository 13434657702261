<template>
  <v-container
    csc
    fluid
    grid-list-xl
    pt-0
  >
    <v-layout wrap>
      <v-flex
        xs12
        lg9
      >
        <material-card
          title="上傳記錄搜尋"
          class="card-padding v-card__first"
          color="general"
        >
          <v-form>
            <v-container
              py-0
              class="mw-100">
              <v-layout wrap>
                <v-flex
                  flex-small
                  d-flex
                  align-items-center
                  xs12
                  lg5>
                  <p class="m-0">上傳時間</p>
                  <v-menu
                    v-model="uploadedStartDateMenu"
                    :close-on-content-click="false"
                    :nudge-left="40"
                    lazy
                    transition="scale-transition"
                    style="max-width: 170px;margin-left: 10px;"
                    max-width="290px"
                    offset-y
                    full-width
                  >
                    <v-text-field
                      slot="activator"
                      :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                      v-model="uploadedStartDate"
                      label=""
                      prepend-icon="event"
                    />
                    <v-date-picker
                      v-model="uploadedStartDate"
                      :min="startdate"
                      :max="new Date() | date-format"
                      :color="theme==='dark'?'danger':'general'"
                      type="date"
                      no-title
                      scrollable
                      actions/>
                  </v-menu>
                  <p class="m-0 text-center px-3">到</p>
                  <v-menu
                    v-model="uploadedEndDateMenu"
                    :close-on-content-click="false"
                    :nudge-left="40"
                    lazy
                    transition="scale-transition"
                    style="max-width: 170px;margin-left: 10px;"
                    max-width="290px"
                    offset-y
                    full-width
                  >
                    <v-text-field
                      slot="activator"
                      :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                      v-model="uploadedEndDate"
                      label=""
                      prepend-icon="event"
                    />
                    <v-date-picker
                      v-model="uploadedEndDate"
                      :min="uploadedStartDate"
                      :max="new Date() | date-format"
                      :color="theme==='dark'?'danger':'general'"
                      type="date"
                      no-title
                      scrollable
                      actions/>
                  </v-menu>
                </v-flex>
                <v-flex
                  flex-small
                  xs12
                  sm6
                  lg2>
                  <v-text-field
                    v-model="fileName"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    label="檔名"/>
                </v-flex>
                <v-flex
                  flex-small
                  xs12
                  sm6
                  lg2>
                  <v-autocomplete
                    v-model="type"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="typeMenu"
                    label="類別"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex
                  flex-small
                  xs12
                  sm6
                  lg2>
                  <v-autocomplete
                    v-model="status"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="statusMenu"
                    label="狀態"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex
                  flex-small
                  xs12
                  lg1
                  text-xs-right
                  d-flex
                  align-items-center
                >
                  <v-btn
                    class="mx-0 font-weight-light"
                    color="general"
                    @click="doSubmitBefore()"
                  >
                    <v-icon class="mr-2">mdi-backup-restore</v-icon>查詢
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
      </v-flex>
      <v-flex
        xs12
        lg3
      />
      <v-flex
        flex_table_width_auto
        xs12
        lg9>
        <material-card
          id="checkTable"
          :style="'max-width: calc(100vw - '+(Number(shrink)+32)+'px);'"
          title="查詢結果列表"
          color="deep-orange"
          class="card-mix d-table-t0 no-data-left"
          style="margin-top: 30px!important;">
          <v-select
            v-model="pagination.current.rowsPerPage"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="rowsAmount"
            class="mix-list v-text-field__slot d-inline-block float-right ml-5"
            style="width: 90px;"
            persistent-hint
            outlined
          />
          <input
            v-show="false"
            id="upload-file"
            type="file"
            data-target="file-uploader"
            persistent-hint
            @change="setFile($event)">
          <v-btn
            class="mix-btn black text-light"
            style="right: 8rem;"
            @click="uploadFile(0)">
            <v-icon class="float-left">mdi-upload</v-icon>CSC
          </v-btn>
          <v-btn
            class="mix-btn black text-light"
            style="right: 12.5rem;"
            @click="uploadFile(1)">
            <v-icon class="float-left">mdi-upload</v-icon>Active
          </v-btn>
          <v-btn
            class="mix-btn black text-light"
            style="right: 18.25rem;"
            @click="uploadFile(2)">
            <v-icon class="float-left">mdi-upload</v-icon>Sales
          </v-btn>
          <v-btn
            class="mix-btn black text-light"
            style="right: 23.7rem;"
            @click="uploadFile(3)">
            <v-icon class="float-left">mdi-upload</v-icon>EMS
          </v-btn>
          <v-btn
            class="mix-btn black text-light"
            style="right: 28.3rem;"
            @click="uploadFile(5)">
            <v-icon class="float-left">mdi-upload</v-icon>SFIS
          </v-btn>
          <v-btn
            class="mix-btn black text-light"
            style="right: 32.8rem;"
            @click="uploadFile(7)">
            <v-icon class="float-left">mdi-upload</v-icon>BBY
          </v-btn>
          <v-data-table
            :headers="responseHeaders"
            :items="responseData"
            :pagination.sync="pagination.current"
            :rows-per-page-items ="rowsAmount"
            class="height-row-3 table-align-left"
            multi-sort
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="subheading font-weight-light text-general text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <tr>
                <td>{{ item.fileName }}</td>
                <td>{{ item.uploadedDate }}</td>
                <td>{{ item.finishedDate }}</td>
                <td>{{ item.type }}</td>
                <td>{{ item.status }}</td>
                <td>
                  <div>
                    <v-btn
                      class="mx-0 font-weight-bold small"
                      color="orange"
                      @click="doDownload(item.pk)">
                      <v-icon class="mr-2">mdi-download</v-icon>下載
                    </v-btn>
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
          <div
            v-if="pagination.current.rowsPerPage != -1"
            class="text-xs-center pt-2 d-inline-block"
            style="margin-left: 0px;width: calc(100% - 140px);">
            <v-pagination
              v-model="pagination.page"
              :length="pages"/>
          </div>
          <v-autocomplete
            v-if="pagination.current.rowsPerPage != -1"
            v-model="pagination.page"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="pageslist"
            class="v-text-field__slot d-inline-block float-right mr-0"
            style="width: 90px;"
            persistent-hint
            outlined
          />
        </material-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="processingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        PROCESSING 處理中
      </p>
    </v-dialog>
    <v-dialog
      v-model="loadingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :top="true"
      :color="snackbarColor">
      {{ snackbarMsg }}
      <v-btn
        dark
        flat
        @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import $ from 'jquery'
import { mapState } from 'vuex'
import Loading from '@/assets/img/loading.gif'

export default {
  name: 'CscUploadParsingDataMgmt',
  data () {
    return {
      startdate: '2018-04-01',
      snackbar: false,
      snackbarColor: 'red',
      snackbarMsg: '',
      Loading,
      loadingView: false,
      processingView: false,
      uploadedStartDateMenu: false,
      uploadedStartDate: '',
      uploadedStartDate_check: '',
      uploadedEndDateMenu: false,
      uploadedEndDate: '',
      uploadedEndDate_check: '',
      fileName: '',
      fileName_check: '',
      type: '',
      type_check: '',
      typeMenu: [],
      status: '',
      status_check: '',
      statusMenu: [],
      file: [
        {
          file: '',
          name: '',
          url: ''
        }
      ],
      fileType: -1,
      submit: false,
      IsSubmitPage: false,
      responseData: [],
      responseHeaders: [
        {
          align: '',
          class: '',
          sortable: true,
          text: '原始檔名',
          value: 'fileName'
        }, {
          align: '',
          class: '',
          sortable: true,
          text: '上傳時間',
          value: 'uploadedDate'
        }, {
          align: '',
          class: '',
          sortable: true,
          text: '處理完成時間',
          value: 'finishedDate'
        }, {
          align: '',
          class: '',
          sortable: true,
          text: '類別',
          value: 'type'
        }, {
          align: '',
          class: '',
          sortable: true,
          text: '狀態',
          value: 'status'
        }, {
          align: '',
          class: '',
          sortable: false,
          text: '操作',
          value: 'operation'
        }
      ],
      // rowsAmount: [25, 50, 100, { text: 'All', value: -1 }],
      rowsAmount: [10, 25, 50, 100],
      pagination: {
        page: 1,
        current: {
          descending: true,
          page: 1,
          rowsPerPage: 25,
          sortBy: 'uploadedDate',
          totalItems: 0
        }
      },
      pages: 1
    }
  },
  computed: {
    ...mapState('app', ['theme', 'shrink']),
    // pages () {
    //   if(this.responseData.length > 0) {
    //     return this.pagination.rowsPerPage ? Math.ceil(this.responseData.length / this.pagination.rowsPerPage) : 10
    //   }
    // },
    pageslist () {
      let i = 0
      const list = []
      if (this.pagination.current.rowsPerPage) {
        for (i = 0; i < Math.ceil(this.pagination.current.totalItems / this.pagination.current.rowsPerPage); i++) {
          list.push(i + 1)
        }
      }
      return list
    }
  },
  watch: {
    startDate (val) {
      this.startDateMenu = false
    },
    endDate (val) {
      this.endDateMenu = false
    },
    // perPageRows (val) {
    //   if (val === -1) {
    //     if (confirm('完整顯示將可能導致效能降低，是否仍完整顯示?')) {
    //       this.pagination.current.rowsPerPage = val
    //       this.pages = Math.ceil(this.pagination.current.totalItems / this.pagination.current.rowsPerPage)
    //       this.pagination.page = 1
    //     } else {
    //       setTimeout(() => {
    //         this.perPageRows = this.pagination.current.rowsPerPage
    //       }, 100)
    //     }
    //   } else {
    //     this.pagination.current.rowsPerPage = val
    //     this.pages = Math.ceil(this.pagination.current.totalItems / this.pagination.current.rowsPerPage)
    //     this.pagination.page = 1
    //   }
    // },
    pagination: {
      handler (newName, oldName) {
        if (this.submit && !this.IsSubmitPage) {
          this.doSubmit()
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted () {
    this.$store.dispatch('getAuthorize', '/pqm/cscDataParsingMgmt/uploadParsingDataListView')
      .then(response => {
        if (response.status === 'success' && response.data.accessible) {
          JSON.parse(localStorage.getItem('moduleDataInitDate')).forEach((n) => {
            if (n.name === 'csc') {
              this.startdate = n.value
            }
          })
          // this.$http.get('/api/pqm/getSelectOptionData/uploadParsingFileType').then(res => res.data)
          //   .then(res => {
          //     if (res.status === 'success') {
          //       this.typeMenu = [{ text: 'All', value: 'All' }].concat(res.data)
          //     }
          //   })
          this.typeMenu = [
            { text: 'All', value: 'All' },
            { text: 'CSC', value: 'CSC' },
            { text: 'Active', value: 'Active' },
            { text: 'Sales', value: 'Sales' },
            { text: 'EMS', value: 'EMS' },
            { text: 'SFIS', value: 'SFIS' },
            { text: 'BBY', value: 'BBY' }
          ]
          this.$http.get('/api/pqm/getSelectOptionData/uploadParsingFileStatus').then(res => res.data)
            .then(res => {
              if (res.status === 'success') {
                this.statusMenu = [{ text: 'All', value: 'All' }].concat(res.data)
              }
            })
          this.doSubmit()
        } else if (response.errCode === 'E10000002') {
          const routeData = this.$router.resolve({ path: '/noEnter/E10000002' })
          localStorage.setItem('errormsg', response.errMsg)
          window.location.assign(routeData.href)
        } else {
          const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
          window.location.assign(routeData.href)
        }
      })
      .catch(err => {
        console.log(err)
        const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
        window.location.assign(routeData.href)
      })
  },
  methods: {
    doSubmitBefore () {
      this.uploadedStartDate_check = this.uploadedStartDate
      this.uploadedEndDate_check = this.uploadedEndDate
      this.fileName_check = this.fileName
      this.type_check = (this.type === 'All' || this.type === '') ? ['CSC', 'Active', 'Sales', 'EMS', 'SFIS', 'BBY'] : this.type
      this.status_check = this.status
      this.pagination.page = 1
      this.doSubmit()
    },
    doSubmit () {
      this.loadingView = true
      this.submit = true
      const body = {
        pageNum: this.pagination.page,
        pageRows: this.pagination.current.rowsPerPage,
        sortColumn: (this.pagination.current.descending !== null) ? this.pagination.current.sortBy : '',
        sortType: this.pagination.current.descending ? 'DESC' : ((this.pagination.current.descending !== null) ? 'ASC' : ''),
        uploadedStartDate: this.uploadedStartDate_check,
        uploadedEndDate: this.uploadedEndDate_check,
        fileName: this.fileName_check,
        type: (this.type_check === 'All' || this.type_check === '') ? ['CSC', 'Active', 'Sales', 'EMS', 'SFIS', 'BBY'] : [this.type_check],
        status: this.status_check
      }
      this.$http.post('/api/pqm/cscDataParsingMgmt/uploadParsingDataList', body).then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.responseData = res.data.data
            this.IsSubmitPage = true
            this.pagination.current.totalItems = res.data.totalRows
          }
          return res
        })
        .then(res => {
          if (res.status === 'success') {
            this.pages = Math.ceil(res.data.totalRows / this.pagination.current.rowsPerPage)
            this.IsSubmitPage = false
          }
          this.loadingView = false
        })
    },
    uploadFile (type) {
      if (!this.file[0].url) {
        this.fileType = type
        $('#upload-file').click()
      }
    },
    cancelFile () {
      this.file[0].file = this.file[0].name = this.file[0].url = ''
      $('#upload-file').val('')
    },
    setFile (e) {
      const files = e.target.files || e.dataTransfer.files
      this.file[0].url = window.URL.createObjectURL(e.srcElement.files.item(0))
      this.file[0].file = files[0]
      this.file[0].name = e.srcElement.files.item(0).name
      this.doUpload()
    },
    doUpload () {
      this.processingView = true
      const formData = new FormData()
      formData.append('file', this.file[0].file)
      this.$http.post(`/api/pqm/cscDataParsingMgmt/uploadParsingFile/${this.fileType}`, formData).then(res => res.data)
        .then(res => {
          this.processingView = false
          if (res.status === 'success') {
            this.snackbar = true
            this.snackbarColor = 'green'
            this.snackbarMsg = '檔案上傳成功'
            this.cancelFile()
            this.doSubmit()
          } else { // failure
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
            this.cancelFile()
          }
        })
      this.fileType = -1
    },
    doDownload (key) {
      this.processingView = true
      this.$http.post(`/api/pqm/cscDataParsingMgmt/downloadParsingFile/${key}`, null, { responseType: 'blob' })
        .then(res => {
          this.processingView = false
          if (res.data.type === 'application/json') {
            return new Promise((resolve, reject) => {
              const reader = new FileReader()
              reader.onload = () => {
                res.data = JSON.parse(reader.result)
                if (res.data.status === 'success') {
                  alert(res.data.data)
                } else {
                  this.snackbar = true
                  this.snackbarColor = 'red'
                  this.snackbarMsg = res.data.errMsg + ' (' + res.data.errCode + ')'
                }
              }
              reader.onerror = () => {
                reject(res.data.errMsg || res.data.data)
              }
              reader.readAsText(res.data)
            })
          } else if (res.data.status !== 'failure' && res.headers['content-disposition']) {
            const blob = new Blob([res.data], { type: 'application/octet-stream' })
            const url = window.URL.createObjectURL(blob)
            const fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]
            const downloadLink = document.createElement('a')
            downloadLink.href = url
            downloadLink.download = fileName

            document.body.appendChild(downloadLink)
            downloadLink.click()
            document.body.removeChild(downloadLink)
          } else {
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = '下載失敗，請通知系統管理員'
          }
        })
        .catch(err => {
          this.processingView = false
          this.snackbar = true
          this.snackbarColor = 'orange'
          this.snackbarMsg = err
        })
    }
  }
}
</script>
<style>
</style>
