<template>
  <v-container
    fluid
    grid-list-xl
    pt-0
  >
    <v-layout wrap>
      <v-flex
        xs12
        sm6
        lg3>
        <material-stats-card
          :value="summaryCaseName"
          style="margin-bottom:-20px !important;"
          class="v-offset-none"
          color="purple"
          icon="mdi-folder-open"
          title="案名"/>
      </v-flex>
      <v-flex
        xs12
        sm6
        lg3>
        <material-stats-card
          :value="summaryDate"
          style="margin-bottom:-20px !important;"
          color="indigo"
          icon="mdi-calendar"
          title="資料日期"/>
      </v-flex>
      <v-flex
        xs12
        sm6
        lg3>
        <material-stats-card
          :value="summaryEMS"
          style="margin-bottom:-20px !important;"
          color="light-blue"
          icon="mdi-factory"
          title="EMS"/>
      </v-flex>
      <v-flex
        xs12
        sm6
        lg3>
        <material-stats-card
          :value="(responseAreaData.failRate * 100).toFixed(1) + '%'"
          :class="responseAreaData.warningColor || 'green'"
          :color="responseAreaData.color"
          :icon="responseAreaData.icon || 'mdi-check'"
          class="title-color"
          style="margin-bottom:-20px !important;"
          title="Fail Rate"/>
      </v-flex>
      <v-flex
        class="v-flex__first"
        style="margin-top: 55px !important;"
        xs12>
        <material-card
          class="v-flex v-c3-chart"
        >
          <div
            class="chart-btn c3-chart c3-click"
            style="margin-top: -3.5rem!important;">
            <material-notification
              color="brown"
              class="mb-3 h4 font-weight-bold"
            >
              燒機數據分佈圖[{{ summaryItem }} @ {{ summaryRange }} {{ summaryItem.indexOf('TEMP')>-1?'°C':'W' }}區間]
            </material-notification>
            <vue-c3 :handler="handler_bar"/>
          </div>
          <div>
            <v-flex
              xs12
              style="margin-left: 16px!important;padding: 0!important;">
              <v-autocomplete
                v-model="summaryItem"
                :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                :items="[
                  { text: 'CPU TEMP', value: 'CPU_TEMP' },
                  { text: 'GPU TEMP', value: 'GPU_TEMP' },
                  { text: 'CPU POWER', value: 'CPU_POWER' },
                  { text: 'GPU POWER', value: 'GPU_POWER' }
                ]"
                label="資料來源"
                class="v-text-field__slot v-text-field__details-none"
                persistent-hint
                outlined
              />
            </v-flex>
            <v-flex
              xs12
              style="margin-left: 16px!important;padding: 0!important;">
              <v-autocomplete
                v-model="summaryRange"
                :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                :items="[
                  { text: summaryItem.indexOf('TEMP')>-1?'2.5 °C':'2.5 W', value: '2.5' },
                  { text: summaryItem.indexOf('TEMP')>-1?'5 °C':'5 W', value: '5' },
                  { text: summaryItem.indexOf('TEMP')>-1?'7.5 °C':'7.5 W', value: '7.5' },
                  { text: summaryItem.indexOf('TEMP')>-1?'10 °C':'10 W', value: '10' }
                ]"
                :label="summaryItem.indexOf('TEMP')>-1?'溫度區間':'瓦數區間'"
                class="v-text-field__slot v-text-field__details-none"
                persistent-hint
                outlined
              />
            </v-flex>
            <material-notification
              :color="responseAreaData.warningColor || 'green'"
              class="mt-1 py-1 mb-0 h4 font-weight-bold"
            >
              <v-card-title
                primary-title
                class="mt-0 px-0 py-0 d-block text-center">
                <div class="headline m-auto">
                  <h6 class="my-0 text-left">生產數量</h6>
                  <h4 class="my-0 text-right">{{ responseAreaData.productionQty }} pcs</h4>
                  <hr class="my-0 white">
                  <h6 class="my-0 text-left">pass數量</h6>
                  <h4 class="my-0 text-right">{{ responseAreaData.passQty }} pcs ({{ (responseAreaData.passRate * 100).toFixed(1) }}%)</h4>
                  <hr class="my-0 white">
                  <h6 class="my-0 text-left">fail數量</h6>
                  <h4 class="my-0 text-right">{{ responseAreaData.failQty }} pcs ({{ (responseAreaData.failRate * 100).toFixed(1) }}%)</h4>
                  <hr class="my-0 white">
                  <h6 class="my-0 text-left">thermal fail</h6>
                  <h4 class="my-0 text-right">{{ responseAreaData.thermalFailQty }} pcs ({{ (responseAreaData.thermalFailRate * 100).toFixed(1) }}%)</h4>
                  <hr class="my-0 white">
                  <h6 class="my-0 text-left">風扇異常</h6>
                  <h4 class="my-0 text-right">{{ responseAreaData.fanFailQty }} pcs ({{ (responseAreaData.fanFailRate * 100).toFixed(1) }}%)</h4>
                  <hr class="my-0 white">
                  <h6 class="my-0 text-left">非Thermal Issue</h6>
                  <h4 class="my-0 text-right">{{ responseAreaData.commonFailQty }}<span v-if="responseAreaData.noDefineFailQty>0"> ({{ responseAreaData.noDefineFailQty }})</span> pcs ({{ (responseAreaData.commonFailRate * 100).toFixed(1) }}%)</h4>
                </div>
              </v-card-title>
              <v-card-actions class="d-block text-center">
                <v-btn
                  v-if="RangeAll"
                  class="black text-light"
                  @click="summaryList (999, 'fan');RangeAll=false">
                  列出風扇異常
                </v-btn>
                <v-btn
                  v-if="!RangeAll"
                  class="black text-light"
                  @click="summaryList (999, 'all');RangeAll=true">
                  取消異常篩選
                </v-btn>
              </v-card-actions>
            </material-notification>
          </div>
        </material-card>
      </v-flex>
      <v-flex
        flex_width_auto
        xs12>
        <material-card
          id="checkTable"
          :style="'max-width: calc(100vw - '+(Number(shrink)+32)+'px);'"
          :title="(RangeIndex===999 ? (RangeAll ? '全部':'風扇異常') : (summaryItem + '區間' + (RangeMin+(RangeIndex*summaryRange)) + '-' + (RangeMin+Number(summaryRange)+(RangeIndex*summaryRange)) ) ) + '機台清單'"
          color="deep-orange"
          class="card-mix card-padding pt-0">
          <v-select
            v-model="perPageRows"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="rowsAmount"
            class="mix-list v-text-field__slot d-inline-block float-right ml-5"
            style="width: 90px;"
            persistent-hint
            outlined
          />
          <v-btn
            class="mix-btn black text-light"
            style="right: 8rem;"
            @click="summaryList (999, 'all');RangeAll=true">
            列出全部資料
          </v-btn>
          <v-btn
            class="mix-btn black text-light"
            style="right: 15rem;"
            @click="doGroupDownload()">
            <v-icon class="floata-left">mdi-download</v-icon>打包下載
          </v-btn>
          <v-autocomplete
            id="checkTableInput"
            v-model="checkTableShowList"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="summaryTableShowList"
            label="選擇欄位"
            class="v-text-field__slot row-btn-175 pt-0"
            color="white"
            multitude
            chips
            persistent-hint
            multiple
            outlined
          />
          <v-btn
            class="red text-light float-right mr-0"
            @click="checkTableShowListClear()"
          >
            清除
          </v-btn>
          <v-btn
            class="blue text-light float-right"
            @click="checkTableShowListAll()"
          >
            全選
          </v-btn>
        </material-card>
        <material-card
          :style="'max-width: calc(100vw - '+(Number(shrink)+32)+'px);'"
          class="d-table-t0 no-data-left">
          <!-- <v-select
            v-model="pagination.rowsPerPage"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="rowsAmount"
            class="v-text-field__slot d-inline-block float-right ml-5"
            style="width: 90px;"
            persistent-hint
            outlined
          /> -->
          <v-data-table
            :headers="responseHeaders"
            :items="responseData"
            :pagination.sync="pagination"
            :rows-per-page-items ="rowsAmount"
            :class="{ 'showList': checkTableShowList.length>0}"
            class="freeze-title"
            multi-sort
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="subheading font-weight-light text-general text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <tr
                :class="{'check-row': checkList.findIndex(function checkUnfold (pk) { return pk === item.ttrsPkList }) > -1}"
                :title="item.deviceIsn">
                <td
                  class="td-check table-width-55"
                  style="position: relative;overflow: hidden;">
                  <v-checkbox
                    v-model="checkList"
                    :value="item.ttrsPkList"
                    class="mr-2"
                    style="display: table-caption;max-height: 20px;margin-left: -3px;"
                    color="white"
                    label=""/>
                </td>
                <td
                  class="table-width-175 cursor"
                  @click="rowCheckList(item.ttrsPkList)">{{ item.deviceIsn }}</td>
                <td
                  class="table-width-135 cursor"
                  @click="rowCheckList(item.ttrsPkList)">{{ item.CPU_TEMP }}</td>
                <td
                  class="table-width-135 cursor"
                  @click="rowCheckList(item.ttrsPkList)">{{ item.CPU_FREQ }}</td>
                <td
                  class="table-width-135 cursor"
                  @click="rowCheckList(item.ttrsPkList)">{{ item.CPU_POWER }}</td>
                <td
                  class="table-width-135 cursor"
                  @click="rowCheckList(item.ttrsPkList)">{{ item.GPU_TEMP }}</td>
                <td
                  class="table-width-135 cursor"
                  @click="rowCheckList(item.ttrsPkList)">{{ item.GPU_FREQ }}</td>
                <td
                  class="table-width-135 cursor"
                  @click="rowCheckList(item.ttrsPkList)">{{ item.GPU_POWER }}</td>
                <td class="table-width-500">
                  <div
                    v-for="(ttrsPk, t) in item.ttrsPkList"
                    :key="'ttrsPkBtn' + t">
                    <span
                      class="d-inline-block mr-3"
                      style="width:2rem;">
                      {{ item.operation.replace('@', t+1) }}
                    </span>
                    <v-btn
                      class="mx-0 mr-3 font-weight-light small"
                      color="primary"
                      @click="doBeforeLink(ttrsPk,item.deviceIsn)">
                      <v-icon class="mr-2">mdi-chart-bubble</v-icon>線上分析
                    </v-btn>
                    <router-link
                      :to="'/viewThermalLog/' + ttrsPk"
                      target="_blank">
                      <v-btn
                        class="mx-0 mr-3 font-weight-light small"
                        color="green">
                        <v-icon class="mr-2">mdi-file-find</v-icon>線上檢視
                      </v-btn>
                    </router-link>
                    <v-btn
                      class="mx-0 font-weight-light small"
                      color="orange"
                      @click="doDownload(ttrsPk)">
                      <v-icon class="mr-2">mdi-download</v-icon>下載LOG
                    </v-btn>
                  </div>
                </td>
                <td
                  v-for="(col, index) in responseTableShowList"
                  v-show="responseTableShowList[index]"
                  :class="responseColumnClassList[index]+' text-xs-'+responseAlignList[index]"
                  :key="'col'+index"
                  class="cursor"
                  @click="rowCheckList(item.ttrsPkList)"
                >{{ item[index] }}</td>
                <!-- {{ isNaN(item[index]) ? item[index] : Number(item[index]).toFixed(1) }} -->
              </tr>
            </template>
          </v-data-table>
          <div
            v-if="pagination.rowsPerPage != -1"
            class="text-xs-center pt-2 d-inline-block"
            style="margin-left: 0px;width: calc(100% - 140px);">
            <v-pagination
              v-model="pagination.page"
              :length="pages"/>
          </div>
          <v-autocomplete
            v-if="pagination.rowsPerPage != -1"
            v-model="pagination.page"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="pageslist"
            class="v-text-field__slot d-inline-block float-right mr-0"
            style="width: 90px;"
            persistent-hint
            outlined
          />
          <!-- <v-btn
            class="mx-0 font-weight-light small"
            @click="doGroupDownload()">
            <v-icon class="floata-left">mdi-download</v-icon>打包下載
          </v-btn> -->
        </material-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="processingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        PROCESSING 處理中
      </p>
    </v-dialog>
    <v-dialog
      v-model="loadingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :top="true"
      :color="snackbarColor">
      {{ snackbarMsg }}
      <v-btn
        dark
        flat
        @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import Vue from 'vue'
import VueC3 from 'vue-c3'
import 'c3/c3.min.css'
import { mapState } from 'vuex'
import Loading from '@/assets/img/loading.gif'

export default {
  name: 'DailyBurnInDataStatistic',
  components: {
    VueC3
  },
  data () {
    return {
      startdate: '2018-04-01',
      snackbar: false,
      snackbarColor: 'red',
      snackbarMsg: '',
      Loading,
      loadingView: false,
      processingView: false,
      summaryCaseName: '',
      summaryDate: '',
      summaryEMS: '',
      summaryEMSpk: 0,
      summaryRange: '5',
      summaryItem: 'CPU_TEMP',
      summaryTableShowList: [],
      RangeAll: true,
      RangeIndex: 999,
      RangeMin: 0,
      RangeMax: 9999,
      RangeMaxList: {
        CPU_TEMP: 200,
        GPU_TEMP: 200,
        CPU_POWER: 200,
        GPU_POWER: 200
      },
      OverRangeCount: 0,
      RangeCount: 1,
      responseData: [],
      responseDataOnly: [],
      responseHeaders: [],
      responseHeadersOnly: [],
      responseHeadersList: [],
      responseColumnClassList: {},
      responseAlignList: {},
      responseTableShowList: {},
      responseTableShowListOnly: {},
      responseAreaData: {
        commonFailQty: 0,
        commonFailRate: 0,
        failQty: 0,
        failRate: 0,
        fanErrorCodeList: [],
        fanFailQty: 0,
        fanFailRate: 0,
        noDefineFailQty: 0,
        passQty: 0,
        passRate: 0,
        productionQty: 0,
        thermalFailQty: 0,
        thermalFailRate: 0,
        warningColor: 'green'
      },
      checkList: [],
      checkTableShowList: [],
      lineXaxisName: [],
      lineXaxisValue: [],
      rowsAmount: [25, 50, 100, { text: 'All', value: -1 }],
      perPageRows: 25,
      pagination: {
        page: 1,
        rowsPerPage: 25,
        sortBy: '',
        descending: false
      },
      pages: 0,
      handler_bar: new Vue()
    }
  },
  computed: {
    ...mapState('app', ['theme', 'shrink']),
    // pages () {
    //   if(this.responseData.length > 0) {
    //     return this.pagination.rowsPerPage ? Math.ceil(this.responseData.length / this.pagination.rowsPerPage) : 10
    //   }
    // },
    pageslist () {
      let i = 0
      const list = []
      if (this.pagination.rowsPerPage) {
        for (i = 0; i < Math.ceil(this.responseData.length / this.pagination.rowsPerPage); i++) {
          list.push(i + 1)
        }
      }
      return list
    },
    options_bar () {
      return {
        data: {
          x: 'x',
          columns: [
            this.barXaxisName,
            this.barXaxisValue
          ],
          labels: true,
          // labels: {
          //   // format: function (v, id, i, j) { return v + '%' }
          // },
          // onclick: function (d) { alert(d.index) },
          onclick: (d) => { this.summaryList(d.index, 'range') },
          type: 'bar',
          types: {
            NormalDistribution: 'spline'
          },
          axes: {
            ModelName: 'y'
          }
          // colors: {
          //   ModelName: '#999999'
          // }
          // onclick: (d, i) => { this.summaryList(d, i) } // {id: "data23", value: 120, ratio: 0.8, index: 1, name: "data23"}
          // color: function (color, d) {
          //   // d will be 'id' when called for legends
          //   return d.value >= 5.0 ? '#ff0000' : d.value >= 3.0 ? '#ff9800' : color
          // }
          // color: (color, d) => {
          //   // d will be 'id' when called for legends
          //   return (this.OverRangeCount > 0 && d.index === this.barXaxisName.length - 2) ? '#ffa500' : color
          // }
        },
        axis: {
          x: {
            type: 'category', // this needed to load string x value
            tick: {
              rotate: -45,
              multiline: false
            }
            // label: {
            //   text: '期',
            //   position: 'outer-center'
            // }
          },
          y: {
            // max: this.RangeCount,
            // min: 0,
            // tick: {
            //   // count: this.RangeCount + 1
            // },
            // padding: {
            //   top: 0,
            //   bottom: 0
            // }
            // tick: {
            //   format: function (value) { return value + '%' }
            // }
            // label: {
            //   text: '%',
            //   position: 'outer-middle'
            // }
          },
          y2: {
            // show: true
            // label: {
            //   text: '億',
            //   position: 'outer-middle'
            // }
          }
        },
        tooltip: {
          format: {
            title (d) {
              // return `第 ${d} 期`
            },
            value (value, ratio, id) {
              // return `${value} 億`
            }
          }
        },
        grid: {
          x: {
            show: true
          },
          y: {
            show: true
          }
        },
        legend: {
          position: 'bottom',
          padding: 16,
          show: false
        },
        size: {
          // width: 640,
          height: 500
        }
      }
    }
  },
  watch: {
    checkTableShowList (val) {
      this.responseHeaders = JSON.parse(JSON.stringify(this.responseHeadersOnly))
      this.responseTableShowList = JSON.parse(JSON.stringify(this.responseTableShowListOnly))
      this.summaryTableShowList.forEach((s, index) => {
        this.checkTableShowList.forEach((c) => {
          this.responseTableShowList[c] = true
          if (s.value === c) {
            this.responseHeaders.push(this.responseHeadersList[index])
          }
        })
      })
    },
    responseData (val) {
      this.pages = Math.ceil(this.responseData.length / this.pagination.rowsPerPage)
      if (this.pages > 1) {
        setTimeout(() => {
          this.pagination.page = this.pages
        }, 0)
        setTimeout(() => {
          this.pagination.page = 1
        }, 100)
      } else {
        setTimeout(() => {
          this.pagination.rowsPerPage = 1
        }, 0)
        setTimeout(() => {
          this.pagination.rowsPerPage = this.perPageRows
        }, 100)
      }
    },
    perPageRows (val) {
      if (val === -1) {
        if (confirm('完整顯示將可能導致效能降低，是否仍完整顯示?')) {
          this.pagination.rowsPerPage = val
          this.pages = Math.ceil(this.responseData.length / this.pagination.rowsPerPage)
          this.pagination.page = 1
        } else {
          setTimeout(() => {
            this.perPageRows = this.pagination.rowsPerPage
          }, 100)
        }
      } else {
        this.pagination.rowsPerPage = val
        this.pages = Math.ceil(this.responseData.length / this.pagination.rowsPerPage)
        this.pagination.page = 1
      }
    },
    'pagination.page' (val) {
      this.checkList = []
    },
    'pagination.rowsPerPage' (val) {
      this.checkList = []
    },
    'pagination.sortBy' (val) {
      this.checkList = []
    },
    'pagination.descending' (val) {
      this.checkList = []
    },
    summaryDate (val) {
      this.summaryDateMenu = false
    },
    summaryItem (val) {
      this.doSummary()
    },
    summaryRange (val) {
      this.doSummary()
    }
  },
  mounted () {
    this.$store.dispatch('getAuthorize', '/thermal/dailyBurnInDataStatistic')
      .then(response => {
        if (response.status === 'success' && response.data.accessible) {
          JSON.parse(localStorage.getItem('moduleDataInitDate')).forEach((n) => {
            if (n.name === 'thermal') {
              this.startdate = n.value
            }
          })
          const { modelname, date, emspk, ems } = this.$route.query
          this.summaryCaseName = modelname || this.summaryCaseName
          this.summaryDate = date || this.summaryDate
          this.summaryEMSpk = emspk || this.summaryEMSpk
          this.summaryEMS = ems || this.summaryEMS
          this.doSubmit()
          // d3.selectAll('.c3-axis-x .tick tspan').on('click', function(d,i) {
          //   this.summaryList(d.index, 'range')
          // })
        } else if (response.errCode === 'E10000002') {
          const routeData = this.$router.resolve({ path: '/noEnter/E10000002' })
          localStorage.setItem('errormsg', response.errMsg)
          window.location.assign(routeData.href)
        } else {
          const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
          window.location.assign(routeData.href)
        }
      })
      .catch(err => {
        console.log(err)
        const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
        window.location.assign(routeData.href)
      })
  },
  methods: {
    doBeforeLink (TtrsPk, deviceSn) {
      this.$http.post('/api/thermal/singleDeviceBurnInDataStatistic?ttrsPk=' + TtrsPk).then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            const Groups = res.data.fanTestData.length
            const routeData = this.$router.resolve({ path: '/thermal/singleDeviceBurnInDataStatistic/' + TtrsPk + '/' + Groups, query: { sn: deviceSn, modelname: this.summaryCaseName, date: this.summaryDate, ems: this.summaryEMS } })
            window.open(routeData.href, '_blank')
          }
        })
    },
    doSummary (type) {
      this.barXaxisName = ['x']
      this.barXaxisValue = ['ModelName']
      const AllArray = []
      this.responseDataOnly.forEach((r) => {
        if (r[this.summaryItem] >= 0) {
          AllArray.push(r[this.summaryItem])
        }
      })
      this.RangeMax = Math.ceil(Math.max(...AllArray) / this.summaryRange) * this.summaryRange
      this.RangeMin = Math.floor(Math.min(...AllArray) / this.summaryRange) * this.summaryRange
      if (this.RangeMax > this.RangeMaxList[this.summaryItem]) {
        this.RangeMax = Math.min(this.RangeMax, this.RangeMaxList[this.summaryItem])
      }
      this.OverRangeCount = 0
      const RangeArray = []
      for (let i = this.RangeMin; i < this.RangeMax; i = i + this.summaryRange) {
        RangeArray[(i - this.RangeMin) / this.summaryRange] = 0
      }
      AllArray.forEach((r) => {
        if (r > this.RangeMaxList[this.summaryItem]) {
          this.OverRangeCount = this.OverRangeCount + 1
        } else {
          if (RangeArray[Math.floor((r - this.RangeMin) / this.summaryRange)] > 0) {
            RangeArray[Math.floor((r - this.RangeMin) / this.summaryRange)] = RangeArray[Math.floor((r - this.RangeMin) / this.summaryRange)] + 1
          } else {
            RangeArray[Math.floor((r - this.RangeMin) / this.summaryRange)] = 1
          }
        }
      })

      this.RangeCount = Math.max(...RangeArray)
      for (let i = 0; i < RangeArray.length; i++) {
        this.barXaxisValue.push(RangeArray[i] || 0)
        switch (true) {
          case (this.summaryRange === '5' || this.summaryRange === '10'):
            this.barXaxisName.push((this.RangeMin + (i * this.summaryRange)).toFixed(0) + '-' + (this.RangeMin + ((i + 1) * this.summaryRange)).toFixed(0))
            break
          case (this.summaryRange === '2.5' || this.summaryRange === '7.5'):
            this.barXaxisName.push((this.RangeMin + (i * this.summaryRange)).toFixed(1) + '-' + (this.RangeMin + ((i + 1) * this.summaryRange)).toFixed(1))
            break
          // default:
          // return
        }
      }
      if (this.OverRangeCount > 0) {
        this.barXaxisValue.push(this.OverRangeCount)
        this.barXaxisName.push('>' + this.RangeMaxList[this.summaryItem])
      }
      // RangeArray.forEach((r, index) => {
      //   console.log(r)
      //   console.log(index)
      //   this.barXaxisValue.push(r)
      //   this.barXaxisName.push((this.RangeMin + (index * this.summaryRange)) + '-' + (this.RangeMin + ((index + 1) * this.summaryRange)))
      // })
      if (type === 'init') {
        this.handler_bar.$emit('init', this.options_bar)
      } else {
        this.handler_bar.$emit('dispatch', chart => {
          const options = {
            columns: [
              this.barXaxisName,
              this.barXaxisValue
            ]
          }
          chart.load(options)
        })
      }
    },
    checkTableShowListAll () {
      this.checkTableShowList = []
      this.summaryTableShowList.forEach((s) => {
        this.checkTableShowList.push(s.value)
      })
    },
    checkTableShowListClear () {
      this.checkTableShowList = []
    },
    doSubmit () {
      this.loadingView = true
      const body = {
        date: this.summaryDate,
        modelName: this.summaryCaseName,
        ems: this.summaryEMSpk
      }
      this.$http.post('/api/thermal/singleModuleDailyBurnInDataStatisticColumnDefineList', body).then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.responseHeaders.push({
              align: '',
              class: 'table-width-55',
              fixed: true,
              sortable: false,
              text: '標記',
              value: ''
            })
            this.responseHeadersOnly.push({
              align: '',
              class: 'table-width-55',
              fixed: true,
              sortable: false,
              text: '標記',
              value: ''
            })
            this.responseHeadersList = []
            res.data.columnDefine.forEach((r) => {
              if (r.fixed) {
                this.responseHeaders.push(r)
                this.responseHeadersOnly.push(r)
              } else {
                this.responseHeadersList.push(r)
                this.responseTableShowList[r.value] = r.fixed
                this.responseTableShowListOnly[r.value] = r.fixed
                this.responseColumnClassList[r.value] = r.class
                this.responseAlignList[r.value] = r.align
                this.summaryTableShowList.push({ text: r.text, value: r.value })
              }
            })
            this.pagination.sortBy = res.data.sortBy
            this.pagination.descending = res.data.descending
          }
        })
      this.$http.post('/api/thermal/singleModuleDailyBurnInDataStatistic', body).then(res => res.data)
        .then(res => {
          this.loadingView = false
          if (res.status === 'success') {
            // this.pagination.rowsPerPage = res.data.length
            res.data.deviceList.forEach((r, index) => {
              this.responseData.push({})
              r.forEach((rr) => {
                this.responseData[index][rr.name] = rr.value
              })
              this.responseDataOnly.push({})
              r.forEach((rr) => {
                this.responseDataOnly[index][rr.name] = rr.value
              })
            })
            this.pagination.rowsPerPage = 25
            // this.pages = Math.ceil(this.responseData.length / this.pagination.rowsPerPage)
            this.doSummary('init')
            this.responseAreaData = res.data.summaryInfo
            this.responseAreaData.color = this.responseAreaData.warningColor === '' ? 'light-green' : this.responseAreaData.warningColor === 'orange' ? 'deep-orange' : 'pink'
            this.responseAreaData.icon = this.responseAreaData.warningColor === '' ? 'mdi-check' : this.responseAreaData.warningColor === 'orange' ? 'mdi-alarm-light' : 'mdi-alert'
          }
        })
      // this.$http.post('/api/thermal/getDailyBurnInDataSummary', body).then(res => res.data)
      //   .then(res => {
      //     if (res.status === 'success') {
      //       this.responseAreaData = res.data[0]
      //       this.responseAreaData.color = this.responseAreaData.warningColor === '' ? 'light-green' : this.responseAreaData.warningColor === 'orange' ? 'deep-orange' : 'pink'
      //       this.responseAreaData.icon = this.responseAreaData.warningColor === '' ? 'mdi-check' : this.responseAreaData.warningColor === 'orange' ? 'mdi-alarm-light' : 'mdi-alert'
      //     }
      //   })
    },
    doDownload (key) {
      this.processingView = true
      const body = {
        type: 0,
        ttrsPkList: [key]
      }
      this.$http.post('/api/thermal/downloadDailyBurnInOriginalData', body)
        .then(res => {
          this.processingView = false
          if (res.data.type === 'application/json') {
            return new Promise((resolve, reject) => {
              const reader = new FileReader()
              reader.onload = () => {
                res.data = JSON.parse(reader.result)
                if (res.data.status === 'success') {
                  alert(res.data.data)
                } else {
                  this.snackbar = true
                  this.snackbarColor = 'red'
                  this.snackbarMsg = res.data.errMsg + ' (' + res.data.errCode + ')'
                }
              }
              reader.onerror = () => {
                reject(res.data.errMsg || res.data.data)
              }
              reader.readAsText(res.data)
            })
          } else if (res.data.status !== 'failure' && res.headers['content-disposition']) {
            const blob = new Blob([res.data], { type: 'application/octet-stream' })
            const url = window.URL.createObjectURL(blob)
            const fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]
            const downloadLink = document.createElement('a')
            downloadLink.href = url
            downloadLink.download = fileName

            document.body.appendChild(downloadLink)
            downloadLink.click()
            document.body.removeChild(downloadLink)
          } else {
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = '下載失敗，請通知系統管理員'
          }
        })
        .catch(err => {
          this.processingView = false
          this.snackbar = true
          this.snackbarColor = 'orange'
          this.snackbarMsg = err
        })
    },
    doGroupDownload () {
      if (this.checkList.length > 0) {
        this.processingView = true
        const body = {
          type: 1,
          ttrsPkList: this.checkList.join().split(',')
        }
        body.ttrsPkList.forEach((tt, index) => {
          body.ttrsPkList[index] = Number(tt)
        })
        this.$http.post('/api/thermal/downloadDailyBurnInOriginalData', body, { observe: 'response', responseType: 'blob' })
          .then(res => {
            this.processingView = false
            if (res.data.type === 'application/json') {
              return new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.onload = () => {
                  res.data = JSON.parse(reader.result)
                  if (res.data.status === 'success') {
                    alert(res.data.data)
                    // resolve(Promise.reject(res.data.data))
                  } else {
                    this.snackbar = true
                    this.snackbarColor = 'red'
                    this.snackbarMsg = res.data.errMsg + ' (' + res.data.errCode + ')'
                  }
                }
                reader.onerror = () => {
                  reject(res.data.data)
                }
                reader.readAsText(res.data)
              })
            } else if (res.data.status !== 'failure' && res.headers['content-disposition']) {
              // let blob = new Blob([res.data])
              const url = window.URL.createObjectURL(res.data)
              const fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]
              const downloadLink = document.createElement('a')

              // downloadLink.setAttribute('download', fileName)
              downloadLink.href = url
              downloadLink.download = fileName

              document.body.appendChild(downloadLink)
              downloadLink.click()
              document.body.removeChild(downloadLink)
            } else {
              this.snackbar = true
              this.snackbarColor = 'red'
              this.snackbarMsg = '下載失敗，請通知系統管理員'
            }
          })
          .catch(err => {
            this.processingView = false
            this.snackbar = true
            this.snackbarColor = 'orange'
            this.snackbarMsg = err
          })
      } else {
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '未勾選Device ISN，請勾選後，再重新下載'
      }
    },
    rowCheckList (val) {
      if (this.checkList.findIndex(function checkUnfold (pk) { return pk === val }) === -1) {
        this.checkList.push(val)
      } else {
        this.checkList.splice(this.checkList.findIndex(function checkUnfold (pk) { return pk === val }), 1)
      }
    },
    summaryList (Index, type) {
      this.checkList = []
      this.RangeIndex = Index
      this.responseData = []
      // this.responseData.push({})
      if (type === 'all') {
        // let n = 0
        this.responseDataOnly.forEach((r) => {
          this.responseData.push(r)
          // r.forEach((rr) => {
          //   this.responseData[n][rr.name] = rr.value
          // })
          // n++
        })
      } else if (type === 'fan') {
        // let n = 0
        this.responseDataOnly.forEach((r) => {
          if (this.responseAreaData.fanErrorCodeList.findIndex(function checkUnfold (c) { return c === r.errorCode }) > -1) {
            this.responseData.push(r)
            // r.forEach((rr) => {
            //   this.responseData[n][rr.name] = rr.value
            // })
            // n++
          }
        })
      } else if (type === 'range') {
        // let n = 0
        this.responseDataOnly.forEach((r) => {
          if (r[this.summaryItem] >= (this.RangeMin + (this.RangeIndex * this.summaryRange)) && r[this.summaryItem] < (this.RangeMin + Number(this.summaryRange) + (this.RangeIndex * this.summaryRange))) {
            this.responseData.push(r)
            // r.forEach((rr) => {
            //   this.responseData[n][rr.name] = rr.value
            // })
            // n++
          }
        })
      }
      // console.log(this.responseDataOnly)
      // console.log(this.responseData)
    }
  }
}
</script>
<style>
/* .c3-axis-x .tick tspan:hover {
  cursor: pointer;
} */
</style>
