<template>
  <v-container
    cfr
    fluid
    grid-list-xl
    pt-0
  >
    <v-layout wrap>
      <v-flex
        mt-3
        xs12
      >
        <material-card
          title="SFIS原始維修資料下載"
          color="general"
          class="card-mix card-padding pt-0 pb-3">
          <v-btn
            class="mix-btn mx-0 font-weight-light small"
            @click="modelYear=[];modelGroup=[];model=[];mucModule=[];excludeBestBuy=false;"
          >清除重置</v-btn>
          <v-form>
            <v-container
              py-0
              class="mw-100">
              <v-layout wrap>
                <v-flex
                  flex-small
                  xs12
                  lg1>
                  <v-autocomplete
                    v-model="modelYear"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="modelYearMenu"
                    label="開賣年度"
                    class="v-text-field__slot list-select-field"
                    persistent-hint
                    outlined
                    multiple
                    multitude
                  />
                </v-flex>
                <v-flex
                  flex-small
                  xs12
                  lg2>
                  <v-autocomplete
                    v-model="modelGroup"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="modelGroupMenu"
                    label="modelGroup"
                    class="v-text-field__slot list-select-field"
                    persistent-hint
                    outlined
                    multiple
                    multitude
                  />
                </v-flex>
                <v-flex
                  flex-small
                  xs12
                  lg2>
                  <v-autocomplete
                    v-model="model"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="modelMenu"
                    label="Model"
                    class="v-text-field__slot list-select-field"
                    persistent-hint
                    outlined
                    multiple
                    multitude
                  />
                </v-flex>
                <v-flex
                  flex-small
                  xs12
                  lg3>
                  <v-autocomplete
                    v-model="mucModule"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="mucModuleMenu"
                    label="MucModule"
                    class="v-text-field__slot list-select-field"
                    persistent-hint
                    outlined
                    multiple
                    multitude
                  />
                </v-flex>
                <v-flex
                  d-flex
                  align-items-center
                  xs12
                  lg2>
                  <p style="max-width: 150px; margin: 0px !important;">選擇資料項目</p>
                  <v-checkbox
                    v-model="excludeBestBuy"
                    :value="true"
                    class="m-0 py-0"
                    label="ExcludeBestBuy"/>
                </v-flex>
                <v-flex
                  flex-small
                  xs12
                  lg2
                  text-xs-right
                  d-flex
                  align-items-center>
                  <v-btn
                    :disabled="!(modelGroup.length>0 || model.length>0) || mucModule.length===0"
                    color="indigo"
                    class="ml-3"
                    small
                    @click="doDownload()">
                    <v-icon class="mr-2">mdi-export</v-icon>匯出統計資料
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="processingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        PROCESSING 處理中
      </p>
    </v-dialog>
    <v-dialog
      v-model="loadingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :color="snackbarColor"
      :top="true">
      {{ snackbarMsg }}
      <v-btn
        flat
        @click="snackbar = false">
        關閉
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
// import qs from 'qs'
// import $ from 'jquery'
import { mapState } from 'vuex'
import Loading from '@/assets/img/loading.gif'

export default {
  name: 'ModuleStatisticDataExport',
  components: {
  },
  data () {
    return {
      snackbar: false,
      snackbarColor: 'green',
      snackbarMsg: '',
      Loading,
      loadingView: false,
      processingView: false,
      loadingOfModelGroup: false,
      loadingOfModel: false,
      modelYear: [],
      modelYearMenu: [],
      modelMenu: [],
      model: [],
      modelGroup: [],
      modelGroupMenu: [],
      mucModule: [],
      mucModuleMenu: [],
      excludeBestBuy: false
    }
  },
  computed: {
    ...mapState('app', ['theme', 'shrink'])
  },
  watch: {
    modelYear (val) {
      this.getModelGroup(val)
      this.getModel(val, this.modelGroup)
    },
    modelGroup (val) {
      this.getModel(this.modelYear, val)
    }
  },
  mounted () {
    this.$store.dispatch('getAuthorize', '/pqm/cscDataParsingMgmt/moduleStatisticDataExportView')
      .then(response => {
        if (response.status === 'success' && response.data.accessible) {
          this.$http.get('/api/pqm/cfrAnalysisMgmt/modelYear').then(res => res.data)
            .then(res => {
              if (res.status === 'success') {
                this.modelYearMenu = res.data
                this.modelYear = []
              }
            })
          this.$http.get('/api/pqm/getSelectOptionData/mucModule').then(res => res.data)
            .then(res => {
              if (res.status === 'success') {
                this.mucModuleMenu = res.data
                this.mucModule = []
              }
            })
          this.getModelGroup(this.modelYear)
          this.getModel(this.modelYear, this.modelGroup)
        } else if (response.errCode === 'E10000002') {
          const routeData = this.$router.resolve({ path: '/noEnter/E10000002' })
          localStorage.setItem('errormsg', response.errMsg)
          window.location.assign(routeData.href)
        } else {
          const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
          window.location.assign(routeData.href)
        }
      })
      .catch(err => {
        console.log(err)
        const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
        window.location.assign(routeData.href)
      })
  },
  methods: {
    getModelGroup (year) {
      if (!this.loadingOfModelGroup) {
        this.loadingOfModelGroup = true
        this.$http.get(`/api/pqm/cfr/modelGroup?yearList=${year}`).then(res => res.data)
          .then(res => {
            this.loadingOfModelGroup = false
            if (res.status === 'success') {
              this.modelGroupMenu = res.data
              this.modelGroup = []
            }
          })
      }
    },
    getModel (year, modelGroup) {
      if (!this.loadingOfModel) {
        this.loadingOfModel = true
        this.$http.get(`/api/pqm/cfr/model?yearList=${year}&modelGroupList=${modelGroup}`).then(res => res.data)
          .then(res => {
            this.loadingOfModel = false
            if (res.status === 'success') {
              this.modelMenu = res.data
              this.model = []
            }
          })
      }
    },
    doDownload () {
      this.processingView = true
      this.$http.get(`/api/pqm/cfr/downloadModuleStatisticFile?modelGroupList=${this.modelGroup}&modelList=${this.model}&mucModule=${this.mucModule}&excludeBestBuy=${this.excludeBestBuy}`, { responseType: 'blob' })
        .then(res => {
          this.processingView = false
          if (res.data.type === 'application/json') {
            return new Promise((resolve, reject) => {
              const reader = new FileReader()
              reader.onload = () => {
                res.data = JSON.parse(reader.result)
                if (res.data.status === 'success') {
                  alert(res.data.data)
                } else {
                  this.snackbar = true
                  this.snackbarColor = 'red'
                  this.snackbarMsg = res.data.errMsg + ' (' + res.data.errCode + ')'
                }
              }
              reader.onerror = () => {
                reject(res.data.errMsg || res.data.data)
              }
              reader.readAsText(res.data)
            })
          } else if (res.data.status !== 'failure' && res.headers['content-disposition']) {
            const blob = new Blob([res.data], { type: 'application/octet-stream' })
            const url = window.URL.createObjectURL(blob)
            const fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]
            const downloadLink = document.createElement('a')
            downloadLink.href = url
            downloadLink.download = fileName

            document.body.appendChild(downloadLink)
            downloadLink.click()
            document.body.removeChild(downloadLink)
          } else {
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = '下載失敗，請通知系統管理員'
          }
        })
        .catch(err => {
          this.processingView = false
          this.snackbar = true
          this.snackbarColor = 'orange'
          this.snackbarMsg = err
        })
    }
  }
}
</script>
<style>
</style>
