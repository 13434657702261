<template>
  <div>
    <material-card
      color="general"
      title="Inline Edit Table(Fat & Protein)"
      text="Such a classic table pt.2: revenge of the crud"

    >

      <v-data-table
        :headers="headers"
        :items="desserts"
        class="elevation-1"
      >
        <!-- change table header color(or other properties) -->
        <template
          slot="headerCell"
          slot-scope="{ header }"
        >
          <span
            class="subheading font-weight-light text-general text--darken-3"
            v-text="header.text"
          />
        </template>
        <!-- inline editing data table -->
        <template v-slot:items="props">
          <td>
            <v-edit-dialog
              :return-value.sync="props.item.name"
              lazy
              @save="saveInline"
              @cancel="cancelInline"
              @open="openInline"
              @close="closeInline"
            > {{ props.item.name }}
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.name"
                  :rules="[max25chars]"
                  label="Edit"
                  single-line
                  counter
                />
              </template>
            </v-edit-dialog>
          </td>
          <td class="t">{{ props.item.calories }}</td>
          <td class="text-xs-right">
            <v-edit-dialog
              :return-value.sync="props.item.fat"
              large
              lazy
              persistent
              @save="saveInline"
              @cancel="cancelInline"
              @open="openInline"
              @close="closeInline"
            >
              <div>{{ props.item.fat }}</div>
              <template v-slot:input>
                <div class="mt-3 title">Update Fat</div>
              </template>
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.fat"
                  :rules="[max25chars]"
                  label="Edit"
                  single-line
                  counter
                  autofocus
                />
              </template>
            </v-edit-dialog>
          </td>
          <td class="">{{ props.item.carbs }}</td>
          <td class="text-xs-right">
            <v-edit-dialog
              :return-value.sync="props.item.protein"
              large
              lazy
              persistent
              @save="saveInline"
              @cancel="cancelInline"
              @open="openInline"
              @close="closeInline"
            >
              <div>{{ props.item.protein }}</div>
              <template v-slot:input>
                <div class="mt-3 title">Update Protein</div>
              </template>
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.protein"
                  :rules="[max25chars]"
                  label="Edit"
                  single-line
                  counter
                  autofocus
                />
              </template>
            </v-edit-dialog>
          </td>
        </template>
      </v-data-table>
      <v-snackbar
        v-model="snack"
        :timeout="3000"
        :color="snackColor">
        {{ snackText }}
        <v-btn
          flat
          @click="snack = false">Close</v-btn>
      </v-snackbar>
    </material-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      snack: false,
      snackColor: '',
      snackText: '',
      max25chars: v => v.length <= 25 || 'Input too long!',
      pagination: {},
      headers: [
        {
          text: 'Dessert (100g serving)',
          align: 'left',
          sortable: false,
          value: 'name'
        },
        { text: 'Calories', value: 'calories' },
        { text: 'Fat (g)', value: 'fat' },
        { text: 'Carbs (g)', value: 'carbs' },
        { text: 'Protein (g)', value: 'protein' },
        { text: 'Iron (%)', value: 'iron' }
      ],
      desserts: [
        {
          name: 'Frozen Yogurt',
          calories: 159,
          fat: 6.0,
          carbs: 24,
          protein: 4.0,
          iron: '1%'
        },
        {
          name: 'Ice cream sandwich',
          calories: 237,
          fat: 9.0,
          carbs: 37,
          protein: 4.3,
          iron: '1%'
        },
        {
          name: 'Eclair',
          calories: 262,
          fat: 16.0,
          carbs: 23,
          protein: 6.0,
          iron: '7%'
        },
        {
          name: 'Cupcake',
          calories: 305,
          fat: 3.7,
          carbs: 67,
          protein: 4.3,
          iron: '8%'
        },
        {
          name: 'Gingerbread',
          calories: 356,
          fat: 16.0,
          carbs: 49,
          protein: 3.9,
          iron: '16%'
        },
        {
          name: 'Jelly bean',
          calories: 375,
          fat: 0.0,
          carbs: 94,
          protein: 0.0,
          iron: '0%'
        },
        {
          name: 'Lollipop',
          calories: 392,
          fat: 0.2,
          carbs: 98,
          protein: 0,
          iron: '2%'
        },
        {
          name: 'Honeycomb',
          calories: 408,
          fat: 3.2,
          carbs: 87,
          protein: 6.5,
          iron: '45%'
        },
        {
          name: 'Donut',
          calories: 452,
          fat: 25.0,
          carbs: 51,
          protein: 4.9,
          iron: '22%'
        },
        {
          name: 'KitKat',
          calories: 518,
          fat: 26.0,
          carbs: 65,
          protein: 7,
          iron: '6%'
        }
      ]
    }
  },
  methods: {
    save () {
      this.snack = true
      this.snackColor = 'success'
      this.snackText = 'Data saved'
    },
    cancel () {
      this.snack = true
      this.snackColor = 'error'
      this.snackText = 'Canceled'
    },
    open () {
      this.snack = true
      this.snackColor = 'info'
      this.snackText = 'Dialog opened'
    },
    close () {
      // console.log('Dialog closed')
    }
  }
}
</script>
