<template>
  <v-container
    :style="'background: url(' + (theme === 'light' ? homeBgLight : homeBgDark) + ') center center / cover;opacity: 1;height: 100%;'"
    fluid>
    <v-dialog
      v-model="processingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        PROCESSING 處理中
      </p>
    </v-dialog>
    <v-dialog
      v-model="loadingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :color="snackbarColor"
      :top="true">
      {{ snackbarMsg }}
      <v-btn
        flat
        @click="snackbar = false">
        關閉
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import homeBgLight from '@/assets/img/Homebg_light.jpg'
import homeBgDark from '@/assets/img/Homebg_dark.jpg'
import Loading from '@/assets/img/loading.gif'

export default {
  name: 'DownloadPage',
  components: {
  },
  data: () => ({
    snackbar: false,
    snackbarColor: 'green',
    snackbarMsg: '',
    Loading,
    loadingView: false,
    processingView: false,
    homeBgLight,
    homeBgDark
  }),
  computed: {
    ...mapState('app', ['theme', 'shrink'])
  },
  watch: {
  },
  mounted () {
    if (this.$route.params.apiUrl) {
      const apiUrl = this.$route.params.apiUrl
      this.loadingView = true
      this.$store.dispatch('getAuthorize', `/system/download/${apiUrl}`)
        .then(response => {
          this.loadingView = false
          if (response.status === 'success' && response.data.accessible) {
            this.processingView = true
            this.$http.post(`/api/system/downloadUrl/${apiUrl}`).then(res => res.data)
              .then(res => {
                if (res.status === 'success') {
                  if (res.data.method === 'GET') {
                    this.doDownloadGet(res.data)
                  } else if (res.data.method === 'POST') {
                    this.doDownloadPost(res.data)
                  } else {
                    const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
                    window.location.assign(routeData.href)
                  }
                } else {
                  this.processingView = false
                  this.snackbar = true
                  this.snackbarColor = 'red'
                  this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
                  setTimeout(() => {
                    const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
                    window.location.assign(routeData.href)
                  }, 3000)
                }
              })
              .catch(err => {
                this.processingView = false
                this.snackbar = true
                this.snackbarColor = 'red'
                this.snackbarMsg = err
                console.log(err)
                setTimeout(() => {
                  const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
                  window.location.assign(routeData.href)
                }, 3000)
              })
          } else if (response.errCode === 'E10000002') {
            const routeData = this.$router.resolve({ path: '/noEnter/E10000002' })
            localStorage.setItem('errormsg', response.errMsg)
            window.location.assign(routeData.href)
          } else {
            const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
            window.location.assign(routeData.href)
          }
        })
        .catch(err => {
          console.log(err)
          const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
          window.location.assign(routeData.href)
        })
    } else {
      const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
      window.location.assign(routeData.href)
    }
  },
  methods: {
    doDownloadGet (data) {
      this.$http.get(`${data.url}`, { responseType: 'blob' })
        .then(res => {
          this.processingView = false
          if (res.data.type === 'application/json') {
            return new Promise((resolve, reject) => {
              const reader = new FileReader()
              reader.onload = () => {
                res.data = JSON.parse(reader.result)
                if (res.status === 'success') {
                  alert(res.data.data)
                  // resolve(Promise.reject(res.data.data))
                } else {
                  this.snackbar = true
                  this.snackbarColor = 'red'
                  this.snackbarMsg = res.data.errMsg + ' (' + res.data.errCode + ')'
                }
              }
              reader.onerror = () => {
                reject(res.data.errMsg)
              }
              reader.readAsText(res.data)
            })
          } else if (res.data.status !== 'failure' && res.headers['content-disposition']) {
          // if (res.status !== 'failure' && res.headers['content-disposition']) {
            const blob = new Blob([res.data], { type: 'application/octet-stream' })
            const url = window.URL.createObjectURL(blob)
            const fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]
            const downloadLink = document.createElement('a')
            downloadLink.href = url
            downloadLink.download = fileName

            document.body.appendChild(downloadLink)
            downloadLink.click()
            document.body.removeChild(downloadLink)
          } else {
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = '下載失敗，請通知系統管理員'
            setTimeout(() => {
              const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
              window.location.assign(routeData.href)
            }, 3000)
          }
        })
        .catch(err => {
          this.processingView = false
          this.snackbar = true
          this.snackbarColor = 'orange'
          this.snackbarMsg = err
          console.log(err)
          setTimeout(() => {
            const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
            window.location.assign(routeData.href)
          }, 3000)
        })
    },
    doDownloadPost (data) {
      this.$http.post(`${data.url}`, data.params)
        .then(res => {
          this.processingView = false
          if (res.data.type === 'application/json') {
            return new Promise((resolve, reject) => {
              const reader = new FileReader()
              reader.onload = () => {
                res.data = JSON.parse(reader.result)
                if (res.status === 'success') {
                  alert(res.data.data)
                  // resolve(Promise.reject(res.data.data))
                } else {
                  this.snackbar = true
                  this.snackbarColor = 'red'
                  this.snackbarMsg = res.data.errMsg + ' (' + res.data.errCode + ')'
                }
              }
              reader.onerror = () => {
                reject(res.data.errMsg)
              }
              reader.readAsText(res.data)
            })
          } else if (res.data.status !== 'failure' && res.headers['content-disposition']) {
          // if (res.status !== 'failure' && res.headers['content-disposition']) {
            const blob = new Blob([res.data], { type: 'application/octet-stream' })
            const url = window.URL.createObjectURL(blob)
            const fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]
            const downloadLink = document.createElement('a')
            downloadLink.href = url
            downloadLink.download = fileName

            document.body.appendChild(downloadLink)
            downloadLink.click()
            document.body.removeChild(downloadLink)
          } else {
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = '下載失敗，請通知系統管理員'
            setTimeout(() => {
              const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
              window.location.assign(routeData.href)
            }, 3000)
          }
        })
        .catch(err => {
          this.processingView = false
          this.snackbar = true
          this.snackbarColor = 'orange'
          this.snackbarMsg = err
          console.log(err)
          setTimeout(() => {
            const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
            window.location.assign(routeData.href)
          }, 3000)
        })
    }
  }
}
</script>
