<template>
  <v-container
    cfr
    fluid
    grid-list-xl
    pt-0
  >
    <v-layout wrap>
      <v-flex
        xs12
        lg12
      >
        <material-card
          title="CSC Raw Data匯出管理"
          class="card-padding v-card__first"
          color="general"
        >
          <v-form>
            <v-container
              py-0
              class="mw-100">
              <v-layout wrap>
                <v-flex
                  flex-small
                  d-flex
                  align-items-center
                  xs12
                  lg3>
                  <p class="m-0">時間</p>
                  <v-menu
                    v-model="summaryStartDateMenu"
                    :close-on-content-click="false"
                    :nudge-left="40"
                    lazy
                    transition="scale-transition"
                    style="max-width: 170px;margin-left: 10px;"
                    max-width="290px"
                    offset-y
                    full-width
                  >
                    <v-text-field
                      slot="activator"
                      :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                      v-model="summaryStartDate"
                      label=""
                      prepend-icon="event"
                    />
                    <v-date-picker
                      v-model="summaryStartDate"
                      :min="startdate"
                      :max="new Date() | date-format"
                      :color="theme==='dark'?'danger':'general'"
                      type="date"
                      no-title
                      scrollable
                      actions/>
                  </v-menu>
                  <p class="m-0 text-center px-3">到</p>
                  <v-menu
                    v-model="summaryEndDateMenu"
                    :close-on-content-click="false"
                    :nudge-left="40"
                    lazy
                    transition="scale-transition"
                    style="max-width: 170px;margin-left: 10px;"
                    max-width="290px"
                    offset-y
                    full-width
                  >
                    <v-text-field
                      slot="activator"
                      :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                      v-model="summaryEndDate"
                      label=""
                      prepend-icon="event"
                    />
                    <v-date-picker
                      v-model="summaryEndDate"
                      :min="summaryStartDate"
                      :max="new Date() | date-format"
                      :color="theme==='dark'?'danger':'general'"
                      type="date"
                      no-title
                      scrollable
                      actions/>
                  </v-menu>
                </v-flex>
                <v-flex lg6/>
                <v-flex
                  d-flex
                  xs12
                  lg4>
                  <v-radio-group
                    v-model="modelInputAction"
                    class="align-items-center"
                    row>
                    <v-radio
                      label="MODEL 選單"
                      value="modelDropDown"/>
                    <v-radio
                      label="文字輸入"
                      value="modelInputText"/>
                  </v-radio-group>
                </v-flex>
                <v-flex
                  v-if="modelInputAction==='modelDropDown'"
                  flex-small
                  xs12
                  lg2>
                  <v-autocomplete
                    v-model="modelYear"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="modelYearMenu"
                    label="開賣年度"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex
                  v-if="modelInputAction==='modelDropDown'"
                  flex-small
                  xs12
                  lg2
                  style="justify-content: space-between;display: flex;">
                  <v-autocomplete
                    v-model="model"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="modelMenu"
                    label="MODEL"
                    class="v-text-field__slot list-select-field"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex
                  v-if="modelInputAction==='modelInputText'"
                  flex-small
                  xs12
                  lg2
                  style="justify-content: space-between;display: flex;">
                  <v-text-field
                    v-model="model"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light' }"
                    label="自行輸入 MODEL..."
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex
                  xs12
                  lg2
                  text-xs-right
                >
                  <v-btn
                    class="mx-0 font-weight-light"
                    color="indigo"
                    @click="doDownload()"
                  >
                    <v-icon class="mr-2">mdi-export</v-icon>匯出Raw Data
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="processingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        PROCESSING 處理中
      </p>
    </v-dialog>
    <v-dialog
      v-model="loadingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :top="true"
      :color="snackbarColor">
      {{ snackbarMsg }}
      <v-btn
        dark
        flat
        @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import qs from 'qs'
import { mapState } from 'vuex'
import Loading from '@/assets/img/loading.gif'
export default {
  name: 'CscRawDataExport',
  data () {
    return {
      startdate: '2018-04-01',
      submit: false,
      snackbar: false,
      snackbarColor: 'red',
      snackbarMsg: '',
      Loading,
      loadingView: false,
      processingView: false,
      summaryStartDateMenu: false,
      summaryStartDate: this.$moment(new Date()).format('YYYY-MM-DD'),
      summaryEndDateMenu: false,
      summaryEndDate: this.$moment(new Date()).format('YYYY-MM-DD'),
      modelInputAction: 'modelDropDown',
      modelYear: '',
      modelYearMenu: [],
      modelMenu: [],
      model: '',
      nvModel: ''
    }
  },
  computed: {
    ...mapState('app', ['theme', 'shrink'])
  },
  watch: {
    startDate (val) {
      this.startDateMenu = false
    },
    endDate (val) {
      this.endDateMenu = false
    },
    modelYear (val) {
      this.getYearlyCfrAnalysisViewSelectOptionData('model', val)
    },
    modelInputAction (newVal, oldVal) {
      // 如果之前是 dropdown，現在變成 input，則清空輸入框
      if (oldVal === 'modelDropDown' && newVal === 'modelInputText') {
        this.model = ''
      }
    }
  },
  mounted () {
    this.$store.dispatch('getAuthorize', '/pqm/cscDataParsingMgmt/cscRawDataExportView')
      .then(response => {
        if (response.status === 'success' && response.data.accessible) {
          JSON.parse(localStorage.getItem('moduleDataInitDate')).forEach((n) => {
            if (n.name === 'csc') {
              this.startdate = n.value
            }
          })
          this.$http.get('/api/pqm/cfrAnalysisMgmt/modelYear').then(res => res.data)
            .then(res => {
              if (res.status === 'success') {
                this.modelYearMenu = res.data.sort((a, b) => b.value.localeCompare(a.value))
              }
            })
          // this.doSubmit()
        } else if (response.errCode === 'E10000002') {
          const routeData = this.$router.resolve({ path: '/noEnter/E10000002' })
          localStorage.setItem('errormsg', response.errMsg)
          window.location.assign(routeData.href)
        } else {
          const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
          window.location.assign(routeData.href)
        }
      })
      .catch(err => {
        console.log(err)
        const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
        window.location.assign(routeData.href)
      })
  },
  methods: {
    getYearlyCfrAnalysisViewSelectOptionData (fieldName, year) {
      this.$http.get(`/api/pqm/cfrAnalysisMgmt/getYearlyCfrAnalysisViewSelectOptionData/${fieldName}/${year}`).then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.modelMenu = res.data
            this.model = ''
          }
        })
    },
    doDownload () {
      if ((new Date(this.summaryEndDate).getTime() < new Date(this.summaryStartDate).getTime())) {
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '結尾時間不得早於起始時間'
      } else {
        this.processingView = true
        let body = {
          closedDateStart: this.summaryStartDate,
          closedDateEnd: this.summaryEndDate,
          model: this.model
        }

        this.$http.get('/api/pqm/cscDataParsingMgmt/exportCscRawData?' + qs.stringify(body, { arrayFormat: 'repeat', encode: true }), { observe: 'response', responseType: 'blob' })
          .then(res => {
            this.processingView = false
            if (res.data.type === 'application/json') {
              return new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.onload = () => {
                  res.data = JSON.parse(reader.result)
                  if (res.data.status === 'success') {
                    alert(res.data.data)
                    // resolve(Promise.reject(res.data.data))
                  } else {
                    this.snackbar = true
                    this.snackbarColor = 'red'
                    this.snackbarMsg = res.data.errMsg + ' (' + res.data.errCode + ')'
                  }
                }
                reader.onerror = () => {
                  reject(res.data.data)
                }
                reader.readAsText(res.data)
              })
            } else if (res.data.status !== 'failure' && res.headers['content-disposition']) {
              // let blob = new Blob([res.data])
              const url = window.URL.createObjectURL(res.data)
              const fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]
              const downloadLink = document.createElement('a')

              // downloadLink.setAttribute('download', fileName)
              downloadLink.href = url
              downloadLink.download = fileName

              document.body.appendChild(downloadLink)
              downloadLink.click()
              document.body.removeChild(downloadLink)
            } else {
              this.snackbar = true
              this.snackbarColor = 'red'
              this.snackbarMsg = '下載失敗，請通知系統管理員'
            }
          })
          .catch(err => {
            this.processingView = false
            this.snackbar = true
            this.snackbarColor = 'orange'
            this.snackbarMsg = err
          })
      }
    }
  }
}
</script>
<style>
</style>
