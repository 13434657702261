<template>
  <v-container
    fluid
    grid-list-xl
    pt-0
  >
    <v-layout wrap>
      <v-flex
        xs12
      >
        <material-card
          class="card-shrink card-padding v-card__first"
          color="general"
          title="群組搜尋"
        >
          <v-form>
            <v-container
              py-0
              class="mw-100">
              <v-layout wrap>
                <v-flex
                  xs12
                  sm6
                  lg4>
                  <v-text-field
                    v-model="summaryGroupName"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    label="群組名稱"/>
                </v-flex>
                <!-- <v-flex
                  xs12
                  sm6
                  lg4>
                  <v-autocomplete
                    v-model="summaryAuthority"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryAuthorityMenu"
                    label="群組功能"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined/>
                </v-flex> -->
                <v-flex
                  xs12
                  sm6
                  lg4>
                  <v-autocomplete
                    v-model="summaryAuthority"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryAuthorityMenu"
                    label="群組功能"
                    item-text="text"
                    item-value="value"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined>
                    <!-- <template
                      slot="selection"
                      slot-scope="data">
                      <v-chip
                        :selected="data.selected"
                        :key="JSON.stringify(data.item)"
                        close
                        class="chip--select-multi"
                        @input="data.parent.selectItem(data.item)"
                      >
                        {{ data.item.text }}
                      </v-chip>
                    </template> -->
                    <template
                      slot="item"
                      slot-scope="data">
                      <template v-if="data.item.level === 1">
                        <v-list-tile-content>
                          <v-list-tile-title
                            class="font-weight-bold h4"
                            v-html="data.item.text"/>
                        </v-list-tile-content>
                      </template>
                      <template v-else>
                        <v-list-tile-content class="ml-3">
                          <v-list-tile-title v-html="data.item.text"/>
                          <v-list-tile-sub-title
                            class="font-italic mt-0 h6"
                            v-html="'>> '+data.item.type"/>
                        </v-list-tile-content>
                      </template>
                      <!-- <template v-if="typeof data.item !== 'object'">
                        <v-list-tile-content v-text="data.item"/>
                      </template>
                      <template v-else>
                        <v-list-tile-content>
                          <v-list-tile-title v-html="data.item.text"/>
                          <v-list-tile-sub-title v-html="data.item.type"/>
                        </v-list-tile-content>
                      </template> -->
                    </template>
                  </v-autocomplete>
                </v-flex>
                <v-flex
                  xs12
                  lg4
                  text-xs-right
                >
                  <v-btn
                    class="mx-0 font-weight-light"
                    color="blue"
                    @click="doSubmit()"
                  >
                    <v-icon class="mr-2">mdi-backup-restore</v-icon>查詢
                  </v-btn>
                  <v-btn
                    class="mr-0 ml-3 font-weight-light"
                    color="pink"
                    @click="doGroupEditBefore('add', 0, '')"
                  >
                    <v-icon class="mr-2">mdi-plus</v-icon>新增群組
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
        <material-card
          class="d-inline-block freeze-title-small"
          style="min-height: 500px;">
          <v-data-table
            :headers="headers"
            :items="responseDataTable"
            :pagination.sync="pagination"
            multi-sort
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="subheading font-weight-light text-general text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <td class="table-width-125">{{ item.name }}</td>
              <td class="table-width-125">
                <v-btn
                  class="m-0 font-weight-light small"
                  color="deep-purple"
                  @click="doGroupEditBefore('edit', item.pk, item.name)">
                  <v-icon class="mr-2">mdi-pencil</v-icon>編輯群組
                </v-btn>
              </td>
            </template>
          </v-data-table>
        </material-card>
        <material-card
          v-if="action === 'edit' || action === 'add'"
          class="group-edit-area"
          style="min-height: 500px;">
          <v-container class="container mw-100 p-0">
            <v-text-field
              v-model="groupNameEdit"
              :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
              class="row-btn-150"
              label="群組名稱"/>
            <v-btn
              class="mr-0 mr-3 font-weight-light float-right"
              color="red"
              @click="doDeleteGroup()"
            >
              <v-icon class="mr-2">mdi-delete</v-icon>刪除群組
            </v-btn>
          </v-container>
          <v-container class="p-0 pb-3 mw-100">
            <v-layout wrap>
              <v-flex xs5>
                <v-alert
                  v-model="editGroupMemberArea"
                  class="m-0 px-2 py-1 border-0"
                  color="blue-grey">
                  <strong class="h5">已選使用者</strong>
                  <input
                    v-model="filterGroupUserName"
                    :style="theme==='dark'?'background:#000;color:#fff;':theme==='light'?'background:#fff;color:#000;':''"
                    placeholder="篩選使用者"
                    class="v-label row-btn-350 rounded p-1 float-right"
                    type="text">
                    <!-- <v-btn
                    color="indigo"
                    class="my-0 p-1 float-right"
                    @click="doViewGroupPrivilege(userGroupPrivilege, editUserGroupViewName+'('+editUserGroupViewNumber+')')">
                    <v-icon class="mr-1">mdi-eye</v-icon>檢視現有權限
                    </v-btn> -->
                </v-alert>
                <div
                  :style="theme==='dark'?'background:#333;':theme==='light'?'background:#bbb;':''"
                  style="height: calc((100vh - 600px) / 2);min-height: 70px;overflow-y: auto;margin-top: 0.5rem;margin-bottom: 0.25rem;">
                  <div
                    v-for="(gu_list, index) in responseDataGroupUser"
                    v-show="filterGroupUserName==='' ? true : (gu_list.name.toUpperCase().indexOf(filterGroupUserName.toUpperCase()) != -1)"
                    :key="'gu_list'+index"
                    :class="{'list-item-click' : responseDataGroupUserClick.findIndex(function checkGroup (g) { return g === gu_list.value }) > -1}"
                    class="list-item-area">
                    <span
                      class="w-100"
                      @click="doClickGroupUser(gu_list.value)"
                      @dblclick="doUserGroupToAvailable('alone', gu_list.value)">
                      {{ gu_list.name }}{{ gu_list.dept?' ('+gu_list.dept+')':'' }}
                    </span>
                    <!-- <v-btn
                      color="indigo"
                      class="list-item-btn"
                      @click="doViewGroupPrivilege([gu_list.value], gu_list.name)">
                      檢視權限
                    </v-btn> -->
                  </div>
                </div>
                <v-btn
                  color="blue ml-0"
                  small
                  class=""
                  @click="doClickGroupUser('all')">
                  選擇全部
                </v-btn>
                <v-btn
                  color="red mr-0"
                  small
                  class=""
                  @click="doClickGroupUser('reset')">
                  取消選擇
                </v-btn>
              </v-flex>
              <v-flex
                xs2
                style="text-align: center;padding: 60px 0 0 0 !important;">
                <v-btn
                  color="black"
                  style="margin: calc((100vh - 600px) / 16) 10px;"
                  @click="doUserAvailableToGroup('array')">
                  ＜＜＜新增
                </v-btn>
                <v-btn
                  color="black"
                  style="margin: calc((100vh - 600px) / 16) 10px;"
                  @click="doUserGroupToAvailable('array')">
                  移除＞＞＞
                </v-btn>
              </v-flex>
              <v-flex xs5>
                <v-alert
                  v-model="editGroupMemberArea"
                  class="m-0 px-2 py-1 border-0"
                  color="blue-grey">
                  <strong class="h5">可選使用者</strong>
                  <input
                    v-model="filterAvailableUserName"
                    :style="theme==='dark'?'background:#000;color:#fff;':theme==='light'?'background:#fff;color:#000;':''"
                    placeholder="篩選使用者"
                    class="v-label row-btn-350 rounded p-1 float-right"
                    type="text">
                    <!-- <v-btn
                    color="pink"
                    class="my-0 p-1 float-right"
                    @click="doUserGroupEdit()">
                    <v-icon class="mr-0">mdi-plus</v-icon>新增群組
                    </v-btn> -->
                </v-alert>
                <div
                  :style="theme==='dark'?'background:#333;':theme==='light'?'background:#bbb;':''"
                  style="height: calc((100vh - 600px) / 2);min-height: 70px;overflow-y: auto;margin-top: 0.5rem;margin-bottom: 0.25rem;">
                  <div
                    v-for="(au_list, index) in responseDataAvailableUser"
                    v-show="filterAvailableUserName==='' ? true : (au_list.name.toUpperCase().indexOf(filterAvailableUserName.toUpperCase()) != -1)"
                    :key="'au_list'+index"
                    :class="{'list-item-click' : responseDataAvailableUserClick.findIndex(function checkGroup (g) { return g === au_list.value }) > -1}"
                    class="list-item-area">
                    <span
                      class="w-100"
                      @click="doClickAvailableUser(au_list.value)"
                      @dblclick="doUserAvailableToGroup('alone', au_list.value)">
                      {{ au_list.name }}{{ au_list.dept?' ('+au_list.dept+')':'' }}
                    </span>
                    <!-- <v-btn
                      color="indigo"
                      class="list-item-btn"
                      @click="doViewGroupPrivilege([au_list.value], au_list.name)">
                      檢視權限
                    </v-btn> -->
                  </div>
                </div>
                <v-btn
                  color="blue ml-0"
                  small
                  class=""
                  @click="doClickAvailableUser('all')">
                  選擇全部
                </v-btn>
                <v-btn
                  color="red mr-0"
                  small
                  class=""
                  @click="doClickAvailableUser('reset')">
                  取消選擇
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
          <v-container class="px-0 py-3 mw-100 border-top border-secondary">
            <v-layout wrap>
              <v-flex xs5>
                <v-alert
                  v-model="editGroupPrivilegeArea"
                  class="m-0 px-2 py-1 border-0"
                  color="blue-grey">
                  <strong class="h5">已選功能</strong>
                  <input
                    v-model="filterGroupPrivilegeName"
                    :style="theme==='dark'?'background:#000;color:#fff;':theme==='light'?'background:#fff;color:#000;':''"
                    placeholder="篩選功能"
                    class="v-label row-btn-350 rounded p-1 float-right"
                    type="text">
                    <!-- <v-btn
                    color="indigo"
                    class="my-0 p-1 float-right"
                    @click="doViewGroupPrivilege(userGroupPrivilege, editUserGroupViewName+'('+editUserGroupViewNumber+')')">
                    <v-icon class="mr-1">mdi-eye</v-icon>檢視現有權限
                    </v-btn> -->
                </v-alert>
                <div
                  :style="theme==='dark'?'background:#333;':theme==='light'?'background:#bbb;':''"
                  style="height: calc((100vh - 600px) / 2);min-height: 70px;overflow-y: auto;margin-top: 0.5rem;margin-bottom: 0.25rem;">
                  <div
                    v-for="(gp_list, index) in responseDataGroupPrivilege"
                    v-show="filterGroupPrivilegeName==='' ? true : (gp_list.keyword.toUpperCase().indexOf(filterGroupPrivilegeName.toUpperCase()) != -1)"
                    :key="'gp_list'+index"
                    :class="{'list-item-click' : responseDataGroupPrivilegeClick.findIndex(function checkGroup (g) { return g === gp_list.seq }) > -1}"
                    class="list-item-area">
                    <span
                      v-if="gp_list.text"
                      :class="{'pl-3': gp_list.level===2}"
                      class="w-100"
                      @click="doClickGroupPrivilege(gp_list.seq, gp_list)"
                      @dblclick="doPrivilegeGroupToAvailable('alone', gp_list)">
                      {{ gp_list.text }}
                    </span>
                    <hr
                      v-if="gp_list.divider"
                      class="my-3 border-secondary">
                      <!-- <v-btn
                      color="indigo"
                      class="list-item-btn"
                      @click="doViewGroupPrivilege([gp_list.value], gp_list.text)">
                      檢視權限
                    </v-btn> -->
                  </div>
                </div>
                <v-btn
                  color="blue ml-0"
                  small
                  class=""
                  @click="doClickGroupPrivilege('all')">
                  選擇全部
                </v-btn>
                <v-btn
                  color="red mr-0"
                  small
                  class=""
                  @click="doClickGroupPrivilege('reset')">
                  取消選擇
                </v-btn>
              </v-flex>
              <v-flex
                xs2
                style="text-align: center;padding: 60px 0 0 0 !important;">
                <v-btn
                  color="black"
                  style="margin: calc((100vh - 600px) / 16) 10px;"
                  @click="doPrivilegeAvailableToGroup('array')">
                  ＜＜＜新增
                </v-btn>
                <v-btn
                  color="black"
                  style="margin: calc((100vh - 600px) / 16) 10px;"
                  @click="doPrivilegeGroupToAvailable('array')">
                  移除＞＞＞
                </v-btn>
              </v-flex>
              <v-flex xs5>
                <v-alert
                  v-model="editGroupPrivilegeArea"
                  class="m-0 px-2 py-1 border-0"
                  color="blue-grey">
                  <strong class="h5">可選功能</strong>
                  <input
                    v-model="filterAvailablePrivilegeName"
                    :style="theme==='dark'?'background:#000;color:#fff;':theme==='light'?'background:#fff;color:#000;':''"
                    placeholder="篩選功能"
                    class="v-label row-btn-350 rounded p-1 float-right"
                    type="text">
                    <!-- <v-btn
                    color="pink"
                    class="my-0 p-1 float-right"
                    @click="doUserGroupEdit()">
                    <v-icon class="mr-0">mdi-plus</v-icon>新增群組
                    </v-btn> -->
                </v-alert>
                <div
                  :style="theme==='dark'?'background:#333;':theme==='light'?'background:#bbb;':''"
                  style="height: calc((100vh - 600px) / 2);min-height: 70px;overflow-y: auto;margin-top: 0.5rem;margin-bottom: 0.25rem;">
                  <div
                    v-for="(ap_list, index) in responseDataAvailablePrivilege"
                    v-show="filterAvailablePrivilegeName==='' ? true : (ap_list.keyword.toUpperCase().indexOf(filterAvailablePrivilegeName.toUpperCase()) != -1)"
                    :key="'ap_list'+index"
                    :class="{'list-item-click' : responseDataAvailablePrivilegeClick.findIndex(function checkGroup (g) { return g === ap_list.seq }) > -1}"
                    class="list-item-area">
                    <span
                      v-if="ap_list.text"
                      :class="{'pl-3': ap_list.level===2}"
                      class="w-100"
                      @click="doClickAvailablePrivilege(ap_list.seq, ap_list)"
                      @dblclick="doPrivilegeAvailableToGroup('alone', ap_list)">
                      {{ ap_list.text }}
                    </span>
                    <hr
                      v-if="ap_list.divider"
                      class="my-3 border-secondary">
                      <!-- <v-btn
                      color="indigo"
                      class="list-item-btn"
                      @click="doViewGroupPrivilege([ap_list.value], ap_list.text)">
                      檢視權限
                    </v-btn> -->
                  </div>
                </div>
                <v-btn
                  color="blue ml-0"
                  small
                  class=""
                  @click="doClickAvailablePrivilege('all')">
                  選擇全部
                </v-btn>
                <v-btn
                  color="red mr-0"
                  small
                  class=""
                  @click="doClickAvailablePrivilege('reset')">
                  取消選擇
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
          <v-card-actions class="m-0 border-top border-secondary">
            <v-spacer/>
            <v-btn
              color="red"
              small
              @click="action=''">
              <v-icon class="mr-2">mdi-close</v-icon>返回
            </v-btn>
            <v-btn
              color="lime"
              small
              class="text-dark"
              @click="doGroupEdit('edit', editGroupViewPk , editGroupViewName)">
              <v-icon class="mr-2">mdi-replay</v-icon>重置
            </v-btn>
            <v-btn
              v-if="action==='edit'"
              color="blue"
              class="mr-3"
              small
              @click="doSaveGroupEdit(editUserGroupViewPk)">
              <v-icon class="mr-2">mdi-content-save</v-icon>儲存
            </v-btn>
            <v-btn
              v-if="action==='add'"
              color="pink"
              class="mr-3"
              small
              @click="doAddGroup()">
              <v-icon class="mr-2">mdi-plus</v-icon>新增
            </v-btn>
          </v-card-actions>
        </material-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="processingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        PROCESSING 處理中
      </p>
    </v-dialog>
    <v-dialog
      v-model="loadingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :color="snackbarColor"
      :top="true">
      {{ snackbarMsg }}
      <v-btn
        flat
        @click="snackbar = false">
        關閉
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import Loading from '@/assets/img/loading.gif'

export default {
  name: 'GroupManagementSetting',
  components: {
  },
  data () {
    return {
      snackbar: false,
      snackbarColor: 'green',
      snackbarMsg: '',
      Loading,
      loadingView: false,
      processingView: false,
      action: '',
      editGroupPrivilegeArea: true,
      editGroupMemberArea: true,
      summaryAuthorityMenu: [],
      summaryGroupName: '',
      summaryAuthority: 'All',
      filterGroupUserName: '',
      filterAvailableUserName: '',
      filterGroupPrivilegeName: '',
      filterAvailablePrivilegeName: '',
      responseDataTable: [],
      responseDataAvailableUser: [],
      responseDataAvailableUserClick: [],
      responseDataGroupUser: [],
      responseDataGroupUserClick: [],
      responseDataAvailablePrivilege: [],
      responseDataAvailablePrivilegeClick: [],
      responseDataGroupPrivilege: [],
      responseDataGroupPrivilegeClick: [],
      editGroupViewName: '',
      editGroupViewPk: '',
      groupNameEdit: '',
      checkList: [],
      pagination: {
        rowsPerPage: 1,
        sortBy: 'name', // The field that you're sorting by
        descending: true
      },
      headers: [
        {
          sortable: true,
          text: '群組名稱',
          value: 'name',
          class: 'table-width-135'
        },
        {
          sortable: false,
          text: '操作',
          value: 'operation',
          class: 'table-width-125'
        }
      ]
    }
  },
  computed: {
    ...mapState('app', ['theme', 'shrink'])
  },
  watch: {
    filterGroupUserName (keyword) {
      this.responseDataGroupUserClick = []
    },
    filterAvailableUserName (keyword) {
      this.responseDataAvailableUserClick = []
    },
    filterGroupPrivilegeName (keyword) {
      this.responseDataGroupPrivilegeClick = []
    },
    filterAvailablePrivilegeName (keyword) {
      this.responseDataAvailablePrivilegeClick = []
    }
  },
  mounted () {
    this.$store.dispatch('getAuthorize', '/privilege/groupManagementSetting')
      .then(response => {
        if (response.status === 'success' && response.data.accessible) {
          if (this.$route.params.action) {
            this.action = this.$route.params.action
          }
          this.$http.get('/api/getSelectOptionData/menu').then(res => res.data)
            .then(res => {
              if (res.status === 'success') {
                this.summaryAuthorityMenu = [{ text: 'All', value: 'All', type: 'All', level: 1 }, { divider: true }]
                res.data.forEach((m) => {
                  this.summaryAuthorityMenu.push({ text: m.text, value: m.pk, type: m.text, level: 1 })
                  m.children.forEach((mm) => {
                    this.summaryAuthorityMenu.push({ text: mm.text, value: mm.pk, type: m.text, level: 2 })
                  })
                  this.summaryAuthorityMenu.push({ divider: true })
                })
                // this.summaryAuthorityMenu = [{ text: 'All', value: 'All'}].concat(res.data)
              }
            })
          this.doSubmit()
        } else if (response.errCode === 'E10000002') {
          const routeData = this.$router.resolve({ path: '/noEnter/E10000002' })
          localStorage.setItem('errormsg', response.errMsg)
          window.location.assign(routeData.href)
        } else {
          const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
          window.location.assign(routeData.href)
        }
      })
      .catch(err => {
        console.log(err)
        const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
        window.location.assign(routeData.href)
      })
  },
  methods: {
    doGroupEditBefore (action, pk, name) {
      if (this.action !== '') {
        if (confirm('目前群組編輯區塊內容將不會被儲存，是否繼續此操作?')) {
          this.doGroupEdit(action, pk, name)
        }
      } else {
        this.doGroupEdit(action, pk, name)
      }
    },
    doGroupEdit (action, pk, name) {
      this.loadingView = true
      this.$http.post('/api/system/groupInfo/' + pk).then(res => res.data)
        .then(res => {
          this.loadingView = false
          if (res.status === 'success') {
            this.filterGroupUserName = ''
            this.filterAvailableUserName = ''
            this.filterGroupPrivilegeName = ''
            this.filterAvailablePrivilegeName = ''
            this.responseDataAvailableUser = res.data.availableUser
            this.responseDataAvailableUserClick = []
            this.responseDataGroupUser = res.data.groupUser
            this.responseDataGroupUserClick = []

            this.responseDataGroupPrivilege = res.data.groupPrivilege
            this.responseDataGroupPrivilegeClick = []

            this.responseDataAvailablePrivilege = []
            this.responseDataAvailablePrivilegeClick = []
            res.data.availablePrivilege.forEach((m) => {
              let keyword = ''
              m.children.forEach((mm) => {
                keyword = keyword + ',' + mm.text
                this.responseDataAvailablePrivilege.push({ pk: mm.pk, seq: (m.seq * 100) + mm.seq, type: m.text, level: 2, text: mm.text, keyword: m.text + ',' + mm.text })
              })
              this.responseDataAvailablePrivilege.push({ pk: m.pk, seq: m.seq * 100, type: m.text, level: 1, text: m.text, keyword: m.text + keyword })
              this.responseDataAvailablePrivilege.push({ seq: (m.seq * 100) + 99, type: m.text, keyword: m.text + keyword, divider: true })
            })
            this.responseDataAvailablePrivilege.sort(function (a, b) {
              return a.seq - b.seq
            })

            this.responseDataGroupPrivilege = []
            this.responseDataGroupPrivilegeClick = []
            res.data.groupPrivilege.forEach((m) => {
              let keyword = ''
              m.children.forEach((mm) => {
                keyword = keyword + ',' + mm.text
                this.responseDataGroupPrivilege.push({ pk: mm.pk, seq: (m.seq * 100) + mm.seq, type: m.text, level: 2, text: mm.text, keyword: m.text + ',' + mm.text })
              })
              this.responseDataGroupPrivilege.push({ pk: m.pk, seq: m.seq * 100, type: m.text, level: 1, text: m.text, keyword: m.text + keyword })
              this.responseDataGroupPrivilege.push({ seq: (m.seq * 100) + 99, type: m.text, keyword: m.text + keyword, divider: true })
            })
            this.responseDataGroupPrivilege.sort(function (a, b) {
              return a.seq - b.seq
            })
          }
        })
      this.action = action
      this.editGroupViewName = name
      this.groupNameEdit = name
      this.editGroupViewPk = pk
    },
    doClickAvailableUser (pk) {
      if (pk === 'all') {
        this.responseDataAvailableUserClick = []
        this.responseDataAvailableUser.forEach((g) => {
          if (this.filterAvailableUserName === '') {
            this.responseDataAvailableUserClick.push(g.value)
          } else if (g.name.toUpperCase().indexOf(this.filterAvailableUserName.toUpperCase()) !== -1) {
            this.responseDataAvailableUserClick.push(g.value)
          }
        })
      } else if (pk === 'reset') {
        this.responseDataAvailableUserClick = []
      } else {
        if (this.responseDataAvailableUserClick.findIndex(function checkUser (g) { return g === pk }) === -1) {
          this.responseDataAvailableUserClick.push(pk)
        } else {
          this.responseDataAvailableUserClick.splice(this.responseDataAvailableUserClick.findIndex(function checkUser (g) { return g === pk }), 1)
        }
      }
    },
    doClickGroupUser (pk) {
      if (pk === 'all') {
        this.responseDataGroupUserClick = []
        this.responseDataGroupUser.forEach((g) => {
          if (this.filterGroupUserName === '') {
            this.responseDataGroupUserClick.push(g.value)
          } else if (g.name.toUpperCase().indexOf(this.filterGroupUserName.toUpperCase()) !== -1) {
            this.responseDataGroupUserClick.push(g.value)
          }
        })
      } else if (pk === 'reset') {
        this.responseDataGroupUserClick = []
      } else {
        if (this.responseDataGroupUserClick.findIndex(function checkUser (g) { return g === pk }) === -1) {
          this.responseDataGroupUserClick.push(pk)
        } else {
          this.responseDataGroupUserClick.splice(this.responseDataGroupUserClick.findIndex(function checkUser (g) { return g === pk }), 1)
        }
      }
    },
    doClickAvailablePrivilege (seq, item) {
      if (seq === 'all') {
        this.responseDataAvailablePrivilegeClick = []
        this.responseDataAvailablePrivilege.forEach((g) => {
          if (this.filterAvailablePrivilegeName === '') {
            this.responseDataAvailablePrivilegeClick.push(g.seq)
          } else if (g.keyword.toUpperCase().indexOf(this.filterAvailablePrivilegeName.toUpperCase()) !== -1) {
            this.responseDataAvailablePrivilegeClick.push(g.seq)
          }
        })
      } else if (seq === 'reset') {
        this.responseDataAvailablePrivilegeClick = []
      } else {
        if (item.level === 1) {
          if (this.responseDataAvailablePrivilegeClick.findIndex(function checkPrivilege (g) { return g === seq }) === -1) {
            this.responseDataAvailablePrivilege.forEach((a) => {
              if (a.type === item.type) {
              // if (a.type === item.type && (this.responseDataGroupPrivilege.findIndex(function checkPrivilege (g) { return g.seq === a.seq }) === -1)) {
                // this.responseDataAvailablePrivilegeClick.push(a.seq)
                if (this.filterAvailablePrivilegeName === '') {
                  this.responseDataAvailablePrivilegeClick.push(a.seq)
                } else if (a.keyword.toUpperCase().indexOf(this.filterAvailablePrivilegeName.toUpperCase()) !== -1) {
                  this.responseDataAvailablePrivilegeClick.push(a.seq)
                }
              }
            })
          } else {
            this.responseDataAvailablePrivilege.forEach((a) => {
              if (a.type === item.type) {
                if (this.filterAvailablePrivilegeName === '') {
                  this.responseDataAvailablePrivilegeClick.splice(this.responseDataAvailablePrivilegeClick.findIndex(function checkPrivilege (g) { return g === seq }), 1)
                } else if (a.keyword.toUpperCase().indexOf(this.filterAvailablePrivilegeName.toUpperCase()) !== -1) {
                  this.responseDataAvailablePrivilegeClick.splice(this.responseDataAvailablePrivilegeClick.findIndex(function checkPrivilege (g) { return g === seq }), 1)
                }
              }
            })
          }
        } else {
          if (this.responseDataAvailablePrivilegeClick.findIndex(function checkPrivilege (g) { return g === seq }) === -1) {
            this.responseDataAvailablePrivilege.forEach((a) => {
              if (a.seq === seq) {
                this.responseDataAvailablePrivilegeClick.push(a.seq)
              }
              if (a.text === item.type) {
                this.responseDataAvailablePrivilegeClick.push(a.seq)
              }
              if (a.divider && a.type === item.type) {
                this.responseDataAvailablePrivilegeClick.push(a.seq)
              }
            })
          } else {
            this.responseDataAvailablePrivilegeClick.splice(this.responseDataAvailablePrivilegeClick.findIndex(function checkPrivilege (g) { return g === seq }), 1)
          }
        }
        // if (this.responseDataAvailablePrivilegeClick.findIndex(function checkPrivilege (g) { return g === seq }) === -1) {
        //   this.responseDataAvailablePrivilegeClick.push(seq)
        // } else {
        //   this.responseDataAvailablePrivilegeClick.splice(this.responseDataAvailablePrivilegeClick.findIndex(function checkPrivilege (g) { return g === seq }), 1)
        // }
      }
    },
    doClickGroupPrivilege (seq, item) {
      if (seq === 'all') {
        this.responseDataGroupPrivilegeClick = []
        this.responseDataGroupPrivilege.forEach((g) => {
          if (this.filterGroupPrivilegeName === '') {
            this.responseDataGroupPrivilegeClick.push(g.seq)
          } else if (g.keyword.toUpperCase().indexOf(this.filterGroupPrivilegeName.toUpperCase()) !== -1) {
            this.responseDataGroupPrivilegeClick.push(g.seq)
          }
        })
      } else if (seq === 'reset') {
        this.responseDataGroupPrivilegeClick = []
      } else {
        if (item.level === 1) {
          if (this.responseDataGroupPrivilegeClick.findIndex(function checkPrivilege (g) { return g === seq }) === -1) {
            this.responseDataGroupPrivilege.forEach((a) => {
              if (a.type === item.type) {
              // if (a.type === item.type && (this.responseDataAvailablePrivilege.findIndex(function checkPrivilege (g) { return g.seq === a.seq }) === -1)) {
                // this.responseDataGroupPrivilegeClick.push(a.seq)
                if (this.filterGroupPrivilegeName === '') {
                  this.responseDataGroupPrivilegeClick.push(a.seq)
                } else if (a.keyword.toUpperCase().indexOf(this.filterGroupPrivilegeName.toUpperCase()) !== -1) {
                  this.responseDataGroupPrivilegeClick.push(a.seq)
                }
              }
            })
          } else {
            this.responseDataGroupPrivilege.forEach((a) => {
              if (a.type === item.type) {
                if (this.filterGroupPrivilegeName === '') {
                  this.responseDataGroupPrivilegeClick.splice(this.responseDataGroupPrivilegeClick.findIndex(function checkPrivilege (g) { return g === seq }), 1)
                } else if (a.keyword.toUpperCase().indexOf(this.filterGroupPrivilegeName.toUpperCase()) !== -1) {
                  this.responseDataGroupPrivilegeClick.splice(this.responseDataGroupPrivilegeClick.findIndex(function checkPrivilege (g) { return g === seq }), 1)
                }
              }
            })
          }
        } else {
          if (this.responseDataGroupPrivilegeClick.findIndex(function checkPrivilege (g) { return g === seq }) === -1) {
            this.responseDataGroupPrivilege.forEach((a) => {
              if (a.seq === seq) {
                this.responseDataGroupPrivilegeClick.push(a.seq)
              }
              if (a.text === item.type) {
                this.responseDataGroupPrivilegeClick.push(a.seq)
              }
              if (a.divider && a.type === item.type) {
                this.responseDataGroupPrivilegeClick.push(a.seq)
              }
            })
          } else {
            this.responseDataGroupPrivilegeClick.splice(this.responseDataGroupPrivilegeClick.findIndex(function checkPrivilege (g) { return g === seq }), 1)
          }
        }
        // if (this.responseDataGroupPrivilegeClick.findIndex(function checkPrivilege (g) { return g === seq }) === -1) {
        //   this.responseDataGroupPrivilegeClick.push(seq)
        // } else {
        //   this.responseDataGroupPrivilegeClick.splice(this.responseDataGroupPrivilegeClick.findIndex(function checkPrivilege (g) { return g === seq }), 1)
        // }
      }
    },
    doUserAvailableToGroup (type, pk) {
      if (type === 'alone') {
        if (this.responseDataGroupUser.findIndex(function checkUser (g) { return g.value === pk }) === -1) {
          this.responseDataAvailableUser.forEach((g) => {
            if (g.value === pk) {
              this.responseDataGroupUser.push(g)
              this.responseDataAvailableUser.splice(this.responseDataAvailableUser.findIndex(function checkUser (d) { return d.value === g.value }), 1)
            }
          })
        }
      } else if (type === 'array') {
        this.responseDataAvailableUserClick.forEach((a) => {
          if (this.responseDataGroupUser.findIndex(function checkUser (g) { return g.value === a }) === -1) {
            this.responseDataAvailableUser.forEach((g) => {
              if (g.value === a) {
                this.responseDataGroupUser.push(g)
                this.responseDataAvailableUser.splice(this.responseDataAvailableUser.findIndex(function checkUser (d) { return d.value === g.value }), 1)
              }
            })
          }
        })
      }
      this.responseDataAvailableUserClick = []
      this.responseDataGroupUserClick = []
    },
    doUserGroupToAvailable (type, pk) {
      if (type === 'alone') {
        if (this.responseDataAvailableUser.findIndex(function checkUser (g) { return g.value === pk }) === -1) {
          this.responseDataGroupUser.forEach((g) => {
            if (g.value === pk) {
              this.responseDataAvailableUser.push(g)
              this.responseDataGroupUser.splice(this.responseDataGroupUser.findIndex(function checkUser (d) { return d.value === g.value }), 1)
            }
          })
        }
      } else if (type === 'array') {
        this.responseDataGroupUserClick.forEach((a) => {
          if (this.responseDataAvailableUser.findIndex(function checkUser (g) { return g.value === a }) === -1) {
            this.responseDataGroupUser.forEach((g) => {
              if (g.value === a) {
                this.responseDataAvailableUser.push(g)
                this.responseDataGroupUser.splice(this.responseDataGroupUser.findIndex(function checkUser (d) { return d.value === g.value }), 1)
              }
            })
          }
        })
      }
      this.responseDataAvailableUserClick = []
      this.responseDataGroupUserClick = []
    },
    doPrivilegeAvailableToGroup (type, item) {
      if (type === 'alone') {
        if (item.level === 1) {
          const seqList = []
          this.responseDataAvailablePrivilege.forEach((a) => {
            if (a.type === item.type) {
              seqList.push(a.seq)
              if (this.responseDataGroupPrivilege.findIndex(function checkPrivilege (g) { return g.seq === a.seq }) === -1) {
                this.responseDataGroupPrivilege.push(a)
              }
            }
          })
          seqList.forEach((s) => {
            this.responseDataAvailablePrivilege.splice(this.responseDataAvailablePrivilege.findIndex(function checkPrivilege (d) { return d.seq === s }), 1)
          })
        }
        if (item.level === 2) {
          let typeQty = 0
          let typeSeq = 0
          let typeDividerSeq = 0
          if (this.responseDataGroupPrivilege.findIndex(function checkPrivilege (g) { return g.seq === item.seq }) === -1) {
            this.responseDataGroupPrivilege.push(item)
            this.responseDataAvailablePrivilege.splice(this.responseDataAvailablePrivilege.findIndex(function checkPrivilege (d) { return d.seq === item.seq }), 1)
          }
          this.responseDataAvailablePrivilege.forEach((a) => {
            if (a.text === item.type && a.level === 1) {
              typeSeq = a.seq
              if (this.responseDataGroupPrivilege.findIndex(function checkPrivilege (g) { return g.seq === a.seq }) === -1) {
                this.responseDataGroupPrivilege.push(a)
              }
            }
            if (a.type === item.type && a.divider) {
              typeDividerSeq = a.seq
              if (this.responseDataGroupPrivilege.findIndex(function checkPrivilege (g) { return g.seq === a.seq }) === -1) {
                this.responseDataGroupPrivilege.push(a)
              }
            }
            if (a.type === item.type && a.level === 2) {
              typeQty++
            }
          })
          if (typeQty === 0) {
            if (this.responseDataAvailablePrivilege.findIndex(function checkPrivilege (d) { return d.seq === typeSeq }) > -1) {
              this.responseDataAvailablePrivilege.splice(this.responseDataAvailablePrivilege.findIndex(function checkPrivilege (d) { return d.seq === typeSeq }), 1)
              this.responseDataAvailablePrivilege.splice(this.responseDataAvailablePrivilege.findIndex(function checkPrivilege (d) { return d.seq === typeDividerSeq }), 1)
            }
          }
        }
      } else if (type === 'array') {
        const typeList = []
        // let typeSeqList = []
        const seqList = []
        this.responseDataAvailablePrivilege.forEach((a) => {
          if (a.level === 2 && (this.responseDataAvailablePrivilegeClick.findIndex(function checkPrivilege (g) { return g === a.seq }) > -1)) {
            if (this.responseDataGroupPrivilege.findIndex(function checkPrivilege (g) { return g.seq === a.seq }) === -1) {
              this.responseDataGroupPrivilege.push(a)
            }
            typeList.push(a.type)
            seqList.push(a.seq)
            this.responseDataAvailablePrivilege.forEach((aa) => {
              if (aa.level === 1 && aa.text === a.type) {
                if (this.responseDataGroupPrivilege.findIndex(function checkPrivilege (g) { return g.seq === aa.seq }) === -1) {
                  this.responseDataGroupPrivilege.push(aa)
                  // typeSeqList.push(aa.seq)
                }
              }
              if (aa.divider && aa.type === a.type) {
                if (this.responseDataGroupPrivilege.findIndex(function checkPrivilege (g) { return g.seq === aa.seq }) === -1) {
                  this.responseDataGroupPrivilege.push(aa)
                  // typeSeqList.push(aa.seq)
                }
              }
            })
            // this.responseDataAvailablePrivilege.splice(this.responseDataAvailablePrivilege.findIndex(function checkPrivilege (g) { return g.seq === a.seq }), 1)
            // this.responseDataAvailablePrivilegeClick.splice(this.responseDataAvailablePrivilegeClick.findIndex(function checkPrivilege (g) { return g === a.seq }), 1)
          }
        })
        seqList.forEach((c) => {
          if (this.responseDataAvailablePrivilege.findIndex(function checkPrivilege (g) { return g.seq === c }) > -1) {
            this.responseDataAvailablePrivilege.splice(this.responseDataAvailablePrivilege.findIndex(function checkPrivilege (g) { return g.seq === c }), 1)
          }
        })
        typeList.forEach((c) => {
          let typeQty = 0
          this.responseDataAvailablePrivilege.forEach((a) => {
            if (a.level === 2 && a.type === c) {
              typeQty++
            }
          })
          if (typeQty === 0) {
            // typeSeqList.forEach((s) => {
            //   if (this.responseDataAvailablePrivilege.findIndex(function checkPrivilege (d) { console.log(d.seq + '-' + s); return d.seq === s }) > -1) {
            //     this.responseDataAvailablePrivilege.splice(this.responseDataAvailablePrivilege.findIndex(function checkPrivilege (d) { console.log(d.seq + '-' + s); return d.seq === s }), 1)
            //   }
            // })
            if (this.responseDataAvailablePrivilege.findIndex(function checkPrivilege (d) { return d.type === c }) > -1) {
              this.responseDataAvailablePrivilege.splice(this.responseDataAvailablePrivilege.findIndex(function checkPrivilege (d) { return d.type === c }), 1)
              this.responseDataAvailablePrivilege.splice(this.responseDataAvailablePrivilege.findIndex(function checkPrivilege (d) { return d.type === c }), 1)
            }
          }
        })
      }
      this.responseDataAvailablePrivilegeClick = []
      this.responseDataGroupPrivilegeClick = []
      this.responseDataAvailablePrivilege.sort(function (a, b) {
        return a.seq - b.seq
      })
      this.responseDataGroupPrivilege.sort(function (a, b) {
        return a.seq - b.seq
      })
    },
    doPrivilegeGroupToAvailable (type, item) {
      if (type === 'alone') {
        if (item.level === 1) {
          const seqList = []
          this.responseDataGroupPrivilege.forEach((a) => {
            if (a.type === item.type) {
              seqList.push(a.seq)
              if (this.responseDataAvailablePrivilege.findIndex(function checkPrivilege (g) { return g.seq === a.seq }) === -1) {
                this.responseDataAvailablePrivilege.push(a)
              }
            }
          })
          seqList.forEach((s) => {
            this.responseDataGroupPrivilege.splice(this.responseDataGroupPrivilege.findIndex(function checkPrivilege (d) { return d.seq === s }), 1)
          })
        }
        if (item.level === 2) {
          let typeQty = 0
          let typeSeq = 0
          let typeDividerSeq = 0
          if (this.responseDataAvailablePrivilege.findIndex(function checkPrivilege (g) { return g.seq === item.seq }) === -1) {
            this.responseDataAvailablePrivilege.push(item)
            this.responseDataGroupPrivilege.splice(this.responseDataGroupPrivilege.findIndex(function checkPrivilege (d) { return d.seq === item.seq }), 1)
          }
          this.responseDataGroupPrivilege.forEach((a) => {
            if (a.text === item.type && a.level === 1) {
              typeSeq = a.seq
              if (this.responseDataAvailablePrivilege.findIndex(function checkPrivilege (g) { return g.seq === a.seq }) === -1) {
                this.responseDataAvailablePrivilege.push(a)
              }
            }
            if (a.type === item.type && a.divider) {
              typeDividerSeq = a.seq
              if (this.responseDataAvailablePrivilege.findIndex(function checkPrivilege (g) { return g.seq === a.seq }) === -1) {
                this.responseDataAvailablePrivilege.push(a)
              }
            }
            if (a.type === item.type && a.level === 2) {
              typeQty++
            }
          })
          if (typeQty === 0) {
            if (this.responseDataGroupPrivilege.findIndex(function checkPrivilege (d) { return d.seq === typeSeq }) > -1) {
              this.responseDataGroupPrivilege.splice(this.responseDataGroupPrivilege.findIndex(function checkPrivilege (d) { return d.seq === typeSeq }), 1)
              this.responseDataGroupPrivilege.splice(this.responseDataGroupPrivilege.findIndex(function checkPrivilege (d) { return d.seq === typeDividerSeq }), 1)
            }
          }
        }
      } else if (type === 'array') {
        const typeList = []
        // let typeSeqList = []
        const seqList = []
        this.responseDataGroupPrivilege.forEach((a) => {
          if (a.level === 2 && (this.responseDataGroupPrivilegeClick.findIndex(function checkPrivilege (g) { return g === a.seq }) > -1)) {
            if (this.responseDataAvailablePrivilege.findIndex(function checkPrivilege (g) { return g.seq === a.seq }) === -1) {
              this.responseDataAvailablePrivilege.push(a)
            }
            typeList.push(a.type)
            seqList.push(a.seq)
            this.responseDataGroupPrivilege.forEach((aa) => {
              if (aa.level === 1 && aa.text === a.type) {
                if (this.responseDataAvailablePrivilege.findIndex(function checkPrivilege (g) { return g.seq === aa.seq }) === -1) {
                  this.responseDataAvailablePrivilege.push(aa)
                  // typeSeqList.push(aa.seq)
                }
              }
              if (aa.divider && aa.type === a.type) {
                if (this.responseDataAvailablePrivilege.findIndex(function checkPrivilege (g) { return g.seq === aa.seq }) === -1) {
                  this.responseDataAvailablePrivilege.push(aa)
                  // typeSeqList.push(aa.seq)
                }
              }
            })
            // this.responseDataGroupPrivilege.splice(this.responseDataGroupPrivilege.findIndex(function checkPrivilege (g) { return g.seq === a.seq }), 1)
            // this.responseDataGroupPrivilegeClick.splice(this.responseDataGroupPrivilegeClick.findIndex(function checkPrivilege (g) { return g === a.seq }), 1)
          }
        })
        seqList.forEach((c) => {
          if (this.responseDataGroupPrivilege.findIndex(function checkPrivilege (g) { return g.seq === c }) > -1) {
            this.responseDataGroupPrivilege.splice(this.responseDataGroupPrivilege.findIndex(function checkPrivilege (g) { return g.seq === c }), 1)
          }
        })
        typeList.forEach((c) => {
          let typeQty = 0
          this.responseDataGroupPrivilege.forEach((a) => {
            if (a.level === 2 && a.type === c) {
              typeQty++
            }
          })
          if (typeQty === 0) {
            // typeSeqList.forEach((s) => {
            //   if (this.responseDataGroupPrivilege.findIndex(function checkPrivilege (d) { console.log(d.seq + '-' + s); return d.seq === s }) > -1) {
            //     this.responseDataGroupPrivilege.splice(this.responseDataGroupPrivilege.findIndex(function checkPrivilege (d) { console.log(d.seq + '-' + s); return d.seq === s }), 1)
            //   }
            // })
            if (this.responseDataGroupPrivilege.findIndex(function checkPrivilege (d) { return d.type === c }) > -1) {
              this.responseDataGroupPrivilege.splice(this.responseDataGroupPrivilege.findIndex(function checkPrivilege (d) { return d.type === c }), 1)
              this.responseDataGroupPrivilege.splice(this.responseDataGroupPrivilege.findIndex(function checkPrivilege (d) { return d.type === c }), 1)
            }
          }
        })
      }
      this.responseDataAvailablePrivilegeClick = []
      this.responseDataGroupPrivilegeClick = []
      this.responseDataAvailablePrivilege.sort(function (a, b) {
        return a.seq - b.seq
      })
      this.responseDataGroupPrivilege.sort(function (a, b) {
        return a.seq - b.seq
      })
    },
    doSaveGroupEdit () {
      if (this.groupNameEdit.length > 0) {
        this.processingView = true
        const groupPrivilegePkList = []
        this.responseDataGroupPrivilege.forEach((g) => {
          if (g.pk) {
            groupPrivilegePkList.push(g.pk)
          }
        })
        const groupUserPkList = []
        this.responseDataGroupUser.forEach((g) => {
          groupUserPkList.push(g.value)
        })
        const body = {
          pk: this.editGroupViewPk,
          name: this.groupNameEdit,
          menuPkList: groupPrivilegePkList,
          userPkList: groupUserPkList
        }
        this.$http.put('/api/system/group', body).then(res => res.data)
          .then(res => {
            this.processingView = false
            if (res.status === 'success') {
              this.snackbar = true
              this.snackbarColor = 'green'
              this.snackbarMsg = '群組編輯成功'
              this.doGroupEdit('edit', this.editGroupViewPk, this.editGroupViewName)
            } else { // failure
              this.snackbar = true
              this.snackbarColor = 'red'
              this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
            }
          }).catch(err => {
            this.processingView = false
            this.snackbar = true
            this.snackbarColor = 'black'
            this.snackbarMsg = err
          })
      } else {
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '群組名稱不得為空'
      }
    },
    doAddGroup () {
      if (this.groupNameEdit.length > 0) {
        this.processingView = true
        const groupPrivilegePkList = []
        this.responseDataGroupPrivilege.forEach((g) => {
          if (g.pk) {
            groupPrivilegePkList.push(g.pk)
          }
        })
        const groupUserPkList = []
        this.responseDataGroupUser.forEach((g) => {
          groupUserPkList.push(g.value)
        })
        const body = {
          pk: this.editGroupViewPk,
          name: this.groupNameEdit,
          menuPkList: groupPrivilegePkList,
          userPkList: groupUserPkList
        }
        this.$http.post('/api/system/group', body).then(res => res.data)
          .then(res => {
            this.processingView = false
            if (res.status === 'success') {
              this.snackbar = true
              this.snackbarColor = 'green'
              this.snackbarMsg = '群組新增成功'
              this.action = ''
              this.doSubmit()
            } else { // failure
              this.snackbar = true
              this.snackbarColor = 'red'
              this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
            }
          }).catch(err => {
            this.processingView = false
            this.snackbar = true
            this.snackbarColor = 'black'
            this.snackbarMsg = err
          })
      } else {
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '群組名稱不得為空'
      }
    },
    doDeleteGroup () {
      if (confirm('是否確認刪除群組' + this.editGroupViewName + '?')) {
        this.processingView = true
        this.$http.delete('/api/system/group/' + this.editGroupViewPk).then(res => res.data)
          .then(res => {
            this.processingView = false
            if (res.status === 'success') {
              this.snackbar = true
              this.snackbarColor = 'green'
              this.snackbarMsg = '群組刪除成功'
              this.action = ''
              this.doSubmit()
            } else { // failure
              this.snackbar = true
              this.snackbarColor = 'red'
              this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
            }
          }).catch(err => {
            this.processingView = false
            this.snackbar = true
            this.snackbarColor = 'black'
            this.snackbarMsg = err
          })
      }
    },
    doSubmit () {
      this.loadingView = true
      const body = {
        name: this.summaryGroupName,
        menuPk: this.summaryAuthority === 'All' ? '' : this.summaryAuthority
      }
      this.$http.post('/api/system/queryGroup', body).then(res => res.data)
        .then(res => {
          this.loadingView = false
          if (res.status === 'success') {
            this.pagination.rowsPerPage = res.data.length
            this.responseDataTable = res.data
          }
        })
    }
  }
}
</script>
<style>
</style>
