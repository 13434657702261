<template>
  <v-autocomplete
    v-model="currentWeek"
    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
    :items="weekList"
    :label="labelText"
    class="v-text-field__slot"
    persistent-hint
    outlined
  >
    <template v-slot:item="data">
      <div
        :class="{'weeks-list-data-tip':contentweeksList.indexOf(data.item.value)!==-1}"
        class="v-list__tile__content">
        <div class="v-list__tile__title">
          {{ data.item.text }}
        </div>
      </div>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'WeekPicker',
  props: {
    labelText: {
      type: String,
      required: false,
      default: '週次'
    },
    listStartDate: {
      type: String,
      required: false,
      default: '2018-04-01'
    },
    contentDateList: {
      type: Array,
      required: false,
      default: () => []
    },
    valueType: {
      type: String,
      required: false,
      default: 'date'
    },
    selectWeek: {
      type: [String, Number],
      required: false,
      default: null
    }
  },
  data () {
    return {
      currentWeek: null
      // weekList: []
    }
  },
  computed: {
    ...mapState(['theme']),
    weekList () {
      const startDate = this.$moment(this.listStartDate)
      const endDate = this.$moment(new Date()).add(3, 'day')
      let array = []
      while (startDate.isSameOrBefore(endDate)) {
        const year = startDate.year()
        const weekNumber = startDate.isoWeek()
        const weekStart = startDate.clone().startOf('isoWeek').format('MM-DD')
        const weekEnd = startDate.clone().endOf('isoWeek').format('MM-DD')
        let val = null
        switch (this.valueType) {
          case 'sDay': {
            val = startDate.clone().startOf('isoWeek').format('YYYY-MM-DD')
            break
          }
          case 'eDay': {
            val = startDate.clone().endOf('isoWeek').format('YYYY-MM-DD')
            break
          }
          case 'week': {
            val = year * 100 + weekNumber
            break
          }
          default: {
            val = startDate.clone().startOf('isoWeek').format('YYYY-MM-DD')
            break
          }
        }
        // this.weekList.push(`W${weekNumber.toString().padStart(2, '0')} (${weekStart} ~ ${weekEnd})`);
        array.push({ text: year + '　w' + weekNumber + '　( ' + weekStart + ' ～ ' + weekEnd + ' )', value: val })
        startDate.add(1, 'week')
      }
      return array.reverse()
    },
    contentweeksList () {
      switch (this.valueType) {
        case 'sDay': {
          return this.contentDateList.map(date => this.$moment(date).clone().startOf('isoWeek').format('YYYY-MM-DD'))
        }
        case 'eDay': {
          return this.contentDateList.map(date => this.$moment(date).clone().endOf('isoWeek').format('YYYY-MM-DD'))
        }
        case 'week': {
          return this.contentDateList.map(date => this.$moment(date).isoWeek())
        }
        default: {
          return this.contentDateList.map(date => this.$moment(date).clone().startOf('isoWeek').format('YYYY-MM-DD'))
        }
      }
    }
  },
  watch: {
    listStartDate (val) {
      // this.generateWeekList()
    },
    currentWeek (val) {
      this.$emit('selected-week', val)
    },
    selectWeek (val) {
      // this.currentWeek = val
      this.selectWeekToDate()
    }
  },
  mounted () {
    // this.generateWeekList()
    // this.currentWeek = this.weekList[0].value
    this.selectWeekToDate()
  },
  methods: {
    selectWeekToDate () {
      switch (this.valueType) {
        case 'sDay': {
          this.currentWeek = this.$moment(this.selectWeek).clone().startOf('isoWeek').format('YYYY-MM-DD')
          break
        }
        case 'eDay': {
          this.currentWeek = this.$moment(this.selectWeek).clone().endOf('isoWeek').format('YYYY-MM-DD')
          break
        }
        case 'week': {
          this.currentWeek = this.$moment(this.selectWeek).isoWeek()
          break
        }
        default: {
          this.currentWeek = this.$moment(this.selectWeek).clone().startOf('isoWeek').format('YYYY-MM-DD')
        }
      }
    }
    // generateWeekList () {
    //   const startDate = this.$moment(this.listStartDate)
    //   const endDate = this.$moment(new Date())
    //   this.weekList = []
    //   while (startDate.isSameOrBefore(endDate)) {
    //     const weekNumber = startDate.isoWeek()
    //     const year = startDate.year()
    //     const weekStart = startDate.clone().startOf('isoWeek').format('MM-DD')
    //     const weekEnd = startDate.clone().endOf('isoWeek').format('MM-DD')
    //     let val = null
    //     switch (this.valueType) {
    //       case 'sDay': {
    //         val = startDate.clone().startOf('isoWeek').format('YYYY-MM-DD')
    //         break
    //       }
    //       case 'eDay': {
    //         val = startDate.clone().endOf('isoWeek').format('YYYY-MM-DD')
    //         break
    //       }
    //       case 'week': {
    //         val = year * 100 + weekNumber
    //         break
    //       }
    //       default: {
    //         val = startDate.clone().startOf('isoWeek').format('YYYY-MM-DD')
    //         break
    //       }
    //     }
    //     // this.weekList.push(`W${weekNumber.toString().padStart(2, '0')} (${weekStart} ~ ${weekEnd})`);
    //     this.weekList.push({ text: year + ' w' + weekNumber + ' ( ' + weekStart + ' ～ ' + weekEnd + ' )', value: val })
    //     startDate.add(1, 'week')
    //   }
    //   this.weekList.reverse()
    // }
  }
}
</script>
