<template>
  <v-container
    fill-height
    fluid
    grid-list-xl
  >
    <core-filter />
    <v-layout wrap>
      <v-flex
        xs12
      >
        <material-card
          :class="{'card-shrink-active' : shrink_1}"
          class="card-shrink"
          color="general"
          title="Thermal Data Analyzer"
        >
          <v-icon
            :class="{'card-shrink-active-icon' : shrink_1}"
            @click="shrink1()"
          >mdi-apple-keyboard-control</v-icon>
          <v-alert
            v-model="no_data"
            class="mt-3 mb-3"
            color="text-center error"
            icon="mdi-alert-circle-outline"
            outline
            dismissible
          >
            <strong class="h4">--- {{ errorMessages }} ---</strong>
          </v-alert>
          <v-form>
            <v-container
              py-0
              class="mw-100">
              <v-layout wrap>
                <v-flex
                  xs12
                  md6
                >
                  <v-text-field
                    label="Company (disabled)"
                    hint="Pick your favorite states"
                    persistent-hint
                    disabled/>
                </v-flex>
                <v-flex
                  xs12
                  md6
                >
                  <v-autocomplete
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="[
                      { text: 'Florida', value: 'FL' },
                      { text: 'Georgia', value: 'GA' },
                      { text: 'Nebraska', value: 'NE' },
                      { text: 'California', value: 'CA' },
                      { text: 'New York', value: 'NY' }
                    ]"
                    label="Outlined style"
                    class="v-text-field__slot"
                    hint="Pick your favorite states"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex
                  xs12
                  sm6>
                  <v-menu
                    v-model="menu1"
                    :close-on-content-click="false"
                    :nudge-left="40"
                    lazy
                    transition="scale-transition"
                    max-width="290px"
                    offset-y
                    full-width
                  >
                    <v-text-field
                      slot="activator"
                      :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                      v-model="d1"
                      label="Picker in menu"
                      prepend-icon="event"
                    />
                    <v-date-picker
                      v-model="d1"
                      :max="new Date() | date-format"
                      no-title
                      scrollable
                      actions/>
                  </v-menu>
                </v-flex>
                <v-flex
                  xs12
                  sm6>
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-left="40"
                    lazy
                    transition="scale-transition"
                    max-width="290px"
                    offset-y
                    full-width
                  >
                    <v-text-field
                      slot="activator"
                      :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                      v-model="d2"
                      label="Picker in menu"
                      prepend-icon="event"
                    />
                    <v-date-picker
                      v-model="d2"
                      :min="d1"
                      :max="new Date() | date-format"
                      no-title
                      scrollable
                      actions/>
                  </v-menu>
                </v-flex>
                <v-flex
                  xs12
                  md12
                >
                  <v-text-field
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    label="Email Address"/>
                </v-flex>
                <v-flex
                  xs12
                  md12
                >
                  <v-text-field
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    label="Adress"/>
                </v-flex>
                <v-flex
                  xs12
                  md4>
                  <v-text-field
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    label="City"/>
                </v-flex>
                <v-flex
                  xs12
                  md4>
                  <v-text-field
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    label="Country"/>
                </v-flex>
                <v-flex
                  xs12
                  md4>
                  <v-text-field
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    label="Postal Code"
                    type="number"/>
                </v-flex>
                <v-flex xs12>
                  <v-textarea
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    label="About Me"
                    value="Lorem ipsum dolor sit amet, consectetur adipiscing elit(Thats 'Hire me' in english)."
                  />
                </v-flex>
                <v-flex
                  xs12
                  text-xs-right
                >
                  <v-btn
                    class="mx-0 font-weight-light"
                    color="general"
                    @click="doSubmit()"
                  >
                    <v-icon class="mr-2">mdi-backup-restore</v-icon>查詢
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
      </v-flex>
      <v-flex
        class="demarcation mb-0"
        xs12
      >
        <span>Yield Rate Table</span>
        <hr>
        <v-icon
          :class="{'area-shrink-active-icon' : shrink_2}"
          @click="shrink2()"
        >mdi-apple-keyboard-control</v-icon>
      </v-flex>
      <v-flex
        :class="{'area-shrink-active' : shrink_2}"
        class="area-shrink"
        xs12
        md3
        pt-4
      >
        <v-flex
          xs12
        >
          <material-stats-card
            color="red"
            icon="mdi-alert"
            title="Test Fail Rate"
            value="0.3%"
          />
        </v-flex>
        <v-flex
          xs12
        >
          <material-stats-card
            color="blue"
            icon="mdi-animation"
            title="Total Test Inputs"
            value="287"
            small-value="sets"
          />
        </v-flex>
      </v-flex>
      <v-flex
        :class="{'area-shrink-active' : shrink_2}"
        class="area-shrink"
        xs12
        md9
      >
        <material-card
          color="orange"
          title="Yield Rate Table"
        >
          <v-checkbox
            v-model="field"
            class="mt-1 flex xs6 d-inline-block"
            label="CPK"
            value="CPK"/>
          <v-checkbox
            v-model="field"
            class="mt-1 flex xs6 d-inline-block"
            label="Detail"
            value="Detail"/>
          <v-checkbox
            v-model="field"
            class="mt-1 flex xs6 d-inline-block"
            label="Yield Rate"
            value="Yield Rate"/>
          <v-checkbox
            v-model="field"
            class="mt-1 flex xs6 d-inline-block"
            label="Thermal statement"
            value="Thermal statement"/>
          <v-btn
            color="general"
            dark
            class="mb-2 right"
          ><v-icon class="mr-2">mdi-arrow-collapse-down</v-icon>export</v-btn>
        </material-card>
        <material-card>
          <v-select
            v-model="pagination.rowsPerPage"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="rowsAmount"
            class="v-text-field__slot d-inline-block float-right ml-5"
            style="width: 90px;"
            persistent-hint
            outlined
          />
          <v-text-field
            v-model="search"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            append-icon="search"
            label="Search"
            single-line
            hide-details/>
          <v-data-table
            :headers="headers"
            :items="items"
            :search="search"
            :rows-per-page-items ="rowsAmount"
            :pagination.sync="pagination"
            class="freeze-1 mt-3 elevation-1"
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="subheading font-weight-light text-general text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <td>{{ item.name }}</td>
              <td>{{ item.country }}</td>
              <td>{{ item.city }}</td>
              <td><v-chip
                :class="{'red' : item.num>10}"
                small
                class="white--text">{{ item.num }}</v-chip></td>
              <td class="text-xs-right">{{ item.salary1 }}</td>
              <td class="text-xs-right">{{ item.salary2 }}</td>
              <td class="text-xs-right">{{ item.salary3 }}</td>
            </template>
          </v-data-table>
          <!-- <div
            class="d-inline-block pt-3 float-left"
            style="max-width: 55px;">
            <button
              type="button"
              class="v-pagination__navigation">
              <i
                aria-hidden="true"
                class="v-icon mdi mdi-chevron-double-left theme--dark"/></button></div> -->
          <!-- <div
            class="text-xs-center pt-3 float-left"
            style="min-width: calc(100% - 160px);max-width: calc(100% - 110px);"> -->
          <div
            class="text-xs-center pt-2 d-inline-block"
            style="margin-left: 0px;width: calc(100% - 125px);">
            <v-pagination
              v-model="pagination.page"
              :length="pages"/>
          </div>
          <v-autocomplete
            v-model="pagination.page"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="pageslist"
            class="v-text-field__slot d-inline-block float-right mr-2"
            style="width: 90px;"
            persistent-hint
            outlined
          />
          <!-- <div
            class="d-inline-block pt-3 float-left"
            style="max-width: 55px;">
            <button
              type="button"
              class="v-pagination__navigation"
              dark>
              <i
                aria-hidden="true"
                class="v-icon mdi mdi-chevron-double-right theme--dark"/></button></div> -->
        </material-card>
      </v-flex>
      <v-flex
        xs12
      >
        <material-card
          :class="{'card-shrink-active' : shrink_3}"
          class="v-flex card-shrink"
          color="green"
          title="CPK Table"
        >
          <v-icon
            :class="{'card-shrink-active-icon' : shrink_3}"
            @click="shrink3()"
          >mdi-apple-keyboard-control</v-icon>
          <v-flex
            xs12
            md3
          >
            <p class="category grey--text font-weight-light">Amounts</p>
            <v-chip
              class="blue white--text"
              style="font-size: 1.5rem;font-weight: bolder!important;">
              29888888
            </v-chip>the displayed test items
          </v-flex>
          <v-flex
            xs12
            md3
          >
            <p class="category grey--text font-weight-light">Amounts</p>
            <v-chip
              class="blue white--text"
              style="font-size: 1.5rem;font-weight: bolder!important;">
              2768
            </v-chip>the CPK values are greater than 1.3
          </v-flex>
          <v-flex
            xs12
            md3
          >
            <p class="category grey--text font-weight-light">Amounts</p>
            <v-chip
              class="red white--text"
              style="font-size: 1.5rem;font-weight: bolder!important;">
              220
            </v-chip>the CPK valuos aro los than or oqual to 13
          </v-flex>
          <v-flex
            xs12
            md3
          >
            <v-text-field
              :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
              class="v-text-field__slot row-btn-150"
              label="Base value"
              type="number"/>
            <v-btn
              color="general"
              dark
              class="mb-2 right"
            ><v-icon class="mr-2">mdi-backup-restore</v-icon>更新數據</v-btn>
          </v-flex>
        </material-card>
        <material-card
          :class="{'card-shrink-active' : shrink_3}"
          class="card-shrink plus"
        >
          <v-autocomplete
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="[
              { text: 'Florida', value: 'FL' },
              { text: 'Georgia', value: 'GA' },
              { text: 'Nebraska', value: 'NE' },
              { text: 'California', value: 'CA' },
              { text: 'New York', value: 'NY' }
            ]"
            label="Test Item List"
            class="v-text-field__slot row-btn-250"
            multiple
            persistent-hint
            outlined
          />
          <v-btn
            color="general"
            dark
            class="mb-2 right"
          >更多</v-btn>
          <v-btn
            color="general"
            dark
            class="mb-2 right"
          ><v-icon class="mr-2">mdi-backup-restore</v-icon>更新CPK顯示</v-btn>
          <v-text-field
            v-model="searchCPK"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            append-icon="search"
            label="Search"
            single-line
            hide-details/>
          <v-data-table
            :headers="headersCPK"
            :items="itemsCPK"
            :search="searchCPK"
            :rows-per-page-items ="rowsAmount"
            class="mt-3"
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="subheading font-weight-light text-general text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <td>{{ item.testItem }}</td>
              <td>{{ item.cpk }}</td>
            </template>
          </v-data-table>
        </material-card>
      </v-flex>
      <v-flex
        class="demarcation mb-0"
        xs12
      >
        <span>Test chart</span>
        <hr>
        <v-icon
          :class="{'area-shrink-active-icon' : shrink_4}"
          @click="shrink4()"
        >mdi-apple-keyboard-control</v-icon>
      </v-flex>
      <v-flex
        :class="{'area-shrink-active' : shrink_4}"
        class="v-flex__first"
        lg12
        xl6
      >
        <material-card
          class="v-flex v-c3-chart"
          color="green"
        >
          <div class="w-100 chart-btn c3-chart">
            <material-notification
              :color="theme==='dark' ? 'primary' : 'primary'"
              class="mb-3 h4 font-weight-bold"
            >
              測試項目直方圖
            </material-notification>
            <vue-c3 :handler="handler_bar"/>
          </div>
          <div>
            <material-notification
              :color="theme==='dark' ? 'primary' : 'primary'"
              class="mb-3 h4 font-weight-bold"
            >
              <v-card-title
                primary-title
                class="mt-0 pt-0 pb-0 d-block text-center">
                <v-icon class="h1">mdi-av-timer</v-icon>
                <div class="headline m-auto">Temperature</div>
              </v-card-title>
              <v-card-actions class="d-block text-center">
                <hr class="mb-3 v-divider border-success">
                <v-btn class="black text-light">VIEW</v-btn>
              </v-card-actions>
            </material-notification>
            <br>
            <material-notification
              :color="theme==='dark' ? 'primary' : 'primary'"
              class="mb-3 h4 font-weight-bold"
            >
              <v-card-title
                primary-title
                class="mt-0 pt-0 pb-0 d-block text-center">
                <v-icon class="h1">mdi-auto-fix</v-icon>
                <div class="headline m-auto">Power</div>
              </v-card-title>
              <v-card-actions class="d-block text-center">
                <hr class="mb-3 v-divider border-info">
                <v-btn class="black text-light">VIEW</v-btn>
              </v-card-actions>
            </material-notification>
          </div>
        </material-card>
      </v-flex>
      <v-flex
        :class="{'area-shrink-active' : shrink_4}"
        class="v-flex__first"
        lg12
        xl6
      >
        <material-card
          class="v-flex v-c3-chart"
          color="green"
          title=""
        >
          <div class="w-100 c3-chart">
            <material-notification
              :color="theme==='dark' ? 'primary' : 'primary'"
              class="mb-3 h4 font-weight-bold"
            >
              測試項目折線圖
            </material-notification>
            <vue-c3 :handler="handler_line"/>
          </div>
        </material-card>
      </v-flex>
      <v-flex
        :class="{'area-shrink-active' : shrink_4}"
        class="v-flex__first"
        lg12
      >
        <material-card
          class="v-flex v-c3-chart"
          color="green"
        >
          <div class="w-100 c3-chart">
            <material-notification
              :color="theme==='dark' ? 'primary' : 'primary'"
              class="mb-3 h4 font-weight-bold"
            >
              測試項目統計圖
            </material-notification>
            <vue-c3 :handler="handler_mix"/>
          </div>
        </material-card>
      </v-flex>
      <v-flex
        :class="{'area-shrink-active' : shrink_4}"
        class="v-flex__first"
        lg12
      >
        <material-card class="v-flex v-c3-chart">
          <div class="w-100 chart-btn c3-chart">
            <material-notification
              :color="theme==='dark' ? 'primary' : 'primary'"
              class="mb-3 h4 font-weight-bold"
            >
              測試項目圓餅圖
            </material-notification>
            <vue-c3 :handler="handler_pie"/>
          </div>
          <div>
            <material-notification
              :color="theme==='dark' ? 'primary' : 'primary'"
              class="mb-3 h4 font-weight-bold"
            >
              <v-card-title
                primary-title
                class="mt-0 pt-0 pb-0 d-block text-center">
                <v-icon class="h1">mdi-av-timer</v-icon>
                <div class="headline m-auto">Temperature</div>
              </v-card-title>
              <v-card-actions class="d-block text-center">
                <hr class="mb-3 v-divider border-success">
                <v-btn class="black text-light">VIEW</v-btn>
              </v-card-actions>
            </material-notification>
            <br>
            <material-notification
              :color="theme==='dark' ? 'primary' : 'primary'"
              class="mb-3 h4 font-weight-bold"
            >
              <v-card-title
                primary-title
                class="mt-0 pt-0 pb-0 d-block text-center">
                <v-icon class="h1">mdi-auto-fix</v-icon>
                <div class="headline m-auto">Power</div>
              </v-card-title>
              <v-card-actions class="d-block text-center">
                <hr class="mb-3 v-divider border-info">
                <v-btn class="black text-light">VIEW</v-btn>
              </v-card-actions>
            </material-notification>
          </div>
        </material-card>
      </v-flex>
    </v-layout>
    <v-snackbar
      v-model="snackbar"
      :top="true"
      :timeout="3000"
      color="red">
      {{ error_info }}
      <!-- <v-btn
        flat
      >Close</v-btn> -->
    </v-snackbar>
  </v-container>
</template>

<script>
import Vue from 'vue'
import VueC3 from 'vue-c3'
import 'c3/c3.min.css'
import { mapState } from 'vuex'

export default {
  name: 'Search',
  components: {
    VueC3
  },
  props: {
    // test123: {
    //   type: Function
    // }
  },
  data () {
    return {
      error_info: [],
      no_data: false,
      shrink_1: false,
      shrink_2: false,
      shrink_3: false,
      shrink_4: false,
      handler_bar: new Vue(),
      handler_line: new Vue(),
      handler_mix: new Vue(),
      handler_pie: new Vue(),
      errorMessages: '查無資料',
      snackbar: false,
      search: '',
      searchCPK: '',
      // rowsAmount: [1, 20, 25, { 'text': '$vuetify.dataIterator.rowsPerPageAll', 'value': -1 }],
      rowsAmount: [10, 20, 25, 50, 100],
      pagination: {
        sortBy: 'name', // The field that you're sorting by
        descending: true
      },
      field: [],
      d1: null,
      d2: null,
      today: this.$moment(new Date()).format('YYYY-MM-DD'),
      menu1: false,
      menu2: false,
      modal: false,
      headers: [
        {
          sortable: true,
          text: 'Name',
          value: 'name'
        },
        {
          sortable: true,
          text: 'Country',
          value: 'country'
        },
        {
          sortable: true,
          text: 'City',
          value: 'city'
        },
        {
          sortable: true,
          text: 'Num',
          value: 'Num'
        },
        {
          sortable: true,
          text: 'Salary1',
          value: 'salary1',
          align: 'right'
        },
        {
          sortable: false,
          text: 'Salary2',
          value: 'salary2',
          align: 'right'
        },
        {
          sortable: false,
          text: 'Salary3',
          value: 'salary3',
          align: 'right'
        }
      ],
      items: [
        {
          name: 'Dakota Rice',
          country: 'Niger',
          city: 'Oud-Tunrhout',
          num: '17',
          salary1: '$35,738',
          salary2: '$35,738',
          salary3: '$35,738'
        },
        {
          name: 'Minerva Hooper',
          country: 'Curaçao',
          city: 'Sinaai-Waas',
          num: '28',
          salary1: '$23,738',
          salary2: '$23,738',
          salary3: '$23,738'
        }, {
          name: 'Sage Rodriguez',
          country: 'Netherlands',
          city: 'Overland Park',
          num: '6',
          salary1: '$56,142',
          salary2: '$56,142',
          salary3: '$56,142'
        }, {
          name: 'Philip Chanley',
          country: 'Korea, South',
          city: 'Gloucester',
          num: '11',
          salary1: '$38,735',
          salary2: '$38,735',
          salary3: '$38,735'
        }, {
          name: 'Doris Greene',
          country: 'Malawi',
          city: 'Feldkirchen in Kārnten',
          num: '7',
          salary1: '$63,542',
          salary2: '$63,542',
          salary3: '$63,542'
        }, {
          name: 'Mason Porter',
          country: 'Chile',
          city: 'Gloucester',
          num: '31',
          salary1: '$78,615',
          salary2: '$78,615',
          salary3: '$78,615'
        },
        {
          name: 'Dakota Rice',
          country: 'Niger',
          city: 'Oud-Tunrhout',
          num: '17',
          salary1: '$35,738',
          salary2: '$35,738',
          salary3: '$35,738'
        },
        {
          name: 'Minerva Hooper',
          country: 'Curaçao',
          city: 'Sinaai-Waas',
          num: '28',
          salary1: '$23,738',
          salary2: '$23,738',
          salary3: '$23,738'
        }, {
          name: 'Sage Rodriguez',
          country: 'Netherlands',
          city: 'Overland Park',
          num: '6',
          salary1: '$56,142',
          salary2: '$56,142',
          salary3: '$56,142'
        }, {
          name: 'Philip Chanley',
          country: 'Korea, South',
          city: 'Gloucester',
          num: '11',
          salary1: '$38,735',
          salary2: '$38,735',
          salary3: '$38,735'
        }, {
          name: 'Doris Greene',
          country: 'Malawi',
          city: 'Feldkirchen in Kārnten',
          num: '7',
          salary1: '$63,542',
          salary2: '$63,542',
          salary3: '$63,542'
        }, {
          name: 'Mason Porter',
          country: 'Chile',
          city: 'Gloucester',
          num: '31',
          salary1: '$78,615',
          salary2: '$78,615',
          salary3: '$78,615'
        },
        {
          name: 'Dakota Rice',
          country: 'Niger',
          city: 'Oud-Tunrhout',
          num: '17',
          salary1: '$35,738',
          salary2: '$35,738',
          salary3: '$35,738'
        },
        {
          name: 'Minerva Hooper',
          country: 'Curaçao',
          city: 'Sinaai-Waas',
          num: '28',
          salary1: '$23,738',
          salary2: '$23,738',
          salary3: '$23,738'
        }, {
          name: 'Sage Rodriguez',
          country: 'Netherlands',
          city: 'Overland Park',
          num: '6',
          salary1: '$56,142',
          salary2: '$56,142',
          salary3: '$56,142'
        }, {
          name: 'Philip Chanley',
          country: 'Korea, South',
          city: 'Gloucester',
          num: '11',
          salary1: '$38,735',
          salary2: '$38,735',
          salary3: '$38,735'
        }, {
          name: 'Doris Greene',
          country: 'Malawi',
          city: 'Feldkirchen in Kārnten',
          num: '7',
          salary1: '$63,542',
          salary2: '$63,542',
          salary3: '$63,542'
        }, {
          name: 'Mason Porter',
          country: 'Chile',
          city: 'Gloucester',
          num: '31',
          salary1: '$78,615',
          salary2: '$78,615',
          salary3: '$78,615'
        },
        {
          name: 'Dakota Rice',
          country: 'Niger',
          city: 'Oud-Tunrhout',
          num: '17',
          salary1: '$35,738',
          salary2: '$35,738',
          salary3: '$35,738'
        },
        {
          name: 'Minerva Hooper',
          country: 'Curaçao',
          city: 'Sinaai-Waas',
          num: '28',
          salary1: '$23,738',
          salary2: '$23,738',
          salary3: '$23,738'
        }, {
          name: 'Sage Rodriguez',
          country: 'Netherlands',
          city: 'Overland Park',
          num: '6',
          salary1: '$56,142',
          salary2: '$56,142',
          salary3: '$56,142'
        }, {
          name: 'Philip Chanley',
          country: 'Korea, South',
          city: 'Gloucester',
          num: '11',
          salary1: '$38,735',
          salary2: '$38,735',
          salary3: '$38,735'
        }, {
          name: 'Doris Greene',
          country: 'Malawi',
          city: 'Feldkirchen in Kārnten',
          num: '7',
          salary1: '$63,542',
          salary2: '$63,542',
          salary3: '$63,542'
        }, {
          name: 'Mason Porter',
          country: 'Chile',
          city: 'Gloucester',
          num: '31',
          salary1: '$78,615',
          salary2: '$78,615',
          salary3: '$78,615'
        },
        {
          name: 'Dakota Rice',
          country: 'Niger',
          city: 'Oud-Tunrhout',
          num: '17',
          salary1: '$35,738',
          salary2: '$35,738',
          salary3: '$35,738'
        },
        {
          name: 'Minerva Hooper',
          country: 'Curaçao',
          city: 'Sinaai-Waas',
          num: '28',
          salary1: '$23,738',
          salary2: '$23,738',
          salary3: '$23,738'
        }, {
          name: 'Sage Rodriguez',
          country: 'Netherlands',
          city: 'Overland Park',
          num: '6',
          salary1: '$56,142',
          salary2: '$56,142',
          salary3: '$56,142'
        }, {
          name: 'Philip Chanley',
          country: 'Korea, South',
          city: 'Gloucester',
          num: '11',
          salary1: '$38,735',
          salary2: '$38,735',
          salary3: '$38,735'
        }, {
          name: 'Doris Greene',
          country: 'Malawi',
          city: 'Feldkirchen in Kārnten',
          num: '7',
          salary1: '$63,542',
          salary2: '$63,542',
          salary3: '$63,542'
        }, {
          name: 'Mason Porter',
          country: 'Chile',
          city: 'Gloucester',
          num: '31',
          salary1: '$78,615',
          salary2: '$78,615',
          salary3: '$78,615'
        },
        {
          name: 'Dakota Rice',
          country: 'Niger',
          city: 'Oud-Tunrhout',
          num: '17',
          salary1: '$35,738',
          salary2: '$35,738',
          salary3: '$35,738'
        },
        {
          name: 'Minerva Hooper',
          country: 'Curaçao',
          city: 'Sinaai-Waas',
          num: '28',
          salary1: '$23,738',
          salary2: '$23,738',
          salary3: '$23,738'
        }, {
          name: 'Sage Rodriguez',
          country: 'Netherlands',
          city: 'Overland Park',
          num: '6',
          salary1: '$56,142',
          salary2: '$56,142',
          salary3: '$56,142'
        }, {
          name: 'Philip Chanley',
          country: 'Korea, South',
          city: 'Gloucester',
          num: '11',
          salary1: '$38,735',
          salary2: '$38,735',
          salary3: '$38,735'
        }, {
          name: 'Doris Greene',
          country: 'Malawi',
          city: 'Feldkirchen in Kārnten',
          num: '7',
          salary1: '$63,542',
          salary2: '$63,542',
          salary3: '$63,542'
        }, {
          name: 'Mason Porter',
          country: 'Chile',
          city: 'Gloucester',
          num: '31',
          salary1: '$78,615',
          salary2: '$78,615',
          salary3: '$78,615'
        }
      ],
      headersCPK: [
        {
          sortable: true,
          text: 'Test Item',
          value: 'testItem'
        },
        {
          sortable: false,
          text: 'CPK',
          value: 'cpk'
        }
      ],
      itemsCPK: [
        {
          testItem: 'Dakota Rice',
          cpk: '653'
        },
        {
          testItem: 'Sage Rodriguez',
          cpk: '1875'
        }
      ]
    }
  },
  computed: {
    ...mapState('app', ['theme']),
    pages () {
      return this.pagination.rowsPerPage ? Math.ceil(this.items.length / this.pagination.rowsPerPage) : 0
    },
    pageslist () {
      let i = 0
      const list = []
      if (this.pagination.rowsPerPage) {
        for (i = 0; i < this.items.length; i++) {
          list.push(i + 1)
        }
      }
      return list
    },
    options_bar () {
      return {
        data: {
          columns: [
            ['Project A', 0, 20, 10, 40, 15, 35],
            ['Project B', 30, 200, 100, 400, 150, 250]
          ],
          type: 'bar'
        },
        axis: {
          x: {
            label: {
              text: '期',
              position: 'outer-center'
            }
          },
          y: {
            label: {
              text: '億',
              position: 'outer-middle'
            }
          }
        },
        tooltip: {
          format: {
            title (d) {
              return `第 ${d} 期`
            },
            value (value, ratio, id) {
              return `${value} 億`
            }
          }
        },
        grid: {
          x: {
            show: true
          },
          y: {
            show: true
          }
        },
        legend: {
          position: 'bottom',
          padding: 16
        },
        size: {
          // width: 640,
          height: 500
        }
      }
    },
    options_line () {
      return {
        data: {
          columns: [
            ['Project A', 0, 20, 10, 40, 15, 35],
            ['Project B', 30, 200, 100, 400, 150, 250]
          ]
        },
        point: {
          r: 10
        },
        axis: {
          x: {
            label: {
              text: '期',
              position: 'outer-center'
            },
            padding: 0.25
          },
          y: {
            label: {
              text: '億',
              position: 'outer-middle'
            }
          }
        },
        tooltip: {
          format: {
            title (d) {
              return `第 ${d} 期`
            },
            value (value, ratio, id) {
              return `${value} 億`
            }
          }
        },
        grid: {
          x: {
            show: true
          },
          y: {
            show: true
          }
        },
        legend: {
          position: 'right',
          padding: 16
        },
        size: {
          // width: 640,
          height: 500
        }
      }
    },
    options_mix () {
      return {
        data: {
          columns: [
            ['Project A', 120, 160, 210, 140, 185, 135],
            ['Project B', 3, 2, 10, 40, 15, 25]
          ],
          type: 'bar',
          types: {
            'Project B': 'spline'
          },
          axes: {
            'Project A': 'y',
            'Project B': 'y2'
          }
        },
        point: {
          r: 0
        },
        axis: {
          x: {
            label: {
              text: '期',
              position: 'outer-center'
            }
          },
          y: {
            label: {
              text: '億',
              position: 'outer-middle'
            }
          },
          y2: {
            show: true,
            label: {
              text: '億',
              position: 'outer-middle'
            }
          }
        },
        tooltip: {
          format: {
            title (d) {
              return `第 ${d} 期`
            },
            value (value, ratio, id) {
              return `${value} 億`
            }
          }
        },
        grid: {
          x: {
            show: true
          },
          y: {
            show: true
          }
        },
        legend: {
          position: 'right',
          padding: 16
        },
        size: {
          // width: 640,
          height: 500
        }
      }
    },
    options_pie () {
      return {
        data: {
          columns: [
            ['Project A', 50],
            ['Project B', 250]
          ],
          type: 'pie'
        },
        legend: {
          position: 'right',
          padding: 16
        },
        size: {
          // width: 640,
          height: 500
        }
      }
    }
  },
  watch: {
    d1 (val) {
      this.menu1 = false
      if (this.d2 <= this.d1) {
        this.d2 = this.d1
      }
    },
    d2 (val) {
      this.menu2 = false
    }
  },
  mounted () {
    this.handler_bar.$emit('init', this.options_bar)
    this.handler_line.$emit('init', this.options_line)
    this.handler_mix.$emit('init', this.options_mix)
    this.handler_pie.$emit('init', this.options_pie)
  },
  methods: {
    shrink1 () {
      if (this.shrink_1) {
        this.shrink_1 = false
      } else {
        this.shrink_1 = true
      }
    },
    shrink2 () {
      if (this.shrink_2) {
        this.shrink_2 = false
      } else {
        this.shrink_2 = true
      }
    },
    shrink3 () {
      if (this.shrink_3) {
        this.shrink_3 = false
      } else {
        this.shrink_3 = true
      }
    },
    shrink4 () {
      if (this.shrink_4) {
        this.shrink_4 = false
      } else {
        this.shrink_4 = true
      }
    },
    beforeSubmit () {
      let err = []
      if (this.d1 !== '' && this.d1 !== null) {
        if (!/^[0-9]{4}[-][0-9]{2}[-][0-9]{2}/g.test(this.d1) || this.d1.length !== 10) {
          err.push('日期1格式錯誤')
        }
      }
      if (this.d2 !== '' && this.d2 !== null) {
        if (!/^[0-9]{4}[-][0-9]{2}[-][0-9]{2}/g.test(this.d2) || this.d2.length !== 10) {
          err.push('日期2格式錯誤')
        }
      }
      return err
    },
    doSubmit (event) {
      this.error_info = []
      const err = this.beforeSubmit()
      if (err.length > 0) {
      // this.$notify({
      //   group: "post",
      //   title: this.$t('sys.system_notification'),
      //   text: err.join(","),
      //   type: "error"
      // });
      // return;
        this.error_info = err.join(',')
        this.snackbar = true
        return
      }
      this.no_data = true
    }
  }
}
</script>
