<template>
  <v-menu
    :close-on-content-click="false"
    bottom
    left
    min-width="100"
    max-width="100"
    nudge-left="12"
    offset-x
    transition="slide-y-transition"
  >
    <v-btn
      slot="activator"
      :dark="theme==='dark'"
      :light="theme==='light'"
      class="elevation-0"
      color="grey"
      fab
      fixed
      style="top: 100px; left: unset!important; right: 0px;"
      top
    >
      <v-icon>mdi-settings</v-icon>
    </v-btn>
    <v-card >
      <v-container grid-list-xl>
        <v-layout wrap>
          <v-flex xs12>
            <v-btn
              href=""
              target="_blank"
              color="success"
              block
            >
              1
            </v-btn>
          </v-flex>
          <v-flex xs12>
            <v-btn
              href=""
              target="_blank"
              class="white--text"
              color="primary"
              block
            >
              2
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </v-menu>
</template>

<script>
// Utilities
import {
  mapMutations,
  mapState
} from 'vuex'

export default {
  data: () => ({
    colors: [
      'primary',
      'info',
      'success',
      'warning',
      'danger',
      'general'
    ],
    images: [
      'https://demos.creative-tim.com/vue-material-dashboard/img/sidebar-1.23832d31.jpg',
      'https://demos.creative-tim.com/vue-material-dashboard/img/sidebar-2.32103624.jpg',
      'https://demos.creative-tim.com/vue-material-dashboard/img/sidebar-3.3a54f533.jpg',
      'https://demos.creative-tim.com/vue-material-dashboard/img/sidebar-4.3b7e38ed.jpg'
    ]
  }),

  computed: {
    ...mapState('app', ['image', 'color', 'theme']),
    color () {
      return this.$store.state.app.color
    }
  },

  methods: {
    ...mapMutations('app', ['setImage']),
    setColor (color) {
      this.$store.state.app.color = color
    }
  }
}
</script>

<style lang="scss">
  .v-avatar,
  .v-responsive {
    cursor: pointer;
  }
</style>
