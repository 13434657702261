<template>
  <v-container
    system
    fluid
    grid-list-xl
    pt-4
  >
    <v-layout wrap>
      <!-- <v-flex
        xs12
        sm6
        lg2>
        <material-stats-card
          :value="summaryCaseName"
          class="v-offset-shadow v-offset-none"
          style="margin-bottom:-20px !important;"
          color="purple"
          icon="mdi-folder-open"
          title="案名"/>
      </v-flex>
      <v-flex
        xs12
        sm6
        lg2>
        <material-stats-card
          :value="summaryAsusDeviceIsn"
          class="v-offset-none"
          style="margin-bottom:0px !important;"
          color="deep-purple"
          icon="mdi-nut"
          title="ASUS PN"/>
      </v-flex>
      <v-flex
        xs12
        sm12
        lg4>
        <material-stats-card
          :value="summaryDate + (summaryDateRange ? ('~' + summaryDateRange) : '')"
          style="margin-bottom:-20px !important;"
          color="indigo"
          icon="mdi-calendar"
          title="資料日期"/>
      </v-flex>
      <v-flex
        xs12
        sm6
        lg2>
        <material-stats-card
          :value="summaryVendor"
          style="margin-bottom:-20px !important;"
          color="light-blue"
          icon="mdi-factory"
          title="供應商"/>
      </v-flex>
      <v-flex
        xs12
        sm6
        lg2>
        <material-stats-card
          :value="(responseAreaData.failRate * 100).toFixed(1) + '%'"
          :class="responseAreaData.warningColor || 'green'"
          :color="responseAreaData.color"
          :icon="responseAreaData.icon || 'mdi-check'"
          class="title-color"
          style="margin-bottom:-20px !important;"
          title="Fail Rate"/>
      </v-flex> -->
      <v-flex
        class="v-flex__first"
        xs12
      >
        <material-card
          class="v-flex v-c3-chart"
        >
          <div style="width: 300px;">
            <material-notification
              class="mt-1 mb-3 py-1 h4 font-weight-bold"
              color="black"
            >
              <v-card-title class="mt-0 py-0 px-0 d-block text-center">
                <div class="headline m-auto">
                  <h4 class="text-right">
                    <span class="h6 my-1 float-left">不重複使用者</span>
                    <span
                      v-if="!systemUsageInfo.loadingStatus"
                      class="font-weight-bold">{{ systemUsageInfo.nonRepetitiveUsers }}</span>
                    <img
                      v-if="systemUsageInfo.loadingStatus"
                      :src="Loading"
                      style="width: 1.5rem;">
                    <span class="h6 ml-2">人</span>
                  </h4>
                  <hr class="my-1 white">
                  <h4 class="text-right">
                    <span class="h6 my-1 float-left">響應頻率</span>
                    <span
                      v-if="!systemUsageInfo.loadingStatus"
                      class="font-weight-bold">{{ systemUsageInfo.totalRequestQty }}</span>
                    <img
                      v-if="systemUsageInfo.loadingStatus"
                      :src="Loading"
                      style="width: 1.5rem;">
                    <span class="h6 ml-2">次</span>
                  </h4>
                  <hr class="my-1 white">
                  <h4 class="text-right">
                    <span class="h6 my-1 float-left">響應時長</span>
                    <span
                      v-if="!systemUsageInfo.loadingStatus"
                      class="font-weight-bold">{{ systemUsageInfo.avgLatency?(systemUsageInfo.avgLatency).toFixed(2):'-' }}</span>
                    <img
                      v-if="systemUsageInfo.loadingStatus"
                      :src="Loading"
                      style="width: 1.5rem;">
                    <span class="h6 ml-2">秒</span>
                  </h4>
                </div>
              </v-card-title>
            </material-notification>
            <material-notification
              class="p-0 h6 font-weight-bold"
              color="black"
            >
              <table class="v-datatable v-table w-100 theme--dark">
                <thead>
                  <tr>
                    <th
                      colspan="3"
                      class="deep-orange text-white font-weight-bold h4"
                      style="text-align:left!important;">回應時間統計圖</th>
                  </tr>
                  <tr>
                    <th style="text-align:left!important;"><span class="subheading font-weight-light text-general text--darken-3">篩選</span></th>
                    <th style="text-align:left!important;"><span class="subheading font-weight-light text-general text--darken-3">區間(S)</span></th>
                    <th style="text-align:left!important;"><span class="subheading font-weight-light text-general text--darken-3">次數/百分比</span></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(data, d) in latencyBucketTable.responseData"
                    :key="'d'+d"
                    :class="{'selected':latencyBucketTable.filterKeyList.indexOf(data.key)>-1}">
                    <td
                      class="text-center"
                      style="position: relative;">
                      <v-icon
                        v-if="latencyBucketTable.filterKeyList.indexOf(data.key)===-1"
                        :class="{'text-body' : theme==='light'}"
                        class="h4 m-0 cursor"
                        @click="doSelectCheckItem('latencyBucket', data.key)">mdi-checkbox-blank-outline</v-icon>
                      <v-icon
                        v-if="latencyBucketTable.filterKeyList.indexOf(data.key)>-1"
                        :class="{'text-body' : theme==='light'}"
                        class="h4 m-0 cursor"
                        @click="doSelectCheckItem('latencyBucket', data.key)">mdi-checkbox-marked</v-icon>
                    </td>
                    <td>{{ data.label }}</td>
                    <td
                      class="progress table-width-150 align-center;"
                      style="height:45px;">
                      <div
                        v-if="!latencyBucketTable.loadingStatus&&(latencyBucketTable.filterKeyList.length===0||latencyBucketTable.filterKeyList.indexOf(data.key)>-1)"
                        :style="'color:orange;width:'+(doLatencyBucketTableValuePercentage(data.value)*100)+'%'"
                        class="progress-bar my-2 py-1">{{ data.value }} ({{ (doLatencyBucketTableValuePercentage(data.value)*100).toFixed(2) }}%)</div>
                      <span
                        v-if="latencyBucketTable.filterKeyList.length>0&&latencyBucketTable.filterKeyList.indexOf(data.key)===-1"
                        class="my-2 py-1">未選擇</span>
                      <img
                        v-if="latencyBucketTable.loadingStatus&&(latencyBucketTable.filterKeyList.length===0||latencyBucketTable.filterKeyList.indexOf(data.key)>-1)"
                        :src="Loading"
                        class="my-2 py-1"
                        style="width: 1.35rem;">
                    </td>
                  </tr>
                </tbody>
              </table>
            </material-notification>
          </div>
          <div
            class="c3-chart c3-click"
            style="width: calc(100% - 300px);position: relative;">
            <material-notification
              color="brown"
              class="mb-3 h4 font-weight-bold"
            >
              請求頻率統計圖
              <v-btn
                color="red"
                class="black m-0"
                style="position: absolute;bottom: 5px;left: 150px;"
                @click="doResetFilter()">
                清除篩選
              </v-btn>
              <v-btn
                color="lime"
                class="black text-dark m-0"
                style="position: absolute;bottom: 5px;left: 250px;"
                @click="doResetAll()">
                全部重置
              </v-btn>
              <span
                class="h6"
                style="position: absolute;bottom: 3px;right: 740px;">統計日期</span>
              <v-text-field
                :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                v-model="summaryUsageRangeDateFullTitle"
                label=""
                style="width: 320px;position: absolute;bottom: -12px;right: 410px;"
                @click="watchPauseStatus=true;summaryUsageRangeDateSettingView=true"
              />
              <v-btn
                class="black text-light m-0"
                style="position: absolute;bottom: 5px;right: 330px;"
                @click="doSummaryUsageRangeDate(4, 30, true)">
                4小時
              </v-btn>
              <v-btn
                class="black text-light m-0"
                style="position: absolute;bottom: 5px;right: 265px;"
                @click="doSummaryUsageRangeDate(24, 60, true)">
                1天
              </v-btn>
              <v-btn
                class="black text-light m-0"
                style="position: absolute;bottom: 5px;right: 200px;"
                @click="doSummaryUsageRangeDate(168, 720, true)">
                1週
              </v-btn>
              <span
                class="h6"
                style="position: absolute;bottom: 3px;right: 110px;">統計區間</span>
              <v-select
                v-model="summaryUsageRangeInterval"
                :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                :items="summaryUsageRangeIntervalMenu"
                class="v-text-field__slot d-inline-block float-right ml-5"
                style="width: 90px;position: absolute;bottom: -12px;right: 10px;"
                persistent-hint
                outlined
              />
            </material-notification>
            <vue-c3
              :handler="handler_bar"
              :style="statisticRequestChart.loadingStatus?'opacity:0.25;transition: 0.25s;':''"/>
            <div
              v-if="statisticRequestChart.loadingStatus"
              style="position: absolute;top: calc(50% - 2rem);left: 0;width: 100%;"
            >
              <p style="text-align: center!important;width: 50%;margin: auto;">
                <img
                  :src="Loading"
                  style="width: 4rem;">
              </p>
            </div>
          </div>
        </material-card>
      </v-flex>
      <v-flex
        md6
        xs12
      >
        <material-card
          :style="'max-width: calc(100vw - '+(Number(shrink)+32)+'px);'"
          :title="'部門用量統計'+ (displayIntervalState?(' ['+barXaxisName[statisticRequestChart.selectIndex+1]+']'):'')"
          color="deep-orange"
          class="card-mix card-padding pt-0">
          <v-text-field
            v-model="departmentUsageTable.keyWord"
            placeholder="請輸入關鍵字"
            class="mix-list v-text-field__slot d-inline-block float-right ml-5 text-white"
            style="width: 150px;right: 140px;"
            type="text"
            label=""/>
          <v-select
            v-model="departmentUsageTable.pagination.rowsPerPage"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="departmentUsageTable.rowsAmount"
            class="mix-list v-text-field__slot d-inline-block float-right ml-5"
            style="width: 90px;"
            persistent-hint
            outlined
          />
        </material-card>
        <material-card
          :style="'max-width: calc(100vw - '+(Number(shrink)+32)+'px);'"
          class="d-table-t0 d-table-full">
          <v-data-table
            :headers="departmentUsageTable.responseHeaders"
            :items="departmentUsageTable.responseData"
            :pagination.sync="departmentUsageTable.pagination"
            :rows-per-page-items="departmentUsageTable.rowsAmount"
            :search="departmentUsageTable.keyWord"
            multi-sort
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="subheading font-weight-light text-general text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <tr :class="{'selected':departmentUsageTable.filterKeyList.indexOf(item.key)>-1}">
                <td
                  class="text-center"
                  style="position: relative;">
                  <v-icon
                    v-if="departmentUsageTable.filterKeyList.indexOf(item.key)===-1"
                    class="h4 m-0 cursor"
                    @click="doSelectCheckItem('departmentUsage', item.key)">mdi-checkbox-blank-outline</v-icon>
                  <v-icon
                    v-if="departmentUsageTable.filterKeyList.indexOf(item.key)>-1"
                    class="h4 m-0 cursor"
                    @click="doSelectCheckItem('departmentUsage', item.key)">mdi-checkbox-marked</v-icon>
                </td>
                <td>{{ item.label }}</td>
                <td class="text-right">
                  <span v-if="!departmentUsageTable.loadingStatus&&(departmentUsageTable.filterKeyList.length===0||departmentUsageTable.filterKeyList.indexOf(item.key)>-1)">{{ item.totalRequestQty }}</span>
                  <span v-if="departmentUsageTable.filterKeyList.length>0&&departmentUsageTable.filterKeyList.indexOf(item.key)===-1">未選擇</span>
                  <img
                    v-if="departmentUsageTable.loadingStatus&&(departmentUsageTable.filterKeyList.length===0||departmentUsageTable.filterKeyList.indexOf(item.key)>-1)"
                    :src="Loading"
                    style="width: 1rem;">
                </td>
                <td class="text-right">
                  <span v-if="!departmentUsageTable.loadingStatus&&(departmentUsageTable.filterKeyList.length===0||departmentUsageTable.filterKeyList.indexOf(item.key)>-1)">{{ item.avgLatency }}</span>
                  <span v-if="departmentUsageTable.filterKeyList.length>0&&departmentUsageTable.filterKeyList.indexOf(item.key)===-1">未選擇</span>
                  <img
                    v-if="departmentUsageTable.loadingStatus&&(departmentUsageTable.filterKeyList.length===0||departmentUsageTable.filterKeyList.indexOf(item.key)>-1)"
                    :src="Loading"
                    style="width: 1rem;">
                </td>
              </tr>
            </template>
          </v-data-table>
          <div
            v-if="departmentUsageTable.pagination.rowsPerPage != -1"
            class="text-xs-center pt-2 d-inline-block"
            style="margin-left: 0px;width: calc(100% - 140px);">
            <v-pagination
              v-model="departmentUsageTable.pagination.page"
              :length="departmentUsageTable.pages"/>
          </div>
          <v-autocomplete
            v-if="departmentUsageTable.pagination.rowsPerPage != -1"
            v-model="departmentUsageTable.pagination.page"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="departmentUsageTablePageslist"
            class="v-text-field__slot d-inline-block float-right mr-0"
            style="width: 90px;"
            persistent-hint
            outlined
          />
        </material-card>
      </v-flex>
      <v-flex
        md6
        xs12
      >
        <material-card
          :style="'max-width: calc(100vw - '+(Number(shrink)+32)+'px);'"
          :title="'功能用量統計'+ (displayIntervalState?(' ['+barXaxisName[statisticRequestChart.selectIndex+1]+']'):'')"
          color="deep-orange"
          class="card-mix card-padding pt-0">
          <v-text-field
            v-model="serviceUsageTable.keyWord"
            placeholder="請輸入關鍵字"
            class="mix-list v-text-field__slot d-inline-block float-right ml-5 text-white"
            style="width: 150px;right: 140px;"
            type="text"
            label=""/>
          <v-select
            v-model="serviceUsageTable.pagination.rowsPerPage"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="serviceUsageTable.rowsAmount"
            class="mix-list v-text-field__slot d-inline-block float-right ml-5"
            style="width: 90px;"
            persistent-hint
            outlined
          />
        </material-card>
        <material-card
          :style="'max-width: calc(100vw - '+(Number(shrink)+32)+'px);'"
          class="d-table-t0 d-table-full">
          <v-data-table
            :headers="serviceUsageTable.responseHeaders"
            :items="serviceUsageTable.responseData"
            :pagination.sync="serviceUsageTable.pagination"
            :rows-per-page-items="serviceUsageTable.rowsAmount"
            :search="serviceUsageTable.keyWord"
            multi-sort
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="subheading font-weight-light text-general text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <tr :class="{'selected':serviceUsageTable.filterKeyList.indexOf(item.key)>-1}">
                <td
                  class="text-center"
                  style="position: relative;">
                  <v-icon
                    v-if="serviceUsageTable.filterKeyList.indexOf(item.key)===-1"
                    class="h4 m-0 cursor"
                    @click="doSelectCheckItem('serviceUsage', item.key)">mdi-checkbox-blank-outline</v-icon>
                  <v-icon
                    v-if="serviceUsageTable.filterKeyList.indexOf(item.key)>-1"
                    class="h4 m-0 cursor"
                    @click="doSelectCheckItem('serviceUsage', item.key)">mdi-checkbox-marked</v-icon>
                </td>
                <td>{{ item.label }}</td>
                <td class="text-right">
                  <span v-if="!serviceUsageTable.loadingStatus&&(serviceUsageTable.filterKeyList.length===0||serviceUsageTable.filterKeyList.indexOf(item.key)>-1)">{{ item.totalRequestQty }}</span>
                  <span v-if="serviceUsageTable.filterKeyList.length>0&&serviceUsageTable.filterKeyList.indexOf(item.key)===-1">未選擇</span>
                  <img
                    v-if="serviceUsageTable.loadingStatus&&(serviceUsageTable.filterKeyList.length===0||serviceUsageTable.filterKeyList.indexOf(item.key)>-1)"
                    :src="Loading"
                    style="width: 1rem;">
                </td>
                <td class="text-right">
                  <span v-if="!serviceUsageTable.loadingStatus&&(serviceUsageTable.filterKeyList.length===0||serviceUsageTable.filterKeyList.indexOf(item.key)>-1)">{{ item.avgLatency }}</span>
                  <span v-if="serviceUsageTable.filterKeyList.length>0&&serviceUsageTable.filterKeyList.indexOf(item.key)===-1">未選擇</span>
                  <img
                    v-if="serviceUsageTable.loadingStatus&&(serviceUsageTable.filterKeyList.length===0||serviceUsageTable.filterKeyList.indexOf(item.key)>-1)"
                    :src="Loading"
                    style="width: 1rem;">
                </td>
              </tr>
            </template>
          </v-data-table>
          <div
            v-if="serviceUsageTable.pagination.rowsPerPage != -1"
            class="text-xs-center pt-2 d-inline-block"
            style="margin-left: 0px;width: calc(100% - 140px);">
            <v-pagination
              v-model="serviceUsageTable.pagination.page"
              :length="serviceUsageTable.pages"/>
          </div>
          <v-autocomplete
            v-if="serviceUsageTable.pagination.rowsPerPage != -1"
            v-model="serviceUsageTable.pagination.page"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="serviceUsageTablePageslist"
            class="v-text-field__slot d-inline-block float-right mr-0"
            style="width: 90px;"
            persistent-hint
            outlined
          />
        </material-card>
      </v-flex>
      <v-flex
        md12
        xs12
      >
        <material-card
          id="checkTable"
          :style="'max-width: calc(100vw - '+(Number(shrink)+32)+'px);'"
          :title="'詳細請求內容'+ (displayIntervalState?(' ['+barXaxisName[statisticRequestChart.selectIndex+1]+']'):'')"
          color="deep-orange"
          class="card-mix card-padding pt-0">
          <v-select
            v-model="userRawRequestDataTable.perPageRows"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="userRawRequestDataTable.rowsAmount"
            class="mix-list v-text-field__slot d-inline-block float-right ml-5"
            style="width: 90px;"
            persistent-hint
            outlined
          />
        </material-card>
        <material-card
          :style="'max-width: calc(100vw - '+(Number(shrink)+32)+'px);position: relative;'"
          :class="{'opacity-50':userRawRequestDataTable.loadingStatus}"
          class="d-table-t0 d-table-full">
          <v-data-table
            :headers="userRawRequestDataTable.responseHeaders"
            :items="userRawRequestDataTable.responseData"
            :pagination.sync="userRawRequestDataTable.pagination.current"
            :rows-per-page-items="userRawRequestDataTable.rowsAmount"
            multi-sort
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="subheading font-weight-light text-general text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <tr>
                <td clas="table-width-200">{{ item.userName }}</td>
                <td clas="table-width-150">{{ $moment(item.requestTime).format('YYYY-MM-DD HH:mm') }}</td>
                <td clas="w-100">{{ item.reqeustBody }}</td>
                <td clas="table-width-150">{{ item.serviceName }}</td>
                <td class="text-right table-width-200">{{ item.latency }}</td>
              </tr>
            </template>
          </v-data-table>
          <div
            v-if="userRawRequestDataTable.pagination.current.rowsPerPage != -1"
            class="text-xs-center pt-2 d-inline-block"
            style="margin-left: 0px;width: calc(100% - 140px);">
            <v-pagination
              v-model="userRawRequestDataTable.pagination.page"
              :length="userRawRequestDataTable.pages"/>
          </div>
          <v-autocomplete
            v-if="userRawRequestDataTable.pagination.current.rowsPerPage != -1"
            v-model="userRawRequestDataTable.pagination.page"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="userRawRequestDataTablePageslist"
            class="v-text-field__slot d-inline-block float-right mr-0"
            style="width: 90px;"
            persistent-hint
            outlined
          />
          <div
            v-if="userRawRequestDataTable.loadingStatus"
            style="position: absolute;top: calc(50% - 2rem);left: 0;width: 100%;"
          >
            <p style="text-align: center!important;width: 50%;margin: auto;">
              <img
                :src="Loading"
                style="width: 4rem;">
            </p>
          </div>
        </material-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="summaryUsageRangeDateSettingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <v-card
        max-width="85%"
        style="margin:0 auto;">
        <v-card-text>
          <v-alert
            v-model="summaryUsageRangeDateSettingView"
            class="mt-3 mb-3 border-0"
            color="general">
            <strong class="h4">統計日期及區間設定</strong>
          </v-alert>
        </v-card-text>
        <v-layout wrap>
          <v-flex
            style="display: flex;justify-content: flex-end;align-items: center;"
            xs12
            lg1>
            統計日期
          </v-flex>
          <v-flex
            style="display: flex;"
            xs12
            lg3>
            <v-menu
              v-model="summaryUsageRangeSettingStartDateMenu"
              :close-on-content-click="false"
              :nudge-left="40"
              lazy
              transition="scale-transition"
              style="max-width: 170px;margin-left: 10px;"
              max-width="290px"
              offset-y
              full-width
            >
              <v-text-field
                slot="activator"
                :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                v-model="summaryUsageRangeSettingStartDate"
                label=""
                prepend-icon="event"
              />
              <v-date-picker
                v-model="summaryUsageRangeSettingStartDate"
                :min="startdate"
                :max="new Date() | date-format"
                :color="theme==='dark'?'danger':'general'"
                type="date"
                no-title
                scrollable
                actions/>
            </v-menu>
            <v-select
              :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
              v-model="summaryUsageRangeSettingStartTime"
              :items="summaryUsageRangeSettingTimeItem"
              label=""
              prepend-icon="access_time"
            />
            <!-- <v-menu
              v-model="summaryUsageRangeSettingStartTimeMenu"
              :close-on-content-click="false"
              :nudge-left="40"
              lazy
              transition="scale-transition"
              style="max-width: 170px;margin-left: 10px;"
              max-width="290px"
              offset-y
              full-width
            >
              <v-text-field
                slot="activator"
                :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                v-model="summaryUsageRangeSettingStartTime"
                label=""
                prepend-icon="access_time"
              />
              <v-time-picker
                v-model="summaryUsageRangeSettingStartTime"
                type="time"
                format="24hr"
                no-title
                scrollable
                actions/>
            </v-menu> -->
          </v-flex>
          <v-flex
            style="display: flex;justify-content: center;align-items: center;"
            xs12
            lg1>
            到
          </v-flex>
          <v-flex
            style="display: flex;"
            xs12
            lg3>
            <v-menu
              v-model="summaryUsageRangeSettingEndDateMenu"
              :close-on-content-click="false"
              :nudge-left="40"
              lazy
              transition="scale-transition"
              style="max-width: 170px;margin-left: 10px;"
              max-width="290px"
              offset-y
              full-width
            >
              <v-text-field
                slot="activator"
                :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                v-model="summaryUsageRangeSettingEndDate"
                label=""
                prepend-icon="event"
              />
              <v-date-picker
                v-model="summaryUsageRangeSettingEndDate"
                :min="summaryUsageRangeSettingStartDate"
                :max="new Date() | date-format"
                :color="theme==='dark'?'danger':'general'"
                type="date"
                no-title
                scrollable
                actions/>
            </v-menu>
            <v-select
              :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
              v-model="summaryUsageRangeSettingEndTime"
              :items="summaryUsageRangeSettingTimeItem"
              label=""
              prepend-icon="access_time"
            />
            <!-- <v-menu
              v-model="summaryUsageRangeSettingEndTimeMenu"
              :close-on-content-click="false"
              :nudge-left="40"
              lazy
              transition="scale-transition"
              style="max-width: 170px;margin-left: 10px;"
              max-width="290px"
              offset-y
              full-width
            >
              <v-text-field
                slot="activator"
                :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                v-model="summaryUsageRangeSettingEndTime"
                label=""
                prepend-icon="access_time"
              />
              <v-time-picker
                v-model="summaryUsageRangeSettingEndTime"
                type="time"
                format="24hr"
                no-title
                scrollable
                actions/>
            </v-menu> -->
          </v-flex>
          <v-flex
            style="display: flex;justify-content: flex-end;align-items: center;"
            xs12
            lg2>
            統計區間
          </v-flex>
          <v-flex
            xs12
            lg2>
            <v-select
              v-model="summaryUsageRangeSettingInterval"
              :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
              :items="summaryUsageRangeIntervalMenu"
              style="width: 160px;"
              persistent-hint
              outlined
            />
          </v-flex>
        </v-layout>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="red"
            small
            @click="doSummaryUsageRangeSettingClose()">
            <v-icon class="mr-2">mdi-close</v-icon>返回
          </v-btn>
          <v-btn
            color="general"
            small
            @click="doSummaryUsageRangeSetting()">
            <v-icon class="mr-2">mdi-backup-restore</v-icon>設定條件
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <v-dialog
      v-model="processingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        PROCESSING 處理中
      </p>
    </v-dialog> -->
    <!-- <v-dialog
      v-model="loadingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog> -->
    <v-snackbar
      v-model="snackbar"
      :top="true"
      :color="snackbarColor">
      {{ snackbarMsg }}
      <v-btn
        dark
        flat
        @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import qs from 'qs'
import Vue from 'vue'
import VueC3 from 'vue-c3'
import 'c3/c3.min.css'
import { mapState } from 'vuex'
import Loading from '@/assets/img/loading.gif'

export default {
  name: 'ServiceUsageAnalysis',
  components: {
    VueC3
  },
  data () {
    return {
      startdate: '2018-04-01',
      snackbar: false,
      snackbarColor: 'red',
      snackbarMsg: '',
      Loading,
      loadingView: true,
      authorize: false,
      // processingView: false,
      requestId: new Date().getTime(),
      watchPauseStatus: false,
      summaryUsageRangeDateSettingView: false,
      summaryUsageRangeSettingStartDateMenu: false,
      summaryUsageRangeSettingEndDateMenu: false,
      summaryUsageRangeSettingStartTimeMenu: false,
      summaryUsageRangeSettingEndTimeMenu: false,
      summaryUsageRangeSettingStartDate: this.$moment(new Date().getTime() - (3600000 * 24)).format('YYYY-MM-DD'),
      summaryUsageRangeSettingEndDate: this.$moment(new Date().getTime() - (3600000 * 24)).format('YYYY-MM-DD'),
      summaryUsageRangeSettingStartTime: this.$moment(new Date().getTime() - (3600000 * 24)).format('HH:00'),
      summaryUsageRangeSettingEndTime: this.$moment(new Date()).format('HH:00'),
      summaryUsageRangeStartDate: this.$moment(new Date().getTime() - (3600000 * 24)).format('YYYY-MM-DD'),
      summaryUsageRangeEndDate: this.$moment(new Date()).format('YYYY-MM-DD'),
      summaryUsageRangeStartTime: this.$moment(new Date().getTime() - (3600000 * 24)).format('HH:mm'),
      summaryUsageRangeEndTime: this.$moment(new Date()).format('HH:mm'),
      summaryUsageRangeSettingTimeItem: [
        '00:00',
        '01:00',
        '02:00',
        '03:00',
        '04:00',
        '05:00',
        '06:00',
        '07:00',
        '08:00',
        '09:00',
        '10:00',
        '11:00',
        '12:00',
        '13:00',
        '14:00',
        '15:00',
        '16:00',
        '17:00',
        '18:00',
        '19:00',
        '20:00',
        '21:00',
        '22:00',
        '23:00'
      ],
      // summaryUsageRangeHour: 24,
      // summaryUsageRangeHourMenu: [
      //   { text: '4小時', value: 4 },
      //   { text: '1天', value: 24 },
      //   { text: '1週', value: 168 }
      // ],
      summaryUsageRangeInterval: 1440,
      summaryUsageRangeSettingInterval: 1440,
      summaryUsageRangeIntervalDefaultItem: [
        { text: '30分鐘', value: 30 },
        { text: '1小時', value: 60 },
        { text: '2小時', value: 120 },
        { text: '4小時', value: 240 },
        { text: '6小時', value: 360 },
        { text: '12小時', value: 720 },
        { text: '24小時', value: 1440 },
        { text: '72小時', value: 4320 },
        { text: '1週', value: 10080 }
      ],
      systemUsageInfo: {
        loadingStatus: true,
        nonRepetitiveUsers: 0,
        totalRequestQty: 0,
        avgLatency: 0
      },
      statisticRequestChart: {
        loadingStatus: true,
        chartViewStatus: false,
        selectIndex: -1,
        data: [
          // {
          //   rangeTimeStart: 1620189089000,
          //   rangeTimeEnd: 1620192689000,
          //   requests: 3000
          // },
          // {
          //   rangeTimeStart: 1620192689000,
          //   rangeTimeEnd: 1620196289000,
          //   requests: 4000
          // },
          // {
          //   rangeTimeStart: 1620196289000,
          //   rangeTimeEnd: 1620199889000,
          //   requests: 5000
          // },
          // {
          //   rangeTimeStart: 1620199889000,
          //   rangeTimeEnd: 1620203489000,
          //   requests: 6000
          // }
        ]
      },
      latencyBucketTable: {
        loadingStatus: true,
        responseHeaders: [
          {
            align: '',
            class: '',
            sortable: true,
            hidden: false,
            text: '篩選',
            value: 'filter'
          },
          {
            align: '',
            class: '',
            sortable: true,
            hidden: false,
            text: '回應時間區間',
            value: 'label'
          },
          {
            align: '',
            class: '',
            sortable: true,
            hidden: false,
            text: '次數/百分比',
            value: 'value'
          }
        ],
        responseData: [
          // {
          //   key: 'bucket_5',
          //   label: '51-100',
          //   value: 264
          // }
        ],
        filterKeyList: [],
        keyWord: ''
        // rowsAmount: [25, 50, 100, { text: 'All', value: -1 }],
        // perPageRows: 25,
        // pagination: {
        //   page: 1,
        //   rowsPerPage: 25,
        //   sortBy: '',
        //   descending: false
        // },
        // pages: 0
      },
      departmentUsageTable: {
        loadingStatus: true,
        responseHeaders: [
          {
            align: '',
            class: '',
            sortable: true,
            hidden: false,
            text: '篩選',
            value: 'filter'
          },
          {
            align: '',
            class: '',
            sortable: true,
            hidden: false,
            text: '部門名稱',
            value: 'label'
          },
          {
            align: '',
            class: '',
            sortable: true,
            hidden: false,
            text: '請求次數',
            value: 'totalRequestQty'
          },
          {
            align: '',
            class: '',
            sortable: true,
            hidden: false,
            text: '反應時間(S)',
            value: 'avgLatency'
          }
        ],
        responseData: [
          // {
          //   key: 'dep_3',
          //   label: '系统-电竞-品保管理处-零件品保部-SZ机构零件品保课',
          //   totalRequestQty: 26,
          //   avgLatency: 11
          // }
        ],
        filterKeyList: [],
        keyWord: '',
        rowsAmount: [10, 25, 50, { text: 'All', value: -1 }],
        pagination: {
          page: 1,
          rowsPerPage: 10,
          sortBy: '',
          descending: null
        },
        pages: 0
      },
      serviceUsageTable: {
        loadingStatus: true,
        responseHeaders: [
          {
            align: '',
            class: '',
            sortable: true,
            hidden: false,
            text: '篩選',
            value: 'filter'
          },
          {
            align: '',
            class: '',
            sortable: true,
            hidden: false,
            text: '功能頁名稱',
            value: 'label'
          },
          {
            align: '',
            class: '',
            sortable: true,
            hidden: false,
            text: '請求次數',
            value: 'totalRequestQty'
          },
          {
            align: '',
            class: '',
            sortable: true,
            hidden: false,
            text: '反應時間(S)',
            value: 'avgLatency'
          }
        ],
        responseData: [
          // {
          //   key: 'page_2',
          //   label: '每週模組不良項目分析',
          //   totalRequestQty: 26,
          //   avgLatency: 11
          // }
        ],
        filterKeyList: [],
        keyWord: '',
        rowsAmount: [10, 25, 50, { text: 'All', value: -1 }],
        pagination: {
          page: 1,
          rowsPerPage: 10,
          sortBy: '',
          descending: null
        },
        pages: 0
      },
      userRawRequestDataTable: {
        loadingStatus: true,
        responseHeaders: [
          {
            align: '',
            class: 'table-width-200',
            sortable: true,
            hidden: false,
            text: '使用者',
            value: 'userName'
          },
          {
            align: '',
            class: 'table-width-150',
            sortable: true,
            hidden: false,
            text: '請求時間',
            value: 'requestTime'
          },
          {
            align: '',
            class: 'w-100',
            sortable: true,
            hidden: false,
            text: '請求內容',
            value: 'reqeustBody'
          },
          {
            align: '',
            class: 'table-width-150',
            sortable: true,
            hidden: false,
            text: '功能頁面',
            value: 'serviceName'
          },
          {
            align: '',
            class: 'table-width-200',
            sortable: true,
            hidden: false,
            text: '請求總共時間(s)',
            value: 'latency'
          }
        ],
        responseData: [
          // {
          //   userName: 'XXXX',
          //   requestTime: 1620192689000,
          //   reqeustBody: 'XXXX',
          //   serviceName: 'XXXX',
          //   latency: 'XXXX'
          // },
          // {
          //   userName: 'XXXX',
          //   requestTime: 2620192689000,
          //   reqeustBody: 'XXXX',
          //   serviceName: 'XXXX',
          //   latency: 'XXXX'
          // }
        ],
        keyWord: '',
        rowsAmount: [25, 50, 100, { text: 'All', value: -1 }],
        perPageRows: 25,
        pagination: {
          page: 1,
          current: {
            page: 1,
            rowsPerPage: 25,
            sortBy: '',
            descending: null,
            totalItems: 0
          }
        },
        pages: 0
      },
      barXaxisName: ['x'],
      barXaxisValue: ['請求次數'],
      barXaxisNameStart: ['x'],
      handler_bar: new Vue()
    }
  },
  computed: {
    ...mapState('app', ['theme', 'shrink']),
    summaryUsageRangeIntervalMenu () {
      const menu = []
      let customize = true
      this.summaryUsageRangeIntervalDefaultItem.forEach((n) => {
        if (n.value === this.summaryUsageRangeInterval) {
          customize = false
        }
        if (n.value <= ((this.summaryUsageRangeFreezeEndDateStamp - this.summaryUsageRangeFreezeStartDateStamp) / 60000)) {
          menu.push(n)
        }
      })
      if (customize) {
        menu.push({ text: '自訂', value: this.summaryUsageRangeInterval })
      }
      return menu
    },
    displayIntervalState () {
      return (this.statisticRequestChart.selectIndex > -1)
    },
    summaryUsageRangeDateFullTitle () {
      return this.summaryUsageRangeStartDate + ' ' + this.summaryUsageRangeStartTime + ' 至 ' + this.summaryUsageRangeEndDate + ' ' + this.summaryUsageRangeEndTime
    },
    summaryUsageRangeStartDateString () {
      if (this.displayIntervalState) {
        return this.$moment(this.statisticRequestChart.responseData[this.statisticRequestChart.selectIndex].rangeTimeStart).format('YYYY-MM-DD HH:mm:ss.SSS')
      } else {
        return this.$moment(this.summaryUsageRangeStartDate + ' ' + this.summaryUsageRangeStartTime).format('YYYY-MM-DD HH:mm:ss.SSS')
      }
    },
    summaryUsageRangeEndDateString () {
      if (this.displayIntervalState) {
        return this.$moment(this.statisticRequestChart.responseData[this.statisticRequestChart.selectIndex].rangeTimeEnd).format('YYYY-MM-DD HH:mm:ss.SSS')
      } else {
        return this.$moment(this.summaryUsageRangeEndDate + ' ' + this.summaryUsageRangeEndTime).format('YYYY-MM-DD HH:mm:ss.SSS')
      }
    },
    summaryUsageRangeStartDateStamp () {
      if (this.displayIntervalState) {
        return this.statisticRequestChart.responseData[this.statisticRequestChart.selectIndex].rangeTimeStart
      } else {
        return new Date(this.summaryUsageRangeStartDate + ' ' + this.summaryUsageRangeStartTime).getTime()
      }
    },
    summaryUsageRangeEndDateStamp () {
      if (this.displayIntervalState) {
        return this.statisticRequestChart.responseData[this.statisticRequestChart.selectIndex].rangeTimeEnd
      } else {
        return new Date(this.summaryUsageRangeEndDate + ' ' + this.summaryUsageRangeEndTime).getTime()
      }
    },
    summaryUsageRangeFreezeStartDateString () {
      return this.$moment(this.summaryUsageRangeStartDate + ' ' + this.summaryUsageRangeStartTime).format('YYYY-MM-DD HH:mm:ss.SSS')
    },
    summaryUsageRangeFreezeEndDateString () {
      return this.$moment(this.summaryUsageRangeEndDate + ' ' + this.summaryUsageRangeEndTime).format('YYYY-MM-DD HH:mm:ss.SSS')
    },
    summaryUsageRangeFreezeStartDateStamp () {
      return new Date(this.summaryUsageRangeStartDate + ' ' + this.summaryUsageRangeStartTime).getTime()
    },
    summaryUsageRangeFreezeEndDateStamp () {
      return new Date(this.summaryUsageRangeEndDate + ' ' + this.summaryUsageRangeEndTime).getTime()
    },
    departmentUsageTablePageslist () {
      let i = 0
      const list = []
      if (this.departmentUsageTable.pagination.rowsPerPage) {
        for (i = 0; i < Math.ceil(this.departmentUsageTable.responseData.length / this.departmentUsageTable.pagination.rowsPerPage); i++) {
          list.push(i + 1)
        }
      }
      return list
    },
    serviceUsageTablePageslist () {
      let i = 0
      const list = []
      if (this.serviceUsageTable.pagination.rowsPerPage) {
        for (i = 0; i < Math.ceil(this.serviceUsageTable.responseData.length / this.serviceUsageTable.pagination.rowsPerPage); i++) {
          list.push(i + 1)
        }
      }
      return list
    },
    userRawRequestDataTablePageslist () {
      let i = 0
      const list = []
      if (this.userRawRequestDataTable.pagination.current.rowsPerPage) {
        for (i = 0; i < Math.ceil(this.userRawRequestDataTable.pagination.current.totalItems / this.userRawRequestDataTable.pagination.current.rowsPerPage); i++) {
          list.push(i + 1)
        }
      }
      return list
    },
    options_bar () {
      return {
        data: {
          x: 'x',
          columns: [
            this.barXaxisName,
            this.barXaxisValue
          ],
          labels: true,
          // labels: {
          //   // format: function (v, id, i, j) { return v + '%' }
          // },
          // onclick: function (d) { alert(d.index) },
          onclick: (d) => { this.doStatisticRequestSelectRange(d.index) },
          type: 'bar',
          color: (color, d) => {
            // d will be 'id' when called for legends
            return (this.statisticRequestChart.selectIndex === -1 ? color : (d.index === this.statisticRequestChart.selectIndex) ? '#ffa500' : color + '77')
          }
          // colors: {
          //   ModelName: '#999999'
          // }
          // onclick: (d, i) => { this.summaryList(d, i) } // {id: "data23", value: 120, ratio: 0.8, index: 1, name: "data23"}
          // color: function (color, d) {
          //   // d will be 'id' when called for legends
          //   return d.value >= 5.0 ? '#ff0000' : d.value >= 3.0 ? '#ff9800' : color
          // }
          // color: (color, d) => {
          //   // d will be 'id' when called for legends
          //   return (this.OverRangeCount > 0 && d.index === this.barXaxisName.length - 2) ? '#ffa500' : color
          // }
        },
        axis: {
          x: {
            type: 'category', // this needed to load string x value
            tick: {
              count: 7,
              rotate: -45,
              multiline: true,
              fit: true,
              format: (d) => { return this.barXaxisNameStart[d + 1] },
              width: 150
            },
            padding: {
              left: 1
            }
            // label: {
            //   text: '期',
            //   position: 'outer-center'
            // }
          },
          y: {
            // max: this.RangeCount,
            // min: 0,
            // tick: {
            //   count: 5,
            //   format: function (value) { return value.toFixed(0) }
            // }
            // padding: {
            //   top: 0,
            //   bottom: 0
            // }
            // tick: {
            //   format: function (value) { return value + '%' }
            // }
            // label: {
            //   text: '%',
            //   position: 'outer-middle'
            // }
          },
          y2: {
            // show: true
            // label: {
            //   text: '億',
            //   position: 'outer-middle'
            // }
          }
        },
        tooltip: {
          // contents: (d, defaultTitleFormat, defaultValueFormat, color) => {
          //   return this.barXaxisName[d[0].index + 1]
          //   // return this.chartPieTooltip[d[0].index]
          // }
          format: {
            title: (d) => {
              return `${this.barXaxisName[d + 1]}`
            },
            value: (value, ratio, id) => {
              return `${value}`
            }
          }
        },
        grid: {
          x: {
            show: true
          },
          y: {
            show: true
          }
        },
        legend: {
          position: 'bottom',
          padding: 16,
          show: false
        },
        padding: {
          bottom: 30
        },
        size: {
          // width: 640,
          height: 520
        }
      }
    }
  },
  watch: {
    summaryUsageRangeHour (val) {
      if (!this.watchPauseStatus) {
        // this.doStatisticRequestChart()
      }
    },
    summaryUsageRangeInterval (val) {
      if (!this.watchPauseStatus) {
        this.summaryUsageRangeSettingInterval = val
        if (this.statisticRequestChart.selectIndex === -1) {
          this.requestId = new Date().getTime()
          this.getStatisticRequest()
        } else {
          this.statisticRequestChart.selectIndex = -1
          this.requestId = new Date().getTime()
          this.getStatisticRequest()
        }
      }
    },
    'statisticRequestChart.selectIndex' (val) {
      if (!this.watchPauseStatus) {
        this.requestId = new Date().getTime()
        this.getSystemUsage()
        this.getLatencyBucket()
        this.getDepartmentUsage()
        this.getServiceUsage()
        this.getUserRawRequestData()
      }
    },
    'latencyBucketTable.filterKeyList' (val) {
      if (!this.watchPauseStatus) {
        this.requestId = new Date().getTime()
        this.getSystemUsage()
        this.getStatisticRequest()
        this.getLatencyBucket()
        this.getDepartmentUsage()
        this.getServiceUsage()
        this.getUserRawRequestData()
      }
    },
    'departmentUsageTable.filterKeyList' (val) {
      if (!this.watchPauseStatus) {
        this.requestId = new Date().getTime()
        this.getSystemUsage()
        this.getStatisticRequest()
        this.getLatencyBucket()
        this.getDepartmentUsage()
        this.getServiceUsage()
        this.getUserRawRequestData()
      }
    },
    'departmentUsageTable.pagination.rowsPerPage' (val) {
      this.departmentUsageTable.pages = Math.ceil(this.departmentUsageTable.responseData.length / this.departmentUsageTable.pagination.rowsPerPage)
    },
    'serviceUsageTable.filterKeyList' (val) {
      if (!this.watchPauseStatus) {
        this.requestId = new Date().getTime()
        this.getSystemUsage()
        this.getStatisticRequest()
        this.getLatencyBucket()
        this.getDepartmentUsage()
        this.getServiceUsage()
        this.getUserRawRequestData()
      }
    },
    'serviceUsageTable.pagination.rowsPerPage' (val) {
      this.serviceUsageTable.pages = Math.ceil(this.serviceUsageTable.responseData.length / this.serviceUsageTable.pagination.rowsPerPage)
    },
    'userRawRequestDataTable.keyWord' (val) {
      this.requestId = new Date().getTime()
      this.getUserRawRequestData()
    },
    'userRawRequestDataTable.perPageRows' (val) {
      if (val === -1) {
        if (confirm('完整顯示將可能導致效能降低，是否仍完整顯示?')) {
          this.userRawRequestDataTable.pagination.current.rowsPerPage = val
          this.userRawRequestDataTable.pages = Math.ceil(this.userRawRequestDataTable.responseData.length / this.userRawRequestDataTable.pagination.current.rowsPerPage)
          this.userRawRequestDataTable.pagination.page = 1
        } else {
          setTimeout(() => {
            this.userRawRequestDataTable.perPageRows = this.userRawRequestDataTable.pagination.current.rowsPerPage
          }, 100)
        }
      } else {
        this.userRawRequestDataTable.pagination.current.rowsPerPage = val
        this.userRawRequestDataTable.pages = Math.ceil(this.userRawRequestDataTable.responseData.length / this.userRawRequestDataTable.pagination.current.rowsPerPage)
        this.userRawRequestDataTable.pagination.page = 1
      }
    },
    'userRawRequestDataTable.pagination': {
      handler (newName, oldName) {
        if (this.authorize && this.doSummaryUsageRangeDate) {
          // this.requestId = new Date().getTime()
          this.getUserRawRequestData()
        }
      },
      immediate: false,
      deep: true
    }
    // 'userRawRequestDataTable.pagination.page' (val) {
    //   this.getUserRawRequestData()
    // },
    // 'userRawRequestDataTable.pagination.current.rowsPerPage' (val) {
    //   this.getUserRawRequestData()
    // },
    // 'userRawRequestDataTable.pagination.current.sortBy' (val) {
    //   this.getUserRawRequestData()
    // },
    // 'userRawRequestDataTable.pagination.current.descending' (val) {
    //   this.getUserRawRequestData()
    // }
  },
  mounted () {
    this.$store.dispatch('getAuthorize', '/system/serviceUsageAnalysisView')
      .then(response => {
        if (response.status === 'success' && response.data.accessible) {
          this.authorize = true
          JSON.parse(localStorage.getItem('moduleDataInitDate')).forEach((n) => {
            // if (n.name === 'system') {
            //   this.startdate = n.value
            // }
          })
          this.doSummaryUsageRangeDate(this.$route.params.hour || 24, this.$route.params.interval || 1440, true)
          this.requestId = new Date().getTime()
          this.getSystemUsage()
          this.getStatisticRequest()
          this.getLatencyBucket()
          this.getDepartmentUsage()
          this.getServiceUsage()
          this.getUserRawRequestData()
        } else if (response.errCode === 'E10000002') {
          const routeData = this.$router.resolve({ path: '/noEnter/E10000002' })
          localStorage.setItem('errormsg', response.errMsg)
          window.location.assign(routeData.href)
        } else {
          const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
          window.location.assign(routeData.href)
        }
      })
      .catch(err => {
        console.log(err)
        const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
        window.location.assign(routeData.href)
      })
  },
  methods: {
    getSystemUsage () {
      this.systemUsageInfo.loadingStatus = true
      const body = {
        startDate: this.summaryUsageRangeStartDateStamp,
        endDate: this.summaryUsageRangeEndDateStamp,
        userPk: [],
        departmentPk: this.departmentUsageTable.filterKeyList,
        serviceName: this.serviceUsageTable.filterKeyList,
        latencyBucket: this.latencyBucketTable.filterKeyList,
        startDateString: this.summaryUsageRangeStartDateString,
        endDateString: this.summaryUsageRangeEndDateString,
        interval: this.summaryUsageRangeInterval
      }
      this.$http.get('/api/system/systemAnalysis/systemUsageSummaryStatistics?' + qs.stringify(body), {
      // this.$http.get('http://rap2api.taobao.org/app/mock/277656/api/system/systemAnalysis/systemUsageSummaryStatistics?' + qs.stringify(body), {
        headers: {
          request_id: this.requestId
        }
      }).then(res => {
        if (res.config.headers.request_id === this.requestId) {
          if (res.data.status === 'success') {
            this.systemUsageInfo.loadingStatus = false
            this.systemUsageInfo.nonRepetitiveUsers = res.data.data.nonRepetitiveUsers
            this.systemUsageInfo.totalRequestQty = res.data.data.totalRequestQty
            this.systemUsageInfo.avgLatency = res.data.data.avgLatency
          }
        }
      })
        .catch(err => {
          console.log(err)
        })
    },
    getStatisticRequest () {
      this.statisticRequestChart.loadingStatus = true
      const body = {
        startDate: this.summaryUsageRangeFreezeStartDateStamp,
        endDate: this.summaryUsageRangeFreezeEndDateStamp,
        userPk: [],
        departmentPk: this.departmentUsageTable.filterKeyList,
        serviceName: this.serviceUsageTable.filterKeyList,
        latencyBucket: this.latencyBucketTable.filterKeyList,
        startDateString: this.summaryUsageRangeFreezeStartDateString,
        endDateString: this.summaryUsageRangeFreezeEndDateString,
        interval: this.summaryUsageRangeInterval
      }
      this.$http.get('/api/system/systemAnalysis/statisticRequestByTimeRange?' + qs.stringify(body), {
      // this.$http.get('http://rap2api.taobao.org/app/mock/277656/api/system/systemAnalysis/statisticRequestByTimeRange?' + qs.stringify(body), {
        headers: {
          request_id: this.requestId
        }
      }).then(res => {
        if (res.config.headers.request_id === this.requestId) {
          if (res.data.status === 'success') {
            this.statisticRequestChart.loadingStatus = false
            this.statisticRequestChart.responseData = res.data.data
            this.doStatisticRequestChart()
          }
        }
      })
        .catch(err => {
          console.log(err)
        })
    },
    getLatencyBucket () {
      this.latencyBucketTable.loadingStatus = true
      const body = {
        startDate: this.summaryUsageRangeStartDateStamp,
        endDate: this.summaryUsageRangeEndDateStamp,
        userPk: [],
        departmentPk: this.departmentUsageTable.filterKeyList,
        serviceName: this.serviceUsageTable.filterKeyList,
        // latencyBucket: this.latencyBucketTable.filterKeyList,
        latencyBucket: '',
        startDateString: this.summaryUsageRangeStartDateString,
        endDateString: this.summaryUsageRangeEndDateString,
        interval: this.summaryUsageRangeInterval
      }
      this.$http.get('/api/system/systemAnalysis/systemLatencyBucketStatistics?' + qs.stringify(body), {
      // this.$http.get('http://rap2api.taobao.org/app/mock/277656/api/system/systemAnalysis/systemLatencyBucketStatistics?' + qs.stringify(body), {
        headers: {
          request_id: this.requestId
        }
      }).then(res => {
        if (res.config.headers.request_id === this.requestId) {
          if (res.data.status === 'success') {
            this.latencyBucketTable.loadingStatus = false
            this.latencyBucketTable.responseData = res.data.data
          }
        }
      })
        .catch(err => {
          console.log(err)
        })
    },
    getDepartmentUsage () {
      this.departmentUsageTable.loadingStatus = true
      const body = {
        startDate: this.summaryUsageRangeStartDateStamp,
        endDate: this.summaryUsageRangeEndDateStamp,
        userPk: [],
        // departmentPk: this.departmentUsageTable.filterKeyList,
        departmentPk: '',
        serviceName: this.serviceUsageTable.filterKeyList,
        latencyBucket: this.latencyBucketTable.filterKeyList,
        startDateString: this.summaryUsageRangeStartDateString,
        endDateString: this.summaryUsageRangeEndDateString,
        interval: this.summaryUsageRangeInterval
      }
      this.$http.get('/api/system/systemAnalysis/systemUsageStatisticsByDepartment?' + qs.stringify(body), {
      // this.$http.get('http://rap2api.taobao.org/app/mock/277656/api/system/systemAnalysis/systemUsageStatisticsByDepartment?' + qs.stringify(body), {
        headers: {
          request_id: this.requestId
        }
      }).then(res => {
        if (res.config.headers.request_id === this.requestId) {
          if (res.data.status === 'success') {
            this.departmentUsageTable.loadingStatus = false
            this.departmentUsageTable.responseData = res.data.data
            this.departmentUsageTable.pagination.totalItems = res.data.data.length
            this.departmentUsageTable.pages = Number(Math.ceil(res.data.data.length / this.departmentUsageTable.pagination.rowsPerPage))
          }
        }
      })
        .catch(err => {
          console.log(err)
        })
    },
    getServiceUsage () {
      this.serviceUsageTable.loadingStatus = true
      const body = {
        startDate: this.summaryUsageRangeStartDateStamp,
        endDate: this.summaryUsageRangeEndDateStamp,
        userPk: [],
        departmentPk: this.departmentUsageTable.filterKeyList,
        // serviceName: this.serviceUsageTable.filterKeyList,
        serviceName: '',
        latencyBucket: this.latencyBucketTable.filterKeyList,
        startDateString: this.summaryUsageRangeStartDateString,
        endDateString: this.summaryUsageRangeEndDateString,
        interval: this.summaryUsageRangeInterval
      }
      this.$http.get('/api/system/systemAnalysis/systemUsageStatisticsByService?' + qs.stringify(body), {
      // this.$http.get('http://rap2api.taobao.org/app/mock/277656/api/system/systemAnalysis/systemUsageStatisticsByService?' + qs.stringify(body), {
        headers: {
          request_id: this.requestId
        }
      }).then(res => {
        if (res.config.headers.request_id === this.requestId) {
          if (res.data.status === 'success') {
            this.serviceUsageTable.loadingStatus = false
            this.serviceUsageTable.responseData = res.data.data
            this.serviceUsageTable.pagination.totalItems = Number(res.data.data.length)
            this.serviceUsageTable.pages = Number(Math.ceil(res.data.data.length / this.serviceUsageTable.pagination.rowsPerPage))
          }
        }
      })
        .catch(err => {
          console.log(err)
        })
    },
    getUserRawRequestData () {
      this.userRawRequestDataTable.loadingStatus = true
      const body = {
        pageNum: this.userRawRequestDataTable.pagination.page,
        pageRows: this.userRawRequestDataTable.pagination.current.rowsPerPage,
        sortColumn: (this.userRawRequestDataTable.pagination.current.descending !== null) ? this.userRawRequestDataTable.pagination.current.sortBy : '',
        sortType: this.userRawRequestDataTable.pagination.current.descending ? 'DESC' : ((this.userRawRequestDataTable.pagination.current.descending !== null) ? 'ASC' : ''),
        startDate: this.summaryUsageRangeStartDateStamp,
        endDate: this.summaryUsageRangeEndDateStamp,
        userPk: [],
        departmentPk: this.departmentUsageTable.filterKeyList,
        serviceName: this.serviceUsageTable.filterKeyList,
        latencyBucket: this.latencyBucketTable.filterKeyList,
        startDateString: this.summaryUsageRangeStartDateString,
        endDateString: this.summaryUsageRangeEndDateString,
        interval: this.summaryUsageRangeInterval
      }
      this.$http.get('/api/system/systemAnalysis/userRawRequestDataList?' + qs.stringify(body), {
      // this.$http.get('http://rap2api.taobao.org/app/mock/277656/api/system/systemAnalysis/userRawRequestDataList?' + qs.stringify(body), {
        headers: {
          request_id: this.requestId
        }
      }).then(res => {
        if (res.config.headers.request_id === this.requestId) {
          if (res.data.status === 'success') {
            this.userRawRequestDataTable.loadingStatus = false
            this.userRawRequestDataTable.responseData = res.data.data.data
            this.userRawRequestDataTable.pagination.current.totalItems = Number(res.data.data.totalRows)
            this.userRawRequestDataTable.pages = Number(Math.ceil(res.data.data.totalRows / this.userRawRequestDataTable.pagination.current.rowsPerPage))
          }
        }
      })
        .catch(err => {
          console.log(err)
        })
    },
    doSelectCheckItem (table, key) {
      if (table === 'latencyBucket') {
        if (this.latencyBucketTable.filterKeyList.indexOf(key) === -1) {
          this.latencyBucketTable.filterKeyList.push(key)
        } else {
          this.latencyBucketTable.filterKeyList.splice(this.latencyBucketTable.filterKeyList.indexOf(key), 1)
        }
      } else if (table === 'departmentUsage') {
        if (this.departmentUsageTable.filterKeyList.indexOf(key) === -1) {
          this.departmentUsageTable.filterKeyList.push(key)
        } else {
          this.departmentUsageTable.filterKeyList.splice(this.departmentUsageTable.filterKeyList.indexOf(key), 1)
        }
      } else if (table === 'serviceUsage') {
        if (this.serviceUsageTable.filterKeyList.indexOf(key) === -1) {
          this.serviceUsageTable.filterKeyList.push(key)
        } else {
          this.serviceUsageTable.filterKeyList.splice(this.serviceUsageTable.filterKeyList.indexOf(key), 1)
        }
      }
    },
    doSummaryUsageRangeSettingClose () {
      this.watchPauseStatus = false
      this.summaryUsageRangeDateSettingView = false
      this.summaryUsageRangeSettingStartDate = this.summaryUsageRangeStartDate
      this.summaryUsageRangeSettingEndDate = this.summaryUsageRangeEndDate
      // this.summaryUsageRangeSettingStartTime = this.summaryUsageRangeStartTime
      // this.summaryUsageRangeSettingEndTime = this.summaryUsageRangeEndTime
      this.summaryUsageRangeSettingStartTime = this.$moment(this.summaryUsageRangeStartDate + ' ' + this.summaryUsageRangeStartTime).format('HH:00')
      this.summaryUsageRangeSettingEndTime = this.$moment(this.summaryUsageRangeEndDate + ' ' + this.summaryUsageRangeEndTime).format('HH:00')
      this.summaryUsageRangeSettingInterval = this.summaryUsageRangeInterval
    },
    doSummaryUsageRangeSetting () {
      this.summaryUsageRangeStartDate = this.summaryUsageRangeSettingStartDate
      this.summaryUsageRangeEndDate = this.summaryUsageRangeSettingEndDate
      this.summaryUsageRangeStartTime = this.summaryUsageRangeSettingStartTime
      this.summaryUsageRangeEndTime = this.summaryUsageRangeSettingEndTime
      this.summaryUsageRangeInterval = this.summaryUsageRangeSettingInterval
      this.statisticRequestChart.selectIndex = -1
      setTimeout(() => {
        this.watchPauseStatus = false
        this.summaryUsageRangeDateSettingView = false
        this.requestId = new Date().getTime()
        this.getSystemUsage()
        this.getStatisticRequest()
        this.getLatencyBucket()
        this.getDepartmentUsage()
        this.getServiceUsage()
        this.getUserRawRequestData()
      }, 0)
    },
    doSummaryUsageRangeDate (hour, interval, sent) {
      this.watchPauseStatus = true
      setTimeout(() => {
        this.summaryUsageRangeStartDate = this.$moment(new Date().getTime() - (3600000 * hour)).format('YYYY-MM-DD')
        this.summaryUsageRangeEndDate = this.$moment(new Date()).format('YYYY-MM-DD')
        this.summaryUsageRangeStartTime = this.$moment(new Date().getTime() - (3600000 * hour)).format('HH:mm')
        this.summaryUsageRangeEndTime = this.$moment(new Date()).format('HH:mm')
        this.summaryUsageRangeSettingStartDate = this.$moment(new Date().getTime() - (3600000 * hour)).format('YYYY-MM-DD')
        this.summaryUsageRangeSettingEndDate = this.$moment(new Date()).format('YYYY-MM-DD')
        this.summaryUsageRangeSettingStartTime = this.$moment(new Date().getTime() - (3600000 * hour)).format('HH:00')
        this.summaryUsageRangeSettingEndTime = this.$moment(new Date()).format('HH:00')
        this.summaryUsageRangeInterval = Number(interval)
        this.summaryUsageRangeSettingInterval = Number(interval)
        this.statisticRequestChart.selectIndex = -1
      }, 0)
      setTimeout(() => {
        this.watchPauseStatus = false
        if (sent) {
          this.requestId = new Date().getTime()
          this.getSystemUsage()
          this.getStatisticRequest()
          this.getLatencyBucket()
          this.getDepartmentUsage()
          this.getServiceUsage()
          this.getUserRawRequestData()
        }
      }, 0)
    },
    doLatencyBucketTableValuePercentage (value) {
      let total = 0
      this.latencyBucketTable.responseData.forEach((r) => {
        if (this.latencyBucketTable.filterKeyList.length === 0 || (this.latencyBucketTable.filterKeyList.indexOf(r.key) > -1)) {
          total = total + r.value
        }
      })
      return (value / total) || 0
    },
    doStatisticRequestChart () {
      this.barXaxisName = ['x']
      this.barXaxisValue = ['請求次數']
      this.barXaxisNameStart = ['x']
      this.statisticRequestChart.responseData.forEach((r) => {
        this.barXaxisName.push(this.$moment(r.rangeTimeStart).format('YYYY-MM-DD HH:mm') + ' 至 ' + this.$moment(r.rangeTimeEnd).format('YYYY-MM-DD HH:mm'))
        this.barXaxisValue.push(r.requests)
        this.barXaxisNameStart.push(this.$moment(r.rangeTimeStart).format('YYYY-MM-DD HH:mm'))
      })
      // let AllArray = []
      // this.responseDataOnly.forEach((r) => {
      //   if (r[this.summaryItem] >= 0) {
      //     AllArray.push(r[this.summaryItem])
      //   }
      // })
      // this.RangeMax = Math.ceil(Math.max(...AllArray) / this.summaryRange) * this.summaryRange
      // this.RangeMin = Math.floor(Math.min(...AllArray) / this.summaryRange) * this.summaryRange
      // if (this.RangeMax > this.RangeMaxList[this.summaryItem]) {
      //   this.RangeMax = Math.min(this.RangeMax, this.RangeMaxList[this.summaryItem])
      // }
      // this.OverRangeCount = 0
      // const RangeArray = []
      // for (let i = this.RangeMin; i < this.RangeMax; i = i + this.summaryRange) {
      //   RangeArray[(i - this.RangeMin) / this.summaryRange] = 0
      // }
      // AllArray.forEach((r) => {
      //   if (r > this.RangeMaxList[this.summaryItem]) {
      //     this.OverRangeCount = this.OverRangeCount + 1
      //   } else {
      //     if (RangeArray[Math.floor((r - this.RangeMin) / this.summaryRange)] > 0) {
      //       RangeArray[Math.floor((r - this.RangeMin) / this.summaryRange)] = RangeArray[Math.floor((r - this.RangeMin) / this.summaryRange)] + 1
      //     } else {
      //       RangeArray[Math.floor((r - this.RangeMin) / this.summaryRange)] = 1
      //     }
      //   }
      // })

      // this.RangeCount = Math.max(...RangeArray)
      // for (let i = 0; i < RangeArray.length; i++) {
      //   this.barXaxisValue.push(RangeArray[i] || 0)
      //   switch (true) {
      //     case (this.summaryRange === '5' || this.summaryRange === '10'):
      //       this.barXaxisName.push((this.RangeMin + (i * this.summaryRange)).toFixed(0) + '-' + (this.RangeMin + ((i + 1) * this.summaryRange)).toFixed(0))
      //       break
      //     case (this.summaryRange === '0.1' || this.summaryRange === '2.5' || this.summaryRange === '7.5'):
      //       this.barXaxisName.push((this.RangeMin + (i * this.summaryRange)).toFixed(1) + '-' + (this.RangeMin + ((i + 1) * this.summaryRange)).toFixed(1))
      //       break
      //     case (this.summaryRange === '0.05'):
      //       this.barXaxisName.push((this.RangeMin + (i * this.summaryRange)).toFixed(2) + '-' + (this.RangeMin + ((i + 1) * this.summaryRange)).toFixed(2))
      //       break
      //     // default:
      //     // return
      //   }
      // }
      // if (this.OverRangeCount > 0) {
      //   this.barXaxisValue.push(this.OverRangeCount)
      //   this.barXaxisName.push('>' + this.RangeMaxList[this.summaryItem])
      // }
      // RangeArray.forEach((r, index) => {
      //   console.log(r)
      //   console.log(index)
      //   this.barXaxisValue.push(r)
      //   this.barXaxisName.push((this.RangeMin + (index * this.summaryRange)) + '-' + (this.RangeMin + ((index + 1) * this.summaryRange)))
      // })
      if (!this.chartViewStatus) {
        this.chartViewStatus = true
        this.handler_bar.$emit('init', this.options_bar)
      } else {
        this.handler_bar.$emit('dispatch', chart => {
          const options = {
            columns: [
              this.barXaxisName,
              this.barXaxisValue
            ]
          }
          chart.load(options)
        })
      }
    },
    doStatisticRequestSelectRange (index) {
      if (this.statisticRequestChart.selectIndex === index) {
        this.statisticRequestChart.selectIndex = -1
        this.doStatisticRequestChart()
      } else {
        this.statisticRequestChart.selectIndex = index
        this.doStatisticRequestChart()
      }
    },
    doResetFilter () {
      this.watchPauseStatus = true
      setTimeout(() => {
        this.statisticRequestChart.chartViewStatus = false
        this.statisticRequestChart.selectIndex = -1
        this.latencyBucketTable.filterKeyList = []
        this.departmentUsageTable.filterKeyList = []
        this.serviceUsageTable.filterKeyList = []
      }, 0)
      setTimeout(() => {
        this.watchPauseStatus = false
        this.requestId = new Date().getTime()
        this.getSystemUsage()
        this.getStatisticRequest()
        this.getLatencyBucket()
        this.getDepartmentUsage()
        this.getServiceUsage()
        this.getUserRawRequestData()
      }, 0)
    },
    doResetAll () {
      // this.$router.go(0)
      this.doSummaryUsageRangeDate(this.$route.params.hour || 24, this.$route.params.interval || 1440, false)
      setTimeout(() => {
        this.doResetFilter()
      }, 0)
    }
    // checkTableShowListAll () {
    //   this.checkTableShowList = []
    //   this.summaryTableShowList.forEach((s) => {
    //     this.checkTableShowList.push(s.value)
    //   })
    // },
    // checkTableShowListClear () {
    //   this.checkTableShowList = []
    // },
    // doSubmit () {
    //   this.loadingView = true
    //   const body = {
    //     queryStartDate: this.summaryDate,
    //     queryEndDate: this.summaryDateRange || this.summaryDate,
    //     vendor: this.summaryVendorPk,
    //     deviceIsn: this.summaryAsusDeviceIsn,
    //     modelName: this.summaryCaseName,
    //     type: 0
    //     // cpuTemperature: this.toggle_cpuTemp ? this.summaryCpuTemp === '' ? 0 : this.summaryCpuTemp : null,
    //     // cpuCondition: this.toggle_cpuTemp || null,
    //     // gpuTemperature: this.toggle_gpuTemp ? this.summaryGpuTemp === '' ? 0 : this.summaryGpuTemp : null,
    //     // gpuCondition: this.toggle_gpuTemp || null,
    //     // channel: this.summaryChannel === 'All' ? '' : this.summaryChannel
    //   }
    //   this.$http.post('/api/thermalModule/getModuleBurnInDataStatisticListColumnDefine', body).then(res => res.data)
    //     .then(res => {
    //       if (res.status === 'success') {
    //         // this.responseHeaders.push({
    //         //   align: '',
    //         //   class: '',
    //         //   fixed: true,
    //         //   sortable: false,
    //         //   text: '',
    //         //   value: ''
    //         // })
    //         // this.responseHeadersOnly.push({
    //         //   align: '',
    //         //   class: '',
    //         //   fixed: true,
    //         //   sortable: false,
    //         //   text: '',
    //         //   value: ''
    //         // })
    //         this.responseHeadersList = []
    //         res.data.columnDefine.forEach((r) => {
    //           if (r.fixed) {
    //             this.responseHeaders.push(r)
    //             this.responseHeadersOnly.push(r)
    //           } else {
    //             this.responseHeadersList.push(r)
    //             this.responseTableShowList[r.value] = r.fixed
    //             this.responseTableShowListOnly[r.value] = r.fixed
    //             this.responseColumnClassList[r.value] = r.class
    //             this.responseAlignList[r.value] = r.align
    //             this.summaryTableShowList.push({ text: r.text, value: r.value })
    //           }
    //         })
    //         this.pagination.sortBy = res.data.sortBy
    //         this.pagination.descending = res.data.descending
    //       }
    //     })
    //   this.$http.post('/api/thermalModule/getModuleBurnInDataStatistic', body).then(res => res.data)
    //     .then(res => {
    //       this.loadingView = false
    //       if (res.status === 'success') {
    //         // this.pagination.rowsPerPage = res.data.length
    //         res.data.deviceList.forEach((r, index) => {
    //           this.responseData.push({})
    //           r.forEach((rr) => {
    //             this.responseData[index][rr.name] = rr.value
    //           })
    //           this.responseDataOnly.push({})
    //           r.forEach((rr) => {
    //             this.responseDataOnly[index][rr.name] = rr.value
    //           })
    //         })
    //         this.pagination.rowsPerPage = 25
    //         // this.pages = Math.ceil(this.responseData.length / this.pagination.rowsPerPage)
    //         this.doStatisticRequestChart('init')
    //         this.responseAreaData = res.data.summaryInfo
    //         this.responseAreaData.color = this.responseAreaData.warningColor === '' ? 'light-green' : this.responseAreaData.warningColor === 'orange' ? 'deep-orange' : 'pink'
    //         this.responseAreaData.icon = this.responseAreaData.warningColor === '' ? 'mdi-check' : this.responseAreaData.warningColor === 'orange' ? 'mdi-alarm-light' : 'mdi-alert'
    //       }
    //     })
    // }
  }
}
</script>
<style>
</style>
