<template>
  <v-container
    csc
    fluid
    grid-list-xl
    pt-0
  >
    <v-layout wrap>
      <v-flex
        flex_table_width_auto
        xs12
        lg4>
        <material-card
          id="checkTable"
          :style="'max-width: calc(100vw - '+(Number(shrink)+32)+'px);'"
          title="年度不良率標準列表"
          color="deep-orange"
          class="card-mix d-table-t0 no-data-left"
          style="margin-top: 30px!important;">
          <!-- <v-select
            v-model="perPageRows"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="rowsAmount"
            class="mix-list v-text-field__slot d-inline-block float-right ml-5"
            style="width: 90px;"
            persistent-hint
            outlined
          /> -->
          <v-btn
            class="mix-btn black text-light"
            style="right: 1rem;"
            @click="doAddWindows(0)">
            <v-icon class="float-left">mdi-plus</v-icon>新增
          </v-btn>
          <v-data-table
            :headers="responseHeadersYear"
            :items="responseDataYear"
            class="height-row-3 table-align-left"
            multi-sort
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="subheading font-weight-light text-general text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <tr>
                <td>{{ item.name }}</td>
                <td>{{ (item.cfr*100).toFixed(2) }}%</td>
                <td>{{ item.warrantedMonth }}</td>
                <td>
                  <div>
                    <v-btn
                      class="ml-0 mr-2 font-weight-bold small"
                      color="deep-purple"
                      @click="doEditWindows(0, item.pk, item.name, item.cfr, item.warrantedMonth)">
                      <v-icon class="mr-2">mdi-pencil</v-icon>修改
                    </v-btn>
                    <v-btn
                      class="mx-0 font-weight-bold small"
                      color="red"
                      @click="doDelete(0, item.pk)">
                      <v-icon class="mr-2">mdi-close</v-icon>刪除
                    </v-btn>
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
          <!-- <div
            v-if="pagination.rowsPerPage != -1"
            class="text-xs-center pt-2 d-inline-block"
            style="margin-left: 0px;width: calc(100% - 140px);">
            <v-pagination
              v-model="pagination.page"
              :length="pages"/>
          </div>
          <v-autocomplete
            v-if="pagination.rowsPerPage != -1"
            v-model="pagination.page"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="pageslist"
            class="v-text-field__slot d-inline-block float-right mr-0"
            style="width: 90px;"
            persistent-hint
            outlined
          /> -->
        </material-card>
      </v-flex>
      <!-- 註解本段及 doSubmitMaterialDT 相關, 後續可以考慮整段刪掉, howard@20230419 -->
      <v-flex
        v-show="false"
        flex_table_width_auto
        xs12
        lg4>
        <material-card
          id="checkTable"
          :style="'max-width: calc(100vw - '+(Number(shrink)+32)+'px);'"
          title="🖥 DT-料件不良率標準列表"
          color="deep-orange"
          class="card-mix d-table-t0 no-data-left emoji"
          style="margin-top: 30px!important;">
          <!-- <v-select
            v-model="perPageRows"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="rowsAmount"
            class="mix-list v-text-field__slot d-inline-block float-right ml-5"
            style="width: 90px;"
            persistent-hint
            outlined
          /> -->
          <v-btn
            class="mix-btn black text-light"
            style="right: 1rem;"
            @click="doAddWindows(1)">
            <v-icon class="float-left">mdi-plus</v-icon>新增
          </v-btn>
          <v-data-table
            :headers="responseHeadersMaterialDT"
            :items="responseDataMaterialDT"
            class="height-row-3 table-align-left"
            multi-sort
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="subheading font-weight-light text-general text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <tr>
                <td>{{ item.name }}</td>
                <td>{{ (item.cfr*100).toFixed(2) }}%</td>
                <td>{{ item.warrantedMonth }}</td>
                <td>
                  <div>
                    <v-btn
                      class="ml-0 mr-2 font-weight-bold small"
                      color="deep-purple"
                      @click="doEditWindows(1, item.pk, item.name, item.cfr, item.warrantedMonth)">
                      <v-icon class="mr-2">mdi-pencil</v-icon>修改
                    </v-btn>
                    <v-btn
                      class="mx-0 font-weight-bold small"
                      color="red"
                      @click="doDelete(1, item.pk)">
                      <v-icon class="mr-2">mdi-close</v-icon>刪除
                    </v-btn>
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
          <!-- <div
            v-if="pagination.rowsPerPage != -1"
            class="text-xs-center pt-2 d-inline-block"
            style="margin-left: 0px;width: calc(100% - 140px);">
            <v-pagination
              v-model="pagination.page"
              :length="pages"/>
          </div>
          <v-autocomplete
            v-if="pagination.rowsPerPage != -1"
            v-model="pagination.page"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="pageslist"
            class="v-text-field__slot d-inline-block float-right mr-0"
            style="width: 90px;"
            persistent-hint
            outlined
          /> -->
        </material-card>
      </v-flex>
      <v-flex
        flex_table_width_auto
        xs12
        lg4>
        <material-card
          id="checkTable"
          :style="'max-width: calc(100vw - '+(Number(shrink)+32)+'px);'"
          title="💻 NR-料件不良率標準列表"
          color="deep-orange"
          class="card-mix d-table-t0 no-data-left emoji"
          style="margin-top: 30px!important;">
          <!-- <v-select
            v-model="perPageRows"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="rowsAmount"
            class="mix-list v-text-field__slot d-inline-block float-right ml-5"
            style="width: 90px;"
            persistent-hint
            outlined
          /> -->
          <v-btn
            class="mix-btn black text-light"
            style="right: 1rem;"
            @click="doAddWindows(2)">
            <v-icon class="float-left">mdi-plus</v-icon>新增
          </v-btn>
          <v-data-table
            :headers="responseHeadersMaterialNR"
            :items="responseDataMaterialNR"
            class="height-row-3 table-align-left"
            multi-sort
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="subheading font-weight-light text-general text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <tr>
                <td>{{ item.name }}</td>
                <td>{{ (item.cfr*100).toFixed(2) }}%</td>
                <td>{{ item.warrantedMonth }}</td>
                <td>
                  <div>
                    <v-btn
                      class="ml-0 mr-2 font-weight-bold small"
                      color="deep-purple"
                      @click="doEditWindows(2, item.pk, item.name, item.cfr, item.warrantedMonth)">
                      <v-icon class="mr-2">mdi-pencil</v-icon>修改
                    </v-btn>
                    <v-btn
                      class="mx-0 font-weight-bold small"
                      color="red"
                      @click="doDelete(2, item.pk)">
                      <v-icon class="mr-2">mdi-close</v-icon>刪除
                    </v-btn>
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
          <!-- <div
            v-if="pagination.rowsPerPage != -1"
            class="text-xs-center pt-2 d-inline-block"
            style="margin-left: 0px;width: calc(100% - 140px);">
            <v-pagination
              v-model="pagination.page"
              :length="pages"/>
          </div>
          <v-autocomplete
            v-if="pagination.rowsPerPage != -1"
            v-model="pagination.page"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="pageslist"
            class="v-text-field__slot d-inline-block float-right mr-0"
            style="width: 90px;"
            persistent-hint
            outlined
          /> -->
        </material-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="windowsForAddOrEdit"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <v-card
        max-width="25%"
        style="margin:0 auto;">
        <v-card-text>
          <v-alert
            v-model="windowsForAddOrEdit"
            class="mt-3 mb-3 border-0"
            color="deep-orange">
            <strong class="h4">{{ ((action==='add')?'新增':'修改')+((windows.type===0)?'年度':((windows.type===1) ? '💾DT-料件': '💻NR-料件')) }}不良率標準</strong>
          </v-alert>
          <v-flex
            flex-small
            d-flex
            align-items-center
            xs12
            lg12>
            <p class="m-0">{{ (windows.type===0) ? 'Year' : ('料件') }}</p>
            <v-autocomplete
              v-model="windows.name"
              :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
              :style="(windows.type===0)?'max-width: 75px;margin-right: 10px;':''"
              :items="(windows.type===0) ? typeMenu : ((windows.type===1) ? nameMenuDT : nameMenuNR)"
              label=""
              class="v-text-field__slot"
              persistent-hint
              outlined
            />

            <v-text-field
              v-if="windows.type===0"
              v-model="windows.year"
              :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
              label=""/>
          </v-flex>
          <v-flex
            flex-small
            d-flex
            align-items-center
            xs12
            lg12>
            <p class="m-0">CFR</p>
            <v-text-field
              v-model="windows.cfr"
              :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
              label=""
              min="0"
              type="number"/>
            <p style="max-width: 20px;margin: 0;margin-left: 10px;">%</p>
          </v-flex>
          <v-flex
            flex-small
            d-flex
            align-items-center
            xs12
            lg12>
            <p class="m-0">保固月</p>
            <v-text-field
              v-model="windows.warrantedMonth"
              :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
              label=""
              min="0"
              type="number"/>
          </v-flex>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="red"
            small
            @click="windowsForAddOrEdit=false">
            <v-icon class="mr-2">mdi-close</v-icon>取消
          </v-btn>
          <v-btn
            v-if="action==='add'"
            color="blue"
            small
            @click="doAdd()">
            <v-icon class="mr-2">mdi-plus</v-icon>新增
          </v-btn>
          <v-btn
            v-if="action==='edit'"
            color="blue"
            small
            @click="doEdit()">
            <v-icon class="mr-2">mdi-content-save</v-icon>儲存
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="processingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        PROCESSING 處理中
      </p>
    </v-dialog>
    <v-dialog
      v-model="loadingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :top="true"
      :color="snackbarColor">
      {{ snackbarMsg }}
      <v-btn
        dark
        flat
        @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import Loading from '@/assets/img/loading.gif'

export default {
  name: 'CfrBaselineSetting',
  data () {
    return {
      action: 'add',
      windowsForAddOrEdit: false,
      snackbar: false,
      snackbarColor: 'red',
      snackbarMsg: '',
      Loading,
      processingView: false,
      YearLoading: false,
      MaterialDTLoading: false,
      MaterialNRLoading: false,
      reasonCode: '',
      nameMenuDT: [],
      nameMenuNR: [],
      typeMenu: [
        { text: '', value: '' },
        { text: 'NR', value: 'NR' },
        { text: 'DT', value: 'DT' }
      ],
      windows: {
        type: -1,
        pk: 0,
        name: '',
        year: '',
        cfr: '',
        warrantedMonth: ''
      },
      // submit: false,
      responseDataYear: [],
      responseHeadersYear: [
        {
          align: '',
          class: '',
          sortable: true,
          text: 'Year',
          value: 'name'
        }, {
          align: '',
          class: '',
          sortable: true,
          text: 'CFR',
          value: 'cfr'
        }, {
          align: '',
          class: '',
          sortable: true,
          text: '保固月',
          value: 'warrantedMonth'
        }, {
          align: '',
          class: '',
          sortable: false,
          text: '操作',
          value: 'operation'
        }
      ],
      responseDataMaterialDT: [],
      responseHeadersMaterialDT: [
        {
          align: '',
          class: '',
          sortable: true,
          text: '料件',
          value: 'name'
        }, {
          align: '',
          class: '',
          sortable: true,
          text: 'CFR',
          value: 'cfr'
        }, {
          align: '',
          class: '',
          sortable: true,
          text: '保固月',
          value: 'warrantedMonth'
        }, {
          align: '',
          class: '',
          sortable: false,
          text: '操作',
          value: 'operation'
        }
      ],
      responseDataMaterialNR: [],
      responseHeadersMaterialNR: [
        {
          align: '',
          class: '',
          sortable: true,
          text: '料件',
          value: 'name'
        }, {
          align: '',
          class: '',
          sortable: true,
          text: 'CFR',
          value: 'cfr'
        }, {
          align: '',
          class: '',
          sortable: true,
          text: '保固月',
          value: 'warrantedMonth'
        }, {
          align: '',
          class: '',
          sortable: false,
          text: '操作',
          value: 'operation'
        }
      ]
      // rowsAmount: [25, 50, 100, { text: 'All', value: -1 }],
      // rowsAmount: [1, 25, 50, 100],
      // perPageRows: 25,
      // pagination: {
      //   page: 1,
      //   rowsPerPage: 25,
      //   sortBy: '',
      //   descending: null
      // },
      // pages: 1
    }
  },
  computed: {
    ...mapState('app', ['theme', 'shrink']),
    loadingView () {
      if (!this.YearLoading && !this.MaterialDTLoading && !this.MaterialNRLoading) {
        return false
      } else {
        return true
      }
    }
    // pages () {
    //   if(this.responseData.length > 0) {
    //     return this.pagination.rowsPerPage ? Math.ceil(this.responseData.length / this.pagination.rowsPerPage) : 10
    //   }
    // },
    // pageslist () {
    //   let i = 0
    //   let list = []
    //   if (this.pagination.rowsPerPage) {
    //     for (i = 0; i < Math.ceil(this.pages / this.pagination.rowsPerPage); i++) {
    //       list.push(i + 1)
    //     }
    //   }
    //   return list
    // }
  },
  watch: {
    // perPageRows (val) {
    //   if (val === -1) {
    //     if (confirm('完整顯示將可能導致效能降低，是否仍完整顯示?')) {
    //       this.pagination.rowsPerPage = val
    //       this.pages = Math.ceil(this.pages / this.pagination.rowsPerPage)
    //       this.pagination.page = 1
    //     } else {
    //       setTimeout(() => {
    //         this.perPageRows = this.pagination.rowsPerPage
    //       }, 100)
    //     }
    //   } else {
    //     this.pagination.rowsPerPage = val
    //     this.pages = Math.ceil(this.pages / this.pagination.rowsPerPage)
    //     this.pagination.page = 1
    //   }
    // },
    // pagination: {
    //   handler (newName, oldName) {
    //     if (this.submit) {
    //       this.doSubmit()
    //     }
    //   },
    //   immediate: true,
    //   deep: true
    // }
  },
  mounted () {
    this.$store.dispatch('getAuthorize', '/pqm/cscDataParsingMgmt/cfrBaselineSettingView')
      .then(response => {
        if (response.status === 'success' && response.data.accessible) {
          this.$http.get('/api/pqm/getSelectOptionData/partName/1').then(res => res.data)
            .then(res => {
              if (res.status === 'success') {
                // this.nameMenuDT = [{ text: 'All', value: 'All' }].concat(res.data)
                this.nameMenuDT = res.data
              }
            })
          this.$http.get('/api/pqm/getSelectOptionData/partName/2').then(res => res.data)
            .then(res => {
              if (res.status === 'success') {
                // this.nameMenuNR = [{ text: 'All', value: 'All' }].concat(res.data)
                this.nameMenuNR = res.data
              }
            })
          this.doSubmitYear()
          // this.doSubmitMaterialDT()
          this.doSubmitMaterialNR()
        } else if (response.errCode === 'E10000002') {
          const routeData = this.$router.resolve({ path: '/noEnter/E10000002' })
          localStorage.setItem('errormsg', response.errMsg)
          window.location.assign(routeData.href)
        } else {
          const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
          window.location.assign(routeData.href)
        }
      })
      .catch(err => {
        console.log(err)
        const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
        window.location.assign(routeData.href)
      })
  },
  methods: {
    doAddWindows (type) {
      this.action = 'add'
      this.windows = {
        type: type,
        pk: 0,
        name: '',
        year: '',
        cfr: '',
        warrantedMonth: ''
      }
      this.windowsForAddOrEdit = true
    },
    doEditWindows (type, pk, name, cfr, warrantedMonth) {
      this.action = 'edit'
      this.windows = {
        type: type,
        pk: pk,
        name: (type === 0) ? name.slice(0, name.length - 5) : name,
        year: (type === 0) ? name.slice(name.length - 4, name.length) : '',
        cfr: (cfr * 100).toFixed(2),
        warrantedMonth: warrantedMonth
      }
      this.windowsForAddOrEdit = true
    },
    doSubmitYear () {
      this.submit = true
      this.YearLoading = true
      const body = {
        // pageNum: this.pagination.page,
        // pageRows: this.pagination.rowsPerPage,
        // sortColumn: (this.pagination.descending !== null) ? this.pagination.sortBy : '',
        // sortType: this.pagination.descending ? 'DESC' : ((this.pagination.descending !== null) ? 'ASC' : ''),
        type: 0
      }
      this.$http.post('/api/pqm/cscDataParsingMgmt/cfrBaselineSettingList', body).then(res => res.data)
        .then(res => {
          this.YearLoading = false
          if (res.status === 'success') {
            this.responseDataYear = res.data
            // this.pages = res.data.totalRows
          }
        })
    },
    // doSubmitMaterialDT () {
    //   this.submit = true
    //   this.MaterialDTLoading = true
    //   const body = {
    //     // pageNum: this.pagination.page,
    //     // pageRows: this.pagination.rowsPerPage,
    //     // sortColumn: (this.pagination.descending !== null) ? this.pagination.sortBy : '',
    //     // sortType: this.pagination.descending ? 'DESC' : ((this.pagination.descending !== null) ? 'ASC' : ''),
    //     type: 1
    //   }
    //   this.$http.post('/api/pqm/cscDataParsingMgmt/cfrBaselineSettingList', body).then(res => res.data)
    //     .then(res => {
    //       this.MaterialDTLoading = false
    //       if (res.status === 'success') {
    //         this.responseDataMaterialDT = res.data
    //         // this.pages = res.data.totalRows
    //       }
    //     })
    // },
    doSubmitMaterialNR () {
      this.submit = true
      this.MaterialNRLoading = true
      const body = {
        // pageNum: this.pagination.page,
        // pageRows: this.pagination.rowsPerPage,
        // sortColumn: (this.pagination.descending !== null) ? this.pagination.sortBy : '',
        // sortType: this.pagination.descending ? 'DESC' : ((this.pagination.descending !== null) ? 'ASC' : ''),
        type: 2
      }
      this.$http.post('/api/pqm/cscDataParsingMgmt/cfrBaselineSettingList', body).then(res => res.data)
        .then(res => {
          this.MaterialNRLoading = false
          if (res.status === 'success') {
            this.responseDataMaterialNR = res.data
            // this.pages = res.data.totalRows
          }
        })
    },
    doAdd () {
      this.submit = true
      this.windowsForAddOrEdit = false
      this.processingView = true
      const body = {
        type: this.windows.type,
        name: (this.windows.type === 0) ? (this.windows.name + ' ' + this.windows.year) : this.windows.name,
        cfr: this.windows.cfr / 100,
        warrantedMonth: this.windows.warrantedMonth
      }
      this.$http.post('/api/pqm/cscDataParsingMgmt/cfrBaselineSetting', body).then(res => res.data)
        .then(res => {
          this.processingView = false
          if (res.status === 'success') {
            this.snackbar = true
            this.snackbarColor = 'green'
            this.snackbarMsg = '新增成功'
            if (this.windows.type === 0) {
              this.doSubmitYear()
            // } else if (this.windows.type === 1) {
            //   this.doSubmitMaterialDT()
            } else if (this.windows.type === 2) {
              this.doSubmitMaterialNR()
            }
          } else { // failure
            this.windowsForAddOrEdit = true
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
          }
        })
    },
    doEdit () {
      this.submit = true
      this.windowsForAddOrEdit = false
      this.processingView = true
      const body = {
        type: this.windows.type,
        name: (this.windows.type === 0) ? (this.windows.name + ' ' + this.windows.year) : this.windows.name,
        cfr: this.windows.cfr / 100,
        warrantedMonth: this.windows.warrantedMonth
      }
      this.$http.put(`/api/pqm/cscDataParsingMgmt/cfrBaselineSetting/${this.windows.pk}`, body).then(res => res.data)
        .then(res => {
          this.processingView = false
          if (res.status === 'success') {
            this.snackbar = true
            this.snackbarColor = 'green'
            this.snackbarMsg = '修改成功'
            if (this.windows.type === 0) {
              this.doSubmitYear()
            // } else if (this.windows.type === 1) {
            //   this.doSubmitMaterialDT()
            } else if (this.windows.type === 2) {
              this.doSubmitMaterialNR()
            }
          } else { // failure
            this.windowsForAddOrEdit = true
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
          }
        })
    },
    doDelete (type, pk) {
      if (confirm('是否確認刪除?')) {
        this.processingView = true
        this.$http.delete(`/api/pqm/cscDataParsingMgmt/cfrBaselineSetting/${pk}`).then(res => res.data)
          .then(res => {
            this.processingView = false
            if (res.status === 'success') {
              this.snackbar = true
              this.snackbarColor = 'green'
              this.snackbarMsg = '刪除成功'
              if (type === 0) {
                this.doSubmitYear()
              // } else if (type === 1) {
              //   this.doSubmitMaterialDT()
              } else if (type === 2) {
                this.doSubmitMaterialNR()
              }
            } else { // failure
              this.snackbar = true
              this.snackbarColor = 'red'
              this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
            }
          })
      }
    }
  }
}
</script>
<style>
</style>
