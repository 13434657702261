<template>
  <transition mode="out-in">
    <router-view/>
  </transition>
</template>

<style lang="scss">
@import "@/styles/index.scss";
a:hover {
    color: unset!important;
    text-decoration: unset!important;
}
/* Remove in 1.2 */
.v-datatable thead th.column.sortable i {
vertical-align: unset;
}
</style>
<script>

// checks to see if auth jwt token is valid or has expired, if it gets back 401 error log out
export default {

  created: function () {
    // console.log('created')
    // // window.addEventListener('beforeunload', e => this.beforeWindowUnload(e))
    this.$http.interceptors.request.use((config) => {
      // config.headers.common['Authorization'] = localStorage.getItem('token')
      if (localStorage.getItem('token')) {
        config.headers.common.Authorization = 'Bearer ' + localStorage.getItem('token')
      }
      return config
    // }, (error) => {
    //   console.log(error.config)
    })
    this.$http.interceptors.response.use((response) => {
      // if (response.data.errCode === 'E10000001' || response.data.errMsg === 'Access Token 驗證失敗') {
      if (response.data.errCode === 'E10000001' || response.data.errCode === 'E10000003' || response.data.errCode === 'E10000004' || response.data.errCode === 'E10000005') {
        localStorage.removeItem('token')
        localStorage.setItem('msg', response.data.errMsg + '(' + response.data.errCode + ')')
        const routeData = this.$router.resolve({ path: '/' })
        window.location.assign(routeData.href)
      }
      return response
    // }, (error) => {
    //   console.log(error.response)
    // if (error.response.status === 401) {
    // if (error.response.data.status === 'failure') {
    //   if (this.$store.getters.authorized) {
    //     this.$store.dispatch('refreshtoken')
    //   } else {
    //     return Promise.reject(error)
    //   }
    // } else {
    //   return Promise.reject(error)
    // }
    })
  },
  // beforeDestroy () {
  //   // alert('離開前') // 提交购物车的异步操作
  //   window.removeEventListener('beforeunload', e => this.beforeWindowUnload(e))
  // },
  // destroyed () {
  //   window.removeEventListener('beforeunload', e => this.beforeWindowUnload(e))
  // },
  // mounted: function () {
  //   let beforeUnloadTime = 0
  //   let unloadTime = 0
  //   // 窗口關閉或刷新時候的操作
  //   window.addEventListener('beforeunload', (event) => {
  //     // _beforeUnload_time = new Date().getTime()
  //     //  // Cancel the event as stated by the standard.
  //     // event.preventDefault();
  //     // // Chrome requires returnValue to be set.
  //     // event.returnValue = ''
  //   })

  //   window.addEventListener('unload', (event) => {
  //     unloadTime = new Date().getTime()
  //     console.log(unloadTime - beforeUnloadTime)
  //     // 關閉頁面通常小於100毫秒
  //     if (unloadTime - beforeUnloadTime <= 100) {
  //       // 業務代碼
  //       // this.stopUsing()
  //       confirm('確定要離開?')
  //     }
  //   })
  // },
  mounted () {
    // 監聽 window.onbeforeunload 事件
    window.onbeforeunload = () => {
      // 判斷是否是根目錄
      const currentPath = window.location.pathname
      if (currentPath === '/') {
        return undefined
      } else {
        // 判斷是否是網域內的路由
        const currentHost = window.location.host
        const toHost = window.location.href.split('/')[0]
        if (currentHost === toHost) {
          return undefined
        } else {
          const body = {
            url: window.location.pathname
          }
          this.$http.post('/api/system/browsingHistory', body).then(res => res.data)
            .then(res => {
              if (res.status === 'success') {
                this.responseDataTable = res.data
              }
            })
          return '確定要離開嗎？'
        }
      }
    }
  },
  methods: {
    handleRouteChange (to, from, next) {
      // 判斷是否是根目錄
      const currentPath = window.location.pathname
      const toPath = to.fullPath
      if (currentPath === '/' && toPath === '/') {
        next()
      } else {
        // 判斷是否是網域內的路由
        const currentHost = window.location.host
        const toHost = to.fullPath.split('/')[0]
        if (currentHost === toHost) {
          next()
        } else {
          const body = {
            url: window.location.pathname
          }
          this.$http.post('/api/system/browsingHistory', body).then(res => res.data)
            .then(res => {
              if (res.status === 'success') {
                this.responseDataTable = res.data
              }
            })
          // 跳出關閉確認視窗
          event.preventDefault()
          event.returnValue = '確定要離開嗎？'
        }
      }
    }
  }
  // methods: {
  //   beforeWindowUnload (e) {
  //     // 在这里执行你的逻辑，例如弹出确认消息
  //     // console.log(e)
  //     const body = {
  //       url: window.location.pathname
  //     }
  //     this.$http.post('/api/system/browsingHistory', body).then(res => res.data)
  //       .then(res => {
  //         if (res.status === 'success') {
  //           this.responseDataTable = res.data
  //         }
  //       })
  //     const confirmationMessage = '是否要離開QAMP?'
  //     e.returnValue = confirmationMessage // 要求浏览器显示确认消息
  //     // setTimeout(() => {
  //     //   console.log('留下來')
  //     //   alert('留下來')
  //     // }, 5000)
  //     // confirm('確定要離開???')
  //     // if (this.confirmStayInDirtyForm()) {
  //     //   // Cancel the event
  //     //   e.preventDefault()
  //     //   // Chrome requires returnValue to be set
  //     //   e.returnValue = ''
  //     // }
  //   }
  // }
}
</script>
