<template>
  <v-container
    fundamental
    fluid
    grid-list-xl
    pt-0
  >
    <v-layout wrap>
      <!-- <v-flex
        xs12
        lg12
      >
        <material-card
          title="工廠篩選"
          class="card-padding v-card__first"
          color="general"
        >
          <v-form>
            <v-container
              py-0
              class="mw-100">
              <v-layout wrap>
                <v-flex
                  xs12
                  lg3
                  text-xs-right
                >
                  <v-btn
                    class="mx-0 font-weight-light"
                    color="general"
                    @click="doSubmit()"
                  >
                    <v-icon class="mr-2">mdi-backup-restore</v-icon>查詢
                  </v-btn>
                  <v-btn
                    class="mr-0 ml-3 font-weight-light"
                    color="pink"
                    @click="doAddWindows()"
                  >
                    <v-icon class="mr-2">mdi-plus</v-icon>新增
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
      </v-flex>-->
      <v-flex
        v-if="!processingView"
        flex_table_width_auto
        xs12
        lg12>
        <material-card
          id="checkTable"
          :style="'max-width: calc(100vw - '+(Number(shrink)+32)+'px);'"
          title="符合篩選條件的工廠資訊"
          color="deep-orange"
          class="card-mix d-table-t0 no-data-left"
          style="margin-top: 30px!important;">
          <v-text-field
            v-model="keywordFilter"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            class="mix-input"
            style="right: 15rem;"
            append-icon="search"
            label="關鍵字過濾"
            single-line
            hide-details/>
          <!-- <v-select
            v-model="summary工廠類別"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="summary工廠類別Menu"
            class="mix-list v-text-field__slot d-inline-block float-right ml-5"
            style="width: 90px;"
            persistent-hint
            outlined
          /> -->
          <v-btn
            class="mix-btn mx-0 font-weight-light small"
            @click="doAddWindows()"
          >
            <v-icon class="mr-2">mdi-plus</v-icon>新增工廠
          </v-btn>
          <v-btn
            class="mix-btn mx-0 font-weight-light small"
            color="teal"
            style="right: 9.5rem;"
            @click="doUploadProductDataJob()">
            <v-icon class="mr-2">mdi-file-replace-outline</v-icon>轉檔
          </v-btn>
          <v-data-table
            :headers="responseHeaders"
            :items="responseData"
            :search="keywordFilter"
            class="height-row-3 table-align-left"
            multi-sort
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="subheading font-weight-light text-general text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <tr>
                <td>{{ item.code }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.fullName }}</td>
                <td>{{ item.uploadPath }}</td>
                <td>
                  <v-chip
                    v-for="(contacts,c) in item.contacts"
                    :key="'c'+c"
                    small>{{ contacts }}</v-chip>
                </td>
                <td>
                  <div>
                    <v-btn
                      class="ml-0 mr-2 font-weight-bold small"
                      color="deep-purple"
                      @click="doEditWindows(item.pk, item.code, item.name, item.fullName, item.uploadPath, item.contacts)">
                      <v-icon class="mr-2">mdi-pencil</v-icon>修改
                    </v-btn>
                    <!-- <v-btn
                      :disabled="item.processing"
                      class="mx-0 font-weight-bold small"
                      color="orange"
                      @click="doUploadProductDataJob(item.code)">
                      <v-icon class="mr-2">mdi-file-replace-outline</v-icon>轉檔
                    </v-btn> -->
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </material-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="windowsForAddOrEdit"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <v-card
        max-width="25%"
        style="margin:0 auto;">
        <v-card-text>
          <v-alert
            v-model="windowsForAddOrEdit"
            class="mt-3 mb-3 border-0"
            color="deep-orange">
            <strong class="h4">{{ (action==='add')?'新增':'修改' }}工廠資訊</strong>
          </v-alert>
          <v-flex
            flex-small
            d-flex
            align-items-center
            xs12
            lg12>
            <p class="m-0 w-25">工廠代號</p>
            <v-text-field
              v-model="windows.code"
              :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
              :disabled="action==='edit'"
              label=""
              class="w-75"/>
          </v-flex>
          <v-flex
            v-if="action==='edit'"
            flex-small
            d-flex
            align-items-center
            xs12
            lg12>
            <p class="m-0 w-25">工廠資料路徑</p>
            <v-text-field
              v-model="windows.uploadPath"
              :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
              :disabled="true"
              label=""
              class="w-75"/>
          </v-flex>
          <v-flex
            flex-small
            d-flex
            align-items-center
            xs12
            lg12>
            <p class="m-0 w-25">工廠簡稱</p>
            <v-text-field
              v-model="windows.name"
              :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
              placeholder="輸入工廠名稱...ex: Quanta"
              label=""
              class="w-75"/>
          </v-flex>
          <v-flex
            flex-small
            d-flex
            align-items-center
            xs12
            lg12>
            <p class="m-0 w-25">工廠名稱</p>
            <v-text-field
              v-model="windows.fullName"
              :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
              placeholder="輸入工廠名稱...ex: 廣達"
              label=""
              class="w-75"/>
          </v-flex>
          <v-flex
            flex-small
            d-flex
            align-items-center
            xs12
            lg12>
            <p class="m-0 w-25">聯絡人</p>
            <v-textarea
              v-model="windows.contacts"
              :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
              placeholder="輸入聯絡人, 請用分號隔開, ex: aaa@aaa.ccc;aaaa@ccc.ddd"
              label=""
              class="w-75"/>
          </v-flex>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="red"
            small
            @click="windowsForAddOrEdit=false">
            <v-icon class="mr-2">mdi-close</v-icon>取消
          </v-btn>
          <v-btn
            v-if="action==='add'"
            :disabled="windows.code==='' || windows.name==='' || windows.fullName===''"
            color="blue"
            small
            @click="doAdd()">
            <v-icon class="mr-2">mdi-plus</v-icon>新增
          </v-btn>
          <v-btn
            v-if="action==='edit'"
            :disabled="windows.name==='' || windows.fullName===''"
            color="blue"
            small
            @click="doEdit()">
            <v-icon class="mr-2">mdi-content-save</v-icon>儲存
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="processingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        PROCESSING 處理中
      </p>
    </v-dialog>
    <v-dialog
      v-model="loadingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :top="true"
      :color="snackbarColor">
      {{ snackbarMsg }}
      <v-btn
        dark
        flat
        @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import Loading from '@/assets/img/loading.gif'

export default {
  name: 'FactoryMgmtView',
  data () {
    return {
      action: 'add',
      windowsForAddOrEdit: false,
      snackbar: false,
      snackbarColor: 'red',
      snackbarMsg: '',
      Loading,
      loadingView: false,
      processingView: false,
      // summary工廠類別: '',
      // summary工廠類別Menu: [],
      windows: {
        pk: 0,
        code: '',
        name: '',
        fullName: '',
        uploadPath: '',
        contacts: ''
      },
      keywordFilter: '',
      responseData: [],
      responseHeaders: [
        {
          align: '',
          class: '',
          sortable: true,
          text: '工廠代號',
          value: 'code'
        }, {
          align: '',
          class: '',
          sortable: true,
          text: '工廠簡稱',
          value: 'name'
        }, {
          align: '',
          class: '',
          sortable: true,
          text: '工廠名稱',
          value: 'fullName'
        }, {
          align: '',
          class: '',
          sortable: true,
          text: '工廠上傳資料存放路徑',
          value: 'uploadPath'
        }, {
          align: '',
          class: '',
          sortable: false,
          text: '聯絡人',
          value: 'contacts'
        }, {
          align: '',
          class: '',
          sortable: false,
          text: '操作',
          value: 'operation'
        }
      ]
    }
  },
  computed: {
    ...mapState('app', ['theme', 'shrink'])
  },
  watch: {},
  mounted () {
    this.$store.dispatch('getAuthorize', '/product/fundamental/manufacturer/factoryMgmtView')
      .then(response => {
        if (response.status === 'success' && response.data.accessible) {
          // this.get工廠類別()
          this.doSubmit()
        } else if (response.errCode === 'E10000002') {
          const routeData = this.$router.resolve({ path: '/noEnter/E10000002' })
          localStorage.setItem('errormsg', response.errMsg)
          window.location.assign(routeData.href)
        } else {
          const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
          window.location.assign(routeData.href)
        }
      })
      .catch(err => {
        console.log(err)
        const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
        window.location.assign(routeData.href)
      })
  },
  methods: {
    // get工廠類別 () {
    //   this.$http.get('/api/product/fundamental/工廠類別').then(res => res.data)
    //     .then(res => {
    //       if (res.status === 'success') {
    //         this.summary工廠類別Menu = res.data
    //       }
    //     })
    // },
    doAddWindows () {
      this.action = 'add'
      this.windows = {
        pk: 0,
        code: '',
        name: '',
        fullName: '',
        uploadPath: '',
        contacts: ''
      }
      this.windowsForAddOrEdit = true
    },
    doEditWindows (pk, code, name, fullName, uploadPath, contacts) {
      this.action = 'edit'
      this.windows = {
        pk: pk,
        code: code,
        name: name,
        fullName: fullName,
        uploadPath: uploadPath,
        contacts: contacts.join(';')
      }
      this.windowsForAddOrEdit = true
    },
    doSubmit () {
      this.loadingView = true
      this.$http.get('/api/product/fundamental/factory').then(res => res.data)
        .then(res => {
          this.loadingView = false
          if (res.status === 'success') {
            this.responseData = res.data
          }
          return res
        })
    },
    doAdd () {
      this.windowsForAddOrEdit = false
      this.processingView = true
      const body = {
        pk: this.windows.pk,
        code: this.windows.code,
        name: this.windows.name,
        fullName: this.windows.fullName,
        uploadPath: this.windows.uploadPath,
        contacts: this.windows.contacts !== '' ? this.windows.contacts.split(';') : []
      }
      this.$http.post('/api/product/fundamental/factory', body).then(res => res.data)
        .then(res => {
          this.processingView = false
          if (res.status === 'success') {
            this.snackbar = true
            this.snackbarColor = 'green'
            this.snackbarMsg = '新增成功'
            this.doSubmit()
          } else { // failure
            this.windowsForAddOrEdit = true
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
          }
        })
    },
    doEdit () {
      this.windowsForAddOrEdit = false
      this.processingView = true
      const body = {
        pk: this.windows.pk,
        code: this.windows.code,
        name: this.windows.name,
        fullName: this.windows.fullName,
        uploadPath: this.windows.uploadPath,
        contacts: this.windows.contacts !== '' ? this.windows.contacts.split(';') : []
      }
      this.$http.put('/api/product/fundamental/factory', body).then(res => res.data)
        .then(res => {
          this.processingView = false
          if (res.status === 'success') {
            this.snackbar = true
            this.snackbarColor = 'green'
            this.snackbarMsg = '修改成功'
            this.doSubmit()
          } else { // failure
            this.windowsForAddOrEdit = true
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
          }
        })
    },
    doUploadProductDataJob () {
      if (confirm('是否確認啟動批次轉檔?')) {
        // this.$http.get(`/api/product/job/triggerParsingVendorUploadProductDataJob?factoryCode=${code}`).then(res => res.data)
        this.$http.get('/api/product/job/triggerParsingVendorUploadProductDataJob').then(res => res.data)
          .then(res => {
            this.processingView = false
            if (res.status === 'success') {
              // this.snackbar = true
              // this.snackbarColor = 'green'
              // this.snackbarMsg = res.data
              alert(res.data)
              this.doSubmit()
            } else { // failure
              this.windowsForAddOrEdit = true
              this.snackbar = true
              this.snackbarColor = 'red'
              this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
            }
          })
      }
    }
  }
}
</script>
<style>
</style>
