<template>
  <v-container
    krypart
    fluid
    grid-list-xl
    pt-0
  >
    <v-layout wrap>
      <v-flex
        xs12
      >
        <material-card
          title="面板料件生產週報區間"
          class="card-shrink card-padding v-card__first"
          color="general"
        >
          <v-form>
            <v-container
              py-0
              class="mw-100">
              <v-layout
                wrap
                align-items-center>
                <v-flex
                  xs12
                  sm6
                  lg2>
                  <v-autocomplete
                    v-model="summaryVendorName"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryVendorNameMenu"
                    label="廠商"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex
                  xs12
                  sm6
                  lg2>
                  <v-autocomplete
                    v-model="summaryPartNumber"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryPartNumberMenu"
                    label="料號"
                    class="v-text-field__slot"
                    color="white"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex lg2/>
                <v-flex
                  flex-small
                  d-flex
                  align-items-center
                  xs12
                  lg5>
                  <!--<v-autocomplete
                    v-model="summaryWeeksStart"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryAllYearWeeksList"
                    label="上傳檔案起始時間"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  >
                    <template v-slot:item="data">
                      <div
                        :class="{'weeks-list-data-tip':contentDateListWeeks.indexOf(data.item.value)!==-1}"
                        class="v-list__tile__content">
                        <div class="v-list__tile__title">
                          {{ data.item.text }}
                        </div>
                      </div>
                    </template>
                  </v-autocomplete>-->
                  <WeekPicker
                    :list-start-date="startdate"
                    :content-date-list="contentDateList"
                    :select-week="summaryWeeksStart"
                    label-text="上傳檔案起始時間"
                    value-type="sDay"
                    @selected-week="selectedWeekStart"/>
                  <p class="m-0 text-center px-3">到</p>
                  <!--<v-autocomplete
                    v-model="summaryWeeksEnd"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryAllYearWeeksList"
                    label="上傳檔案結束時間"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  >
                    <template v-slot:item="data">
                      <div
                        :class="{'weeks-list-data-tip':contentDateListWeeks.indexOf(data.item.value)!==-1}"
                        class="v-list__tile__content">
                        <div class="v-list__tile__title">
                          {{ data.item.text }}
                        </div>
                      </div>
                    </template>
                  </v-autocomplete>-->
                  <WeekPicker
                    :list-start-date="startdate"
                    :content-date-list="contentDateList"
                    :select-week="summaryWeeksEnd"
                    label-text="上傳檔案結束時間"
                    value-type="eDay"
                    @selected-week="selectedWeekEnd"/>
                </v-flex>
                <v-flex
                  xs12
                  lg1
                  text-xs-right
                >
                  <v-btn
                    :disabled="summaryWeeksStart===''||summaryWeeksEnd===''||summaryVendorName===''||summaryPartNumber===''"
                    class="mx-0 font-weight-light"
                    color="general"
                    @click="doSubmit()"
                  >
                    <v-icon class="mr-2">mdi-backup-restore</v-icon>分析
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
      </v-flex>
      <v-flex
        v-show="processingView"
        style="padding: 0px 0px 0px 0px!important;"
        selenium-grab
        p-0
        m-0
        layout
        wrap
        lg12>
        <v-flex
          xs12
          sm6
          lg4>
          <material-stats-card
            :value="responseVendorName"
            style="margin:10px 0 !important;"
            class="v-offset-none"
            icon="mdi-factory"
            title="廠商"/>
        </v-flex>
        <v-flex
          xs12
          sm6
          lg4>
          <material-stats-card
            :value="responsePartNumber"
            style="margin:10px 0 !important;"
            class="v-offset-none"
            icon="mdi-factory"
            title="料號"/>
        </v-flex>
        <v-flex
          xs12
          sm6
          lg4>
          <material-stats-card
            :value="responseDateStart +' 至 '+ responseDateEnd"
            style="margin:10px 0 !important;"
            color="indigo"
            icon="mdi-calendar"
            title="上傳資料日期區間"/>
        </v-flex>
        <v-flex
          v-if="systemTrigger && overloadMsg!==''"
          class="data-area no-data-page"
          xs12>
          <span v-html="'<p>錯誤訊息:'+overloadMsg+'</p>'"/>
        </v-flex>
        <v-flex
          v-if="summaryNoData && overloadMsg===''"
          class="data-area no-data-page"
          xs12>
          此日期區間查無資料
        </v-flex>
        <v-flex
          v-if="!summaryNoData && overloadMsg==='' && responseDataCompileFailList.length>0"
          lg4
        >
          <material-card
            title="不良製程警告"
            class="card-shrink card-padding v-card__first"
            color="pink-1"
          >
            <div class="fail-data-area lcd">
              <div
                v-for="(fail,f) in responseDataCompileFailList"
                :key="'f'+f">
                <p>{{ fail.type }}</p>
                <div>
                  <span
                    v-for="(list,l) in fail.list"
                    :key="'l'+l"
                    @click="doSubmitSingle(fail.type, list.name)">{{ list.name }}<strong v-if="list.class==='detail'">{{ list.no }}</strong><span>{{ list.value.toFixed(2) }}</span></span>
                </div>
              </div>
            </div>
          </material-card>
        </v-flex>
        <v-flex
          v-show="!summaryNoData && overloadMsg==='' && layoutVer===2"
          :class="{ lg12: responseDataCompileFailList.length===0, lg8: responseDataCompileFailList.length>0 }"
        >
          <v-layout wrap>
            <material-card
              title="Rework Rate"
              class="card-padding v-card__first"
              color="red-4"
            >
              <div class="data-area">
                <vue-c3 :handler="chartData.Chart_Reworkate"/>
              </div>
            </material-card>
            <material-card
              title="Yield Rate"
              class="card-padding v-card__first"
              color="red-3"
            >
              <div class="data-area">
                <vue-c3 :handler="chartData.Chart_YieldRate"/>
              </div>
            </material-card>
          </v-layout>
        </v-flex>
        <v-flex
          :class="{ lg12: (layoutVer===1 && responseDataCompileFailList.length===0) || layoutVer===2, lg8: (layoutVer===1 && responseDataCompileFailList.length>0), 'flex-row': (layoutVer===1 && responseDataCompileFailList.length===0) || layoutVer===2, 'flex-column': (layoutVer===1 && responseDataCompileFailList.length>0) }"
          class="d-flex"
          style="padding: 0 !important;">
          <v-flex
            v-if="!summaryNoData && overloadMsg===''"
            :class="{ lg10: (layoutVer===1 && responseDataCompileFailList.length===0) || layoutVer===2, lg12: (layoutVer===1 && responseDataCompileFailList.length>0) }"
          >
            <material-card
              title="POL ABI"
              class="card-mix card-shrink card-padding v-card__first"
              color="red-2"
            >
              <router-link
                :to="'/keypart/lcdModule/lcdModuleSingleProcessAnalyzer/'+responseDateStart+'/'+responseDateEnd+'/'+responseVendorName+'/'+responsePartNumber+'/POL_ABI'"
                class="mix-btn p-0"
                target="_blank">
                <v-btn
                  class="black text-light mx-0">
                  <v-icon class="float-left mr-1">mdi-eye</v-icon>檢視
                </v-btn>
              </router-link>
              <div
                v-if="responseCpkData.POL_ABI.length>0"
                class="data-area pol-abi">
                <div
                  v-for="(group,g) in responseCpkData.POL_ABI"
                  :key="'g'+g"
                  class="data-group">
                  <div class="data-group-name">{{ group.buttonCode.toUpperCase() }}</div>
                  <div class="data-set">
                    <div
                      v-for="(item,i) in group.item"
                      :key="'i'+i"
                      :class="{ 'ordinary':doCpkGrade(item.value),'bad':!doCpkGrade(item.value) }"
                      class="data-box">
                      <div class="data-item">
                        <span class="data-item-name">{{ item.testItemName.toUpperCase() }}</span>
                        <span
                          class="data-item-value"
                          style="cursor: pointer;"
                          @click="doSubmitSingle('POL_ABI', item.testItemName)">{{ item.value.toFixed(2) }}</span>
                        <span class="data-item-detail">{{ doCpkGradeName(item.value) }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-else
                class="data-area no-data">
                此日期區間查無資料
              </div>
            </material-card>
          </v-flex>
          <v-flex
            v-if="!summaryNoData && overloadMsg===''"
            :class="{ lg2: (layoutVer===1 && responseDataCompileFailList.length===0) || layoutVer===2, lg3: (layoutVer===1 && responseDataCompileFailList.length>0) }"
          >
            <material-card
              title="VCOM"
              class="card-mix card-shrink card-padding v-card__first"
              color="red-1"
            >
              <router-link
                :to="'/keypart/lcdModule/lcdModuleSingleProcessAnalyzer/'+responseDateStart+'/'+responseDateEnd+'/'+responseVendorName+'/'+responsePartNumber+'/VCOM'"
                class="mix-btn p-0"
                target="_blank">
                <v-btn
                  class="black text-light mx-0">
                  <v-icon class="float-left mr-1">mdi-eye</v-icon>檢視
                </v-btn>
              </router-link>
              <div
                v-if="responseCpkData.VCOM.length>0"
                class="data-area key-size VCOM">
                <div
                  v-for="(group,g) in responseCpkData.VCOM"
                  :key="'g'+g"
                  class="data-group">
                  <div
                    :class="{ 'ordinary':doCpkGrade(group.item[0].value),'bad':!doCpkGrade(group.item[0].value) }"
                    class="data-group-name">
                    <span>{{ group.buttonCode }}</span>CPK
                    <p
                      class="cpk"
                      @click="doSubmitSingle('VCOM', group.buttonCode)">{{ group.item[0].value.toFixed(2) }}</p>
                    <p class="lv">{{ doCpkGradeName(group.item[0].value) }}</p>
                  </div>
                </div>
              </div>
              <div
                v-else
                class="data-area no-data">
                此日期區間查無資料
              </div>
            </material-card>
          </v-flex>
        </v-flex>
        <v-flex
          v-if="!summaryNoData && overloadMsg===''"
          lg6
        >
          <material-card
            title="COG_bonding"
            class="card-mix card-shrink card-padding v-card__first"
            color="orange-4"
          >
            <router-link
              :to="'/keypart/lcdModule/lcdModuleSingleProcessAnalyzer/'+responseDateStart+'/'+responseDateEnd+'/'+responseVendorName+'/'+responsePartNumber+'/COG_bonding'"
              class="mix-btn p-0"
              target="_blank">
              <v-btn
                class="black text-light mx-0">
                <v-icon class="float-left mr-1">mdi-eye</v-icon>檢視
              </v-btn>
            </router-link>
            <div
              v-if="responseCpkData.COG_bonding.length>0"
              class="data-area cog">
              <div
                v-for="(group,g) in responseCpkData.COG_bonding"
                :key="'g'+g"
                :class="{'main':!group.buttonCode}"
                class="data-group">
                <div class="data-group-name">{{ group.buttonCode?group.buttonCode.toUpperCase():'' }}</div>
                <div class="data-set">
                  <div
                    v-for="(item,i) in group.item"
                    :key="'i'+i"
                    :class="{ 'ordinary':doCpkGrade(item.value),'bad':!doCpkGrade(item.value),'x':(getNameTag(item.testItemName.toUpperCase(),'b-line',true).substr(0, 1)==='X'),'y':(getNameTag(item.testItemName.toUpperCase(),'b-line',true).substr(0, 1)==='Y') }"
                    class="data-box">
                    <div class="data-item">
                      <span class="data-item-name">{{ getNameTag(item.testItemName.toUpperCase(),'b-line',false) }}<span>{{ getNameTag(item.testItemName.toUpperCase(),'b-line',true) }}</span></span>
                      <span
                        class="data-item-value"
                        style="cursor: pointer;"
                        @click="doSubmitSingle('COG_bonding', item.testItemName)">
                        {{ item.value.toFixed(2) }}
                        <span class="data-item-detail">{{ doCpkGradeName(item.value) }}</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else
              class="data-area no-data">
              此日期區間查無資料
            </div>
          </material-card>
        </v-flex>
        <v-flex
          v-if="!summaryNoData && overloadMsg===''"
          lg6
        >
          <material-card
            title="FOG_bonding"
            class="card-mix card-shrink card-padding v-card__first"
            color="orange-3"
          >
            <router-link
              :to="'/keypart/lcdModule/lcdModuleSingleProcessAnalyzer/'+responseDateStart+'/'+responseDateEnd+'/'+responseVendorName+'/'+responsePartNumber+'/FOG_bonding'"
              class="mix-btn p-0"
              target="_blank">
              <v-btn
                class="black text-light mx-0">
                <v-icon class="float-left mr-1">mdi-eye</v-icon>檢視
              </v-btn>
            </router-link>
            <div
              v-if="responseCpkData.FOG_bonding.length>0"
              class="data-area cog fog">
              <div
                v-for="(group,g) in responseCpkData.FOG_bonding"
                :key="'g'+g"
                :class="{'main':!group.buttonCode}"
                class="data-group">
                <div class="data-group-name">{{ group.buttonCode?group.buttonCode.toUpperCase():'' }}</div>
                <div class="data-set">
                  <div
                    v-for="(item,i) in group.item"
                    :key="'i'+i"
                    :class="{ 'ordinary':doCpkGrade(item.value),'bad':!doCpkGrade(item.value),'x':(getNameTag(item.testItemName.toUpperCase(),'b-line',true).substr(0, 1)==='X'),'y':(getNameTag(item.testItemName.toUpperCase(),'b-line',true).substr(0, 1)==='Y') }"
                    class="data-box">
                    <div class="data-item">
                      <span class="data-item-name">{{ getNameTag(item.testItemName.toUpperCase(),'b-line',false) }}<span>{{ getNameTag(item.testItemName.toUpperCase(),'b-line',true) }}</span></span>
                      <span
                        class="data-item-value"
                        style="cursor: pointer;"
                        @click="doSubmitSingle('FOG_bonding', item.testItemName)">
                        {{ item.value.toFixed(2) }}
                        <span class="data-item-detail">{{ doCpkGradeName(item.value) }}</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else
              class="data-area no-data">
              此日期區間查無資料
            </div>
          </material-card>
        </v-flex>
        <v-flex
          v-if="!summaryNoData && overloadMsg===''"
          lg4
        >
          <material-card
            title="FOB_bonding"
            class="card-mix card-shrink card-padding v-card__first"
            color="orange-2"
          >
            <router-link
              :to="'/keypart/lcdModule/lcdModuleSingleProcessAnalyzer/'+responseDateStart+'/'+responseDateEnd+'/'+responseVendorName+'/'+responsePartNumber+'/FOB_bonding'"
              class="mix-btn p-0"
              target="_blank">
              <v-btn
                class="black text-light mx-0">
                <v-icon class="float-left mr-1">mdi-eye</v-icon>檢視
              </v-btn>
            </router-link>
            <div
              v-if="responseCpkData.FOB_bonding.length>0"
              class="data-area fob">
              <div
                v-for="(group,g) in responseCpkData.FOB_bonding"
                :key="'g'+g"
                class="data-group">
                <div class="data-group-name">{{ group.buttonCode }}</div>
                <div class="data-set">
                  <div
                    v-for="(item,i) in group.item"
                    :key="'i'+i"
                    :class="{ 'ordinary':doCpkGrade(item.value),'bad':!doCpkGrade(item.value),'x':(getNameTag(item.testItemName.toUpperCase(),'brackets',true).substr(0, 1)==='X'),'y':(getNameTag(item.testItemName.toUpperCase(),'brackets',true).substr(0, 1)==='Y') }"
                    class="data-box">
                    <div class="data-item">
                      <span class="data-item-name">{{ getNameTag(item.testItemName.toUpperCase(),'brackets',false) }}<span>{{ getNameTag(item.testItemName.toUpperCase(),'brackets',true) }}</span></span>
                      <span
                        class="data-item-value"
                        style="cursor: pointer;"
                        @click="doSubmitSingle('FOB_bonding', item.testItemName)">
                        {{ item.value.toFixed(2) }}
                        <span class="data-item-detail">{{ doCpkGradeName(item.value) }}</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else
              class="data-area no-data">
              此日期區間查無資料
            </div>
          </material-card>
        </v-flex>
        <v-flex
          v-if="!summaryNoData && overloadMsg===''"
          lg6
        >
          <material-card
            title="BL_Assy"
            class="card-mix card-shrink card-padding v-card__first"
            color="orange-1"
          >
            <router-link
              :to="'/keypart/lcdModule/lcdModuleSingleProcessAnalyzer/'+responseDateStart+'/'+responseDateEnd+'/'+responseVendorName+'/'+responsePartNumber+'/BL_Assy'"
              class="mix-btn p-0"
              target="_blank">
              <v-btn
                class="black text-light mx-0">
                <v-icon class="float-left mr-1">mdi-eye</v-icon>檢視
              </v-btn>
            </router-link>
            <div
              v-if="responseCpkData.BL_Assy.length>0"
              class="data-area bl-assy">
              <table class="keypart-chart-table statistic-table">
                <tbody>
                  <tr>
                    <td/>
                    <td>
                      <div
                        :class="{ 'ordinary':doCpkGrade(responseCpkData.BL_Assy[0].item[3].value),'bad':!doCpkGrade(responseCpkData.BL_Assy[0].item[3].value),'nodata':responseCpkData.BL_Assy[0].item[3].value===null }"
                        class="data-item y">
                        <span class="title">{{ responseCpkData.BL_Assy[0].item[3].testItemName }}</span>
                        <p
                          v-if="responseCpkData.BL_Assy[0].item[3].value!==null"
                          class="cpk"
                          @click="doSubmitSingle('BL_Assy', responseCpkData.BL_Assy[0].item[3].testItemName)">{{ responseCpkData.BL_Assy[0].item[3].value.toFixed(2) }}</p>
                        <p
                          v-else
                          class="cpk">N/A</p>
                        <span
                          v-if="responseCpkData.BL_Assy[0].item[3].value!==null"
                          class="lv">{{ doCpkGradeName(responseCpkData.BL_Assy[0].item[3].value) }}</span>
                      </div>
                    </td>
                    <td>
                      <div
                        :class="{ 'ordinary':doCpkGrade(responseCpkData.BL_Assy[0].item[2].value),'bad':!doCpkGrade(responseCpkData.BL_Assy[0].item[2].value),'nodata':responseCpkData.BL_Assy[0].item[2].value===null }"
                        class="data-item y">
                        <span class="title">{{ responseCpkData.BL_Assy[0].item[2].testItemName }}</span>
                        <p
                          v-if="responseCpkData.BL_Assy[0].item[2].value!==null"
                          class="cpk"
                          @click="doSubmitSingle('BL_Assy', responseCpkData.BL_Assy[0].item[2].testItemName)">{{ responseCpkData.BL_Assy[0].item[2].value.toFixed(2) }}</p>
                        <p
                          v-else
                          class="cpk">N/A</p>
                        <span
                          v-if="responseCpkData.BL_Assy[0].item[2].value!==null"
                          class="lv">{{ doCpkGradeName(responseCpkData.BL_Assy[0].item[2].value) }}</span>
                      </div>
                    </td>
                    <td/>
                  </tr>
                  <tr>
                    <td>
                      <div
                        :class="{ 'ordinary':doCpkGrade(responseCpkData.BL_Assy[0].item[4].value),'bad':!doCpkGrade(responseCpkData.BL_Assy[0].item[4].value),'nodata':responseCpkData.BL_Assy[0].item[4].value===null }"
                        class="data-item x">
                        <span class="title">{{ responseCpkData.BL_Assy[0].item[4].testItemName }}</span>
                        <p
                          v-if="responseCpkData.BL_Assy[0].item[4].value!==null"
                          class="cpk"
                          @click="doSubmitSingle('BL_Assy', responseCpkData.BL_Assy[0].item[4].testItemName)">{{ responseCpkData.BL_Assy[0].item[4].value.toFixed(2) }}</p>
                        <p
                          v-else
                          class="cpk">N/A</p>
                        <span
                          v-if="responseCpkData.BL_Assy[0].item[4].value!==null"
                          class="lv">{{ doCpkGradeName(responseCpkData.BL_Assy[0].item[4].value) }}</span>
                      </div>
                    </td>
                    <td
                      class="pwb"
                      colspan="2"
                      rowspan="2">
                      <div/>
                    </td>
                    <td>
                      <div
                        :class="{ 'ordinary':doCpkGrade(responseCpkData.BL_Assy[0].item[1].value),'bad':!doCpkGrade(responseCpkData.BL_Assy[0].item[1].value),'nodata':responseCpkData.BL_Assy[0].item[1].value===null }"
                        class="data-item x">
                        <span class="title">{{ responseCpkData.BL_Assy[0].item[1].testItemName }}</span>
                        <p
                          v-if="responseCpkData.BL_Assy[0].item[1].value!==null"
                          class="cpk"
                          @click="doSubmitSingle('BL_Assy', responseCpkData.BL_Assy[0].item[1].testItemName)">{{ responseCpkData.BL_Assy[0].item[1].value.toFixed(2) }}</p>
                        <p
                          v-else
                          class="cpk">N/A</p>
                        <span
                          v-if="responseCpkData.BL_Assy[0].item[1].value!==null"
                          class="lv">{{ doCpkGradeName(responseCpkData.BL_Assy[0].item[1].value) }}</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div
                        :class="{ 'ordinary':doCpkGrade(responseCpkData.BL_Assy[0].item[5].value),'bad':!doCpkGrade(responseCpkData.BL_Assy[0].item[5].value),'nodata':responseCpkData.BL_Assy[0].item[5].value===null }"
                        class="data-item x">
                        <span class="title">{{ responseCpkData.BL_Assy[0].item[5].testItemName }}</span>
                        <p
                          v-if="responseCpkData.BL_Assy[0].item[5].value!==null"
                          class="cpk"
                          @click="doSubmitSingle('BL_Assy', responseCpkData.BL_Assy[0].item[5].testItemName)">{{ responseCpkData.BL_Assy[0].item[5].value.toFixed(2) }}</p>
                        <p
                          v-else
                          class="cpk">N/A</p>
                        <span
                          v-if="responseCpkData.BL_Assy[0].item[5].value!==null"
                          class="lv">{{ doCpkGradeName(responseCpkData.BL_Assy[0].item[5].value) }}</span>
                      </div>
                    </td>
                    <td>
                      <div
                        :class="{ 'ordinary':doCpkGrade(responseCpkData.BL_Assy[0].item[0].value),'bad':!doCpkGrade(responseCpkData.BL_Assy[0].item[0].value),'nodata':responseCpkData.BL_Assy[0].item[0].value===null }"
                        class="data-item x">
                        <span class="title">{{ responseCpkData.BL_Assy[0].item[0].testItemName }}</span>
                        <p
                          v-if="responseCpkData.BL_Assy[0].item[0].value!==null"
                          class="cpk"
                          @click="doSubmitSingle('BL_Assy', responseCpkData.BL_Assy[0].item[0].testItemName)">{{ responseCpkData.BL_Assy[0].item[0].value.toFixed(2) }}</p>
                        <p
                          v-else
                          class="cpk">N/A</p>
                        <span
                          v-if="responseCpkData.BL_Assy[0].item[0].value!==null"
                          class="lv">{{ doCpkGradeName(responseCpkData.BL_Assy[0].item[0].value) }}</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td/>
                    <td>
                      <div
                        :class="{ 'ordinary':doCpkGrade(responseCpkData.BL_Assy[0].item[6].value),'bad':!doCpkGrade(responseCpkData.BL_Assy[0].item[6].value),'nodata':responseCpkData.BL_Assy[0].item[6].value===null }"
                        class="data-item y">
                        <span class="title">{{ responseCpkData.BL_Assy[0].item[6].testItemName }}</span>
                        <p
                          v-if="responseCpkData.BL_Assy[0].item[6].value!==null"
                          class="cpk"
                          @click="doSubmitSingle('BL_Assy', responseCpkData.BL_Assy[0].item[6].testItemName)">{{ responseCpkData.BL_Assy[0].item[6].value.toFixed(2) }}</p>
                        <p
                          v-else
                          class="cpk">N/A</p>
                        <span
                          v-if="responseCpkData.BL_Assy[0].item[6].value!==null"
                          class="lv">{{ doCpkGradeName(responseCpkData.BL_Assy[0].item[6].value) }}</span>
                      </div>
                    </td>
                    <td>
                      <div
                        :class="{ 'ordinary':doCpkGrade(responseCpkData.BL_Assy[0].item[7].value),'bad':!doCpkGrade(responseCpkData.BL_Assy[0].item[7].value),'nodata':responseCpkData.BL_Assy[0].item[7].value===null }"
                        class="data-item y">
                        <span class="title">{{ responseCpkData.BL_Assy[0].item[7].testItemName }}</span>
                        <p
                          v-if="responseCpkData.BL_Assy[0].item[7].value!==null"
                          class="cpk"
                          @click="doSubmitSingle('BL_Assy', responseCpkData.BL_Assy[0].item[7].testItemName)">{{ responseCpkData.BL_Assy[0].item[7].value.toFixed(2) }}</p>
                        <p
                          v-else
                          class="cpk">N/A</p>
                        <span
                          v-if="responseCpkData.BL_Assy[0].item[7].value!==null"
                          class="lv">{{ doCpkGradeName(responseCpkData.BL_Assy[0].item[7].value) }}</span>
                      </div>
                    </td>
                    <td/>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              v-else
              class="data-area no-data">
              此日期區間查無資料
            </div>
          </material-card>
        </v-flex>
        <v-flex
          v-if="!summaryNoData && overloadMsg===''"
          lg2
        >
          <material-card
            title="Key_Size"
            class="card-mix card-shrink card-padding v-card__first"
            color="yellow-4"
          >
            <router-link
              :to="'/keypart/lcdModule/lcdModuleSingleProcessAnalyzer/'+responseDateStart+'/'+responseDateEnd+'/'+responseVendorName+'/'+responsePartNumber+'/Key_Size'"
              class="mix-btn p-0"
              target="_blank">
              <v-btn
                class="black text-light mx-0">
                <v-icon class="float-left mr-1">mdi-eye</v-icon>檢視
              </v-btn>
            </router-link>
            <div
              v-if="responseCpkData.Key_Size.length>0"
              class="data-area key-size">
              <div
                v-for="(group,g) in responseCpkData.Key_Size"
                :key="'g'+g"
                class="data-group">
                <div
                  :class="{ 'ordinary':doCpkGrade(group.item[0].value),'bad':!doCpkGrade(group.item[0].value) }"
                  class="data-group-name">
                  <span>{{ group.buttonCode }}</span>CPK
                  <p
                    class="cpk"
                    @click="doSubmitSingle('Key_Size', group.buttonCode)">{{ group.item[0].value.toFixed(2) }}</p>
                  <p class="lv">{{ doCpkGradeName(group.item[0].value) }}</p>
                </div>
              </div>
            </div>
            <div
              v-else
              class="data-area no-data">
              此日期區間查無資料
            </div>
          </material-card>
        </v-flex>
        <v-flex
          v-if="!summaryNoData && overloadMsg===''"
          lg12
        >
          <material-card
            title="Optics"
            class="card-mix card-shrink card-padding v-card__first"
            color="yellow-3"
          >
            <router-link
              :to="'/keypart/lcdModule/lcdModuleSingleProcessAnalyzer/'+responseDateStart+'/'+responseDateEnd+'/'+responseVendorName+'/'+responsePartNumber+'/Optics'"
              class="mix-btn p-0"
              target="_blank">
              <v-btn
                class="black text-light mx-0">
                <v-icon class="float-left mr-1">mdi-eye</v-icon>檢視
              </v-btn>
            </router-link>
            <div
              v-if="responseCpkData.Optics.length>0"
              class="data-area cog optics">
              <div
                v-for="(group,g) in responseCpkData.Optics"
                :key="'g'+g"
                :style="!group.buttonCode?'margin: auto;':''"
                :class="{'main':!group.buttonCode}"
                class="data-group">
                <div class="data-group-name">
                  <span :style="'width:20px;height:20px;position:absolute;left:-10px;border-radius:50%;background-color:'+getNameTag(group.buttonCode?group.buttonCode:'','space',true)+';'"/>
                  {{ group.buttonCode?group.buttonCode.toUpperCase():'' }}
                </div>
                <div class="data-set adjust-height">
                  <div
                    v-for="(item,i) in group.item"
                    :key="'i'+i"
                    :style="!group.buttonCode?('height: '+ (230 / group.item.length) +'px;'):''"
                    :class="{ 'ordinary':doCpkGrade(item.value),'bad':!doCpkGrade(item.value),'x':(getNameTag(item.testItemName.toUpperCase(),'space',true).substr(0, 1)==='X'),'y':(getNameTag(item.testItemName.toUpperCase(),'space',true).substr(0, 1)==='Y') }"
                    class="data-box">
                    <div class="data-item">
                      <span class="data-item-name">{{ getNameTag(item.testItemName.toUpperCase(),'space',false) }}<span>{{ getNameTag(item.testItemName.toUpperCase(),'space',true) }}</span></span>
                      <span
                        class="data-item-value"
                        style="cursor: pointer;"
                        @click="doSubmitSingle('Optics', item.testItemName)">
                        {{ item.value.toFixed(2) }}
                        <span class="data-item-detail">{{ doCpkGradeName(item.value) }}</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else
              class="data-area no-data">
              此日期區間查無資料
            </div>
          </material-card>
        </v-flex>
        <v-flex
          v-show="!summaryNoData && overloadMsg==='' && layoutVer===2"
          lg4
        >
          <material-card
            title="Particle Measure Array"
            class="card-padding v-card__first"
            color="yellow-2"
          >
            <div class="data-area">
              <vue-c3 :handler="chartData.Chart_ParticleMeasure_Array"/>
            </div>
          </material-card>
        </v-flex>
        <v-flex
          v-show="!summaryNoData && overloadMsg==='' && layoutVer===2"
          lg4
        >
          <material-card
            title="Particle Measure Assembly"
            class="card-padding v-card__first"
            color="yellow-1"
          >
            <div class="data-area">
              <vue-c3 :handler="chartData.Chart_ParticleMeasure_Assembly"/>
            </div>
          </material-card>
        </v-flex>
        <v-flex
          v-show="!summaryNoData && overloadMsg==='' && layoutVer===2"
          lg4
        >
          <material-card
            title="Particle Measure Bonding"
            class="card-padding v-card__first"
            color="yellowgreen-4"
          >
            <div class="data-area">
              <vue-c3 :handler="chartData.Chart_ParticleMeasure_Bonding"/>
            </div>
          </material-card>
        </v-flex>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="chartLineView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <v-card
        max-width="80%"
        style="margin:0 auto;">
        <v-card-text>
          <v-alert
            v-model="chartLineView"
            class="mt-3 mb-3 border-0"
            color="brown">
            <strong class="h4">{{ responseStandardData?responseStandardData.testItemName:'' }} ： {{ responseDateStart }} - {{ responseDateEnd }} ｜ {{ responseVendorName }} ｜ {{ responsePartNumber }}</strong>
          </v-alert>
          <div class="d-flex">
            <div class="w-100 m-3">
              <p class="text-center my-1">{{ chartViewTestItemName }}</p>
              <div class="keypart-chart-info">
                <div :class="{ 'ordinary':doCpkGrade(responseStandardData.cpk),'bad':!doCpkGrade(responseStandardData.cpk) }">
                  <span>CPK</span>
                  <p>{{ responseStandardData.cpk.toFixed(2) }}</p>
                  <div>
                    <span>{{ doCpkGradeName(responseStandardData.cpk) }}</span>
                  </div>
                </div>
                <div>
                  <span>SPEC</span>
                  <p>{{ responseStandardData.lowerSpecValue }}{{ (responseStandardData.lowerSpecValue!==null&&responseStandardData.upperSpecValue!==null)?' ~ ':'' }}{{ responseStandardData.upperSpecValue }}</p>
                  <div>
                    <span v-if="responseStandardData.lowerSpecValue!==null">LOWER</span>
                    <span v-if="responseStandardData.upperSpecValue!==null">UPPER</span>
                  </div>
                </div>
              </div>
              <table class="keypart-chart-table statistic-table">
                <thead>
                  <tr>
                    <th>sigma</th>
                    <th>value</th>
                    <th>histogram</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(row, r) in responseStandardData.distributionCoverageList">
                    <tr
                      v-if="row.define.type === 'Sigma'"
                      :key="'r'+r">
                      <td>{{ row.define.name }}</td>
                      <td>{{ row.define.value.toFixed(4) }}</td>
                      <td>{{ row.amount }}</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <vue-c3
              :handler="handler_line"
              class="keypart-chart-area"/>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="red"
            small
            @click="chartLineView=false">
            <v-icon class="mr-2">mdi-close</v-icon>關閉
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="processloadingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog>
    <v-dialog
      v-model="loadingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :top="true"
      :color="snackbarColor">
      {{ snackbarMsg }}
      <v-btn
        dark
        flat
        @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import qs from 'qs'
import Vue from 'vue'
import VueC3 from 'vue-c3'
import 'c3/c3.min.css'
import { mapState } from 'vuex'
import NormalDistribution from 'normal-distribution'
import Loading from '@/assets/img/loading.gif'
import WeekPicker from '@/components/module/datepicker/WeekPicker.vue'

export default {
  name: 'LcdModuleManufacturingReport',
  components: {
    VueC3,
    WeekPicker
  },
  data () {
    return {
      startdate: '2018-04-01',
      snackbar: false,
      snackbarColor: 'red',
      snackbarMsg: '',
      Loading,
      loadingView: false,
      overload: false,
      overloadMsg: '',
      processingView: false,
      summaryNoData: true,
      systemTrigger: false,
      layoutVer: 1,
      summaryLoading: {
        BL_Assy: false,
        COG_bonding: false,
        FOB_bonding: false,
        FOG_bonding: false,
        Key_Size: false,
        Optics: false,
        POL_ABI: false,
        VCOM: false,
        Chart_Reworkate: false,
        Chart_YieldRate: false,
        Chart_ParticleMeasure_Array: false,
        Chart_ParticleMeasure_Assembly: false,
        Chart_ParticleMeasure_Bonding: false,
        Problem_COG_bonding: false,
        Problem_FOG_bonding: false
      },
      summaryMonth: this.$moment(new Date()).format('YYYY-MM'),
      // summaryWeeksStart: Number(this.$moment(new Date()).format('YYYY')) * 100 + Math.ceil((((new Date(this.$moment(new Date()).format('YYYY-MM-DD')) - new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1))) / 86400000) + new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1)).getDay()) / 7) - parseInt((new Date(this.$moment(new Date()).format('YYYY') + '-01-01').getDay() + 5) / 6),
      // summaryWeeksEnd: Number(this.$moment(new Date()).format('YYYY')) * 100 + Math.ceil((((new Date(this.$moment(new Date()).format('YYYY-MM-DD')) - new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1))) / 86400000) + new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1)).getDay()) / 7) - parseInt((new Date(this.$moment(new Date()).format('YYYY') + '-01-01').getDay() + 5) / 6),
      summaryWeeksStart: this.$moment(new Date()).format('YYYY-MM-DD'),
      summaryWeeksEnd: this.$moment(new Date()).format('YYYY-MM-DD'),
      summaryVendorNameMenu: [],
      summaryPartNumberMenu: [],
      summaryVendorName: '',
      summaryPartNumber: '',
      // summaryYearWeeksList: [],
      // summaryAllYearWeeksList: [],
      tempVendorName: '',
      tempPartNumber: '',
      CpkGradeList: [],
      contentDateList: [],
      responseVendorName: '',
      responsePartNumber: '',
      responseDateStart: '',
      responseDateEnd: '',
      // responseWeeksStart: '',
      // responseWeeksEnd: '',
      responseCpkData: {
        BL_Assy: [],
        COG_bonding: [],
        FOB_bonding: [],
        FOG_bonding: [],
        Key_Size: [],
        Optics: [],
        POL_ABI: [],
        VCOM: []
      },
      responseProblemData: {
        COG_bonding: [],
        FOG_bonding: []
      },
      responseStandardData: {
        cpk: 0
      },
      chartData: {
        Chart_Reworkate: new Vue(),
        Chart_YieldRate: new Vue(),
        Chart_ParticleMeasure_Array: new Vue(),
        Chart_ParticleMeasure_Assembly: new Vue(),
        Chart_ParticleMeasure_Bonding: new Vue()
      },
      chartSetting: {
        Chart_Reworkate: {
          chartPath: 'reworkStatisticData',
          widthRate: 8,
          legendShow: false,
          queryType: null,
          type: 'line',
          types: {}, // {常態分佈: 'spline'}
          axes: {}, // {常態分佈: 'y2'}
          yKey: 'reworkRate',
          yType: 'rate',
          y2show: false
        },
        Chart_YieldRate: {
          chartPath: 'yieldStatisticData',
          widthRate: 8,
          legendShow: true,
          queryType: null,
          type: 'bar',
          types: { 'Appearance(%)': 'line', 'Function(%)': 'line' }, // {常態分佈: 'spline'}
          axes: { 'Appearance(%)': 'y2', 'Function(%)': 'y2' }, // {常態分佈: 'y2'}
          colors: { 'Appearance(%)': '#1e2131', 'Function(%)': '#3d2f22' },
          yKey: 'yieldRate',
          yType: 'rate',
          y2show: true
        },
        Chart_ParticleMeasure_Array: {
          chartPath: 'particleMeasureStatisticData',
          widthRate: 4,
          legendShow: false,
          queryType: 'Array',
          type: 'line',
          types: {}, // {常態分佈: 'spline'}
          axes: {}, // {常態分佈: 'y2'}
          yKey: 'value',
          yType: 'number',
          y2show: false
        },
        Chart_ParticleMeasure_Assembly: {
          chartPath: 'particleMeasureStatisticData',
          widthRate: 4,
          legendShow: false,
          queryType: 'Assembly',
          type: 'line',
          types: {}, // {常態分佈: 'spline'}
          axes: {}, // {常態分佈: 'y2'}
          yKey: 'value',
          yType: 'number',
          y2show: false
        },
        Chart_ParticleMeasure_Bonding: {
          chartPath: 'particleMeasureStatisticData',
          widthRate: 4,
          legendShow: false,
          queryType: 'Bonding',
          type: 'line',
          types: {}, // {常態分佈: 'spline'}
          axes: {}, // {常態分佈: 'y2'}
          yKey: 'value',
          yType: 'number',
          y2show: false
        }
      },
      chartLineView: false,
      chartLineViewNum: 0,
      chartLineViewName: '',
      chartViewTestItemName: '',
      barXaxisName: [],
      barXaxisValue: [],
      WeekList: ['日', '一', '二', '三', '四', '五', '六'],
      handler_line: new Vue()
    }
  },
  computed: {
    ...mapState('app', ['theme', 'shrink']),
    processloadingView () {
      if (!this.summaryLoading.BL_Assy && !this.summaryLoading.COG_bonding && !this.summaryLoading.FOB_bonding && !this.summaryLoading.FOG_bonding && !this.summaryLoading.Key_Size && !this.summaryLoading.Optics && !this.summaryLoading.POL_ABI && !this.summaryLoading.VCOM && !this.summaryLoading.Chart_Reworkate && !this.summaryLoading.Chart_YieldRate && !this.summaryLoading.Chart_ParticleMeasure_Array && !this.summaryLoading.Chart_ParticleMeasure_Assembly && !this.summaryLoading.Chart_ParticleMeasure_Bonding) {
        return false
      } else {
        return true
      }
    },
    responseDataCompileFailList () {
      const array = []
      if (this.layoutVer !== 2) {
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(this.responseCpkData)) {
          value.map(function (e) { return e.item }).forEach((n, nn) => {
            n.forEach((i, ii) => {
              if (i.value !== null && !this.doCpkGrade(i.value)) {
                if (array.map(function (e) { return e.type }).indexOf(key) === -1) {
                  array.push({ type: key, list: [{ name: i.testItemName, class: 'normal', no: null, value: i.value }] })
                } else {
                  array[array.map(function (e) { return e.type }).indexOf(key)].list.push({ name: i.testItemName, class: 'normal', no: null, value: i.value })
                }
              }
            })
          })
        }
      } else {
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(this.responseProblemData)) {
          value.forEach((i, ii) => {
            if (!this.doCpkGrade(i.cpk)) {
              if (array.map(function (e) { return e.type }).indexOf(key) === -1) {
                array.push({ type: key, list: [{ name: i.testItemName, class: 'detail', no: i.note.chipNo, value: i.cpk }] })
              } else {
                array[array.map(function (e) { return e.type }).indexOf(key)].list.push({ name: i.testItemName, class: 'detail', no: i.note.chipNo, value: i.cpk })
              }
            }
          })
        }
      }
      array.forEach((n, nn) => {
        array[nn].list = n.list.sort(function (a, b) { return a.name.localeCompare(b.name) })
      })
      return array
    },
    responseStandardUpperLower () {
      const array = []
      let ULindex = this.responseStandardData.upperSpecValue != null ? -0.5 : null
      let LLindex = this.responseStandardData.lowerSpecValue != null ? -0.5 : null
      this.responseStandardData.distributionCoverageList.map(function (e) {
        if (e.define.type === 'Sigma') {
          return e.define
        } else {
          return null
        }
      }).filter(Boolean).forEach((r, rr) => {
        if (this.responseStandardData.lowerSpecValue > r.value) {
          LLindex = rr + 0.5
        }
        if (this.responseStandardData.upperSpecValue >= r.value) {
          ULindex = rr + 0.5
        }
      })
      if (ULindex) {
        array.push({ value: ULindex, text: 'Upper Spec Value' })
      }
      if (LLindex) {
        array.push({ value: LLindex, text: 'Lower Spec Value' })
      }
      return array
    },
    options_line () {
      return {
        data: {
          x: 'x',
          columns: [
            this.barXaxisName,
            this.barXaxisStandard,
            this.barXaxisValue
          ],
          axes: {
            常態分佈: 'y2'
          },
          type: 'bar',
          types: {
            常態分佈: 'spline'
          }
        },
        axis: {
          x: {
            type: 'category',
            padding: {
              top: 0,
              right: 0.1,
              bottom: 0,
              left: 0.2
            }
          },
          y: {
            tick: {
              format: function (value) { return value }
            },
            label: {
              text: '統計次數',
              position: 'outer-middle'
            }
          },
          y2: {
            tick: {
              format: function (value) { return value }
            },
            label: {
              text: '常態分佈',
              position: 'outer-middle'
            },
            show: true
          }
        },
        tooltip: {
          order: function (t1, t2) {
            return t1.id < t2.id
          },
          contents: (d, value, ratio, id) => {
            return '<table class="c3-tooltip"><tbody><tr><th colspan="2">' + this.barXaxisName[d[1].index + 1] + '</th></tr><tr class="c3-tooltip-name--統計次數"><td class="name"><span style="background-color:#1f77b4"></span>統計次數</td><td class="value">' + d[1].value + '</td></tr></tbody></table>'
          }
          // format: {
          //   title: (d, value) => {
          //     return `${this.barXaxisName[d + 1]}`
          //   },
          //   value: (value, ratio, id) => {
          //     // if (id === '常態分佈') {
          //     //   return `${value.toFixed(3)}`
          //     // } else {
          //     //   return `${value}`
          //     // }
          //   }
          // }
          // order: 'asc'
        },
        grid: {
          x: {
            show: true,
            lines: this.responseStandardUpperLower
          },
          y: {
            show: true
          }
        },
        legend: {
          position: 'bottom',
          padding: 16,
          show: false
        },
        size: {
          width: ((window.innerWidth - this.shrink) * 0.5) - 64,
          height: 500
        },
        spline: {
          interpolation: {
            type: 'monotone'
          }
        }
      }
    }
    // contentDateListWeeks () {
    //   const array = []
    //   this.summaryYearWeeksList.forEach((n, nn) => {
    //     n.forEach((w, ww) => {
    //       if (ww === n.length - 1 && Number(this.$moment(w.start).format('DD')) > 25) {
    //         for (let d = new Date((Number(this.$moment(this.startdate).format('YYYY')) + nn) + '-' + this.$moment(w.start).format('MM-DD')).getTime(); d <= new Date((Number(this.$moment(this.startdate).format('YYYY')) + nn + 1) + '-' + this.$moment(w.end).format('MM-DD')).getTime(); d = d + 86400000) {
    //           // console.log(this.$moment(d).format('YYYY-MM-DD'))
    //           if (this.contentDateList.indexOf(this.$moment(d).format('YYYY-MM-DD')) !== -1) {
    //             array.push(Number((Number(this.$moment(this.startdate).format('YYYY')) + nn) + (ww < 10 ? '0' + String(ww) : String(ww))))
    //           }
    //         }
    //       }
    //       for (let d = new Date((Number(this.$moment(this.startdate).format('YYYY')) + nn) + '-' + this.$moment(w.start).format('MM-DD')).getTime(); d <= new Date((Number(this.$moment(this.startdate).format('YYYY')) + nn) + '-' + this.$moment(w.end).format('MM-DD')).getTime(); d = d + 86400000) {
    //         // console.log(this.$moment(d).format('YYYY-MM-DD'))
    //         if (this.contentDateList.indexOf(this.$moment(d).format('YYYY-MM-DD')) !== -1) {
    //           array.push(Number((Number(this.$moment(this.startdate).format('YYYY')) + nn) + (ww < 10 ? '0' + String(ww) : String(ww))))
    //         }
    //       }
    //     })
    //   })
    //   return array
    // }
  },
  watch: {
    processloadingView (val) {
      if (!val && !this.overload) {
        this.processingView = true
      } else if (!val && this.overload) {
        alert(this.overloadMsg)
      }
    },
    summaryVendorName (val) {
      this.getPartNumber(val)
    },
    summaryPartNumber (val) {
      this.contentDateList = []
      if (val) this.getContentDateList(this.summaryVendorName, val)
    },
    chartLineView (val) {
      if (val) {
        this.barXaxisName = ['x']
        this.barXaxisStandard = ['常態分佈']
        this.barXaxisValue = ['統計次數']
        if (this.responseStandardData.distributionCoverageList.length > 0) {
          this.responseStandardData.distributionCoverageList.forEach((r) => {
            if (r.define.type === 'Sigma') {
              this.barXaxisName.push(r.define.name)
              if (this.responseStandardData.standardDeviation === 0) {
                this.barXaxisStandard.push(r.amount.toFixed(3))
              } else {
                this.barXaxisStandard.push(new NormalDistribution(this.responseStandardData.average, this.responseStandardData.standardDeviation).pdf(r.define.value).toFixed(3))
              }
              this.barXaxisValue.push(r.amount)
            }
          })
        }
        if (this.chartLineViewNum === 0) {
          this.handler_line.$emit('init', this.options_line)
          this.chartLineViewNum++
        } else {
          this.handler_line.$emit('dispatch', chart => {
            const options = {
              columns: [
                this.barXaxisName,
                this.barXaxisStandard,
                this.barXaxisValue
              ]
            }
            chart.load(options)
            chart.xgrids(this.responseStandardUpperLower)
          })
        }
      } else {
        // this.handler_line.$emit('destroy')
      }
    }
  },
  mounted () {
    this.$store.dispatch('getAuthorize', '/keypart/lcdModule/lcdModuleManufacturingReport')
      .then(response => {
        if (response.status === 'success' && response.data.accessible) {
          JSON.parse(localStorage.getItem('moduleDataInitDate')).forEach((n) => {
            if (n.name === 'lcdModule') {
              this.startdate = n.value
            }
          })
          if (this.$route.params.searchWeeksStart && this.$route.params.searchWeeksEnd && this.$route.params.searchVendorName && this.$route.params.searchPartNumber) {
            this.summaryWeeksStart = this.$route.params.searchWeeksStart
            this.summaryWeeksEnd = this.$route.params.searchWeeksEnd
            this.tempVendorName = this.$route.params.searchVendorName
            this.tempPartNumber = this.$route.params.searchPartNumber
          }
          this.$http.get('/api/keypart/lcdModule/vendorName').then(res => res.data)
            .then(res => {
              if (res.status === 'success') {
                this.summaryVendorNameMenu = []
                res.data.forEach((n) => {
                  this.summaryVendorNameMenu.push({ text: n, value: n })
                })
                if (this.tempVendorName !== '') {
                  this.summaryVendorName = this.tempVendorName
                }
              }
            })
          // this.loadData()
        } else if (response.errCode === 'E10000002') {
          const routeData = this.$router.resolve({ path: '/noEnter/E10000002' })
          localStorage.setItem('errormsg', response.errMsg)
          window.location.assign(routeData.href)
        } else {
          const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
          window.location.assign(routeData.href)
        }
      })
      .catch(err => {
        console.log(err)
        const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
        window.location.assign(routeData.href)
      })
  },
  methods: {
    selectedWeekStart (val) {
      this.summaryWeeksStart = val
    },
    selectedWeekEnd (val) {
      this.summaryWeeksEnd = val
    },
    // async loadData () {
    //   this.loadingView = true
    //   await this.YearWeeksList()
    //   await this.AllYearWeeksList()
    // },
    // YearWeeksList () {
    //   const Arr = []
    //   const YearStart = this.$moment(this.startdate).format('YYYY')
    //   const YearEnd = this.$moment(new Date()).format('YYYY')
    //   let NewYear = false
    //   let YearEndWeek = 0

    //   for (let y = 0; y <= (YearEnd - YearStart + 1); y++) {
    //     Arr[y] = []
    //     let w = 0
    //     let n = 0
    //     let day = new Date(Number(YearStart) + y + '-01-01').getTime()
    //     if (y === 0 && this.$moment(this.startdate).format('MM-DD') !== '01-01') {
    //       day = new Date(Number(YearStart) + y + '-' + this.$moment(this.startdate).format('MM-DD')).getTime()
    //       w = Math.ceil((((new Date(this.$moment(this.startdate).format('YYYY-MM-DD')) - new Date(new Date(this.$moment(this.startdate).format('YYYY'), 0, 1))) / 86400000) + new Date(new Date(this.$moment(this.startdate).format('YYYY'), 0, 1)).getDay()) / 7)
    //       if (new Date(this.$moment(this.startdate).format('YYYY') + '-01-01').getDay() > 0) {
    //         w = w - 1
    //       }
    //       Arr[y][w] = { start: this.$moment(this.startdate).format('YYYY-MM-DD'), end: '' }
    //       if (new Date(this.$moment(this.startdate).format('YYYY-MM-DD')).getDay() === 6) {
    //         Arr[y][w].end = this.$moment(this.startdate).format('YYYY-MM-DD')
    //       } else if (new Date(this.$moment(this.startdate).format('YYYY-MM-DD')).getDay() === 0) {
    //         w = w - 1
    //       }
    //     } else if (new Date(Number(YearStart) + y + '-01-01').getDay() !== 0) {
    //       n = 6 - new Date(Number(YearStart) + y + '-01-01').getDay()
    //       Arr[y][1] = { start: '', end: '' }
    //     }
    //     for (let d = (day + (86400000 * n)); d <= (new Date(Number(YearStart) + y + '-12-31').getTime()); d = d + 86400000) {
    //       if (y > 0 && new Date(d).getDay() === 6) {
    //         if (Arr[y][1].start === '') {
    //           Arr[y - 1][YearEndWeek].end = this.$moment(d).format('YYYY-MM-DD')
    //           Arr[y][0] = { start: Arr[y - 1][YearEndWeek].start, end: this.$moment(d).format('YYYY-MM-DD') }
    //           w = 0
    //           YearEndWeek = w
    //           NewYear = true
    //         }
    //       }
    //       if (new Date(d).getDay() === 0) {
    //         w++
    //         YearEndWeek = w
    //         NewYear = false
    //         Arr[y][w] = { start: this.$moment(d).format('YYYY-MM-DD'), end: '' }
    //       } else if (!NewYear && new Date(d).getDay() === 6) {
    //         Arr[y][w].end = this.$moment(d).format('YYYY-MM-DD')
    //       }
    //     }
    //   }
    //   this.summaryYearWeeksList = Arr
    // },
    // AllYearWeeksList () {
    //   const Arr = []
    //   const YearStart = this.$moment(this.startdate).format('YYYY')
    //   let YearEnd = this.$moment(this.summaryMonth).format('YYYY')
    //   let LastYear = 0
    //   if (new Date(this.$moment(this.summaryMonth).format('YYYY') + '-01-01').getDay() > 0) {
    //     LastYear = 1
    //   }
    //   const WeeksEnd = Math.ceil((((new Date(this.$moment(new Date()).format('YYYY-MM-DD')) - new Date(new Date(this.$moment(this.summaryMonth).format('YYYY'), 0, 1))) / 86400000) + new Date(new Date(this.$moment(this.summaryMonth).format('YYYY'), 0, 1)).getDay()) / 7) - LastYear
    //   if (WeeksEnd === 0) {
    //     YearEnd--
    //   }
    //   for (let y = 0; y <= (YearEnd - YearStart); y++) {
    //     for (let w = 1; w < this.summaryYearWeeksList[y].length; w++) {
    //       if (y === (YearEnd - YearStart) && w === WeeksEnd) {
    //         Arr.push({ text: (Number(YearStart) + y) + 'w' + w + '( ' + this.$moment(this.summaryYearWeeksList[y][w].start).format('MM-DD') + ' ～ ' + this.$moment(this.summaryYearWeeksList[y][w].end).format('MM-DD') + ' )', value: (Number(YearStart) + y) * 100 + w })
    //         break
    //       }
    //       if (this.summaryYearWeeksList[y][w]) {
    //         Arr.push({ text: (Number(YearStart) + y) + 'w' + w + '( ' + this.$moment(this.summaryYearWeeksList[y][w].start).format('MM-DD') + ' ～ ' + this.$moment(this.summaryYearWeeksList[y][w].end).format('MM-DD') + ' )', value: (Number(YearStart) + y) * 100 + w })
    //       }
    //     }
    //   }
    //   Arr.sort(function (a, b) { return b.value - a.value })
    //   this.summaryAllYearWeeksList = Arr
    //   this.loadingView = false
    // },
    responseCpkDataCompileGroup (groupType, data) {
      const array = []
      const d = data.sort((a, b) => a.testItemName.localeCompare(b.testItemName))

      d.forEach((i, ii) => {
        if (array.map(function (e) { return e.buttonCode }).indexOf(i[groupType]) === -1) {
          array.push({ buttonCode: i[groupType], item: [i] })
        } else {
          array[array.map(function (e) { return e.buttonCode }).indexOf(i[groupType])].item.push(i)
        }
      })
      return array
    },
    getNameTag (val, type, isTag) {
      if (type === 'brackets') {
        if (isTag) {
          return val.substr((val.indexOf('(') + 1), (val.indexOf(')') - val.indexOf('(') - 1))
        } else {
          return val.substr(0, val.indexOf('('))
        }
      } else if (type === 'b-line') {
        if (isTag) {
          return val.substr((val.lastIndexOf('_') + 1), val.length)
        } else {
          return val.substr(0, (val.lastIndexOf('_') + 1))
        }
      } else if (type === 'space') {
        if (isTag) {
          return val.substr((val.lastIndexOf(' ') + 1), val.length)
        } else {
          return val.substr(0, (val.lastIndexOf(' ') + 1))
        }
      }
    },
    getCpkGradeList () {
      this.$http.get('/api/keypart/support/cpkEvaluationList').then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.CpkGradeList = res.data
          }
        })
    },
    doCpkGrade (cpk) {
      let result = false
      this.CpkGradeList.forEach(e => {
        if ((e.upperSpecLimit ? e.upperSpecLimit : 999999999999999) > cpk && cpk >= (e.lowerSpecLimit ? e.lowerSpecLimit : -999999999999999)) {
          result = (e.status === 'qualified')
        }
      })
      return result
    },
    doCpkGradeName (cpk) {
      let result = false
      this.CpkGradeList.forEach(e => {
        if ((e.upperSpecLimit ? e.upperSpecLimit : 999999999999999) > cpk && cpk >= (e.lowerSpecLimit ? e.lowerSpecLimit : -999999999999999)) {
          result = e.evaluation
        }
      })
      return result
    },
    getPartNumber (vendor) {
      const body = {
        vendorName: vendor
      }
      this.$http.get('/api/keypart/lcdModule/partNumber?' + qs.stringify(body)).then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.summaryPartNumberMenu = []
            this.summaryPartNumber = ''
            res.data.forEach((n) => {
              this.summaryPartNumberMenu.push({ text: n, value: n })
            })
            if (this.tempPartNumber !== '') {
              this.summaryPartNumber = this.tempPartNumber
              this.tempVendorName = ''
              this.tempPartNumber = ''
              this.doSubmit()
            }
          }
        })
    },
    getContentDateList (vendor, partNumber) {
      const body = {
        vendorName: vendor,
        partNumber: partNumber
      }
      this.$http.get('/api/keypart/lcdModule/uploadedFileDate?' + qs.stringify(body)).then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.contentDateList = res.data
          }
        })
    },
    async doSubmit () {
      if (this.summaryWeeksEnd < this.summaryWeeksStart) {
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '結尾時間不得早於起始時間'
      } else {
        this.processingView = false
        this.summaryNoData = true
        this.layoutVer = 1
        this.summaryLoading = {
          BL_Assy: true,
          COG_bonding: true,
          FOB_bonding: true,
          FOG_bonding: true,
          Key_Size: true,
          Optics: true,
          POL_ABI: true,
          VCOM: true,
          Chart_Reworkate: true,
          Chart_YieldRate: true,
          Chart_ParticleMeasure_Array: true,
          Chart_ParticleMeasure_Assembly: true,
          Chart_ParticleMeasure_Bonding: true,
          Problem_COG_bonding: true,
          Problem_FOG_bonding: true
        }
        this.overload = false
        this.overloadMsg = ''
        // let wwS = 0
        // let wwE = 0
        // if (Number.isInteger(this.summaryWeeksStart)) {
        //   wwS = String(this.summaryWeeksStart).substr(4)
        // } else {
        //   wwS = Math.ceil((((new Date(this.$moment(new Date()).format('YYYY-MM-DD')) - new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1))) / 86400000) + new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1)).getDay()) / 7) - parseInt((new Date(this.$moment(new Date()).format('YYYY') + '-01-01').getDay() + 5) / 6)
        // }
        // if (Number.isInteger(this.summaryWeeksEnd)) {
        //   wwE = String(this.summaryWeeksEnd).substr(4)
        // } else {
        //   wwE = Math.ceil((((new Date(this.$moment(new Date()).format('YYYY-MM-DD')) - new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1))) / 86400000) + new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1)).getDay()) / 7) - parseInt((new Date(this.$moment(new Date()).format('YYYY') + '-01-01').getDay() + 5) / 6)
        // }
        // const uploadedDateStart = this.$moment(this.summaryYearWeeksList[String(this.summaryWeeksStart).substr(0, 4) - this.$moment(this.startdate).format('YYYY')][Number(wwS)].start).format('YYYY-MM-DD')
        // const uploadedDateEnd = this.$moment(this.summaryYearWeeksList[String(this.summaryWeeksEnd).substr(0, 4) - this.$moment(this.startdate).format('YYYY')][Number(wwE)].end).format('YYYY-MM-DD')
        const uploadedDateStart = this.summaryWeeksStart
        const uploadedDateEnd = this.summaryWeeksEnd
        const vendorName = this.summaryVendorName
        const partNumber = this.summaryPartNumber
        this.responseCpkData = {
          BL_Assy: [],
          COG_bonding: [],
          FOB_bonding: [],
          FOG_bonding: [],
          Key_Size: [],
          Optics: [],
          POL_ABI: [],
          VCOM: []
        }
        this.responseProblemData = {
          COG_bonding: [],
          FOG_bonding: []
        }
        await this.getCpkGradeList()
        await this.doSubmitType('BL_Assy', 'group', vendorName, partNumber, uploadedDateStart, uploadedDateEnd)
        await this.doSubmitType('COG_bonding', 'group', vendorName, partNumber, uploadedDateStart, uploadedDateEnd)
        await this.doSubmitType('FOB_bonding', 'group', vendorName, partNumber, uploadedDateStart, uploadedDateEnd)
        await this.doSubmitType('FOG_bonding', 'group', vendorName, partNumber, uploadedDateStart, uploadedDateEnd)
        await this.doSubmitType('Key_Size', 'testItemName', vendorName, partNumber, uploadedDateStart, uploadedDateEnd)
        await this.doSubmitType('Optics', 'group', vendorName, partNumber, uploadedDateStart, uploadedDateEnd)
        await this.doSubmitType('POL_ABI', 'group', vendorName, partNumber, uploadedDateStart, uploadedDateEnd)
        await this.doSubmitType('VCOM', 'testItemName', vendorName, partNumber, uploadedDateStart, uploadedDateEnd)
        await this.doSubmitChart('Chart_Reworkate', vendorName, partNumber, uploadedDateStart, uploadedDateEnd)
        await this.doSubmitChart('Chart_YieldRate', vendorName, partNumber, uploadedDateStart, uploadedDateEnd)
        await this.doSubmitChart('Chart_ParticleMeasure_Array', vendorName, partNumber, uploadedDateStart, uploadedDateEnd)
        await this.doSubmitChart('Chart_ParticleMeasure_Assembly', vendorName, partNumber, uploadedDateStart, uploadedDateEnd)
        await this.doSubmitChart('Chart_ParticleMeasure_Bonding', vendorName, partNumber, uploadedDateStart, uploadedDateEnd)
        await this.doSubmitAfter(vendorName, partNumber, uploadedDateStart, uploadedDateEnd)
      }
    },
    doSubmitType (type, groupType, vendorName, partNumber, uploadedDateStart, uploadedDateEnd) {
      const body = {
        uploadedDateStart: uploadedDateStart,
        uploadedDateEnd: uploadedDateEnd,
        vendorName: vendorName,
        partNumber: partNumber,
        type: type,
        systemTrigger: this.systemTrigger
      }
      this.$http.get('/api/keypart/lcdModule/summaryUploadedLcdModuleAnalysisDataList?' + qs.stringify(body)).then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            if (res.data.length > 0) {
              this.summaryNoData = false
              this.responseCpkData[type] = this.responseCpkDataCompileGroup(groupType, res.data)
            }
            this.summaryLoading[type] = false
          } else { // failure
            if (this.systemTrigger) {
              this.overloadMsg = this.overloadMsg + '<br>[' + type + '] ' + res.errMsg
              this.summaryLoading[type] = false
            } else {
              this.overload = true
              this.overloadMsg = '系統錯誤，請洽系統管理人員\n錯誤訊息：' + res.errMsg
              this.summaryLoading[type] = false
            }
          }
        })
        .catch(err => {
          if (this.systemTrigger) {
            this.overloadMsg = this.overloadMsg + '<br>[' + type + '] ' + err
            this.summaryLoading[type] = false
          } else {
            this.overload = true
            this.overloadMsg = '系統錯誤，請洽系統管理人員\n錯誤訊息：' + err
            this.summaryLoading[type] = false
          }
        })
      if (type === 'COG_bonding' || type === 'FOG_bonding') {
        this.$http.get('/api/keypart/lcdModule/detailLcdModuleAnalysisDataList?' + qs.stringify(body)).then(res => res.data)
          .then(res => {
            if (res.status === 'success') {
              if (res.data.length > 0) {
                this.summaryNoData = false
                this.responseProblemData[type] = res.data
              }
              this.summaryLoading['Problem_' + type] = false
            } else { // failure
              if (this.systemTrigger) {
                this.overloadMsg = this.overloadMsg + '<br>[' + type + '] ' + res.errMsg
                this.summaryLoading['Problem_' + type] = false
              } else {
                this.overload = true
                this.overloadMsg = '系統錯誤，請洽系統管理人員\n錯誤訊息：' + res.errMsg
                this.summaryLoading['Problem_' + type] = false
              }
            }
          })
          .catch(err => {
            if (this.systemTrigger) {
              this.overloadMsg = this.overloadMsg + '<br>[' + type + '] ' + err
              this.summaryLoading['Problem_' + type] = false
            } else {
              this.overload = true
              this.overloadMsg = '系統錯誤，請洽系統管理人員\n錯誤訊息：' + err
              this.summaryLoading['Problem_' + type] = false
            }
          })
      }
    },
    doSubmitChart (type, vendorName, partNumber, uploadedDateStart, uploadedDateEnd) {
      const body = {
        uploadedDateStart: uploadedDateStart,
        uploadedDateEnd: uploadedDateEnd,
        vendorName: vendorName,
        partNumber: partNumber,
        type: this.chartSetting[type].queryType
      }

      this.$http.get(`/api/keypart/lcdModule/${this.chartSetting[type].chartPath}?` + qs.stringify(body)).then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            if (res.data.length > 0 && type === 'Chart_Reworkate') {
              this.layoutVer = 2
            }
            if (res.data.length > 0 || typeof res.data === 'object') {
              this.summaryNoData = false
              if (type === 'Chart_YieldRate') {
                const columns = [
                  ['x', ...res.data.appearanceInspectionStatsData.map((item) => item.week)],
                  ['Appearance', ...res.data.appearanceInspectionStatsData.map((item) => item.output)],
                  ['Function', ...res.data.functionInspectionStatsData.map((item) => item.output)],
                  ['Appearance(%)', ...res.data.appearanceInspectionStatsData.map((item) => item.yieldRate)],
                  ['Function(%)', ...res.data.functionInspectionStatsData.map((item) => item.yieldRate)]
                ]
                this.chartData[type].$emit('init', this.doChartOptions(type, columns, '數量'))
              } else if (type === 'Chart_Reworkate') {
                const columns = [
                  ['x', ...res.data.map((item) => item.week)],
                  ['比率', ...res.data.map((item) => item[this.chartSetting[type].yKey])]
                ]
                this.chartData[type].$emit('init', this.doChartOptions(type, columns, '比率'))
              } else {
                const columns = [
                  ['x', ...res.data.map((item) => item.week)],
                  ['數量', ...res.data.map((item) => item[this.chartSetting[type].yKey])]
                ]
                this.chartData[type].$emit('init', this.doChartOptions(type, columns, '數量'))
              }
              this.summaryLoading[type] = false
            }
          } else { // failure
            if (this.systemTrigger) {
              this.overloadMsg = this.overloadMsg + '<br>[' + type + '] ' + res.errMsg
              this.summaryLoading[type] = false
            } else {
              this.overload = true
              this.overloadMsg = '系統錯誤，請洽系統管理人員\n錯誤訊息：' + res.errMsg
              this.summaryLoading[type] = false
            }
          }
        })
        .catch(err => {
          if (this.systemTrigger) {
            this.overloadMsg = this.overloadMsg + '<br>[' + type + '] ' + err
            this.summaryLoading[type] = false
          } else {
            this.overload = true
            this.overloadMsg = '系統錯誤，請洽系統管理人員\n錯誤訊息：' + err
            this.summaryLoading[type] = false
          }
        })
    },
    doSubmitAfter (vendorName, partNumber, uploadedDateStart, uploadedDateEnd) {
      this.responseVendorName = vendorName
      this.responsePartNumber = partNumber
      this.responseDateStart = uploadedDateStart
      this.responseDateEnd = uploadedDateEnd
      const Url = '/keypart/lcdModule/lcdModuleManufacturingReport/' + this.responseDateStart + '/' + this.responseDateEnd + '/' + this.responseVendorName + '/' + this.responsePartNumber
      if (this.$router.history.current.path !== Url) {
        this.$router.replace(Url)
      }
    },
    doSubmitSingle (type, testItemName) {
      this.loadingView = true

      const body = {
        uploadedDateStart: this.responseDateStart,
        uploadedDateEnd: this.responseDateEnd,
        vendorName: this.responseVendorName,
        partNumber: this.responsePartNumber,
        type: type,
        testItemName: testItemName
      }

      this.$http.get('/api/keypart/lcdModule/singleLcdModuleCpkItem?' + qs.stringify(body)).then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.responseStandardData = res.data
            this.responseStandardData.distributionCoverageList.sort(function (a, b) {
              return parseFloat(a.define.value) - parseFloat(b.define.value)
            })
            this.chartViewTestItemName = testItemName
            this.chartLineView = true
            this.loadingView = false
          } else { // failure
            this.loadingView = false
            alert('系統錯誤，請洽系統管理人員\n錯誤訊息：' + res.errMsg)
          }
        })
        .catch(err => {
          this.loadingView = false
          alert('系統錯誤，請洽系統管理人員\n錯誤訊息：' + err)
        })
    },
    doChartOptions (type, columns, unit) {
      return {
        data: {
          x: 'x',
          columns: columns,
          axes: this.chartSetting[type].axes,
          // axes: {
          //   常態分佈: 'y2'
          // },
          type: this.chartSetting[type].type,
          types: this.chartSetting[type].types,
          // types: {
          //   常態分佈: 'spline'
          // }
          colors: this.chartSetting[type].colors
        },
        axis: {
          x: {
            type: 'category',
            padding: {
              top: 0,
              right: 0.1,
              bottom: 0,
              left: 0.2
            },
            tick: {
              rotate: -45,
              multiline: false
            }
          },
          y: {
            tick: {
              format: function (value) { return value }
            },
            label: {
              text: unit,
              position: 'outer-middle'
            },
            padding: {
              bottom: 5
            }
          },
          y2: {
            tick: {
              format: function (value) { return value }
            },
            label: {
              text: '比率',
              position: 'outer-middle'
            },
            padding: {
              bottom: 5
            },
            show: this.chartSetting[type].y2show
          }
        },
        tooltip: {
          order: function (t1, t2) {
            return t1.id < t2.id
          }
          // format: {
          //   title: (d, value) => {
          //     return `${this.barXaxisName[d + 1]}`
          //   },
          //   value: (value, ratio, id) => {
          //     if (id === '常態分佈') {
          //       return `${value.toFixed(3)}`
          //     } else {
          //       return `${value}`
          //     }
          //   }
          // },
          // order: 'asc'
        },
        grid: {
          x: {
            show: true
          },
          y: {
            show: true
          }
        },
        legend: {
          position: 'bottom',
          padding: 16,
          show: this.chartSetting[type].legendShow
        },
        size: {
          width: ((window.innerWidth - this.shrink) * this.chartSetting[type].widthRate / 12) - 64,
          height: 400
        },
        spline: {
          interpolation: {
            type: 'monotone'
          }
        }
      }
    }
  }
}
</script>
<style>
</style>
