<template>
  <v-container
    cfr
    fluid
    grid-list-xl
  >
    <v-layout wrap>
      <!-- <v-flex
        xs12
        style="padding: 0px 12px!important;margin-bottom: 10px;">
        <material-card class="bookmark-area">
          <v-container class="p-0 pb-3 mw-100">
            <v-layout wrap>
              <v-flex
                flex-small
                xs12
                lg12
                style="display: flex !important;flex-flow: wrap;padding: 2px 12px!important;">
                <div style="display: flex !important;align-items: center !important;">
                  <h5 class="my-0 mr-2 d-inline-flex"><v-icon class="mr-0 text-deep-orange">mdi-bookmark-check</v-icon>常用分析</h5>
                </div>
              </v-flex>
            </v-layout>
          </v-container>
          <v-card-actions class="m-0 pt-3 px-0 border-top border-secondary">
            <v-container class="p-0 mw-100">
              <v-layout wrap>
                <v-flex
                  flex-small
                  xs12
                  lg12
                  style="display: flex !important;flex-flow: wrap;padding: 2px 12px!important;"
                  class="bookmark-list">
                  <div
                    v-for="(bookmark,b) in resBookmarkList"
                    :key="'b'+b"
                    class="bookmark"
                  >
                    <div>
                      <v-icon>mdi-bookmark</v-icon>
                      <v-icon
                        class="mr-0"
                        @click="copyBookmarkView=true;doCopyBookmarkInfo(bookmark.bookmarkName,bookmark.bookmarkToken)">mdi-content-copy</v-icon>
                      <span @click="getBookmarkContent(bookmark.bookmarkToken);(code!==bookmark.bookmarkToken)?$router.push({ params: { code: bookmark.bookmarkToken } }):''">{{ bookmark.bookmarkName }}</span>
                      <v-icon
                        class="mr-0"
                        @click="doDelBookmark(bookmark.pk)">mdi-close</v-icon>
                    </div>
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-actions>
        </material-card>
      </v-flex> -->
      <v-flex
        xs12
        style="padding: 0px 12px!important;margin-bottom: 10px;">
        <material-card>
          <v-container class="p-0 pb-3 mw-100">
            <v-layout wrap>
              <v-flex
                flex-small
                xs12
                lg12
                style="display: flex !important;justify-content: space-between;padding: 2px 12px!important;">
                <div style="display: flex !important;align-items: center !important;">
                  <h5 class="my-0 mr-2 d-inline-flex"><v-icon class="mr-0 text-warning">mdi-numeric-1-box</v-icon>資料分列設定</h5>
                  <!-- <v-btn
                    :class="{'select': tableGroupType==='region'}"
                    class="line-type"
                    small
                    @click="tableGroupType='region'">
                    <v-icon>mdi-flag</v-icon>區域(國家)
                  </v-btn>
                  <v-btn
                    :class="{'select': tableGroupType==='modelYear'}"
                    class="line-type"
                    small
                    @click="tableGroupType='modelYear'">
                    <v-icon>mdi-inbox-multiple</v-icon>年度(機種)
                  </v-btn> -->
                </div>
              </v-flex>
            </v-layout>
          </v-container>
          <v-card-actions class="m-0 p-0 border-top border-secondary">
            <v-container class="p-0 mw-100">
              <v-layout wrap>
                <!-- <v-flex
                  d-flex
                  align-items-center
                  xs12
                  lg12>
                  <p style="max-width: 150px;margin: 0!important;">統計表名稱</p>
                  <v-text-field
                    v-model="tableName"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    label=""/>
                </v-flex> -->
              </v-layout>
            </v-container>
          </v-card-actions>
          <v-card-actions class="m-0 border-top border-secondary flex-wrap align-start">
            <div class="list-item-type">
              <span>區域(國家)</span>
              <hr>
            </div>
            <div
              v-for="(setting, s) in resSettingListDataRegion.groupList"
              :key="'sr'+s"
              :class="{'select-all': settingSelectListRegion[0].valueList.indexOf(setting.value)>-1,'select': getSelectSettingStatusRegion(setting.value)}"
              class="list-item-group">
              <span
                class="w-100 cursor"
                title="選擇/取消全部項目"
                @click="doSelectSettingRegion(setting.value,s,(settingSelectListRegion[0].valueList.indexOf(setting.value)>-1),'all')">{{ setting.text }}</span>
              <span
                v-for="(children, c) in setting.children"
                :key="'rc'+c"
                :class="{'select': children.selected}"
                class="cursor"
                title="選擇/取消項目"
                @click="doSelectSettingRegion(children.value,s,children.selected,'item')">{{ children.text }}</span>
            </div>
            <div class="list-item-type">
              <span>年度(機種)</span>
              <hr>
            </div>
            <div
              v-for="(setting, s) in resSettingListDataModelYear.groupList"
              :key="'sm'+s"
              :class="{'select-all': settingSelectListModelYear[0].valueList.indexOf(setting.value)>-1,'select': getSelectSettingStatusModelYear(setting.value)}"
              class="list-item-group">
              <span
                class="w-100 cursor"
                title="選擇/取消全部項目"
                @click="doSelectSettingModelYear(setting.value,s,(settingSelectListModelYear[0].valueList.indexOf(setting.value)>-1),'all')">{{ setting.text }}</span>
              <span
                v-for="(children, c) in setting.children"
                :key="'mc'+c"
                :class="{'select': children.selected}"
                class="cursor"
                title="選擇/取消項目"
                @click="doSelectSettingModelYear(children.value,s,children.selected,'item')">{{ children.text }}</span>
            </div>
          </v-card-actions>
        </material-card>
      </v-flex>
      <v-flex
        xs12
        md3
        style="padding: 0px 12px!important;margin-bottom: 10px;">
        <material-card>
          <v-container class="p-0 pb-3 mw-100">
            <v-layout wrap>
              <v-flex
                flex-small
                xs12
                lg12
                style="display: flex !important;justify-content: space-between;padding: 2px 12px!important;">
                <div style="display: flex !important;align-items: center !important;">
                  <h5 class="my-0 mr-2 d-inline-flex"><v-icon class="mr-0 text-warning">mdi-numeric-2-box</v-icon>資料來源類型</h5>
                </div>
              </v-flex>
            </v-layout>
          </v-container>
          <v-card-actions class="m-0 border-top border-secondary flex-wrap">
            <div
              v-for="(item, i) in typeList"
              :key="'i'+i"
              :class="{'select': typeSelectList.indexOf(item.value)>-1}"
              class="list-item cursor"
              @click="doSelectType(item.value,i)">{{ item.text }}</div>
          </v-card-actions>
        </material-card>
      </v-flex>
      <v-flex
        v-if="typeSelectList.length>0"
        xs12
        md9
        style="padding: 0px 12px!important;margin-bottom: 10px;">
        <v-container
          class="px-0 py-1 mw-100">
          <v-layout wrap>
            <v-flex
              v-for="(type, t) in typeItemList"
              :key="'t'+t">
              <material-card>
                <v-container class="p-0 pb-3 mw-100">
                  <v-layout wrap>
                    <v-flex
                      flex-small
                      style="display: flex;justify-content: space-between;align-items: center;">
                      <strong class="h5 m-0">{{ type.columnLabel }}</strong>
                      <div style="display: flex;align-items: center;">
                        <input
                          v-if="!type.custom"
                          v-model="typeKeywordList[t]"
                          :style="theme==='dark'?'background:#000;color:#fff;':theme==='light'?'background:#fff;color:#000;':''"
                          placeholder="關鍵字篩選"
                          class="v-label rounded"
                          type="text">
                        <input
                          v-if="type.custom"
                          v-model="typeCustomItemList[t]"
                          :style="theme==='dark'?'background:#000;color:#fff;':theme==='light'?'background:#fff;color:#000;':''"
                          placeholder="輸入自訂項目"
                          class="v-label rounded"
                          type="text">
                        <v-btn
                          v-if="type.custom"
                          color="pink"
                          class="my-0 px-1"
                          @click="doAddListItem(t,typeCustomItemList[t])">
                          <v-icon>mdi-plus</v-icon>新增
                        </v-btn>
                      </div>
                    </v-flex>
                  </v-layout>
                </v-container>
                <v-card-actions class="m-0 border-top border-secondary">
                  <div
                    :style="theme==='dark'?'background:#333;':theme==='light'?'background:#bbb;':''"
                    style="width: 100%;height: calc((100vh - 420px) / 2);min-height: 70px;overflow-y: auto;margin-top: 0.5rem;margin-bottom: 0.25rem;">
                    <div
                      v-for="(item, i) in type.itemList"
                      v-show="((typeKeywordList[t]===''&&doGroupOpen(item,t))||(typeKeywordList[t]!==''&&item.type==='item'&&(item.text.toUpperCase().indexOf(typeKeywordList[t].toUpperCase()) > -1)))"
                      :key="'i'+i"
                      class="list-item-area">
                      <span
                        :title="item.type==='title'?'點擊展開/收合群組項目':item.type==='group'?'連點兩下新增全部項目至各別新線組':item.type==='item'?'連點兩下新增項目至新線組':''"
                        :class="{'group-title':item.type==='title','group-all':(item.type==='group'&&item.group!==''),'group-item':typeKeywordList[t]===''&&item.type==='item'&&item.group!==''}"
                        class="w-100"
                        @click="doClickListItem(item.type,t,i,item.value,item.group,item.text)"
                        @dblclick="doDblclickListItem(item.type,t,i,item.value,item.group,(item.type==='group'?item.textlist:item.text))">
                        <v-icon v-if="item.type==='title'&&item.group">mdi-chevron-down-circle</v-icon>
                        <v-icon v-if="item.type==='title'&&!item.group">mdi-chevron-up-circle</v-icon>
                        <span>{{ item.type==='group'?'群組全選':item.text }}</span>
                        <hr v-if="item.type==='title'">
                      </span>
                    </div>
                  </div>
                </v-card-actions>
              </material-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
      <v-flex
        xs12
        style="padding: 0px 12px!important;">
        <material-card>
          <v-container class="p-0 pb-3 mw-100">
            <v-layout wrap>
              <v-flex
                flex-small
                xs12
                lg12
                style="display: flex !important;justify-content: space-between;padding: 2px 12px!important;">
                <div style="display: flex !important;align-items: center !important;">
                  <h5 class="my-0 mr-2 d-inline-flex"><v-icon class="mr-0 text-warning">mdi-numeric-3-box</v-icon>資料分欄項目</h5>
                  <!-- <v-btn
                    :class="{'select': lineType===0}"
                    class="line-type"
                    small
                    @click="lineType=0">
                    <v-icon class="mr-0">mdi-chart-line</v-icon>折線圖
                  </v-btn>
                  <v-btn
                    :class="{'select': lineType===1}"
                    class="line-type"
                    small
                    @click="lineType=1">
                    <v-icon class="mr-0">mdi-chart-bar</v-icon>直條圖
                  </v-btn> -->
                </div>
                <div style="display: flex !important;align-items: center !important;">
                  <v-btn
                    v-if="lineArray.length>0"
                    color="black"
                    class="my-0"
                    small
                    @click="doAllDeleteLine()">
                    <v-icon class="mr-2">mdi-close</v-icon>全部刪除
                  </v-btn>
                  <v-btn
                    v-if="lineGroupList.length>1"
                    color="black"
                    class="my-0 text-warning"
                    small
                    @click="doMultipleDeleteLine()">
                    <v-icon class="mr-2">mdi-close</v-icon>刪除已選
                  </v-btn>
                  <v-btn
                    v-if="lineGroupList.length>1"
                    color="indigo"
                    class="my-0"
                    small
                    @click="copyMultipleLine()">
                    <v-icon class="mr-2">mdi-content-copy</v-icon>複製已選
                  </v-btn>
                  <v-btn
                    color="pink"
                    class="my-0"
                    small
                    @click="addLine()">
                    <v-icon class="mr-2">mdi-plus</v-icon>新增線組
                  </v-btn>
                </div>
              </v-flex>
            </v-layout>
          </v-container>
          <v-card-actions class="m-0 border-top border-secondary">
            <v-container class="p-0 pb-3 mw-100">
              <v-layout wrap>
                <v-flex
                  v-for="(line, i) in lineArray"
                  :key="'i'+i"
                  :class="{'active': (lineGroup===i || lineGroupList.indexOf(i)>-1)}"
                  class="line-item">
                  <h6 class="table-col">
                    <v-icon v-if="line.customGroupName">mdi-pencil</v-icon>
                    <input
                      v-model="line.groupName"
                      :style="'width:'+(line.groupName.length*7.25+45)+'px;'"
                      title="點擊自訂標題"
                      @click="line.customGroupName=true">
                  </h6>
                  <div
                    :style="theme==='dark'?'background:#333;':theme==='light'?'background:#bbb;':''"
                    @click.ctrl="multiple(i)"
                    @click.meta="multiple(i)"
                    @click.exact="singleSelect(i)"
                    @dblclick="copyLine(i)">
                    <v-icon class="table-col">mdi-table-search</v-icon>
                    <div
                      v-for="(allfields, a) in line.selectAllFields"
                      :key="'a'+a">
                      <span
                        title="點擊刪除項目"
                        @click="doDeleteGroup(i, allfields.groupValue, a)">
                        [{{ allfields.groupName }}]
                      </span>
                    </div>
                    <div
                      v-for="(fields, f) in line.fields"
                      :style="(line.selectAllList.indexOf(fields.fieldName+':'+fields.group)>-1)?'display: none !important;':''"
                      :key="'f'+f">
                      <span
                        title="點擊刪除項目"
                        @click="doDeleteItem(i, f)">
                        {{ fields.text }}
                      </span>
                    </div>
                    <v-icon @click="doDeleteLine(i)">mdi-close</v-icon>
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-actions>
          <v-card-actions class="mx-2">
            <v-spacer/>
            <v-checkbox
              v-model="excludeBestBuy"
              :value="true"
              class="m-0 py-0"
              style="padding: 0 16px;flex: 0 0 auto;"
              label="Exclude Best Buy"/>
            <!-- <v-btn
              class="mx-0 mr-3 font-weight-light"
              color="deep-orange"
              @click="doAddBookmark()"
            >
              <v-icon class="mr-2">mdi-bookmark-plus</v-icon>產生書籤
            </v-btn> -->
            <v-btn
              class="mx-0 mr-3 font-weight-light"
              color="general"
              @click="doSubmit()"
            >
              <v-icon class="mr-2">mdi-backup-restore</v-icon>查詢
            </v-btn>
            <v-btn
              class="mx-0 font-weight-light"
              color="indigo"
              @click="doDownload()"
            >
              <v-icon class="mr-2">mdi-export</v-icon>匯出Raw Data
            </v-btn>
          </v-card-actions>
        </material-card>
      </v-flex>
      <v-flex
        v-show="analyzerView"
        flex_table_width_auto
        md12
        xs12
        style="padding: 0px 12px!important;">
        <material-card
          :title="tableName"
          color="deep-orange"
          class="card-mix d-table-t0 no-data-left"
          style="margin-top: 30px!important;">
          <v-data-table
            :headers="responseTableHeaders"
            :items="responseTableData"
            multi-sort
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="subheading font-weight-light text-general text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <tr>
                <td
                  v-for="(header,h) in responseTableHeaders"
                  :key="'h'+h"
                  :class="{'text-center':h>0}">{{ header.displayPercent && checkNumber(item[header.value]) ? (item[header.value]*100).toFixed(2)+'%' : item[header.value] }}</td>
              </tr>
            </template>
          </v-data-table>
        </material-card>
      </v-flex>
    </v-layout>
    <!-- <v-dialog
      v-model="copyBookmarkView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <v-card
        max-width="50%"
        style="margin:0 auto;">
        <v-card-text>
          <v-alert
            v-model="copyBookmarkView"
            class="mt-3 mb-3 border-0"
            color="deep-orange">
            <strong class="h4"><v-icon>mdi-bookmark</v-icon>書籤：{{ copyBookmarkInfo.name }}</strong>
          </v-alert>
          {{ copyBookmarkInfo.url }}
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="red"
            small
            @click="copyBookmarkView=false;doCopyBookmarkInfo('','')">
            <v-icon class="mr-2">mdi-close</v-icon>關閉
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
    <v-dialog
      v-model="processingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        PROCESSING 處理中
      </p>
    </v-dialog>
    <v-dialog
      v-model="loadingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 5px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :color="snackbarColor"
      :top="true">
      {{ snackbarMsg }}
      <v-btn
        flat
        @click="snackbar = false">
        關閉
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import Loading from '@/assets/img/loading.gif'

export default {
  name: 'MachineCfrStatisic',
  data () {
    return {
      snackbar: false,
      snackbarColor: 'red',
      snackbarMsg: '',
      Loading,
      loadingView: false,
      processingView: false,
      // code: '',
      // copyBookmarkView: false,
      // copyBookmarkInfo: {
      //   name: '',
      //   code: ''
      // },
      tableGroupType: 'region',
      tableName: '機台維修不良率統計',
      statisticTimeFormat: 'lifetime',
      statisticType: 'Active',
      excludeBestBuy: false,
      baseline: false,
      isDblclick: false,
      isDeleteItem: false,
      analyzerView: false,
      // resBookmarkList: [
      //   {
      //     pk: 1, // required, 不良率分析工具儲存畫面條件 PK
      //     bookmarkName: '三年不良率統計', // required, 不良率分析工具儲存畫面條件名稱
      //     bookmarkToken: '3kjoxlk3jlkjlkxl' // required, 不良率分析工具儲存畫面條件令牌
      //   }, {
      //     pk: 2, // required, 不良率分析工具儲存畫面條件 PK
      //     bookmarkName: '三年不良率統計', // required, 不良率分析工具儲存畫面條件名稱
      //     bookmarkToken: 'djfmhhillg' // required, 不良率分析工具儲存畫面條件令牌
      //   }, {
      //     pk: 2, // required, 不良率分析工具儲存畫面條件 PK
      //     bookmarkName: '三年不良率統計', // required, 不良率分析工具儲存畫面條件名稱
      //     bookmarkToken: 'djfmhhillg' // required, 不良率分析工具儲存畫面條件令牌
      //   }, {
      //     pk: 2, // required, 不良率分析工具儲存畫面條件 PK
      //     bookmarkName: '三年不良率統計三年不良率統計三年不良率統計', // required, 不良率分析工具儲存畫面條件名稱
      //     bookmarkToken: 'djfmhhillg' // required, 不良率分析工具儲存畫面條件令牌
      //   }, {
      //     pk: 2, // required, 不良率分析工具儲存畫面條件 PK
      //     bookmarkName: '三年不良率統計', // required, 不良率分析工具儲存畫面條件名稱
      //     bookmarkToken: 'djfmhhillg' // required, 不良率分析工具儲存畫面條件令牌
      //   }, {
      //     pk: 2, // required, 不良率分析工具儲存畫面條件 PK
      //     bookmarkName: '三年不良率統計三年不良率統計', // required, 不良率分析工具儲存畫面條件名稱
      //     bookmarkToken: 'djfmhhillg' // required, 不良率分析工具儲存畫面條件令牌
      //   }, {
      //     pk: 2, // required, 不良率分析工具儲存畫面條件 PK
      //     bookmarkName: '三年不良率統計', // required, 不良率分析工具儲存畫面條件名稱
      //     bookmarkToken: 'djfmhhillg' // required, 不良率分析工具儲存畫面條件令牌
      //   }, {
      //     pk: 2, // required, 不良率分析工具儲存畫面條件 PK
      //     bookmarkName: '三年不良率統計', // required, 不良率分析工具儲存畫面條件名稱
      //     bookmarkToken: 'djfmhhillg' // required, 不良率分析工具儲存畫面條件令牌
      //   }, {
      //     pk: 2, // required, 不良率分析工具儲存畫面條件 PK
      //     bookmarkName: '三年不良率統計', // required, 不良率分析工具儲存畫面條件名稱
      //     bookmarkToken: 'djfmhhillg' // required, 不良率分析工具儲存畫面條件令牌
      //   }, {
      //     pk: 2, // required, 不良率分析工具儲存畫面條件 PK
      //     bookmarkName: '三年不良率統計', // required, 不良率分析工具儲存畫面條件名稱
      //     bookmarkToken: 'djfmhhillg' // required, 不良率分析工具儲存畫面條件令牌
      //   }, {
      //     pk: 2, // required, 不良率分析工具儲存畫面條件 PK
      //     bookmarkName: '三年不良率統計', // required, 不良率分析工具儲存畫面條件名稱
      //     bookmarkToken: 'djfmhhillg' // required, 不良率分析工具儲存畫面條件令牌
      //   }
      // ],
      resSettingListDataRegion: {
        groupName: 'region',
        childName: 'country',
        groupList: []
      },
      resSettingListDataModelYear: {
        groupName: 'modelYear',
        childName: 'model',
        groupList: []
      },
      // resSettingListData: {
      //   groupName: 'region',
      //   childName: 'country',
      //   groupList: [
      //     // {
      //     //   text: '亞太',
      //     //   value: 'Asia',
      //     //   children: [
      //     //     {
      //     //       text: 'Taiwan1',
      //     //       value: 'TW1',
      //     //       select: false
      //     //     }, {
      //     //       text: 'Taiwan2',
      //     //       value: 'TW2',
      //     //       select: false
      //     //     }, {
      //     //       text: 'Taiwan3',
      //     //       value: 'TW3',
      //     //       select: false
      //     //     }, {
      //     //       text: 'Taiwan4',
      //     //       value: 'TW4',
      //     //       select: false
      //     //     }, {
      //     //       text: 'Taiwan5',
      //     //       value: 'TW5',
      //     //       select: false
      //     //     }, {
      //     //       text: 'Taiwan6',
      //     //       value: 'TW6',
      //     //       select: false
      //     //     }
      //     //   ]
      //     // }, {
      //     //   text: '美州',
      //     //   value: 'AMA',
      //     //   children: [
      //     //     {
      //     //       text: 'American1',
      //     //       value: 'ANA1',
      //     //       select: false
      //     //     }, {
      //     //       text: 'American2',
      //     //       value: 'ANA2',
      //     //       select: false
      //     //     }, {
      //     //       text: 'American3',
      //     //       value: 'ANA3',
      //     //       select: false
      //     //     }, {
      //     //       text: 'American4',
      //     //       value: 'ANA4',
      //     //       select: false
      //     //     }, {
      //     //       text: 'American5',
      //     //       value: 'ANA5',
      //     //       select: false
      //     //     }, {
      //     //       text: 'American6',
      //     //       value: 'ANA6',
      //     //       select: false
      //     //     }
      //     //   ]
      //     // }
      //   ]
      // },
      resTypeListData: [
        // {
        //   columnName: 'country1',
        //   columnLabel: '國家1',
        //   groupMappingColumn: 'year',
        //   itemList: [
        //     {
        //       text: 'KB',
        //       value: 'KB',
        //       group: '2019N',
        //       groupValue: '2019V'
        //     }, {
        //       text: 'MB',
        //       value: 'MB',
        //       group: '2019N',
        //       groupValue: '2019V'
        //     }, {
        //       text: 'GB',
        //       value: 'GB',
        //       group: '2020N',
        //       groupValue: '2020V'
        //     }, {
        //       text: 'TB',
        //       value: 'TB',
        //       group: '2020N',
        //       groupValue: '2020V'
        //     }, {
        //       text: 'ZB',
        //       value: 'ZB',
        //       group: '',
        //       groupValue: ''
        //     }
        //   ]
        // }, {
        //   columnName: 'country2',
        //   columnLabel: '國家2',
        //   groupMappingColumn: 'year',
        //   itemList: [
        //     {
        //       text: 'KB',
        //       value: 'KB',
        //       group: '2019N',
        //       groupValue: '2019V'
        //     }, {
        //       text: 'MB',
        //       value: 'MB',
        //       group: '2019N',
        //       groupValue: '2019V'
        //     }, {
        //       text: 'GB',
        //       value: 'GB',
        //       group: '2020N',
        //       groupValue: '2020V'
        //     }, {
        //       text: 'TB',
        //       value: 'TB',
        //       group: '2020N',
        //       groupValue: '2020V'
        //     }, {
        //       text: 'ZB',
        //       value: 'ZB',
        //       group: '',
        //       groupValue: ''
        //     }
        //   ]
        // }, {
        //   columnName: 'country3',
        //   columnLabel: '國家3',
        //   groupMappingColumn: 'year',
        //   itemList: [
        //     {
        //       text: 'KB',
        //       value: 'KB',
        //       group: '2021N',
        //       groupValue: '2021V'
        //     }, {
        //       text: 'MB',
        //       value: 'MB',
        //       group: '2018N',
        //       groupValue: '2018V'
        //     }, {
        //       text: 'GB',
        //       value: 'GB',
        //       group: '2020N',
        //       groupValue: '2020V'
        //     }, {
        //       text: 'TB',
        //       value: 'TB',
        //       group: '2019N',
        //       groupValue: '2019V'
        //     }, {
        //       text: 'ZB',
        //       value: 'ZB',
        //       group: '',
        //       groupValue: ''
        //     }
        //   ]
        // }, {
        //   columnName: 'country4',
        //   columnLabel: '國家4',
        //   groupMappingColumn: 'year',
        //   itemList: [
        //     {
        //       text: 'KB',
        //       value: 'KB',
        //       group: '2021N',
        //       groupValue: '2021V'
        //     }, {
        //       text: 'MB',
        //       value: 'MB',
        //       group: '2021N',
        //       groupValue: '2021V'
        //     }, {
        //       text: 'GB',
        //       value: 'GB',
        //       group: '2020N',
        //       groupValue: '2020V'
        //     }, {
        //       text: 'TB',
        //       value: 'TB',
        //       group: '2020N',
        //       groupValue: '2020V'
        //     }, {
        //       text: 'ZB',
        //       value: 'ZB',
        //       group: '',
        //       groupValue: ''
        //     }
        //   ]
        // }, {
        //   columnName: 'country5',
        //   columnLabel: '國家5',
        //   groupMappingColumn: 'year',
        //   itemList: [
        //     {
        //       text: 'KB',
        //       value: 'KB',
        //       group: '2019N',
        //       groupValue: '2019V'
        //     }, {
        //       text: 'MB',
        //       value: 'MB',
        //       group: '2019N',
        //       groupValue: '2019V'
        //     }, {
        //       text: 'GB',
        //       value: 'GB',
        //       group: '2020N',
        //       groupValue: '2020V'
        //     }, {
        //       text: 'TB',
        //       value: 'TB',
        //       group: '2020N',
        //       groupValue: '2020V'
        //     }, {
        //       text: 'ZB',
        //       value: 'ZB',
        //       group: '2021N',
        //       groupValue: '2021V'
        //     }
        //   ]
        // }, {
        //   columnName: 'country6',
        //   columnLabel: '國家6',
        //   groupMappingColumn: 'year',
        //   itemList: [
        //     {
        //       text: 'KB',
        //       value: 'KB',
        //       group: '',
        //       groupValue: ''
        //     }, {
        //       text: 'MB',
        //       value: 'MB',
        //       group: '',
        //       groupValue: ''
        //     }, {
        //       text: 'GB',
        //       value: 'GB',
        //       group: '',
        //       groupValue: ''
        //     }, {
        //       text: 'TB',
        //       value: 'TB',
        //       group: '',
        //       groupValue: ''
        //     }, {
        //       text: 'ZB',
        //       value: 'ZB',
        //       group: '',
        //       groupValue: ''
        //     }
        //   ]
        // }
      ],
      typeSelectList: [],
      typeItemList: [],
      typeKeywordList: [],
      typeCustomItemList: [],
      lineType: 0,
      lineGroup: 0,
      lineGroupList: [],
      lineArray: [
        { lineType: 0, customGroupName: false, groupName: '', fields: [], selectAllFields: [], selectAllList: [] }
      ],
      responseTableHeaders: [
        // {
        //   text: '國家',
        //   value: 'country',
        //   align: 'left',
        //   displayPercent: false,
        //   sortable: false
        // }, {
        //   text: '出貨數',
        //   value: 'ShipmentsQty',
        //   align: 'right',
        //   displayPercent: false,
        //   sortable: false
        // }, {
        //   text: '激活數',
        //   value: 'activeQty',
        //   align: 'right',
        //   displayPercent: false,
        //   sortable: false
        // }, {
        //   text: 'KB 不良數',
        //   value: 'KB_badQty',
        //   align: 'right',
        //   displayPercent: false,
        //   sortable: false
        // }, {
        //   text: 'KB CFR',
        //   value: 'KB_cfr',
        //   align: 'right',
        //   displayPercent: true,
        //   sortable: false
        // }
      ],
      responseTableData: [
        // {
        //   country: 'Taiwan1',
        //   ShipmentsQty: 100,
        //   activeQty: 1000,
        //   KB_badQty: 10,
        //   KB_cfr: 0.01
        // }, {
        //   ShipmentsQty: 300,
        //   activeQty: 3000,
        //   KB_badQty: 30,
        //   country: 'Taiwan3',
        //   KB_cfr: 0.03
        // }, {
        //   ShipmentsQty: 200,
        //   country: 'Taiwan2',
        //   activeQty: 2000,
        //   KB_badQty: 20,
        //   KB_cfr: 0.02
        // }
      ]
      // rowsAmount: [25, 50, 100, { text: 'All', value: -1 }],
      // perPageRows: 25,
      // pagination: {
      //   page: 1,
      //   rowsPerPage: 25,
      //   sortBy: '',
      //   descending: false
      // },
      // pages: 0
    }
  },
  computed: {
    ...mapState('app', ['theme', 'shrink']),
    typeList () {
      const list = []
      this.resTypeListData.forEach((r) => {
        list.push({ text: r.columnLabel, value: r.columnName })
      })
      return list
    },
    settingSelectListRegion () {
      const list = [{
        columnName: this.resSettingListDataRegion.groupName,
        valueList: []
      }, {
        columnName: this.resSettingListDataRegion.childName,
        valueList: []
      }]
      this.resSettingListDataRegion.groupList.forEach((g) => {
        let s = 0
        const item = []
        g.children.forEach((c) => {
          if (c.selected) {
            s++
            item.push(c.value)
          }
        })
        if (g.children.length === s) {
          list[0].valueList.push(g.value)
        } else if (item.length > 0) {
          list[1].valueList = list[1].valueList.concat(item)
        }
      })
      return list
    },
    settingSelectListModelYear () {
      const list = [{
        columnName: this.resSettingListDataModelYear.groupName,
        valueList: []
      }, {
        columnName: this.resSettingListDataModelYear.childName,
        valueList: []
      }]
      this.resSettingListDataModelYear.groupList.forEach((g) => {
        let s = 0
        const item = []
        g.children.forEach((c) => {
          if (c.selected) {
            s++
            item.push(c.value)
          }
        })
        if (g.children.length === s) {
          list[0].valueList.push(g.value)
        } else if (item.length > 0) {
          list[1].valueList = list[1].valueList.concat(item)
        }
      })
      return list
    }
  },
  watch: {
    // tableGroupType (val) {
    //   this.$http.get(`/api/pqm/cfrAnalysisMgmt/getMachineStatisicSelectMainColumns/${val}`).then(res => res.data)
    //     .then(res => {
    //       if (res.status === 'success') {
    //         this.resSettingListData = res.data
    //       }
    //     })
    // }
  },
  mounted () {
    this.$store.dispatch('getAuthorize', '/pqm/cfrAnalysisMgmt/machineCfrStatisicView')
      .then(response => {
        if (response.status === 'success' && response.data.accessible) {
          // if (this.$route.params.code) {
          //   this.code = this.$route.params.code
          //   this.getBookmarkContent(this.code)
          // }
          // this.getBookmarkList()
          this.$http.get('/api/pqm/cfr/machineCfrStatistic/multiGroupOptions').then(res => res.data)
            .then(res => {
              if (res.status === 'success') {
                this.resTypeListData = res.data
              }
            })
          this.$http.get('/api/pqm/cfr/machineCfrStatistic/category/region').then(res => res.data)
            .then(res => {
              if (res.status === 'success') {
                this.resSettingListDataRegion = res.data
              }
            })
          this.$http.get('/api/pqm/cfr/machineCfrStatistic/category/modelYear').then(res => res.data)
            .then(res => {
              if (res.status === 'success') {
                this.resSettingListDataModelYear = res.data
              }
            })
          // this.doSubmit()
        } else if (response.errCode === 'E10000002') {
          const routeData = this.$router.resolve({ path: '/noEnter/E10000002' })
          localStorage.setItem('errormsg', response.errMsg)
          window.location.assign(routeData.href)
        } else {
          const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
          window.location.assign(routeData.href)
        }
      })
      .catch(err => {
        console.log(err)
        const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
        window.location.assign(routeData.href)
      })
  },
  methods: {
    // getBookmarkList () {
    //   this.$http.get('/api/pqm/cfrAnalysisMgmt/cfrAnalyzerBookmark').then(res => res.data)
    //     .then(res => {
    //       if (res.status === 'success') {
    //         this.resBookmarkList = res.data
    //       }
    //     })
    // },
    // getBookmarkContent (code) {
    //   this.$http.get(`/api/pqm/cfrAnalysisMgmt/cfrAnalyzerCriteria?bookmarkToken=${code}`).then(res => res.data)
    //     .then(res => {
    //       if (res.status === 'success') {
    //         this.tableName = res.data.tableName
    //         this.statisticType = res.data.statisticType
    //         this.statisticTimeFormat = res.data.statisticTimeFormat
    //         this.baseline = res.data.baseline
    //         this.tableGroupType = res.data.tableGroupType
    //         this.lineArray = res.data.criterial
    //         this.doSubmit()
    //       }
    //     })
    // },
    // doCopyBookmarkInfo (name, code) {
    //   this.copyBookmarkInfo.name = name
    //   this.copyBookmarkInfo.code = code
    //   this.copyBookmarkInfo.url = location.href.split('/')[2] + '/pqm/cfrAnalysisMgmt/cfrAnalyzerView/' + code
    // },
    checkNumber (value) {
      return Number(value) === value
    },
    doSelectSettingRegion (val, i, status, type) {
      if (type === 'all') {
        this.resSettingListDataRegion.groupList[i].children.forEach((c) => {
          c.selected = !status
        })
      } else if (type === 'item') {
        this.resSettingListDataRegion.groupList[i].children.forEach((c) => {
          if (c.value === val) {
            c.selected = !status
          }
        })
      }
    },
    doSelectSettingModelYear (val, i, status, type) {
      if (type === 'all') {
        this.resSettingListDataModelYear.groupList[i].children.forEach((c) => {
          c.selected = !status
        })
      } else if (type === 'item') {
        this.resSettingListDataModelYear.groupList[i].children.forEach((c) => {
          if (c.value === val) {
            c.selected = !status
          }
        })
      }
    },
    getSelectSettingStatusRegion (val) {
      let status = false
      this.resSettingListDataRegion.groupList.forEach((g) => {
        if (g.value === val) {
          g.children.forEach((c) => {
            if (c.selected) {
              status = true
            }
          })
        }
      })
      return status
    },
    getSelectSettingStatusModelYear (val) {
      let status = false
      this.resSettingListDataModelYear.groupList.forEach((g) => {
        if (g.value === val) {
          g.children.forEach((c) => {
            if (c.selected) {
              status = true
            }
          })
        }
      })
      return status
    },
    doSelectType (val, i) {
      if (this.typeSelectList.indexOf(val) === -1) {
        this.typeSelectList.push(val)
        this.typeKeywordList.push('')
        this.typeCustomItemList.push('')
        this.doAddtypeItemList(i)
      } else {
        this.typeKeywordList.splice(this.typeSelectList.indexOf(val), 1)
        this.typeCustomItemList.splice(this.typeSelectList.indexOf(val), 1)
        this.typeItemList.splice(this.typeSelectList.indexOf(val), 1)
        this.typeSelectList.splice(this.typeSelectList.indexOf(val), 1)
      }
      if (this.typeSelectList.length > 2) {
        this.typeSelectList.splice(0, 1)
        this.typeItemList.splice(0, 1)
        this.typeKeywordList.splice(0, 1)
        this.typeCustomItemList.splice(0, 1)
      }
    },
    doAddtypeItemList (val) {
      let itemList = []
      const groupNameList = []
      const groupItemList = []
      const noGroupItemList = []
      let custom = false
      this.resTypeListData[val].itemList.forEach((i) => {
        if (i.group !== '' && i.groupValue !== '' && groupNameList.indexOf(i.group + ':' + i.groupValue) === -1) {
          groupNameList.push(i.group + ':' + i.groupValue)
          groupItemList.push({ text: i.group, code: i.groupValue, value: [], list: [], namelist: [] })
        }
        if (i.group !== '' && i.groupValue !== '') {
          groupItemList[groupNameList.indexOf(i.group + ':' + i.groupValue)].list.push(i.value)
          groupItemList[groupNameList.indexOf(i.group + ':' + i.groupValue)].namelist.push(i.text)
          groupItemList[groupNameList.indexOf(i.group + ':' + i.groupValue)].value.push({ type: 'item', value: i.value, text: i.text, group: i.groupValue })
        } else {
          noGroupItemList.push({ type: 'item', value: i.value, text: i.text, group: '' })
        }
      })

      groupNameList.sort().forEach((n) => {
        groupItemList.forEach((g) => {
          if (n.split(':')[0] === g.text) {
            itemList.push({ type: 'title', value: g.code, text: g.text, group: false, textlist: '' })
            itemList.push({ type: 'group', value: g.list, text: g.text, group: g.code, textlist: g.namelist })
            itemList = itemList.concat(g.value)
          }
        })
      })
      itemList = itemList.concat(noGroupItemList)
      if (itemList.length === 0) {
        custom = true
      }
      this.typeItemList.push({ columnName: this.resTypeListData[val].columnName, columnLabel: this.resTypeListData[val].columnLabel, groupMappingColumn: this.resTypeListData[val].groupMappingColumn, itemList: itemList, custom: custom })
    },
    doAddListItem (index, val) {
      this.typeItemList[index].itemList.push({ type: 'item', value: '^' + val, text: '^' + val, group: '', textlist: '' })
      // this.doClickListItem('item', index, (this.typeItemList[index].itemList.length - 1), '*' + val, '', '')
      this.typeCustomItemList[index] = ''
    },
    doGroupOpen (val, index) {
      let group = false
      if (val.type !== 'title' && val.group !== '') {
        this.typeItemList[index].itemList.forEach((i) => {
          if (val.group === i.value && i.type === 'title') {
            group = i.group
          }
        })
      } else {
        group = true
      }
      return group
    },
    doClickListItem (type, t, i, val, group, name) {
      if (type === 'title') {
        this.typeItemList[t].itemList[i].group = !this.typeItemList[t].itemList[i].group
      } else {
        this.isDblclick = false
        this.addItemManyToOne(this.typeItemList[t].columnName, this.typeItemList[t].groupMappingColumn, val, group, name, false)
      }
    },
    doDblclickListItem (type, t, i, val, group, name) {
      if (type !== 'title') {
        this.addItemOneToOne(this.typeItemList[t].columnName, this.typeItemList[t].groupMappingColumn, val, group, name)
      }
    },
    addLine () {
      this.lineArray.push({ lineType: this.lineType, customGroupName: false, groupName: '', fields: [], selectAllFields: [], selectAllList: [] })
      this.lineGroup = (this.lineArray.length - 1)
      this.lineGroupList = []
    },
    addItemManyToOne (type, column, val, group, name, multiple) {
      if (this.lineGroup >= 0 || multiple) {
        setTimeout(() => {
          if (!this.isDblclick) {
            const list = []
            const allList = []
            this.lineArray[this.lineGroup].fields.forEach((i) => {
              list.push(i.fieldName + '-' + i.value)
            })
            this.lineArray[this.lineGroup].selectAllFields.forEach((i) => {
              allList.push(i.columnName + '-' + i.groupValue + '-' + i.groupColumn)
            })
            if (Array.isArray(val)) {
              // Array
              if (group === '') {
                const list = []
                this.lineArray[this.lineGroup].selectAllFields.forEach((a, index) => {
                  if (a.columnName === type) {
                    list.push(index)
                  }
                })
                list.sort().reverse().forEach((l) => {
                  this.lineArray[this.lineGroup].selectAllFields.splice(l, 1)
                })
              }
              if (allList.indexOf(type + '-' + group + '-' + column) === -1) {
                this.lineArray[this.lineGroup].selectAllFields.push({ columnName: type, groupValue: group, groupName: name, groupColumn: column })
                this.lineArray[this.lineGroup].selectAllList.push(type + ':' + group)
              }
              val.forEach((i) => {
                if (list.indexOf(type + '-' + i) === -1) {
                  this.lineArray[this.lineGroup].fields.push({ fieldName: type, value: i, group: group, text: name })
                  this.doAutoName(this.lineGroup, group)
                }
              })
            } else {
              if (list.indexOf(type + '-' + val) === -1) {
                this.lineArray[this.lineGroup].fields.push({ fieldName: type, value: val, group: group, text: name })
                this.doAutoName(this.lineGroup, group)
              }
            }
          }
        }, 300)
      } else if (this.lineGroupList.length > 0) {
        this.lineGroupList.forEach((b) => {
          setTimeout(() => {
            if (!this.isDblclick) {
              const list = []
              const allList = []
              this.lineArray[b].fields.forEach((i) => {
                list.push(i.fieldName + '-' + i.value)
              })
              this.lineArray[b].selectAllFields.forEach((i) => {
                allList.push(i.columnName + '-' + i.groupValue + '-' + i.groupColumn)
              })
              if (Array.isArray(val)) {
                // Array
                if (group === '') {
                  const list = []
                  this.lineArray[b].selectAllFields.forEach((a, index) => {
                    if (a.columnName === type) {
                      list.push(index)
                    }
                  })
                  list.sort().reverse().forEach((l) => {
                    this.lineArray[b].selectAllFields.splice(l, 1)
                  })
                }
                if (allList.indexOf(type + '-' + group + '-' + column) === -1) {
                  this.lineArray[b].selectAllFields.push({ columnName: type, groupValue: group, groupName: name, groupColumn: column })
                  this.lineArray[b].selectAllList.push(type + ':' + group)
                }
                val.forEach((i) => {
                  if (list.indexOf(type + '-' + i) === -1) {
                    this.lineArray[b].fields.push({ fieldName: type, value: i, group: group, text: name })
                    this.doAutoName(b, group)
                  }
                })
              } else {
                if (list.indexOf(type + '-' + val) === -1) {
                  this.lineArray[b].fields.push({ fieldName: type, value: val, group: group, text: name })
                  this.doAutoName(b, group)
                }
              }
            }
          }, 300)
        })
        this.lineGroup = -1
      } else {
        this.addLine()
        this.addItemManyToOne(type, column, val, group, name, false)
      }
    },
    addItemOneToOne (type, column, val, group, name) {
      this.isDblclick = true
      if (this.lineArray[(this.lineArray.length - 1)].fields.length === 0 && this.lineArray[(this.lineArray.length - 1)].selectAllFields.length === 0) {
        this.lineArray.splice(val, 1)
      }
      if (Array.isArray(val)) {
        // Array
        // if (group === '') {
        //   let list = []
        //   this.lineArray[(this.lineArray.length - 1)].selectAllFields.forEach((a, index) => {
        //     if (a.columnName === type) {
        //       list.push(index)
        //     }
        //   })
        //   list.sort().reverse().forEach((l) => {
        //     this.lineArray[(this.lineArray.length - 1)].selectAllFields.splice(l, 1)
        //   })
        // }
        // this.lineArray[(this.lineArray.length - 1)].selectAllFields.push({ columnName: type, groupValue: group, groupColumn: column })
        val.forEach((i, index) => {
          this.addLine()
          this.lineArray[(this.lineArray.length - 1)].fields.push({ fieldName: type, value: i, group: group, text: name[index] })
          this.doAutoName((this.lineArray.length - 1), group)
        })
      } else {
        this.addLine()
        this.lineArray[(this.lineArray.length - 1)].fields.push({ fieldName: type, value: val, group: group, text: name })
        this.doAutoName((this.lineArray.length - 1), group)
      }
    },
    doDeleteItem (LineIndex, itemIndex) {
      // let deleteAllIndex = ''
      this.isDeleteItem = true
      this.lineGroup = LineIndex
      this.resTypeListData.forEach((t) => {
        if (t.columnName === this.lineArray[LineIndex].fields[itemIndex].fieldName) {
          t.itemList.forEach((i, index) => {
            if (i.value === this.lineArray[LineIndex].fields[itemIndex].value) {
              if (i.groupValue !== '') {
                this.lineArray[LineIndex].selectAllFields.forEach((a, index) => {
                  if (a.groupValue === i.groupValue && a.columnName === t.columnName) {
                    // deleteAllIndex = index
                    this.lineArray[LineIndex].selectAllFields.splice(index, 1)
                  }
                })
                // this.lineArray[LineIndex].selectAllFields.splice(deleteAllIndex, 1)
              }
            }
          })
        }
      })
      this.lineArray[LineIndex].selectAllFields.forEach((a, index) => {
        if (a.groupValue === '' && a.columnName === this.lineArray[LineIndex].fields[itemIndex].fieldName) {
          this.lineArray[LineIndex].selectAllFields.splice(index, 1)
        }
      })
      this.lineArray[LineIndex].fields.splice(itemIndex, 1)
      this.doAutoName(LineIndex)
    },
    doDeleteGroup (LineIndex, group, index) {
      this.isDeleteItem = true
      const list = []
      this.lineArray[LineIndex].fields.forEach((f) => {
        if (f.group !== group) {
          list.push(f)
        }
      })
      this.lineArray[LineIndex].fields = list
      this.lineArray[LineIndex].selectAllFields.splice(index, 1)
      this.lineArray[LineIndex].selectAllList.splice(index, 1)
      this.doAutoName(LineIndex)
    },
    doDeleteLine (LineIndex) {
      this.lineArray.splice(LineIndex, 1)
      setTimeout(() => {
        this.lineGroup = (this.lineArray.length - 1)
      }, 0)
      if (this.lineArray.length < 1) {
        this.addLine()
      }
    },
    doMultipleDeleteLine () {
      const tempList = []
      this.lineArray.forEach((i, index) => {
        if (this.lineGroupList.indexOf(index) === -1) {
          tempList.push(i)
        }
      })
      this.lineArray = tempList
      // this.lineGroupList.forEach((i) => {
      //   this.lineArray.splice(i, 1)
      //   console.log(this.lineArray)
      // })
      // this.lineGroup = (this.lineArray.length - 1)
      this.lineGroupList = []
      if (this.lineArray.length < 1) {
        this.addLine()
      }
    },
    doAllDeleteLine () {
      this.lineArray = [{ lineType: 0, customGroupName: false, groupName: '', fields: [], selectAllFields: [], selectAllList: [] }]
      this.lineGroup = 0
      this.lineGroupList = []
    },
    copyLine (val) {
      this.lineArray.push(JSON.parse(JSON.stringify(this.lineArray[val])))
      this.lineGroup = (this.lineArray.length - 1)
    },
    copyMultipleLine () {
      if (this.lineGroupList.length === 0) {
        this.lineGroupList.push(this.lineGroup)
        this.lineGroup = -1
      }
      this.lineGroupList.forEach((i) => {
        this.lineArray.push(JSON.parse(JSON.stringify(this.lineArray[i])))
      })
      this.lineGroup = (this.lineArray.length - 1)
      this.lineGroupList = []
    },
    singleSelect (val) {
      if (!this.isDeleteItem) {
        if (this.lineGroup === val) {
          this.lineGroup = -1
        } else {
          this.lineGroup = val
        }
        this.lineGroupList = []
      }
      this.isDeleteItem = false
    },
    multiple (val) {
      if (this.lineGroupList.length === 0 && this.lineGroupList.indexOf(this.lineGroup) === -1 && this.lineGroup !== -1) {
        this.lineGroupList.push(this.lineGroup)
      }
      if (this.lineGroupList.indexOf(val) === -1) {
        this.lineGroupList.push(val)
        this.lineGroup = -1
      } else {
        this.lineGroupList.splice(this.lineGroupList.indexOf(val), 1)
      }
    },
    allSelect (val) {
      alert('x')
    },
    doAutoName (val, group) {
      const list = []
      const item = []
      let name = ''
      this.lineArray[val].fields.forEach((i) => {
        list.push(i.fieldName + ':' + i.value + ':' + i.group + ':' + i.text)
      })
      list.sort()
      this.lineArray[val].selectAllFields.forEach((s) => {
        name = name + '[' + s.groupName + '],'
      })
      this.typeList.forEach((t) => {
        list.forEach((i) => {
          const array = i.split(':')
          if (t.value === array[0] && this.lineArray[val].selectAllList.indexOf(array[0] + ':' + array[2]) === -1) {
            name = name + array[3] + ','
            item.push({ fieldName: array[0], value: array[1], group: array[2], text: array[3] })
          }
        })
        if (name.lastIndexOf(',') === (name.length - 1)) {
          name = name.substr(0, name.lastIndexOf(',')) + '+'
        }
      })
      if (name.substr(0, 1) === '+') {
        name = name.substr(1, (name.length - 1))
      }
      if (name.substr((name.length - 1), 1) === '+') {
        name = name.substr(0, (name.length - 1))
      }
      if (!this.lineArray[val].customGroupName) {
        this.lineArray[val].groupName = name
      }
      this.lineArray[val].fields = item
    },
    doClearBlankLine () {
      const clearList = []
      this.lineArray.forEach((line, index) => {
        if (index > 0 && line.fields.length === 0 && line.selectAllFields.length === 0) {
          clearList.push(index)
        }
      })
      clearList.sort().reverse().forEach((c) => {
        this.lineArray.splice(c, 1)
      })
    },
    doSubmitVerification () {
      // if (this.tableName === '') {
      //   this.snackbar = true
      //   this.snackbarColor = 'red'
      //   this.snackbarMsg = '統計表名稱不得為空'
      //   return false
      // } else {
      this.doClearBlankLine()
      if (this.lineArray.length > 1 || this.lineArray[0].fields.length > 0 || this.lineArray[0].selectAllFields.length > 0) {
        if (this.lineArray[0].fields.length === 0 && this.lineArray[0].selectAllFields.length === 0) {
          this.lineArray.splice(0, 1)
        }
        return true
      } else {
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '資料分欄項目不得為空'
        return false
      }
      // }
    },
    doSubmit () {
      if (this.doSubmitVerification()) {
        this.loadingView = true
        const body = {
          // tableName: this.tableName,
          scopeCriteria: this.settingSelectListRegion.concat(this.settingSelectListModelYear),
          statisticCriteria: this.lineArray,
          excludeBestBuy: this.excludeBestBuy || false
        }
        this.$http.post('/api/pqm/cfr/machineCfrStatistic/machineCfrStatisticData', body).then(res => res.data)
          .then(res => {
            this.loadingView = false
            if (res.status === 'success') {
              this.analyzerView = true
              this.responseTableHeaders = res.data.columnNameList
              this.responseTableHeaders.forEach((h) => {
                h.sortable = true
                h.align = 'right'
                h.class = ''
              })
              this.responseTableHeaders[0].align = 'left'
              this.responseTableData = res.data.tableData
            } else {
              this.analyzerView = false
              this.snackbar = true
              this.snackbarColor = 'red'
              this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
            }
          })
      }
    },
    // doAddBookmark () {
    //   if (this.doSubmitVerification()) {
    //     const body = {
    //       tableName: this.tableName,
    //       statisticType: this.statisticType,
    //       statisticTimeFormat: this.statisticTimeFormat,
    //       baseline: this.baseline,
    //       tableGroupType: this.tableGroupType,
    //       criterial: this.lineArray
    //     }
    //     this.$http.post('/api/pqm/cfrAnalysisMgmt/cfrAnalyzerBookmark', body).then(res => res.data)
    //       .then(res => {
    //         if (res.status === 'success') {
    //           this.snackbar = true
    //           this.snackbarColor = 'green'
    //           this.snackbarMsg = '書籤已建立'
    //           this.getBookmarkList()
    //         } else {
    //           this.snackbar = true
    //           this.snackbarColor = 'red'
    //           this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
    //         }
    //       })
    //   }
    // },
    // doDelBookmark (pk) {
    //   this.$http.delete(`/api/pqm/cfrAnalysisMgmt/cfrAnalyzerBookmark/${pk}`).then(res => res.data)
    //     .then(res => {
    //       if (res.status === 'success') {
    //         this.snackbar = true
    //         this.snackbarColor = 'green'
    //         this.snackbarMsg = '書籤已刪除'
    //         this.getBookmarkList()
    //       } else {
    //         this.snackbar = true
    //         this.snackbarColor = 'red'
    //         this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
    //       }
    //     })
    // },
    doDownload () {
      if (this.doSubmitVerification()) {
        this.processingView = true
        const body = {
          // tableName: this.tableName,
          scopeCriteria: this.settingSelectListRegion.concat(this.settingSelectListModelYear),
          statisticCriteria: this.lineArray,
          excludeBestBuy: this.excludeBestBuy || false
        }
        this.$http.post('/api/pqm/cfr/machineCfrStatistic/machineCfrStatisticDataFile', body, { responseType: 'blob' })
          .then(res => {
            this.processingView = false
            if (res.data.type === 'application/json') {
              return new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.onload = () => {
                  res.data = JSON.parse(reader.result)
                  if (res.data.status === 'success') {
                    alert(res.data.data)
                    // resolve(Promise.reject(res.data.data))
                  } else {
                    this.snackbar = true
                    this.snackbarColor = 'red'
                    this.snackbarMsg = res.data.errMsg + ' (' + res.data.errCode + ')'
                  }
                }
                reader.onerror = () => {
                  reject(res.data.data)
                }
                reader.readAsText(res.data)
              })
            } else if (res.data.status !== 'failure' && res.headers['content-disposition']) {
              const blob = new Blob([res.data], { type: 'application/octet-stream' })
              const url = window.URL.createObjectURL(blob)
              const fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]
              const downloadLink = document.createElement('a')
              downloadLink.href = url
              downloadLink.download = fileName

              document.body.appendChild(downloadLink)
              downloadLink.click()
              document.body.removeChild(downloadLink)
            } else {
              this.snackbar = true
              this.snackbarColor = 'red'
              this.snackbarMsg = '下載失敗，請通知系統管理員'
            }
          })
          .catch(err => {
            this.processingView = false
            this.snackbar = true
            this.snackbarColor = 'orange'
            this.snackbarMsg = err
          })
      }
    }
  }
}
</script>
<style>
</style>
