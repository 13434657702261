<template>
  <v-container
    cfr
    fluid
    grid-list-xl
    pt-0
  >
    <v-layout wrap>
      <v-flex
        xs12
        lg12
      >
        <material-card
          class="card-padding v-card__first"
          color="general"
        >
          <v-form>
            <v-container
              py-0
              class="mw-100">
              <v-layout wrap>
                <v-flex
                  flex-small
                  text-left
                  lg12>
                  <table>
                    <tbody>
                      <tr
                        v-for="(item,i) in systemSettingList"
                        :key="'i'+i"
                        style="background-color:unset;">
                        <td class="my-1 h3 text-right">{{ item.displayName }}</td>
                        <td
                          v-if="item.data.type!=='empty'&&(item.type==='decimal'||item.type==='integer')"
                          class="my-1 px-5 h3 d-flex align-items-center">
                          <v-btn
                            :color="theme==='dark'?'error':'info'"
                            class="m-0 px-2 font-weight-light"
                            @click="doChangeValue(i, item.lowerLimit, -item.interval, item.upperLimit, item.precision)">
                            <v-icon class="float-left">mdi-minus</v-icon>
                          </v-btn>
                          <!-- <span class="h6 ml-2">{{ item.suffixToken }}</span> -->
                          <v-text-field
                            v-model="item.data.value"
                            :min="item.lowerLimit"
                            :step="item.interval"
                            :max="item.upperLimit"
                            :messages="(item.data.value>item.upperLimit||item.data.value<item.lowerLimit)?'數值超出範圍':''"
                            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                            class="mx-2 w-100 v-text-field__slot plus-btn w-10"
                            type="number"
                            persistent-hint
                            outlined
                          />
                          <span class="h6 mr-2">{{ item.suffixToken }}</span>
                          <v-btn
                            :color="theme==='dark'?'error':'info'"
                            class="m-0 px-2 font-weight-light"
                            @click="doChangeValue(i, item.lowerLimit, item.interval, item.upperLimit, item.precision)">
                            <v-icon class="float-left">mdi-plus</v-icon>
                          </v-btn>
                        </td>
                        <td
                          v-if="item.data.type!=='empty'"
                          class="my-1 h3">
                          <v-btn
                            :disabled="item.data.value>item.upperLimit||item.data.value<item.lowerLimit||(Number(item.data.value) === (systemParameterSettingList.filter(n => n.name === item.settingParameterName).length>0 ? Number(systemParameterSettingList.filter(n => n.name === item.settingParameterName)[0].value) : null))"
                            class="m-0 mb-1 font-weight-light"
                            color="blue"
                            @click="doChangeStatus(item.data.pk, item.type, item.data.value, item.precision, item.lowerLimit, item.upperLimit)">
                            <v-icon class="float-left">mdi-content-save</v-icon>儲存
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="processingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        PROCESSING 處理中
      </p>
    </v-dialog>
    <v-dialog
      v-model="loadingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :top="true"
      :color="snackbarColor">
      {{ snackbarMsg }}
      <v-btn
        dark
        flat
        @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
// import qs from 'qs'
import { mapState } from 'vuex'
import Loading from '@/assets/img/loading.gif'
export default {
  name: 'SpeakerSystemParameterSetting',
  data () {
    return {
      startdate: '2018-04-01',
      submit: false,
      snackbar: false,
      snackbarColor: 'red',
      snackbarMsg: '',
      Loading,
      loadingView: false,
      processingView: false,
      systemSettingList: [],
      systemParameterUIComponentSettingList: [
        // {
        //   'name': 'number_setting_somponent',
        //   'settingParameterName': 'weeklyReportQualifiedYieldRate',
        //   'displayName': '週報生產率設定',
        //   'type': 'decimal',
        //   'precision': 1,
        //   'interval': 0.1,
        //   'lowerLimit': 0,
        //   'upperLimit': 100,
        //   'suffixToken': '%'
        // }, {
        //   'name': 'number_setting_somponent',
        //   'settingParameterName': 'weeklyReportQualifiedDPPM',
        //   'displayName': '週報DPPM設定',
        //   'type': 'integer',
        //   'precision': 2,
        //   'interval': 15,
        //   'lowerLimit': 0,
        //   'upperLimit': 100000,
        //   'suffixToken': 'PCS'
        // }
      ],
      systemParameterSettingList: [
        // {
        //   'pk': 3,
        //   'type': 'decimal',
        //   'name': 'weeklyReportQualifiedYieldRate',
        //   'group': 'report',
        //   'value': 0.9
        // }, {
        //   'pk': 3,
        //   'type': 'integer',
        //   'name': 'weeklyReportQualifiedDPPM',
        //   'group': 'report',
        //   'value': 1000
        // }
      ]
    }
  },
  computed: {
    ...mapState('app', ['theme', 'shrink']),
    systemSettingComputList () {
      let array = this.systemParameterUIComponentSettingList

      array.forEach((obj, o) => {
        if (this.systemParameterSettingList.filter(n => n.name === obj.settingParameterName).length > 0) {
          const listIndex = this.systemParameterSettingList.map(n => n.name).indexOf(obj.settingParameterName)
          array[o] = { ...array[o], data: this.systemParameterSettingList[listIndex] }
        } else {
          array[o] = { ...array[o], data: { pk: -999, type: 'empty', name: null, group: null, value: null } }
        }
      })

      return array
    }
  },
  watch: {
    systemSettingComputList (val) {
      this.systemSettingList = JSON.parse(JSON.stringify(val))
    }
  },
  mounted () {
    this.$store.dispatch('getAuthorize', '/keypart/speaker/speakerSystemParameterSettingView')
      .then(response => {
        if (response.status === 'success' && response.data.accessible) {
          this.getSystemParameterSetting()
          this.getSystemParameterUIComponentSetting()
          // this.doSubmit()
        } else if (response.errCode === 'E10000002') {
          const routeData = this.$router.resolve({ path: '/noEnter/E10000002' })
          localStorage.setItem('errormsg', response.errMsg)
          window.location.assign(routeData.href)
        } else {
          const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
          window.location.assign(routeData.href)
        }
      })
      .catch(err => {
        console.log(err)
        const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
        window.location.assign(routeData.href)
      })
  },
  methods: {
    getSystemParameterUIComponentSetting () {
      this.processingView = true
      this.$http.get('/api/keypart-data/speaker/speakerSystemParameterUIComponentSetting').then(res => res.data)
        .then(res => {
          this.processingView = false
          if (res.status === 'success') {
            this.systemParameterUIComponentSettingList = res.data
          } else { // failure
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
          }
        }).catch(err => {
          this.processingView = false
          this.snackbar = true
          this.snackbarColor = 'red'
          this.snackbarMsg = err
        })
    },
    getSystemParameterSetting () {
      this.$http.get('/api/keypart-data/speaker/speakerSystemParameterSetting').then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.systemParameterSettingList = res.data
          } else { // failure
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
          }
        }).catch(err => {
          this.processingView = false
          this.snackbar = true
          this.snackbarColor = 'red'
          this.snackbarMsg = err
        })
    },
    doToFixed (val, precision, min, max) {
      return Math.max(Math.min(val.toFixed(precision), max), min)
    },
    doChangeValue (i, min, interval, max, precision) {
      this.systemSettingList[i].data.value = this.doToFixed(Number(this.systemSettingList[i].data.value) + interval, precision, min, max)
    },
    doChangeStatus (pk, type, value, precision, min, max) {
      this.submit = true
      this.loadingView = true
      const body = {
        pk: pk,
        type: type,
        value: this.doToFixed(Number(value), precision, min, max)
      }
      this.$http.put(`/api/keypart-data/speaker/speakerSystemParameterSetting`, body).then(res => res.data)
        .then(res => {
          this.loadingView = false
          if (res.status === 'success') {
            this.getSystemParameterSetting()
            this.snackbar = true
            this.snackbarColor = 'green'
            this.snackbarMsg = '修改成功'
          } else { // failure
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
          }
        }).catch(err => {
          this.loadingView = false
          this.snackbar = true
          this.snackbarColor = 'red'
          this.snackbarMsg = err
        })
    }
  }
}
</script>
<style>
.plus-btn input[type="number"]::-webkit-outer-spin-button,
.plus-btn input[type="number"]::-webkit-inner-spin-button {
  appearance: none !important;
}
</style>
