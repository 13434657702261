<template>
  <v-navigation-drawer
    id="app-drawer"
    v-model="inputValue"
    :dark="theme==='dark'"
    :light="theme==='light'"
    :class="{'drawer_bg_dark' : theme==='dark' , 'drawer_bg_light' : theme==='light', 'v-navigation-small': shrink === '50' }"
    :style="'margin-left: -'+(shrink-shrink)+'px; width: '+shrink+'px; z-index: 202!important;transform: translateX(0px)!important;overflow: visible;top: 0px;left: 0px;position: fixed;'"
    app
    floating
    persistent
    mobile-break-point="991"
    style="max-height: none;"
    absolute
  >
    <v-img
      :src="image"
      style="cursor: unset;overflow-y: auto;overflow-x: hidden;"
      height="100%"
    >
      <v-layout
        class="fill-height"
        tag="v-list"
        column
      >
        <v-list-tile
          class="mb-3"
          style="height: 48px;">
          <v-list-tile-avatar
            :class="{ 'v-list__tile__avatar__small': shrink === '50' }"
            color=""
          >
            <v-img
              :src="logo"
              style="cursor: default;"
              height=""
              contain
            />
          </v-list-tile-avatar>
          <v-list-tile-title
            :style="theme=='light' ? 'color:#000;' : 'color:#FFF;'"
            class="title"
            style="position: relative;overflow: visible;">
            {{ webName }}
            <p style="position: absolute;bottom: -20px;margin: 0;font-size: 12px !important;opacity: 0.75;">v {{ version }}</p>
          </v-list-tile-title>
        </v-list-tile>
        <v-divider
          :class="{'drawer_border_dark' : theme==='dark' , 'drawer_border_light' : theme==='light'}"
          class="mb-0"
        />
        <!-- <v-list-tile
          v-if="responsive"
        >
          <v-text-field
            class="purple-input search-input"
            label="Search..."
            color="purple"
          />
        </v-list-tile> -->
        <div
          v-for="(link, i) in demo ? demolinks : links"
          :key="i"
        >
          <v-list-tile
            :to="link.to"
            :class="{ 'v-list-item-small': shrink === '50' }"
            avatar
            class="v-list-item"
            @click="navItem = navItem === link.text ? '' : link.text"
          >
            <v-list-tile-action>
              <v-icon
                class="nav_icon"
              >{{ link.icon }}</v-icon>
            </v-list-tile-action>
            <v-list-tile-title
              v-text="link.text"
            />
          </v-list-tile>
          <div
            :class="{ 'nav_open':navItem === link.text, 'nav_list_small': shrink === '50' }"
            class="nav_list">
            <v-list-tile
              v-for="(clink, c) in link.children"
              :key="c"
              :to="clink.to"
              avatar
              class="v-list-item nav_children"
            >
              <v-list-tile-action>
                <v-icon
                  class="nav_icon"
                >{{ clink.icon }}</v-icon>
              </v-list-tile-action>
              <v-list-tile-title
                v-text="clink.text"
              />
            </v-list-tile>
          </div>
        </div>
      </v-layout>
    </v-img>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import logo from '@/assets/img/logo.svg'
import {
  mapMutations,
  mapState
} from 'vuex'

export default {
  data: () => ({
    // logo: require('@/assets/img/redditicon.png'),
    navItem: '',
    logo,
    version: '0*',
    webName: '品質管理平台*',
    links: JSON.parse(localStorage.getItem('normalMenu')),
    demolinks: JSON.parse(localStorage.getItem('devMenu')),
    responsive: false
  }),
  computed: {
    ...mapState('app', ['image', 'color', 'theme', 'shrink', 'demo']),
    inputValue: {
      get () {
        return this.$store.state.app.drawer
      },
      set (val) {
        this.setDrawer(val)
      }
    },
    items () {
      return this.$t('Layout.View.items')
    }
  },
  mounted () {
    this.webName = process.env.VUE_APP_NAME
    this.version = process.env.VUE_APP_VERSION
    if (!JSON.parse(localStorage.getItem('normalMenu')) || !JSON.parse(localStorage.getItem('devMenu'))) {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/')
      })
    }
    this.onResponsiveInverted()
    window.addEventListener('resize', this.onResponsiveInverted)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResponsiveInverted)
  },
  methods: {
    ...mapMutations('app', ['setDrawer', 'toggleDrawer']),
    onResponsiveInverted () {
      if (window.innerWidth < 991) {
        this.responsive = true
      } else {
        this.responsive = false
      }
    }
  }
}
</script>

<style lang="scss">
  #app-drawer {
    .v-list__tile__action {
      min-width: auto!important;
    }

    a.v-list__tile--active:hover {
      color: #FFF !important;
      text-decoration:none !important;
    }

    .theme--dark a.v-list__tile--avatar:hover {
      color: #FFF !important;
      text-decoration:none !important;
    }

    .theme--light a.v-list__tile--avatar:hover {
      color: #000 !important;
      text-decoration:none !important;
    }

    .v-list__tile {
      border-radius: 4px;

      &--buy {
        margin-top: auto;
        margin-bottom: 17px;
      }
    }

    .nav_children {
      margin: 0 15px;
    }

    .nav_children a {
      padding: 0;
      padding-left: 15px;
    }

    .v-image__image--contain {
      top: 9px;
      height: 60%;
    }

    .search-input {
      margin-bottom: 30px !important;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .drawer_bg_dark {
    -webkit-background-image: repeating-linear-gradient(to left, RGBA(0,0,0,0.25) 0%, RGBA(0,0,0,0.25) 6%, RGBA(0,0,0,0.25)  7.5%), repeating-linear-gradient(to left, RGBA(0,0,0,0.25) 0%, RGBA(0,0,0,0.25) 4%, RGBA(0,0,0,0.25) 4.5%), repeating-linear-gradient(to left, RGBA(90,0,0,0.25) 0%, RGBA(0,0,0,0.25) 1.2%, RGBA(90,0,0,0.25) 2.2%), linear-gradient(150deg, #000 30%, #A00 47%, #500 53%,  #000 70%);
    -moz-background-image: repeating-linear-gradient(to left, RGBA(0,0,0,0.25) 0%, RGBA(0,0,0,0.25) 6%, RGBA(0,0,0,0.25)  7.5%), repeating-linear-gradient(to left, RGBA(0,0,0,0.25) 0%, RGBA(0,0,0,0.25) 4%, RGBA(0,0,0,0.25) 4.5%), repeating-linear-gradient(to left, RGBA(90,0,0,0.25) 0%, RGBA(0,0,0,0.25) 1.2%, RGBA(90,0,0,0.25) 2.2%), linear-gradient(150deg, #000 30%, #A00 47%, #500 53%,  #000 70%);
    -o-background-image: repeating-linear-gradient(to left, RGBA(0,0,0,0.25) 0%, RGBA(0,0,0,0.25) 6%, RGBA(0,0,0,0.25)  7.5%), repeating-linear-gradient(to left, RGBA(0,0,0,0.25) 0%, RGBA(0,0,0,0.25) 4%, RGBA(0,0,0,0.25) 4.5%), repeating-linear-gradient(to left, RGBA(90,0,0,0.25) 0%, RGBA(0,0,0,0.25) 1.2%, RGBA(90,0,0,0.25) 2.2%), linear-gradient(150deg, #000 30%, #A00 47%, #500 53%,  #000 70%);
    -ms-background-image: repeating-linear-gradient(to left, RGBA(0,0,0,0.25) 0%, RGBA(0,0,0,0.25) 6%, RGBA(0,0,0,0.25)  7.5%), repeating-linear-gradient(to left, RGBA(0,0,0,0.25) 0%, RGBA(0,0,0,0.25) 4%, RGBA(0,0,0,0.25) 4.5%), repeating-linear-gradient(to left, RGBA(90,0,0,0.25) 0%, RGBA(0,0,0,0.25) 1.2%, RGBA(90,0,0,0.25) 2.2%), linear-gradient(150deg, #000 30%, #A00 47%, #500 53%,  #000 70%);
    background-image: repeating-linear-gradient(to left, RGBA(0,0,0,0.25) 0%, RGBA(0,0,0,0.25) 6%, RGBA(0,0,0,0.25)  7.5%), repeating-linear-gradient(to left, RGBA(0,0,0,0.25) 0%, RGBA(0,0,0,0.25) 4%, RGBA(0,0,0,0.25) 4.5%), repeating-linear-gradient(to left, RGBA(90,0,0,0.25) 0%, RGBA(0,0,0,0.25) 1.2%, RGBA(90,0,0,0.25) 2.2%), linear-gradient(150deg, #000 30%, #A00 47%, #500 53%,  #000 70%);

}

  .drawer_bg_light {
    -webkit-background-image: repeating-linear-gradient(to left, RGBA(255,255,255,0.25) 0%, RGBA(255,255,255,0.25) 6%, RGBA(255,255,255,0.25)  7.5%), repeating-linear-gradient(to left, RGBA(255,255,255,0.25) 0%, RGBA(255,255,255,0.25) 4%, RGBA(255,255,255,0.25) 4.5%), repeating-linear-gradient(to left, RGBA(150,190,250,0.25) 0%, RGBA(255,255,255,0.25) 1.2%, RGBA(150,190,250,0.25) 2.2%), linear-gradient(150deg, #FFF 30%, #8EF 47%, #9EF 53%,  #FFF 70%);
    -moz-background-image: repeating-linear-gradient(to left, RGBA(255,255,255,0.25) 0%, RGBA(255,255,255,0.25) 6%, RGBA(255,255,255,0.25)  7.5%), repeating-linear-gradient(to left, RGBA(255,255,255,0.25) 0%, RGBA(255,255,255,0.25) 4%, RGBA(255,255,255,0.25) 4.5%), repeating-linear-gradient(to left, RGBA(150,190,250,0.25) 0%, RGBA(255,255,255,0.25) 1.2%, RGBA(150,190,250,0.25) 2.2%), linear-gradient(150deg, #FFF 30%, #8EF 47%, #9EF 53%,  #FFF 70%);
    -o-background-image: repeating-linear-gradient(to left, RGBA(255,255,255,0.25) 0%, RGBA(255,255,255,0.25) 6%, RGBA(255,255,255,0.25)  7.5%), repeating-linear-gradient(to left, RGBA(255,255,255,0.25) 0%, RGBA(255,255,255,0.25) 4%, RGBA(255,255,255,0.25) 4.5%), repeating-linear-gradient(to left, RGBA(150,190,250,0.25) 0%, RGBA(255,255,255,0.25) 1.2%, RGBA(150,190,250,0.25) 2.2%), linear-gradient(150deg, #FFF 30%, #8EF 47%, #9EF 53%,  #FFF 70%);
    -ms-background-image: repeating-linear-gradient(to left, RGBA(255,255,255,0.25) 0%, RGBA(255,255,255,0.25) 6%, RGBA(255,255,255,0.25)  7.5%), repeating-linear-gradient(to left, RGBA(255,255,255,0.25) 0%, RGBA(255,255,255,0.25) 4%, RGBA(255,255,255,0.25) 4.5%), repeating-linear-gradient(to left, RGBA(150,190,250,0.25) 0%, RGBA(255,255,255,0.25) 1.2%, RGBA(150,190,250,0.25) 2.2%), linear-gradient(150deg, #FFF 30%, #8EF 47%, #9EF 53%,  #FFF 70%);
    background-image: repeating-linear-gradient(to left, RGBA(255,255,255,0.25) 0%, RGBA(255,255,255,0.25) 6%, RGBA(255,255,255,0.25)  7.5%), repeating-linear-gradient(to left, RGBA(255,255,255,0.25) 0%, RGBA(255,255,255,0.25) 4%, RGBA(255,255,255,0.25) 4.5%), repeating-linear-gradient(to left, RGBA(150,190,250,0.25) 0%, RGBA(255,255,255,0.25) 1.2%, RGBA(150,190,250,0.25) 2.2%), linear-gradient(150deg, #FFF 30%, #8EF 47%, #9EF 53%,  #FFF 70%);
  }

  .drawer_border_dark {
    -webkit-border-image: repeating-linear-gradient(150deg, #000 0%, #A00 25%, #500 75%, #000 100%)!important;
    -moz-border-image: repeating-linear-gradient(150deg, #000 0%, #A00 25%, #500 75%, #000 100%)!important;
    -o-border-image: repeating-linear-gradient(150deg, #000 0%, #A00 25%, #500 75%, #000 100%)!important;
    -ms-border-image: repeating-linear-gradient(150deg, #000 0%, #A00 25%, #500 75%, #000 100%)!important;
    border-image: repeating-linear-gradient(150deg, #000 0%, #A00 25%, #500 75%, #000 100%)!important;
    border-image-slice: 1!important;
  }

  .drawer_border_light {
    -webkit-border-image: repeating-linear-gradient(150deg, #FFF 0%, #8EF 25%, #9EF 75%, #FFF 100%)!important;
    -moz-border-image: repeating-linear-gradient(150deg, #FFF 0%, #8EF 25%, #9EF 75%, #FFF 100%)!important;
    -o-border-image: repeating-linear-gradient(150deg, #FFF 0%, #8EF 25%, #9EF 75%, #FFF 100%)!important;
    -ms-border-image: repeating-linear-gradient(150deg, #FFF 0%, #8EF 25%, #9EF 75%, #FFF 100%)!important;
    border-image: repeating-linear-gradient(150deg, #FFF 0%, #8EF 25%, #9EF 75%, #FFF 100%)!important;
    border-image-slice: 1!important;
  }

  .theme--dark .nav_icon {
    color: #f44336 !important;
  }

  .theme--light .nav_icon {
    color: #1e88e5 !important;
  }

  .theme--dark .v-list__tile--active {
    background-color: #f44336!important;
    border-color: #f44336!important;
  }

  .theme--light .v-list__tile--active {
    background-color: #2196f3 !important;
    border-color: #2196f3 !important;
  }

  .theme--light.v-list {
    color: #000 !important;
  }

  .theme--dark.v-list {
    color: #fff !important;
  }

  .v-avatar {
    box-shadow:none !important;
  }

  .v-list__tile--active .v-list__tile__title, .v-list__tile--active .nav_icon {
    color: #fff !important;
    font-weight: bold!important;
  }

  .v-navigation-small {
    transition: 0.1s;
  }

  .v-navigation-small:hover {
    width: 260px!important;
  }

  .v-navigation-small:hover #app-drawer-shrink{
   left: 245px!important;
  }

  .v-navigation-drawer .v-list .v-list__tile--avatar {
    height: auto;
    padding: 15px 0;
  }

  .v-navigation-drawer .v-list .v-list__tile__avatar {
    min-width: 40px;
  }

  .v-navigation-small .v-list__tile__avatar__small {
    transition: 0.1s;
    margin-left: -10px!important;
    margin-right: 35px!important;
  }

  .v-navigation-small:hover .v-list__tile__avatar__small {
    margin-left: 25px!important;
    margin-right: 11px!important;
  }

  .v-list-item-small {
    transition: 0.1s;
    margin-left: -20px!important;
  }

  .v-navigation-small:hover .v-list-item-small {
    margin-left: 15px!important;
  }

  .v-list-item-small .nav_icon {
    font-size: 2rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .v-navigation-small:hover .v-list-item-small .nav_icon {
    font-size:24px;
    margin-left:0px;
    margin-right:0px;
  }

  .nav_list {
    max-height: 0;
    overflow: hidden;
    transition: 0.25s;
  }

  .v-navigation-small .nav_list_small {
    display: none;
  }

  .v-navigation-small:hover .nav_list_small {
    display: block;
  }

  .nav_open {
    max-height: 100vh;
  }
  .list-select-field .v-select__selection.v-select__selection--comma{
    border: 1px #2196f3 solid;
    border-radius: 5px;
    padding: 2px 5px;
  }
/* IE10+ CSS styles go here */
_:-ms-lang(x), .v-navigation-drawer .v-list .v-list__tile__title { line-height:normal!important; }

</style>
