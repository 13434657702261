<template>
  <v-container
    lcd
    fluid
    grid-list-xl
    pt-0
  >
    <v-layout wrap>
      <!-- <v-flex
        xs12
        sm6
        lg3>
        <material-stats-card
          :value="summaryCaseName"
          style="margin-bottom:-20px !important;"
          class="v-offset-none"
          color="purple"
          icon="mdi-folder-open"
          title="案名"/>
      </v-flex>
      <v-flex
        xs12
        sm6
        lg3>
        <material-stats-card
          :value="summaryDate"
          style="margin-bottom:-20px !important;"
          color="indigo"
          icon="mdi-calendar"
          title="資料日期"/>
      </v-flex>
      <v-flex
        xs12
        sm6
        lg3>
        <material-stats-card
          :value="summaryEMS"
          style="margin-bottom:-20px !important;"
          color="light-blue"
          icon="mdi-factory"
          title="EMS"/>
      </v-flex>
      <v-flex
        xs12
        sm6
        lg3>
        <material-stats-card
          :value="(responseAreaData.failRate * 100).toFixed(1) + '%'"
          :class="responseAreaData.warningColor || 'green'"
          :color="responseAreaData.color"
          :icon="responseAreaData.icon || 'mdi-check'"
          class="title-color"
          style="margin-bottom:-20px !important;"
          title="Fail Rate"/>
      </v-flex> -->
      <v-flex
        class="v-flex__first"
        style="margin-top: 55px !important;"
        xs12>
        <material-card
          class="v-flex v-c3-chart"
        >
          <div
            class="chart-btn c3-chart c3-click"
            style="margin-top: -3.5rem!important;">
            <material-notification
              color="brown"
              class="mb-3 h4 font-weight-bold"
            >
              2D(x,y)散佈圖 [Mode：{{ calibrationMode }}]
              <div class="float-right">
                <v-btn
                  v-for="(mode,m) in calibrationModeMenu"
                  :key="'m'+m"
                  class="black text-light mr-0 ml-2 px-2"
                  @click="calibrationMode=mode">{{ mode }}</v-btn>
              </div>
              <p class="m-0">Factory：{{ propData.factoryName }} ｜ Project：{{ propData.projectName }} ｜ Time Interval：{{ propData.startTime }} ~ {{ propData.endTime }}</p>
              <!-- <h6 class="my-1 float-right">篩選條件最後更新時間：{{ lcdFilterUpdateTime }}</h6> -->
            </material-notification>
            <vue-c3 :handler="handler_bar"/>
          </div>
          <div>
            <v-flex
              xs12
              style="margin-left: 16px!important;padding: 0!important;min-width: 200px!important;">
              <v-text-field
                v-model="target.x"
                :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                label="目標X軸設定"
                class="v-text-field__slot v-text-field__details-none"
                type="number"
                step="0.001"
                persistent-hint
                outlined
              />
            </v-flex>
            <v-flex
              xs12
              style="margin-left: 16px!important;padding: 0!important;min-width: 200px!important;">
              <v-text-field
                v-model="target.y"
                :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                label="目標Y軸設定"
                class="v-text-field__slot v-text-field__details-none"
                type="number"
                step="0.001"
                persistent-hint
                outlined
              />
            </v-flex>
            <v-flex
              xs12
              m-4/>
            <v-flex
              xs12
              style="margin-left: 16px!important;padding: 0!important;min-width: 200px!important;">
              <v-text-field
                v-model="spec.x.high"
                :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                label="SPEC X值上限"
                class="v-text-field__slot v-text-field__details-none"
                type="number"
                step="0.001"
                persistent-hint
                outlined
              />
            </v-flex>
            <v-flex
              xs12
              style="margin-left: 16px!important;padding: 0!important;min-width: 200px!important;">
              <v-text-field
                v-model="spec.x.low"
                :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                label="SPEC X值下限"
                class="v-text-field__slot v-text-field__details-none"
                type="number"
                step="0.001"
                persistent-hint
                outlined
              />
            </v-flex>
            <v-flex
              xs12
              m-4/>
            <v-flex
              xs12
              style="margin-left: 16px!important;padding: 0!important;min-width: 200px!important;">
              <v-text-field
                v-model="spec.y.high"
                :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                label="SPEC Y值上限"
                class="v-text-field__slot v-text-field__details-none"
                type="number"
                step="0.001"
                persistent-hint
                outlined
              />
            </v-flex>
            <v-flex
              xs12
              style="margin-left: 16px!important;padding: 0!important;min-width: 200px!important;">
              <v-text-field
                v-model="spec.y.low"
                :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                label="SPEC Y值下限"
                class="v-text-field__slot v-text-field__details-none"
                type="number"
                step="0.001"
                persistent-hint
                outlined
              />
            </v-flex>
          </div>
        </material-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="loadingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :top="true"
      :color="snackbarColor">
      {{ snackbarMsg }}
      <v-btn
        dark
        flat
        @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import Vue from 'vue'
import VueC3 from 'vue-c3'
import 'c3/c3.min.css'
import { mapState } from 'vuex'
import Loading from '@/assets/img/loading.gif'

export default {
  name: 'ShowLcdCalibration2DXYScatterPlot',
  components: {
    VueC3
  },
  data () {
    return {
      snackbar: false,
      snackbarColor: 'red',
      snackbarMsg: '',
      Loading,
      loadingView: false,
      propData: {},
      // lcdFilterUpdateTime: '',
      calibrationMode: 'NoGM',
      calibrationModeMenu: [],
      target: {
        x: 0.313,
        y: 0.329
      },
      spec: {
        x: {
          high: 0.343,
          low: 0.283
        },
        y: {
          high: 0.359,
          low: 0.299
        }
      },
      responseAreaData: [],
      barXaxisItem: ['beforeWx', 'beforeWy', 'finalWx', 'finalWy'],
      barXaxisItemName: ['beforeCalibration_X', 'beforeCalibration', 'afterCalibration_x', 'afterCalibration'],
      barXaxisName: [],
      barXaxisValue: [],
      handler_bar: new Vue()
    }
  },
  computed: {
    ...mapState('app', ['theme', 'shrink']),
    xAxisDataArray () {
      const x = []
      for (let i = 0.275; i <= 0.350; i += 0.005) {
        x.push(i.toFixed(3))
      }
      return x
    },
    yAxisDataArray () {
      const y = []
      for (let i = 0.290; i <= 0.365; i += 0.005) {
        y.push(i.toFixed(3))
      }
      return y
    },
    options_bar () {
      return {
        data: {
          names: {
            beforeCalibration: '校準前',
            afterCalibration: '校準後',
            target: '目標'
          },
          xs: {
            beforeCalibration: 'beforeCalibration_X',
            afterCalibration: 'afterCalibration_x',
            target: 'target_x'
          },
          columns: this.barXaxisValue.concat([['target_x', this.target.x]]).concat([['target', this.target.y]]),
          labels: true,
          // labels: {
          //   // format: function (v, id, i, j) { return v + '%' }
          // },
          // onclick: function (d) { alert(d.index) },
          // onclick: (d) => { this.summaryList(d.index, 'range') },
          type: 'scatter',
          colors: {
            // afterCalibration: '#ff0000',
            target: 'green'
          }
          // onclick: (d, i) => { this.summaryList(d, i) } // {id: "data23", value: 120, ratio: 0.8, index: 1, name: "data23"}
          // color: function (color, d) {
          //   // d will be 'id' when called for legends
          //   return d.value >= 5.0 ? '#ff0000' : d.value >= 3.0 ? '#ff9800' : color
          // }
          // color: (color, d) => {
          //   // d will be 'id' when called for legends
          //   return (this.OverRangeCount > 0 && d.index === this.barXaxisName.length - 2) ? '#ffa500' : color
          // }
        },
        axis: {
          x: {
            label: '數列X值',
            min: 0.275,
            max: 0.350,
            tick: {
              values: this.xAxisDataArray
            }
          },
          y: {
            label: '數列Y值',
            min: 0.290,
            max: 0.365,
            tick: {
              values: this.yAxisDataArray
            }
          }
        },
        tooltip: {
          contents: (d, defaultTitleFormat, defaultValueFormat, color) => {
            let data = []
            if (d[0].x === this.target.x) {
              data.push({ type: 'target', name: '目標', sn: '', color: 'green', x: this.target.x, y: this.target.y })
            }
            this.responseAreaData.forEach((n) => {
              if (d[0].x === n.beforeWx) {
                data.push({ type: 'beforeCalibration', name: '校準前', sn: n.deviceIsn, color: '#1f77b4', x: n.beforeWx, y: n.beforeWy })
              }
              if (d[0].x === n.finalWx) {
                data.push({ type: 'afterCalibration', name: '校準後', sn: n.deviceIsn, color: '#ff7f0e', x: n.finalWx, y: n.finalWy })
              }
            })
            let table = ''
            data.sort((a, b) => b.y - a.y).forEach((n) => {
              table += `
                <tr class="c3-tooltip-name--${n.type}">
                  <td class="type"><span style="background-color:${n.color}"></span></td>
                  <td class="name text-right">${n.sn} ${n.name}</td>
                  <td class="value">(${n.x} , ${n.y})</td>
                </tr>
              `
            })
            return `
              <table class="c3-tooltip">
                <tbody>
                  <!--<tr>
                    <th colspan="3"></th>
                  </tr>-->
                  ${table}
                </tbody>
              </table>
            `
          }
          // format: {
          //   title: (d, Value) => {
          //     return `${d}}`
          //   },
          //   valuee: (value, ratio, id) => {
          //     return `${value}`
          //   }
          // }
        },
        grid: {
          x: {
            show: true,
            lines: [
              { value: this.spec.x.low, text: 'x(下限)', position: 'start', class: 'scatter-assign-board-line' },
              { value: this.spec.x.high, text: 'x(上限)', position: 'end', class: 'scatter-assign-board-line' }
            ]
          },
          y: {
            show: true,
            lines: [
              { value: this.spec.y.low, text: 'y(下限)', position: 'start', class: 'scatter-assign-board-line' },
              { value: this.spec.y.high, text: 'y(上限)', position: 'end', class: 'scatter-assign-board-line' }
            ]
          }
        },
        legend: {
          position: 'bottom',
          padding: 16,
          show: true
        },
        point: {
          r: function (d) {
            return d.id === 'target' ? 8 : 3.5
          }
        },
        size: {
          // width: 640,
          height: 600
        }
      }
    }
  },
  watch: {
    target: {
      handler: function (val, oldVal) { this.doChart() },
      deep: true
    },
    spec: {
      handler: function (val, oldVal) { this.doChart() },
      deep: true
    },
    calibrationMode () {
      this.doSubmit()
    }
  },
  mounted () {
    this.$store.dispatch('getAuthorize', '/lcd/lcdCalibrationAnalyzer/showLcdCalibration2DXYScatterPlotView')
      .then(response => {
        if (response.status === 'success' && response.data.accessible) {
          this.propData = JSON.parse(localStorage.getItem('lcdFilterInfo'))
          // this.lcdFilterUpdateTime = localStorage.getItem('lcdFilterUpdateTime')
          this.doSubmit()
          this.$http.post('/api/lcd/analyzeMgmt/calibrationMode', this.propData).then(res => res.data)
            .then(res => {
              if (res.status === 'success') {
                this.calibrationModeMenu = res.data
              }
            })
        } else if (response.errCode === 'E10000002') {
          const routeData = this.$router.resolve({ path: '/noEnter/E10000002' })
          localStorage.setItem('errormsg', response.errMsg)
          window.location.assign(routeData.href)
        } else {
          const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
          window.location.assign(routeData.href)
        }
      })
      .catch(err => {
        console.log(err)
        const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
        window.location.assign(routeData.href)
      })
  },
  methods: {
    doChart (type) {
      this.barXaxisValue = []
      this.barXaxisItem.forEach((item, index) => {
        this.barXaxisValue.push([this.barXaxisItemName[index]])
        this.responseAreaData.forEach((r) => {
          if (r[item] >= 0) {
            this.barXaxisValue[index].push(r[item])
          }
        })
      })
      if (type === 'init') {
        this.handler_bar.$emit('init', this.options_bar)
      } else {
        this.handler_bar.$emit('dispatch', chart => {
          const options = {
            xs: { target: 'target_x' },
            columns: this.barXaxisValue.concat([['target_x', this.target.x]]).concat([['target', this.target.y]])
          }
          const optionsXline = [
            { value: this.spec.x.low, text: 'x(下限)', position: 'start', class: 'scatter-assign-board-line' },
            { value: this.spec.x.high, text: 'x(上限)', position: 'end', class: 'scatter-assign-board-line' }
          ]
          const optionsYline = [
            { value: this.spec.y.low, text: 'y(下限)', position: 'start', class: 'scatter-assign-board-line' },
            { value: this.spec.y.high, text: 'y(上限)', position: 'end', class: 'scatter-assign-board-line' }
          ]
          chart.load(options)
          chart.xgrids(optionsXline)
          chart.ygrids(optionsYline)
        })
      }
    },
    doSubmit () {
      this.loadingView = true
      this.$http.post(`/api/lcd/analyzeMgmt/2DXYDataList/${this.calibrationMode}`, this.propData).then(res => res.data)
        .then(res => {
          this.loadingView = false
          if (res.status === 'success') {
            this.responseAreaData = res.data
            this.doChart('init')
          } else {
            this.responseAreaData = []
            this.doChart('init')
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = '查無資料'
          }
        })
        .catch(err => {
          this.responseAreaData = []
          this.doChart('init')
          this.loadingView = false
          this.snackbar = true
          this.snackbarColor = 'orange'
          this.snackbarMsg = err
        })
    }
  }
}
</script>
<style>
/* .c3-axis-x .tick tspan:hover {
  cursor: pointer;
} */
.c3 .scatter-assign-board-line line{
  stroke: hotpink;
  stroke-width: 2;
  stroke-dasharray: 6;
}
.c3 .c3-circles-target circle{
  fill: green;
  opacity: 1 !important;
}
</style>
