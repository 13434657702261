<template>
  <v-container
    fluid
    grid-list-xl
    pt-0
  >
    <v-layout wrap>
      <!-- <v-flex
        xs12
        sm6
        lg2>
        <material-stats-card
          v-model="summaryCaseName"
          style="margin-bottom:-20px !important;"
          color="purple"
          icon="mdi-folder-open"
          title="案名"/>
      </v-flex> -->
      <v-flex
        xs12
        sm6
        lg8>
        <v-layout wrap>
          <v-flex
            xs12
            sm6
            lg4>
            <material-stats-card
              :value="responseAreaData.modelName"
              style="margin-bottom:-20px !important;margin-top:28px !important;"
              color="purple"
              icon="mdi-television-guide"
              title="機種名稱"/>
          </v-flex>
          <v-flex
            xs12
            sm6
            lg4>
            <material-stats-card
              :value="responseAreaData.deviceIsn"
              style="margin-bottom:0px !important;margin-top:28px !important;"
              color="deep-purple"
              icon="mdi-nut"
              title="ASUS PN"/>
          </v-flex>
          <v-flex
            xs12
            sm6
            lg4>
            <material-stats-card
              :value="responseAreaData.vendor"
              style="margin-bottom:-20px !important;margin-top:28px !important;"
              color="indigo"
              icon="mdi-factory"
              title="供應商"/>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex
        xs12
        sm6
        lg4>
        <!-- <v-btn
          class="mx-0 font-weight-light"
          color="primary"
          @click="doSubmit()"
        >
          <v-icon class="mr-2">mdi-chart-bar</v-icon>當日統計
        </v-btn> -->
        <material-stats-card
          :value="responseAreaData.launchDate + ' ' + responseAreaData.launchTime"
          style="margin-bottom:-20px !important;margin-top:25px !important;padding-right: 125px;!important;"
          color="light-blue"
          icon="mdi-clock"
          title="測試時間"/>
        <router-link
          :to="'/thermalModule/pl2/thermalModuleTestDataStatistc?deviceIsn='+responseAreaData.deviceIsn+'&modelname='+responseAreaData.modelName+'&date='+responseAreaData.launchDate+'&vendorpk='+responseAreaData.vendorPk+'&vendor='+responseAreaData.vendor"
          target="_blank">
          <v-btn
            style="position: absolute;right: 27px;top: 45px;"
            class="mx-0 px-3 font-weight-light"
            color="primary">
            <v-icon class="mr-2">mdi-chart-bar</v-icon>當日統計
          </v-btn>
        </router-link>
      </v-flex>
      <v-flex
        xs12
        lg3>
        <v-layout wrap>
          <v-flex
            xs12>
            <material-stats-card
              :value="responseAreaData.fixtureId"
              style="margin-bottom:-20px !important;margin-top:5px !important;margin-right: 5px!important;width: calc(((100% - 120px) / 3) + 109px);float: left;"
              color="blue-grey"
              icon="mdi-factory"
              title="機台號"/>
            <material-stats-card
              :value="responseAreaData.stationId"
              class="v-offset-none"
              style="margin-bottom:-20px !important;margin-top:5px !important;margin-right: 5px!important;width: calc((100% - 120px) / 3);float: left;"
              color="cyan"
              icon="mdi-check"
              title="站別"/>
            <material-stats-card
              :value="responseAreaData.roomTemperature"
              class="v-offset-none"
              style="margin-bottom:-20px !important;margin-top:5px !important;width: calc((100% - 120px) / 3);float: left;"
              color="cyan"
              icon="mdi-check"
              title="室溫"/>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex
        xs12
        lg9>
        <v-layout wrap>
          <v-flex
            xs12
            lg6>
            <material-stats-card
              :value="responseAreaData.barcode"
              style="margin-bottom:-20px !important;margin-top:5px !important;"
              color="grey"
              icon="mdi-barcode"
              title="測試料號條碼號"/>
          </v-flex>
          <v-flex
            xs12
            sm8
            lg6>
            <material-stats-card
              :value="responseAreaData.errorMessage || 'pass'"
              :color="responseAreaData.color"
              :class="responseAreaData.warningColor || 'green'"
              :icon="responseAreaData.icon || 'mdi-check'"
              class="title-color"
              style="margin-bottom:-20px !important;margin-top:5px !important;margin-right: 5px!important;width: calc(((100% - 115px) * 0.75) + 109px);float: left;"
              title="ERROR CODE"/>
            <material-stats-card
              :value="responseAreaData.testResult"
              :color="responseAreaData.color"
              :class="responseAreaData.warningColor || 'green'"
              :icon="responseAreaData.icon || 'mdi-check'"
              class="title-color v-offset-none"
              style="margin-bottom:-20px !important;margin-top:5px !important;width: calc((100% - 115px) * 0.25);float: left;"
              title="測試結果"/>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex
        xs12
        md12
      >
        <material-card
          class="card-shrink v-card__first"
          color="deep-orange"
          title="熱測數據清單"
        >
          <v-data-table
            :headers="headers"
            :items="items"
            :pagination.sync="pagination"
            multi-sort
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="subheading font-weight-light text-general text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <!-- <td
                class="td-check"
                style="position: relative;">
                <v-checkbox
                  v-model="checkList"
                  :value="item.SN"
                  class="mx-2"
                  style="position: absolute;top: 10px;"
                  color="orange"
                  label=""/>
              </td> -->
              <td>{{ item.Type }}</td>
              <td>{{ item.Rspec }}</td>
              <td class="text-xs-right">{{ item.R }}</td>
              <td class="text-xs-right">{{ item.temperature }}</td>
              <td class="text-xs-right">{{ item.TA }}</td>
              <td class="text-xs-right">{{ item.power }}</td>
            </template>
          </v-data-table>
        </material-card>
      </v-flex>
      <v-flex
        class="v-flex__first"
        xs12
        md6
      >
        <material-card
          class="v-flex v-c3-chart"
          color="green"
        >
          <div class="w-100 chart-btn c3-chart">
            <material-notification
              color="brown"
              class="mb-3 h4 font-weight-bold"
            >
              當日數據分佈_CPU R
              <div class="float-right">
                <v-icon class="text-warning">mdi-checkbox-blank</v-icon>
                表模組數據的分佈落點</div>
            </material-notification>
            <vue-c3 :handler="handler_bar_CPU"/>
          </div>
        </material-card>
      </v-flex>
      <v-flex
        class="v-flex__first"
        xs12
        md6
      >
        <material-card
          class="v-flex v-c3-chart"
          color="green"
        >
          <div class="w-100 chart-btn c3-chart">
            <material-notification
              color="brown"
              class="mb-3 h4 font-weight-bold"
            >
              當日數據分佈_VGA R
              <div class="float-right">
                <v-icon class="text-warning">mdi-checkbox-blank</v-icon>
                表模組數據的分佈落點</div>
            </material-notification>
            <vue-c3 :handler="handler_bar_VGA"/>
          </div>
        </material-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="loadingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :top="true"
      :color="snackbarColor">
      {{ snackbarMsg }}
      <v-btn
        dark
        flat
        @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import Vue from 'vue'
import VueC3 from 'vue-c3'
import 'c3/c3.min.css'
import { mapState } from 'vuex'
import Loading from '@/assets/img/loading.gif'

export default {
  name: 'SingleThermalModuleTestDatalnfoPL2',
  components: {
    VueC3
  },
  data () {
    return {
      startdate: '2018-04-01',
      snackbar: false,
      snackbarColor: 'red',
      snackbarMsg: '',
      Loading,
      loadingView: false,
      summaryPk: '',
      summaryCPURange: '0.1',
      summaryVGARange: '0.1',
      RangeCPUindex: 0,
      RangeCPUMin: 0,
      RangeCPUMax: 9999,
      RangeCPUViewMax: 4,
      CPUOverRangeCount: 0,
      RangeCPUCount: 1,
      RangeVGAindex: 0,
      RangeVGAMin: 0,
      RangeVGAMax: 9999,
      RangeVGAViewMax: 3,
      VGAOverRangeCount: 0,
      RangeVGACount: 1,
      barCPUXaxisName: [],
      barCPUXaxisValue: [],
      barVGAXaxisName: [],
      barVGAXaxisValue: [],
      responseAreaData: {
        launchDate: '',
        launchTime: {
          hour: 0,
          minute: 0,
          second: 0,
          nano: 0
        },
        deviceIsn: '',
        modelName: '',
        vendor: '',
        vendorPk: 0,
        fixtureId: '',
        stationId: 0,
        roomTemperature: 0,
        barcode: '',
        testResult: '',
        errorMessage: '',
        cpuRSpec: 0,
        cpuR: 0,
        cpuTemperature: 0,
        cpuTemperatureDiff: 0,
        cpuPower: 0,
        vgaRSpec: 0,
        vgaR: 0,
        vgaTemperature: 0,
        vgaTemperatureDiff: 0,
        vgaPower: 0,
        cpuRList: [
          0
        ],
        vgaRList: [
          0
        ]
      },
      pagination: {
        sortBy: 'TotalFailRate', // The field that you're sorting by
        descending: true
      },
      headers: [
        {
          sortable: false,
          text: '',
          value: 'Type'
        },
        {
          sortable: true,
          text: 'R SPEC',
          value: 'Rspec'
        },
        {
          sortable: true,
          text: 'R值',
          value: 'R',
          align: 'right'
        },
        {
          sortable: true,
          text: '溫度',
          value: 'temperature',
          align: 'right'
        },
        {
          sortable: true,
          text: 'Ta溫差',
          value: 'TA',
          align: 'right'
        },
        {
          sortable: true,
          text: '實際功率',
          value: 'power',
          align: 'right'
        }
      ],
      items: [],
      handler_bar_CPU: new Vue(),
      handler_bar_VGA: new Vue()
    }
  },
  computed: {
    ...mapState('app', ['theme', 'shrink']),
    options_bar_CPU () {
      return {
        data: {
          x: 'x',
          columns: [
            // ['x', '1-5', '6-10', '11-15', '16-20', '21-25', '26-30', '31-35', '36-40', '41-45', '46-50'],
            // ['ModelName', 2, 3, 5, 12, 18, 17, 11, 6, 5, 3]
            // this.responseAreaData.cpuRList
            this.barCPUXaxisName,
            this.barCPUXaxisValue
          ],
          // labels: true,
          labels: {
            // format: (v, id, i, j) => { return i === this.RangeCPUindex ? v : '' }
          },
          type: 'bar',
          // types: {
          //   'NormalDistribution': 'spline'
          // },
          axes: {
            R: 'y'
          },
          // colors: {
          //   'R': '#999999'
          // },
          // onclick: (d) => { this.summaryList(d) },
          // onclick: (d, i) => { this.summaryList(d, i) } // {id: "data23", value: 120, ratio: 0.8, index: 1, name: "data23"}
          color: (color, d) => {
            // d will be 'id' when called for legends
            return d.index === this.RangeCPUindex ? '#ffa500' : color
          }
        },
        axis: {
          x: {
            type: 'category', // this needed to load string x value
            tick: {
              rotate: -45,
              multiline: false
            },
            label: {
              text: '熱阻(C/W)',
              position: 'outer-center'
            }
          },
          y: {
            // tick: {
            //   format: function (value) { return value + '%' }
            // }
            label: {
              text: '數量',
              position: 'outer-middle'
            }
          },
          y2: {
            // show: true
            // label: {
            //   text: '億',
            //   position: 'outer-middle'
            // }
          }
        },
        tooltip: {
          format: {
            title: (d, value) => {
              if (this.RangeCPUindex === d) {
                return `★ ${this.barCPUXaxisName[d + 1]} ★`
              } else {
                return `${this.barCPUXaxisName[d + 1]}`
              }
              // return `第 ${d} 期`
            },
            value: (value, ratio, id) => {
              return `${value}`
              // return `${value} 億`
            }
          }
        },
        grid: {
          x: {
            show: true
          },
          y: {
            show: true
          }
        },
        legend: {
          position: 'bottom',
          padding: 16,
          show: false
        },
        size: {
          // width: 640,
          height: 500
        }
      }
    },
    options_bar_VGA () {
      return {
        data: {
          x: 'x',
          columns: [
            // ['x', '1-5', '6-10', '11-15', '16-20', '21-25', '26-30', '31-35', '36-40', '41-45', '46-50'],
            // ['ModelName', 2, 3, 5, 12, 18, 17, 11, 6, 5, 3]
            // this.responseAreaData.vgaRList
            this.barVGAXaxisName,
            this.barVGAXaxisValue
          ],
          // labels: true,
          labels: {
            // format: (v, id, i, j) => { return i === this.RangeVGAindex ? v : '' }
          },
          type: 'bar',
          // types: {
          //   'NormalDistribution': 'spline'
          // },
          axes: {
            R: 'y'
          },
          // colors: {
          //   ModelName: '#999999'
          // },
          // onclick: (d) => { this.summaryList(d) },
          // onclick: (d, i) => { this.summaryList(d, i) } // {id: "data23", value: 120, ratio: 0.8, index: 1, name: "data23"}
          color: (color, d) => {
            // d will be 'id' when called for legends
            return d.index === this.RangeVGAindex ? '#ffa500' : color
          }
        },
        axis: {
          x: {
            type: 'category', // this needed to load string x value
            tick: {
              rotate: -45,
              multiline: false
            },
            label: {
              text: '熱阻(C/W)',
              position: 'outer-center'
            }
          },
          y: {
            // tick: {
            //   format: function (value) { return value + '%' }
            // }
            label: {
              text: '數量',
              position: 'outer-middle'
            }
          },
          y2: {
            // show: true
            // label: {
            //   text: '億',
            //   position: 'outer-middle'
            // }
          }
        },
        tooltip: {
          format: {
            title: (d, value) => {
              if (this.RangeVGAindex === d) {
                return `★ ${this.barVGAXaxisName[d + 1]} ★`
              } else {
                return `${this.barVGAXaxisName[d + 1]}`
              }
            },
            value: (value, ratio, id) => {
              return `${value}`
              // return `${value} 億`
            }
          }
        },
        grid: {
          x: {
            show: true
          },
          y: {
            show: true
          }
        },
        legend: {
          position: 'bottom',
          padding: 16,
          show: false
        },
        size: {
          // width: 640,
          height: 500
        }
      }
    }
  },
  watch: {
    summaryDate (val) {
      this.summaryDateMenu = false
    }
  },
  mounted () {
    this.$store.dispatch('getAuthorize', '/thermalModule/pl2/singleThermalModuleTestDatalnfo')
      .then(response => {
        if (response.status === 'success' && response.data.accessible) {
          JSON.parse(localStorage.getItem('moduleDataInitDate')).forEach((n) => {
            if (n.name === 'thermalModule') {
              this.startdate = n.value
            }
          })
          if (this.$route.params.pk) {
            this.summaryPk = this.$route.params.pk
          }
          this.doSubmit()
        } else if (response.errCode === 'E10000002') {
          const routeData = this.$router.resolve({ path: '/noEnter/E10000002' })
          localStorage.setItem('errormsg', response.errMsg)
          window.location.assign(routeData.href)
        } else {
          const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
          window.location.assign(routeData.href)
        }
      })
      .catch(err => {
        console.log(err)
        const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
        window.location.assign(routeData.href)
      })
  },
  methods: {
    doSummaryCPU (type) {
      this.barCPUXaxisName = ['x']
      this.barCPUXaxisValue = ['數量']
      const AllArray = []
      this.responseAreaData.cpuRList.forEach((r) => {
        if (r >= 0) {
          AllArray.push(r)
        }
      })
      this.RangeCPUMax = Math.ceil(Math.max(...AllArray) / this.summaryCPURange) * this.summaryCPURange
      this.RangeCPUMin = Math.floor(Math.min(...AllArray) / this.summaryCPURange) * this.summaryCPURange
      if (this.RangeCPUMax > this.RangeCPUViewMax) {
        this.RangeCPUMax = Math.min(this.RangeCPUMax, this.RangeCPUViewMax)
      }
      this.CPUOverRangeCount = 0
      const RangeArray = []
      for (let i = this.RangeCPUMin; i < this.RangeCPUMax; i = i + this.summaryCPURange) {
        RangeArray[(i - this.RangeCPUMin) / this.summaryCPURange] = 0
      }
      AllArray.forEach((r) => {
        if (r > this.RangeCPUViewMax) {
          this.CPUOverRangeCount = this.CPUOverRangeCount + 1
          if (r === this.responseAreaData.cpuR) {
            this.RangeCPUindex = 'OVER'
          }
        } else {
          if (r === this.responseAreaData.cpuR) {
            this.RangeCPUindex = Math.floor((r - this.RangeCPUMin) / this.summaryCPURange)
          }
          if (RangeArray[Math.floor((r - this.RangeCPUMin) / this.summaryCPURange)] > 0) {
            RangeArray[Math.floor((r - this.RangeCPUMin) / this.summaryCPURange)] = RangeArray[Math.floor((r - this.RangeCPUMin) / this.summaryCPURange)] + 1
          } else {
            RangeArray[Math.floor((r - this.RangeCPUMin) / this.summaryCPURange)] = 1
          }
        }
      })
      this.RangeCPUCount = Math.max(...RangeArray)
      for (let i = 0; i < RangeArray.length; i++) {
        this.barCPUXaxisValue.push(RangeArray[i] || 0)
        switch (true) {
          case (this.summaryCPURange === '0.1'):
            this.barCPUXaxisName.push((this.RangeCPUMin + (i * this.summaryCPURange)).toFixed(1) + '-' + (this.RangeCPUMin + ((i + 1) * this.summaryCPURange)).toFixed(1))
            break
          case (this.summaryCPURange === '0.05'):
            this.barCPUXaxisName.push((this.RangeCPUMin + (i * this.summaryCPURange)).toFixed(2) + '-' + (this.RangeCPUMin + ((i + 1) * this.summaryCPURange)).toFixed(2))
            break
          // default:
          // return
        }
      }
      if (this.CPUOverRangeCount > 0) {
        this.barCPUXaxisValue.push(this.CPUOverRangeCount)
        this.barCPUXaxisName.push('>' + this.RangeCPUViewMax)
      }
      // RangeArray.forEach((r, index) => {
      //   console.log(r)
      //   console.log(index)
      //   this.barXaxisValue.push(r)
      //   this.barXaxisName.push((this.RangeMin + (index * this.summaryRange)) + '-' + (this.RangeMin + ((index + 1) * this.summaryRange)))
      // })
      if (this.RangeCPUindex === 'OVER') {
        this.RangeCPUindex = this.barCPUXaxisName.length - 2
      }
      if (type === 'init') {
        this.handler_bar_CPU.$emit('init', this.options_bar_CPU)
      } else {
        this.handler_bar_CPU.$emit('dispatch', chart => {
          const options = {
            columns: [
              this.barCPUXaxisName,
              this.barCPUXaxisValue
            ]
          }
          chart.load(options)
        })
      }
    },
    doSummaryVGA (type) {
      this.barVGAXaxisName = ['x']
      this.barVGAXaxisValue = ['數量']
      const AllArray = []
      this.responseAreaData.vgaRList.forEach((r) => {
        if (r >= 0) {
          AllArray.push(r)
        }
      })
      this.RangeVGAMax = Math.ceil(Math.max(...AllArray) / this.summaryVGARange) * this.summaryVGARange
      this.RangeVGAMin = Math.floor(Math.min(...AllArray) / this.summaryVGARange) * this.summaryVGARange
      if (this.RangeVGAMax > this.RangeVGAViewMax) {
        this.RangeVGAMax = Math.min(this.RangeVGAMax, this.RangeVGAViewMax)
      }
      this.VGAOverRangeCount = 0
      const RangeArray = []
      for (let i = this.RangeVGAMin; i < this.RangeVGAMax; i = i + this.summaryVGARange) {
        RangeArray[(i - this.RangeVGAMin) / this.summaryVGARange] = 0
      }
      AllArray.forEach((r) => {
        if (r > this.RangeVGAViewMax) {
          this.VGAOverRangeCount = this.VGAOverRangeCount + 1
          if (r === this.responseAreaData.vgaR) {
            this.RangeVGAindex = 'OVER'
          }
        } else {
          if (r === this.responseAreaData.vgaR) {
            this.RangeVGAindex = Math.floor((r - this.RangeVGAMin) / this.summaryVGARange)
          }
          if (RangeArray[Math.floor((r - this.RangeVGAMin) / this.summaryVGARange)] > 0) {
            RangeArray[Math.floor((r - this.RangeVGAMin) / this.summaryVGARange)] = RangeArray[Math.floor((r - this.RangeVGAMin) / this.summaryVGARange)] + 1
          } else {
            RangeArray[Math.floor((r - this.RangeVGAMin) / this.summaryVGARange)] = 1
          }
        }
      })
      this.RangeVGACount = Math.max(...RangeArray)
      for (let i = 0; i < RangeArray.length; i++) {
        this.barVGAXaxisValue.push(RangeArray[i] || 0)
        switch (true) {
          case (this.summaryVGARange === '0.1'):
            this.barVGAXaxisName.push((this.RangeVGAMin + (i * this.summaryVGARange)).toFixed(1) + '-' + (this.RangeVGAMin + ((i + 1) * this.summaryVGARange)).toFixed(1))
            break
          case (this.summaryVGARange === '0.05'):
            this.barVGAXaxisName.push((this.RangeVGAMin + (i * this.summaryVGARange)).toFixed(2) + '-' + (this.RangeVGAMin + ((i + 1) * this.summaryVGARange)).toFixed(2))
            break
          // default:
          // return
        }
      }
      if (this.VGAOverRangeCount > 0) {
        this.barVGAXaxisValue.push(this.VGAOverRangeCount)
        this.barVGAXaxisName.push('>' + this.RangeVGAViewMax)
      }
      // RangeArray.forEach((r, index) => {
      //   console.log(r)
      //   console.log(index)
      //   this.barXaxisValue.push(r)
      //   this.barXaxisName.push((this.RangeMin + (index * this.summaryRange)) + '-' + (this.RangeMin + ((index + 1) * this.summaryRange)))
      // })
      if (this.RangeVGAindex === 'OVER') {
        this.RangeVGAindex = this.barVGAXaxisName.length - 2
      }
      if (type === 'init') {
        this.handler_bar_VGA.$emit('init', this.options_bar_VGA)
      } else {
        this.handler_bar_VGA.$emit('dispatch', chart => {
          const options = {
            columns: [
              this.barVGAXaxisName,
              this.barVGAXaxisValue
            ]
          }
          chart.load(options)
        })
      }
    },
    doSubmit (type) {
      this.loadingView = true
      this.$http.post('/api/thermalModule/pl2/singleDeviceBurnInDataStatistic?pk=' + this.summaryPk).then(res => res.data)
        .then(res => {
          this.loadingView = false
          if (res.status === 'success') {
            this.responseAreaData = res.data
            this.responseAreaData.warningColor = this.responseAreaData.testResult === 'pass' ? 'green' : 'red'
            this.responseAreaData.color = this.responseAreaData.testResult === 'pass' ? 'light-green' : 'pink'
            this.responseAreaData.icon = this.responseAreaData.testResult === 'pass' ? 'mdi-check' : 'mdi-alert'
            this.items = [
              {
                Type: 'CPU',
                Rspec: 'R < ' + this.responseAreaData.cpuRSpec,
                R: this.responseAreaData.cpuR,
                temperature: this.responseAreaData.cpuTemperature,
                TA: this.responseAreaData.cpuTemperatureDiff,
                power: this.responseAreaData.cpuPower
              },
              {
                Type: 'VGA',
                Rspec: 'R < ' + this.responseAreaData.vgaRSpec,
                R: this.responseAreaData.vgaR,
                temperature: this.responseAreaData.vgaTemperature,
                TA: this.responseAreaData.vgaTemperatureDiff,
                power: this.responseAreaData.vgaPower
              },
              {
                Type: 'CPU-VGA溫差',
                Rspec: '',
                R: '',
                temperature: (this.responseAreaData.cpuTemperature - this.responseAreaData.vgaTemperature).toFixed(1),
                TA: '',
                power: ''
              }
            ]
            this.doSummaryCPU('init')
            this.doSummaryVGA('init')
            // this.handler_bar_CPU.$emit('init', this.options_bar_CPU)
            // this.handler_bar_VGA.$emit('init', this.options_bar_VGA)
          }
        })
    },
    summaryList (Range) {
      if (Range.id === 'ModelName') {
        this.items = [
          {
            SN: 'DDDXXXX00' + Range.index,
            CPU: 83 + (Range.value * 5),
            CPUmax: 83 + (Range.value * 7),
            CPUmin: 83 + (Range.value * 2),
            GPU: 83 + (Range.value * 9),
            MESSAGE: 'fan error',
            TRY: Range.value
          }
        ]
      }
    }
    // doApi () {
    //   console.log(process.env.NODE_ENV)
    //   console.log(process.env.hosturl)
    //   const body = {
    //     queryStartDate: '2020-06-20',
    //     queryEndDate: '2020-06-25',
    //     stage: '',
    //     ems: 0,
    //     failRate: 0,
    //     modelName: ''
    //   }
    //   let options = ''
    //   let successCallback = 'success'
    //   let errorCallback = 'error'
    //   // this.axios.get('/index/phpinfo.php').then((res) => {
    //   //   console.log(res)
    //   // })
    //   this.$http.get('/api/getSelectOptionData/stage', options).then(successCallback, errorCallback)
    //   this.$http.post('/api/thermalModule/pl2/getSingleDeviceBurnInDataStatistic', body, options).then(successCallback, errorCallback)
    //   // this.$http.get('/api/getSelectOptionData/stage', { params: { a: 1, b: 2 } }).then(function (res) {
    //   //   // document.write(res.body)
    //   // }, function (res) {
    //   //   console.log(res.status)
    //   // })
    // }
  }
}
</script>
<style>
</style>
