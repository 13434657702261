<template>
  <v-container
    cfr
    fluid
    grid-list-xl
  >
    <v-layout wrap>
      <v-flex
        xs12
        lg12
      >
        <material-card
          title="每週不良項目分析"
          class="card-padding"
          color="general"
        >
          <v-form>
            <v-container
              py-0
              class="mw-100">
              <v-layout wrap>
                <v-flex
                  flex-small
                  xs12
                  lg2>
                  <v-autocomplete
                    v-model="modelYear"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="yearMenu"
                    label="開賣年度"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex
                  flex-small
                  xs12
                  lg1/>
                <v-flex
                  flex-small
                  xs12
                  lg2>
                  <v-autocomplete
                    v-model="year"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="yearMenu"
                    label="分析年度"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex
                  flex-small
                  xs12
                  lg2>
                  <v-autocomplete
                    v-model="weekNumber"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="weekNumberMenu"
                    label="分析週次"
                    class="v-text-field__slot list-select-field"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex
                  flex-small
                  d-flex
                  align-items-center
                  xs12
                  lg2>
                  <v-checkbox
                    v-model="excludeBestBuy"
                    :value="true"
                    input-value="1"
                    class="m-0 py-0"
                    label="Exclude Best Buy"/>
                </v-flex>
                <v-flex
                  xs12
                  lg3
                  text-xs-right
                >
                  <v-btn
                    class="mx-0 mr-3 font-weight-light"
                    color="general"
                    @click="doSubmit()"
                  >
                    <v-icon class="mr-2">mdi-backup-restore</v-icon>統計
                  </v-btn>
                  <v-btn
                    class="mx-0 font-weight-light"
                    color="indigo"
                    @click="doDownload()"
                  >
                    <v-icon class="mr-2">mdi-export</v-icon>匯出Raw Data
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
      </v-flex>
      <v-flex
        v-show="analyzerView"
        xs12
        md9
        style="padding: 0px 12px!important;">
        <material-card
          title="每週不良項目分析結果"
          color="brown"
          class="card-mix d-table-t0 no-data-left"
          style="margin-top: 30px!important;">
          <vue-c3 :handler="chartArea"/>
        </material-card>
      </v-flex>
      <v-flex
        v-show="analyzerView"
        flex_table_width_auto
        xs12
        md3
        style="padding: 0px 12px!important;">
        <material-card
          title="每週不良項目分析結果列表"
          color="deep-orange"
          class="card-mix table-overflow d-table-t0 no-data-left row-problem-total"
          style="margin-top: 30px!important;">
          <table class="statistic-table analyzer-table">
            <thead>
              <tr>
                <th style="min-width: 65px;"/>
                <th
                  v-for="(title,t) in responseTableData.columnName"
                  :key="'t'+t"
                  class="text-center">{{ title }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(content,c) in responseTableData.content"
                :key="'c'+c">
                <td style="min-width: 65px;">{{ content.name }}</td>
                <td
                  v-for="(data,d) in content.data"
                  :key="'d'+d"
                  class="text-center">
                  <div>
                    <span>{{ d===1?((data[0] * 100).toFixed(2)+'%'):data[0] }}</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </material-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="processingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        PROCESSING 處理中
      </p>
    </v-dialog>
    <v-dialog
      v-model="loadingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :color="snackbarColor"
      :top="true">
      {{ snackbarMsg }}
      <v-btn
        flat
        @click="snackbar = false">
        關閉
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import Vue from 'vue'
import VueC3 from 'vue-c3'
import 'c3/c3.min.css'
import { mapState } from 'vuex'
import Loading from '@/assets/img/loading.gif'

export default {
  name: 'WeeklyRepairItemAnalysis',
  components: {
    VueC3
  },
  data () {
    return {
      startdate: '2018-04-01',
      snackbar: false,
      snackbarColor: 'red',
      snackbarMsg: '',
      Loading,
      loadingView: false,
      processingView: false,
      year: '',
      modelYear: '',
      weekNumber: '',
      resWeekNumber: '',
      excludeBestBuy: false,
      baseline: false,
      analyzerView: false,
      responseChartData: [],
      chartXaxisName: [],
      chartXaxisValue: [],
      chartLineTypes: {},
      // chartLineRegions: {},
      chartLineHint: {},
      // chartPieTooltip: {
      //   contents: (d, defaultTitleFormat, defaultValueFormat, color) => {
      //     return '<p>' + defaultTitleFormat + '</p>'
      //   }
      // },
      chartPieTooltip: [],
      chartBaseLineId: '',
      chartArea: new Vue(),
      responseTableData: {
        columnName: [],
        content: []
      }
      // responseTableData: [],
      // responseHeaders: []
      // pagination: {
      //   rowsPerPage: 1,
      //   sortBy: 'name', // The field that you're sorting by
      //   descending: true
      // },
    }
  },
  computed: {
    ...mapState('app', ['theme', 'shrink']),
    typeList () {
      const list = []
      this.resTypeListData.forEach((r) => {
        list.push({ text: r.columnLabel, value: r.columnName })
      })
      return list
    },
    yearMenu () {
      const list = []
      for (let i = this.$moment(new Date()).format('YYYY'); i >= this.$moment(this.startdate).format('YYYY'); i--) {
        list.push({ text: i, value: i })
      }
      return list
    },
    weekNumberMenu () {
      const list = []
      for (let i = this.resWeekNumber; i >= 1; i--) {
        list.push({ text: i, value: i })
      }
      return list
    },
    options_line () {
      return {
        data: {
          x: 'x',
          columns: this.chartXaxisName.concat(this.chartXaxisValue),
          labels: {
            format: (v, id, i, j) => {
              if (v && id && i >= 0) {
                if (this.chartLineHint[id].indexOf(i) > -1) {
                  return (v * 100).toFixed(2) + '%'
                } else {
                  return ''
                }
              }
            },
            color: '#999999'
          },
          type: 'pie',
          types: this.chartLineTypes,
          // regions: this.chartLineRegions,
          colors: {
            // ModelName: '#999999'
          },
          onclick: (d, i) => {
            if (d.id !== this.chartBaseLineId) {
              this.doChangeChartLineHint(d)
            }
          },
          onmouseover: (d, i) => { this.doChangeChartLineId(d) }
        },
        point: {
          r: (v) => {
            if (v.id && v.index >= 0) {
              if (this.chartLineHint[v.id].indexOf(v.index) > -1) {
                return 5
              } else if (v.id === this.chartBaseLineId) {
                return 0
              } else {
                return 3
              }
            } else {
              return 0
            }
          }
        },
        axis: {
          x: {
            type: 'category', // this needed to load string x value
            tick: {
              rotate: -45,
              multiline: false
            },
            label: {
              text: '週期月',
              position: 'outer-right'
            }
          },
          y: {
            tick: {
              format: (value) => { return (value * 100).toFixed(2) + '%' }
            },
            label: {
              text: '不良率',
              position: 'outer-bottom'
            }
          }
        },
        pie: {
          label: {
            format: (value, ratio, id) => {
              return (ratio * 100).toFixed(2) + '%'
            }
          }
        },
        // pie: {
        //   label: {
        //     format: (value, ratio, id) => {
        //       return value + 'Pcs'
        //     },
        //     threshold: -1
        //   }
        // },
        // tooltip: this.chartPieTooltip,
        tooltip: {
          contents: (d, defaultTitleFormat, defaultValueFormat, color) => {
            return this.chartPieTooltip[this.chartXaxisName[0].indexOf(d[0].name) - 1]
            // return this.chartPieTooltip[d[0].index]
          },
          format: {
            title: (d, value) => {
              if (d) {
                return `${this.chartXaxisName[0][d + 1]}`
              } else {
                return `${this.chartXaxisValue[value][0]}`
              }
              // return `第 ${d} 期`
            },
            value: (value, ratio, id) => {
              return `${(value * 100).toFixed(2)} %`
              // return `${value} 億`
            }
          }
        },
        grid: {
          x: {
            show: true
          },
          y: {
            show: true
          }
        },
        legend: {
          position: 'right',
          padding: 0,
          show: true
        },
        size: {
          width: ((window.innerWidth - this.shrink) * 1) - 64 - 410,
          height: 600
        }
      }
    }
  },
  watch: {
    year (val) {
      this.weekNumber = ''
      this.getweekNumber(val)
    }
    // chartType (val) {
    //   if (val === 0) {
    //     this.lineArray = [{ lineType: 0, customGroupName: false, groupName: '', fields: [], selectAllFields: [], selectAllList: [] }]
    //     this.lineGroup = 0
    //     this.lineGroupList = []
    //   } else if (val === 1) {
    //     this.lineArray = [{ lineType: 2, customGroupName: false, groupName: '', fields: [], selectAllFields: [], selectAllList: [] }]
    //     this.lineGroup = 0
    //     this.lineGroupList = []
    //   }
    // },
    // chartLineIndex (val) {
    //   this.responseSpecifyData = [this.responseData[val]]
    // }
  },
  mounted () {
    this.$store.dispatch('getAuthorize', '/pqm/cfrAnalysisMgmt/weeklyRepairItemAnalysisView')
      .then(response => {
        if (response.status === 'success' && response.data.accessible) {
          JSON.parse(localStorage.getItem('moduleDataInitDate')).forEach((n) => {
            if (n.name === 'pqm') {
              this.startdate = n.value
            }
          })
          this.year = this.$moment(new Date()).format('YYYY')
          this.modelYear = this.$moment(new Date()).format('YYYY')
          this.getweekNumber(this.$moment(new Date()).format('YYYY'))
          this.weekNumber = this.resWeekNumber
          // this.doSubmit()
          // this.chartArea.$emit('init', this.options_line)
        } else if (response.errCode === 'E10000002') {
          const routeData = this.$router.resolve({ path: '/noEnter/E10000002' })
          localStorage.setItem('errormsg', response.errMsg)
          window.location.assign(routeData.href)
        } else {
          const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
          window.location.assign(routeData.href)
        }
      })
      .catch(err => {
        console.log(err)
        const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
        window.location.assign(routeData.href)
      })
  },
  methods: {
    getweekNumber (year) {
      this.$http.get(`/api/pqm/cfr/option/weekNumber/${year}`).then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.resWeekNumber = res.data
          }
        })
    },
    doChangeChartLineId (d) {
      this.chartLineId = d.id
    },
    doChangeChartLineHint (d) {
      if (this.chartLineHint[d.id].indexOf(d.index) === -1) {
        this.chartLineHint[d.id].push(d.index)
      } else {
        this.chartLineHint[d.id].splice(this.chartLineHint[d.id].indexOf(d.index), 1)
      }
      this.chartArea.$emit('dispatch', chart => {
        const options = {
          columns: this.chartXaxisName.concat(this.chartXaxisValue)
        }
        chart.load(options)
      })
      this.chartXaxisValue.forEach((c, index) => {
        if (c[0] === d.id) {
          this.chartLineIndex = index
        }
      })
    },
    doSubmitVerification () {
      if (this.modelYear === '') {
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '請選擇開賣年度'
        return false
      } else if (this.year === '') {
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '請選擇分析年度'
        return false
      } else if (this.weekNumber === '') {
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '請選擇分析週次'
        return false
      } else {
        return true
      }
    },
    doSubmit () {
      if (this.doSubmitVerification()) {
        this.loadingView = true
        this.$http.get(`/api/pqm/cfr/weeklyRepairItemAnalysis/weeklyRepairItemList?modelYear=${this.modelYear}&year=${this.year}&weekNumber=${this.weekNumber}&excludeBestBuy=${this.excludeBestBuy || false}`).then(res => res.data)
          .then(res => {
            this.loadingView = false
            if (res.status === 'success') {
              this.responseTableData = res.data.table
              this.chartXaxisName = [res.data.xColumn]
              this.chartXaxisValue = []
              this.chartLineTypes = {}
              // this.chartLineRegions = {}
              this.chartLineHint = {}
              this.chartPieTooltip = []
              // res.data.lineData.forEach((c) => {
              //   this.chartXaxisValue.push(c.data)
              //   this.chartLineTypes[c.data[0]] = 'line'
              //   this.chartLineHint[c.data[0]] = c.hint
              // })
              // res.data.barData.forEach((c) => {
              //   this.chartXaxisValue.push(c.data)
              //   this.chartLineTypes[c.data[0]] = 'bar'
              //   this.chartLineHint[c.data[0]] = c.hint
              // })
              if (res.data.pieData.length > 0) {
                res.data.pieData.forEach((c) => {
                  this.chartXaxisValue.push(c.data)
                  this.chartLineTypes[c.data[0]] = 'pie'
                  this.chartLineHint[c.data[0]] = c.hint
                  this.chartPieTooltip.push('<div style="color:#ffffff!important;background-color:#000000;padding:10px;font-weight:900!important;">' + c.tip + '</div>')
                })
              }
              // if (this.baseline && this.chartType === 0) {
              //   this.chartXaxisValue.push(res.data.baseline.data)
              //   this.chartLineTypes[res.data.baseline.data[0]] = 'line'
              //   // this.chartLineRegions[baseline.data[0]] = [{ style: 'dashed' }]
              //   this.chartLineHint[res.data.baseline.data[0]] = res.data.baseline.hint
              //   this.chartBaseLineId = res.data.baseline.data[0]
              // }
              this.chartArea.$emit('init', this.options_line)
              this.analyzerView = true
            } else {
              this.analyzerView = false
              this.snackbar = true
              this.snackbarColor = 'red'
              this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
            }
          })
        // this.loadingView = false
        // let data = {
        //   type: 1,
        //   xColumn: ['x', 1, 2],
        //   lineData: [],
        //   barData: [],
        //   pieData: [
        //     {
        //       data: ['sample6', 30, 200, 100, 400, 150, 250, 30, 200, 100, 400, 150, 250],
        //       hint: [1, 4],
        //       tip: ''
        //     }, {
        //       data: ['test6', 100, 20, 10, 40, 10, 50, 100, 20, 10, 40, 10, 50],
        //       hint: [0, 2],
        //       tip: ''
        //     }
        //   ],
        //   baseline: null,
        //   table: {
        //     columnName: ['合計', '%'],
        //     content: [
        //       {
        //         name: 'FX505DD',
        //         data: [[12812], [0.0581]],
        //         target: null
        //       }, {
        //         name: 'FX505DD',
        //         data: [[12812], [0.0581]],
        //         target: null
        //       }, {
        //         name: 'FX505DD',
        //         data: [[12812], [0.0581]],
        //         target: null
        //       }
        //     ]
        //   }
        // }
        // this.responseTableData = data.table
        // this.chartXaxisName = [data.xColumn]
        // this.chartXaxisValue = []
        // this.chartLineTypes = {}
        // // this.chartLineRegions = {}
        // this.chartLineHint = {}
        // data.pieData.forEach((c) => {
        //   this.chartXaxisValue.push(c.data)
        //   this.chartLineTypes[c.data[0]] = 'pie'
        //   this.chartLineHint[c.data[0]] = c.hint
        //   this.chartPieTooltip.push('<div style="color:#ffffff!important;background-color:#000000;padding:10px;font-weight:900!important;">' + c.tip + '</div>')
        // })

        // this.chartLineTooltip = {
        //   contents: (d, defaultTitleFormat, defaultValueFormat, color) => {
        //     return '<p>xxx</p>'
        //   }
        // }

        // this.chartLineTooltip['contents'] = (d, defaultTitleFormat, defaultValueFormat, color) => {
        //   return '<p>xxx</p>'
        // }

        // this.chartArea.$emit('init', this.options_line)
        // this.chartArea.$emit('dispatch', chart => {
        //   const options = {
        //     columns: this.chartXaxisName.concat(this.chartXaxisValue),
        //     type: (this.chartType === 0) ? 'line' : 'pie',
        //     types: this.chartLineTypes,
        //     regions: this.chartLineRegions,
        //     tooltip: this.chartLineTooltip
        //   }
        //   chart.load(options)
        // })
        // const body = {
        //   name: this.summaryGroupName,
        //   menuPk: this.summaryAuthority === 'All' ? '' : this.summaryAuthority
        // }
        // this.$http.post('/api/system/queryGroup', body).then(res => res.data)
        //   .then(res => {
        //     if (res.status === 'success') {
        //       this.responseDataTable = res.data
        //     }
        //   })
        // this.analyzerView = true
      }
    },
    doDownload () {
      if (this.doSubmitVerification()) {
        this.processingView = true
        this.$http.get(`/api/pqm/cfr/weeklyRepairItemAnalysis/weeklyRepairItemRawDataFile?modelYear=${this.modelYear}&year=${this.year}&weekNumber=${this.weekNumber}&excludeBestBuy=${this.excludeBestBuy || false}`, { responseType: 'blob' })
          .then(res => {
            this.processingView = false
            if (res.data.type === 'application/json') {
              return new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.onload = () => {
                  res.data = JSON.parse(reader.result)
                  if (res.data.status === 'success') {
                    alert(res.data.data)
                    // resolve(Promise.reject(res.data.data))
                  } else {
                    this.snackbar = true
                    this.snackbarColor = 'red'
                    this.snackbarMsg = res.data.errMsg + ' (' + res.data.errCode + ')'
                  }
                }
                reader.onerror = () => {
                  reject(res.data.data)
                }
                reader.readAsText(res.data)
              })
            } else if (res.data.status !== 'failure' && res.headers['content-disposition']) {
              const blob = new Blob([res.data], { type: 'application/octet-stream' })
              const url = window.URL.createObjectURL(blob)
              const fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]
              const downloadLink = document.createElement('a')
              downloadLink.href = url
              downloadLink.download = fileName

              document.body.appendChild(downloadLink)
              downloadLink.click()
              document.body.removeChild(downloadLink)
            } else {
              this.snackbar = true
              this.snackbarColor = 'red'
              this.snackbarMsg = '下載失敗，請通知系統管理員'
            }
          })
          .catch(err => {
            this.processingView = false
            this.snackbar = true
            this.snackbarColor = 'orange'
            this.snackbarMsg = err
          })
      }
    }
  }
}
</script>
<style>
</style>
