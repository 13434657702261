<template>
  <v-container
    fluid
    grid-list-xl
    pt-0
  >
    <v-layout wrap>
      <v-flex
        xs12
      >
        <material-card
          class="card-shrink card-padding v-card__first"
          color="general"
          title="熱測資料搜尋"
        >
          <v-form>
            <v-container
              py-0
              class="mw-100">
              <v-layout wrap>
                <v-flex
                  xs12
                  sm12
                  lg6>
                  <v-text-field
                    v-model="summaryBarcode"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    label="Barcode"/>
                </v-flex>
                <v-flex
                  xs12
                  lg6
                  text-xs-right
                >
                  <v-btn
                    class="mx-0 font-weight-light"
                    color="general"
                    @click="doSubmit()"
                  >
                    <v-icon class="mr-2">mdi-backup-restore</v-icon>查詢
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
        <material-card>
          <v-data-table
            :headers="headers"
            :items="responseDataTable"
            :pagination.sync="pagination"
            multi-sort
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="subheading font-weight-light text-general text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <td>{{ item.barcode }}</td>
              <td class="text-xs-right">{{ item.cpuTemperature }}</td>
              <td class="text-xs-right">{{ item.cpuR }}</td>
              <td class="text-xs-right">{{ item.vgaTemperature }}</td>
              <td class="text-xs-right">{{ item.vgaR }}</td>
              <td class="text-xs-right">{{ item.roomTemperature }}</td>
              <td>{{ item.testResult }}</td>
              <td>{{ item.traceDate }} {{ item.traceTime }}</td>
              <td>
                <router-link
                  :to="'/thermalModule/pl2/singleThermalModuleTestDatalnfo/' + item.pk"
                  target="_blank">
                  <v-btn
                    class="mx-0 mr-3 font-weight-light small"
                    color="green">
                    <v-icon class="mr-2">mdi-clipboard-text-outline</v-icon>資料檢視
                  </v-btn>
                </router-link>
                <router-link
                  :to="'/thermalModule/pl2/thermalModuleTestDataStatistc?deviceIsn='+item.deviceIsn+'&modelname='+item.modelName+'&date='+item.traceDate+'&vendorpk='+item.vendorPk+'&vendor='+item.vendor"
                  target="_blank">
                  <v-btn
                    class="mx-0 font-weight-light small"
                    color="primary">
                    <v-icon class="mr-2">mdi-chart-bar</v-icon>當日統計
                  </v-btn>
                </router-link>
              </td>
            </template>
          </v-data-table>
        </material-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="loadingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :top="true"
      :color="snackbarColor">
      {{ snackbarMsg }}
      <v-btn
        dark
        flat
        @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import Loading from '@/assets/img/loading.gif'

export default {
  name: 'ThermalModuleTestDataInfoSearchPL2',
  components: {
  },
  data () {
    return {
      startdate: '2018-04-01',
      snackbar: false,
      snackbarColor: 'red',
      snackbarMsg: '',
      Loading,
      loadingView: false,
      summaryBarcode: '',
      checkList: '',
      responseDataTable: [],
      pagination: {
        rowsPerPage: 1,
        sortBy: 'TotalFailRate', // The field that you're sorting by
        descending: true
      },
      headers: [
        {
          sortable: true,
          text: 'Barcode',
          value: 'barcode'
        },
        {
          sortable: true,
          text: 'CPU溫度',
          value: 'cpuTemperature',
          align: 'right'
        },
        {
          sortable: true,
          text: 'CPU R',
          value: 'cpuR',
          align: 'right'
        },
        {
          sortable: true,
          text: 'VGA溫度',
          value: 'vgaTemperature',
          align: 'right'
        },
        {
          sortable: true,
          text: 'VGA R',
          value: 'vgaR',
          align: 'right'
        },
        {
          sortable: true,
          text: 'Ta',
          value: 'roomTemperature',
          align: 'right'
        },
        {
          sortable: true,
          text: 'Status',
          value: 'testResult'
        },
        {
          sortable: true,
          text: 'Date',
          value: 'traceDate'
        },
        {
          sortable: false,
          text: '操作',
          value: ''
        }
      ]
    }
  },
  computed: {
    ...mapState('app', ['theme', 'shrink'])
  },
  watch: {
  },
  mounted () {
    this.$store.dispatch('getAuthorize', '/thermalModule/pl2/thermalModuleTestDataInfoSearch')
      .then(response => {
        if (response.status === 'success' && response.data.accessible) {
          JSON.parse(localStorage.getItem('moduleDataInitDate')).forEach((n) => {
            if (n.name === 'thermalModule') {
              this.startdate = n.value
            }
          })
          if (this.$route.params.searchBarcode) {
            this.summaryBarcode = this.$route.params.searchBarcode
          }
          this.doSubmit()
        } else if (response.errCode === 'E10000002') {
          const routeData = this.$router.resolve({ path: '/noEnter/E10000002' })
          localStorage.setItem('errormsg', response.errMsg)
          window.location.assign(routeData.href)
        } else {
          const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
          window.location.assign(routeData.href)
        }
      })
      .catch(err => {
        console.log(err)
        const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
        window.location.assign(routeData.href)
      })
  },
  methods: {
    doSubmit () {
      this.loadingView = true
      this.$http.post('/api/thermalModule/pl2/singleDeviceBurnInData?barcode=' + this.summaryBarcode).then(res => res.data)
        .then(res => {
          this.loadingView = false
          if (res.status === 'success') {
            this.pagination.rowsPerPage = res.data.length
            this.responseDataTable = res.data
          }
        })
    }
  }
}
</script>
<style>
</style>
