<template>
  <v-container
    cfr
    fluid
    grid-list-xl
    pt-0
  >
    <v-layout wrap>
      <v-flex
        xs12
        lg12
      >
        <material-card
          title="機台返修查詢"
          class="card-padding v-card__first"
          color="general"
        >
          <v-form>
            <v-container
              py-0
              class="mw-100">
              <v-layout wrap>
                <v-flex
                  flex-small
                  xs12
                  lg11>
                  <v-layout
                    wrap
                    m-0>
                    <v-text-field
                      v-model="file[0].name"
                      :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                      label="SN檔名"
                      disabled
                      @click="uploadFile()"/>
                    <v-btn
                      class="mt-3 black text-light"
                      @click="uploadFile(0)">
                      <v-icon class="float-left">mdi-paperclip</v-icon>選擇檔案
                    </v-btn>
                    <v-btn
                      class="mt-3 black text-light"
                      @click="cancelFile(0)">
                      <v-icon class="float-left">mdi-close</v-icon>清除檔案
                    </v-btn>
                    <v-btn
                      class="mt-3 black text-light"
                      @click="doSampleDownload()">
                      <v-icon class="float-left">mdi-file</v-icon>下載範本檔案
                    </v-btn>
                    <input
                      v-show="false"
                      id="upload-file"
                      :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                      type="file"
                      label="檔名"
                      @change="setFile($event)">
                  </v-layout>
                </v-flex>
                <v-flex
                  flex-small
                  xs12
                  lg11
                  style="justify-content: space-between;display: flex;">
                  <v-autocomplete
                    v-model="problemGroup"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="problemGroupMenu"
                    label="Problem group"
                    class="v-text-field__slot list-select-field"
                    persistent-hint
                    outlined
                    multiple
                  />
                  <v-btn
                    class="mt-3 black text-light"
                    @click="problemGroup=[]">
                    清除
                  </v-btn>
                </v-flex>
                <v-flex
                  flex-small
                  lg1/>
                <v-flex
                  flex-small
                  xs12
                  lg11
                  style="justify-content: space-between;display: flex;">
                  <v-autocomplete
                    v-model="problem"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="problemMenu"
                    label="Problem"
                    class="v-text-field__slot list-select-field"
                    persistent-hint
                    outlined
                    multiple
                  />
                  <v-btn
                    class="mt-3 black text-light"
                    @click="problem=[]">
                    清除
                  </v-btn>
                </v-flex>
                <v-flex
                  flex-small
                  xs12
                  lg1
                  text-xs-right
                  d-flex
                  align-items-center
                >
                  <v-btn
                    class="mx-0 font-weight-light"
                    color="general"
                    @click="doSubmit()"
                  >
                    <v-icon class="mr-2">mdi-backup-restore</v-icon>查詢
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
      </v-flex>
      <v-flex
        v-if="submit"
        xs12
        lg12>
        <material-card
          id="checkTable"
          :style="'max-width: calc(100vw - '+(Number(shrink)+32)+'px);'"
          title="查詢結果"
          color="deep-orange"
          class="card-mix d-table-t0 no-data-left"
          style="margin-top: 30px!important;">
          <div class="m-3 mt-4">
            <span class="mt-2">機台數總計:{{ responseData.size }}</span>
            <v-btn
              class="m-0 font-weight-light float-right"
              color="orange"
              @click="doDownload(responseData.pk)"
            >
              <v-icon class="mr-2">mdi-export</v-icon>匯出Raw Data
            </v-btn>
          </div>
        </material-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="processingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        PROCESSING 處理中
      </p>
    </v-dialog>
    <v-dialog
      v-model="loadingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :top="true"
      :color="snackbarColor">
      {{ snackbarMsg }}
      <v-btn
        dark
        flat
        @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import $ from 'jquery'
import { mapState } from 'vuex'
import Loading from '@/assets/img/loading.gif'
export default {
  name: 'MachineRepairBySnStatisic',
  data () {
    return {
      submit: false,
      snackbar: false,
      snackbarColor: 'red',
      snackbarMsg: '',
      Loading,
      loadingView: false,
      processingView: false,
      problemGroup: [],
      problemGroupMenu: [],
      problem: [],
      problemMenu: [],
      file: [
        {
          file: '',
          name: '',
          url: ''
        }
      ],
      responseData: []
    }
  },
  computed: {
    ...mapState('app', ['theme', 'shrink'])
  },
  watch: {
    problemGroup (val) {
      let path = val.join()
      if (val.length === this.problemGroupMenu.length) {
        path = 'all'
      }
      this.$http.get(`/api/pqm/cfr/getSelectOptionData/problem/${path}`).then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.problemMenu = res.data
            this.problem = []
          }
        })
    }
  },
  mounted () {
    this.$store.dispatch('getAuthorize', '/pqm/cfrAnalysisMgmt/machineRepairBySnStatisicView')
      .then(response => {
        if (response.status === 'success' && response.data.accessible) {
          this.$http.get('/api/pqm/cfr/getSelectOptionData/problemGroup').then(res => res.data)
            .then(res => {
              if (res.status === 'success') {
                this.problemGroupMenu = res.data
              }
            })
          this.$http.get('/api/pqm/cfr/getSelectOptionData/problem/all').then(res => res.data)
            .then(res => {
              if (res.status === 'success') {
                this.problemMenu = res.data
              }
            })
          // this.doSubmit()
        } else if (response.errCode === 'E10000002') {
          const routeData = this.$router.resolve({ path: '/noEnter/E10000002' })
          localStorage.setItem('errormsg', response.errMsg)
          window.location.assign(routeData.href)
        } else {
          const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
          window.location.assign(routeData.href)
        }
      })
      .catch(err => {
        console.log(err)
        const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
        window.location.assign(routeData.href)
      })
  },
  methods: {
    doSubmit () {
      if (this.file[0].file) {
        this.loadingView = true
        const formData = new FormData()
        formData.append('snFile', this.file[0].file)
        formData.append('problemGroup', this.problemGroup)
        formData.append('problem', this.problem)
        this.$http.post('/api/pqm/cfrAnalysisMgmt/findMachineRepairRawDataBySnStatisic', formData).then(res => res.data)
          .then(res => {
            this.loadingView = false
            if (res.status === 'success') {
              this.submit = true
              this.responseData = res.data
            } else {
              this.snackbar = true
              this.snackbarColor = 'red'
              this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
            }
          })
      } else {
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = 'SN檔名或檔案不得為空'
      }
    },
    uploadFile (type) {
      $('#upload-file').click()
    },
    cancelFile () {
      this.file[0].file = this.file[0].name = this.file[0].url = ''
      $('#upload-file').val('')
    },
    setFile (e) {
      const files = e.target.files || e.dataTransfer.files
      this.file[0].url = window.URL.createObjectURL(e.srcElement.files.item(0))
      this.file[0].file = files[0]
      this.file[0].name = e.srcElement.files.item(0).name
    },
    // doUpload () {
    //   this.fileType = -1
    //   let formData = new FormData()
    //   formData.append('file', this.file[0].file)
    //   this.$http.post(`/api/pqm/cscDataParsingMgmt/uploadParsingFile/${this.fileType}`, formData).then(res => res.data)
    //     .then(res => {
    //       if (res.status === 'success') {
    //         this.snackbar = true
    //         this.snackbarColor = 'green'
    //         this.snackbarMsg = '檔案上傳成功'
    //         this.cancelFile()
    //       } else { // failure
    //         this.snackbar = true
    //         this.snackbarColor = 'red'
    //         this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
    //         this.cancelFile()
    //       }
    //     })
    // },
    doDownload (key) {
      this.processingView = true
      const body = {
        pk: key
      }
      this.$http.post('/api/pqm/cfrAnalysisMgmt/downloadMachineRepairRawDataFile', body, { responseType: 'blob' })
        .then(res => {
          this.processingView = false
          if (res.data.type === 'application/json') {
            return new Promise((resolve, reject) => {
              const reader = new FileReader()
              reader.onload = () => {
                res.data = JSON.parse(reader.result)
                if (res.data.status === 'success') {
                  alert(res.data.data)
                } else {
                  this.snackbar = true
                  this.snackbarColor = 'red'
                  this.snackbarMsg = res.data.errMsg + ' (' + res.data.errCode + ')'
                }
              }
              reader.onerror = () => {
                reject(res.data.errMsg || res.data.data)
              }
              reader.readAsText(res.data)
            })
          } else if (res.data.status !== 'failure' && res.headers['content-disposition']) {
            const blob = new Blob([res.data], { type: 'application/octet-stream' })
            const url = window.URL.createObjectURL(blob)
            const fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]
            const downloadLink = document.createElement('a')
            downloadLink.href = url
            downloadLink.download = fileName

            document.body.appendChild(downloadLink)
            downloadLink.click()
            document.body.removeChild(downloadLink)
          } else {
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = '下載失敗，請通知系統管理員'
          }
        })
        .catch(err => {
          this.processingView = false
          this.snackbar = true
          this.snackbarColor = 'orange'
          this.snackbarMsg = err
        })
    },
    doSampleDownload () {
      this.processingView = true
      this.$http.post('/api/pqm/cfrAnalysisMgmt/downloadMachineRepairImportSnSampleFile', {}, { responseType: 'blob' })
        .then(res => {
          this.processingView = false
          if (res.data.type === 'application/json') {
            return new Promise((resolve, reject) => {
              const reader = new FileReader()
              reader.onload = () => {
                res.data = JSON.parse(reader.result)
                if (res.data.status === 'success') {
                  alert(res.data.data)
                } else {
                  this.snackbar = true
                  this.snackbarColor = 'red'
                  this.snackbarMsg = res.data.errMsg + ' (' + res.data.errCode + ')'
                }
              }
              reader.onerror = () => {
                reject(res.data.errMsg || res.data.data)
              }
              reader.readAsText(res.data)
            })
          } else if (res.data.status !== 'failure' && res.headers['content-disposition']) {
            const blob = new Blob([res.data], { type: 'application/octet-stream' })
            const url = window.URL.createObjectURL(blob)
            const fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]
            const downloadLink = document.createElement('a')
            downloadLink.href = url
            downloadLink.download = fileName

            document.body.appendChild(downloadLink)
            downloadLink.click()
            document.body.removeChild(downloadLink)
          } else {
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = '無檔案可供下載'
          }
        })
        .catch(err => {
          this.processingView = false
          this.snackbar = true
          this.snackbarColor = 'orange'
          this.snackbarMsg = err
        })
    }
  }
}
</script>
<style>
</style>
