<template>
  <v-container
    fluid
    grid-list-xl
    pt-0
  >
    <v-layout wrap>
      <v-flex
        xs12
      >
        <material-card
          :title="'生產不良率摘要篩選 (' + summaryWeeksRange + ')'"
          class="card-shrink card-padding v-card__first"
          color="general"
        >
          <div class="card-memo-1 text-white">
            <v-icon class="text-warning">mdi-checkbox-blank</v-icon>
            黃字代表不良率大於1%</div>
          <div class="card-memo-2 text-white">
            <v-icon class="text-danger">mdi-checkbox-blank</v-icon>
            紅字代表不良率大於3%</div>
          <v-form>
            <v-container
              py-0
              class="mw-100">
              <v-layout wrap>
                <v-flex
                  xs12
                  lg4>
                  <v-autocomplete
                    v-model="summaryWeeks"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryAllYearWeeksList"
                    label="時間"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex
                  xs12
                  sm12
                  lg4>
                  <v-autocomplete
                    v-model="summaryVendor"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryVendorMenu"
                    label="供應商"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex
                  xs12
                  lg4
                  text-xs-right
                >
                  <v-btn
                    class="mx-0 font-weight-light"
                    color="general"
                    @click="doSubmit()"
                  >
                    <v-icon class="mr-2">mdi-backup-restore</v-icon>查詢
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
        <material-card>
          <div class="calendar">
            <div>
              <div>
                <div class="calendar-np">
                  <span>案名/ASUS料號</span>
                </div>
                <div
                  v-for="(week, w) in WeekList"
                  :key="'w'+w"
                  class="calendar-week calendar-week-np">
                  <span>{{ week }}</span>
                </div>
                <div class="calendar-np">
                  <span>小計</span>
                </div>
              </div>
              <div
                v-for="(list, d) in summaryWeeksList"
                :key="'d'+d"
                class="calendar-row calendar-row-np mr-0 ml-0">
                <div>
                  <div class="calendar-day calendar-day-np-np d-flex">
                    <router-link
                      v-if="NPlist[d].TotalFailQty > 0"
                      :to="'/thermalModule/pl2/thermalModuleTestDataStatistc?deviceIsn='+NPlist[d].NP+'&modelname='+NPlist[d].modelName+'&date='+DataStatistcStart+'&daterange='+DataStatistcEnd+'&vendorpk='+NPlist[d].vendorPk+'&vendor='+NPlist[d].vendor"
                      target="_blank">
                      <v-btn class="black text-light pl-2 pr-2"><v-icon>mdi-clipboard-text-outline</v-icon></v-btn>
                    </router-link>
                    <div
                      :style="'color:'+ (theme === 'dark' ? 'rgba(252,252,252,1)' : 'rgba(33,33,33,1)') + ';line-height: 1rem;display: inline-block;display: table-cell;vertical-align: middle;padding: 0.5rem 0;'"
                      small>{{ NPlist[d].modelName }}<br>{{ NPlist[d].NP }}</div>
                  </div>
                  <div
                    v-for="(fail, dd) in list.date"
                    :key="'dd'+dd"
                    :class="{'calendar-around': fail.around}"
                    class="calendar-day calendar-day-fail calendar-day-np">
                    <a>
                      {{ fail.day }}
                    </a>
                    <div class="calendar-fail cursor">
                      <router-link
                        :to="'/thermalModule/pl2/thermalModuleTestDataStatistc?deviceIsn='+NPlist[d].NP+'&modelname='+NPlist[d].modelName+'&date='+fail.date+'&vendorpk='+NPlist[d].vendorPk+'&vendor='+NPlist[d].vendor"
                        :style="'color:' + (fail.failList.warningColor !== '' ? fail.failList.warningColor : theme === 'dark' ? 'rgba(245,245,245,1)' : 'rgba(30,30,30,1)') + '!important;'"
                        :hover="'color:' + (fail.failList.warningColor !== '' ? fail.failList.warningColor : theme === 'dark' ? 'rgba(245,245,245,1)' : 'rgba(30,30,30,1)') + '!important;'"
                        target="_blank"
                        small><span v-if="fail.failList.failRate >= 0 && fail.failList.productionQty > 0">{{ (fail.failList.failRate*100).toFixed(1) }}%</span><br v-if="fail.failList.productionQty > 0 && fail.failList.failRate >= 0"><span v-if="fail.failList.productionQty > 0">{{ fail.failList.productionQty }}pcs</span>
                      </router-link>
                      <!-- <div
                        :style="'color:' + fail.failList.warningColor + ';'"
                        style="color:#999;line-height: 3rem;"
                        small>{{ fail.failList.productionQty }}<span v-if="fail.failList.productionQty && fail.failList.failRate"> / </span>{{ (fail.failList.failRate*100).toFixed(1) }}%</div> -->
                    </div>
                  </div>
                  <div class="calendar-day calendar-day-np-np">
                    <div class="calendar-fail">
                      <div
                        :style="'color:'+ (theme === 'dark' ? 'rgba(252,252,252,1)' : 'rgba(33,33,33,1)') + ';line-height: 3rem;'"
                        small>{{ NPlist[d].TotalFailQty }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div class="calendar-np">
                  <span>總計</span>
                </div>
                <div
                  v-for="(Qty, q) in DayTotalQtylist"
                  :key="'q'+q"
                  class="calendar-week calendar-week-np pr-1 text-right">
                  <span>{{ Qty }}</span>
                </div>
                <div class="calendar-np text-right">
                  <span>{{ WeeksTotalQty }}</span>
                </div>
              </div>
            </div>
          </div>
        </material-card>
      </v-flex>
      <v-flex
        flex_width_auto
        xs12
      >
        <material-card
          :title="'符合 '+summaryWeeksRange+' 的總合數據'"
          color="deep-orange"
        >
          <v-data-table
            :headers="headers"
            :items="responseFailTable"
            :pagination.sync="pagination"
            class="mt-3 elevation-1"
            multi-sort
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="subheading font-weight-light text-general text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <td>{{ item.deviceIsn }}</td>
              <td class="text-xs-right">{{ item.productionQty }}</td>
              <td class="text-xs-right">{{ item.failQty }}</td>
              <td class="text-xs-right"><v-chip
                :class="item.warningColor"
                small>{{ (item.failRate*100).toFixed(1) }}%</v-chip></td>
              <td>{{ item.vendor }}</td>
              <td>
                <router-link
                  :to="'/thermalModule/pl2/thermalModuleTestDataStatistc?deviceIsn='+item.deviceIsn+'&modelname='+item.modelName+'&date='+DataStatistcStart+'&daterange='+DataStatistcEnd+'&vendorpk='+item.vendorPk+'&vendor='+item.vendor"
                  target="_blank">
                  <v-btn
                    class="mx-0 mr-3 font-weight-light small"
                    color="primary">
                    <v-icon class="mr-2">mdi-chart-bar</v-icon>當週統計
                  </v-btn>
                </router-link>
              </td>
              <td>
                <v-btn
                  class="mx-0 font-weight-light small"
                  color="green"
                  @click="chartLineViewName=item.deviceIsn;chartLineView=true">
                  <v-icon class="mr-2">mdi-chart-line</v-icon>折線分佈
                </v-btn>
              </td>
            </template>
          </v-data-table>
        </material-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="chartLineView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <v-card
        max-width="85%"
        style="margin:0 auto;">
        <v-card-text>
          <v-alert
            v-model="chartLineView"
            class="mt-3 mb-3 border-0"
            color="brown">
            <strong class="h4">{{ chartLineViewName }} 生產不良率折線分佈 {{ summaryWeeksRange }}</strong>
          </v-alert>
          <vue-c3 :handler="handler_line"/>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="red"
            small
            @click="chartLineView=false">
            <v-icon class="mr-2">mdi-close</v-icon>關閉
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="loadingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :top="true"
      :color="snackbarColor">
      {{ snackbarMsg }}
      <v-btn
        dark
        flat
        @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import Vue from 'vue'
import VueC3 from 'vue-c3'
import 'c3/c3.min.css'
import { mapState } from 'vuex'
import Loading from '@/assets/img/loading.gif'

export default {
  name: 'WeeklyThermalModuleProdutionSummaryPL2',
  components: {
    VueC3
  },
  data () {
    return {
      startdate: '2018-04-01',
      snackbar: false,
      snackbarColor: 'red',
      snackbarMsg: '',
      Loading,
      loadingView: false,
      summaryMonth: this.$moment(new Date()).format('YYYY-MM'),
      summaryWeeks: Number(this.$moment(new Date()).format('YYYY')) * 100 + Math.ceil((((new Date(this.$moment(new Date()).format('YYYY-MM-DD')) - new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1))) / 86400000) + new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1)).getDay()) / 7) - parseInt((new Date(this.$moment(new Date()).format('YYYY') + '-01-01').getDay() + 5) / 6),
      summaryVendorMenu: [],
      summaryVendor: 'All',
      summaryWeeksRange: '',
      summaryWeeksUnfoldList: [],
      summaryWeeksList: [],
      summaryYearWeeksList: [],
      summaryAllYearWeeksList: [],
      responseFailList: [],
      responseFailTable: [],
      chartLineView: false,
      chartLineViewNum: 0,
      chartLineViewName: '',
      DataStatistcStart: '',
      DataStatistcEnd: '',
      barXaxisName: [],
      barXaxisValue: [],
      WeekList: ['日', '一', '二', '三', '四', '五', '六'],
      pagination: {
        rowsPerPage: 1,
        sortBy: 'TotalFailRate', // The field that you're sorting by
        descending: true
      },
      headers: [
        {
          sortable: true,
          text: 'ASUS料號',
          value: 'deviceIsn'
        },
        {
          sortable: true,
          text: '生產數量',
          value: 'productionQty',
          align: 'right'
        },
        {
          sortable: true,
          text: '總不良數量',
          value: 'failQty',
          align: 'right'
        },
        {
          sortable: true,
          text: '總不良率',
          value: 'failRate',
          align: 'right'
        },
        {
          sortable: true,
          text: '供應商',
          value: 'vendor'
        },
        {
          sortable: false,
          text: '熱測數據',
          value: ''
        },
        {
          sortable: false,
          text: '不良率分佈',
          value: ''
        }
      ],
      NPlist: [],
      DayTotalQtylist: [0, 0, 0, 0, 0, 0, 0],
      WeeksTotalQty: 0,
      handler_line: new Vue()
    }
  },
  computed: {
    ...mapState('app', ['theme', 'shrink']),
    options_line () {
      return {
        data: {
          x: 'x',
          columns: [
            this.barXaxisName,
            this.barXaxisValue
          ],
          labels: {
            // format: function (v, id, i, j) { return v + '%' }
          },
          type: 'line',
          colors: {
            // ModelName: '#999999'
          }
          // color: function (color, d) {
          //   // d will be 'id' when called for legends
          //   return d.value >= 5.0 ? '#ff0000' : d.value >= 3.0 ? '#ff9800' : color
          // }
        },
        axis: {
          x: {
            type: 'category', // this needed to load string x value
            label: {
              text: '日期',
              position: 'outer-center'
            }
          },
          y: {
            tick: {
              format: function (value) { return value + '%' }
            },
            label: {
              text: '不良率',
              position: 'outer-middle'
            }
          }
        },
        tooltip: {
          format: {
            title: (d, value) => {
              return `${this.barXaxisName[d + 1]}`
              // return `第 ${d} 期`
            },
            value: (value, ratio, id) => {
              return `${value} %`
              // return `${value} 億`
            }
          }
        },
        grid: {
          x: {
            show: true
          },
          y: {
            show: true
          }
        },
        legend: {
          position: 'bottom',
          padding: 16,
          show: false
        },
        size: {
          width: ((window.innerWidth - this.shrink) * 0.85) - 64,
          height: 500
        }
      }
    }
  },
  watch: {
    chartLineView (val) {
      if (val) {
        this.barXaxisName = ['x']
        this.barXaxisValue = ['不良率']
        this.NPlist.forEach((r, index) => {
          if (r.NP === this.chartLineViewName) {
            this.summaryWeeksList[index].date.forEach((rr) => {
              this.barXaxisName.push(this.$moment(rr.date).format('MM-DD'))
              this.barXaxisValue.push(((rr.failList.failRate || 0) * 100).toFixed(1))
            })
          }
        })
        // this.responseFailList.forEach((r) => {
        //   this.barXaxisName.push(this.$moment(r.productionDate).format('MM-DD'))
        //   this.barXaxisValue.push('')
        //   if (r.productionData.length > 0) {
        //    r.productionData.forEach((rr) => {
        //      if (rr.modelName === this.chartLineViewName) {
        //        this.barXaxisValue[this.barXaxisValue.length - 1] = (rr.failRate * 100).toFixed(1)
        //      }
        //    })
        //   }
        // })
        if (this.chartLineViewNum === 0) {
          this.handler_line.$emit('init', this.options_line)
          this.chartLineViewNum++
        } else {
          this.handler_line.$emit('dispatch', chart => {
            const options = {
              columns: [
                this.barXaxisName,
                this.barXaxisValue
              ]
            }
            chart.load(options)
          })
        }
      } else {
        // this.handler_line.$emit('destroy')
      }
    }
  },
  mounted () {
    this.$store.dispatch('getAuthorize', '/thermalModule/pl2/weeklyThermalModuleProdutionSummary')
      .then(response => {
        if (response.status === 'success' && response.data.accessible) {
          JSON.parse(localStorage.getItem('moduleDataInitDate')).forEach((n) => {
            if (n.name === 'thermalModule') {
              this.startdate = n.value
            }
          })
          if (this.$route.params.searchDate) {
            this.summaryWeeks = Number(this.$route.params.searchDate)
          }
          this.$http.get('/api/getSelectOptionData/vendor').then(res => res.data)
            .then(res => {
              if (res.status === 'success') {
                this.summaryVendorMenu = [{ text: 'All', value: 'All' }].concat(res.data)
              }
            })
          // this.$http.get('/api/getSelectOptionData/thermalModuleAsusDeviceIsn').then(res => res.data)
          //   .then(res => {
          //     if (res.status === 'success') {
          //       this.summaryAsusDeviceIsnMenu = [{ text: 'All', value: 'All' }].concat(res.data)
          //     }
          //   })
          this.loadData()
        } else if (response.errCode === 'E10000002') {
          const routeData = this.$router.resolve({ path: '/noEnter/E10000002' })
          localStorage.setItem('errormsg', response.errMsg)
          window.location.assign(routeData.href)
        } else {
          const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
          window.location.assign(routeData.href)
        }
      })
      .catch(err => {
        console.log(err)
        const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
        window.location.assign(routeData.href)
      })
  },
  methods: {
    async loadData () {
      this.loadingView = true
      await this.YearWeeksList()
      await this.AllYearWeeksList()
      await this.doSubmit()
    },
    YearWeeksList () {
      const Arr = []
      const YearStart = this.$moment(this.startdate).format('YYYY')
      const YearEnd = this.$moment(new Date()).format('YYYY')
      let NewYear = false
      let YearEndWeek = 0

      for (let y = 0; y <= (YearEnd - YearStart + 1); y++) {
        Arr[y] = []
        let w = 0
        let n = 0
        let day = new Date(Number(YearStart) + y + '-01-01').getTime()
        if (y === 0 && this.$moment(this.startdate).format('MM-DD') !== '01-01') {
          day = new Date(Number(YearStart) + y + '-' + this.$moment(this.startdate).format('MM-DD')).getTime()
          w = Math.ceil((((new Date(this.$moment(this.startdate).format('YYYY-MM-DD')) - new Date(new Date(this.$moment(this.startdate).format('YYYY'), 0, 1))) / 86400000) + new Date(new Date(this.$moment(this.startdate).format('YYYY'), 0, 1)).getDay()) / 7)
          if (new Date(this.$moment(this.startdate).format('YYYY') + '-01-01').getDay() > 0) {
            w = w - 1
          }
          Arr[y][w] = { start: this.$moment(this.startdate).format('YYYY-MM-DD'), end: '' }
          if (new Date(this.$moment(this.startdate).format('YYYY-MM-DD')).getDay() === 6) {
            Arr[y][w].end = this.$moment(this.startdate).format('YYYY-MM-DD')
          } else if (new Date(this.$moment(this.startdate).format('YYYY-MM-DD')).getDay() === 0) {
            w = w - 1
          }
        } else if (new Date(Number(YearStart) + y + '-01-01').getDay() !== 0) {
          n = 6 - new Date(Number(YearStart) + y + '-01-01').getDay()
          Arr[y][1] = { start: '', end: '' }
        }
        for (let d = (day + (86400000 * n)); d <= (new Date(Number(YearStart) + y + '-12-31').getTime()); d = d + 86400000) {
          if (y > 0 && new Date(d).getDay() === 6) {
            if (Arr[y][1].start === '') {
              Arr[y - 1][YearEndWeek].end = this.$moment(d).format('YYYY-MM-DD')
              Arr[y][0] = { start: Arr[y - 1][YearEndWeek].start, end: this.$moment(d).format('YYYY-MM-DD') }
              w = 0
              YearEndWeek = w
              NewYear = true
            }
          }
          if (new Date(d).getDay() === 0) {
            w++
            YearEndWeek = w
            NewYear = false
            Arr[y][w] = { start: this.$moment(d).format('YYYY-MM-DD'), end: '' }
          } else if (!NewYear && new Date(d).getDay() === 6) {
            Arr[y][w].end = this.$moment(d).format('YYYY-MM-DD')
          }
        }
      }
      this.summaryYearWeeksList = Arr
    },
    AllYearWeeksList () {
      const Arr = []
      const YearStart = this.$moment(this.startdate).format('YYYY')
      let YearEnd = this.$moment(this.summaryMonth).format('YYYY')
      let LastYear = 0
      if (new Date(this.$moment(this.summaryMonth).format('YYYY') + '-01-01').getDay() > 0) {
        LastYear = 1
      }
      const WeeksEnd = Math.ceil((((new Date(this.$moment(new Date()).format('YYYY-MM-DD')) - new Date(new Date(this.$moment(this.summaryMonth).format('YYYY'), 0, 1))) / 86400000) + new Date(new Date(this.$moment(this.summaryMonth).format('YYYY'), 0, 1)).getDay()) / 7) - LastYear
      if (WeeksEnd === 0) {
        YearEnd--
      }
      for (let y = 0; y <= (YearEnd - YearStart); y++) {
        for (let w = 1; w < this.summaryYearWeeksList[y].length; w++) {
          if (y === (YearEnd - YearStart) && w === WeeksEnd) {
            Arr.push({ text: (Number(YearStart) + y) + '　w' + w + '　( ' + this.$moment(this.summaryYearWeeksList[y][w].start).format('MM-DD') + ' ～ ' + this.$moment(this.summaryYearWeeksList[y][w].end).format('MM-DD') + ' )', value: (Number(YearStart) + y) * 100 + w })
            break
          }
          if (this.summaryYearWeeksList[y][w]) {
            Arr.push({ text: (Number(YearStart) + y) + '　w' + w + '　( ' + this.$moment(this.summaryYearWeeksList[y][w].start).format('MM-DD') + ' ～ ' + this.$moment(this.summaryYearWeeksList[y][w].end).format('MM-DD') + ' )', value: (Number(YearStart) + y) * 100 + w })
          }
        }
      }
      Arr.sort(function (a, b) { return b.value - a.value })
      this.summaryAllYearWeeksList = Arr
    },
    doSubmit () {
      this.loadingView = true
      let ww = 0
      if (Number.isInteger(this.summaryWeeks)) {
        ww = String(this.summaryWeeks).substr(4)
      } else {
        ww = Math.ceil((((new Date(this.$moment(new Date()).format('YYYY-MM-DD')) - new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1))) / 86400000) + new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1)).getDay()) / 7) - parseInt((new Date(this.$moment(new Date()).format('YYYY') + '-01-01').getDay() + 5) / 6)
      }

      const body = {
        queryStartDate: this.$moment(this.summaryYearWeeksList[String(this.summaryWeeks).substr(0, 4) - this.$moment(this.startdate).format('YYYY')][Number(ww)].start).format('YYYY-MM-DD'),
        queryEndDate: this.$moment(this.summaryYearWeeksList[String(this.summaryWeeks).substr(0, 4) - this.$moment(this.startdate).format('YYYY')][Number(ww)].end).format('YYYY-MM-DD'),
        vendor: this.summaryVendor === 'All' ? '' : this.summaryVendor,
        deviceIsn: this.summaryAsusDeviceIsn === 'All' ? '' : this.summaryAsusDeviceIsn
      }

      this.DataStatistcStart = body.queryStartDate
      this.DataStatistcEnd = body.queryEndDate

      this.$http.post('/api/thermalModule/pl2/thermalModuleFailRateSummaryList', body).then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.pagination.rowsPerPage = res.data.length
            this.responseFailTable = res.data
          }
        })
      this.$http.post('/api/thermalModule/pl2/weeklyThermalModuleFailRateInfo', body).then(res => res.data)
        .then(res => {
          this.loadingView = false
          if (res.status === 'success') {
            this.responseFailList = res.data
            const Arr = []
            let Year = String(this.summaryWeeks).substr(0, 4)
            let start = this.$moment(this.summaryYearWeeksList[Year - this.$moment(this.startdate).format('YYYY')][Number(ww)].start).format('DD')
            let end = this.$moment(this.summaryYearWeeksList[Year - this.$moment(this.startdate).format('YYYY')][Number(ww)].end).format('DD')
            this.summaryWeeksRange = this.summaryYearWeeksList[Year - this.$moment(this.startdate).format('YYYY')][Number(ww)].start + ' 至 ' + Year + '-' + this.summaryYearWeeksList[Year - this.$moment(this.startdate).format('YYYY')][Number(ww)].end

            if (start > end) {
              start = '01'
            } else if (end < start) {
              end = this.$moment(new Date(this.$moment(this.summaryMonth).format('YYYY'), this.$moment(this.summaryMonth).format('MM'), 0)).format('DD')
            }

            const MonthStart = this.$moment(this.summaryYearWeeksList[String(this.summaryWeeks).substr(0, 4) - this.$moment(this.startdate).format('YYYY')][Number(String(this.summaryWeeks).substr(4))].start).format('MM')
            const MonthEnd = this.$moment(this.summaryYearWeeksList[String(this.summaryWeeks).substr(0, 4) - this.$moment(this.startdate).format('YYYY')][Number(String(this.summaryWeeks).substr(4))].end).format('MM')

            if (MonthStart > MonthEnd) {
              Year++
            }

            if (res.data.length > 0) {
              for (let x = 0; x < res.data.length; x++) {
                Arr[x] = { weeks: ww, date: [] }

                const BeforeSpaceList = []
                if (MonthStart !== MonthEnd) {
                  const lastMonthEndDate = this.$moment(new Date(Year + '-' + MonthEnd + '-01') - 86400000).format('DD')
                  for (let i = 0; i < new Date(Year + '-' + MonthEnd + '-01').getDay(); i++) {
                    BeforeSpaceList.push({ date: this.$moment(String(this.summaryWeeks).substr(0, 4) + '-' + MonthStart + '-' + (lastMonthEndDate - new Date(Year + '-' + MonthEnd + '-01').getDay() + i + 1)).format('YYYY-MM-DD'), day: lastMonthEndDate - new Date(Year + '-' + MonthEnd + '-01').getDay() + i + 1, around: false, fail: true, over: false, failList: res.data[x].statistic[i] })
                  }
                }
                for (let n = 0; n < BeforeSpaceList.length; n++) {
                  Arr[x].date.push(BeforeSpaceList[n])
                }
                for (let d = 0; d <= (end - start); d++) {
                  const dd = (Number(start) + d) < 10 ? '0' + (Number(start) + d) : (Number(start) + d)
                  Arr[x].date.push({ date: this.$moment(Year + '-' + MonthEnd + '-' + dd).format('YYYY-MM-DD'), day: Number(start) + d, fail: true, over: false, failList: res.data[x].statistic[d + BeforeSpaceList.length] })
                }
              }
              this.NPlist = []
              res.data.forEach((r) => {
                this.NPlist.push({ NP: r.deviceIsn, modelName: r.modelName, TotalFailQty: r.totalProductionQty, vendor: r.vendor, vendorPk: r.vendorPk })
              })
              this.DayTotalQtylist = [0, 0, 0, 0, 0, 0, 0]
              res.data.forEach((r) => {
                for (let day = 0; day <= 6; day++) {
                  this.DayTotalQtylist[day] = this.DayTotalQtylist[day] + r.statistic[day].productionQty
                }
              })
            } else {
              Arr[0] = { weeks: ww, date: [] }
              const BeforeSpaceList = []
              if (MonthStart !== MonthEnd) {
                const lastMonthEndDate = this.$moment(new Date(Year + '-' + MonthEnd + '-01') - 86400000).format('DD')
                for (let i = 0; i < new Date(Year + '-' + MonthEnd + '-01').getDay(); i++) {
                  BeforeSpaceList.push({ date: this.$moment(String(this.summaryWeeks).substr(0, 4) + '-' + MonthStart + '-' + (lastMonthEndDate - new Date(Year + '-' + MonthEnd + '-01').getDay() + i + 1)).format('YYYY-MM-DD'), day: lastMonthEndDate - new Date(Year + '-' + MonthEnd + '-01').getDay() + i + 1, around: false, fail: true, over: false, failList: '' })
                }
              }
              for (let n = 0; n < BeforeSpaceList.length; n++) {
                Arr[0].date.push(BeforeSpaceList[n])
              }
              for (let d = 0; d <= (end - start); d++) {
                const dd = (Number(start) + d) < 10 ? '0' + (Number(start) + d) : (Number(start) + d)
                Arr[0].date.push({ date: this.$moment(Year + '-' + MonthEnd + '-' + dd).format('YYYY-MM-DD'), day: Number(start) + d, fail: true, over: false, failList: '' })
              }
              this.NPlist = [{ NP: '', modelName: '', TotalFailQty: '', vendor: '', vendorPk: '' }]
              this.DayTotalQtylist = [0, 0, 0, 0, 0, 0, 0]
            }

            this.summaryWeeksList = Arr

            this.WeeksTotalQty = 0
            this.DayTotalQtylist.forEach((r) => {
              this.WeeksTotalQty = this.WeeksTotalQty + r
            })
          }
        })
    }
  }
}
</script>
<style>
</style>
