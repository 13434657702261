<template>
  <v-container
    cfr
    fluid
    grid-list-xl
  >
    <v-layout wrap>
      <v-flex
        xs12
        lg12
      >
        <material-card
          title="年度不良率趨勢"
          class="card-padding v-card__first"
          color="general"
        >
          <v-form>
            <v-container
              py-0
              class="mw-100">
              <v-layout wrap>
                <v-flex
                  flex-small
                  xs12
                  lg3>
                  <v-autocomplete
                    v-model="modelYear"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="modelYearMenu"
                    label="開賣年度"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                    multiple
                  />
                </v-flex>
                <v-flex
                  flex-small
                  xs12
                  lg3>
                  <v-autocomplete
                    v-model="series"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="seriesMenu"
                    label="Series"
                    class="v-text-field__slot list-select-field"
                    persistent-hint
                    outlined
                    multiple
                  />
                </v-flex>
                <v-flex
                  flex-small
                  xs12
                  lg3>
                  <v-autocomplete
                    v-model="repairLevel"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="repairLevelMenu"
                    label="Level"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                    multiple
                  />
                </v-flex>
                <v-flex
                  flex-small
                  d-flex
                  align-items-center
                  text-xs-right
                  xs12
                  lg3>
                  <v-checkbox
                    v-model="excludeBestBuy"
                    :value="true"
                    input-value="1"
                    class="m-0 py-0"
                    label="Exclude Best Buy"/>
                  <v-btn
                    class="mx-0 mr-3 font-weight-light"
                    color="general"
                    @click="doSubmit()"
                  >
                    <v-icon class="mr-2">mdi-backup-restore</v-icon>統計
                  </v-btn>
                  <v-btn
                    class="mx-0 mr-3 font-weight-light"
                    color="indigo"
                    @click="doDownloadRawData()"
                  >
                    <v-icon class="mr-2">mdi-export</v-icon>匯出Raw Data
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
      </v-flex>
      <v-flex
        v-show="analyzerView"
        md12
        xs12
        selenium-grab
        class="selenium-grab"
        style="padding: 0px 12px!important;">
        <material-card
          :title="chartName"
          color="brown"
          class="d-table-t0 no-data-left"
          style="margin-top: 30px!important;">
          <vue-c3 :handler="chartArea"/>
        </material-card>
        <material-card class="card-mix table-overflow d-table-t0 no-data-left">
          <table class="statistic-table analyzer-table">
            <thead>
              <tr>
                <th class="text-center">Life Time</th>
                <th
                  v-for="(title,t) in responseTableData.columnName"
                  :key="'t'+t"
                  class="text-center">{{ title }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(content,c) in responseTableData.content"
                :key="'c'+c"
                :class="{'target':content.name.toLowerCase().indexOf('target')>-1}">
                <td>{{ content.name }}</td>
                <td
                  v-for="(data,d) in content.data"
                  :key="'d'+d"
                  class="text-center">{{ data!==null?(data * 100).toFixed(2)+'%':'' }}</td>
              </tr>
            </tbody>
          </table>
        </material-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="processingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        PROCESSING 處理中
      </p>
    </v-dialog>
    <v-dialog
      v-model="loadingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :color="snackbarColor"
      :top="true">
      {{ snackbarMsg }}
      <v-btn
        flat
        @click="snackbar = false">
        關閉
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import qs from 'qs'
import Vue from 'vue'
import VueC3 from 'vue-c3'
import 'c3/c3.min.css'
import { mapState } from 'vuex'
import Loading from '@/assets/img/loading.gif'

export default {
  name: 'ThreeYearCfrAnalysis',
  components: {
    VueC3
  },
  data () {
    return {
      snackbar: false,
      snackbarColor: 'red',
      snackbarMsg: '',
      Loading,
      loadingView: false,
      processingView: false,
      modelYear: [],
      modelYearMenu: [],
      series: [],
      seriesMenu: [],
      repairLevel: [],
      repairLevelMenu: [],
      excludeBestBuy: false,
      hintType: 'fix_point',
      chartName: '',
      baseline: false,
      analyzerView: false,
      analyzerType: 0,
      responseChartData: [],
      chartXaxisName: [],
      chartXaxisValue: [],
      chartLineTypes: {},
      // chartLineRegions: {},
      chartLineHint: {},
      // chartPieTooltip: {
      //   contents: (d, defaultTitleFormat, defaultValueFormat, color) => {
      //     return '<p>' + defaultTitleFormat + '</p>'
      //   }
      // },
      chartLineTooltip: [],
      chartLineId: 0,
      chartBaseLineId: '',
      chartArea: new Vue(),
      responseTableData: {
        columnName: [],
        content: []
      }
      // pagination: {
      //   rowsPerPage: 1,
      //   sortBy: 'name', // The field that you're sorting by
      //   descending: true
      // },
      // headers: [
      //   {
      //     sortable: true,
      //     text: '群組名稱',
      //     value: 'name',
      //     class: 'table-width-135'
      //   },
      //   {
      //     sortable: false,
      //     text: '操作',
      //     value: 'operation',
      //     class: 'table-width-125'
      //   }
      // ]
    }
  },
  computed: {
    ...mapState('app', ['theme', 'shrink']),
    typeList () {
      const list = []
      this.resTypeListData.forEach((r) => {
        list.push({ text: r.columnLabel, value: r.columnName })
      })
      return list
    },
    options_line () {
      return {
        data: {
          x: 'x',
          columns: this.chartXaxisName.concat(this.chartXaxisValue),
          labels: {
            format: (v, id, i, j) => {
              if (v && id && i >= 0) {
                if (this.chartLineHint[id].indexOf(i) > -1) {
                  return (v * 100).toFixed(2) + '%'
                } else {
                  return ''
                }
              }
            },
            color: '#999999'
          },
          type: (this.analyzerType === 0) ? 'line' : 'pie',
          types: this.chartLineTypes,
          // regions: this.chartLineRegions,
          colors: {
            // ModelName: '#999999'
          },
          onclick: (d, i) => {
            if (d.id !== this.chartBaseLineId) {
              this.doChangeChartLineHint(d)
            }
          },
          onmouseover: (d, i) => { this.doChangeChartLineId(d) }
        },
        point: {
          r: (v) => {
            if (v.id && v.index >= 0) {
              if (this.chartLineHint[v.id].indexOf(v.index) > -1) {
                return 5
              } else if (v.id === this.chartBaseLineId) {
                return 0
              } else {
                return 3
              }
            } else {
              return 0
            }
          }
        },
        axis: {
          x: {
            type: 'category', // this needed to load string x value
            tick: {
              rotate: -45,
              multiline: false
            },
            label: {
              text: '週期月',
              position: 'outer-right'
            },
            padding: {
              top: 0,
              right: 0.5,
              bottom: 0,
              left: 0.5
            }
          },
          y: {
            tick: {
              format: (value) => { return (value * 100).toFixed(2) + '%' }
            },
            label: {
              text: '不良率',
              position: 'outer-bottom'
            }
          }
        },
        pie: {
          label: {
            format: (value, ratio, id) => {
              return (ratio * 100).toFixed(2) + '%'
            }
          }
        },
        // pie: {
        //   label: {
        //     format: (value, ratio, id) => {
        //       return value + 'Pcs'
        //     },
        //     threshold: -1
        //   }
        // },
        // tooltip: this.analyzerType === 0 ? this.chartLineTooltip : this.chartPieTooltip,
        tooltip: {
          contents: (d) => {
            let message = ''
            const that = this
            d.forEach((dd, index) => {
              if (dd.id === that.chartLineId) {
                that.chartLineTooltip.forEach((c, i) => {
                  if (c.id === that.chartLineId) {
                    message = '<div style="color:#ffffff!important;background-color:#000000;padding:10px;font-weight:900!important;">' + that.chartLineTooltip[i].message[dd.index] + '</div>'
                  }
                })
              }
            })
            return message
          }
          // format: {
          //   title: (d, value) => {
          //     if (d) {
          //       return `${this.chartXaxisName[0][d + 1]}`
          //     } else {
          //       return `${this.chartXaxisValue[value][0]}`
          //     }
          //     // return `第 ${d} 期`
          //   },
          //   value: (value, ratio, id) => {
          //     return `${(value * 100).toFixed(2)} %`
          //     // return `${value} 億`
          //   }
          // }
        },
        grid: {
          x: {
            show: true
          },
          y: {
            show: true
          }
        },
        legend: {
          position: 'right',
          padding: 0,
          show: true
        },
        size: {
          width: ((window.innerWidth - this.shrink) * 1) - 64 - (this.chartType === 1 ? 410 : 0),
          height: 600
        }
      }
    }
  },
  watch: {
    chartType (val) {
      if (val === 0) {
        this.lineArray = [{ lineType: 0, customGroupName: false, groupName: '', fields: [], selectAllFields: [], selectAllList: [] }]
        this.lineGroup = 0
        this.lineGroupList = []
      } else if (val === 1) {
        this.lineArray = [{ lineType: 2, customGroupName: false, groupName: '', fields: [], selectAllFields: [], selectAllList: [] }]
        this.lineGroup = 0
        this.lineGroupList = []
      }
    }
  },
  mounted () {
    this.$store.dispatch('getAuthorize', '/pqm/cfrAnalysisMgmt/threeYearCfrAnalysisView')
      .then(response => {
        if (response.status === 'success' && response.data.accessible) {
          this.$http.get('/api/pqm/cfrAnalysisMgmt/modelYear').then(res => res.data)
            .then(res => {
              this.loadingView = false
              if (res.status === 'success') {
                this.modelYearMenu = res.data
              }
            })
          this.$http.get('/api/pqm/cfr/series').then(res => res.data)
            .then(res => {
              this.loadingView = false
              if (res.status === 'success') {
                this.seriesMenu = res.data
              }
            })
          this.$http.get('/api/pqm/cfr/repairLevel').then(res => res.data)
            .then(res => {
              this.loadingView = false
              if (res.status === 'success') {
                this.repairLevelMenu = res.data
              }
            })
          setTimeout(() => {
            const { yearList, hintType } = this.$route.query
            if (yearList || hintType) {
              this.modelYear = yearList || this.modelYear
              this.hintType = hintType || this.hintType
              this.doSubmit()
            }
          }, 500)
          // this.doSubmit()
          // this.chartArea.$emit('init', this.options_line)
        } else if (response.errCode === 'E10000002') {
          const routeData = this.$router.resolve({ path: '/noEnter/E10000002' })
          localStorage.setItem('errormsg', response.errMsg)
          window.location.assign(routeData.href)
        } else {
          const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
          window.location.assign(routeData.href)
        }
      })
      .catch(err => {
        console.log(err)
        const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
        window.location.assign(routeData.href)
      })
  },
  methods: {
    doChangeChartLineId (d) {
      this.chartLineId = d.id
    },
    doChangeChartLineHint (d) {
      if (this.chartLineHint[d.id].indexOf(d.index) === -1) {
        this.chartLineHint[d.id].push(d.index)
      } else {
        this.chartLineHint[d.id].splice(this.chartLineHint[d.id].indexOf(d.index), 1)
      }
      this.chartArea.$emit('dispatch', chart => {
        const options = {
          columns: this.chartXaxisName.concat(this.chartXaxisValue)
        }
        chart.load(options)
      })
    },
    doSubmit () {
      this.loadingView = true
      if (this.modelYear.length === 0 && this.series.length === 0 && this.repairLevel.length === 0) {
        this.modelYear.push(this.$moment(new Date()).format('YYYY'))
        this.modelYear.push((this.$moment(new Date()).format('YYYY') - 1).toString())
        this.modelYear.push((this.$moment(new Date()).format('YYYY') - 2).toString())
      }
      const body = {
        yearList: this.modelYear,
        seriesList: this.series,
        repairLevelList: this.repairLevel,
        excludeBestBuy: this.excludeBestBuy || false,
        hintType: this.hintType
      }
      this.$http.get('/api/pqm/cfr/threeYearCfrAnalysisData?' + qs.stringify(body, { arrayFormat: 'repeat', encode: true })).then(res => res.data)
        .then(res => {
          this.loadingView = false
          if (res.status === 'success') {
            this.chartName = res.data.chartName
            this.responseTableData = res.data.table
            this.chartXaxisName = [res.data.xColumn]
            this.chartXaxisValue = []
            this.chartLineTypes = {}
            // this.chartLineRegions = {}
            this.chartLineHint = {}
            if (res.data.lineData.length > 0) {
              res.data.lineData.forEach((c) => {
                this.chartXaxisValue.push(c.data)
                this.chartLineTypes[c.data[0]] = 'line'
                this.chartLineHint[c.data[0]] = c.hint
                this.chartLineTooltip.push({ id: c.data[0], message: c.messageList })
              })
            }
            if (res.data.barData.length > 0) {
              res.data.barData.forEach((c) => {
                this.chartXaxisValue.push(c.data)
                this.chartLineTypes[c.data[0]] = 'bar'
                this.chartLineHint[c.data[0]] = c.hint
                this.chartLineTooltip.push({ id: c.data[0], message: c.messageList })
              })
            }
            if (res.data.pieData.length > 0) {
              res.data.pieData.forEach((c) => {
                this.chartXaxisValue.push(c.data)
                this.chartLineTypes[c.data[0]] = 'pie'
                this.chartLineHint[c.data[0]] = c.hint
              })
            }
            if (res.data.type === 0) {
              this.chartXaxisValue.push(res.data.baseline.data)
              this.chartLineTypes[res.data.baseline.data[0]] = 'area'
              // this.chartLineRegions[res.data.baseline.data[0]] = [{ style: 'dashed' }]
              this.chartLineHint[res.data.baseline.data[0]] = res.data.baseline.hint
              this.chartBaseLineId = res.data.baseline.data[0]
              this.chartBaseLineColor = { [res.data.baseline.data[0]]: '#99cc5555' }
            }
            this.chartArea.$emit('init', this.options_line)
            this.analyzerType = res.data.type
            this.analyzerView = true
          } else {
            this.analyzerView = false
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
          }
        })
    },
    doDownloadRawData () {
      this.processingView = true
      if (this.modelYear.length === 0 && this.series.length === 0 && this.repairLevel.length === 0) {
        this.modelYear = [this.$moment(new Date()).format('YYYY'), this.$moment(new Date()).format('YYYY') - 1, this.$moment(new Date()).format('YYYY') - 2]
      }
      const body = {
        yearList: this.modelYear,
        seriesList: this.series,
        repairLevelList: this.repairLevel,
        excludeBestBuy: this.excludeBestBuy || false
      }
      this.$http.get('/api/pqm/cfr/threeYearCfrAnalysisRawDataFile?' + qs.stringify(body, { arrayFormat: 'repeat', encode: true }), { responseType: 'blob' })
        .then(res => {
          this.processingView = false
          if (res.data.type === 'application/json') {
            return new Promise((resolve, reject) => {
              const reader = new FileReader()
              reader.onload = () => {
                res.data = JSON.parse(reader.result)
                if (res.data.status === 'success') {
                  alert(res.data.data)
                } else {
                  this.snackbar = true
                  this.snackbarColor = 'red'
                  this.snackbarMsg = res.data.errMsg + ' (' + res.data.errCode + ')'
                }
              }
              reader.onerror = () => {
                reject(res.data.errMsg || res.data.data)
              }
              reader.readAsText(res.data)
            })
          } else if (res.data.status !== 'failure' && res.headers['content-disposition']) {
            const blob = new Blob([res.data], { type: 'application/octet-stream' })
            const url = window.URL.createObjectURL(blob)
            const fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]
            const downloadLink = document.createElement('a')
            downloadLink.href = url
            downloadLink.download = fileName

            document.body.appendChild(downloadLink)
            downloadLink.click()
            document.body.removeChild(downloadLink)
          } else {
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = '下載失敗，請通知系統管理員'
          }
        })
        .catch(err => {
          this.processingView = false
          this.snackbar = true
          this.snackbarColor = 'orange'
          this.snackbarMsg = err
        })
    }
  }
}
</script>
<style>
</style>
