<template>
  <v-container
    fluid
    grid-list-xl
    pt-0
  >
    <v-layout wrap>
      <v-flex
        xs6
        sm6
        lg3>
        <material-stats-card
          v-model="summaryCaseName"
          class="v-offset-none"
          style="margin-bottom:0px !important;"
          color="purple"
          icon="mdi-folder-open"
          title="案名"/>
      </v-flex>
      <v-flex
        xs6
        sm6
        lg3>
        <material-stats-card
          v-model="summarySN"
          class="v-offset-none"
          style="margin-bottom:0px !important;"
          color="deep-purple"
          icon="mdi-alarm-light"
          title="SN"/>
      </v-flex>
      <v-flex
        xs6
        sm6
        lg3>
        <material-stats-card
          v-model="summaryDate"
          style="margin-bottom:0px !important;"
          color="indigo"
          icon="mdi-calendar"
          title="資料日期"/>
      </v-flex>
      <v-flex
        xs6
        sm6
        lg3>
        <material-stats-card
          v-model="summaryEMS"
          style="margin-bottom:0px !important;"
          color="light-blue"
          icon="mdi-factory"
          title="EMS"/>
      </v-flex>
      <v-flex
        xs12
        lg6>
        <material-stats-card
          :value="responseDataArea.barcode"
          color="grey"
          icon="mdi-barcode"
          title="測試料號條碼號"
          class="material-cursor"
          style="margin-bottom:0px !important;"
          @click="$router.push('/thermalModule/pl1/thermalModuleTestDataInfoSearch/'+responseDataArea.barcode)"/>
      </v-flex>
      <v-flex
        xs12
        lg6>
        <material-stats-card
          :value="responseDataArea.errorMessage"
          :title="'Status [ ' + responseDataArea.testResult + ' ]'"
          :color="responseDataArea.color"
          :class="responseDataArea.class + ' scale-' + errorMessageScale"
          :icon="responseDataArea.icon"
          class="title-color"
          style="margin-bottom:0px !important;"/>
      </v-flex>
      <v-flex
        v-for="(res, r) in responseDataStatistic"
        :key="'init' + r"
        class="v-flex__first"
        xs12
        lg12
      >
        <material-card
          class="v-flex v-c3-chart"
          color="green"
        >
          <div class="w-100 chart-btn c3-chart">
            <material-notification
              color="brown"
              class="mb-3 h4 font-weight-bold"
            >
              [{{ res.name }}_init]<span class="float-right">Init Type: {{ res.initType }}</span>
            </material-notification>
            <vue-c3 :handler="res.initResultList.handler"/>
          </div>
          <!-- <div>
            <material-notification
              :color="res.initResultList.testResult==='PASS' ? 'primary' : 'red'"
              class="mt-5 mb-4 h4 font-weight-bold"
              style="width:120px;margin-left: 0px;">
              <v-card-title
                primary-title
                class="mt-0 pt-0 pb-0 d-block text-center">
                <v-icon class="h1 mt-0">{{ res.initResultList.testResult==='PASS'?'mdi-check':'mdi-alert' }}</v-icon>
                <div class="headline m-auto">{{ res.initResultList.testResult }}</div>
              </v-card-title>
            </material-notification>
            <br>
            <material-notification
              :color="res.monitorResultList.testResult==='PASS' ? 'primary' : 'red'"
              class="mt-5 h4 font-weight-bold"
              style="width:120px;margin-left: 16px;margin-right: 0px;">
              <v-card-title
                primary-title
                class="mt-0 pt-0 pb-0 d-block text-center">
                <v-icon class="h1 mt-0">{{ res.monitorResultList.testResult==='PASS'?'mdi-check':'mdi-alert' }}</v-icon>
                <div class="headline m-auto">{{ res.monitorResultList.testResult }}</div>
              </v-card-title>
            </material-notification>
          </div> -->
          <div class="w-100 chart-btn c3-chart">
            <material-notification
              color="brown"
              class="mb-3 h4 font-weight-bold"
              style="width: auto;margin-left: auto;"
            >
              [{{ res.name }}_Monitor]
            </material-notification>
            <vue-c3 :handler="res.monitorResultList.handler"/>
          </div>
        </material-card>
      </v-flex>
      <v-flex
        class="v-flex__first"
        xs12
      >
        <material-card
          class="v-flex v-c3-chart"
          color="green"
        >
          <div class="w-100 chart-btn c3-chart">
            <material-notification
              color="brown"
              class="mb-3 h4 font-weight-bold"
            >
              [Thermal Monitor]
            </material-notification>
            <vue-c3 :handler="handler_line"/>
          </div>
        </material-card>
        <material-card>
          <v-data-table
            :headers="headers"
            :items="responseDataChartStatistic.thermalMonitorTableData"
            :pagination.sync="pagination"
            multi-sort
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="subheading font-weight-light text-general text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <td
                class="td-check"
                style="position: relative;">
                <v-checkbox
                  v-model="checkList"
                  :value="item.item"
                  class="mx-2"
                  style="display: table-caption;max-height: 20px;"
                  color="orange"
                  label=""/>
              </td>
              <td>{{ item.item }}</td>
              <td class="text-xs-right">{{ item.avg }}</td>
              <td class="text-xs-right">{{ item.max }}</td>
              <td class="text-xs-right">{{ item.min }}</td>
            </template>
          </v-data-table>
        </material-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="loadingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :top="true"
      :color="snackbarColor">
      {{ snackbarMsg }}
      <v-btn
        dark
        flat
        @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import Vue from 'vue'
import VueC3 from 'vue-c3'
import 'c3/c3.min.css'
import { mapState } from 'vuex'
import Loading from '@/assets/img/loading.gif'

export default {
  name: 'SingleDeviceCpuBurnInDataStatistic',
  components: {
    VueC3
  },
  data () {
    return {
      startdate: '2018-04-01',
      snackbar: false,
      snackbarColor: 'red',
      snackbarMsg: '',
      Loading,
      loadingView: false,
      summaryTtrsPk: '',
      summaryGroups: 0,
      summaryCaseName: '',
      summaryDate: '',
      summaryEMS: '',
      summarySN: '',
      errorMessageScale: '1',
      typeList: [],
      checkList: [],
      responseDataStatistic: [],
      responseDataArea: {
        barcode: '',
        errorMessage: '',
        testResult: '',
        color: 'light-green',
        class: 'green',
        icon: 'mdi-check'
      },
      responseDataChartStatistic: {
        thermalMonitorChartData: [],
        thermalMonitorTableData: []
      },
      pagination: {
        sortBy: 'TotalFailRate', // The field that you're sorting by
        descending: true
      },
      headers: [
        {
          sortable: false,
          text: '曲線開關',
          value: ''
        },
        {
          sortable: false,
          text: '項目',
          value: 'item'
        },
        {
          sortable: true,
          text: 'avg',
          value: 'avg',
          align: 'right'
        },
        {
          sortable: true,
          text: 'max',
          value: 'max',
          align: 'right'
        },
        {
          sortable: true,
          text: 'min',
          value: 'min',
          align: 'right'
        }
      ],
      handler_line: new Vue()
    }
  },
  computed: {
    ...mapState('app', ['theme', 'shrink']),
    // checkList () {
    //   this.hideList = this.typeList.filter(el => !~this.checkList.indexOf(el))
    //   this.handler_line3.$emit('init', this.options_line3)
    // },
    hideList () {
      return this.typeList.filter(el => !~this.checkList.indexOf(el))
      // this.checkList = this.typeList.filter(el => !~this.hideList.indexOf(el))
    },
    options_line () {
      return {
        data: {
          x: 'x',
          columns: this.responseDataChartStatistic.thermalMonitorChartData,
          // columns: [
          //   // ['x', '1-5', '6-10', '11-15', '16-20', '21-25', '26-30', '31-35', '36-40', '41-45', '46-50'],
          //   ['CPU temp', 62, 78, 73, 88, 82, 90, 83, 95, 88, 96],
          //   ['GPU temp', 60, 66, 72, 80, 80, 80, 80, 83, 85, 89],
          //   ['CPU power', 21, 15, 26, 18, 20, 13, 18, 25, 29, 21],
          //   ['GPU power', 105, 123, 96, 118, 92, 105, 112, 85, 107, 119]
          // ],
          // labels: {
          // format: function (v, id, i, j) { return v + '%' }
          // },
          hide: this.hideList,
          type: 'line'
          // axes: {
          //   'Duty': 'y',
          //   '12%': 'y',
          //   'RPM': 'y'
          // }
          // colors: {
          //   ModelName: '#999999'
          // },
          // onclick: (d) => { this.summaryList(d) }
          // onclick: (d, i) => { this.summaryList(d, i) } // {id: "data23", value: 120, ratio: 0.8, index: 1, name: "data23"}
          // color: function (color, d) {
          //   // d will be 'id' when called for legends
          //   return d.value >= 5.0 ? '#ff0000' : d.value >= 3.0 ? '#ff9800' : color
          // }
        },
        axis: {
          x: {
            tick: {
              rotate: -45,
              multiline: false,
              culling: {
                max: 10
              }
            },
            type: 'category', // this needed to load string x value
            label: {
              text: '時間',
              position: 'outer-center'
            }
          },
          y: {
            // tick: {
            //   format: function (value) { return value + '%' }
            // }
            label: {
              text: '溫度瓦數',
              position: 'outer-middle'
            }
          },
          y2: {
            // show: true
            // label: {
            //   text: '億',
            //   position: 'outer-middle'
            // }
          }
        },
        tooltip: {
          format: {
            title: (d, value) => {
              return `${this.responseDataChartStatistic.thermalMonitorChartData[0][d + 1]}`
              // return `第 ${d} 期`
            },
            value: (value, ratio, id) => {
              return `${value}`
              // return `${value} 億`
            }
          }
        },
        grid: {
          x: {
            show: true
          },
          y: {
            show: true
          }
        },
        legend: {
          position: 'bottom',
          padding: 16,
          item: {
            onclick: (d) => { this.changecheckList(d) }
          }
        },
        padding: {
          // top: 40,
          // right: 100,
          bottom: 30
          // left: 100,
        },
        size: {
          // width: 640,
          height: 500
        }
      }
    }
  },
  watch: {
    hideList () {
      this.handler_line.$emit('init', this.options_line)
    }
  },
  mounted () {
    this.$store.dispatch('getAuthorize', '/thermal/thermalCpuBurnIn/singleDeviceCpuBurnInDataStatistic')
      .then(response => {
        if (response.status === 'success' && response.data.accessible) {
          JSON.parse(localStorage.getItem('moduleDataInitDate')).forEach((n) => {
            if (n.name === 'thermal') {
              this.startdate = n.value
            }
          })
          const { modelname, date, sn, ems } = this.$route.query
          this.summaryCaseName = modelname || this.summaryCaseName
          this.summaryDate = date || this.summaryDate
          this.summarySN = sn || this.summarySN
          this.summaryEMS = ems || this.summaryEMS
          if (this.$route.params.ttrsPk) {
            this.summaryTtrsPk = Number(this.$route.params.ttrsPk)
          }
          if (this.$route.params.groups) {
            this.summaryGroups = Number(this.$route.params.groups)
          }
          this.beforeloadData()
          this.loadData()
        } else if (response.errCode === 'E10000002') {
          const routeData = this.$router.resolve({ path: '/noEnter/E10000002' })
          localStorage.setItem('errormsg', response.errMsg)
          window.location.assign(routeData.href)
        } else {
          const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
          window.location.assign(routeData.href)
        }
      })
      .catch(err => {
        console.log(err)
        const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
        window.location.assign(routeData.href)
      })
  },
  methods: {
    beforeloadData () {
      this.loadingView = true
      for (let fanTest = 0; fanTest < Number(this.summaryGroups); fanTest++) {
        this.responseDataStatistic[fanTest] = {
          initResultList: {},
          monitorResultList: {}
        }
        this.responseDataStatistic[fanTest].initResultList = {
          handler: new Vue(),
          RPM: ['RPM', 0],
          Duty: ['Duty', 0]
        }
        this.responseDataStatistic[fanTest].monitorResultList = {
          handler: new Vue(),
          RPM: ['RPM', 0],
          Duty: ['Duty', 0]
        }
      }
    },
    async loadData () {
      await this.doSubmit()
    },
    doSubmit () {
      this.loadingView = true
      this.$http.post('/api/thermal/singleDeviceCpuBurnInDataStatistic?ttrsPk=' + this.summaryTtrsPk).then(res => res.data)
        .then(res => {
          this.loadingView = false
          if (res.status === 'success') {
            this.responseDataArea.barcode = res.data.thermalModuleBarcode
            this.responseDataArea.errorMessage = res.data.errorMessage
            this.responseDataArea.testResult = res.data.testResult
            this.responseDataArea.color = res.data.testResult === 'PASS' ? 'light-green' : 'pink'
            this.responseDataArea.class = res.data.testResult === 'PASS' ? 'green' : 'red'
            this.responseDataArea.icon = res.data.testResult === 'PASS' ? 'mdi-check' : 'mdi-alert'
            this.errorMessageScale = Math.floor((25 / res.data.errorMessage.length) * 10)

            this.responseDataChartStatistic.thermalMonitorChartData.push(['x'].concat(res.data.thermalMonitorTestDataTimeList))
            res.data.thermalMonitorData.forEach((r) => {
              this.typeList.push(r.type)
              this.checkList.push(r.type)
              this.responseDataChartStatistic.thermalMonitorChartData.push([r.type].concat(r.testData))
              this.responseDataChartStatistic.thermalMonitorTableData.push({ item: r.type, avg: r.avg, max: r.max, min: r.min })
            })

            for (let fanTest = 0; fanTest < Number(this.summaryGroups); fanTest++) {
              this.responseDataStatistic[fanTest].name = res.data.fanTestData[fanTest].name
              this.responseDataStatistic[fanTest].type = res.data.fanTestData[fanTest].type
              this.responseDataStatistic[fanTest].initType = res.data.fanTestData[fanTest].initType
              this.responseDataStatistic[fanTest].initResultList.testResult = res.data.fanTestData[fanTest].initResult.testResult
              this.responseDataStatistic[fanTest].initResultList.handler.$emit('init', this.doChartData(['RPM'].concat(res.data.fanTestData[fanTest].initResult.rpm), ['Duty'].concat(res.data.fanTestData[fanTest].initResult.duty), Math.max(...res.data.fanTestData[fanTest].initResult.rpm), Math.max(...res.data.fanTestData[fanTest].initResult.duty)))
              this.responseDataStatistic[fanTest].monitorResultList.testResult = res.data.fanTestData[fanTest].monitorResult.testResult
              this.responseDataStatistic[fanTest].monitorResultList.handler.$emit('init', this.doChartData(['RPM'].concat(res.data.fanTestData[fanTest].monitorResult.rpm), ['Duty'].concat(res.data.fanTestData[fanTest].monitorResult.duty), Math.max(...res.data.fanTestData[fanTest].monitorResult.rpm), Math.max(...res.data.fanTestData[fanTest].monitorResult.duty)))
            }
            this.handler_line.$emit('init', this.options_line)
          }
        })
    },
    doChartData (RPM, Duty, maxRPM, naxDuty) {
      const RPMRange = 500
      const RPMMax = (Math.ceil(maxRPM / RPMRange) * RPMRange) + RPMRange
      const RPMMin = 0 // let RPMMin = Math.floor(Math.min(...RPM) / RPMRange) * RPMRange
      const RPMCount = ((RPMMax - RPMMin) / RPMRange) + 1

      const DutyRange = 20
      const DutyMax = (Math.ceil(naxDuty / DutyRange) * DutyRange) + DutyRange
      const DutyMin = 0 // let DutyMin = Math.floor(Math.min(...RPM) / DutyRange) * DutyRange
      const DutyCount = ((DutyMax - DutyMin) / DutyRange) + 1

      return {
        data: {
          columns: [
            RPM,
            Duty
          ],
          labels: {},
          type: 'line',
          axes: {
            RPM: 'y',
            Duty: 'y2'
          }
        },
        axis: {
          x: {
            // type: 'category' // this needed to load string x value
            label: {
              text: '次數',
              position: 'outer-center'
            }
          },
          y: {
            max: RPMMax,
            min: RPMMin,
            tick: {
              count: RPMCount
            },
            padding: {
              top: 0,
              bottom: 0
            },
            // tick: {
            //   format: function (value) { return value + '%' }
            // }
            label: {
              text: 'RPM',
              position: 'outer-middle'
            }
          },
          y2: {
            show: true,
            max: DutyMax,
            min: DutyMin,
            tick: {
              count: DutyCount
            },
            padding: {
              top: 0,
              bottom: 0
            },
            label: {
              text: 'Duty',
              position: 'outer-middle'
            }
          }
        },
        tooltip: {
          format: {
            title: (d, value) => {
              return `第 ${d} 次`
              // return `第 ${d} 期`
            },
            value: (value, ratio, id) => {
              return `${value}`
              // return `${value} 億`
            }
          }
        },
        grid: {
          x: {
            show: true
          },
          y: {
            show: true
          }
        },
        legend: {
          position: 'bottom',
          padding: 16
        },
        size: {
          // width: 640,
          height: 500
        },
        padding: {
          top: 10
        }
      }
    },
    changecheckList (type) {
      if (this.checkList.findIndex(function checkUnfold (t) { return t === type }) === -1) {
        this.checkList.push(type)
        // this.handler_line3.$emit('init', this.options_line3)
      } else {
        this.checkList.splice(this.checkList.findIndex(function checkUnfold (t) { return t === type }), 1)
      }
    }
  }
}
</script>
<style lang='css'>
.material-cursor>.v-card__text>.text-xs-right>h3{
  cursor: pointer !important;
  transform: scaleX(0.9);
  transform-origin: right;
}
.material-cursor>.v-card__text>.text-xs-right>h3:after{
  content: "\F349";
  display: inline-block;
  font: normal normal normal 24px/1 "Material Design Icons"!important;
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
