<template>
  <v-container
    cfr
    fluid
    grid-list-xl
    pt-0
  >
    <v-layout wrap>
      <v-flex
        xs12
        lg12
      >
        <material-card
          :class="{'card-shrink-active': shrink_1, 'card-shrink-115': shrink_1}"
          title="軟硬體不良率統計"
          class="card-mix card-shrink0 card-padding v-card__first"
          color="general"
        >
          <v-btn
            class="mix-btn mx-0 font-weight-light small"
            @click="shrink_1=!shrink_1"
          >進階搜尋<v-icon :class="{'card-shrink-active-icon' : shrink_1}">mdi-apple-keyboard-control</v-icon>
          </v-btn>
          <v-btn
            class="mix-btn mx-0 font-weight-light small"
            style="right: 9rem;"
            @click="modelYear=[];series=[];modelGroup=[];model=[];mucModule=[];problemMenu=[];statisticPeriodType='lifetime';excludeBestBuy=false;top=5;"
          >清除重置</v-btn>
          <v-form>
            <v-container
              py-0
              class="mw-100">
              <v-layout wrap>
                <v-flex
                  flex-small
                  xs12
                  lg2>
                  <v-autocomplete
                    v-model="modelYear"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="modelYearMenu"
                    label="開賣年度"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex
                  flex-small
                  xs12
                  lg2>
                  <v-autocomplete
                    v-model="series"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="seriesMenu"
                    label="Series"
                    class="v-text-field__slot list-select-field"
                    persistent-hint
                    outlined
                    multiple
                    multitude
                  />
                </v-flex>
                <v-flex
                  flex-small
                  xs12
                  lg2>
                  <v-autocomplete
                    v-model="modelGroup"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="modelGroupMenu"
                    label="modelGroup"
                    class="v-text-field__slot list-select-field"
                    persistent-hint
                    outlined
                    multiple
                    multitude
                  />
                </v-flex>
                <v-flex
                  flex-small
                  xs12
                  lg2>
                  <v-autocomplete
                    v-model="model"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="modelMenu"
                    label="Model"
                    class="v-text-field__slot list-select-field"
                    persistent-hint
                    outlined
                    multiple
                    multitude
                  />
                </v-flex>
                <v-flex
                  d-flex
                  align-items-center
                  xs12
                  lg4>
                  <p style="max-width: 150px;margin: 0!important;">時間類型</p>
                  <v-radio-group
                    v-model="statisticPeriodType"
                    class="m-0 py-0"
                    row>
                    <v-radio
                      label="Lifetime"
                      value="lifetime"/>
                    <v-radio
                      label="返修年月"
                      value="repairYearMonth"/>
                    <v-radio
                      label="返修週"
                      value="repairWeek"/>
                    <v-radio
                      label="製造年月(SN2)"
                      value="sn"/>
                  </v-radio-group>
                </v-flex>
                <v-flex
                  flex-small
                  align-items-center
                  xs12
                  lg10
                  style="justify-content: space-between;display: flex;margin-top: -25px;">
                  <v-autocomplete
                    v-model="mucModule"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="mucModuleMenu"
                    label="MucModule"
                    class="v-text-field__slot list-select-field"
                    persistent-hint
                    outlined
                    multiple
                    multitude
                  />
                  <v-btn
                    :disabled="modelGroup.length === 0"
                    class="mt-3 black text-light"
                    @click="mucModule=mucModuleMenu.map(e => e.value)">
                    全選
                  </v-btn>
                </v-flex>
                <v-flex
                  flex-small
                  xs12
                  lg2
                  text-xs-right
                  d-flex
                  align-items-center
                  style="margin-top: -15px;"
                >
                  <v-btn
                    v-if="shrink_1"
                    :disabled="modelYear==='' || modelYear===null || mucModule.length===0"
                    class="mx-0 mr-3 font-weight-light"
                    color="general"
                    @click="doSubmit()"
                  >
                    <v-icon class="mr-2">mdi-backup-restore</v-icon>統計
                  </v-btn>
                  <v-btn
                    v-if="shrink_1"
                    :disabled="modelYear==='' || modelYear===null || mucModule.length===0"
                    class="mx-0 font-weight-light"
                    color="indigo"
                    @click="doDownload()"
                  >
                    <v-icon class="mr-2">mdi-export</v-icon>匯出Raw Data
                  </v-btn>
                </v-flex>
                <v-flex
                  v-if="!shrink_1"
                  flex-small
                  border-primary
                  xs12
                  lg12
                  style="border-top: 2px dashed;"/>
                <v-flex
                  v-if="!shrink_1"
                  flex-small
                  xs12
                  lg8>
                  <v-autocomplete
                    v-model="problem"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="problemMenu"
                    label="Problem"
                    class="v-text-field__slot list-select-field"
                    persistent-hint
                    outlined
                    multiple
                  />
                </v-flex>
                <v-flex
                  v-if="!shrink_1"
                  lg4/>
                <v-flex
                  v-if="!shrink_1"
                  d-flex
                  align-items-center
                  xs12
                  lg3
                  style="margin-top: -15px;">
                  <p style="max-width: 150px; margin: 0px !important;">選擇資料項目</p>
                  <v-checkbox
                    v-model="excludeBestBuy"
                    :value="true"
                    class="m-0 py-0"
                    label="Exclude Best Buy"/>
                </v-flex>
                <v-flex
                  v-if="!shrink_1"
                  d-flex
                  align-items-center
                  text-xs-left
                  xs12
                  lg3
                  style="margin-top: -15px;">
                  <p style="max-width: 75px;margin: 0!important;">分列顯示前</p>
                  <v-text-field
                    v-model="top"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    type="number"
                    min="1"
                    max="10"
                    label=""/>
                  <p style="max-width: 50px;margin: 0!important;">項目</p>
                </v-flex>
                <v-flex
                  v-if="!shrink_1"
                  lg4/>
                <v-flex
                  v-if="!shrink_1"
                  flex-small
                  xs12
                  lg2
                  text-xs-right
                  d-flex
                  align-items-center
                  style="margin-top: -15px;"
                >
                  <v-btn
                    :disabled="modelYear==='' || modelYear===null || mucModule.length===0"
                    class="mx-0 mr-3 font-weight-light"
                    color="general"
                    @click="doSubmit()"
                  >
                    <v-icon class="mr-2">mdi-backup-restore</v-icon>統計
                  </v-btn>
                  <v-btn
                    :disabled="modelYear==='' || modelYear===null || mucModule.length===0"
                    class="mx-0 font-weight-light"
                    color="indigo"
                    @click="doDownload()"
                  >
                    <v-icon class="mr-2">mdi-export</v-icon>匯出Raw Data
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
      </v-flex>
      <v-flex
        v-if="submit"
        :style="systemTrigger?'z-index:99999 !important;':''"
        flex_width_auto
        selenium-grab
        xs12
        lg12>
        <material-card
          id="checkTable"
          :style="'max-width: calc(100vw - '+(Number(shrink)+32)+'px);'"
          title="不良率統計結果列表"
          color="deep-orange"
          class="card-mix d-table-t0 no-data-left freeze-title freeze-col"
          style="margin-top: 30px!important;">
          <span
            v-if="responseData.tableData.length === 0"
            style="width: 25vw;margin-bottom: -5px;margin-top: 10px;">查無資料</span>
          <table
            v-if="responseData.tableData.length > 0"
            class="statistic-table v-table freeze-1 freeze-2 freeze-3 freeze-4 freeze-unset-bg freeze-border-4">
            <thead>
              <tr>
                <th class="text-left table-width-150">MODULE</th>
                <th
                  class="text-left table-width-300"
                  style="left: 150px;">PROBLEM
                </th>
                <!-- <th
                  v-if="!(responseData.tableData.length===1&&responseData.tableData[0].module==='')"
                  :colspan="responseData.columnNameList.length">SN2</th> -->
                <th
                  class="text-left table-width-75"
                  style="left: 450px;">TTL</th>
                <th
                  class="text-left table-width-75"
                  style="position: relative;left: 525px;">CFR
                  <v-icon
                    v-if="othersColumns>=2"
                    :style="'position: absolute;right: -10px;top: -5px;cursor: pointer;transform: rotate('+(unfoldOthersCol?0:180)+'deg);'"
                    @click="unfoldOthersCol=!unfoldOthersCol">mdi-backburger</v-icon>
                </th>
                <th
                  v-if="!unfoldOthersCol && othersColumns>=2"
                  class="text-danger text-left table-width-75">Others</th>
                <th
                  v-for="(col,c) in responseData.columnNameList"
                  v-show="unfoldOthersCol || (c > othersColumns - 1)"
                  :key="'c'+c"
                  :class="{'text-danger':(c < othersColumns && othersColumns>=2)}"
                  class="text-left table-width-75">{{ col }}</th>
              </tr>
              <!-- <tr v-if="!(responseData.tableData.length===1&&responseData.tableData[0].module==='')">
                <th
                  v-for="(col,c) in responseData.columnNameList"
                  :key="'c'+c">{{ col }}</th>
              </tr> -->
            </thead>
            <tbody
              v-for="(problem,p) in responseData.tableData"
              :key="'p'+p">
              <tr
                v-for="(row,r) in problem.problemList"
                :key="'pr'+r"
                :class="{ 'problem-total':r===problem.problemList.length-1 }">
                <td
                  v-show="r===0"
                  :rowspan="problem.problemList.length"
                  :class="{'table-module-warning':(problem.moduleTarget!==null)&&(problem.problemList[problem.problemList.length-1].value[1]>problem.moduleTarget)}"
                  class="problem-module text-center table-width-150">
                  <v-icon v-if="(problem.moduleTarget!==null)&&(problem.problemList[problem.problemList.length-1].value[1]>problem.moduleTarget)">mdi-alert-decagram</v-icon>
                  <br v-if="(problem.moduleTarget!==null)&&(problem.problemList[problem.problemList.length-1].value[1]>problem.moduleTarget)">
                  {{ problem.module }}
                </td>
                <td
                  class="text-left table-width-300"
                  style="left: 150px;">{{ row.name }}</td>
                <td
                  :class="{'table-text-zero':row.value[0]===0}"
                  class="text-left table-width-75"
                  style="left: 450px;">
                  {{ row.value[0] }}
                </td>
                <td
                  :class="{'table-text-zero':row.value[1]===0}"
                  class="text-left table-width-75"
                  style="left: 525px;">
                  {{ (row.value[1]*100).toFixed(2)+'%' }}
                  <hr
                    v-if="r===problem.problemList.length-1&&problem.moduleTarget"
                    class="m-0 border-danger">
                  <span
                    v-if="r===problem.problemList.length-1&&problem.moduleTarget"
                    class="text-danger font-weight-bold">{{ (problem.moduleTarget*100).toFixed(2)+'%' }}</span>
                </td>
                <td
                  v-if="!unfoldOthersCol && othersColumns>=2"
                  :class="{'table-text-zero':col===0}"
                  class="text-left table-width-75">{{ doOthersSumValue(doExcludeSumValue(row.value)) }}</td>
                <td
                  v-for="(col,c) in doExcludeSumValue(row.value)"
                  v-show="unfoldOthersCol || (c > othersColumns - 1)"
                  :key="'prc'+c"
                  :class="{'table-text-zero':col===0}"
                  class="cursor text-left table-width-75"
                  @click="doDownload(responseData.columnNameList[c], (r>=problem.problemList.length-2)?'':row.name, (r===problem.problemList.length-2)?doExcludeProblemList(problem.problemList):[], problem.module)">
                  {{ (c>=row.value.length-2&&col!==null)?((col*100).toFixed(2)+'%'):col }}
                </td>
              </tr>
            </tbody>
            <!-- <tbody> -->
            <!-- <tr
              v-if="responseData.otherData"
              class="others">
              <td :colspan="(responseData.tableData.length===1&&responseData.tableData[0].module==='')?1:2">OTHERS</td>
              <td
                v-for="(col,c) in responseData.otherData"
                :key="'c'+c">{{ (c===responseData.otherData.length-1)?((col*100).toFixed(2)+'%'):col }}</td>
            </tr> -->
            <!-- <tr
              v-if="responseData.totalData"
              class="total">
              <td
                :colspan="(responseData.tableData.length===1&&responseData.tableData[0].module==='')?1:2"
                class="text-center">TTL</td>
              <td
                v-for="(col,c) in responseData.totalData"
                :key="'c'+c">{{ (c>=responseData.totalData.length-2&&col!==null)?((col*100).toFixed(2)+'%'):col }}</td>
            </tr> -->
            <!-- </tbody> -->
          </table>
        </material-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="processingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        PROCESSING 處理中
      </p>
    </v-dialog>
    <v-dialog
      v-model="loadingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :top="true"
      :color="snackbarColor">
      {{ snackbarMsg }}
      <v-btn
        dark
        flat
        @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import Loading from '@/assets/img/loading.gif'
export default {
  name: 'ProblemStatistic',
  data () {
    return {
      submit: false,
      snackbar: false,
      snackbarColor: 'red',
      snackbarMsg: '',
      systemTrigger: false,
      Loading,
      loadingView: false,
      processingView: false,
      loadingOfSeries: false,
      loadingOfModelGroup: false,
      loadingOfModel: false,
      shrink_1: true,
      unfoldOthersCol: false,
      modelYear: '',
      modelYearMenu: [],
      modelMenu: [],
      model: [],
      modelGroup: [],
      modelGroupMenu: [],
      series: [],
      seriesMenu: [],
      mucModule: [],
      mucModuleMenu: [],
      problem: [],
      problemMenu: [],
      statisticPeriodType: 'lifetime',
      excludeBestBuy: false,
      top: 5,
      defaultBestColumns: 13,
      othersColumns: 0,
      responseData: {
        columnNameList: [],
        tableData: [
          {
            module: '',
            problemList: []
          }
        ],
        // otherData: null,
        totalData: null
      }
    }
  },
  computed: {
    ...mapState('app', ['theme', 'shrink'])
  },
  watch: {
    modelYear (val) {
      this.series = []
      this.getSeries(val)
      this.getModelGroup(val, this.series)
      this.getModel(val, this.series, this.modelGroup)
    },
    series (val) {
      this.modelGroup = []
      this.getModelGroup(this.modelYear, val)
      this.getModel(this.modelYear, val, this.modelGroup)
    },
    modelGroup (val) {
      this.model = []
      this.getModel(this.modelYear, this.series, val)
    },
    // modelYear (val) {
    //   this.getYearlyCfrAnalysisViewSelectOptionData('model', val)
    // },
    top (val) {
      if (val < 1) {
        this.top = 1
      }
      if (val > 10) {
        this.top = 10
      }
    }
  },
  mounted () {
    this.$store.dispatch('getAuthorize', '/pqm/cfrAnalysisMgmt/problemStatisticView')
      .then(response => {
        if (response.status === 'success' && response.data.accessible) {
          const { year, seriesList, moduleList, systemTrigger, statisticPeriodType } = this.$route.query
          // if (year || moduleList) {
          //   this.modelYear = year || this.modelYear
          //   this.series = seriesList || this.series
          //   this.modelGroup = modelGroupList || this.modelGroup
          //   this.mucModule = moduleList || this.mucModule
          //   this.doSubmit()
          // }
          if (systemTrigger) {
            this.systemTrigger = systemTrigger
            this.statisticPeriodType = statisticPeriodType
          }
          this.$http.get('/api/pqm/cfrAnalysisMgmt/modelYear').then(res => res.data)
            .then(res => {
              if (res.status === 'success') {
                this.modelYearMenu = res.data
                this.modelYear = ''
                if (year) {
                  this.modelYear = year || this.modelYear
                  if (seriesList === null && moduleList === null) {
                    this.doSubmit()
                  }
                }
              }
            })
          this.$http.get('/api/pqm/getSelectOptionData/mucModule').then(res => res.data)
            .then(res => {
              if (res.status === 'success') {
                this.mucModuleMenu = res.data
                this.mucModule = []
                if (moduleList) {
                  this.mucModule = (typeof moduleList === 'string' ? [moduleList] : moduleList) || this.mucModule
                  if (year === null) {
                    this.doSubmit()
                  }
                }
              }
            })
          this.$http.get('/api/pqm/cfr/getSelectOptionData/problem/all').then(res => res.data)
            .then(res => {
              if (res.status === 'success') {
                this.problemMenu = res.data
                this.problem = []
              }
            })
          // this.doSubmit()
        } else if (response.errCode === 'E10000002') {
          const routeData = this.$router.resolve({ path: '/noEnter/E10000002' })
          localStorage.setItem('errormsg', response.errMsg)
          window.location.assign(routeData.href)
        } else {
          const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
          window.location.assign(routeData.href)
        }
      })
      .catch(err => {
        console.log(err)
        const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
        window.location.assign(routeData.href)
      })
  },
  methods: {
    getSeries (year) {
      if (year !== '' && !this.loadingOfSeries) {
        this.loadingOfSeries = true
        this.$http.get(`/api/pqm/cfr/series?year=${year}`).then(res => res.data)
          .then(res => {
            this.loadingOfSeries = false
            if (res.status === 'success') {
              this.seriesMenu = res.data
              this.series = []
              const { seriesList, modelGroupList } = this.$route.query
              if (seriesList) {
                this.series = [seriesList] || this.series
                if (modelGroupList === null) {
                  this.doSubmit()
                }
              }
            }
          })
      }
    },
    getModelGroup (year, series) {
      if (year !== '' && !this.loadingOfModelGroup) {
        this.loadingOfModelGroup = true
        this.$http.get(`/api/pqm/cfr/modelGroup?year=${year}&seriesList=${series}`).then(res => res.data)
          .then(res => {
            this.loadingOfModelGroup = false
            if (res.status === 'success') {
              this.modelGroupMenu = res.data
              this.modelGroup = []
              const { modelGroupList } = this.$route.query
              if (modelGroupList) {
                this.modelGroup = [modelGroupList] || this.modelGroup
                this.doSubmit()
              }
            }
          })
      }
    },
    getModel (year, series, modelGroup) {
      if (year !== '' && !this.loadingOfModel) {
        this.loadingOfModel = true
        this.$http.get(`/api/pqm/cfr/model?year=${year}&seriesList=${series}&modelGroupList=${modelGroup}`).then(res => res.data)
          .then(res => {
            this.loadingOfModel = false
            if (res.status === 'success') {
              this.modelMenu = res.data
              this.model = []
            }
          })
      }
    },
    // getYearlyCfrAnalysisViewSelectOptionData (fieldName, year) {
    //   this.$http.get(`/api/pqm/cfrAnalysisMgmt/getYearlyCfrAnalysisViewSelectOptionData/${fieldName}/${year}`).then(res => res.data)
    //     .then(res => {
    //       this.modelMenu = res.data
    //       this.cfrModel = ''
    //     })
    // },
    doOthersSumValue (value) {
      let val = 0
      for (let i = 0; i < this.othersColumns; i++) {
        val += value[i] || 0
      }
      return val
    },
    doExcludeSumValue (value) {
      const val = value.slice(2)
      return val
    },
    doExcludeProblemList (value) {
      const val = value.slice(0, -2).map((e) => { return e.name })
      return val
    },
    doSubmit () {
      this.loadingView = true
      const body = {
        statisticPeriodType: this.statisticPeriodType,
        excludeBestBuy: this.excludeBestBuy || false,
        year: this.modelYear,
        top: this.top,
        seriesList: this.series,
        modelGroupList: this.modelGroup,
        modelList: this.model,
        problemList: this.problem,
        moduleList: this.mucModule,
        systemTrigger: this.systemTrigger
      }
      // this.$http.post('/api/pqm/cfr/problemStatisticData', body).then(res => res.data)
      this.$http.post('/api/pqm/cfr/problemStatisticData', body).then(res => res.data)
        .then(res => {
          this.loadingView = false
          if (res.status === 'success') {
            this.submit = true
            this.responseData = res.data
            this.unfoldOthersCol = (res.data.columnNameList.length <= this.defaultBestColumns)
            this.othersColumns = (res.data.columnNameList.length - this.defaultBestColumns + 1)
          } else {
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
          }
        })
    },
    doDownload (periodColumn, problem, excludeProblemList, module) {
      this.processingView = true
      let body = {}
      if (periodColumn) {
        body = {
          statisticPeriodType: this.statisticPeriodType,
          excludeBestBuy: this.excludeBestBuy || false,
          excludeProblemList: excludeProblemList,
          periodColumn: periodColumn,
          problem: problem,
          module: module,
          year: this.modelYear,
          seriesList: this.series,
          modelGroupList: this.modelGroup,
          modelList: this.model
        }
      } else {
        body = {
          statisticPeriodType: this.statisticPeriodType,
          excludeBestBuy: this.excludeBestBuy || false,
          year: this.modelYear,
          top: this.top,
          seriesList: this.series,
          modelGroupList: this.modelGroup,
          modelList: this.model,
          problemList: this.problem,
          moduleList: this.mucModule
        }
      }
      // this.$http.post('/api/pqm/cfr/downloadProblemStatisticRawDataFile', body, { responseType: 'blob' })
      this.$http.post('/api/pqm/cfr/downloadProblemStatisticRawDataFile', body, { responseType: 'blob' })
        .then(res => {
          this.processingView = false
          if (res.data.type === 'application/json') {
            return new Promise((resolve, reject) => {
              const reader = new FileReader()
              reader.onload = () => {
                res.data = JSON.parse(reader.result)
                if (res.data.status === 'success') {
                  alert(res.data.data)
                  // resolve(Promise.reject(res.data.data))
                } else {
                  this.snackbar = true
                  this.snackbarColor = 'red'
                  this.snackbarMsg = res.data.errMsg + ' (' + res.data.errCode + ')'
                }
              }
              reader.onerror = () => {
                reject(res.data.data)
              }
              reader.readAsText(res.data)
            })
          } else if (res.data.status !== 'failure' && res.headers['content-disposition']) {
            const blob = new Blob([res.data], { type: 'application/octet-stream' })
            const url = window.URL.createObjectURL(blob)
            const fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]
            const downloadLink = document.createElement('a')
            downloadLink.href = url
            downloadLink.download = fileName

            document.body.appendChild(downloadLink)
            downloadLink.click()
            document.body.removeChild(downloadLink)
          } else {
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = '下載失敗，請通知系統管理員'
          }
        })
        .catch(err => {
          this.processingView = false
          this.snackbar = true
          this.snackbarColor = 'orange'
          this.snackbarMsg = err
        })
    }
  }
}
</script>
<style>
</style>
