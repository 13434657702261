<template>
  <v-container
    csc
    fluid
    grid-list-xl
    pt-0
  >
    <v-layout
      wrap
      my-auto>
      <v-flex
        flex_table_width_auto
        xs12
        lg4>
        <material-card
          id="checkTable"
          :style="'max-width: calc(100vw - '+(Number(shrink)+32)+'px);'"
          title="維修項目等級主歸類規則列表"
          color="deep-orange"
          class="card-mix d-table-t0 no-data-left"
          style="margin-top: 30px!important;">
          <!-- <v-select
            v-model="perPageRows"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="rowsAmount"
            class="mix-list v-text-field__slot d-inline-block float-right ml-5"
            style="width: 90px;"
            persistent-hint
            outlined
          /> -->
          <v-btn
            class="mix-btn black text-light"
            style="right: 5.5rem;"
            @click="doEdiiItemMainClassifyRuleMaintainList()">
            <v-icon class="float-left">mdi-content-save</v-icon>儲存
          </v-btn>
          <v-btn
            class="mix-btn black text-light"
            style="right: 1rem;"
            @click="getItemMainClassifyRuleMaintainList()">
            <v-icon class="float-left">mdi-replay</v-icon>重置
          </v-btn>
          <v-data-table
            :headers="responseHeadersMain"
            :items="responseTableDataMain"
            class="height-row-3 table-align-left freeze-1 freeze-2"
            multi-sort
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="subheading font-weight-light text-general text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <tr>
                <td class="freeze-width-75">{{ item.priority }}</td>
                <td
                  :class="{ 'text-warning': (item.repairLevelPqaNewTemp !== item.repairLevelPqaNew) }"
                  class="freeze-width-150">{{ item.repairLevelPqaNewTemp }}</td>
                <td class="freeze-width-150">
                  <v-text-field
                    v-model="responseDataMain[(item.priority-1)].repairLevelPqaNew"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    class="small"
                    label=""/>
                </td>
                <td class="freeze-width-90">
                  <div>
                    <div style="display: inline-flex;justify-content: center;">
                      <v-btn
                        :disabled="item.priority===1"
                        class="mx-0 mr-1 font-weight-bold small"
                        style="width: 30px;"
                        color="green"
                        @click="doUpMain(item.priority)">
                        <v-icon>mdi-arrow-up-thick</v-icon>
                      </v-btn>
                    </div>
                    <div style="display: inline-flex;justify-content: center;">
                      <v-btn
                        :disabled="responseTableDataMain.length===1"
                        class="mx-0 font-weight-bold small text-warning"
                        style="width: 30px;"
                        color="black"
                        @click="doRemoveMain(item.priority)">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </div>
                    <div style="display: inline-flex;justify-content: center;">
                      <v-btn
                        :disabled="item.priority===responseTableDataMain.length"
                        class="mx-0 mr-1 font-weight-bold small"
                        style="width: 30px;"
                        color="teal"
                        @click="doDownMain(item.priority)">
                        <v-icon>mdi-arrow-down-thick</v-icon>
                      </v-btn>
                    </div>
                    <div style="display: inline-flex;justify-content: center;">
                      <v-btn
                        class="mx-0 font-weight-bold small"
                        style="width: 30px;"
                        color="pink"
                        @click="doInsertMain(item.priority)">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
          <!-- <div
            v-if="pagination.rowsPerPage != -1"
            class="text-xs-center pt-2 d-inline-block"
            style="margin-left: 0px;width: calc(100% - 140px);">
            <v-pagination
              v-model="pagination.page"
              :length="pages"/>
          </div>
          <v-autocomplete
            v-if="pagination.rowsPerPage != -1"
            v-model="pagination.page"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="pageslist"
            class="v-text-field__slot d-inline-block float-right mr-0"
            style="width: 90px;"
            persistent-hint
            outlined
          /> -->
        </material-card>
      </v-flex>
      <v-flex
        flex_table_width_auto
        xs12
        lg4>
        <material-card
          id="checkTable"
          :style="'max-width: calc(100vw - '+(Number(shrink)+32)+'px);'"
          title="🖥 DT-維修項目等級次歸類規則列表"
          color="deep-orange"
          class="card-mix d-table-t0 no-data-left emoji freeze-1 freeze-2"
          style="margin-top: 30px!important;">
          <!-- <v-select
            v-model="perPageRows"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="rowsAmount"
            class="mix-list v-text-field__slot d-inline-block float-right ml-5"
            style="width: 90px;"
            persistent-hint
            outlined
          /> -->
          <v-btn
            class="mix-btn black text-light"
            style="right: 5.5rem;"
            @click="doEditItemSubClassifyDTRuleMaintainList()">
            <v-icon class="float-left">mdi-content-save</v-icon>儲存
          </v-btn>
          <v-btn
            class="mix-btn black text-light"
            style="right: 1rem;"
            @click="getItemSubClassifyDTRuleMaintainList()">
            <v-icon class="float-left">mdi-replay</v-icon>重置
          </v-btn>
          <v-data-table
            :headers="responseHeadersSubDT"
            :items="responseTableDataSubDT"
            class="height-row-3 table-align-left"
            multi-sort
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="subheading font-weight-light text-general text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <tr>
                <td class="freeze-width-75">{{ item.priority }}</td>
                <td
                  :class="{ 'text-warning': (item.repairLevelPqaNewTemp !== item.repairLevelPqaNew) }"
                  class="freeze-width-150">{{ item.repairLevelPqaNewTemp }}</td>
                <td class="freeze-width-150">
                  <v-text-field
                    v-model="responseDataSubDT[(item.priority-1)].repairLevelPqaNew"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    class="small"
                    label=""/>
                </td>
                <td class="freeze-width-90">
                  <div>
                    <div style="display: inline-flex;justify-content: center;">
                      <v-btn
                        :disabled="item.priority===1"
                        class="mx-0 mr-1 font-weight-bold small"
                        style="width: 30px;"
                        color="green"
                        @click="doUpSubDT(item.priority)">
                        <v-icon>mdi-arrow-up-thick</v-icon>
                      </v-btn>
                    </div>
                    <div style="display: inline-flex;justify-content: center;">
                      <v-btn
                        :disabled="responseTableDataSubDT.length===1"
                        class="mx-0 font-weight-bold small text-warning"
                        style="width: 30px;"
                        color="black"
                        @click="doRemoveSubDT(item.priority)">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </div>
                    <div style="display: inline-flex;justify-content: center;">
                      <v-btn
                        :disabled="item.priority===responseTableDataSubDT.length"
                        class="mx-0 mr-1 font-weight-bold small"
                        style="width: 30px;"
                        color="teal"
                        @click="doDownSubDT(item.priority)">
                        <v-icon>mdi-arrow-down-thick</v-icon>
                      </v-btn>
                    </div>
                    <div style="display: inline-flex;justify-content: center;">
                      <v-btn
                        class="mx-0 font-weight-bold small"
                        style="width: 30px;"
                        color="pink"
                        @click="doInsertSubDT(item.priority)">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
          <!-- <div
            v-if="pagination.rowsPerPage != -1"
            class="text-xs-center pt-2 d-inline-block"
            style="margin-left: 0px;width: calc(100% - 140px);">
            <v-pagination
              v-model="pagination.page"
              :length="pages"/>
          </div>
          <v-autocomplete
            v-if="pagination.rowsPerPage != -1"
            v-model="pagination.page"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="pageslist"
            class="v-text-field__slot d-inline-block float-right mr-0"
            style="width: 90px;"
            persistent-hint
            outlined
          /> -->
        </material-card>
      </v-flex>
      <v-flex
        flex_table_width_auto
        xs12
        lg4>
        <material-card
          id="checkTable"
          :style="'max-width: calc(100vw - '+(Number(shrink)+32)+'px);'"
          title="💻 NR-維修項目等級次歸類規則列表"
          color="deep-orange"
          class="card-mix d-table-t0 no-data-left emoji freeze-1 freeze-2"
          style="margin-top: 30px!important;">
          <!-- <v-select
            v-model="perPageRows"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="rowsAmount"
            class="mix-list v-text-field__slot d-inline-block float-right ml-5"
            style="width: 90px;"
            persistent-hint
            outlined
          /> -->
          <v-btn
            class="mix-btn black text-light"
            style="right: 5.5rem;"
            @click="doEditItemSubClassifyNRRuleMaintainList()">
            <v-icon class="float-left">mdi-content-save</v-icon>儲存
          </v-btn>
          <v-btn
            class="mix-btn black text-light"
            style="right: 1rem;"
            @click="getItemSubClassifyNRRuleMaintainList()">
            <v-icon class="float-left">mdi-replay</v-icon>重置
          </v-btn>
          <v-data-table
            :headers="responseHeadersSubNR"
            :items="responseTableDataSubNR"
            class="height-row-3 table-align-left"
            multi-sort
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="subheading font-weight-light text-general text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <tr>
                <td class="freeze-width-75">{{ item.priority }}</td>
                <td
                  :class="{ 'text-warning': (item.repairLevelPqaNewTemp !== item.repairLevelPqaNew) }"
                  class="freeze-width-150">{{ item.repairLevelPqaNewTemp }}</td>
                <td class="freeze-width-150">
                  <v-text-field
                    v-model="responseDataSubNR[(item.priority-1)].repairLevelPqaNew"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    class="small"
                    label=""/>
                </td>
                <td class="freeze-width-90">
                  <div>
                    <div style="display: inline-flex;justify-content: center;">
                      <v-btn
                        :disabled="item.priority===1"
                        class="mx-0 mr-1 font-weight-bold small"
                        style="width: 30px;"
                        color="green"
                        @click="doUpSubNR(item.priority)">
                        <v-icon>mdi-arrow-up-thick</v-icon>
                      </v-btn>
                    </div>
                    <div style="display: inline-flex;justify-content: center;">
                      <v-btn
                        :disabled="responseTableDataSubNR.length===1"
                        class="mx-0 font-weight-bold small text-warning"
                        style="width: 30px;"
                        color="black"
                        @click="doRemoveSubNR(item.priority)">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </div>
                    <div style="display: inline-flex;justify-content: center;">
                      <v-btn
                        :disabled="item.priority===responseTableDataSubNR.length"
                        class="mx-0 mr-1 font-weight-bold small"
                        style="width: 30px;"
                        color="teal"
                        @click="doDownSubNR(item.priority)">
                        <v-icon>mdi-arrow-down-thick</v-icon>
                      </v-btn>
                    </div>
                    <div style="display: inline-flex;justify-content: center;">
                      <v-btn
                        class="mx-0 font-weight-bold small"
                        style="width: 30px;"
                        color="pink"
                        @click="doInsertSubNR(item.priority)">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
          <!-- <div
            v-if="pagination.rowsPerPage != -1"
            class="text-xs-center pt-2 d-inline-block"
            style="margin-left: 0px;width: calc(100% - 140px);">
            <v-pagination
              v-model="pagination.page"
              :length="pages"/>
          </div>
          <v-autocomplete
            v-if="pagination.rowsPerPage != -1"
            v-model="pagination.page"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="pageslist"
            class="v-text-field__slot d-inline-block float-right mr-0"
            style="width: 90px;"
            persistent-hint
            outlined
          /> -->
        </material-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="processingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        PROCESSING 處理中
      </p>
    </v-dialog>
    <v-dialog
      v-model="loadingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :top="true"
      :color="snackbarColor">
      {{ snackbarMsg }}
      <v-btn
        dark
        flat
        @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import Loading from '@/assets/img/loading.gif'

export default {
  name: 'RepairItemClassifyRuleMaintain',
  data () {
    return {
      snackbar: false,
      snackbarColor: 'red',
      snackbarMsg: '',
      Loading,
      processingView: false,
      mainClassifyLoading: false,
      subClassifyDTLoading: false,
      subClassifyNRLoading: false,
      reasonCode: '',
      // submit: false,
      responseDataMain: [],
      responseDataMainTemp: [],
      responseHeadersMain: [
        {
          align: '',
          class: 'freeze-width-75',
          sortable: false,
          text: '優先權',
          value: 'priority'
        }, {
          align: '',
          class: 'freeze-width-150',
          sortable: false,
          text: 'Repair_level_PQA_new(O)',
          value: 'repairLevelPqaNewTemp'
        }, {
          align: '',
          class: 'freeze-width-150',
          sortable: false,
          text: 'Repair_level_PQA_new',
          value: 'repairLevelPqaNew'
        }, {
          align: '',
          class: 'freeze-width-90',
          sortable: false,
          text: '操作',
          value: 'operation'
        }
      ],
      responseDataSubDT: [],
      responseDataSubDTtemp: [],
      responseHeadersSubDT: [
        {
          align: '',
          class: 'freeze-width-75',
          sortable: false,
          text: '優先權',
          value: 'priority'
        }, {
          align: '',
          class: 'freeze-width-150',
          sortable: false,
          text: 'MUC_MODULE (O)',
          value: 'repairLevelPqaNewTemp'
        }, {
          align: '',
          class: 'freeze-width-150',
          sortable: false,
          text: 'MUC_MODULE',
          value: 'repairLevelPqaNew'
        }, {
          align: '',
          class: 'freeze-width-90',
          sortable: false,
          text: '操作',
          value: 'operation'
        }
      ],
      responseDataSubNR: [],
      responseDataSubNRtemp: [],
      responseHeadersSubNR: [
        {
          align: '',
          class: 'freeze-width-75',
          sortable: false,
          text: '優先權',
          value: 'priority'
        }, {
          align: '',
          class: 'freeze-width-150',
          sortable: false,
          text: 'MUC_MODULE (O)',
          value: 'repairLevelPqaNewTemp'
        }, {
          align: '',
          class: 'freeze-width-150',
          sortable: false,
          text: 'MUC_MODULE',
          value: 'repairLevelPqaNew'
        }, {
          align: '',
          class: 'freeze-width-90',
          sortable: false,
          text: '操作',
          value: 'operation'
        }
      ]
      // rowsAmount: [25, 50, 100, { text: 'All', value: -1 }],
      // rowsAmount: [1, 25, 50, 100],
      // perPageRows: 25,
      // pagination: {
      //   page: 1,
      //   rowsPerPage: 25,
      //   sortBy: '',
      //   descending: null
      // },
      // pages: 1
    }
  },
  computed: {
    ...mapState('app', ['theme', 'shrink']),
    loadingView () {
      if (!this.mainClassifyLoading && !this.subClassifyDTLoading && !this.subClassifyNRLoading) {
        return false
      } else {
        return true
      }
    },
    // pages () {
    //   if(this.responseData.length > 0) {
    //     return this.pagination.rowsPerPage ? Math.ceil(this.responseData.length / this.pagination.rowsPerPage) : 10
    //   }
    // },
    // pageslist () {
    //   let i = 0
    //   let list = []
    //   if (this.pagination.rowsPerPage) {
    //     for (i = 0; i < Math.ceil(this.pages / this.pagination.rowsPerPage); i++) {
    //       list.push(i + 1)
    //     }
    //   }
    //   return list
    // }
    responseTableDataMain () {
      const array = []
      // for (let n = 0; n < Math.max(this.responseDataMain.length, this.responseDataMainTemp.length); n++) {
      for (let n = 0; n < this.responseDataMain.length; n++) {
        array.push({ priority: n + 1, repairLevelPqaNewTemp: (this.responseDataMainTemp[n] ? this.responseDataMainTemp[n].repairLevelPqaNew : ''), repairLevelPqaNew: (this.responseDataMain[n] ? this.responseDataMain[n].repairLevelPqaNew : '') })
      }
      return array
    },
    responseTableDataSubDT () {
      const array = []
      // for (let n = 0; n < Math.max(this.responseDataSubDT.length, this.responseDataSubDTtemp.length); n++) {
      for (let n = 0; n < this.responseDataSubDT.length; n++) {
        array.push({ priority: n + 1, repairLevelPqaNewTemp: (this.responseDataSubDTtemp[n] ? this.responseDataSubDTtemp[n].repairLevelPqaNew : ''), repairLevelPqaNew: (this.responseDataSubDT[n] ? this.responseDataSubDT[n].repairLevelPqaNew : '') })
      }
      return array
    },
    responseTableDataSubNR () {
      const array = []
      // for (let n = 0; n < Math.max(this.responseDataSubNR.length, this.responseDataSubNRtemp.length); n++) {
      for (let n = 0; n < this.responseDataSubNR.length; n++) {
        array.push({ priority: n + 1, repairLevelPqaNewTemp: (this.responseDataSubNRtemp[n] ? this.responseDataSubNRtemp[n].repairLevelPqaNew : ''), repairLevelPqaNew: (this.responseDataSubNR[n] ? this.responseDataSubNR[n].repairLevelPqaNew : '') })
      }
      return array
    }
  },
  watch: {
    // perPageRows (val) {
    //   if (val === -1) {
    //     if (confirm('完整顯示將可能導致效能降低，是否仍完整顯示?')) {
    //       this.pagination.rowsPerPage = val
    //       this.pages = Math.ceil(this.pages / this.pagination.rowsPerPage)
    //       this.pagination.page = 1
    //     } else {
    //       setTimeout(() => {
    //         this.perPageRows = this.pagination.rowsPerPage
    //       }, 100)
    //     }
    //   } else {
    //     this.pagination.rowsPerPage = val
    //     this.pages = Math.ceil(this.pages / this.pagination.rowsPerPage)
    //     this.pagination.page = 1
    //   }
    // },
    // pagination: {
    //   handler (newName, oldName) {
    //     if (this.submit) {
    //       this.doSubmit()
    //     }
    //   },
    //   immediate: true,
    //   deep: true
    // }
  },
  mounted () {
    this.$store.dispatch('getAuthorize', '/pqm/cscDataParsingMgmt/repairItemClassifyRuleMaintainView')
      .then(response => {
        if (response.status === 'success' && response.data.accessible) {
          this.getItemMainClassifyRuleMaintainList()
          this.getItemSubClassifyDTRuleMaintainList()
          this.getItemSubClassifyNRRuleMaintainList()
        } else if (response.errCode === 'E10000002') {
          const routeData = this.$router.resolve({ path: '/noEnter/E10000002' })
          localStorage.setItem('errormsg', response.errMsg)
          window.location.assign(routeData.href)
        } else {
          const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
          window.location.assign(routeData.href)
        }
      })
      .catch(err => {
        console.log(err)
        const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
        window.location.assign(routeData.href)
      })
  },
  methods: {
    getItemMainClassifyRuleMaintainList () {
      this.mainClassifyLoading = true
      this.$http.get('/api/pqm/cscDataParsingMgmt/repairItemMainClassifyRuleMaintainList').then(res => res.data)
        .then(res => {
          this.mainClassifyLoading = false
          if (res.status === 'success') {
            this.responseDataMain = res.data
            this.responseDataMainTemp = JSON.parse(JSON.stringify(res.data))
          }
        })
    },
    getItemSubClassifyDTRuleMaintainList () {
      this.subClassifyDTLoading = true
      this.$http.get('/api/pqm/cscDataParsingMgmt/repairItemSubClassifyDTRuleMaintainList').then(res => res.data)
        .then(res => {
          this.subClassifyDTLoading = false
          if (res.status === 'success') {
            this.responseDataSubDT = res.data
            this.responseDataSubDTtemp = JSON.parse(JSON.stringify(res.data))
          }
        })
    },
    getItemSubClassifyNRRuleMaintainList () {
      this.subClassifyNRLoading = true
      this.$http.get('/api/pqm/cscDataParsingMgmt/repairItemSubClassifyNRRuleMaintainList').then(res => res.data)
        .then(res => {
          this.subClassifyNRLoading = false
          if (res.status === 'success') {
            this.responseDataSubNR = res.data
            this.responseDataSubNRtemp = JSON.parse(JSON.stringify(res.data))
          }
        })
    },
    doUpMain (priority) {
      const a = this.responseDataMain[(priority - 1)].repairLevelPqaNew
      const b = this.responseDataMain[(priority - 1 - 1)].repairLevelPqaNew
      this.responseDataMain[(priority - 1)].repairLevelPqaNew = b
      this.responseDataMain[(priority - 1 - 1)].repairLevelPqaNew = a
    },
    doDownMain (priority) {
      const a = this.responseDataMain[(priority - 1)].repairLevelPqaNew
      const b = this.responseDataMain[(priority - 1 + 1)].repairLevelPqaNew
      this.responseDataMain[(priority - 1)].repairLevelPqaNew = b
      this.responseDataMain[(priority - 1 + 1)].repairLevelPqaNew = a
    },
    doInsertMain (priority) {
      const array = {
        priority: (priority + 1),
        repairLevelPqaNew: ''
      }
      this.responseDataMain.splice((priority - 1 + 1), 0, array)
      this.doResetPriorityMain()
    },
    doRemoveMain (priority) {
      this.responseDataMain.splice((priority - 1), 1)
      this.doResetPriorityMain()
      if (this.responseDataMain.length === 1) {
        alert('需至少保留一筆主歸類規則')
      }
    },
    doResetPriorityMain () {
      this.responseDataMain.forEach((r, index) => {
        r.priority = (index + 1)
      })
    },
    doUpSubDT (priority) {
      const a = this.responseDataSubDT[(priority - 1)].repairLevelPqaNew
      const b = this.responseDataSubDT[(priority - 1 - 1)].repairLevelPqaNew
      this.responseDataSubDT[(priority - 1)].repairLevelPqaNew = b
      this.responseDataSubDT[(priority - 1 - 1)].repairLevelPqaNew = a
    },
    doDownSubDT (priority) {
      const a = this.responseDataSubDT[(priority - 1)].repairLevelPqaNew
      const b = this.responseDataSubDT[(priority - 1 + 1)].repairLevelPqaNew
      this.responseDataSubDT[(priority - 1)].repairLevelPqaNew = b
      this.responseDataSubDT[(priority - 1 + 1)].repairLevelPqaNew = a
    },
    doInsertSubDT (priority) {
      const array = {
        priority: (priority + 1),
        repairLevelPqaNew: ''
      }
      this.responseDataSubDT.splice((priority - 1 + 1), 0, array)
      this.doResetPrioritySubDT()
    },
    doRemoveSubDT (priority) {
      this.responseDataSubDT.splice((priority - 1), 1)
      this.doResetPrioritySubDT()
      if (this.responseDataSubDT.length === 1) {
        alert('需至少保留一筆DT次歸類規則')
      }
    },
    doResetPrioritySubDT () {
      this.responseDataSubDT.forEach((r, index) => {
        r.priority = (index + 1)
      })
    },
    doUpSubNR (priority) {
      const a = this.responseDataSubNR[(priority - 1)].repairLevelPqaNew
      const b = this.responseDataSubNR[(priority - 1 - 1)].repairLevelPqaNew
      this.responseDataSubNR[(priority - 1)].repairLevelPqaNew = b
      this.responseDataSubNR[(priority - 1 - 1)].repairLevelPqaNew = a
    },
    doDownSubNR (priority) {
      const a = this.responseDataSubNR[(priority - 1)].repairLevelPqaNew
      const b = this.responseDataSubNR[(priority - 1 + 1)].repairLevelPqaNew
      this.responseDataSubNR[(priority - 1)].repairLevelPqaNew = b
      this.responseDataSubNR[(priority - 1 + 1)].repairLevelPqaNew = a
    },
    doInsertSubNR (priority) {
      const array = {
        priority: (priority + 1),
        repairLevelPqaNew: ''
      }
      this.responseDataSubNR.splice((priority - 1 + 1), 0, array)
      this.doResetPrioritySubNR()
    },
    doRemoveSubNR (priority) {
      this.responseDataSubNR.splice((priority - 1), 1)
      this.doResetPrioritySubNR()
      if (this.responseDataSubNR.length === 1) {
        alert('需至少保留一筆NR次歸類規則')
      }
    },
    doResetPrioritySubNR () {
      this.responseDataSubNR.forEach((r, index) => {
        r.priority = (index + 1)
      })
    },
    doEdiiItemMainClassifyRuleMaintainList () {
      let error = 0
      this.responseTableDataMain.forEach((r) => {
        if (r.repairLevelPqaNew === '') {
          error++
        }
      })
      if (error === 0) {
        this.processingView = true
        const body = {
          // pageNum: this.pagination.page,
          // pageRows: this.pagination.rowsPerPage,
          // sortColumn: (this.pagination.descending !== null) ? this.pagination.sortBy : '',
          // sortType: this.pagination.descending ? 'DESC' : ((this.pagination.descending !== null) ? 'ASC' : ''),
          data: this.responseDataMain
        }
        this.$http.post('/api/pqm/cscDataParsingMgmt/repairItemMainClassifyRuleMaintainList', body).then(res => res.data)
          .then(res => {
            this.processingView = false
            if (res.status === 'success') {
              this.snackbar = true
              this.snackbarColor = 'green'
              this.snackbarMsg = '儲存成功'
              // this.responseDataMaterial = res.data.data
              // this.pages = res.data.totalRows
              this.getItemMainClassifyRuleMaintainList()
            } else { // failure
              this.snackbar = true
              this.snackbarColor = 'red'
              this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
            }
          })
      } else {
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '欄位不得為空'
      }
    },
    doEditItemSubClassifyDTRuleMaintainList () {
      let error = 0
      this.responseDataSubDT.forEach((r) => {
        if (r.repairLevelPqaNew === '') {
          error++
        }
      })
      if (error === 0) {
        this.processingView = true
        const body = {
          // pageNum: this.pagination.page,
          // pageRows: this.pagination.rowsPerPage,
          // sortColumn: (this.pagination.descending !== null) ? this.pagination.sortBy : '',
          // sortType: this.pagination.descending ? 'DESC' : ((this.pagination.descending !== null) ? 'ASC' : ''),
          data: this.responseDataSubDT
        }
        this.$http.post('/api/pqm/cscDataParsingMgmt/repairItemSubClassifyDTRuleMaintainList', body).then(res => res.data)
          .then(res => {
            this.processingView = false
            if (res.status === 'success') {
              this.snackbar = true
              this.snackbarColor = 'green'
              this.snackbarMsg = '儲存成功'
              // this.responseDataMaterial = res.data.data
              // this.pages = res.data.totalRows
              this.getItemSubClassifyDTRuleMaintainList()
            } else { // failure
              this.snackbar = true
              this.snackbarColor = 'red'
              this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
            }
          })
      } else {
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '欄位不得為空'
      }
    },
    doEditItemSubClassifyNRRuleMaintainList () {
      let error = 0
      this.responseDataSubNR.forEach((r) => {
        if (r.repairLevelPqaNew === '') {
          error++
        }
      })
      if (error === 0) {
        this.processingView = true
        const body = {
          // pageNum: this.pagination.page,
          // pageRows: this.pagination.rowsPerPage,
          // sortColumn: (this.pagination.descending !== null) ? this.pagination.sortBy : '',
          // sortType: this.pagination.descending ? 'DESC' : ((this.pagination.descending !== null) ? 'ASC' : ''),
          data: this.responseDataSubNR
        }
        this.$http.post('/api/pqm/cscDataParsingMgmt/repairItemSubClassifyNRRuleMaintainList', body).then(res => res.data)
          .then(res => {
            this.processingView = false
            if (res.status === 'success') {
              this.snackbar = true
              this.snackbarColor = 'green'
              this.snackbarMsg = '儲存成功'
              // this.responseDataMaterial = res.data.data
              // this.pages = res.data.totalRows
              this.getItemSubClassifyNRRuleMaintainList()
            } else { // failure
              this.snackbar = true
              this.snackbarColor = 'red'
              this.snackbarMsg = res.errMsg + ' (' + res.errCode + ')'
            }
          })
      } else {
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '欄位不得為空'
      }
    }
  }
}
</script>
<style>
</style>
