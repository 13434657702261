<template>
  <v-container
    :style="'background: url(' + (theme === 'light' ? homeBgLight : homeBgDark) + ') center center / cover;opacity: 1;height: 100%;'"
    fluid/>
</template>

<script>
import { mapState } from 'vuex'
import homeBgLight from '@/assets/img/Homebg_light.jpg'
import homeBgDark from '@/assets/img/Homebg_dark.jpg'

export default {
  name: 'HomePage',
  components: {
  },
  data: () => ({
    homeBgLight,
    homeBgDark
  }),
  computed: {
    ...mapState('app', ['theme', 'shrink'])
  },
  watch: {
  },
  mounted () {
  },
  beforeDestroy () {
  },
  methods: {
  }
}
</script>
