<template>
  <v-container
    fluid
    grid-list-xl
    pt-0
  >
    <v-layout wrap>
      <v-flex
        xs12
      >
        <material-card
          :title="'燒機不良率摘要篩選 (' + summaryDate + ')'"
          class="card-shrink card-padding v-card__first"
          color="general"
        >
          <v-form>
            <v-container
              py-0
              class="mw-100">
              <v-layout wrap>
                <v-flex
                  xs12
                  lg2>
                  <v-menu
                    v-model="summaryDateMenu"
                    :close-on-content-click="false"
                    :nudge-left="40"
                    lazy
                    transition="scale-transition"
                    max-width="290px"
                    offset-y
                    full-width
                  >
                    <v-text-field
                      slot="activator"
                      :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                      v-model="summaryDate"
                      label="時間"
                      prepend-icon="event"
                    />
                    <v-date-picker
                      v-model="summaryDate"
                      :min="startdate"
                      :max="new Date() | date-format"
                      :color="theme==='dark'?'danger':'general'"
                      no-title
                      scrollable
                      actions/>
                  </v-menu>
                </v-flex>
                <v-flex
                  xs12
                  sm6
                  lg2>
                  <v-autocomplete
                    v-model="summaryStage"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryStageMenu"
                    label="Stage"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex
                  xs12
                  sm6
                  lg2>
                  <v-autocomplete
                    v-model="summaryEMS"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryEMSMenu"
                    label="EMS"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex
                  xs12
                  sm6
                  lg2>
                  <v-autocomplete
                    v-model="summaryFailRate"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="[
                      { text: 'All', value: 'All' },
                      { text: '> 3%', value: '0.03' },
                      { text: '> 5%', value: '0.05' }
                    ]"
                    label="Fail Rate"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex
                  xs12
                  sm6
                  lg2>
                  <v-autocomplete
                    v-model="summaryModelName"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryModelNameMenu"
                    label="Model Name"
                    class="v-text-field__slot"
                    color="white"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex
                  xs12
                  lg2
                  text-xs-right
                >
                  <v-btn
                    class="mx-0 font-weight-light"
                    color="general"
                    @click="doSubmit()"
                  >
                    <v-icon class="mr-2">mdi-backup-restore</v-icon>查詢
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
        <material-card>
          <v-data-table
            :headers="headers"
            :items="responseFailTable"
            :pagination.sync="pagination"
            multi-sort
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="subheading font-weight-light text-general text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <td>{{ item.modelName }}</td>
              <td class="text-xs-right">{{ item.productionQty }}</td>
              <td class="text-xs-right">{{ item.failQty }}</td>
              <td class="text-xs-right"><v-chip
                :class="item.warningColor"
                small>{{ (item.failRate*100).toFixed(1) }}%</v-chip></td>
              <td>
                <span
                  v-for="(stage, s) in item.stageList"
                  :key="'s'+s">
                  {{ stage }}
                </span>
              </td>
              <td>{{ item.ems }}</td>
              <td>
                <router-link
                  :to="'/thermal/dailyBurnInDataStatistic?modelname='+item.modelName+'&date='+summaryDate+'&emspk='+item.emsPk+'&ems='+item.ems"
                  target="_blank">
                  <v-btn
                    class="mx-0 font-weight-light small"
                    color="primary">
                    <v-icon class="mr-2">mdi-chart-bar</v-icon>當日統計
                  </v-btn>
                </router-link>
              </td>
            </template>
          </v-data-table>
        </material-card>
      </v-flex>
      <v-flex
        class="v-flex__first"
        style="margin-top: 60px !important;"
        xs12
      >
        <material-card
          class="v-flex v-c3-chart"
          color="green"
        >
          <div
            class="w-100 chart-btn c3-chart"
            style="overflow-x: auto;">
            <material-notification
              color="brown"
              class="mb-3 h4 font-weight-bold"
              style="margin-top: 0px!important;"
            >
              燒機不良率
            </material-notification>
            <vue-c3 :handler="handler_bar"/>
          </div>
        </material-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="loadingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :top="true"
      :color="snackbarColor">
      {{ snackbarMsg }}
      <v-btn
        dark
        flat
        @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import Vue from 'vue'
import VueC3 from 'vue-c3'
import 'c3/c3.min.css'
import { mapState } from 'vuex'
import Loading from '@/assets/img/loading.gif'

export default {
  name: 'DailyThermalFailRateSummary',
  components: {
    VueC3
  },
  data () {
    return {
      startdate: '2018-04-01',
      snackbar: false,
      snackbarColor: 'red',
      snackbarMsg: '',
      Loading,
      loadingView: false,
      summaryDateMenu: false,
      summaryDate: this.$moment(new Date()).format('YYYY-MM-DD'),
      summaryStageMenu: [],
      summaryEMSMenu: [],
      summaryModelNameMenu: [],
      summaryStage: 'All',
      summaryEMS: 'All',
      summaryFailRate: 'All',
      summaryModelName: 'All',
      responseFailTable: [],
      barXaxisName: ['x'],
      barXaxisValue: ['ModelName'],
      barXaxisColor: ['#999999'],
      pagination: {
        rowsPerPage: 1,
        sortBy: 'failRate', // The field that you're sorting by
        descending: true
      },
      headers: [
        {
          sortable: true,
          text: 'ModelName',
          value: 'modelName'
        },
        {
          sortable: true,
          text: '生產數量',
          value: 'productionQty',
          align: 'right'
        },
        {
          sortable: true,
          text: '不良數量',
          value: 'failQty',
          align: 'right'
        },
        {
          sortable: true,
          text: '不良率',
          value: 'failRate',
          align: 'right'
        },
        {
          sortable: true,
          text: 'Stage',
          value: 'stageList'
        },
        {
          sortable: true,
          text: 'EMS',
          value: 'ems'
        },
        {
          sortable: false,
          text: '操作',
          value: 'operation'
        }
      ],
      handler_bar: new Vue()
    }
  },
  computed: {
    ...mapState('app', ['theme', 'shrink']),
    options_bar () {
      return {
        data: {
          x: 'x',
          columns: [
            this.barXaxisName,
            this.barXaxisValue
          ],
          labels: {
            format: function (v, id, i, j) { return v + '%' }
          },
          type: 'bar',
          colors: {
            ModelName: '#999999'
          },
          color: (color, d) => {
            // d will be 'id' when called for legends
            // return d.value >= 5.0 ? '#ff0000' : d.value >= 3.0 ? '#ff9800' : color
            return this.barXaxisColor[d.index]
          }
        },
        axis: {
          x: {
            type: 'category' // this needed to load string x value
            // label: {
            //   text: '期',
            //   position: 'outer-center'
            // }
          },
          y: {
            tick: {
              format: function (value) { return value + '%' }
            }
            // label: {
            //   text: '%',
            //   position: 'outer-middle'
            // }
          }
        },
        tooltip: {
          format: {
            title (d) {
              // return `第 ${d} 期`
            },
            value (value, ratio, id) {
              // return `${value} 億`
            }
          }
        },
        grid: {
          x: {
            show: true
          },
          y: {
            show: true
          }
        },
        legend: {
          position: 'bottom',
          padding: 16,
          show: false
        },
        size: {
          width: (this.barXaxisName.length - 1) * 100,
          height: 500
        }
      }
    }
  },
  watch: {
    summaryDate (val) {
      this.summaryDateMenu = false
    }
  },
  mounted () {
    this.$store.dispatch('getAuthorize', '/thermal/dailyThermalFailRateSummary')
      .then(response => {
        if (response.status === 'success' && response.data.accessible) {
          JSON.parse(localStorage.getItem('moduleDataInitDate')).forEach((n) => {
            if (n.name === 'thermal') {
              this.startdate = n.value
            }
          })
          if (this.$route.params.searchDate) {
            this.summaryDate = this.$route.params.searchDate
          }
          this.$http.get('/api/getSelectOptionData/stage').then(res => res.data)
            .then(res => {
              if (res.status === 'success') {
                this.summaryStageMenu = [{ text: 'All', value: 'All' }].concat(res.data)
              }
            })
          this.$http.get('/api/getSelectOptionData/ems').then(res => res.data)
            .then(res => {
              if (res.status === 'success') {
                this.summaryEMSMenu = [{ text: 'All', value: 'All' }].concat(res.data)
              }
            })
          this.$http.get('/api/getSelectOptionData/modelName').then(res => res.data)
            .then(res => {
              if (res.status === 'success') {
                this.summaryModelNameMenu = [{ text: 'All', value: 'All' }].concat(res.data)
              }
            })
          this.loadData()
        } else if (response.errCode === 'E10000002') {
          const routeData = this.$router.resolve({ path: '/noEnter/E10000002' })
          localStorage.setItem('errormsg', response.errMsg)
          window.location.assign(routeData.href)
        } else {
          const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
          window.location.assign(routeData.href)
        }
      })
      .catch(err => {
        console.log(err)
        const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
        window.location.assign(routeData.href)
      })
  },
  methods: {
    async loadData () {
      this.loadingView = true
      await this.handler_bar.$emit('init', this.options_bar)
      await this.doSubmit()
    },
    doSubmit () {
      this.loadingView = true
      const body = {
        queryStartDate: this.summaryDate,
        queryEndDate: this.summaryDate,
        stage: this.summaryStage === 'All' ? '' : this.summaryStage,
        ems: this.summaryEMS === 'All' ? '' : this.summaryEMS,
        failRate: this.summaryFailRate === 'All' ? '' : this.summaryFailRate,
        modelPk: this.summaryModelName === 'All' ? '' : this.summaryModelName
      }
      this.$http.post('/api/thermal/thermalBurnInFailRateSummaryList', body).then(res => res.data)
        .then(res => {
          this.loadingView = false
          if (res.status === 'success') {
            this.barXaxisName = ['x']
            this.barXaxisValue = ['ModelName']
            this.barXaxisColor = []
            this.pagination.rowsPerPage = res.data.length
            this.responseFailTable = res.data
            res.data.forEach((r) => {
              this.barXaxisName.push(r.modelName)
              this.barXaxisValue.push((r.failRate * 100).toFixed(1))
              this.barXaxisColor.push(r.warningColor || '#999999')
              this.handler_bar.$emit('init', this.options_bar)
            })
          }
        })
    },
    summaryWeeksArray (weeks) {
      if (this.summaryWeeksUnfoldList.findIndex(function checkUnfold (ww) { return ww === weeks }) === -1) {
        this.summaryWeeksUnfoldList.push(weeks)
      } else {
        this.summaryWeeksUnfoldList.splice(this.summaryWeeksUnfoldList.findIndex(function checkUnfold (ww) { return ww === weeks }), 1)
      }
    },
    summaryWeeksMatch (weeks) {
      return this.summaryWeeksUnfoldList.findIndex(function checkUnfold (ww) { return ww === weeks }) > -1
    }
  }
}
</script>
<style>
</style>
