// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import VueMoment from 'vue-moment'
import moment from 'moment'
import 'babel-polyfill'

// Components
import './components'

// Plugins
import './plugins'

// Sync router with store
import { sync } from 'vuex-router-sync'

// Application imports
import App from './App'
import i18n from '@/i18n'
import router from '@/router'
import store from '@/store'
import Vuetify from 'vuetify'
import theme from './plugins/theme'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import DatetimePicker from 'vuetify-datetime-picker'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import axios from 'axios'
// // custom axios
// import axios from './_axios'
// import '../config/axios'

// // 新增請求攔截器
// axios.interceptors.request.use(function (config) {
//   // 在傳送請求之前做些什麼
//   return config
// }, function (error) {
//   // 對請求錯誤做些什麼
//   return Promise.reject(error)
// })
// // 新增響應攔截器
// axios.interceptors.response.use(function (response) {
//   // 對響應資料做點什麼
//   return response
// }, function (error) {
//   // 對響應錯誤做點什麼
//   return Promise.reject(error)
// })

// 自定義過濾器
Vue.filter('date-format', function (value, formatStrDate = 'YYYY-MM-DD') {
  return moment(value).format(formatStrDate)
})
Vue.filter('datetime-format', function (value, formatStrDateTime = 'YYYY-MM-DD HH:mm:ss') {
  return moment(value).format(formatStrDateTime)
})
Vue.filter('time-format', function (value, formatStrTime = 'HH:mm:ss') {
  return moment(value).format(formatStrTime)
})

Vue.prototype.$http = axios
Vue.prototype.$httpGet = async function (url, loadingView) {
  try {
    const response = await this.$http.get(url)
    return response.data
  } catch (error) {
    console.error(`Error in httpGet(${url}):`, error)
    // 您還可以在此處添加其他錯誤處理通報（例如，顯示錯誤消息）
    throw error
  }
}

let env

if (process.env.NODE_ENV === 'production') {
  env = require('../config/pro.env')
} else if (process.env.VUE_APP_MODE === 'uat') {
  env = require('../config/uat.env')
} else {
  env = require('../config/dev.env')
}

Vue.prototype.$CUSTOME_ENV = env

axios.defaults.baseURL = env.api_url
axios.defaults.headers.get.Accept = 'application/json'

const token = localStorage.getItem('token')
if (token) {
  axios.defaults.headers.common.Authorization = 'Bearer ' + token
}

// axios.interceptors.request.use( // 这是拦截器设置tokne
//   config => {
//     // if (localStorage.getItem('token') === '' || localStorage.getItem('token') === null) {
//     //   this.$router.push('/')
//     // }
//     // let token = localStorage.getItem('x-auth-token')
//     // console.log(config.url)
//     // console.log(axios.defaults.baseURL)
//     // if (token) { // 判断是否存在token，如果存在的话，则每个http header都加上token
//     // config.headers.token = `${token}`
//     // }
//     // if (config.url.indexOf(axios.defaults.baseURL) === -1) {
//     // let getTimestamp = new Date().getTime()
//     // if (config.url.indexOf('?') > -1) {
//     //   config.url = axios.defaults.baseURL + config.url + '&timestamp=' + getTimestamp
//     // } else {
//     //   config.url = axios.defaults.baseURL + config.url + '?timestamp=' + getTimestamp
//     // }
//     // }
//     return config
//   },
//   err => {
//     // alert(err)
//     return Promise.reject(err)
//   })

// axios.interceptors.response.use(
//   response => { // 在这里可以设置请求成功的一些设置
//     // let newToken = response.config.headers.token
//     // localStorage.setItem('x-auth-token', newToken)
//     // if (response.data.errCode === 'E0000001' || response.data.errMsg === 'Access Token 驗證失敗') {
//     //   this.$router.push('/')
//     // }
//     // if (response.data.code === -1 && response.status === 200) {
//     // this.$message({ showClose: true, message: response.data.msg, type: 'warning' })
//     // }
//     return response
//   },
//   error => { // 在这里设置token过期的跳转
//     if (error.response) {
//       // if (error.response.data.code === 401) {
//       // this.$router.push('/')
//       // }
//     }
//   })

// Sync store with router
sync(store, router)

// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(Vuetify, {
  iconfont: 'mdi',
  theme
})

Vue.use(VueMoment, {
  moment
})
Vue.moment.suppressDeprecationWarnings = true
Vue.use(DatetimePicker)
Vue.config.productionTip = false
Vue.config.devtools = true
/* eslint-disable no-new */
new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
