<template>
  <v-container
    krypart
    fluid
    grid-list-xl
    pt-0
  >
    <v-layout wrap>
      <v-flex
        xs12
      >
        <material-card
          title="揚聲器料件整體製程品質總覽"
          class="card-shrink card-padding v-card__first"
          color="general"
        >
          <v-form>
            <v-container
              py-0
              class="mw-100">
              <v-layout
                wrap
                align-items-center>
                <v-flex
                  xs12
                  sm6
                  lg2>
                  <v-autocomplete
                    v-model="summaryVendorName"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryVendorNameMenu"
                    label="廠商"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex
                  xs12
                  sm6
                  lg2>
                  <v-autocomplete
                    v-model="summaryPartNumber"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryPartNumberMenu"
                    label="料號"
                    class="v-text-field__slot"
                    color="white"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex
                  xs12
                  lg1/>
                <v-flex
                  flex-small
                  d-flex
                  xs12
                  lg4>
                  <v-radio-group
                    v-model="summaryDateType"
                    class="justify-content-end align-items-center"
                    row>
                    <v-radio
                      label="依週次統計"
                      value="week"/>
                    <v-radio
                      label="依月份統計"
                      value="month"/>
                    <v-radio
                      label="依季別統計"
                      value="season"/>
                  </v-radio-group>
                </v-flex>
                <v-flex
                  v-show="summaryDateType==='week'"
                  flex-small
                  align-items-center
                  xs12
                  lg2>
                  <!--<v-autocomplete
                    v-model="summaryWeeksStart"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryAllYearWeeksList"
                    label="起始週次"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  >
                    <template v-slot:item="data">
                      <div
                        :class="{'weeks-list-data-tip':contentDateListWeeks.indexOf(data.item.value)!==-1}"
                        class="v-list__tile__content">
                        <div class="v-list__tile__title">
                          {{ data.item.text }}
                        </div>
                      </div>
                    </template>
                  </v-autocomplete>-->
                  <WeekPicker
                    :list-start-date="startdate"
                    :content-date-list="contentDateList"
                    :select-week="summaryWeeksStart"
                    label-text="起始週次"
                    value-type="sDay"
                    @selected-week="selectedWeek"/>
                </v-flex>
                <v-flex
                  v-show="summaryDateType==='month'"
                  xs12
                  lg2>
                  <v-menu
                    v-model="summaryMonthMenu"
                    :close-on-content-click="false"
                    :nudge-left="40"
                    lazy
                    transition="scale-transition"
                    max-width="290px"
                    offset-y
                    full-width
                  >
                    <v-text-field
                      slot="activator"
                      :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                      v-model="summaryMonthStart"
                      label="起始月份"
                      prepend-icon="event"
                    />
                    <v-date-picker
                      v-model="summaryMonthStart"
                      :min="startdate"
                      :max="new Date() | date-format"
                      :color="theme==='dark'?'danger':'general'"
                      :events="contentDateList.map((d) => this.$moment(d).format('YYYY-MM'))"
                      event-color="green lighten-1"
                      type="month"
                      no-title
                      scrollable
                      actions/>
                  </v-menu>
                </v-flex>
                <v-flex
                  v-show="summaryDateType==='season'"
                  flex-small
                  align-items-center
                  xs12
                  lg2>
                  <v-autocomplete
                    v-model="summarySeasonStart"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryAllYearSeasonList"
                    label="起始季別"
                    class="v-text-field__slot"
                    persistent-hint
                    outlined
                  >
                    <template v-slot:item="data">
                      <div
                        :class="{'weeks-list-data-tip':contentDateListSeason.indexOf(data.item.value)!==-1}"
                        class="v-list__tile__content">
                        <div class="v-list__tile__title">
                          {{ data.item.text }}
                        </div>
                      </div>
                    </template>
                  </v-autocomplete>
                </v-flex>
                <v-flex
                  xs12
                  lg1
                  text-xs-right
                >
                  <v-btn
                    :disabled="(summaryWeeksStart===''||summaryMonthStart===''||summarySeasonStart==='')||summaryVendorName===''||summaryPartNumber===''"
                    class="mx-0 font-weight-light"
                    color="general"
                    @click="doSubmit()"
                  >
                    <v-icon class="mr-2">mdi-backup-restore</v-icon>分析
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
      </v-flex>
      <v-flex
        v-show="processingStatus"
        style="padding: 0px 0px 0px 0px!important;"
        selenium-grab
        p-0
        m-0
        layout
        wrap
        lg12>
        <v-flex
          xs12
          sm6
          lg4>
          <material-stats-card
            :value="responseVendorName"
            style="margin:10px 0 !important;"
            class="v-offset-none"
            icon="mdi-factory"
            title="廠商"/>
        </v-flex>
        <!-- <v-flex
          xs12
          sm6
          lg3>
          <material-stats-card
            :value="responseModelList"
            style="margin:10px 0 !important;"
            class="v-offset-none"
            icon="mdi-factory"
            title="機種"/>
        </v-flex> -->
        <v-flex
          xs12
          sm6
          lg4
          style="position:relative;">
          <material-stats-card
            :value="responsePartNumber+' ____'"
            style="margin:10px 0 !important;"
            class="card-mix v-offset-none"
            icon="mdi-factory"
            title="料號"/>
          <router-link
            :to="'/keypart/speaker/speakerSingleProcessAnalyzer/' + responseDateType + '/' + responseDateStart + '/' + responseVendorName + '/' + responsePartNumber"
            class="mix-btn p-0"
            style="position: absolute;right: 1.825rem;top: 2.75rem;color: #ffffff;font-size: 1rem;"
            target="_blank">
            <span>[詳情]</span>
          </router-link>
        </v-flex>
        <v-flex
          xs12
          sm6
          lg4>
          <material-stats-card
            :value="responseDateStart +' ('+ responseDateType + ')'"
            style="margin:10px 0 !important;"
            color="indigo"
            icon="mdi-calendar"
            title="起始日期"/>
        </v-flex>
        <v-flex
          v-if="systemTrigger && overloadMsg!==''"
          class="data-area no-data-page"
          xs12>
          <span v-html="'<p>錯誤訊息:'+overloadMsg+'</p>'"/>
        </v-flex>
        <v-flex
          v-if="summaryNoData && overloadMsg===''"
          class="data-area no-data-page"
          xs12>
          此日期區間查無資料
        </v-flex>
        <v-flex
          v-if="!summaryNoData && overloadMsg==='' && responseModelList.length>0"
          lg3
        >
          <material-card
            title="機種"
            class="card-mix card-shrink card-padding v-card__first"
            color="blue-grey"
          >
            <div
              v-for="(model,m) in responseModelList"
              :key="'m'+m"
              class="d-flex my-3 p-2 bg-dark h4">
              {{ model }}
            </div>
          </material-card>
        </v-flex>
        <v-flex
          v-if="!summaryNoData && overloadMsg==='' && responseDataCompileFailList.length>0"
          lg9
        >
          <material-card
            title="不良製程警告"
            class="card-mix card-shrink card-padding v-card__first"
            color="pink-1"
          >
            <router-link
              :to="'/keypart/speaker/speakerSingleProcessAnalyzer/' + responseDateType + '/' + responseDateStart + '/' + responseVendorName + '/' + responsePartNumber"
              class="mix-btn p-0"
              target="_blank">
              <v-btn
                class="black text-light mx-0">
                <v-icon class="float-left mr-1">mdi-eye</v-icon>檢視
              </v-btn>
            </router-link>
            <div class="fail-data-area speaker">
              <div
                v-for="(fail,f) in responseDataCompileFailList"
                :key="'f'+f">
                <h4 class="d-flex w-100 mt-3 mb-0 mx-2">{{ fail.speakerType }}<hr class="ml-3 border-light"></h4>
                <div v-if="fail.left.length>0">
                  <p>Left</p>
                  <div>
                    <span
                      v-for="(left,l) in fail.left"
                      :key="'l'+l"
                      @click="doSubmitSingle(fail.speakerType, left.name)">{{ left.name }}<strong v-if="left.class==='detail'">{{ left.no }}</strong><span>{{ left.value.toFixed(2) }}</span></span>
                  </div>
                </div>
                <div v-if="fail.right.length>0">
                  <p>Right</p>
                  <div>
                    <span
                      v-for="(right,r) in fail.right"
                      :key="'r'+r"
                      @click="doSubmitSingle(fail.speakerType, right.name)">{{ right.name }}<strong v-if="right.class==='detail'">{{ right.no }}</strong><span>{{ right.value.toFixed(2) }}</span></span>
                  </div>
                </div>
              </div>
            </div>
          </material-card>
        </v-flex>
        <v-flex
          v-show="!summaryNoData && overloadMsg===''"
          lg12
        >
          <div
            v-for="(speaker,s) in responseData"
            v-show="responseData.length>0"
            :key="'s'+s">
            <h3 class="d-flex w-100 mt-4 mb-0 mx-2">{{ speaker.speakerType }}<hr class="mx-3 border-light"></h3>
            <v-layout
              v-show="speaker.left.length>0"
            >
              <v-flex
                v-for="(type,t) in typrList"
                :key="'tl'+t"
                :class="'lg'+ type.grid"
              >
                <material-card
                  :title="'L-'+type.typeName"
                  class="card-shrink card-padding v-card__first speaker"
                  color="yellow-4"
                >
                  <div
                    v-if="speaker.left.filter(e => e.testItemType === type.typeName).length>0"
                    class="data-area key-size"
                    style="flex-flow: row;">
                    <div
                      v-for="(group,g) in speaker.left.filter(e => e.testItemType === type.typeName)"
                      :key="'g'+g"
                      class="data-group">
                      <div
                        :class="{ 'ordinary':doCpkGrade(group.cpk),'bad':!doCpkGrade(group.cpk) }"
                        class="data-group-name"
                        style="width: 100%;min-width: 120px;margin: 0 5px;">
                        <span style="font-size: 1.25rem;">{{ group.testItemName.split('_').length > 1 ? group.testItemName.split('_')[1] : '' }}</span>
                        <p
                          class="cpk"
                          @click="doSubmitSingle(speaker.speakerType, group.testItemName)">{{ group.cpk.toFixed(2) }}</p>
                        <p class="lv">{{ doCpkGradeName(group.cpk) }}</p>
                      </div>
                    </div>
                  </div>
                  <!-- <div
                    v-else
                    class="data-area no-data">
                    此日期區間查無資料
                  </div> -->
                </material-card>
              </v-flex>
            </v-layout>
            <v-layout
              v-show="speaker.right.length>0"
            >
              <v-flex
                v-for="(type,t) in typrList"
                :key="'tr'+t"
                :class="'lg'+ type.grid"
              >
                <material-card
                  :title="'R-'+type.typeName"
                  class="card-shrink card-padding v-card__first speaker"
                  color="yellowgreen-4"
                >
                  <div
                    v-if="speaker.right.filter(e => e.testItemType === type.typeName).length>0"
                    class="data-area key-size"
                    style="flex-flow: row;">
                    <div
                      v-for="(group,g) in speaker.right.filter(e => e.testItemType === type.typeName)"
                      :key="'g'+g"
                      class="data-group">
                      <div
                        :class="{ 'ordinary':doCpkGrade(group.cpk),'bad':!doCpkGrade(group.cpk) }"
                        class="data-group-name"
                        style="width: 100%;min-width: 120px;margin: 0 5px;">
                        <span style="font-size: 1.25rem;">{{ group.testItemName.split('_').length > 1 ? group.testItemName.split('_')[1] : '' }}</span>
                        <p
                          class="cpk"
                          @click="doSubmitSingle(speaker.speakerType, group.testItemName)">{{ group.cpk.toFixed(2) }}</p>
                        <p class="lv">{{ doCpkGradeName(group.cpk) }}</p>
                      </div>
                    </div>
                  </div>
                  <!-- <div
                    v-else
                    class="data-area no-data">
                    此日期區間查無資料
                  </div> -->
                </material-card>
              </v-flex>
            </v-layout>
          </div>
          <div
            v-show="responseData.length===0"
            class="data-area no-data">
            此日期區間查無資料
          </div>
        </v-flex>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="chartLineView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <v-card
        max-width="80%"
        style="margin:0 auto;">
        <v-card-text>
          <v-alert
            v-model="chartLineView"
            class="mt-3 mb-3 border-0"
            color="brown">
            <strong class="h4"> ( {{ chartViewSpeakerType }} ) {{ responseStandardData?responseStandardData.testItemName:'' }} ： {{ responseDateStart }} - {{ responseDateEnd }} ({{ responseDateType }}) ｜ {{ responseVendorName }} ｜ {{ responsePartNumber }}</strong>
          </v-alert>
          <div class="d-flex">
            <div class="w-100 m-3">
              <p class="text-center my-1">{{ chartViewTestItemName }}</p>
              <div class="keypart-chart-info">
                <div :class="{ 'ordinary':doCpkGrade(responseStandardData.cpk),'bad':!doCpkGrade(responseStandardData.cpk) }">
                  <span>CPK</span>
                  <p>{{ responseStandardData.cpk.toFixed(2) }}</p>
                  <div>
                    <span>{{ doCpkGradeName(responseStandardData.cpk) }}</span>
                  </div>
                </div>
                <div>
                  <span>SPEC</span>
                  <p>{{ responseStandardData.lowerSpecValue }}{{ (responseStandardData.lowerSpecValue!==null&&responseStandardData.upperSpecValue!==null)?' ~ ':'' }}{{ responseStandardData.upperSpecValue }}</p>
                  <div>
                    <span v-if="responseStandardData.lowerSpecValue!==null">LOWER</span>
                    <span v-if="responseStandardData.upperSpecValue!==null">UPPER</span>
                  </div>
                </div>
              </div>
              <table class="keypart-chart-table statistic-table">
                <thead>
                  <tr>
                    <th>sigma</th>
                    <th>value</th>
                    <th>histogram</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(row, r) in responseStandardData.distributionCoverageList">
                    <tr
                      v-if="row.define.type === 'Sigma'"
                      :key="'r'+r">
                      <td>{{ row.define.name }}</td>
                      <td>{{ row.define.value.toFixed(4) }}</td>
                      <td>{{ row.amount }}</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <vue-c3
              :handler="handler_line"
              class="keypart-chart-area"/>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="red"
            small
            @click="chartLineView=false">
            <v-icon class="mr-2">mdi-close</v-icon>關閉
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="processingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog>
    <v-dialog
      v-model="loadingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :top="true"
      :color="snackbarColor">
      {{ snackbarMsg }}
      <v-btn
        dark
        flat
        @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import qs from 'qs'
import Vue from 'vue'
import VueC3 from 'vue-c3'
import 'c3/c3.min.css'
import { mapState } from 'vuex'
import NormalDistribution from 'normal-distribution'
import Loading from '@/assets/img/loading.gif'
import WeekPicker from '@/components/module/datepicker/WeekPicker.vue'

export default {
  name: 'SpeakerSummaryAnalyzer',
  components: {
    VueC3,
    WeekPicker
  },
  data () {
    return {
      startdate: '2018-04-01',
      snackbar: false,
      snackbarColor: 'red',
      snackbarMsg: '',
      Loading,
      loadingView: false,
      // overload: false,
      overloadMsg: '',
      processingView: false,
      processingStatus: false,
      summaryNoData: true,
      systemTrigger: false,
      // summaryLoading: {

      // },
      summaryDateType: 'week',
      summaryMonthMenu: false,
      summaryMonth: this.$moment(new Date()).format('YYYY-MM'),
      summaryMonthStart: this.$moment(new Date()).format('YYYY-MM'),
      summarySeasonStart: Math.ceil((Number(this.$moment(new Date()).format('MM')) - 1) / 3) === 0 ? (Number(this.$moment(new Date()).format('YYYY')) - 1) + '-11-01' : (this.$moment(new Date()).format('YYYY')) + '-0' + (((Math.ceil((Number(this.$moment(new Date()).format('MM')) - 1) / 3) - 1) * 3) + 2) + '-01',
      // summaryWeeksStart: Number(this.$moment(new Date()).format('YYYY')) * 100 + Math.ceil((((new Date(this.$moment(new Date()).format('YYYY-MM-DD')) - new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1))) / 86400000) + new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1)).getDay()) / 7) - parseInt((new Date(this.$moment(new Date()).format('YYYY') + '-01-01').getDay() + 5) / 6),
      summaryWeeksStart: this.$moment(new Date()).format('YYYY-MM-DD'),
      summaryVendorNameMenu: [],
      summaryPartNumberMenu: [],
      summaryVendorName: '',
      summaryPartNumber: '',
      // summaryYearWeeksList: [],
      // summaryAllYearWeeksList: [],
      summaryYearSeasonList: [],
      summaryAllYearSeasonList: [],
      tempDateType: 'week',
      tempVendorName: '',
      tempPartNumber: '',
      CpkGradeList: [],
      contentDateList: [],
      typrList: [
        {
          typeName: 'SPL',
          grid: 12
        }, {
          typeName: 'THD',
          grid: 8
        }, {
          typeName: 'RUB',
          grid: 8
        }, {
          typeName: 'FO',
          grid: 4
        }, {
          typeName: 'DCR',
          grid: 4
        }
      ],
      responseDateType: 'week',
      responseVendorName: '',
      responseModelList: [],
      responsePartNumber: '',
      responseDateStart: '',
      responseDateEnd: '',
      // responseWeeksStart: '',
      responseData: [{
        speakerType: '',
        left: [],
        right: []
      }],
      responseStandardData: {
        cpk: 0
      },
      chartLineView: false,
      chartLineViewNum: 0,
      chartLineViewName: '',
      chartViewTestItemName: '',
      chartViewSpeakerType: '',
      barXaxisName: [],
      barXaxisValue: [],
      WeekList: ['日', '一', '二', '三', '四', '五', '六'],
      handler_line: new Vue()
    }
  },
  computed: {
    ...mapState('app', ['theme', 'shrink']),
    // processloadingView () {
    //   if (!this.summaryLoading.BL_Assy && !this.summaryLoading.COG_bonding && !this.summaryLoading.FOB_bonding && !this.summaryLoading.FOG_bonding && !this.summaryLoading.Key_Size && !this.summaryLoading.Optics && !this.summaryLoading.POL_ABI && !this.summaryLoading.VCOM) {
    //     return false
    //   } else {
    //     return true
    //   }
    // },
    responseDataCompileFailList () {
      const array = []
      // eslint-disable-next-line no-unused-vars
      this.responseData.forEach((speaker, s) => {
        if (speaker.speakerType.length > 0 && array.map(function (e) { return e.speakerType }).indexOf(speaker.speakerType) === -1) {
          array.push({ speakerType: speaker.speakerType, left: [], right: [] })
          const index = array.map(function (e) { return e.speakerType }).indexOf(speaker.speakerType)
          if ('left' in speaker) {
            speaker.left.forEach((i, ii) => {
              if (i.cpk !== null && !this.doCpkGrade(i.cpk)) {
                array[index].left.push({ name: i.testItemName, class: 'normal', no: null, value: i.cpk })
              }
            })
            array[index].left = array[index].left.sort(function (a, b) { return a.name.localeCompare(b.name) })
          }

          if ('right' in speaker) {
            speaker.right.forEach((i, ii) => {
              if (i.cpk !== null && !this.doCpkGrade(i.cpk)) {
                array[index].right.push({ name: i.testItemName, class: 'normal', no: null, value: i.cpk })
              }
            })
            array[index].right = array[index].right.sort(function (a, b) { return a.name.localeCompare(b.name) })
          }
        }
      })

      // this.responseData.left.forEach((i, ii) => {
      //   if (i.cpk !== null && !this.doCpkGrade(i.cpk)) {
      //     if (array.map(function (e) { return e.type }).indexOf('Left') === -1) {
      //       array.push({ type: 'Left', list: [{ name: i.testItemName, class: 'normal', no: null, value: i.cpk }] })
      //     } else {
      //       array[array.map(function (e) { return e.type }).indexOf('Left')].list.push({ name: i.testItemName, class: 'normal', no: null, value: i.cpk })
      //     }
      //   }
      // })

      // this.responseData.right.forEach((i, ii) => {
      //   if (i.cpk !== null && !this.doCpkGrade(i.cpk)) {
      //     if (array.map(function (e) { return e.type }).indexOf('Right') === -1) {
      //       array.push({ type: 'Right', list: [{ name: i.testItemName, class: 'normal', no: null, value: i.cpk }] })
      //     } else {
      //       array[array.map(function (e) { return e.type }).indexOf('Right')].list.push({ name: i.testItemName, class: 'normal', no: null, value: i.cpk })
      //     }
      //   }
      // })

      // array.forEach((n, nn) => {
      //   array[nn].list = n.list.sort(function (a, b) { return a.name.localeCompare(b.name) })
      // })
      return array
    },
    responseStandardUpperLower () {
      const array = []
      let ULindex = this.responseStandardData.upperSpecValue != null ? -0.5 : null
      let LLindex = this.responseStandardData.lowerSpecValue != null ? -0.5 : null
      this.responseStandardData.distributionCoverageList.map(function (e) {
        if (e.define.type === 'Sigma') {
          return e.define
        } else {
          return null
        }
      }).filter(Boolean).forEach((r, rr) => {
        if (this.responseStandardData.lowerSpecValue && this.responseStandardData.lowerSpecValue > r.value) {
          LLindex = rr + 0.5
        }
        if (this.responseStandardData.upperSpecValue && this.responseStandardData.upperSpecValue >= r.value) {
          ULindex = rr + 0.5
        }
      })
      if (ULindex) {
        array.push({ value: ULindex, text: 'Upper Spec Value' })
      }
      if (LLindex) {
        array.push({ value: LLindex, text: 'Lower Spec Value' })
      }
      return array
    },
    options_line () {
      return {
        data: {
          x: 'x',
          columns: [
            this.barXaxisName,
            this.barXaxisStandard,
            this.barXaxisValue
          ],
          axes: {
            常態分佈: 'y2'
          },
          type: 'bar',
          types: {
            常態分佈: 'spline'
          }
        },
        axis: {
          x: {
            type: 'category',
            padding: {
              top: 0,
              right: 0.1,
              bottom: 0,
              left: 0.2
            },
            tick: {
              rotate: -45,
              multiline: false
            }
          },
          y: {
            tick: {
              format: function (value) { return value }
            },
            label: {
              text: '統計次數',
              position: 'outer-middle'
            }
          },
          y2: {
            tick: {
              format: function (value) { return value }
            },
            label: {
              text: '常態分佈',
              position: 'outer-middle'
            },
            show: true
          }
        },
        tooltip: {
          order: function (t1, t2) {
            return t1.id < t2.id
          },
          contents: (d, value, ratio, id) => {
            return '<table class="c3-tooltip"><tbody><tr><th colspan="2">' + this.barXaxisName[d[1].index + 1] + '</th></tr><tr class="c3-tooltip-name--統計次數"><td class="name"><span style="background-color:#1f77b4"></span>統計次數</td><td class="value">' + d[1].value + '</td></tr></tbody></table>'
          }
          // format: {
          //   title: (d, value) => {
          //     return `${this.barXaxisName[d + 1]}`
          //   },
          //   value: (value, ratio, id) => {
          //     // if (id === '常態分佈') {
          //     //   return `${value.toFixed(3)}`
          //     // } else {
          //     //   return `${value}`
          //     // }
          //   }
          // }
          // order: 'asc'
        },
        grid: {
          x: {
            show: true,
            lines: this.responseStandardUpperLower
          },
          y: {
            show: true
          }
        },
        legend: {
          position: 'bottom',
          padding: 16,
          show: false
        },
        size: {
          width: ((window.innerWidth - this.shrink) * 0.5) - 64,
          height: 500
        },
        spline: {
          interpolation: {
            type: 'monotone'
          }
        }
      }
    },
    // contentDateListWeeks () {
    //   const array = []
    //   this.summaryYearWeeksList.forEach((n, nn) => {
    //     n.forEach((w, ww) => {
    //       if (ww === n.length - 1 && Number(this.$moment(w.start).format('DD')) > 25) {
    //         for (let d = new Date((Number(this.$moment(this.startdate).format('YYYY')) + nn) + '-' + this.$moment(w.start).format('MM-DD')).getTime(); d <= new Date((Number(this.$moment(this.startdate).format('YYYY')) + nn + 1) + '-' + this.$moment(w.end).format('MM-DD')).getTime(); d = d + 86400000) {
    //           // console.log(this.$moment(d).format('YYYY-MM-DD'))
    //           if (this.contentDateList.indexOf(this.$moment(d).format('YYYY-MM-DD')) !== -1) {
    //             array.push(Number((Number(this.$moment(this.startdate).format('YYYY')) + nn) + (ww < 10 ? '0' + String(ww) : String(ww))))
    //           }
    //         }
    //       }
    //       for (let d = new Date((Number(this.$moment(this.startdate).format('YYYY')) + nn) + '-' + this.$moment(w.start).format('MM-DD')).getTime(); d <= new Date((Number(this.$moment(this.startdate).format('YYYY')) + nn) + '-' + this.$moment(w.end).format('MM-DD')).getTime(); d = d + 86400000) {
    //         // console.log(this.$moment(d).format('YYYY-MM-DD'))
    //         if (this.contentDateList.indexOf(this.$moment(d).format('YYYY-MM-DD')) !== -1) {
    //           array.push(Number((Number(this.$moment(this.startdate).format('YYYY')) + nn) + (ww < 10 ? '0' + String(ww) : String(ww))))
    //         }
    //       }
    //     })
    //   })
    //   return array
    // },
    // contentDateListWeeks () {
    //   const array = []
    //   this.summaryYearWeeksList.forEach((n, nn) => {
    //     n.forEach((w, ww) => {
    //       if (w) {
    //         for (let d = new Date(w.start).getTime(); d <= new Date(w.end).getTime(); d = d + 86400000) {
    //           if (this.contentDateList.indexOf(this.$moment(d).format('YYYY-MM-DD')) !== -1) {
    //             array.push(Number(this.$moment(w.start).format('YYYY') + (ww < 10 ? '0' + String(ww) : String(ww))))
    //           }
    //         }
    //       }
    //     })
    //   })
    //   return array
    // },
    contentDateListSeason () {
      const array = []
      this.summaryYearSeasonList.forEach((n, nn) => {
        n.forEach((s, ss) => {
          if (s) {
            for (let d = new Date(s.start).getTime(); d <= new Date(s.end).getTime(); d = d + 86400000) {
              if (this.contentDateList.indexOf(this.$moment(d).format('YYYY-MM-DD')) !== -1) {
                array.push(s.start)
              }
            }
          }
        })
      })
      return array
    }
  },
  watch: {
    // processloadingView (val) {
    //   if (!val && !this.overload) {
    //     this.processingView = true
    //   } else if (!val && this.overload) {
    //     alert(this.overloadMsg)
    //   }
    // },
    summaryVendorName (val) {
      this.getPartNumber(val)
    },
    summaryPartNumber (val) {
      this.contentDateList = []
      if (val) this.getContentDateList(this.summaryVendorName, val, this.summaryDateType)
    },
    summaryDateType (val) {
      this.contentDateList = []
      if (this.summaryPartNumber) this.getContentDateList(this.summaryVendorName, this.summaryPartNumber, val)
    },
    chartLineView (val) {
      if (val) {
        this.barXaxisName = ['x']
        this.barXaxisStandard = ['常態分佈']
        this.barXaxisValue = ['統計次數']
        if (this.responseStandardData.distributionCoverageList.length > 0) {
          this.responseStandardData.distributionCoverageList.forEach((r) => {
            if (r.define.type === 'Sigma') {
              this.barXaxisName.push(r.define.name)
              if (this.responseStandardData.standardDeviation === 0) {
                this.barXaxisStandard.push(r.amount.toFixed(3))
              } else {
                this.barXaxisStandard.push(new NormalDistribution(this.responseStandardData.average, this.responseStandardData.standardDeviation).pdf(r.define.value).toFixed(3))
              }
              this.barXaxisValue.push(r.amount)
            }
          })
        }
        if (this.chartLineViewNum === 0) {
          this.handler_line.$emit('init', this.options_line)
          this.chartLineViewNum++
        } else {
          this.handler_line.$emit('dispatch', chart => {
            const options = {
              columns: [
                this.barXaxisName,
                this.barXaxisStandard,
                this.barXaxisValue
              ]
            }
            chart.load(options)
            chart.xgrids(this.responseStandardUpperLower)
          })
        }
      } else {
        // this.handler_line.$emit('destroy')
      }
    }
  },
  mounted () {
    this.$store.dispatch('getAuthorize', '/keypart/speaker/speakerSummaryAnalyzer')
      .then(response => {
        if (response.status === 'success' && response.data.accessible) {
          JSON.parse(localStorage.getItem('moduleDataInitDate')).forEach((n) => {
            if (n.name === 'lcdModule') {
              this.startdate = n.value
            }
          })
          if (this.$route.params.searchDateType && this.$route.params.searchDateStart && this.$route.params.searchVendorName && this.$route.params.searchPartNumber) {
            this.summaryDateType = this.$route.params.searchDateType
            switch (this.$route.params.searchDateType) {
              case 'week':
                this.summaryWeeksStart = this.$route.params.searchDateStart
                break
              case 'month':
                this.summaryMonthStart = this.$moment(this.$route.params.searchDateStart).format('YYYY-MM')
                break
              case 'season':
                this.summarySeasonStart = this.$route.params.searchDateStart
                break
            }
            this.tempVendorName = this.$route.params.searchVendorName
            this.tempPartNumber = this.$route.params.searchPartNumber
          }
          this.$http.get('/api/keypart/speaker/vendorName').then(res => res.data)
            .then(res => {
              if (res.status === 'success') {
                this.summaryVendorNameMenu = []
                res.data.forEach((n) => {
                  this.summaryVendorNameMenu.push({ text: n, value: n })
                })
                if (this.tempVendorName !== '') {
                  this.summaryVendorName = this.tempVendorName
                }
              }
            })
          this.loadData()
        } else if (response.errCode === 'E10000002') {
          const routeData = this.$router.resolve({ path: '/noEnter/E10000002' })
          localStorage.setItem('errormsg', response.errMsg)
          window.location.assign(routeData.href)
        } else {
          const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
          window.location.assign(routeData.href)
        }
      })
      .catch(err => {
        console.log(err)
        const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
        window.location.assign(routeData.href)
      })
  },
  methods: {
    selectedWeek (val) {
      this.summaryWeeksStart = val
    },
    async loadData () {
      this.loadingView = true
      await this.YearSeasonList()
      await this.AllYearSeasonList()
      // await this.YearWeeksList()
      // await this.AllYearWeeksList()
    },
    // YearWeeksList () {
    //   const Arr = []
    //   const YearStart = this.$moment(this.startdate).format('YYYY')
    //   const YearEnd = this.$moment(new Date()).format('YYYY')
    //   let NewYear = false
    //   let YearEndWeek = 0

    //   for (let y = 0; y <= (YearEnd - YearStart + 1); y++) {
    //     Arr[y] = []
    //     let w = 0
    //     let n = 0
    //     let day = new Date(Number(YearStart) + y + '-01-01').getTime()
    //     if (y === 0 && this.$moment(this.startdate).format('MM-DD') !== '01-01') {
    //       day = new Date(Number(YearStart) + y + '-' + this.$moment(this.startdate).format('MM-DD')).getTime()
    //       w = Math.ceil((((new Date(this.$moment(this.startdate).format('YYYY-MM-DD')) - new Date(new Date(this.$moment(this.startdate).format('YYYY'), 0, 1))) / 86400000) + new Date(new Date(this.$moment(this.startdate).format('YYYY'), 0, 1)).getDay()) / 7)
    //       if (new Date(this.$moment(this.startdate).format('YYYY') + '-01-01').getDay() > 0) {
    //         w = w - 1
    //       }
    //       Arr[y][w] = { start: this.$moment(this.startdate).format('YYYY-MM-DD'), end: '' }
    //       if (new Date(this.$moment(this.startdate).format('YYYY-MM-DD')).getDay() === 6) {
    //         Arr[y][w].end = this.$moment(this.startdate).format('YYYY-MM-DD')
    //       } else if (new Date(this.$moment(this.startdate).format('YYYY-MM-DD')).getDay() === 0) {
    //         w = w - 1
    //       }
    //     } else if (new Date(Number(YearStart) + y + '-01-01').getDay() !== 0) {
    //       n = 6 - new Date(Number(YearStart) + y + '-01-01').getDay()
    //       Arr[y][1] = { start: '', end: '' }
    //     }
    //     for (let d = (day + (86400000 * n)); d <= (new Date(Number(YearStart) + y + '-12-31').getTime()); d = d + 86400000) {
    //       if (y > 0 && new Date(d).getDay() === 6) {
    //         if (Arr[y][1].start === '') {
    //           Arr[y - 1][YearEndWeek].end = this.$moment(d).format('YYYY-MM-DD')
    //           Arr[y][0] = { start: Arr[y - 1][YearEndWeek].start, end: this.$moment(d).format('YYYY-MM-DD') }
    //           w = 0
    //           YearEndWeek = w
    //           NewYear = true
    //         }
    //       }
    //       if (new Date(d).getDay() === 0) {
    //         w++
    //         YearEndWeek = w
    //         NewYear = false
    //         Arr[y][w] = { start: this.$moment(d).format('YYYY-MM-DD'), end: '' }
    //       } else if (!NewYear && new Date(d).getDay() === 6) {
    //         Arr[y][w].end = this.$moment(d).format('YYYY-MM-DD')
    //       }
    //     }
    //   }
    //   this.summaryYearWeeksList = Arr
    // },
    // AllYearWeeksList () {
    //   const Arr = []
    //   const YearStart = this.$moment(this.startdate).format('YYYY')
    //   let YearEnd = this.$moment(this.summaryMonth).format('YYYY')
    //   let LastYear = 0
    //   if (new Date(this.$moment(this.summaryMonth).format('YYYY') + '-01-01').getDay() > 0) {
    //     LastYear = 1
    //   }
    //   const WeeksEnd = Math.ceil((((new Date(this.$moment(new Date()).format('YYYY-MM-DD')) - new Date(new Date(this.$moment(this.summaryMonth).format('YYYY'), 0, 1))) / 86400000) + new Date(new Date(this.$moment(this.summaryMonth).format('YYYY'), 0, 1)).getDay()) / 7) - LastYear
    //   if (WeeksEnd === 0) {
    //     YearEnd--
    //   }
    //   for (let y = 0; y <= (YearEnd - YearStart); y++) {
    //     for (let w = 1; w < this.summaryYearWeeksList[y].length; w++) {
    //       if (y === (YearEnd - YearStart) && w === WeeksEnd) {
    //         Arr.push({ text: (Number(YearStart) + y) + 'w' + w + '( ' + this.$moment(this.summaryYearWeeksList[y][w].start).format('MM-DD') + ' ～ ' + this.$moment(this.summaryYearWeeksList[y][w].end).format('MM-DD') + ' )', value: (Number(YearStart) + y) * 100 + w })
    //         break
    //       }
    //       if (this.summaryYearWeeksList[y][w]) {
    //         Arr.push({ text: (Number(YearStart) + y) + 'w' + w + '( ' + this.$moment(this.summaryYearWeeksList[y][w].start).format('MM-DD') + ' ～ ' + this.$moment(this.summaryYearWeeksList[y][w].end).format('MM-DD') + ' )', value: (Number(YearStart) + y) * 100 + w })
    //       }
    //     }
    //   }
    //   Arr.sort(function (a, b) { return b.value - a.value })
    //   this.summaryAllYearWeeksList = Arr
    //   this.loadingView = false
    // },
    YearSeasonList () {
      const Arr = []
      const startYear = this.$moment(this.startdate).format('YYYY')
      const endYear = this.$moment(new Date()).format('YYYY')

      for (let year = startYear; year <= endYear; year++) {
        Arr[Number(year) - Number(startYear)] = []
        for (let month = 1; month < 12; month += 3) {
          const seasonStartMonth = month
          const seasonEndMonth = month + 3 > 12 ? month + 3 - 12 : month + 3

          // const season = `${month + 3 > 12 ? year + 1 : year} Q${Math.ceil(seasonStartMonth / 3)} `
          const seasonRange = { start: this.$moment(year + '-' + seasonStartMonth + '-01').format('YYYY-MM-DD'), end: this.$moment(new Date(year + '-' + seasonEndMonth + '-01').getTime() - 86400000).format('YYYY-MM-DD') }
          Arr[Number(year) - Number(startYear)][Math.floor(seasonStartMonth / 3)] = seasonRange
          // Arr.push({ text: season + seasonRange, value: seasonRange })
        }
      }

      this.summaryYearSeasonList = Arr
    },
    AllYearSeasonList () {
      const Arr = []
      const startDate = this.$moment(this.startdate).format('YYYY-MM-DD')
      const endDate = this.$moment(new Date()).format('YYYY-MM-DD')

      this.summaryYearSeasonList.forEach((year, y) => {
        year.forEach((season, q) => {
          if (new Date(season.start).getTime() < new Date(endDate).getTime() && new Date(season.end).getTime() > new Date(startDate).getTime()) {
            Arr.push({ text: (Number(this.$moment(this.startdate).format('YYYY')) + y) + '　Q' + (q + 1) + '　( ' + this.$moment(season.start).format('MM-DD') + ' ～ ' + this.$moment(season.end).format('MM-DD') + ' )', value: season.start })
          }
        })
      })

      this.summaryAllYearSeasonList = Arr.reverse()
      this.loadingView = false
    },
    // responseDataCompileGroup (groupType, data) {
    //   const array = []
    //   const d = data.sort((a, b) => a.testItemName.localeCompare(b.testItemName))

    //   d.forEach((i, ii) => {
    //     if (array.map(function (e) { return e.buttonCode }).indexOf(i[groupType]) === -1) {
    //       array.push({ buttonCode: i[groupType], item: [i] })
    //     } else {
    //       array[array.map(function (e) { return e.buttonCode }).indexOf(i[groupType])].item.push(i)
    //     }
    //   })
    //   return array
    // },
    // getNameTag (val, type, isTag) {
    //   if (type === 'brackets') {
    //     if (isTag) {
    //       return val.substr((val.indexOf('(') + 1), (val.indexOf(')') - val.indexOf('(') - 1))
    //     } else {
    //       return val.substr(0, val.indexOf('('))
    //     }
    //   } else if (type === 'b-line') {
    //     if (isTag) {
    //       return val.substr((val.lastIndexOf('_') + 1), val.length)
    //     } else {
    //       return val.substr(0, (val.lastIndexOf('_') + 1))
    //     }
    //   } else if (type === 'space') {
    //     if (isTag) {
    //       return val.substr((val.lastIndexOf(' ') + 1), val.length)
    //     } else {
    //       return val.substr(0, (val.lastIndexOf(' ') + 1))
    //     }
    //   }
    // },
    getCpkGradeList () {
      this.$http.get('/api/keypart/support/cpkEvaluationList').then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.CpkGradeList = res.data
          }
        })
    },
    doCpkGrade (cpk) {
      let result = false
      this.CpkGradeList.forEach(e => {
        if ((e.upperSpecLimit ? e.upperSpecLimit : 999999999999999) > cpk && cpk >= (e.lowerSpecLimit ? e.lowerSpecLimit : -999999999999999)) {
          result = (e.status === 'qualified')
        }
      })
      return result
    },
    doCpkGradeName (cpk) {
      let result = false
      this.CpkGradeList.forEach(e => {
        if ((e.upperSpecLimit ? e.upperSpecLimit : 999999999999999) > cpk && cpk >= (e.lowerSpecLimit ? e.lowerSpecLimit : -999999999999999)) {
          result = e.evaluation
        }
      })
      return result
    },
    getPartNumber (vendor) {
      const body = {
        vendorName: vendor
      }
      this.$http.get('/api/keypart/speaker/partNumber?' + qs.stringify(body)).then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.summaryPartNumberMenu = []
            this.summaryPartNumber = ''
            res.data.forEach((n) => {
              this.summaryPartNumberMenu.push({ text: n, value: n })
            })
            if (this.tempPartNumber !== '') {
              this.summaryPartNumber = this.tempPartNumber
              this.tempVendorName = ''
              this.tempPartNumber = ''
              this.doSubmit()
            }
          }
        })
    },
    getContentDateList (vendor, partNumber, statistic) {
      const body = {
        vendorName: vendor,
        partNumber: partNumber,
        statisticType: statistic
      }
      this.$http.get('/api/keypart/speaker/manufacturingDate?' + qs.stringify(body)).then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.contentDateList = res.data
          }
        })
    },
    async doSubmit () {
      if (this.summaryWeeksEnd < this.summaryWeeksStart) {
        this.snackbar = true
        this.snackbarColor = 'red'
        this.snackbarMsg = '結尾時間不得早於起始時間'
      } else {
        this.loadingView = true
        this.processingStatus = false
        this.summaryNoData = true
        // this.overload = false
        // this.overloadMsg = ''
        // let wwS = 0

        // if (Number.isInteger(this.summaryWeeksStart)) {
        //   wwS = String(this.summaryWeeksStart).substr(4)
        // } else {
        //   wwS = Math.ceil((((new Date(this.$moment(new Date()).format('YYYY-MM-DD')) - new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1))) / 86400000) + new Date(new Date(this.$moment(new Date()).format('YYYY'), 0, 1)).getDay()) / 7) - parseInt((new Date(this.$moment(new Date()).format('YYYY') + '-01-01').getDay() + 5) / 6)
        // }

        const dateType = this.summaryDateType

        let manufactureStart = this.$moment(new Date()).format('YYYY-MM-DD')
        // let manufactureEnd = this.$moment(new Date()).format('YYYY-MM-DD')
        switch (dateType) {
          case 'week':
            // manufactureStart = this.$moment(this.summaryYearWeeksList[String(this.summaryWeeksStart).substr(0, 4) - this.$moment(this.startdate).format('YYYY')][Number(wwS)].start).format('YYYY-MM-DD')
            // manufactureEnd = this.$moment(this.summaryYearWeeksList[String(this.summaryWeeksStart).substr(0, 4) - this.$moment(this.startdate).format('YYYY')][Number(wwS)].end).format('YYYY-MM-DD')
            manufactureStart = this.summaryWeeksStart
            // manufactureStart = this.$moment().year(String(this.summaryWeeksStart).substr(0, 4)).isoWeek(String(this.summaryWeeksStart).substr(4, 2)).startOf('isoWeek').format('YYYY-MM-DD')
            break
          case 'month':
            manufactureStart = this.summaryMonthStart + '-01'
            // manufactureEnd = this.$moment(new Date(new Date(manufactureStart).getFullYear(), new Date(manufactureStart).getMonth() + 1, 0)).format('YYYY-MM-DD')
            break
          case 'season':
            manufactureStart = this.summarySeasonStart
            // manufactureEnd = this.summaryYearSeasonList.flat(Infinity).filter((x) => x.start === manufactureStart)[0].end
            break
        }

        // const manufactureWeeksStart = this.summaryWeeksStart
        const vendorName = this.summaryVendorName
        const partNumber = this.summaryPartNumber
        this.responseData = [{
          speakerType: '',
          left: [],
          right: []
        }]
        await this.getCpkGradeList()
        await this.doSubmitTypeDetail(dateType, vendorName, partNumber, manufactureStart)
        // await this.doSubmitAfter(dateType, vendorName, partNumber, manufactureStart, manufactureEnd, (this.summaryDateType === 'week' ? manufactureWeeksStart : manufactureStart))
        await this.doSubmitAfter(dateType, vendorName, partNumber, manufactureStart)
      }
    },
    // doSubmitType (type, groupType, vendorName, partNumber, uploadedDateStart, uploadedDateEnd) {
    //   const body = {
    //     uploadedDateStart: uploadedDateStart,
    //     uploadedDateEnd: uploadedDateEnd,
    //     vendorName: vendorName,
    //     partNumber: partNumber,
    //     type: type,
    //     systemTrigger: this.systemTrigger
    //   }
    //   this.$http.get('/api/keypart/lcdModule/summaryUploadedLcdModuleAnalysisDataList?' + qs.stringify(body)).then(res => res.data)
    //     .then(res => {
    //       if (res.status === 'success') {
    //         if (res.data.length > 0) {
    //           this.summaryNoData = false
    //           // this.responseData[type] = this.responseDataCompileGroup(groupType, res.data)
    //           this.responseData = {
    //             L: [
    //               {
    //                 type: 'L-SPL',
    //                 grid: 12,
    //                 item: [
    //                   {
    //                     partNumber: '18010-13410100',
    //                     testItemName: '400Hz',
    //                     type: 'Key_Size',
    //                     group: null,
    //                     statType: 'cpk',
    //                     detail: null,
    //                     value: 3.17
    //                   },
    //                   {
    //                     partNumber: '18010-13410100',
    //                     testItemName: '2000Hz',
    //                     type: 'Key_Size',
    //                     group: null,
    //                     statType: 'cpk',
    //                     detail: null,
    //                     value: 3.46
    //                   },
    //                   {
    //                     partNumber: '18010-13410100',
    //                     testItemName: '3150Hz',
    //                     type: 'Key_Size',
    //                     group: null,
    //                     statType: 'cpk',
    //                     detail: null,
    //                     value: -2.20
    //                   }
    //                 ]
    //               },
    //               {
    //                 type: 'L-THD',
    //                 grid: 12,
    //                 item: [
    //                   {
    //                     partNumber: '18010-13410100',
    //                     testItemName: '250Hz',
    //                     type: 'Key_Size',
    //                     group: null,
    //                     statType: 'cpk',
    //                     detail: null,
    //                     value: 3.17
    //                   },
    //                   {
    //                     partNumber: '18010-13410100',
    //                     testItemName: '5000Hz',
    //                     type: 'Key_Size',
    //                     group: null,
    //                     statType: 'cpk',
    //                     detail: null,
    //                     value: 3.46
    //                   }
    //                 ]
    //               },
    //               {
    //                 type: 'L-RUB',
    //                 grid: 12,
    //                 item: [
    //                   {
    //                     partNumber: '18010-13410100',
    //                     testItemName: '250Hz',
    //                     type: 'Key_Size',
    //                     group: null,
    //                     statType: 'cpk',
    //                     detail: null,
    //                     value: 3.17
    //                   },
    //                   {
    //                     partNumber: '18010-13410100',
    //                     testItemName: '5000Hz',
    //                     type: 'Key_Size',
    //                     group: null,
    //                     statType: 'cpk',
    //                     detail: null,
    //                     value: 3.46
    //                   }
    //                 ]
    //               },
    //               {
    //                 type: 'L-FO',
    //                 grid: 6,
    //                 item: [
    //                   {
    //                     partNumber: '18010-13410100',
    //                     testItemName: '',
    //                     type: 'Key_Size',
    //                     group: null,
    //                     statType: 'cpk',
    //                     detail: null,
    //                     value: 3.17
    //                   }
    //                 ]
    //               },
    //               {
    //                 type: 'L-DCR',
    //                 grid: 6,
    //                 item: [
    //                   {
    //                     partNumber: '18010-13410100',
    //                     testItemName: '',
    //                     type: 'Key_Size',
    //                     group: null,
    //                     statType: 'cpk',
    //                     detail: null,
    //                     value: 3.17
    //                   }
    //                 ]
    //               }
    //             ],
    //             R: [
    //               {
    //                 type: 'R-SPL',
    //                 grid: 12,
    //                 item: [
    //                   {
    //                     partNumber: '18010-13410100',
    //                     testItemName: '400Hz',
    //                     type: 'Key_Size',
    //                     group: null,
    //                     statType: 'cpk',
    //                     detail: null,
    //                     value: 3.17
    //                   },
    //                   {
    //                     partNumber: '18010-13410100',
    //                     testItemName: '2000Hz',
    //                     type: 'Key_Size',
    //                     group: null,
    //                     statType: 'cpk',
    //                     detail: null,
    //                     value: 3.46
    //                   },
    //                   {
    //                     partNumber: '18010-13410100',
    //                     testItemName: '3150Hz',
    //                     type: 'Key_Size',
    //                     group: null,
    //                     statType: 'cpk',
    //                     detail: null,
    //                     value: -2.20
    //                   }
    //                 ]
    //               },
    //               {
    //                 type: 'R-THD',
    //                 grid: 12,
    //                 item: [
    //                   {
    //                     partNumber: '18010-13410100',
    //                     testItemName: '250Hz',
    //                     type: 'Key_Size',
    //                     group: null,
    //                     statType: 'cpk',
    //                     detail: null,
    //                     value: 3.17
    //                   },
    //                   {
    //                     partNumber: '18010-13410100',
    //                     testItemName: '5000Hz',
    //                     type: 'Key_Size',
    //                     group: null,
    //                     statType: 'cpk',
    //                     detail: null,
    //                     value: 3.46
    //                   }
    //                 ]
    //               },
    //               {
    //                 type: 'R-RUB',
    //                 grid: 12,
    //                 item: [
    //                   {
    //                     partNumber: '18010-13410100',
    //                     testItemName: '250Hz',
    //                     type: 'Key_Size',
    //                     group: null,
    //                     statType: 'cpk',
    //                     detail: null,
    //                     value: 3.17
    //                   },
    //                   {
    //                     partNumber: '18010-13410100',
    //                     testItemName: '5000Hz',
    //                     type: 'Key_Size',
    //                     group: null,
    //                     statType: 'cpk',
    //                     detail: null,
    //                     value: 3.46
    //                   }
    //                 ]
    //               },
    //               {
    //                 type: 'R-FO',
    //                 grid: 6,
    //                 item: [
    //                   {
    //                     partNumber: '18010-13410100',
    //                     testItemName: '',
    //                     type: 'Key_Size',
    //                     group: null,
    //                     statType: 'cpk',
    //                     detail: null,
    //                     value: 3.17
    //                   }
    //                 ]
    //               },
    //               {
    //                 type: 'R-DCR',
    //                 grid: 6,
    //                 item: [
    //                   {
    //                     partNumber: '18010-13410100',
    //                     testItemName: '',
    //                     type: 'Key_Size',
    //                     group: null,
    //                     statType: 'cpk',
    //                     detail: null,
    //                     value: 3.17
    //                   }
    //                 ]
    //               }
    //             ]
    //           }
    //         }
    //         this.summaryLoading[type] = false
    //       } else { // failure
    //         if (this.systemTrigger) {
    //           this.overloadMsg = this.overloadMsg + '<br>[' + type + '] ' + res.errMsg
    //           this.summaryLoading[type] = false
    //         } else {
    //           this.overload = true
    //           this.overloadMsg = '系統錯誤，請洽系統管理人員\n錯誤訊息：' + res.errMsg
    //           this.summaryLoading[type] = false
    //         }
    //       }
    //     })
    //     .catch(err => {
    //       if (this.systemTrigger) {
    //         this.overloadMsg = this.overloadMsg + '<br>[' + type + '] ' + err
    //         this.summaryLoading[type] = false
    //       } else {
    //         this.overload = true
    //         this.overloadMsg = '系統錯誤，請洽系統管理人員\n錯誤訊息：' + err
    //         this.summaryLoading[type] = false
    //       }
    //     })
    //   // if (type === 'COG_bonding' || type === 'FOG_bonding') {
    //   //   this.$http.get('/api/keypart/lcdModule/detailLcdModuleAnalysisDataList?' + qs.stringify(body)).then(res => res.data)
    //   //     .then(res => {
    //   //       if (res.status === 'success') {
    //   //         if (res.data.length > 0) {
    //   //           this.summaryNoData = false
    //   //           this.responseProblemData[type] = res.data
    //   //         }
    //   //         this.summaryLoading['Problem_' + type] = false
    //   //       } else { // failure
    //   //         if (this.systemTrigger) {
    //   //           this.overloadMsg = this.overloadMsg + '<br>[' + type + '] ' + res.errMsg
    //   //           this.summaryLoading['Problem_' + type] = false
    //   //         } else {
    //   //           this.overload = true
    //   //           this.overloadMsg = '系統錯誤，請洽系統管理人員\n錯誤訊息：' + res.errMsg
    //   //           this.summaryLoading['Problem_' + type] = false
    //   //         }
    //   //       }
    //   //     })
    //   //     .catch(err => {
    //   //       if (this.systemTrigger) {
    //   //         this.overloadMsg = this.overloadMsg + '<br>[' + type + '] ' + err
    //   //         this.summaryLoading['Problem_' + type] = false
    //   //       } else {
    //   //         this.overload = true
    //   //         this.overloadMsg = '系統錯誤，請洽系統管理人員\n錯誤訊息：' + err
    //   //         this.summaryLoading['Problem_' + type] = false
    //   //       }
    //   //     })
    //   // }
    // },
    // // doSubmitChart (type, vendorName, partNumber, uploadedDateStart, uploadedDateEnd) {
    // //   const body = {
    // //     uploadedDateStart: uploadedDateStart,
    // //     uploadedDateEnd: uploadedDateEnd,
    // //     vendorName: vendorName,
    // //     partNumber: partNumber,
    // //     type: this.chartSetting[type].queryType
    // //   }

    // //   this.$http.get(`/api/keypart/lcdModule/${this.chartSetting[type].chartPath}?` + qs.stringify(body)).then(res => res.data)
    // //     .then(res => {
    // //       if (res.status === 'success') {
    // //         if (res.data.length > 0 || typeof res.data === 'object') {
    // //           this.summaryNoData = false
    // //           if (type === 'Chart_YieldRate') {
    // //             const columns = [
    // //               ['x', ...res.data.appearanceInspectionStatsData.map((item) => item.week)],
    // //               ['Appearance', ...res.data.appearanceInspectionStatsData.map((item) => item.output)],
    // //               ['Function', ...res.data.functionInspectionStatsData.map((item) => item.output)],
    // //               ['Appearance(%)', ...res.data.appearanceInspectionStatsData.map((item) => item.yieldRate)],
    // //               ['Function(%)', ...res.data.functionInspectionStatsData.map((item) => item.yieldRate)]
    // //             ]
    // //             console.log(type, columns)
    // //             this.chartData[type].$emit('init', this.doChartOptions(type, columns))
    // //           } else {
    // //             const columns = [
    // //               ['x', ...res.data.map((item) => item.week)],
    // //               ['數量', ...res.data.map((item) => item[this.chartSetting[type].yKey])]
    // //             ]
    // //             console.log(type, columns)
    // //             this.chartData[type].$emit('init', this.doChartOptions(type, columns))
    // //           }
    // //           this.summaryLoading[type] = false
    // //         }
    // //       } else { // failure
    // //         if (this.systemTrigger) {
    // //           this.overloadMsg = this.overloadMsg + '<br>[' + type + '] ' + res.errMsg
    // //           this.summaryLoading['Problem_' + type] = false
    // //         } else {
    // //           this.overload = true
    // //           this.overloadMsg = '系統錯誤，請洽系統管理人員\n錯誤訊息：' + res.errMsg
    // //           this.summaryLoading['Problem_' + type] = false
    // //         }
    // //       }
    // //     })
    // //     .catch(err => {
    // //       if (this.systemTrigger) {
    // //         this.overloadMsg = this.overloadMsg + '<br>[' + type + '] ' + err
    // //         this.summaryLoading['Problem_' + type] = false
    // //       } else {
    // //         this.overload = true
    // //         this.overloadMsg = '系統錯誤，請洽系統管理人員\n錯誤訊息：' + err
    // //         this.summaryLoading['Problem_' + type] = false
    // //       }
    // //     })
    // // },
    // doSubmitAfter (vendorName, partNumber, uploadedDateStart, uploadedDateEnd, manufactureWeeksStart, manufactureWeeksEnd) {
    //   this.responseVendorName = vendorName
    //   this.responsePartNumber = partNumber
    //   this.responseDateStart = uploadedDateStart
    //   this.responseDateEnd = uploadedDateEnd
    //   this.responseWeeksStart = manufactureWeeksStart
    //   this.responseWeeksEnd = manufactureWeeksEnd
    //   const Url = '/keypart/speaker/speakerSummaryAnalyzer/' + this.responseWeeksStart + '/' + this.responseWeeksEnd + '/' + this.responseVendorName + '/' + this.responsePartNumber
    //   if (this.$router.history.current.path !== Url) {
    //     this.$router.replace(Url)
    //   }
    // },
    // doSubmitSingle (type, testItemName) {
    //   this.loadingView = true

    //   const body = {
    //     uploadedDateStart: this.responseDateStart,
    //     uploadedDateEnd: this.responseDateEnd,
    //     vendorName: this.responseVendorName,
    //     partNumber: this.responsePartNumber,
    //     type: type,
    //     testItemName: testItemName
    //   }

    //   this.$http.get('/api/keypart/lcdModule/singleLcdModuleCpkItem?' + qs.stringify(body)).then(res => res.data)
    //     .then(res => {
    //       if (res.status === 'success') {
    //         this.responseStandardData = res.data
    //         this.responseStandardData.distributionCoverageList.sort(function (a, b) {
    //           return parseFloat(a.define.value) - parseFloat(b.define.value)
    //         })
    //         this.chartViewTestItemName = testItemName
    //         this.chartLineView = true
    //         this.loadingView = false
    //       } else { // failure
    //         this.loadingView = false
    //         alert('系統錯誤，請洽系統管理人員\n錯誤訊息：' + res.errMsg)
    //       }
    //     })
    //     .catch(err => {
    //       this.loadingView = false
    //       alert('系統錯誤，請洽系統管理人員\n錯誤訊息：' + err)
    //     })
    // }
    doSubmitTypeDetail (dateType, vendorName, partNumber, manufactureStart) {
      const body = {
        dateType: dateType,
        date: manufactureStart,
        vendorName: vendorName,
        partNumber: partNumber
      }
      this.$http.get('/api/keypart/speaker/summarySpeakerAnalysisDataList?' + qs.stringify(body)).then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            if (res.data.statisticDataList.length > 0) {
              this.summaryNoData = false
              this.responseData = res.data.statisticDataList
              this.responseModelList = res.data.modelList
            }
            this.loadingView = false
            this.processingStatus = true
          } else { // failure
            this.loadingView = false
            alert('系統錯誤，請洽系統管理人員\n錯誤訊息：' + res.errMsg)
          }
        })
        .catch(err => {
          this.loadingView = false
          alert('系統錯誤，請洽系統管理人員\n錯誤訊息：' + err)
        })
      // const statisticData = [{
      //   speakerType: 'Pair-LR1',
      //   left: [
      //     {
      //       testItemName: 'L-DCR',
      //       testItemType: 'DCR',
      //       cpk: 0.3043
      //     }, {
      //       testItemName: 'L-RUB_500Hz',
      //       testItemType: 'RUB',
      //       cpk: 0.2573
      //     }],
      //   right: [
      //     {
      //       testItemName: 'L-DCR',
      //       testItemType: 'DCR',
      //       cpk: 0.3043
      //     }, {
      //       testItemName: 'L-RUB_500Hz',
      //       testItemType: 'RUB',
      //       cpk: 0.2573
      //     }]
      // }, {
      //   speakerType: 'Pair-LR2',
      //   left: [
      //     {
      //       testItemName: 'L-DCR',
      //       testItemType: 'DCR',
      //       cpk: 0.3043
      //     }, {
      //       testItemName: 'L-RUB_500Hz',
      //       testItemType: 'RUB',
      //       cpk: 0.2573
      //     }],
      //   right: [
      //     {
      //       testItemName: 'L-DCR',
      //       testItemType: 'DCR',
      //       cpk: 0.3043
      //     }, {
      //       testItemName: 'L-RUB_500Hz',
      //       testItemType: 'RUB',
      //       cpk: 0.2573
      //     }]
      // }]
      // this.summaryNoData = false
      // this.responseData = statisticData
      // this.loadingView = false
      // this.processingStatus = true
    },
    // doSubmitAfter (dateType, vendorName, partNumber, manufactureStart, manufactureEnd, manufactureWeeksStart) {
    doSubmitAfter (dateType, vendorName, partNumber, manufactureStart) {
      this.responseVendorName = vendorName
      this.responsePartNumber = partNumber
      this.responseDateType = dateType
      // this.responseWeeksStart = manufactureWeeksStart
      this.responseDateStart = manufactureStart
      // this.responseDateEnd = manufactureEnd
      // const Url = '/keypart/speaker/speakerSummaryAnalyzer/' + this.responseDateType + '/' + (this.summaryDateType === 'week' ? this.responseWeeksStart : this.responseDateStart) + '/' + this.responseVendorName + '/' + this.responsePartNumber
      const Url = '/keypart/speaker/speakerSummaryAnalyzer/' + this.responseDateType + '/' + this.responseDateStart + '/' + this.responseVendorName + '/' + this.responsePartNumber
      if (this.$router.history.current.path !== Url) {
        this.$router.replace(Url)
      }
    },
    doSubmitSingle (speakerType, testItemName) {
      this.loadingView = true

      const body = {
        dateType: this.responseDateType,
        date: this.responseDateStart,
        vendorName: this.responseVendorName,
        partNumber: this.responsePartNumber,
        testItemName: testItemName,
        speakerType: speakerType
      }

      this.$http.get('/api/keypart/speaker/singleSpeakerCpkItem?' + qs.stringify(body)).then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            this.responseStandardData = res.data
            this.responseStandardData.distributionCoverageList.sort(function (a, b) {
              return parseFloat(a.define.value) - parseFloat(b.define.value)
            })
            this.chartViewTestItemName = testItemName
            this.chartViewSpeakerType = speakerType
            this.chartLineView = true
            this.loadingView = false
          } else { // failure
            this.loadingView = false
            alert('系統錯誤，請洽系統管理人員\n錯誤訊息：' + res.errMsg)
          }
        })
        .catch(err => {
          this.loadingView = false
          alert('系統錯誤，請洽系統管理人員\n錯誤訊息：' + err)
        })
    }
  }
}
</script>
<style>
</style>
