<template>
  <v-container
    fluid
    grid-list-xl
    pt-0
  >
    <v-layout wrap>
      <!-- <v-flex
        xs12
        sm6
        lg2>
        <material-stats-card
          :value="summaryCaseName"
          style="margin-bottom:-20px !important;"
          color="purple"
          icon="mdi-folder-open"
          title="案名"/>
      </v-flex> -->
      <v-flex
        xs12>
        <v-flex
          xs12>
          <v-layout wrap>
            <v-flex
              xs12
              lg4>
              <material-stats-card
                :value="summaryAsusDeviceIsn"
                class="v-offset-none"
                style="margin-bottom:-20px !important;margin-top:20px !important;"
                color="deep-purple"
                icon="mdi-nut"
                title="ASUS PN"/>
            </v-flex>
            <v-flex
              xs12
              lg5>
              <material-stats-card
                :value="summaryDate + (summaryDateRange ? ('~' + summaryDateRange) : '')"
                style="margin-bottom:-20px !important;margin-top:20px !important;"
                color="indigo"
                icon="mdi-calendar"
                title="資料日期"/>
            </v-flex>
            <v-flex
              xs12
              lg3>
              <material-stats-card
                :value="summaryVendor"
                style="margin-bottom:-20px !important;margin-top:20px !important;"
                color="light-blue"
                icon="mdi-factory"
                title="供應商"/>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex
          xs12>
          <v-layout wrap>
            <v-flex
              xs12
              md4>
              <v-layout
                style="margin-top: -9px;"
                wrap>
                <v-flex
                  xs12>
                  <material-stats-card
                    :value="'R &lt; ' + responseAreaData.cpuRSpec"
                    class="v-offset-shadow"
                    style="margin-bottom:-20px !important;margin-top:25px !important;margin-right: 5px!important;width: calc(((100% - 115px) / 2) + 109px);float: left;"
                    color="purple"
                    icon="mdi-math-compass"
                    title="CPU R SPEC"/>
                  <material-stats-card
                    :value="'R &lt; ' + responseAreaData.vgaRSpec"
                    class="v-offset-none"
                    style="margin-bottom:-20px !important;margin-top:25px !important;width: calc((100% - 115px) / 2);float: left;"
                    color="purple"
                    icon="mdi-math-compass"
                    title="VGA R SPEC"/>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex
              xs12
              md8>
              <v-layout
                style="margin-top: -9px;"
                wrap>
                <v-flex
                  xs12>
                  <material-stats-card
                    :value="responseAreaData.productionQty + 'pcs'"
                    :color="responseAreaData.color"
                    :class="responseAreaData.warningColor || 'green'"
                    :icon="responseAreaData.icon || 'mdi-check'"
                    style="margin-bottom:-20px !important;margin-top:25px !important;margin-right: 5px!important;width: calc(((100% - 120px) / 3) + 109px);float: left;"
                    title="今日生產總數量"/>
                  <material-stats-card
                    :value="responseAreaData.resultQty + 'pcs'"
                    :color="responseAreaData.color"
                    :class="responseAreaData.warningColor || 'green'"
                    :icon="responseAreaData.icon || 'mdi-check'"
                    class="title-color v-offset-none"
                    style="margin-bottom:-20px !important;margin-top:25px !important;margin-right: 5px!important;width: calc((100% - 120px) / 3);float: left;"
                    title="符合條件數量"/>
                  <material-stats-card
                    :value="(responseAreaData.failRate * 100).toFixed(1) + '%'"
                    :color="responseAreaData.color"
                    :class="responseAreaData.warningColor || 'green'"
                    :icon="responseAreaData.icon || 'mdi-check'"
                    class="title-color v-offset-none"
                    style="margin-bottom:-20px !important;margin-top:25px !important;width: calc((100% - 120px) / 3);float: left;"
                    title="此條件不良率"/>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-flex>
      <v-flex
        xs12>
        <material-card
          class="card-shrink card-padding v-card__first"
          style="margin-top:30px !important;"
          color="general"
          title="燒機數據篩選">
          <v-form>
            <v-container
              py-0
              pt-3
              class="mw-100">
              <v-layout wrap>
                <v-flex
                  xs12
                  lg3>
                  <v-layout wrap>
                    <v-flex
                      style="text-align: center;padding: 3px 12px !important;"
                      xs4>
                      <v-text-field
                        label=""
                        value="CPU溫度"
                        persistent-hint
                        disabled/>
                    </v-flex>
                    <v-flex
                      style="text-align: center;padding: 3px 12px !important;"
                      xs5>
                      <v-btn-toggle
                        v-model="toggle_cpuTemp"
                        class="mt-2">
                        <v-btn
                          value="le"
                          class="general"
                          color="light"
                          flat>
                          <v-icon>mdi-less-than-or-equal</v-icon>
                        </v-btn>
                        <v-btn
                          value="eq"
                          class="general"
                          color="light"
                          flat>
                          <v-icon>mdi-equal</v-icon>
                        </v-btn>
                        <v-btn
                          value="ge"
                          class="general"
                          color="light"
                          flat>
                          <v-icon>mdi-greater-than-or-equal</v-icon>
                        </v-btn>
                      </v-btn-toggle>
                    </v-flex>
                    <v-flex
                      style="text-align: center;padding: 3px 12px !important;"
                      xs3>
                      <v-text-field
                        v-model="summaryCpuTemp"
                        :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                        :disabled="!toggle_cpuTemp"
                        label=""
                        persistent-hint/>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex
                  xs12
                  lg1/>
                <v-flex
                  xs12
                  lg3>
                  <v-layout wrap>
                    <v-flex
                      style="text-align: center;padding: 3px 12px !important;"
                      xs4>
                      <v-text-field
                        label=""
                        value="VGA溫度"
                        persistent-hint
                        disabled/>
                    </v-flex>
                    <v-flex
                      style="text-align: center;padding: 3px 12px !important;"
                      xs5>
                      <v-btn-toggle
                        v-model="toggle_gpuTemp"
                        class="mt-2">
                        <v-btn
                          value="le"
                          class="general"
                          color="light"
                          flat>
                          <v-icon>mdi-less-than-or-equal</v-icon>
                        </v-btn>
                        <v-btn
                          value="eq"
                          class="general"
                          color="light"
                          flat>
                          <v-icon>mdi-equal</v-icon>
                        </v-btn>
                        <v-btn
                          value="ge"
                          class="general"
                          color="light"
                          flat>
                          <v-icon>mdi-greater-than-or-equal</v-icon>
                        </v-btn>
                      </v-btn-toggle>
                    </v-flex>
                    <v-flex
                      style="text-align: center;padding: 3px 12px !important;"
                      xs3>
                      <v-text-field
                        v-model="summaryGpuTemp"
                        :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                        :disabled="!toggle_gpuTemp"
                        label=""
                        persistent-hint/>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex
                  xs12
                  lg1/>
                <v-flex
                  xs12
                  lg3>
                  <v-autocomplete
                    v-model="summaryChannel"
                    :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                    :items="summaryChannelMenu"
                    label="站別"
                    class="v-text-field__slot pt-0"
                    persistent-hint
                    outlined
                  />
                </v-flex>
                <v-flex
                  style="text-align: center;padding: 2px 12px !important;"
                  xs12
                  lg1
                  text-xs-right>
                  <v-btn
                    class="mx-0 mb-3 font-weight-light"
                    color="general"
                    @click="doSubmit('reSubmit')"
                  >
                    <v-icon class="mr-2">mdi-backup-restore</v-icon>更新結果
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </material-card>
      </v-flex>
      <v-flex
        class="v-flex__first"
        xs12>
        <material-card
          class="v-flex v-c3-chart"
          color="green">
          <div class="w-100 chart-btn c3-chart c3-click">
            <material-notification
              color="brown"
              class="mb-3 h4 font-weight-bold"
            >
              熱測數據分佈圖[{{ summaryItem }} @ {{ summaryRange }} {{ summaryItem === 'cpuTemperature' ? 'C' : summaryItem === 'vgaTemperature' ? 'C' : '' }}區間]
            </material-notification>
            <vue-c3 :handler="handler_bar"/>
          </div>
          <div>
            <v-flex
              xs12
              style="margin-left: 16px!important;padding: 0!important;">
              <v-autocomplete
                v-model="summaryItem"
                :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                :items="[
                  { text: 'CPU溫度', value: 'cpuTemperature' },
                  { text: 'VGA溫度', value: 'vgaTemperature' },
                  { text: 'CPU R', value: 'cpuR' },
                  { text: 'VGA R', value: 'vgaR' }
                ]"
                label="資料來源"
                class="v-text-field__slot v-text-field__details-none"
                persistent-hint
                outlined
              />
            </v-flex>
            <v-flex
              xs12
              style="margin-left: 16px!important;padding: 0!important;">
              <v-autocomplete
                v-model="summaryRange"
                :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
                :items="summaryRangeMenu"
                label="區間"
                class="v-text-field__slot v-text-field__details-none"
                persistent-hint
                outlined
              />
            </v-flex>
            <material-notification
              :color="responseAreaData.warningColor || 'green'"
              class="mt-1 mb-0 py-1 h4 font-weight-bold"
            >
              <v-card-title
                primary-title
                class="mt-0 py-0 d-block text-center">
                <div class="headline m-auto">
                  <h4 class="text-center">CPU R</h4>
                  <hr class="my-2 white">
                  <h6 class="text-left">avg<span class="float-right">{{ responseAreaData.cpuRAvg }}</span></h6>
                  <h6 class="text-left">max<span class="float-right">{{ responseAreaData.cpuRMax }}</span></h6>
                  <h6 class="text-left">min<span class="float-right">{{ responseAreaData.cpuRMin }}</span></h6>
                  <h6 class="text-left">cpk<span class="float-right">{{ responseAreaData.cpuRCpk }}</span></h6>
                </div>
              </v-card-title>
            </material-notification>
            <material-notification
              :color="responseAreaData.warningColor || 'green'"
              class="mt-1 mb-0 py-1 h4 font-weight-bold"
            >
              <v-card-title
                primary-title
                class="mt-0 py-0 d-block text-center">
                <div class="headline m-auto">
                  <h4 class="text-center">VGA R</h4>
                  <hr class="my-2 white">
                  <h6 class="text-left">avg<span class="float-right">{{ responseAreaData.vgaRAvg }}</span></h6>
                  <h6 class="text-left">max<span class="float-right">{{ responseAreaData.vgaRMax }}</span></h6>
                  <h6 class="text-left">min<span class="float-right">{{ responseAreaData.vgaRMin }}</span></h6>
                  <h6 class="text-left">cpk<span class="float-right">{{ responseAreaData.vgaRCpk }}</span></h6>
                </div>
              </v-card-title>
            </material-notification>
          </div>
        </material-card>
      </v-flex>
      <v-flex
        flex_width_auto
        xs12
      >
        <material-card
          id="checkTable"
          :style="'max-width: calc(100vw - '+(Number(shrink)+32)+'px);'"
          :title="(RangeIndex===999 ? '全部' : (summaryItem + '區間' + (RangeMin+(RangeIndex*summaryRange)) + '-' + (RangeMin+Number(summaryRange)+(RangeIndex*summaryRange)) ) ) + '數據列表'"
          color="deep-orange"
          class="card-mix card-padding pt-0">
          <v-select
            v-model="perPageRows"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="rowsAmount"
            class="mix-list v-text-field__slot d-inline-block float-right ml-5"
            style="width: 90px;"
            persistent-hint
            outlined
          />
          <v-btn
            class="mix-btn black text-light"
            style="right: 8rem;"
            @click="summaryList (999, 'all')">
            列出全部資料
          </v-btn>
          <v-btn
            class="mix-btn black text-light"
            style="right: 15rem;"
            @click="doGroupDownload()">
            <v-icon class="floata-left">mdi-download</v-icon>打包下載
          </v-btn>
          <v-autocomplete
            id="checkTableInput"
            v-model="checkTableShowList"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="summaryTableShowList"
            label="選擇欄位"
            class="v-text-field__slot row-btn-175 pt-0"
            color="white"
            multitude
            chips
            persistent-hint
            multiple
            outlined
          />
          <v-btn
            class="red text-light float-right mr-0"
            @click="checkTableShowListClear()"
          >
            清除
          </v-btn>
          <v-btn
            class="blue text-light float-right"
            @click="checkTableShowListAll()"
          >
            全選
          </v-btn>
        </material-card>
        <material-card
          :style="'max-width: calc(100vw - '+(Number(shrink)+32)+'px);'"
          class="d-table-t0 no-data-left">
          <v-data-table
            :headers="responseHeaders"
            :items="responseData"
            :pagination.sync="pagination"
            :rows-per-page-items ="rowsAmount"
            :class="{ 'showList': checkTableShowList.length>0}"
            class="freeze-title"
            multi-sort
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="subheading font-weight-light text-general text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <tr :title="item.barcode">
                <!-- <td
                  class="td-check"
                  style="position: relative;">
                  <v-checkbox
                    v-model="checkList"
                    :value="item.SN"
                    class="mx-2"
                    style="position: absolute;top: 10px;"
                    color="orange"
                    label=""/>
                </td> -->
                <td class="table-width-300">{{ item.barcode }}</td>
                <td class="table-width-100">{{ item.fixtureId }}</td>
                <td class="table-width-75">{{ item.stationId }}</td>
                <td class="table-width-100">{{ item.cpuTemperature }}</td>
                <td class="table-width-100">{{ item.cpuR }}</td>
                <td class="table-width-100">{{ item.vgaTemperature }}</td>
                <td class="table-width-100">{{ item.vgaR }}</td>
                <td class="table-width-75">{{ item.roomTemperature }}</td>
                <td class="table-width-100">{{ item.endResult }}</td>
                <td class="table-width-100">
                  <div
                    v-for="(ttrsPk, t) in item.pkList"
                    :key="'ttrsPkBtn' + t">
                    <router-link
                      :to="'/thermalModule/pl2/singleThermalModuleTestDatalnfo/' + ttrsPk"
                      target="_blank">
                      <v-btn
                        class="w-100 mx-0 black font-weight-light small">
                        <v-icon class="mr-2">mdi-clipboard-text-outline</v-icon>{{ item.operation.replace('@', t+1) }}
                      </v-btn>
                    </router-link>
                  </div>
                </td>
                <td
                  v-for="(col, index) in responseTableShowList"
                  v-show="responseTableShowList[index]"
                  :class="responseColumnClassList[index]+' text-xs-'+responseAlignList[index]"
                  :key="'col'+index"
                >{{ item[index] }}</td>
              </tr>
            </template>
          </v-data-table>
          <div
            v-if="pagination.rowsPerPage != -1"
            class="text-xs-center pt-2 d-inline-block"
            style="margin-left: 0px;width: calc(100% - 140px);">
            <v-pagination
              v-model="pagination.page"
              :length="pages"/>
          </div>
          <v-autocomplete
            v-if="pagination.rowsPerPage != -1"
            v-model="pagination.page"
            :class="{'danger-input' : theme==='dark' , 'general-input' : theme==='light'}"
            :items="pageslist"
            class="v-text-field__slot d-inline-block float-right mr-0"
            style="width: 90px;"
            persistent-hint
            outlined
          />
        </material-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="processingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        PROCESSING 處理中
      </p>
    </v-dialog>
    <v-dialog
      v-model="loadingView"
      :width="'calc(100% - ' + shrink + 'px)'"
      class="chart-view"
      persistent>
      <p style="letter-spacing: 5px;text-align: center !important;color: #ffffff;background-color: #000000dd;padding: 15px;width: 50%;margin: auto;">
        <img
          :src="Loading"
          style="width: 2rem;margin-bottom: 10px;">
        <br>
        LOADING 載入中
      </p>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :top="true"
      :color="snackbarColor">
      {{ snackbarMsg }}
      <v-btn
        dark
        flat
        @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import Vue from 'vue'
import VueC3 from 'vue-c3'
import 'c3/c3.min.css'
import { mapState } from 'vuex'
import Loading from '@/assets/img/loading.gif'

export default {
  name: 'ThermalModuleTestDataAnalysisPL2',
  components: {
    VueC3
  },
  data () {
    return {
      startdate: '2018-04-01',
      snackbar: false,
      snackbarColor: 'red',
      snackbarMsg: '',
      Loading,
      loadingView: false,
      processingView: false,
      summaryCaseName: '',
      summaryAsusDeviceIsn: '',
      summaryDate: '',
      summaryDateRange: '',
      summaryVendor: '',
      summaryVendorPk: 0,
      summaryRange: '5',
      summaryRangeMenu: [
        { text: '2.5 C', value: '2.5' },
        { text: '5 C', value: '5' },
        { text: '7.5 C', value: '7.5' },
        { text: '10 C', value: '10' }
      ],
      summaryItem: 'cpuTemperature',
      summaryTableShowList: [],
      toggle_cpuTemp: '',
      toggle_gpuTemp: '',
      summaryCpuTemp: 0,
      summaryGpuTemp: 0,
      summaryChannel: 'All',
      summaryChannelMenu: [],
      RangeAll: true,
      RangeIndex: 999,
      RangeMin: 0,
      RangeMax: 9999,
      RangeMaxList: {
        cpuTemperature: 200,
        vgaTemperature: 200,
        cpuR: 4,
        vgaR: 3
      },
      OverRangeCount: 0,
      RangeCount: 1,
      responseData: [],
      responseDataOnly: [],
      responseHeaders: [],
      responseHeadersOnly: [],
      responseHeadersList: [],
      responseColumnClassList: {},
      responseAlignList: {},
      responseTableShowList: {},
      responseTableShowListOnly: {},
      responseAreaData: {
        productionQty: 0,
        failQty: 0,
        failRate: 0,
        warningColor: 'green',
        resultQty: 0,
        cpuRSpec: 0,
        vgaRSpec: 0,
        cpuRAvg: 0,
        cpuRMin: 0,
        cpuRMax: 0,
        cpuRCpk: 0,
        vgaRAvg: 0,
        vgaRMin: 0,
        vgaRMax: 0,
        vgaRCpk: 0
      },
      // checkList: [],
      checkTableShowList: [],
      lineXaxisName: [],
      lineXaxisValue: [],
      rowsAmount: [25, 50, 100, { text: 'All', value: -1 }],
      perPageRows: 25,
      pagination: {
        page: 1,
        rowsPerPage: 25,
        sortBy: '',
        descending: false
      },
      pages: 0,
      handler_bar: new Vue()
    }
  },
  computed: {
    ...mapState('app', ['theme', 'shrink']),
    pageslist () {
      let i = 0
      const list = []
      if (this.pagination.rowsPerPage) {
        for (i = 0; i < Math.ceil(this.responseData.length / this.pagination.rowsPerPage); i++) {
          list.push(i + 1)
        }
      }
      return list
    },
    options_bar () {
      return {
        data: {
          x: 'x',
          columns: [
            this.barXaxisName,
            this.barXaxisValue
          ],
          labels: true,
          // labels: {
          //   // format: function (v, id, i, j) { return v + '%' }
          // },
          // onclick: function (d) { alert(d.index) },
          onclick: (d) => { this.summaryList(d.index, 'range') },
          type: 'bar',
          types: {
            NormalDistribution: 'spline'
          },
          axes: {
            ModelName: 'y'
          }
          // colors: {
          //   ModelName: '#999999'
          // }
          // onclick: (d, i) => { this.summaryList(d, i) } // {id: "data23", value: 120, ratio: 0.8, index: 1, name: "data23"}
          // color: function (color, d) {
          //   // d will be 'id' when called for legends
          //   return d.value >= 5.0 ? '#ff0000' : d.value >= 3.0 ? '#ff9800' : color
          // }
          // color: (color, d) => {
          //   // d will be 'id' when called for legends
          //   return (this.OverRangeCount > 0 && d.index === this.barXaxisName.length - 2) ? '#ffa500' : color
          // }
        },
        axis: {
          x: {
            type: 'category', // this needed to load string x value
            tick: {
              rotate: -45,
              multiline: false
            }
            // label: {
            //   text: '期',
            //   position: 'outer-center'
            // }
          },
          y: {
            // max: this.RangeCount,
            // min: 0,
            // tick: {
            //   // count: this.RangeCount + 1
            // },
            // padding: {
            //   top: 0,
            //   bottom: 0
            // }
            // tick: {
            //   format: function (value) { return value + '%' }
            // }
            // label: {
            //   text: '%',
            //   position: 'outer-middle'
            // }
          },
          y2: {
            // show: true
            // label: {
            //   text: '億',
            //   position: 'outer-middle'
            // }
          }
        },
        tooltip: {
          format: {
            title (d) {
              // return `第 ${d} 期`
            },
            value (value, ratio, id) {
              // return `${value} 億`
            }
          }
        },
        grid: {
          x: {
            show: true
          },
          y: {
            show: true
          }
        },
        legend: {
          position: 'bottom',
          padding: 16,
          show: false
        },
        size: {
          // width: 640,
          height: 500
        }
      }
    }
  },
  watch: {
    checkTableShowList (val) {
      this.responseHeaders = JSON.parse(JSON.stringify(this.responseHeadersOnly))
      this.responseTableShowList = JSON.parse(JSON.stringify(this.responseTableShowListOnly))
      this.summaryTableShowList.forEach((s, index) => {
        this.checkTableShowList.forEach((c) => {
          this.responseTableShowList[c] = true
          if (s.value === c) {
            this.responseHeaders.push(this.responseHeadersList[index])
          }
        })
      })
    },
    toggle_cpuTemp (val) {
      if (!this.toggle_cpuTemp) {
        this.summaryCpuTemp = 0
      }
    },
    toggle_gpuTemp (val) {
      if (!this.toggle_gpuTemp) {
        this.summaryGpuTemp = 0
      }
    },
    responseData (val) {
      this.pages = Math.ceil(this.responseData.length / this.pagination.rowsPerPage)
      if (this.pages > 1) {
        setTimeout(() => {
          this.pagination.page = this.pages
        }, 0)
        setTimeout(() => {
          this.pagination.page = 1
        }, 100)
      } else {
        setTimeout(() => {
          this.pagination.rowsPerPage = 1
        }, 0)
        setTimeout(() => {
          this.pagination.rowsPerPage = this.perPageRows
        }, 100)
      }
    },
    perPageRows (val) {
      if (val === -1) {
        if (confirm('完整顯示將可能導致效能降低，是否仍完整顯示?')) {
          this.pagination.rowsPerPage = val
          this.pages = Math.ceil(this.responseData.length / this.pagination.rowsPerPage)
          this.pagination.page = 1
        } else {
          setTimeout(() => {
            this.perPageRows = this.pagination.rowsPerPage
          }, 100)
        }
      } else {
        this.pagination.rowsPerPage = val
        this.pages = Math.ceil(this.responseData.length / this.pagination.rowsPerPage)
        this.pagination.page = 1
      }
    },
    'pagination.page' (val) {
      this.checkList = []
    },
    'pagination.rowsPerPage' (val) {
      this.checkList = []
    },
    'pagination.sortBy' (val) {
      this.checkList = []
    },
    'pagination.descending' (val) {
      this.checkList = []
    },
    summaryDate (val) {
      this.summaryDateMenu = false
    },
    summaryItem (val) {
      if (val === 'cpuTemperature' || val === 'vgaTemperature') {
        this.summaryRange = '5'
        this.summaryRangeMenu = [
          { text: '2.5 C', value: '2.5' },
          { text: '5 C', value: '5' },
          { text: '7.5 C', value: '7.5' },
          { text: '10 C', value: '10' }
        ]
      } else if (val === 'cpuR' || val === 'vgaR') {
        this.summaryRange = '0.1'
        this.summaryRangeMenu = [
          { text: '0.05', value: '0.05' },
          { text: '0.1', value: '0.1' }
        ]
      }
      this.doSummary()
    },
    summaryRange (val) {
      this.doSummary()
    }
  },
  mounted () {
    this.$store.dispatch('getAuthorize', '/thermalModule/pl2/thermalModuleTestDataAnalysis')
      .then(response => {
        if (response.status === 'success' && response.data.accessible) {
          JSON.parse(localStorage.getItem('moduleDataInitDate')).forEach((n) => {
            if (n.name === 'thermalModule') {
              this.startdate = n.value
            }
          })
          const { deviceIsn, modelname, date, daterange, vendorpk, vendor } = this.$route.query
          this.summaryCaseName = modelname || this.summaryCaseName
          this.summaryAsusDeviceIsn = deviceIsn || this.summaryAsusDeviceIsn
          this.summaryDate = date || this.summaryDate
          this.summaryDateRange = daterange || this.summaryDateRange
          this.summaryVendorPk = vendorpk || this.summaryVendorPk
          this.summaryVendor = vendor || this.summaryVendor
          const body = {
            queryStartDate: this.summaryDate,
            queryEndDate: this.summaryDateRange || this.summaryDate,
            vendor: this.summaryVendorPk,
            deviceIsn: this.summaryAsusDeviceIsn,
            modelName: this.summaryCaseName
          }
          this.$http.post('/api/thermalModule/pl2/thermalModuleChannel', body).then(res => res.data)
            .then(res => {
              if (res.status === 'success') {
                this.summaryChannelMenu = [{ text: 'All', value: 'All' }].concat(res.data)
              }
            })
          this.doSubmit()
        } else if (response.errCode === 'E10000002') {
          const routeData = this.$router.resolve({ path: '/noEnter/E10000002' })
          localStorage.setItem('errormsg', response.errMsg)
          window.location.assign(routeData.href)
        } else {
          const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
          window.location.assign(routeData.href)
        }
      })
      .catch(err => {
        console.log(err)
        const routeData = this.$router.resolve({ path: JSON.parse(localStorage.getItem('user')).home })
        window.location.assign(routeData.href)
      })
  },
  methods: {
    doSummary (type) {
      this.barXaxisName = ['x']
      this.barXaxisValue = ['ModelName']
      const AllArray = []
      this.responseDataOnly.forEach((r) => {
        if (r[this.summaryItem] >= 0) {
          AllArray.push(r[this.summaryItem])
        }
      })
      this.RangeMax = Math.ceil(Math.max(...AllArray) / this.summaryRange) * this.summaryRange
      this.RangeMin = Math.floor(Math.min(...AllArray) / this.summaryRange) * this.summaryRange
      if (this.RangeMax > this.RangeMaxList[this.summaryItem]) {
        this.RangeMax = Math.min(this.RangeMax, this.RangeMaxList[this.summaryItem])
      }
      this.OverRangeCount = 0
      const RangeArray = []
      for (let i = this.RangeMin; i < this.RangeMax; i = i + this.summaryRange) {
        RangeArray[(i - this.RangeMin) / this.summaryRange] = 0
      }
      AllArray.forEach((r) => {
        if (r > this.RangeMaxList[this.summaryItem]) {
          this.OverRangeCount = this.OverRangeCount + 1
        } else {
          if (RangeArray[Math.floor((r - this.RangeMin) / this.summaryRange)] > 0) {
            RangeArray[Math.floor((r - this.RangeMin) / this.summaryRange)] = RangeArray[Math.floor((r - this.RangeMin) / this.summaryRange)] + 1
          } else {
            RangeArray[Math.floor((r - this.RangeMin) / this.summaryRange)] = 1
          }
        }
      })

      this.RangeCount = Math.max(...RangeArray)
      for (let i = 0; i < RangeArray.length; i++) {
        this.barXaxisValue.push(RangeArray[i] || 0)
        switch (true) {
          case (this.summaryRange === '5' || this.summaryRange === '10'):
            this.barXaxisName.push((this.RangeMin + (i * this.summaryRange)).toFixed(0) + '-' + (this.RangeMin + ((i + 1) * this.summaryRange)).toFixed(0))
            break
          case (this.summaryRange === '0.1' || this.summaryRange === '2.5' || this.summaryRange === '7.5'):
            this.barXaxisName.push((this.RangeMin + (i * this.summaryRange)).toFixed(1) + '-' + (this.RangeMin + ((i + 1) * this.summaryRange)).toFixed(1))
            break
          case (this.summaryRange === '0.05'):
            this.barXaxisName.push((this.RangeMin + (i * this.summaryRange)).toFixed(2) + '-' + (this.RangeMin + ((i + 1) * this.summaryRange)).toFixed(2))
            break
          // default:
          // return
        }
      }
      if (this.OverRangeCount > 0) {
        this.barXaxisValue.push(this.OverRangeCount)
        this.barXaxisName.push('>' + this.RangeMaxList[this.summaryItem])
      }
      // RangeArray.forEach((r, index) => {
      //   console.log(r)
      //   console.log(index)
      //   this.barXaxisValue.push(r)
      //   this.barXaxisName.push((this.RangeMin + (index * this.summaryRange)) + '-' + (this.RangeMin + ((index + 1) * this.summaryRange)))
      // })
      if (type === 'init') {
        this.handler_bar.$emit('init', this.options_bar)
      } else {
        this.handler_bar.$emit('dispatch', chart => {
          const options = {
            columns: [
              this.barXaxisName,
              this.barXaxisValue
            ]
          }
          chart.load(options)
        })
      }
    },
    checkTableShowListAll () {
      this.checkTableShowList = []
      this.summaryTableShowList.forEach((s) => {
        this.checkTableShowList.push(s.value)
      })
    },
    checkTableShowListClear () {
      this.checkTableShowList = []
    },
    doSubmit (type) {
      this.loadingView = true
      const body = {
        queryStartDate: this.summaryDate,
        queryEndDate: this.summaryDateRange || this.summaryDate,
        vendor: this.summaryVendorPk,
        deviceIsn: this.summaryAsusDeviceIsn,
        modelName: this.summaryCaseName,
        type: 1,
        cpuTemperature: this.toggle_cpuTemp ? this.summaryCpuTemp === '' ? 0 : this.summaryCpuTemp : null,
        cpuCondition: this.toggle_cpuTemp || null,
        gpuTemperature: this.toggle_gpuTemp ? this.summaryGpuTemp === '' ? 0 : this.summaryGpuTemp : null,
        gpuCondition: this.toggle_gpuTemp || null,
        channel: this.summaryChannel === 'All' ? '' : this.summaryChannel
      }
      if (type !== 'reSubmit') {
        this.$http.post('/api/thermalModule/pl2/thermalModuleBurnInDataStatisticTableColumnDefinition', body).then(res => res.data)
          .then(res => {
            if (res.status === 'success') {
              // this.responseHeaders.push({
              //   align: '',
              //   class: '',
              //   fixed: true,
              //   sortable: false,
              //   text: '',
              //   value: ''
              // })
              // this.responseHeadersOnly.push({
              //   align: '',
              //   class: '',
              //   fixed: true,
              //   sortable: false,
              //   text: '',
              //   value: ''
              // })
              this.responseHeadersList = []
              res.data.columnDefine.forEach((r) => {
                if (r.fixed) {
                  this.responseHeaders.push(r)
                  this.responseHeadersOnly.push(r)
                } else {
                  this.responseHeadersList.push(r)
                  this.responseTableShowList[r.value] = r.fixed
                  this.responseTableShowListOnly[r.value] = r.fixed
                  this.responseColumnClassList[r.value] = r.class
                  this.responseAlignList[r.value] = r.align
                  this.summaryTableShowList.push({ text: r.text, value: r.value })
                }
              })
              this.pagination.sortBy = res.data.sortBy
              this.pagination.descending = res.data.descending
            }
          })
      }
      this.$http.post('/api/thermalModule/pl2/thermalModuleBurnInDataStatistic', body).then(res => res.data)
        .then(res => {
          this.loadingView = false
          if (res.status === 'success') {
            this.responseData = []
            this.responseDataOnly = []
            // this.pagination.rowsPerPage = res.data.length
            res.data.deviceList.forEach((r, index) => {
              this.responseData.push({})
              r.forEach((rr) => {
                this.responseData[index][rr.name] = rr.value
              })
              this.responseDataOnly.push({})
              r.forEach((rr) => {
                this.responseDataOnly[index][rr.name] = rr.value
              })
            })
            this.pagination.rowsPerPage = 25
            // this.pages = Math.ceil(this.responseData.length / this.pagination.rowsPerPage)
            if (type !== 'reSubmit') {
              this.doSummary('init')
            } else {
              this.doSummary()
            }
            this.responseAreaData = res.data.summaryInfo
            this.responseAreaData.color = this.responseAreaData.warningColor === '' ? 'light-green' : this.responseAreaData.warningColor === 'orange' ? 'deep-orange' : 'pink'
            this.responseAreaData.icon = this.responseAreaData.warningColor === '' ? 'mdi-check' : this.responseAreaData.warningColor === 'orange' ? 'mdi-alarm-light' : 'mdi-alert'
          }
        })
    },
    doGroupDownload () {
      this.processingView = true
      const body = {
        queryStartDate: this.summaryDate,
        queryEndDate: this.summaryDateRange || this.summaryDate,
        vendor: this.summaryVendorPk,
        deviceIsn: this.summaryAsusDeviceIsn,
        modelName: this.summaryCaseName,
        type: 1,
        cpuTemperature: this.toggle_cpuTemp ? this.summaryCpuTemp === '' ? 0 : this.summaryCpuTemp : null,
        cpuCondition: this.toggle_cpuTemp || null,
        gpuTemperature: this.toggle_gpuTemp ? this.summaryGpuTemp === '' ? 0 : this.summaryGpuTemp : null,
        gpuCondition: this.toggle_gpuTemp || null,
        channel: this.summaryChannel === 'All' ? '' : this.summaryChannel
      }
      this.$http.post('/api/thermalModule/pl2/thermalModuleBurnInRawDataFile', body, { observe: 'response', responseType: 'blob' })
        .then(res => {
          this.processingView = false
          if (res.data.type === 'application/json') {
            return new Promise((resolve, reject) => {
              const reader = new FileReader()
              reader.onload = () => {
                res.data = JSON.parse(reader.result)
                if (res.data.status === 'success') {
                  alert(res.data.data)
                  // resolve(Promise.reject(res.data.data))
                } else {
                  this.snackbar = true
                  this.snackbarColor = 'red'
                  this.snackbarMsg = res.data.errMsg + ' (' + res.data.errCode + ')'
                }
              }
              reader.onerror = () => {
                reject(res.data.data)
              }
              reader.readAsText(res.data)
            })
          } else if (res.data.status !== 'failure' && res.headers['content-disposition']) {
            // let blob = new Blob([res.data])
            const url = window.URL.createObjectURL(res.data)
            const fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]
            const downloadLink = document.createElement('a')

            // downloadLink.setAttribute('download', fileName)
            downloadLink.href = url
            downloadLink.download = fileName

            document.body.appendChild(downloadLink)
            downloadLink.click()
            document.body.removeChild(downloadLink)
          } else {
            this.snackbar = true
            this.snackbarColor = 'red'
            this.snackbarMsg = '下載失敗，請通知系統管理員'
          }
        })
        .catch(err => {
          // this.error = true
          this.processingView = false
          this.snackbar = true
          this.snackbarColor = 'orange'
          this.snackbarMsg = err
        })
    },
    summaryList (Index, type) {
      this.checkList = []
      this.RangeIndex = Index
      this.responseData = []
      // this.responseData.push({})
      if (type === 'all') {
        // let n = 0
        this.responseDataOnly.forEach((r) => {
          this.responseData.push(r)
          // r.forEach((rr) => {
          //   this.responseData[n][rr.name] = rr.value
          // })
          // n++
        })
      } else if (type === 'range') {
        // let n = 0
        this.responseDataOnly.forEach((r) => {
          if (r[this.summaryItem] >= (this.RangeMin + (this.RangeIndex * this.summaryRange)) && r[this.summaryItem] < (this.RangeMin + Number(this.summaryRange) + (this.RangeIndex * this.summaryRange))) {
            this.responseData.push(r)
            // r.forEach((rr) => {
            //   this.responseData[n][rr.name] = rr.value
            // })
            // n++
          }
        })
      }
      // console.log(this.responseDataOnly)
      // console.log(this.responseData)
    }
  }
}
</script>
<style>
</style>
